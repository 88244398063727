import React, { useState } from 'react';
import { Icon } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';
import { parseQlFormula } from 'utils';

import FormulaModal from '../FormulaModal';
import expressions from '../FormulaModal/expressions';
import GapModal from '../GapModal';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const EditableGap = ({ node }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const value = JSON.parse(node.getAttribute('value'));

  return (
    <span>
      <Tooltip
        tip={lang.edit}
        place={'down'}
      >
        <span
          onClick={() => setOpen(true)}
          className={classes.formulaWrapper}
        >
          {value.formula ? (
            <span
              dangerouslySetInnerHTML={{ __html: parseQlFormula(`<span class="ql-formula">${value.formula}</span>`) }}
            />
          ) : (
            <span>
              {value}
            </span>
          )}
          <Icon className={classes.editIcon} icon={'pen'} />

        </span>
      </Tooltip>
      {value.formula ? (
        <FormulaModal
          open={open}
          close={() => setOpen(false)}
          value={value.formula}
          insert={(value) => {
            node.setAttribute('value', JSON.stringify({ formula: value }));
          }}
          mathSymbols={expressions}
        />
      ) : (
        <GapModal
          open={open}
          close={() => setOpen(false)}
          value={value}
          insert={(value) => {
            node.setAttribute('value', JSON.stringify(value));
          }}
        />
      )
      }
    </span>
  );
};

EditableGap.propTypes = {
  node: PropTypes.node,
};

export default EditableGap;
