import { useCallback, useState } from 'react';

const useForm = (formVariables) => {
  const initialValues = {};
  formVariables.forEach(variable => {
    initialValues[variable.label] = variable.initialValue;
  });
  const initialErrors = {};
  formVariables.forEach(variable => {
    initialErrors[variable.label] = variable.initialErrors || [];
  });

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(initialErrors);

  const getValue = useCallback((label) => {
    return values[label];
  }, [values]);

  const setValue = useCallback((label, newValue) => {
    setValues((values) => ({
      ...values,
      [label]: newValue,
    }));
  }, []);

  const getErrors = useCallback((label) => {
    return errors[label];
  }, [errors]);

  const _setErrors = useCallback((label, newErrors) => {
    setErrors((errors) => ({
      ...errors,
      [label]: newErrors,
    }));
  }, []);

  return [getValue, setValue, getErrors, _setErrors];
};

export default useForm;
