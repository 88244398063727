import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
    marginLeft: '10px',
    '&.noBack': {
      marginLeft: 'unset',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  btn: {
    marginLeft: '6px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  typeOption: {
    marginRight: '10px',
    marginTop: '10px',
    height: '170px !important',
    width: '170px !important',
    fontSize: '14px',
    borderRadius: '0',
    backgroundColor: theme.cardBackground2,
    border: `1px solid ${theme.cardBorder2}`,
    position: 'relative',
    transition: 'background-color 0.1s',
    '&:hover': {
      backgroundColor: theme.cardBackgroundHover2,
      border: `1px solid ${theme.cardBorderHover2}`,
    },
    '& .otl-cardContentContainer': {
      padding: '0',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  typeOptionDisabled: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
    marginTop: '10px',
    height: '180px !important',
    width: '180px !important',
    color: '#707070 !important',
  },
  typeOptionIcon: {
    fontSize: '40px',
  },
  typeOptionText: {
    marginTop: '13px',
  },
  selection: {
    width: '16px !important',
    height: '16px !important',
    borderRadius: '100%',
    backgroundColor: theme.backgroundColor,
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: 'unset',
    border: `1px solid ${theme.selectedHover}`,
    '&.selected': {
      width: '17px !important',
      height: '17px !important',
      color: theme.selectedColor,
      border: 'none',
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    typeOption: {
      height: '90px !important',
      width: '90px !important',
    },
    typeOptionIcon: {

      fontSize: '25px',
    },
    typeOptionText: {

      marginTop: '13px',
      fontSize: '12px',
    },
  },
}));
