import React, { useCallback, useMemo, useState } from 'react';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import lang from 'lang';
import { simpleCompare } from 'utils';

import DraggableOrderItem from '../../DraggableOrderItem';
import OrderItem from '../../OrderItem';
import FlowStep from '../FlowStep';
import Button from 'components/common/Button';

import useStyles from './styles';

const AskForOrderItems = ({ number, orderItems, setOrderItems, restricted }) => {
  const classes = useStyles();
  const [active, setActive] = useState(null);

  const sortedOrderItems = useMemo(() => orderItems.sort((a, b) => simpleCompare(a.order, b.order)), [orderItems]);

  const addItem = () => {
    setOrderItems(items => {
      return [
        ...items,
        {
          id: v4(),
          text: {},
          order: items.length,
          isCorrect: false,
          identifier: `order_${items.length + 1}`,
        },
      ];
    });
  };

  const deleteItem = (itemId) => {
    if (orderItems.length < 3) {
      return;
    }

    setOrderItems(items => {
      return items.filter((item) => item.id !== itemId);
    });
  };

  const updateItem = (itemId, value) => {
    setOrderItems(items =>
      items.map(item => item.id === itemId ? { ...item, text: value } : item),
    );
  };

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    setActive(null);

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = sortedOrderItems.findIndex(el => el.id === active.id);
      const newIndex = sortedOrderItems.findIndex(el => el.id === over.id);

      const newItems = arrayMove(sortedOrderItems, oldIndex, newIndex)
        .map((item, index) => ({
          ...item,
          order: index,
        }));

      setOrderItems(newItems);
    }
  }, [setOrderItems, sortedOrderItems]);

  const handleDragStart = (event) => {
    const { active } = event;
    setActive({
      item: orderItems.find(el => el.id === active.id),
      index: orderItems.map(el => el.id).indexOf(active.id),
    });
  };

  return (
    <FlowStep
      stepNumber={number}
      header={lang.exerciseForm.itemstoOrder}
      subHeader={lang.exerciseForm.itemsToOrderTip}
    >
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={sortedOrderItems}
          strategy={verticalListSortingStrategy}
        >
          <div className={classes.choicesContainer}>
            {sortedOrderItems.map((item, index) => (
              <DraggableOrderItem
                key={item.id}
                id={item.id}
                item={item}
                index={index}
                updateItem={updateItem}
                deleteItem={deleteItem}
                itemsLength={sortedOrderItems.length}
                restricted={restricted}
              />
            ))}
            {!restricted &&
            <Button onClick={addItem}>
              <FontAwesomeIcon icon={faPlus} />
              <div className={classes.newChoiceText}>
                {lang.exerciseForm.addItem}
              </div>
            </Button>
            }
          </div>
        </SortableContext>
        <DragOverlay>
          {active
            ? (
              <OrderItem
                item={active.item}
                index={active.index}
                updateItem={updateItem}
                restricted={restricted}
                isDragging
              />
            )
            : null}
        </DragOverlay>
      </DndContext>
    </FlowStep>
  );
};

AskForOrderItems.propTypes = {
  number: PropTypes.number,
  orderItems: PropTypes.array,
  setOrderItems: PropTypes.func,
  restricted: PropTypes.bool,
};

export default AskForOrderItems;
