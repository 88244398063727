import React from 'react';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';
import { exerciseTypeIcon, exerciseTypeString, renderQuill } from 'utils';

import SettingsInput from '../../SettingsInput';
import Card from 'components/common/Card';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const AnswerZoneCard = ({ exercise, getValue, num, setValue, getErrors, previewExercise }) => {
  const classes = useStyles();

  const getLabel = () => {
    if (exercise.type === 'information') {
      return lang.test.informationBlock;
    } else if (exercise.type === 'pause') {
      return lang.test.exercises.pauseItem;
    } else {
      return `${lang.test.question} ${num}`;
    }
  };

  return (
    <Card
      header={getLabel()}
      width="100%"
      className={classes.exerciseCard}
    >
      <div className={classes.answerZone}>
        <div className={classes.exerciseInfoContainer}>
          <div className={classes.exerciseInfo}>
            <div className={classes.typeWrapper}>
              <FontAwesomeIcon
                icon={exerciseTypeIcon(exercise.type)}
              />
              <div className={classes.type}>
                {exerciseTypeString(exercise.type)}
              </div>
            </div>

            <div
              className={classes.exerciseStatement}
              dangerouslySetInnerHTML={{ __html: renderQuill(exercise.statement, {}, { preview: true }) }}
            />
          </div>
          <div className={classes.previewExercise}>
            <Tooltip tip={lang.preview} place={'left'}>
              <div
                className={classes.actionWrap}
                onClick={previewExercise}
              >
                <FontAwesomeIcon
                  icon={faEye}
                  className={classes.actionBtn}
                />
              </div>
            </Tooltip>
          </div>
        </div>

        <Render when={exercise.type === 'text' || exercise.type === 'image' || (exercise.type === 'choices' && exercise.hasJustification) || (exercise.type === 'true-false' && exercise.hasJustification)}>
          <Table className={classes.inputTable}>
            <SettingsInput
              type="number"
              label={(exercise.type === 'choices' || exercise.type === 'true-false') ? lang.test.physicalVersion.numberOfLinesJustify : lang.test.physicalVersion.numberOfLines}
              placeholder={'Number of lines'}
              onChange={event => setValue(exercise.testExerciseId, { ...getValue(exercise.testExerciseId), value: parseInt(event.target.value) })}
              value={getValue(exercise.testExerciseId).enabled ? getValue(exercise.testExerciseId).value : ''}
              minSize={1}
              errors={getErrors(exercise.testExerciseId)}
              invalid={getErrors(exercise.testExerciseId).length !== 0}
              hasTogglers
              canBeEnabled
              isEnabled={getValue(exercise.testExerciseId).enabled}
              onEnable={value => setValue(exercise.testExerciseId, { ...getValue(exercise.testExerciseId), enabled: value })}
            />
            <Render when={getValue(exercise.testExerciseId).enabled}>
              <SettingsInput
                type="toggle"
                label={lang.test.physicalVersion.showLines}
                hasTogglers
                canBeEnabled
                isEnabled={getValue(exercise.testExerciseId).showLines}
                onEnable={value => setValue(exercise.testExerciseId, { ...getValue(exercise.testExerciseId), showLines: value })}
              />
            </Render>
          </Table>
        </Render>
      </div>
    </Card>
  );
};

AnswerZoneCard.propTypes = {
  exercise: PropTypes.object.isRequired,
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getErrors: PropTypes.func.isRequired,
  num: PropTypes.number.isRequired,
  previewExercise: PropTypes.func.isRequired,
};

export default AnswerZoneCard;
