import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setFilters, setOrder, setSearch, USAGES_ORDER } from 'actions/exploreActions';
import { selectUserSubjects, selectUserYears } from 'actions/userActions';
import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';

import ExploreExercisesList from 'components/explore/ExploreExercisesList';
import ExploreHeader from 'components/explore/ExploreHeader';

const Explore = () => {
  const { loaded, pageLoading } = usePageLogic(lang.explore.title, true);
  const dispatch = useDispatch();
  const userSubjects = useSelector(selectUserSubjects);
  const userYears = useSelector(selectUserYears);

  useEffect(() => {
    const subjects = userSubjects.map(subject => ({
      ...subject,
      color: '#CB6EB7',
      hoverColor: '#B4419B',
      type: 'subject',
    }));

    const years = userYears.map(year => ({
      ...year,
      color: '#F79649',
      hoverColor: '#EB6F0A',
      type: 'year',
    }));

    dispatch(setFilters([...subjects, ...years]));
    dispatch(setSearch(''));
    dispatch(setOrder(USAGES_ORDER));
    loaded();
  }, [dispatch, loaded, userSubjects, userYears]);

  if (pageLoading) {
    return null;
  }

  return (
    <div style={{ backgroundColor: '#FFFFFF' }}>
      <ExploreHeader />
      <ExploreExercisesList />
    </div>
  );
};

export default Explore;
