const addAttempts = (attempts, fileDataWithHeader, maxTotal, hasSections, hasRubric) => {
  const fileDataWithAttempts = [...fileDataWithHeader];

  for (let i = 0; i < attempts.length; i++) {
    const attempt = attempts[i];
    const attemptData = [attempt.fullName];
    let total = 0;

    attempt.items.forEach(item => {

      if (!hasSections && !hasRubric) {
        item.grade = parseFloat(item.grade);
        attemptData.push(item.grade);
        total += item.grade;
      }

      if (hasRubric && !hasSections) {
        item.criteriaGrades.filter(grade => grade.weight !== 0).forEach(criteria => {
          attemptData.push(criteria.grade);
        });
        item.grade = parseFloat(item.grade);
        attemptData.push(item.grade);
        total += item.grade;
      }

      if (hasRubric && hasSections && item.itemType === 'section' && item.exercises.length > 0) {
        item.exercises.forEach(ex => {

          ex.criteriaGrades.filter(grade => grade.weight !== 0).forEach(criteria => {
            attemptData.push(criteria.grade);
          });
          ex.grade = parseFloat(ex.grade);
          attemptData.push(ex.grade);
          total += ex.grade;
        });
      }

      if (hasRubric && hasSections && item.itemType === 'exercise') {
        item.criteriaGrades.filter(grade => grade.weight !== 0).forEach(criteria => {
          attemptData.push(criteria.grade);
        });
        item.grade = parseFloat(item.grade);
        attemptData.push(item.grade);
        total += item.grade;
      }

      if (!hasRubric && hasSections && item.itemType === 'section' && item.exercises.length > 0) {
        item.exercises.forEach(ex => {
          ex.grade = parseFloat(ex.grade);
          attemptData.push(ex.grade);
          total += ex.grade;
        });
      }

      if (!hasRubric && hasSections && item.itemType === 'exercise') {
        item.grade = parseFloat(item.grade);
        attemptData.push(item.grade);
        total += item.grade;
      }

    });

    attemptData.push(total);
    const totalPercentage = ((total / maxTotal) * 100).toFixed(2);
    attemptData.push(`${totalPercentage}%`);

    fileDataWithAttempts.push(attemptData);

  }

  return fileDataWithAttempts;
};
export default addAttempts;
