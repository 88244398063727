import React, { useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import api from 'api';
import { MAX_SIZE_10MB } from 'constants/fileMaxSizes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import FlowStep from '../FlowStep';
import Input from 'components/common/Input';

import useStyles from './styles';

const AskForImage = ({ number, image, setImage }) => {
  const classes = useStyles();
  const toast = useToast();
  const [uploadFileRequest] = useApi(api.uploadFile);

  const onDrop = useCallback((filesToUpload) => {
    const file = filesToUpload[0];

    if (!file) {
      toast.error(lang.exerciseForm.askForImageError);
      return;
    }

    if (file.size > MAX_SIZE_10MB) {
      toast.warning(lang.fileTooLarge);
      return;
    }

    uploadFileRequest([], { file }, ({ data }) => {
      if (data.status === 0) {
        setImage(`${process.env.REACT_APP_S3_URL}/${data.key}`);
        return;
      }

      toast.error(lang.oops);
    });
  }, [uploadFileRequest, toast, setImage]);

  return (
    <FlowStep
      stepNumber={number}
      header={lang.exerciseForm.askForImage}
    >
      <div className={classes.wrapper}>
        <Input
          type="file"
          value={image ? [image] : []}
          placeholder={lang.test.dragImageHint}
          accept={['image/*']}
          onDrop={onDrop}
        />
      </div>
    </FlowStep>
  );
};

AskForImage.propTypes = {
  number: PropTypes.number,
  image: PropTypes.array,
  setImage: PropTypes.func,
};

export default AskForImage;
