import lang from 'lang';
import { buildStep, buildOptions } from 'services/shepherd/utils';

export const options = buildOptions('rubrics');

export const steps = (isOnRubricsPage) => [
  {
    id: 'sidebar-rubrics',
    attachOn: 'right',
    ...(
      isOnRubricsPage
        ? {
          buttons: ['next'],
          text: lang.tours.rubrics.steps.sidebar,
        }
        : {
          text: lang.tours.rubrics.steps.sidebarGoToPage,
          advanceOnClick: true,
        }
    ),
  },
  {
    id: 'create-new-rubric-button',
    text: lang.tours.rubrics.steps.createNewButton,
    attachOn: 'bottom-left',
    advanceOnClick: true,
    buttons: ['back'],
  },
  {
    id: 'rubric-form-step-general-name',
    text: lang.tours.rubrics.steps.nameInput,
    attachOn: 'bottom',
    canClickTarget: true,
    buttons: ['back', 'next'],
  },
  {
    id: 'rubric-form-step-general-description',
    text: lang.tours.rubrics.steps.descriptionInput,
    attachOn: 'bottom',
    canClickTarget: true,
    buttons: ['back', 'next'],
  },
  {
    id: 'rubric-criteria-step',
    text: lang.tours.rubrics.steps.criteriaCard,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'rubric-criteria-step-criteria-0-name',
    text: lang.tours.rubrics.steps.criteria0NameInput,
    canClickTarget: true,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'rubric-criteria-step-criteria-0-table',
    text: lang.tours.rubrics.steps.criteria0ValueInput,
    canClickTarget: true,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'rubric-criteria-step-add-criteria-button',
    text: lang.tours.rubrics.steps.addCriteriaButton,
    advanceOnClick: true,
    attachOn: 'right',
    buttons: ['back'],
  },
  {
    id: 'rubric-criteria-step-criteria-1-name',
    text: lang.tours.rubrics.steps.criteria1NameInput,
    attachOn: 'bottom',
    canClickTarget: true,
    buttons: ['back', 'next'],
  },
  {
    id: 'rubric-criteria-step-criteria-1-table',
    text: lang.tours.rubrics.steps.criteria1ValueInput,
    attachOn: 'bottom',
    canClickTarget: true,
    buttons: ['back', 'next'],
  },
  {
    id: 'rubric-criteria-step-criteria-1-remove-button',
    text: lang.tours.rubrics.steps.criteria1RemoveButton,
    attachOn: 'left',
    advanceOnClick: true,
    buttons: ['back'],
  },
  {
    id: 'rubric-criteria-step-exercise-example',
    text: lang.tours.rubrics.steps.exerciseExample,
    modal: true,
    buttons: ['next'],
  },
  {
    id: 'rubrics-editor-page-header-group-dropdown',
    text: lang.tours.rubrics.steps.groupDropdown,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'rubrics-editor-page-header-save-button',
    text: lang.tours.rubrics.steps.saveButton,
    attachOn: 'bottom-left',
    advanceOnClick: true,
    buttons: ['back'],
  },
  {
    id: 'rubric-card-0',
    text: lang.tours.rubrics.steps.card,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'rubric-card-0-right',
    text: lang.tours.rubrics.steps.cardActions,
    attachOn: 'left',
    buttons: ['back', 'next'],
  },
  {
    id: 'tour-hub',
    text: lang.tours.rubrics.steps.hub,
    attachOn: 'top-left',
    buttons: ['back', 'complete'],
  },
].map(buildStep);
