import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin, selectUserSubjects, selectUserYears } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';

const useExploreFilters = () => {
  const userSubjects = useSelector(selectUserSubjects);
  const userYears = useSelector(selectUserYears);
  const isAdmin = useSelector(selectUserIsAdmin);
  const [getSubjectsRequest] = useApi(api.getSubjects);
  const [getYearsRequest] = useApi(api.getYears);

  const [filters, setFilters] = useState([]);

  useEffect(() => {
    if (isAdmin) {
      return;
    }

    getSubjectsRequest([], null, ({ data }) => {
      const subjects = data.subjects.map(subject => ({
        ...subject,
        color: '#CB6EB7',
        hoverColor: '#B4419B',
        type: 'subject',
        active: !!userSubjects.find(userSubject => userSubject.id === subject.id),
      }));

      setFilters(filters => [...filters, ...subjects]);
    });

    getYearsRequest([], null, ({ data }) => {
      const years = data.years.map(year => ({
        ...year,
        color: '#F79649',
        hoverColor: '#EB6F0A',
        type: 'year',
        active: !!userYears.find(userYear => userYear.id === year.id),
      }));

      setFilters(filters => [...filters, ...years]);
    });
  }, [getSubjectsRequest, getYearsRequest, userSubjects, userYears, isAdmin]);

  return [filters, setFilters];
};

export default useExploreFilters;
