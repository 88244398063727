import { createUseStyles } from 'react-jss';

export default createUseStyles({
  contentContainer: {
    padding: 0,
    maxHeight: 'unset',
    height: 'unset',
    overflowY: 'unset',
    fontSize: '16px',
  },
});
