import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectAttemptEndedAt, selectAttemptSection } from 'actions/attemptActions';
import { selectTestClassificationType } from 'actions/testActions';
import { selectUserIsAdmin } from 'actions/userActions';
import { quillIsEmpty } from 'utils';

import GradeableExercise from '../GradeableExercise';
import EntityActionsContainer from 'components/common/entity/EntityActionsContainer';
import EntityBody from 'components/common/entity/EntityBody';
import EntityContent from 'components/common/entity/EntityContent';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import EntityHeader from 'components/common/entity/EntityHeader';
import EntitySubHeader from 'components/common/entity/EntitySubHeader';
import EntityTitle from 'components/common/entity/EntityTitle';
import ExerciseGrade from 'components/exercises/exercise/exercise-header/ExerciseGrade';

import useStyles from './styles';

const GradeableSection = ({ sectionId, testHasEnded, setStudents, setCanBeFetch }) => {

  const classes = useStyles();
  const classificationType = useSelector(selectTestClassificationType);
  const endedAt = useSelector(selectAttemptEndedAt);
  const { name, description, exercises, sectionTotal, sectionMaxTotal } = useSelector(selectAttemptSection(sectionId));
  const isAdmin = useSelector(selectUserIsAdmin);

  const getExercises = () => {
    if (!exercises) {
      return null;
    }

    let exerciseNum = 0;
    return exercises.map((exercise) => {
      if (exercise.type !== 'information') {
        exerciseNum++;
      }

      return (
        <div className={classes.gradeableExercise} key={exercise.id}>
          <GradeableExercise
            num={exerciseNum}
            exercise={exercise}
            exerciseId={exercise.id}
            sectionId={sectionId}
            classificationType={classificationType}
            disabled={!testHasEnded && !endedAt}
            setStudents={setStudents}
            setCanBeFetch={setCanBeFetch}
          />
        </div>
      );
    });
  };

  return (
    <EntityBody
      header={name}
      className={classes.section}
    >
      <EntityHeader>
        <EntityTitle
          name={name}
        />
        <EntityActionsContainer>
          {classificationType !== 'none' && !isAdmin && (
            <ExerciseGrade
              grade={sectionTotal}
              maxGrade={sectionMaxTotal}
            />
          )}
        </EntityActionsContainer>
      </EntityHeader>
      {!quillIsEmpty(description) && (
        <EntitySubHeader>
          <EntityExpandableText
            text={description}
          />
        </EntitySubHeader>
      )}
      <EntityContent>
        {getExercises()}
      </EntityContent>
    </EntityBody>
  );
};

GradeableSection.propTypes = {
  sectionId: PropTypes.string,
  testHasEnded: PropTypes.bool,
  setStudents: PropTypes.func,
  setCanBeFetch: PropTypes.func,
};

export default GradeableSection;
