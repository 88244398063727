import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FINISHED, IS_INSIDE, IS_ONGOING, UPCOMING } from 'constants/publicationStages';
import lang from 'lang';

import Countdown from 'components/common/countdown/Countdown';
import CountdownRenderer from 'components/common/countdown/CountdownRenderer';

import useStyles from './styles';

const PublicationHeader = ({ publication, canEnterTest, stage, setStage, currentAttempt }) => {
  const classes = useStyles();
  const clockOffset = useSelector(state => state.page.clockOffset);

  const onCompleteUpcoming = () => {
    if (publication.testType === 'test') {
      setStage(IS_INSIDE);
    } else if (publication.testType === 'worksheet') {
      setStage(IS_ONGOING);
    }
  };

  const getCountdown = () => {
    if (!publication) {
      return null;
    }

    return (
      <div>
        <div className={cx(classes.timerContainer, { hidden: stage === IS_ONGOING && !canEnterTest })}>
          <Render when={stage === UPCOMING}>
            {lang.tests.student.opensIn}
            <Countdown
              date={publication.startsAt + clockOffset}
              renderer={CountdownRenderer}
              onComplete={onCompleteUpcoming}
            />
          </Render>
          <Render when={stage === IS_INSIDE}>
            {lang.tests.student.openingClosesAt}
            <Countdown
              date={publication.windowClosesAt + clockOffset}
              renderer={CountdownRenderer}
              onComplete={() => setStage(IS_ONGOING)}
            />
          </Render>
          <Render when={stage === IS_ONGOING}>
            {lang.tests.student.endsAt}
            <Countdown
              date={currentAttempt ? currentAttempt.endsAt + clockOffset : publication.endsAt + clockOffset}
              renderer={CountdownRenderer}
              onComplete={() => setStage(FINISHED)}
            />
          </Render>
          <Render when={stage === FINISHED}>
            {lang.finished}
          </Render>
        </div>
        <Render when={stage === IS_ONGOING && !canEnterTest}>
          {lang.publication.testWindowClosedInfo}
        </Render>
      </div>
    );
  };

  return (
    <div>
      <div className={classes.loginHeader}>
        <div className={classes.loginTitle}>
          {publication ? publication.testName : ''}
        </div>
        <div className={classes.loginSubtitle}>
          {publication ? publication.teacherName : ''}
        </div>
      </div>
      {getCountdown()}
    </div>
  );
};

PublicationHeader.propTypes = {
  publication: PropTypes.object,
  currentAttempt: PropTypes.object,
  canEnterTest: PropTypes.bool,
  stage: PropTypes.number,
  setStage: PropTypes.func,
};

export default PublicationHeader;
