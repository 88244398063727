import React, { useCallback, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { decrementTotalRubrics } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const DeleteRubricsModal = ({ open, close, templateRubricIds, refresh }) => {
  const toast = useToast();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [deleteTemplateRubricsRequest] = useApi(api.deleteTemplateRubrics);
  const dispatch = useDispatch();

  const _close = useCallback(() => {
    close();
    setTimeout(350);
  }, [close]);

  const deleteRubric = () => {
    setLoading(true);
    deleteTemplateRubricsRequest([], { templateRubricIds }, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        toast.success(templateRubricIds.length === 1 ? lang.rubrics.successDeleteRubric : lang.rubrics.successDeleteRubrics);
        setTimeout(() => {
          dispatch(decrementTotalRubrics(templateRubricIds.length));
          refresh();
          close();
        }, 350);
        return;
      }

      setTimeout(() => {
        toast.error(lang.oops);
      }, 200);
    });
  };

  const getActions = () => {
    return [
      {
        name: lang.delete,
        color: 'red',
        loading: loading,
        onClick: deleteRubric,
      },
      {
        name: lang.cancel,
        color: 'black',
        onClick: _close,
      },
    ];
  };

  return (
    <div
      className={classes.deleteButton}
      onClick={(event) => event.stopPropagation()}
    >
      <Modal
        open={open}
        close={_close}
        header={templateRubricIds.length === 1 ? lang.rubrics.deleteRubric : lang.rubrics.deleteRubrics}
        actions={getActions()}
        center
        transition
        small
      >
        <div className={classes.deleteRubricInfo}>
          {templateRubricIds.length === 1 ? lang.rubrics.deleteRubricInformation : lang.rubrics.deleteRubricsInformation}
        </div>
      </Modal>
    </div>
  );
};

DeleteRubricsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  templateRubricIds: PropTypes.array,
  refresh: PropTypes.func,
};

export default DeleteRubricsModal;
