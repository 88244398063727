import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  gapContainer: {
    padding: '15px 13px',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25em',
    width: '50%',
  },
}));
