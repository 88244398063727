import React, { useCallback, useMemo } from 'react';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { Spacer } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import useExploreFilters from 'hooks/explore/useExploreFilters';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { sortPreferencesByActive } from 'utils';

import FilterPool from 'components/common/filter-selector/FilterPool';
import Input from 'components/common/Input';
import CardSelector from 'components/test/card-selector/CardSelector';

import useStyles from './styles';

const ExerciseSettingsForm = ({ exerciseSettings, setExerciseSettings, titleErrors, subjectsErrors, yearsErrors }) => {
  const classes = useStyles();
  const [filters] = useExploreFilters();
  const exploreToggle = useFeature(toggles.explore);

  const privacyOptions = useMemo(() => ([
    {
      value: 'public',
      label: lang.exerciseForm.exerciseSettings.public,
      tip: lang.exerciseForm.exerciseSettings.publicTip,
      icon: faLockOpen,
    },
    {
      value: 'private',
      label: lang.exerciseForm.exerciseSettings.private,
      tip: lang.exerciseForm.exerciseSettings.privateTip,
      icon: faLock,
      dataTour: 'exercise-settings-form-visibility-private',
    },
  ]), []);

  const privacyType = useMemo(() => {
    if (exerciseSettings.isPublic) {
      return privacyOptions[0];
    } else {
      return privacyOptions[1];
    }
  }, [exerciseSettings, privacyOptions]);

  const toggleSubject = useCallback((selectedSubject) => {
    const isActive = !!exerciseSettings.subjects.find(subjectId => subjectId === selectedSubject.id);
    let newSelectedSubjects;
    if (isActive) {
      newSelectedSubjects = exerciseSettings.subjects.filter(subjectId => subjectId !== selectedSubject.id);
    } else {
      newSelectedSubjects = [...exerciseSettings.subjects, selectedSubject.id];
    }

    setExerciseSettings({
      ...exerciseSettings,
      subjects: newSelectedSubjects,
    });
  }, [exerciseSettings, setExerciseSettings]);

  const toggleYear = useCallback((selectedYear) => {
    const isActive = !!exerciseSettings.years.find(yearId => yearId === selectedYear.id);
    let newSelectedYears;
    if (isActive) {
      newSelectedYears = exerciseSettings.years.filter(yearId => yearId !== selectedYear.id);
    } else {
      newSelectedYears = [...exerciseSettings.years, selectedYear.id];
    }

    setExerciseSettings({
      ...exerciseSettings,
      years: newSelectedYears,
    });
  }, [exerciseSettings, setExerciseSettings]);

  const subjects = useMemo(() => filters
    .filter(filter => filter.type === 'subject')
    .map(filter => {
      const isActive = !!exerciseSettings.subjects.find(el => el === filter.id);

      return ({
        ...filter,
        type: 'action',
        active: isActive,
        color: isActive ? filter.color : null,
        hoverColor: isActive ? filter.hoverColor : null,
        action: () => toggleSubject(filter),
      });
    }), [exerciseSettings, filters, toggleSubject]);

  const years = useMemo(() => filters
    .filter(filter => filter.type === 'year')
    .map(filter => {
      const isActive = !!exerciseSettings.years.find(el => el === filter.id);

      return ({
        ...filter,
        type: 'action',
        active: isActive,
        color: isActive ? filter.color : null,
        hoverColor: isActive ? filter.hoverColor : null,
        action: () => toggleYear(filter),
      });
    }), [exerciseSettings, filters, toggleYear]);

  return (
    <div>
      <Input
        dataTour="exercise-settings-form-title"
        type="text"
        value={exerciseSettings.title}
        label={lang.exerciseForm.exerciseSettings.title}
        placeholder={lang.exerciseForm.exerciseSettings.title}
        onChange={(event) => setExerciseSettings({
          ...exerciseSettings,
          title: event.target.value,
        })}
        maxSize={100}
        errors={titleErrors}
      />
      <Render when={exploreToggle}>
        <Spacer px={15} />
        <CardSelector
          dataTour="exercise-settings-form-visibility"
          headerLabel={lang.exerciseForm.exerciseSettings.whoCanSee}
          options={privacyOptions}
          selected={privacyType}
          setSelected={() => setExerciseSettings({
            ...exerciseSettings,
            isPublic: !exerciseSettings.isPublic,
          })}
        />
        <Render when={exerciseSettings.isPublic}>
          <Spacer px={15} />
          <div className={classes.areas}>
            <div className={classes.label}>
              {lang.exerciseForm.exerciseSettings.areas}
            </div>
            <FilterPool
              poolItems={sortPreferencesByActive(subjects)}
              numberOfVisibleItems={10}
            />
            <Render when={subjectsErrors?.length !== 0}>
              <div className={classes.error}>
                {subjectsErrors?.toString()}
              </div>
            </Render>
          </div>
          <Spacer px={15} />
          <div className={classes.years}>
            <div className={classes.label}>
              {lang.exerciseForm.exerciseSettings.years}
            </div>
            <FilterPool
              poolItems={sortPreferencesByActive(years)}
              numberOfVisibleItems={10}
            />
            <Render when={yearsErrors?.length !== 0}>
              <div className={classes.error}>
                {yearsErrors?.toString()}
              </div>
            </Render>
          </div>
        </Render>
      </Render>
    </div>
  );
};

ExerciseSettingsForm.propTypes = {
  exerciseSettings: PropTypes.object,
  setExerciseSettings: PropTypes.func,
  titleErrors: PropTypes.array,
  subjectsErrors: PropTypes.array,
  yearsErrors: PropTypes.array,
};

export default ExerciseSettingsForm;
