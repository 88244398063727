import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/common/Card';

import useStyles from './styles';

const RubricFormStep = ({ header, dataTour, children }) => {
  const classes = useStyles();

  return (
    <Card
      dataTour={dataTour}
      header={header}
      width="100%"
    >
      <div className={classes.stepContainer}>
        {children}
      </div>
    </Card>
  );
};

RubricFormStep.propTypes = {
  header: PropTypes.string,
  dataTour: PropTypes.string,
  children: PropTypes.any,
};

export default RubricFormStep;
