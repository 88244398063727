import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import { DOCUMENT_DOWNLOAD, DOCUMENT_GENERATION, DOCUMENT_IDLE } from '../PhysicalTab/constants';
import Modal from 'components/common/Modal';
import DownloadDocument from 'components/test/DownloadDocument';
import LoadingDocumentGeneration from 'components/test/LoadingDocumentGeneration';

const AssessmentExportModal = ({ open, close, documentURL, documentState, setDocumentState }) => {
  const _close = () => {
    setTimeout(() => {
      setDocumentState(DOCUMENT_IDLE);
    }, 300);
    close();
  };

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: _close,
    },
  ];

  return (
    <Modal
      open={open}
      close={_close}
      header={lang.test.physicalVersion.exportTest}
      actions={actions}
      center
      transition
      small
    >
      <Render when={documentState === DOCUMENT_GENERATION}>
        <LoadingDocumentGeneration />
      </Render>
      <Render when={documentState === DOCUMENT_DOWNLOAD}>
        <DownloadDocument
          documentURL={documentURL}
        />
      </Render>
    </Modal>
  );
};

AssessmentExportModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  documentURL: PropTypes.string,
  documentState: PropTypes.string,
  setDocumentState: PropTypes.func,
};

export default AssessmentExportModal;
