import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import { v4 } from 'uuid';

import api from 'api';
import { highlightColors } from 'constants/annotationColors';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import { quillIsEmpty } from 'utils';

import CommentBox from '../CommentBox';
import { useAttemptContext } from '../CorrectionTab/context';
import AnnotationBlot from 'components/common/rich-text/RichText/AnnotationBlot';
import { SAVING, SUCCESS } from 'components/exercises/exercise/exercise-header/ExerciseSavingSpinner/states';

import useStyles from './styles';

const UPWARDS = 'upwards';
const DOWNWARDS = 'downwards';

const UseAnnotations = ({
  exerciseId,
  setSavingState,
  textAnnotations,
  id,
  newQuillAnswer,
  setNewQuillAnswer,
  sectionId,
  exerciseRef,
  selectedAnnotation,
  setSelectedAnnotation,
  annotate,
  setAnnotate,
}) => {
  const classes = useStyles();

  const toast = useToast();
  const [editAnswerRequest] = useApi(api.editAnswer);
  const { commenting, commentingExerciseId, setCommenting, setTextAnnotationHovered, setTextAnnotationFocused, focusedTextAnnotationId, hoveredTextAnnotationId, editingComment } = useAttemptContext();

  const annotationsRef = useRef(null);
  const [highlightColor, setHighlightColor] = useState(highlightColors[highlightColors.length - 1].name);
  const [annotationsDirection, setAnnotationsDirection] = useState(DOWNWARDS);
  const [annotations, setAnnotations] = useState(textAnnotations ?? []);
  const [addComment, setAddComment] = useState(false);

  useEffect(() => {
    if (commenting && exerciseRef.current && annotationsRef.current) {
      const exerciseRect = exerciseRef.current.getBoundingClientRect();
      const annotationsHeight = annotationsRef.current.offsetHeight;

      const scrollY = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight;

      const exerciseBottomPosition = exerciseRect.bottom + scrollY;

      const spaceBelow = pageHeight - exerciseBottomPosition;

      if (spaceBelow < annotationsHeight) {
        setAnnotationsDirection(UPWARDS);
      } else {
        setAnnotationsDirection(DOWNWARDS);
      }
    }
  }, [commenting, exerciseRef]);

  const handleColorChange = (color) => {
    setHighlightColor(color);

    if (selectedAnnotation) {
      setNewQuillAnswer(newQuillAnswer.ops.map(op => {
        if (op.attributes && op.attributes.annotation?.id.includes(selectedAnnotation)) {
          return {
            insert: op.insert,
            attributes: {
              ...op.attributes,
              annotation: {
                ...op.attributes.annotation,
                color: color,
              },
            },
          };
        }

        return op;
      }));

      setAnnotate(true);
    }
  };

  const onChangeAnnotation = useCallback(() => {
    setSavingState(SAVING);

    editAnswerRequest([id], { quillAnswer: newQuillAnswer, annotations }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
          setAnnotate(!annotate);
          AnnotationBlot.setAllInactive();
        } else {
          toast.error(lang.oops);
        }

      }, 300);
    });

  }, [setSavingState, editAnswerRequest, id, newQuillAnswer, annotations, setAnnotate, annotate, toast]);

  const handleCommentButtonClick = () => {
    if (selectedAnnotation) {
      setCommenting(true, exerciseId);
      setAddComment(true);
    }
  };

  const handleSaveComment = (textAnnotationId, newComment) => {
    if (quillIsEmpty(newComment)) {
      toast.warning(lang.test.grades.emptyCommentError);
      return;
    }

    const newAnnotation = {
      id: textAnnotationId,
      comment: newComment,
    };

    const updatedAnnotations = annotations.filter(annotation => annotation.id !== textAnnotationId);
    updatedAnnotations.push(newAnnotation);
    setAnnotations(updatedAnnotations);

    setCommenting(false);
    setAddComment(false);
    setAnnotate(true);
    setSelectedAnnotation(null);
    AnnotationBlot.setAllDefault();
    setTextAnnotationHovered(null);
  };

  const handleAnnotateClick = useCallback(() => {
    if (annotate) {
      onChangeAnnotation();
    } else {
      setAnnotate(!annotate);
      setCommenting(false);
      setAddComment(false);
    }
  }, [annotate, onChangeAnnotation, setAnnotate, setCommenting]);

  const handleClickViewComments = useCallback(() => {
    if (commenting && commentingExerciseId === exerciseId) {
      setCommenting(false);
      setTextAnnotationFocused(null);
      AnnotationBlot.setAllDefault();
      return;
    }

    setCommenting(true, exerciseId);
  }, [commenting, commentingExerciseId, exerciseId, setCommenting, setTextAnnotationFocused]);

  const onSelectionChange = useCallback((range, editor) => {
    if (editingComment) {
      return;
    }

    if (!annotate && !addComment) {
      AnnotationBlot.setAllDefault();
      setTextAnnotationFocused(null);
      setSelectedAnnotation(null);
    }

    if (range?.length === 0) {
      const contents = editor.getContents(range.index);
      const op = contents.ops[0];
      if (op && op.attributes?.annotation) {
        AnnotationBlot.setAllDefault();

        const idsString = op.attributes.annotation.id;
        const ids = idsString.split('|');

        const onlyOverlappedId = ids.find(id => {
          const blots = document.querySelectorAll(`[data-id*="${id}"]`);
          return blots.length === 1;
        });

        const id = onlyOverlappedId ?? ids[ids.length - 1];
        if (!annotate) {
          AnnotationBlot.setAllUnfocused();
        }
        if (selectedAnnotation !== id) {
          setAddComment(false);
        }
        AnnotationBlot.setIsActive(id, true);
        setTextAnnotationFocused(id);
        setSelectedAnnotation(id);
        setHighlightColor(op.attributes.annotation.color);
        if (annotations.some(annotation => annotation.id === id)) {
          setCommenting(true, exerciseId);
        }
      } else if (commenting) {
        AnnotationBlot.setAllDefault();
        setTextAnnotationFocused(null);
        setSelectedAnnotation(null);
      }
    }

    if (range && range.length !== 0 && annotate) {
      AnnotationBlot.setAllDefault();
      const textAnnotationId = v4();
      const contents = editor.getContents(range.index, range.length);
      let opsProcessed = 0;

      contents.ops.forEach(op => {
        const length = (typeof op.insert === 'string') ? op.insert.length : 1;

        if (op.attributes && op.attributes.annotation) {
          const oldId = op.attributes.annotation.id;

          const combinedId = `${oldId}|${textAnnotationId}`;
          editor.formatText(range.index + opsProcessed, length, 'annotation', false);

          editor.formatText(range.index + opsProcessed, length, 'annotation', {
            id: combinedId,
            color: highlightColor,
          });

          AnnotationBlot.setIsActive(combinedId, true);
        } else {
          editor.formatText(range.index + opsProcessed, length, 'annotation', {
            id: textAnnotationId,
            color: highlightColor,
          });

          AnnotationBlot.setIsActive(textAnnotationId, true);
        }

        opsProcessed += length;
      });

      AnnotationBlot.setIsActive(textAnnotationId, true);

      setSelectedAnnotation(textAnnotationId);
    }
  }, [addComment, annotate, annotations, commenting, editingComment, exerciseId, highlightColor, selectedAnnotation, setCommenting, setSelectedAnnotation, setTextAnnotationFocused]);

  const annotationsSorted = annotations
    .reduce((annotations, annotation) => [
      ...(annotation.id === focusedTextAnnotationId || (!focusedTextAnnotationId && annotation.id === hoveredTextAnnotationId) ? [annotation] : []),
      ...annotations,
      ...((focusedTextAnnotationId && annotation.id !== focusedTextAnnotationId) || (annotation.id !== focusedTextAnnotationId && annotation.id !== hoveredTextAnnotationId) ? [annotation] : []),
    ], [])
    .filter(annotation => !annotate || annotation.id === selectedAnnotation);

  const commentBox = commenting && commentingExerciseId === exerciseId ? (
    <div
      ref={annotationsRef}
      className={cx(classes.commentBoxContainer, { isInsideSection: !!sectionId, isUpwards: annotationsDirection === UPWARDS })}
    >
      {addComment && !annotations.some(annotation => annotation.id === selectedAnnotation) && (
        <CommentBox
          textAnnotationId={selectedAnnotation}
          annotate={annotate}
          handleSaveComment={handleSaveComment}
          startEditing
          setAddComment={setAddComment}
          canEdit
        />
      )}
      {(annotationsDirection === DOWNWARDS ? annotationsSorted : annotationsSorted.reverse())
        .map(annotation => (
          <CommentBox
            key={annotation.id}
            annotate={annotate}
            textAnnotationId={annotation.id}
            comment={annotation.comment}
            handleSaveComment={handleSaveComment}
            setAnnotations={setAnnotations}
            setAnnotate={setAnnotate}
            canEdit
          />
        ))}
    </div>
  ) : false;

  const onMouseOver = (event) => {
    if (annotate) {
      return;
    }

    if (event.target.className.includes?.('ql-annotation')) {
      const idsString = event.target.getAttribute('data-id');
      const ids = idsString.split('|');

      const onlyOverlappedId = ids.find(id => {
        const blots = document.querySelectorAll(`[data-id*="${id}"]`);
        return blots.length === 1;
      });

      const id = onlyOverlappedId ?? ids[ids.length - 1];

      if (!focusedTextAnnotationId) {
        AnnotationBlot.setAllUnfocused();
        AnnotationBlot.setIsActive(id, true);
      }
      setTextAnnotationHovered(id);
    }
  };

  const onMouseOut = (event) => {
    if (annotate) {
      return;
    }

    if (event.target.className.includes?.('ql-annotation')) {
      if (!focusedTextAnnotationId) {
        AnnotationBlot.setAllDefault();
      }
      setTextAnnotationHovered(null);
    }
  };

  return {
    onSelectionChange,
    onMouseOver,
    onMouseOut,
    handleAnnotateClick,
    handleCommentButtonClick,
    handleColorChange,
    commentBox,
    addComment,
    annotate,
    setAnnotate,
    highlightColor,
    annotations,
    setAnnotations,
    handleClickViewComments,
  };
};

export default UseAnnotations;
