import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const EntityActionsContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.actions}>
      {children}
    </div>
  );
};

EntityActionsContainer.propTypes = {
  children: PropTypes.any.isRequired,
};

export default EntityActionsContainer;
