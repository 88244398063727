import { createUseStyles } from 'react-jss';

export default createUseStyles({
  exerciseListHeader: {
    width: 'calc(100% - 20px)',
    padding: '20px 10px 15px 10px',
    fontWeight: 600,
    fontSize: '25px',
  },
  headerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  orderContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '17px',
  },
  selectInput: {
    fontWeight: '400',
    width: '200px',
  },
  exerciseListHeaderSeparator: {
    borderBottom: '1px solid #3d3d3d',
    marginTop: '15px',
  },
});
