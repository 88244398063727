import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
  text: {
    marginLeft: '5px',
  },
}));
