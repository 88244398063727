import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  connectorWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    gap: '0.2em',
  },
  outerRing: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '0.25em',
    border: `2px solid ${theme.white}`,
    cursor: 'pointer',
    '&:hover': {
      border: `2px solid ${theme.buttonBackground2}`,
    },
  },
  innerRing: {
    width: '1em',
    height: '1em',
    borderRadius: '50%',
    backgroundColor: '#595959CC',
    '&.active': {
      backgroundColor: theme.buttonBackground2,
    },
    '&.used': {
      backgroundColor: theme.buttonBackground2,
    },
  },
  deleteBtn: {
    fontSize: '20px',
    cursor: 'pointer',
    color: theme.removeColor,
    '&.disabled': {
      color: theme.unselectedColor2,
      cursor: 'not-allowed',
    },
  },
  optionInput: {
    '& .ql-toolbar': {
      border: `1px solid ${theme.cardBorder3}`,
      padding: '2px',
    },
  },
}));
