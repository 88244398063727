import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserFeatureToggleMaxUsages } from 'actions/userActions';
import api from 'api';
import { CONNECTING, INFORMATION, PAUSE, SEGMENTATION } from 'constants/exerciseTypes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import toggles from 'toggles';

import PublishFormModal from '../PublishFormModal';
import BeforePublish from 'components/test/publish-modal/BeforePublish';
import TestInfo from 'components/test/publish-modal/TestInfo';

import useStyles from './styles';

const PublishModal = ({ open, close, test, afterSubmit, refresh }) => {
  const classes = useStyles();
  const connectingToggle = useFeature(toggles.connecting);
  const segmentationToggle = useFeature(toggles.segmentation);
  const sectionsToggle = useFeature(toggles.sections);
  const sectionsToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.sections));
  const [getTestPublicationRequest] = useApi(api.getTestPublication);

  const [edit, setEdit] = useState(false);
  const [isOnGoing, setIsOnGoing] = useState(false);
  const [publication, setPublication] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (open) {
      setLoading(true);
      getTestPublicationRequest([test.id], null, ({ data }) => {
        if (data.status === 0) {
          setPublication(data.publication);
        }
        setLoading(false);
      });
    }
  }, [open, test, getTestPublicationRequest]);

  const hasExercises = useMemo(() => {
    const flattenedExercises = test.items.flatMap(item => item.itemType === 'section' ? (item.exercises || []) : item).filter(item => ![INFORMATION, PAUSE].includes(item.type));
    return flattenedExercises.length > 0;
  }, [test]);

  const hasConnectingExercise = useMemo(() => {
    const flattenedExercises = test.items.flatMap(item => item.itemType === 'section' ? (item.exercises || []) : item).filter(item => ![INFORMATION, PAUSE].includes(item.type));
    return flattenedExercises.some(exercise => exercise.type === CONNECTING) > 0;
  }, [test]);

  const hasSegmentationExercise = useMemo(() => {
    const flattenedExercises = test.items.flatMap(item => item.itemType === 'section' ? (item.exercises || []) : item).filter(item => ![INFORMATION, PAUSE].includes(item.type));
    return flattenedExercises.some(exercise => exercise.type === SEGMENTATION) > 0;
  }, [test]);

  const sectionsInfo = useMemo(() => {
    const sections = test.items.filter(item => item.itemType === 'section');
    return {
      hasSections: sections.length > 0,
      sectionsHaveExercises: !sections.some(section => (section.exercises ?? []).filter(exercise => ![INFORMATION, PAUSE].includes(exercise.type)).length === 0),
      maxSectionExceeded: !sectionsToggle && sections.length > sectionsToggleMaxUsages,
    };
  }, [test, sectionsToggle, sectionsToggleMaxUsages]);

  const hasRubric = useMemo(() => {
    return !!test.rubric;
  }, [test.rubric]);

  const areWeightsCorrect = useMemo(() => {
    let areWeightsCorrect = true;

    if (test.classificationType === 'rubric') {
      if (test.rubric) {
        const exercises = test.items
          .flatMap(item => item.itemType === 'section' ? (item.exercises || []) : item)
          .filter(exercise => ![INFORMATION, PAUSE].includes(exercise.type));

        for (let i = 0; i < exercises.length; i++) {
          const exercise = exercises[i];
          let sum = 0;

          for (let j = 0; j < exercise.criteriaWeights.length; j++) {
            const criteriaWeight = exercise.criteriaWeights[j];
            sum += criteriaWeight.weight;
          }

          areWeightsCorrect = sum === 100;
          if (!areWeightsCorrect) {
            break;
          }
        }
      }

      areWeightsCorrect = areWeightsCorrect && hasRubric && hasExercises;
    }

    return areWeightsCorrect;
  }, [hasExercises, hasRubric, test.classificationType, test.items, test.rubric]);

  const isPublishable = useMemo(() => {
    if (!connectingToggle || !segmentationToggle) {
      return hasExercises && areWeightsCorrect && sectionsInfo.sectionsHaveExercises && !sectionsInfo.maxSectionExceeded && !hasConnectingExercise && !hasSegmentationExercise;
    }
    return hasExercises && areWeightsCorrect && sectionsInfo.sectionsHaveExercises && !sectionsInfo.maxSectionExceeded;
  }, [hasExercises, areWeightsCorrect, sectionsInfo, hasConnectingExercise, hasSegmentationExercise, connectingToggle, segmentationToggle]);

  const getContent = () => {
    if (test.isPublished && !edit) {
      return (
        <TestInfo
          open={open}
          close={close}
          testType={test.type}
          publication={publication}
          setEdit={setEdit}
          setIsOnGoing={setIsOnGoing}
          action={refresh}
          loading={loading}
        />
      );
    } else if (edit || isPublishable) {
      return (
        <PublishFormModal
          open={open}
          close={close}
          testId={test.id}
          testType={test.type}
          classificationType={test.classificationType}
          publication={publication}
          afterSubmit={afterSubmit}
          refresh={refresh}
          isOnGoing={isOnGoing}
          edit={edit}
          setEdit={setEdit}
        />
      );
    } else {
      return (
        <BeforePublish
          open={open}
          close={close}
          testClassificationType={test.classificationType}
          hasExercises={hasExercises}
          hasConnectingExercise={hasConnectingExercise}
          hasSegmentationExercise={hasSegmentationExercise}
          hasRubric={hasRubric}
          areWeightsCorrect={areWeightsCorrect}
          sectionsInfo={sectionsInfo}
        />
      );
    }
  };

  return (
    <div
      onClick={e => e.stopPropagation()}
      className={classes.container}
    >
      {getContent()}
    </div>
  );
};

PublishModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  test: PropTypes.object,
  afterSubmit: PropTypes.func,
  refresh: PropTypes.func,
};

export default PublishModal;
