import React, { useMemo, useState } from 'react';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faCopy, faEye, faPaperPlane, faPen, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { incrementTotalRubrics, selectUserFeatureToggleMaxUsages, selectUserTotalFreeRubrics } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import DeleteRubricsModal from '../DeleteRubricsModal';
import PreviewRubricModal from '../PreviewRubricModal';
import SendRubricModal from '../SendRubricModal';
import TemplateEntityCard from 'components/common/entity/TemplateEntityCard';
import UpgradePremiumModal from 'components/common/plans/UpgradePremiumModal';

import useStyles from './styles';

const RubricCard = ({ rubric, groups, selectable, selected, check, fetchRubrics, dataTour }) => {
  const classes = useStyles();
  const history = useHistory();
  const { spaceId } = useParams();
  const toast = useToast();
  const [duplicateTemplateRubricsRequest] = useApi(api.duplicateTemplateRubrics);
  const totalFreeRubrics = useSelector(selectUserTotalFreeRubrics);
  const createRubricToggle = useFeature(toggles.createRubric, totalFreeRubrics);
  const createRubricToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createRubric));
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.data.id);

  const [previewRubricModal, setPreviewRubricModal] = useState(false);
  const [deleteRubricModal, setDeleteRubricModal] = useState(false);
  const [duplicateloading, setDuplicateLoading] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [upgradePremiumModal, setUpgradePremiumModal] = useState(false);

  const deleteDisabled = useMemo(() => {
    const isPersonalGroup = groups.find(group => group.id === rubric.groupId && group.isPersonal);
    return rubric.authorId !== userId && !isPersonalGroup;
  }, [rubric, userId, groups]);

  const checkRubric = (event) => {
    const str = event.target.classList.toString();
    if (!(
      str.includes('rubricLeftWrap') ||
      str.includes('rubricInfoWrap') ||
      str.includes('checkboxWrapper') ||
      str.includes('exerciseCheckbox') ||
      str.includes('rubricName')
    )) {
      return;
    }
    check(rubric);
  };

  const duplicateTemplateRubric = () => {
    if (!createRubricToggle) {
      setUpgradePremiumModal(true);
      return;
    }

    setDuplicateLoading(true);
    if (rubric) {
      duplicateTemplateRubricsRequest([], { templateRubricIds: [rubric.id], prefix: lang.copyOf }, ({ data }) => {
        setDuplicateLoading(false);
        if (data.status === 0) {
          dispatch(incrementTotalRubrics(1));
          toast.success(lang.rubrics.successDuplicateRubric);
          fetchRubrics();
          return;
        }

        toast.error(lang.oops);
      });
    }
  };

  let actions, extraActions;
  if (!selectable) {
    actions = [
      {
        icon: faEye,
        label: lang.preview,
        onClick: () => setPreviewRubricModal(true),
        hideMedia: 'xs',
      },
      {
        icon: faPen,
        label: lang.edit,
        onClick: () => history.push({
          pathname: routes.editRubric.ref(spaceId, rubric.id),
          search: `?group=${rubric.groupId}`,
        }),
        hideMedia: 'sm',
      },
      {
        icon: faPaperPlane,
        label: lang.send,
        onClick: () => setShowSendModal(true),
        hideMedia: 'md',
      },
      {
        icon: faCopy,
        label: lang.duplicate,
        onClick: duplicateTemplateRubric,
        loading: duplicateloading,
        hideMedia: 'md',
      },
    ];

    extraActions = [
      {
        icon: faTrash,
        label: lang.delete,
        onClick: () => setDeleteRubricModal(true),
        disabled: deleteDisabled,
        tip: deleteDisabled ? lang.deleteDisabled : null,
      },
    ];
  } else {
    actions = [
      {
        icon: faEye,
        label: lang.preview,
        onClick: () => setPreviewRubricModal(true),
      },
    ];
  }

  return (
    <TemplateEntityCard
      dataTour={dataTour}
      className={cx(classes.rubricCard, { selectable })}
      actions={actions}
      extraActions={extraActions}
      selectable
      onClick={checkRubric}
      isSelected={selected}
    >
      <UpgradePremiumModal
        open={upgradePremiumModal}
        close={() => setUpgradePremiumModal(false)}
        message={lang.plans.exceededRubrics(createRubricToggleMaxUsages)}
      />
      <PreviewRubricModal
        open={previewRubricModal}
        close={() => setPreviewRubricModal(false)}
        templateRubricId={rubric.id}
      />
      <SendRubricModal
        open={showSendModal}
        close={() => setShowSendModal(false)}
        selectedRubrics={[rubric]}
        groupId={rubric.groupId}
        groups={groups}
        refresh={fetchRubrics}
      />
      <DeleteRubricsModal
        open={deleteRubricModal}
        close={() => setDeleteRubricModal(false)}
        templateRubricIds={[rubric.id]}
        refresh={fetchRubrics}
      />
      <div className={cx(classes.rubricLeftWrap, { selected: selected === rubric.id })}>
        <div className={classes.rubricName}>
          {rubric.name}
        </div>
        <div className={classes.rubricInfoWrap}>
          <FontAwesomeIcon
            icon={faUser}
          />
          <div className={classes.rubricInfoLabel}>
            {rubric.authorName}
          </div>
        </div>
        <div className={classes.rubricInfoWrap}>
          <FontAwesomeIcon
            icon={faCheckCircle}
          />
          <div className={classes.rubricInfoLabel}>
            {rubric.numCriteria}
            {' '}
            {rubric.numCriteria === 1 ? lang.appKeywords.criterion : lang.appKeywords.criteria}
          </div>
        </div>
      </div>
    </TemplateEntityCard>
  );
};

RubricCard.propTypes = {
  rubric: PropTypes.object,
  fetchRubrics: PropTypes.func,
  selectable: PropTypes.bool,
  selected: PropTypes.string,
  check: PropTypes.func,
  groups: PropTypes.array,
  dataTour: PropTypes.string,
};

export default RubricCard;
