import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  toggleWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '10px',
  },
  toggleLabel: {
    marginRight: '4px',
  },
  formulaLabel: {
    marginBottom: '5px',
  },
  outerSectionsContainer: {
    width: '100%',
  },
  tabs: {
    '& .otl-tabHeader.active': {
      backgroundColor: theme.shade4 + ' !important',
      borderBottomColor: theme.shade4 + ' !important',
    },
    '& .otl-tabsTab': {
      backgroundColor: theme.shade4 + ' !important',
    },
  },
  sectionTab: {
    minHeight: '365px',
  },
  innerSectionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
  },
  innerSection: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
  },
  formulaBtn: {
    width: '40px',
    height: '40px',
    backgroundColor: theme.shade1 + ' !important',
    color: theme.textColor,
    marginRight: '4px',
    marginBottom: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.added': {
      border: '2px solid ' + theme.successColor + ' !important',
    },
    '&:not(added)': {
      border: '2px solid ' + theme.errorColor + ' !important',
    },
  },
  selectAllBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
}));
