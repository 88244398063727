import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  inputArea: {
    borderRadius: 0,
  },
  wrapper: {
    marginTop: '20px',
  },
  gapList: {
    minHeight: '35px',
    marginTop: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
  },
  gap: {
    minHeight: '23px',
    padding: '5px 10px',
    backgroundColor: theme.buttonBackground3,
    color: 'black',
    border: `1px solid ${theme.buttonBorder3}`,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.disabled': {
      pointerEvents: 'none',
    },
    '&.draggable': {
      padding: '5px',
      cursor: 'pointer',
    },
    '&.isCorrect': {
      border: 'none',
      backgroundColor: '#b7e9bf',
    },
    '&.isWrong': {
      border: 'none',
      backgroundColor: '#F4897BB3',
    },
    '&.isCorrection': {
      border: 'none',
      backgroundColor: '#6dcfe7B3',
    },
  },
  handle: {
    color: theme.buttonBackground2,
    marginRight: '0.3em',
  },
  check: {
    color: theme.successColor,
    marginLeft: '0.25em',
    fontSize: '22px',
  },
  wrong: {
    color: theme.errorColor,
    marginLeft: '0.25em',
    fontSize: '22px',
    '&.hideMargin': {
      marginLeft: 'unset',
    },
  },
  gapInfo: {
    display: 'flex',
    alignItems: 'center',
    '&.isDragging': {
      maxHeight: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& > div > p': {
      margin: 'auto',
    },
  },
  identifier: {
    color: 'grey',
    marginLeft: '5px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
}));
