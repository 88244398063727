import { useCallback, useState } from 'react';

const useLoading = (initialLoading) => {
  const [loading, _setLoading] = useState(initialLoading);

  const setLoading = useCallback((loading) => {
    if (!loading) {
      setTimeout(() => {
        _setLoading(loading);
      }, 350);
      return;
    }

    _setLoading(loading);
  }, []);

  return [loading, setLoading];
};

export default useLoading;
