import React, { useCallback, useEffect, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import api from 'api';
import { MAX_SIZE_10MB } from 'constants/fileMaxSizes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Dropzone from 'components/common/Dropzone';

import useStyles from './styles';

const ExerciseAnswerImage = ({ answer, answerable, onAnswer, color }) => {
  const classes = useStyles();
  const toast = useToast();
  const [files, setFiles] = useState([]);
  const [numFiles, setNumFiles] = useState(0);
  const [uploadFileRequest] = useApi(api.uploadFile, true);

  useEffect(() => {
    if (files.length === numFiles && numFiles !== 0) {
      onAnswer(files);
    }
  }, [files, numFiles, onAnswer]);

  const onDrop = useCallback((filesToUpload) => {
    setNumFiles(filesToUpload.length);
    setFiles([]);

    filesToUpload.forEach((file) => {

      if (file.size > MAX_SIZE_10MB) {
        toast.warning(lang.fileTooLarge);
        return;
      }

      uploadFileRequest([], { file }, ({ data }) => {
        if (data.status === 0) {
          setFiles(filesState => {
            const newFiles = [...filesState];
            newFiles.push(`${process.env.REACT_APP_S3_URL}/${data.key}`);
            return newFiles;
          });
          return;
        }

        toast.error(lang.oops);
      });
    });
  }, [uploadFileRequest, toast]);

  const newFiles = answer ? answer.map(a => ({ name: a })) : [];

  return (
    <div
      className={cx(classes.dropzoneWrapper, { color })}
    >
      <Dropzone
        value={newFiles}
        placeholder={lang.test.dragImageHint}
        accept={['image/*']}
        disabled={!answerable}
        onDrop={onDrop}
        multiple
      />
    </div>
  );
};

ExerciseAnswerImage.propTypes = {
  answer: PropTypes.arrayOf(PropTypes.string),
  answerable: PropTypes.bool,
  onAnswer: PropTypes.func,
  color: PropTypes.bool,
};

export default ExerciseAnswerImage;
