import React, { useEffect, useRef, useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { Button, Icon, Tooltip, useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectAttemptExercise, updateCriteriaGrade, updateFeedback, updateGrade } from 'actions/attemptActions';
import { selectUserIsAdmin } from 'actions/userActions';
import api from 'api';
import { highlightColors } from 'constants/annotationColors';
import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { quillIsEmpty } from 'utils';

import { useAttemptContext } from '../CorrectionTab/context';
import DeleteAnnotationModal from '../DeleteAnnotationModal';
import UseAnnotations from '../UseAnnotations';
import EntityActionsContainer from 'components/common/entity/EntityActionsContainer';
import EntityBody from 'components/common/entity/EntityBody';
import EntityContent from 'components/common/entity/EntityContent';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import EntityHeader from 'components/common/entity/EntityHeader';
import EntitySubHeader from 'components/common/entity/EntitySubHeader';
import EntityTitle from 'components/common/entity/EntityTitle';
import PlansPill from 'components/common/plans/PlansPill';
import ExerciseAnswer from 'components/exercises/exercise/exercise-answer/ExerciseAnswer';
import ExerciseJustification from 'components/exercises/exercise/exercise-answer/ExerciseJustification';
import ExerciseGrade from 'components/exercises/exercise/exercise-header/ExerciseGrade';
import ExerciseGrader from 'components/exercises/exercise/exercise-header/ExerciseGrader';
import ExerciseSavingSpinner from 'components/exercises/exercise/exercise-header/ExerciseSavingSpinner';
import { IDLE, SAVING, SUCCESS } from 'components/exercises/exercise/exercise-header/ExerciseSavingSpinner/states';
import ExerciseCriteriaGrader from 'components/exercises/exercise/exercise-sub-header/ExerciseCriteriaGrader';
import ExerciseFeedback from 'components/exercises/exercise/ExerciseFeedback';
import ExerciseModelAnswer from 'components/exercises/exercise/ExerciseModelAnswer';
import ExerciseStatement from 'components/exercises/exercise/ExerciseStatement';
import ProcessNodeOptions from 'components/exercises/exercise-form/ProcessNodeOptions';

import useStyles from './styles';

const GradeableExercise = ({ num, classificationType, disabled, sectionId, exerciseId, dataTour, setCanBeFetch }) => {
  const toast = useToast();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    id,
    statement,
    type,
    valueText,
    quillAnswer,
    choices,
    valueChoices,
    valueTrueFalse,
    valueImages,
    gaps,
    valueGaps,
    valueOrderItems,
    valueMultipleChoices,
    orderingCorrect,
    correctTrueFalse,
    hasJustification,
    image,
    criteriaGrades,
    grade,
    maxGrade,
    feedback,
    isShortAnswer,
    option,
    isCompact,
    extraText,
    extraTextStartExpanded,
    connectors,
    connections,
    valueConnections,
    modelAnswer,
    gradingOption,
    exerciseCells,
    textAnnotations,
  } = useSelector(selectAttemptExercise(exerciseId, sectionId));
  const [editAnswerGradeRequest] = useApi(api.editAnswerGrade);
  const [editAnswerCriteriaGradeRequest] = useApi(api.editAnswerCriteriaGrade);
  const [editAnswerFeedbackRequest] = useApi(api.editAnswerFeedback);
  const modelAnswerToggle = useFeature(toggles.modelAnswer);
  const textAnnotationToggle = useFeature(toggles.textAnnotation);
  const textAnnotationCommentsToggle = useFeature(toggles.textAnnotationComments);
  const isAdmin = useSelector(selectUserIsAdmin);
  const [deleteAnnotationModal, setDeleteAnnotationModal] = useState(null);
  const { commenting, commentingExerciseId, setTextAnnotationFocused } = useAttemptContext();
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);

  const timeoutRef = useRef();
  const exerciseRef = useRef(null);
  const [savingState, setSavingState] = useState(IDLE);
  const [dropAnswers, setDropAnswers] = useState(valueGaps || []);
  const [newFeedback, setNewFeedback] = useState(feedback);
  const [newQuillAnswer, setNewQuillAnswer] = useState(quillAnswer);
  const [annotate, setAnnotate] = useState(false);

  const hasMultipleCorrectChoices = choices?.filter(choice => choice.isCorrect).length > 1;

  const {
    onSelectionChange,
    onMouseOver,
    onMouseOut,
    handleAnnotateClick,
    handleCommentButtonClick,
    handleColorChange,
    commentBox,
    addComment,
    highlightColor,
    annotations,
    setAnnotations,
    handleClickViewComments,
  } = UseAnnotations({
    exerciseId,
    setSavingState,
    textAnnotations,
    id,
    newQuillAnswer,
    setNewQuillAnswer,
    sectionId,
    exerciseRef,
    selectedAnnotation,
    setSelectedAnnotation,
    annotate,
    setAnnotate,
  });

  useEffect(() => {
    setDropAnswers(valueGaps);
  }, [valueGaps]);

  const saveGrade = (grade) => {
    setSavingState(SAVING);

    editAnswerGradeRequest([exerciseId], { grade }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
          setTimeout(() => {
            setSavingState(IDLE);
          }, 300);
          dispatch(updateGrade(exerciseId, grade, sectionId));
          return;
        } else {
          toast.error(lang.oops);
        }

        setSavingState(IDLE);
      }, 300);
    });
  };

  const saveCriteriaGrade = (answerCriteriaGradeId, grade) => {
    setSavingState(SAVING);

    editAnswerCriteriaGradeRequest([answerCriteriaGradeId], { grade }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
          setTimeout(() => {
            setSavingState(IDLE);
          }, 300);
          dispatch(updateCriteriaGrade(exerciseId, answerCriteriaGradeId, grade, sectionId));
          return;
        } else {
          toast.error(lang.oops);
        }

        setSavingState(IDLE);
      }, 300);
    });
  };

  const saveFeedback = (feedback) => {
    setSavingState(SAVING);
    dispatch(updateFeedback(exerciseId, feedback, sectionId));

    editAnswerFeedbackRequest([exerciseId], { feedback }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
        } else {
          toast.error(lang.oops);
        }

      }, 300);
    });
  };

  const onChange = (feedback) => {
    setCanBeFetch(false);
    clearTimeout(timeoutRef.current);
    setNewFeedback(feedback);

    timeoutRef.current = setTimeout(() => {
      saveFeedback(feedback);
      setCanBeFetch(true);
    }, 2000);
  };

  const onBlur = (feedback) => {
    clearTimeout(timeoutRef.current);

    saveFeedback(feedback);
    setCanBeFetch(true);
  };

  const processNode = (node) => {
    let item;
    const drop = dropAnswers.find(el => el.position === node.attribs['data-position']);
    if (!drop) {
      return null;
    }
    const gapAnswer = dropAnswers.find(answer => answer.position === drop.position);

    if (gapAnswer) {
      if (type === 'filling') {
        item = gaps.find(gap => gap.id === gapAnswer.gapId);
      }
    } else {
      item = null;
    }

    return (
      <ProcessNodeOptions
        id={exerciseId}
        drop={drop}
        item={option === 'write' ? gapAnswer : item}
        gaps={gaps}
        option={option}
        correction={!isAdmin}
      />
    );
  };

  const instructions = [
    {
      shouldProcessNode: (node) => {
        return node.attribs && node.attribs['data-drop'];
      },
      processNode: processNode,
    },
  ];

  const getAnswer = () => {
    if (valueChoices) {
      return valueChoices;
    } else if ((valueText || newQuillAnswer) && type !== 'choices' && type !== 'true-false') {
      return isShortAnswer ? valueText : newQuillAnswer;
    } else if (valueTrueFalse !== null) {
      return valueTrueFalse;
    } else if (valueImages) {
      return valueImages.map(type => (type.value));
    } else if (valueGaps) {
      return valueGaps;
    } else if (valueMultipleChoices) {
      return valueMultipleChoices;
    } else if (valueConnections) {
      return valueConnections;
    }
  };

  const footer = (
    <>
      {selectedAnnotation && (
        <>
          <DeleteAnnotationModal
            open={deleteAnnotationModal}
            close={() => setDeleteAnnotationModal(false)}
            textAnnotationId={selectedAnnotation}
            setAnnotations={setAnnotations}
            answer={newQuillAnswer}
            setAnswer={setNewQuillAnswer}
            setTextAnnotationFocused={setTextAnnotationFocused}
            setSelectedAnnotation={setSelectedAnnotation}
            setAnnotate={setAnnotate}
          />
          <Button
            onClick={() => setDeleteAnnotationModal(true)}
            loading={savingState === SAVING}
            styleType='outlined'
            className={classes.deleteAnnotationButton}
          >
            {lang.test.grades.deleteAnnotation}
          </Button>
        </>
      )}
      {selectedAnnotation && !annotations.find(annotation => annotation.id === selectedAnnotation && annotation.comment) && (
        <div className={classes.buttonContainer}>
          {!textAnnotationCommentsToggle && (
            <div className={classes.premiumPill}>
              <PlansPill tip={lang.plans.premiumFeature} />
            </div>
          )}
          <Button
            onClick={handleCommentButtonClick}
            styleType="outlined"
            disabled={!textAnnotationCommentsToggle}
          >
            <Icon icon="comment" className={classes.annotateIcon} />
            {lang.test.grades.comment}
          </Button>
        </div>
      )}
      {(annotate || selectedAnnotation) && (
        <div className={classes.colorsWrapper}>
          {highlightColors.map((color, index) => (
            <Button
              key={index}
              className={cx(classes.colorButton, { active: highlightColor === color.name })}
              onClick={() => handleColorChange(color.name)}
              style={{ backgroundColor: color.default, border: '1px solid #00000026' }}
            />
          ))}
        </div>
      )}
      {(!commenting || annotate || addComment || commentingExerciseId !== exerciseId) && (
        <Tooltip tip={disabled && lang.test.grades.unavailableStudent}>
          <Button
            onClick={handleAnnotateClick}
            loading={savingState === SAVING}
            disabled={disabled || (addComment && commentingExerciseId === exerciseId)}
          >
            {!annotate && (
              <Icon icon='highlighter' className={classes.annotateIcon} />
            )}
            {!annotate ? lang.test.grades.annotate : lang.test.grades.saveAnnotation}
          </Button>
        </Tooltip>
      )}
      {!annotate && (annotations.length > 0 || commenting) && (
        <Button
          onClick={handleClickViewComments}
          styleType={commenting && commentingExerciseId === exerciseId ? 'filled' : 'outlined'}
        >
          <Icon icon="comment" className={classes.annotateIcon} />
          {commenting && commentingExerciseId === exerciseId ? lang.test.grades.closeAnnotations : annotations.length}
        </Button>
      )}
    </>
  );

  return (
    <EntityBody
      dataTour={`${dataTour}`}
      className={commenting && commentingExerciseId !== exerciseId && classes.exerciseUnfocused}
      _ref={exerciseRef}
    >
      <EntityHeader>
        <EntityTitle num={num} type={type} />
        {!isAdmin && (
          <EntityActionsContainer>
            <ExerciseSavingSpinner state={savingState} />
            {![INFORMATION, PAUSE].includes(type) && classificationType !== 'none' && (
              <>
                {!criteriaGrades ? (
                  <ExerciseGrader
                    dataTour={`${dataTour}-grader`}
                    grade={grade}
                    maxGrade={maxGrade}
                    saveGrade={saveGrade}
                    disabled={disabled}
                  />
                ) : (
                  classificationType === 'rubric' && (
                    <ExerciseGrade grade={grade} maxGrade={maxGrade} />
                  )
                )}
              </>
            )}
          </EntityActionsContainer>
        )}
      </EntityHeader>
      {classificationType === 'rubric' && criteriaGrades && !isAdmin && (
        <EntitySubHeader>
          <ExerciseCriteriaGrader
            criteriaGrades={criteriaGrades}
            saveCriteriaGrade={saveCriteriaGrade}
            updateGrade={(newGrade, answerCriteriaGradeId) =>
              dispatch(updateCriteriaGrade(exerciseId, answerCriteriaGradeId, newGrade, sectionId))
            }
            maxGrade={maxGrade && parseFloat(maxGrade)}
            disabled={disabled}
          />
        </EntitySubHeader>
      )}
      <EntityContent>
        <ExerciseStatement statement={statement} instructions={instructions} />
        {![INFORMATION, PAUSE].includes(type) && (
          <>
            <div onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
              <ExerciseAnswer
                exerciseId={exerciseId}
                type={type}
                choices={choices}
                answer={getAnswer()}
                correctTrueFalse={correctTrueFalse}
                image={image}
                gaps={gaps}
                dropAnswers={dropAnswers}
                orderItems={valueOrderItems}
                orderingCorrect={orderingCorrect}
                hasMultipleCorrectChoices={hasMultipleCorrectChoices}
                isCompact={isCompact}
                isShortAnswer={isShortAnswer}
                option={option}
                connectors={connectors}
                connections={connections}
                exerciseCells={exerciseCells}
                gradingOption={gradingOption}
                footer={textAnnotationToggle && footer}
                commentBox={commentBox}
                onAnswer={setNewQuillAnswer}
                onSelectionChange={onSelectionChange}
                correction
                hasWordCount
              />
              {hasJustification && (
                <ExerciseJustification
                  exerciseId={exerciseId}
                  justification={hasJustification && (valueText || newQuillAnswer)}
                  footer={textAnnotationToggle && footer}
                  commentBox={commentBox}
                  onAnswer={setNewQuillAnswer}
                  onSelectionChange={onSelectionChange}
                  hasWordCount
                />
              )}
            </div>
            {!quillIsEmpty(extraText) && (
              <>
                <Spacer px={10} />
                <EntityExpandableText text={extraText} full={extraTextStartExpanded} />
              </>
            )}
            {!isAdmin && (
              <>
                {modelAnswerToggle && !quillIsEmpty(modelAnswer) && (
                  <>
                    <Spacer px={10} />
                    <ExerciseModelAnswer modelAnswer={modelAnswer} />
                  </>
                )}
                <ExerciseFeedback
                  dataTour={`${dataTour}-feedback`}
                  feedback={newFeedback}
                  onChange={onChange}
                  onBlur={onBlur}
                  defining
                />
              </>
            )}
          </>
        )}
      </EntityContent>
    </EntityBody>
  );
};

GradeableExercise.propTypes = {
  num: PropTypes.number.isRequired,
  exercise: PropTypes.shape({
    id: PropTypes.string.isRequired,
    statement: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(PropTypes.object),
    gaps: PropTypes.arrayOf(PropTypes.object),
    valueOrderItems: PropTypes.arrayOf(PropTypes.object),
    exerciseCells: PropTypes.arrayOf(PropTypes.object),
    orderingCorrect: PropTypes.bool,
    correctTrueFalse: PropTypes.bool,
    answer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.array,
    ]),
    grade: PropTypes.string,
    maxGrade: PropTypes.string,
    feedback: PropTypes.object,
    hasJustification: PropTypes.bool,
    justification: PropTypes.string,
    image: PropTypes.string,
    criteriaGrades: PropTypes.array,
    gradingOption: PropTypes.string,
  }),
  classificationType: PropTypes.string,
  disabled: PropTypes.bool,
  sectionId: PropTypes.string,
  exerciseId: PropTypes.string,
  dataTour: PropTypes.string,
  setCanBeFetch: PropTypes.func,
};

export default GradeableExercise;
