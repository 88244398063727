import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: 'unset !important',
    borderBottom: 'unset !important',
    '&:hover': {
      backgroundColor: theme.selectBackgroundHover,
      cursor: 'pointer',
    },
    '&.selected': {
      backgroundColor: theme.selectBackgroundHover,
    },
    '&.noPadding': {
      padding: 'unset !important',
    },
  },
  square: {
    width: '1em',
    height: '1em',
    borderRadius: '3px',
    marginRight: '0.5em',
  },
}));
