import { createUseStyles } from 'react-jss';

export default createUseStyles({
  formulaWrapper: {
    display: 'inline-block',
    cursor: 'pointer',
    padding: '5px 10px',
    backgroundColor: '#ececec',
    borderRadius: '4px',
    border: '2px solid #5ED0F0',
    '&:hover': {
      // backgroundColor: '#cece',
      padding: '5px 10px',
      borderRadius: '4px',
      '& $editIcon': {
        color: 'black',
      },
    },
  },
  editIcon: {
    marginLeft: '6px',
    cursor: 'pointer',
    color: 'dimgrey',
  },
});
