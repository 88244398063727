import React, { useState, Fragment } from 'react';
import { Icon, InputText, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import api from 'api';
import useApi from 'hooks/common/useApi';
import useInput from 'hooks/common/useInput';
import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const PasswordModal = ({ open, close, publicationId, createAttemptResponse }) => {
  const classes = useStyles();
  const toast = useToast();
  const [createAttemptRequest] = useApi(api.createAttempt);

  const [openLoading, setOpenLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [password, setPassword] = useInput('');

  const closeModal = () => {
    if (openLoading === false) {
      close();
    }
  };

  const handleOpenTest = () => {
    setOpenLoading(true);

    if (openLoading === false) {
      createAttemptRequest([], { publicationId, password }, ({ data }) => {
        setTimeout(() => {
          createAttemptResponse(data);
          if (data.status === 3) {
            toast.error(lang.tests.student.testWrongPassword);
          }

          setOpenLoading(false);
        }, 200);
      });
    }
  };

  const getInputs = () => {
    return (
      <Fragment>
        <div className={classes.passwordWrapper}>
          <InputText
            suffix={
              <Icon
                className={classes.icon}
                icon={showPassword ? 'eye-slash' : 'eye' }
                onClick={() => setShowPassword(!showPassword)}
              />
            }
            type={showPassword ? 'text' : 'password'}
            label={lang.publication.password}
            placeholder={lang.publication.password}
            onInput={e => setPassword(e.currentTarget.value)}
            value={password}
            max={100}
          />
        </div>
      </Fragment>

    );
  };

  const getActions = () => {
    return [
      {
        name: lang.confirm,
        loading: openLoading,
        onClick: handleOpenTest,
      },
      {
        name: lang.common.cancel,
        color: 'black',
        onClick: closeModal,
      },
    ];
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      <Modal
        open={open}
        close={closeModal}
        header={lang.tests.student.testPasswordModal}
        actions={getActions()}
        center
        transition
      >
        {getInputs()}
      </Modal>
    </div>
  );
};

PasswordModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  publicationId: PropTypes.string,
  createAttemptResponse: PropTypes.func,
};

export default PasswordModal;
