import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  navigation: {
    position: 'fixed',
    left: '0',
    paddingTop: '62px',
    width: '240px',
    height: '100%',
    backgroundColor: '#f5f5f5',
    transition: 'left 300ms ease-in-out',
    zIndex: '9',
    boxSizing: 'border-box',
    overflowY: 'auto',
  },
  navigationButtonContainer: {
    padding: '0',
  },
  navigationButton: {
    display: 'none',
    borderRadius: '0 4px 4px 0',
    backgroundColor: '#e0e0e0',
    width: '36px',
    height: '36px',
    cursor: 'pointer',
    zIndex: '10',
    position: 'fixed',
    left: '0',
    transition: 'left 300ms ease-in-out',
    justifyContent: 'center',
    alignItems: 'center',
    '&.open': {
      borderRadius: '4px',
      left: '222px',
    },
  },
  navigationIcon: {
    width: '14px',
    height: '14px',
    color: '#2d2d2d',
  },
  assessmentContent: {
    marginLeft: '240px',
    padding: 'calc(61.39px + 10.5px) 28px',
    transition: 'margin 300ms ease-in-out',
    '&.inReview': {
      marginLeft: '10%',
      marginRight: '10%',
    },
  },
  overlay: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000000',
    opacity: '0',
    position: 'fixed',
    right: '-100vw',
    zIndex: '8',
    transition: 'opacity 300ms ease-in-out',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    navigation: {
      left: '-240px',
      '&.open': {
        left: '0',
      },
    },
    assessmentContent: {
      marginLeft: '0',
      marginRight: '0',
      padding: '0 28px',
      '&.inReview': {
        marginLeft: '0',
        marginRight: '0',
      },
    },
    overlay: {
      '&.open': {
        right: '0',
        top: '0',
        opacity: '58%',
      },
    },
    navigationButtonContainer: {
      padding: 'calc(61.39px + 20.5px - 18px + 10.345px) 0 calc(20.5px - 18px + 10.345px + 36px) 0',
    },
    navigationButton: {
      display: 'flex',
    },
  },
}));
