import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import EntityExpandableText from 'components/common/entity/EntityExpandableText';

const ExerciseModelAnswer = ({ modelAnswer }) => {
  return (
    <div>
      <h4>
        {lang.exercises.modelAnswer}
      </h4>
      <EntityExpandableText
        text={modelAnswer}
      />
    </div>
  );
};

ExerciseModelAnswer.propTypes = {
  modelAnswer: PropTypes.object,
};

export default ExerciseModelAnswer;
