import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  testExerciseDrop: {
    width: '100%',
    height: '1px',
    margin: '-10px 0 10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '18px',
    color: theme.paginationBorder,
  },
}));
