import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  chart: {
    flex: 1,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 'auto',
    marginBottom: '25px',
    width: '100%',
    fontSize: '75%',
  },
}));
