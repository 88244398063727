import React, { useEffect, useMemo, useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { decrementTotalTests, incrementTotalTests, selectUserTotalFreeTests, selectUserFeatureToggleMaxUsages } from 'actions/userActions';
import api from 'api';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useInput from 'hooks/common/useInput';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';
import PlansPill from 'components/common/plans/PlansPill';

import useStyles from './styles';

const SendTestModal = ({ open, close, test, groupId, groups, refresh }) => {
  const classes = useStyles();
  const toast = useToast();
  const [sendTestRequest] = useApi(api.sendTest);
  const { spaceId } = useParams();
  const userId = useSelector(state => state.user.data.id);
  const schools = useSelector(state => state.user.data.schools);
  const hasPersonalSpace = useSelector(state => state.user.data.hasPersonalSpace);
  const totalFreeTests = useSelector(selectUserTotalFreeTests);
  const createTestToggle = useFeature(toggles.createTest, totalFreeTests);
  const iaveToggle = useFeature(toggles.iave);
  const dispatch = useDispatch();
  const createTestToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createTest));

  const schoolOptions = schools?.map(school => ({ value: school.id, label: school.name }));

  if (hasPersonalSpace) {
    schoolOptions.push({ value: PERSONAL_SPACE, label: lang.appKeywords.personalSpace });
  }

  const [loading, setLoading] = useState(false);
  const [group, setGroup, groupErrors, setGroupErrors] = useInput(null);
  const [school, setSchool, schoolErrors, setSchoolErrors] = useInput(schoolOptions.find(currentSchool => currentSchool.value === spaceId));
  const [copy, setCopy] = useState(!createTestToggle ? false : true);

  const groupOptions = groups.map(group => ({ value: group.id, label: group.label, schoolId: group.schoolId }))
    .filter(group => group.schoolId === (school?.value !== PERSONAL_SPACE ? school?.value : null))
    .filter(group => group.value !== groupId);

  const sendDisabled = useMemo(() => {
    if (iaveToggle) {
      return false;
    }

    if (!createTestToggle && copy && school.value === PERSONAL_SPACE && spaceId === PERSONAL_SPACE) {
      return true;
    }

    return false;
  }, [iaveToggle, createTestToggle, copy, school.value, spaceId]);

  const copyDisabled = useMemo(() => {
    if (iaveToggle) {
      return false;
    }

    if (!createTestToggle && school.value === PERSONAL_SPACE && spaceId === PERSONAL_SPACE) {
      return true;
    }

    if (test.authorId !== userId) {
      return true;
    }

    return false;
  }, [createTestToggle, iaveToggle, school.value, spaceId, test.authorId, userId]);

  useEffect(() => {
    if (test.authorId !== userId) {
      setCopy(true);
      return;
    }

    if (!createTestToggle && school.value === PERSONAL_SPACE && spaceId === PERSONAL_SPACE) {
      setCopy(false);
      return;
    }

    setCopy(true);
  }, [test.authorId, userId, createTestToggle, school.value, spaceId]);

  const _setSchool = (school) => {
    setSchool(school);
    const schoolGroup = groups.find(group => group.schoolId === (school.value !== PERSONAL_SPACE ? school.value : null) && group.isPersonal);
    setGroup({
      value: schoolGroup.id,
      label: schoolGroup.label,
    });
  };

  const cancel = () => {
    close();
    setTimeout(() => {
      setGroup(null);
      setCopy(true);
    }, 350);
  };

  const sendTest = () => {
    if (!school) {
      setSchoolErrors([lang.test.send.errorSendTestSchool]);
      return;
    }

    if (!group) {
      setGroupErrors([lang.test.send.errorSendTestGroup]);
      return;
    }

    if (test.authorId !== userId && spaceId !== PERSONAL_SPACE && school.value === PERSONAL_SPACE) {
      toast.warning(lang.test.send.errorSendTestToMySpace);
      return;
    }

    setLoading(true);
    sendTestRequest([test.id], { groupId: group.value, copy }, ({ data }) => {
      if (data.status === 0) {
        toast.success(lang.test.send.successSendTest);

        setTimeout(() => {
          if (school.value === PERSONAL_SPACE && (spaceId !== PERSONAL_SPACE || (spaceId === PERSONAL_SPACE && copy))) {
            dispatch(incrementTotalTests());
          } else if (spaceId === PERSONAL_SPACE && school.value !== PERSONAL_SPACE && !copy) {
            dispatch(decrementTotalTests());
          }

          setLoading(false);
          refresh();
          close();
        }, 350);
        return;
      }

      setLoading(false);
      toast.error(lang.oops);
      close();
    });
  };

  const getActions = () => ([
    {
      name: lang.cancel,
      color: 'black',
      onClick: cancel,
    },
    {
      name: lang.send,
      onClick: sendTest,
      loading: loading,
      disabled: sendDisabled,
      tip: sendDisabled ? lang.plans.exceededTestsTip(createTestToggleMaxUsages) : '',
    },
  ]);

  return (
    <Modal
      open={open}
      close={close}
      header={lang.test.send.sendTest}
      actions={getActions()}
      center
      transition
      small
    >
      <Input
        type="select"
        value={school}
        label={lang.appKeywords.spaces}
        placeholder={lang.appKeywords.space}
        onChange={(option) => _setSchool(option)}
        options={schoolOptions}
        errors={schoolErrors}
        disabled={test.authorId !== userId && spaceId !== PERSONAL_SPACE}
      />
      <Spacer px={15} />
      <Input
        type="select"
        value={group}
        label={lang.appKeywords.group}
        placeholder={lang.appKeywords.group}
        onChange={(option) => setGroup(option)}
        options={groupOptions}
        errors={groupErrors}
      />
      <Spacer px={15} />
      <div className={classes.keepCopyContainer}>
        <Input
          type="checkbox"
          value={copy}
          label={lang.test.send.keepCopy}
          onChange={(event) => setCopy(event.target.checked)}
          disabled={copyDisabled}
        />
        <Render when={!createTestToggle && school.value === PERSONAL_SPACE}>
          <PlansPill
            tip={lang.plans.exceededTestsTip(createTestToggleMaxUsages)}
          />
        </Render>
      </div>
    </Modal>
  );
};

SendTestModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  test: PropTypes.object,
  groupId: PropTypes.string,
  groups: PropTypes.array,
  refresh: PropTypes.func,
};

export default SendTestModal;
