import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import PropTypes from 'prop-types';

import ExtraGap from '../ExtraGap';

const DraggableExtraGap = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
    isDragging,
  } = useSortable({ id: props.id });

  const style = {
    transform: isSorting ? (transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined) : undefined,
    transition,
    touchAction: 'none',
    opacity: isDragging ? '40%' : '100%',
  };

  return (
    <ExtraGap
      ref={setNodeRef}
      style={style}
      id={props.id}
      item={props.item}
      restricted={props.restricted}
      setGaps={props.setGaps}
      attributes={attributes}
      listeners={listeners}
      isDragging={isDragging}
      isSorting={isSorting}
      type={props.type}
      answerable
      {...props}
    />
  );
};

DraggableExtraGap.propTypes = {
  id: PropTypes.string,
  item: PropTypes.object,
  restricted: PropTypes.bool,
  index: PropTypes.number,
  updateItem: PropTypes.func,
  deleteItem: PropTypes.func,
  itemsLength: PropTypes.number,
  setGaps: PropTypes.func,
  type: PropTypes.string,
};

export default DraggableExtraGap;
