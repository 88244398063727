import lang from 'lang';

const addHeader = (attempts, hasRubric, hasSections, fileData) => {
  let maxTotal = 0;
  let cellCount = 1;
  let exerciseCounter = 1;
  const merge = [];
  const fileDataWithHeader = [...fileData];

  if (hasSections) {
    fileDataWithHeader.push(['']);
  }

  if (hasRubric) {
    fileDataWithHeader.push([''], ['']);
  }

  for (let i = 0; i < attempts[0].items.length; i++) {
    const answer = attempts[0].items[i];

    if (!hasSections && !hasRubric) {
      fileDataWithHeader[0].push(`${lang.test.question} ${i + 1} (${lang.test.grades.maxValueLabel} ${answer.maxGrade})`);
      maxTotal += parseFloat(answer.maxGrade);
    } else if (hasSections && !hasRubric && answer.itemType === 'section' && answer.exercises.length > 0) {
      const numberOfQuestions = answer.exercises.length - 1;
      const fillers = Array(numberOfQuestions).fill('');

      fileDataWithHeader[0].push(`${answer.name}`, ...fillers);

      merge.push({
        s: { r: 0, c: cellCount },
        e: { r: 0, c: cellCount + numberOfQuestions },
      });

      for (let j = 0; j < answer.exercises.length; j++) {
        const exercise = answer.exercises[j];
        fileDataWithHeader[1].push(`${lang.test.question} ${j + 1} (${lang.test.grades.maxValueLabel} ${exercise.maxGrade})`);
        maxTotal += parseFloat(exercise.maxGrade);
      }
      cellCount += numberOfQuestions + 1;
    } else if (hasSections && !hasRubric && answer.itemType === 'exercise') {
      fileDataWithHeader[0].push(`${lang.test.question} ${exerciseCounter} (${lang.test.grades.maxValueLabel} ${answer.maxGrade})`);
      fileDataWithHeader[1].push('');
      merge.push({
        s: { r: 0, c: cellCount },
        e: { r: 1, c: cellCount },
      });

      maxTotal += parseFloat(answer.maxGrade);
      cellCount++;
      exerciseCounter++;
    } else if (hasRubric && !hasSections) {
      const numberOfCriteria = answer.criteriaGrades.filter(grade => grade.weight !== 0).length;
      const fillers = Array(numberOfCriteria).fill('');
      fileDataWithHeader[0].push(`${lang.test.question} ${i + 1} (${lang.test.grades.maxValueLabel} ${answer.maxGrade})`, ...fillers);
      merge.push({
        s: { r: 0, c: cellCount },
        e: { r: 0, c: cellCount + numberOfCriteria },
      });
      answer.criteriaGrades.filter(grade => grade.weight !== 0).forEach(criteria => {
        fileDataWithHeader[1].push(`${criteria.name}`);
        fileDataWithHeader[2].push(`${criteria.weight}%`);
      });
      fileDataWithHeader[1].push(`${lang.total} (${answer.maxGrade})`);
      fileDataWithHeader[2].push('');
      merge.push({
        s: { r: 1, c: cellCount + numberOfCriteria },
        e: { r: 2, c: cellCount + numberOfCriteria },
      });
      cellCount += numberOfCriteria + 1;
      maxTotal += parseFloat(answer.maxGrade);
    } else if (hasRubric && hasSections && answer.itemType === 'section' && answer.exercises.length > 0) {

      const numberOfCriteria = answer.exercises[0].criteriaGrades.filter(grade => grade.weight !== 0).length;
      const numberOfQuestions = answer.exercises.length;
      const sectionFillers = Array((numberOfCriteria + 1) * numberOfQuestions - 1).fill('');
      const rubricFillers = Array(numberOfCriteria).fill('');

      fileDataWithHeader[0].push(`${answer.name}`, ...sectionFillers);
      merge.push({
        s: { r: 0, c: cellCount },
        e: { r: 0, c: cellCount + (numberOfCriteria + 1) * numberOfQuestions - 1 },
      });

      for (let index = 0; index < answer.exercises.length; index++) {
        const exercise = answer.exercises[index];
        fileDataWithHeader[1].push(`${lang.test.question} ${index + 1} (${lang.test.grades.maxValueLabel} ${exercise.maxGrade})`, ...rubricFillers);

        merge.push({
          s: { r: 1, c: cellCount },
          e: { r: 1, c: cellCount + numberOfCriteria },
        });

        exercise.criteriaGrades.filter(grade => grade.weight !== 0).forEach(criteria => {
          fileDataWithHeader[2].push(`${criteria.name}`);
          fileDataWithHeader[3].push(`${criteria.weight}%`);
        });
        fileDataWithHeader[2].push(`${lang.total} (${exercise.maxGrade})`);
        fileDataWithHeader[3].push('');

        merge.push({
          s: { r: 2, c: cellCount + numberOfCriteria },
          e: { r: 3, c: cellCount + numberOfCriteria },
        });

        cellCount += numberOfCriteria + 1;

        maxTotal += parseFloat(exercise.maxGrade);
      }
    } else if (hasRubric && hasSections && answer.itemType === 'exercise') {
      const numberOfCriteria = answer.criteriaGrades.filter(grade => grade.weight !== 0).length;
      const fillers = Array(numberOfCriteria).fill('');
      fileDataWithHeader[0].push(`${lang.test.question} ${exerciseCounter} (${lang.test.grades.maxValueLabel} ${answer.maxGrade})`, ...fillers);
      fileDataWithHeader[1].push('', ...fillers);
      merge.push({
        s: { r: 0, c: cellCount },
        e: { r: 1, c: cellCount + numberOfCriteria },
      });
      answer.criteriaGrades.filter(grade => grade.weight !== 0).forEach(criteria => {
        fileDataWithHeader[2].push(`${criteria.name}`);
        fileDataWithHeader[3].push(`${criteria.weight}%`);
      });
      fileDataWithHeader[2].push(`${lang.total} (${answer.maxGrade})`);
      fileDataWithHeader[3].push('');
      merge.push({
        s: { r: 2, c: cellCount + numberOfCriteria },
        e: { r: 3, c: cellCount + numberOfCriteria },
      });
      cellCount += numberOfCriteria + 1;
      maxTotal += parseFloat(answer.maxGrade);
      exerciseCounter++;
    }
  }

  fileDataWithHeader[0].push(`${lang.total} (${lang.test.grades.maxValueLabel} ${maxTotal})`, `${lang.total} (%)`);

  const colNumber = fileDataWithHeader[0].length - 1;
  if (hasRubric && hasSections) {
    merge.push(
      {
        s: { r: 0, c: 0 },
        e: { r: 3, c: 0 },
      },
      {
        s: { r: 0, c: colNumber - 1 },
        e: { r: 3, c: colNumber - 1 },
      },
      {
        s: { r: 0, c: colNumber },
        e: { r: 3, c: colNumber },
      },
    );
  } else if (hasRubric) {
    merge.push(
      {
        s: { r: 0, c: 0 },
        e: { r: 2, c: 0 },
      },
      {
        s: { r: 0, c: colNumber - 1 },
        e: { r: 2, c: colNumber - 1 },
      },
      {
        s: { r: 0, c: colNumber },
        e: { r: 2, c: colNumber },
      });
  } else if (hasSections) {
    merge.push(
      {
        s: { r: 0, c: 0 },
        e: { r: 1, c: 0 },
      },
      {
        s: { r: 0, c: colNumber - 1 },
        e: { r: 1, c: colNumber - 1 },
      },
      {
        s: { r: 0, c: colNumber },
        e: { r: 1, c: colNumber },
      });
  }

  return {
    fileDataWithHeader,
    merge,
    maxTotal,
  };
};

export default addHeader;
