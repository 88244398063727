import React, { useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import lang from 'lang.js';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import FilterSelectorItem from 'components/common/filter-selector/FilterSelectorItem';

import useStyles from './styles.js';

const FilterSelector = ({ itemOptions, currentItems, itemAction, contextPlaceholder, action, actionLabel, actionIcon, value, multiple, disabled }) => {
  const classes = useStyles();
  const dark = useSelector(state => state.page.isDark);

  const [dropdown, setDropdown] = useState(false);

  const _setDropdown = () => {
    if (!disabled) {
      setDropdown(!dropdown);
    }
  };

  const _action = () => {
    setDropdown(false);
    action();
  };

  const _itemAction = (item) => {
    if (!multiple) {
      setDropdown(false);
    }
    itemAction(item);
  };

  return (
    <Dropdown
      close={() => setDropdown(false)}
      dark={dark}
    >
      <div
        className={cx(classes.placeholder, { disabled: disabled })}
        onClick={() => _setDropdown(!dropdown)}
      >
        {value ? value.name : contextPlaceholder}
        <FontAwesomeIcon
          icon={dropdown ? faChevronUp : faChevronDown}
          className={classes.icon}
        />
      </div>
      <DropdownMenu
        className={classes.menu}
        open={dropdown}
      >
        <Render when={itemOptions.length === 0 || (currentItems && itemOptions.length === currentItems.length)}>
          <DropdownMenuItem
            className={classes.noOptions}
          >
            {lang.noOptionsAvailable}
          </DropdownMenuItem>
        </Render>
        {itemOptions.map((itemOption) => (
          <Render
            key={itemOption.id}
            when={(!currentItems || !currentItems.some(item => item.id === itemOption.id)) && !itemOption.soon}
          >
            <FilterSelectorItem
              key={itemOption.id}
              item={itemOption}
              currentItems={currentItems}
              itemAction={_itemAction}
              selected={value && value.id === itemOption.id}
            />
          </Render>
        ))}
        <Render when={action}>
          <DropdownMenuItem
            className={classes.itemContainer}
            onClick={_action}
          >
            <FontAwesomeIcon
              icon={actionIcon}
              className={classes.iconPlus}
            />
            {actionLabel}
          </DropdownMenuItem>
        </Render>
      </DropdownMenu>
    </Dropdown>
  );
};

FilterSelector.propTypes = {
  itemOptions: PropTypes.array.isRequired,
  currentItems: PropTypes.array,
  contextPlaceholder: PropTypes.string.isRequired,
  itemAction: PropTypes.func,
  action: PropTypes.func,
  actionLabel: PropTypes.string,
  actionIcon: PropTypes.object,
  value: PropTypes.object,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default FilterSelector;
