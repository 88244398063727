import React from 'react';
import Quill from 'quill';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { LOCAL } from 'constants/environments';
import store from 'store';

import AudioPlayerSettings from '../AudioPlayerSettings';

const BlockEmbed = Quill.import('blots/block/embed');

class CustomAudioBlot extends BlockEmbed {
  static create(value) {
    const node = super.create(value);

    let src, canPause, limitRepetitions, maxRepetitions;
    if (typeof value === 'string') {
      src = value;
      if (process.env.REACT_APP_NODE_ENV === LOCAL) {
        const hostname = window.location.hostname;
        src = src.replace('localhost', hostname);
      }

      node.setAttribute('src', src);
      canPause = true;
      limitRepetitions = false;
    }

    if (typeof value === 'object') {
      src = value.src;
      if (process.env.REACT_APP_NODE_ENV === LOCAL) {
        const hostname = window.location.hostname;
        src = src.replace('localhost', hostname);
      }

      node.setAttribute('src', src);
      node.setAttribute('data-canpause', value.canPause);
      node.setAttribute('data-limitrepetitions', value.limitRepetitions);
      node.setAttribute('data-maxrepetitions', value.maxRepetitions);
      canPause = typeof value.canPause === 'string' ? value.canPause === 'true' : value.canPause;
      limitRepetitions = typeof value.limitRepetitions === 'string' ? value.limitRepetitions === 'true' : value.limitRepetitions;
      maxRepetitions = value.maxRepetitions;
    }

    node.setAttribute('contenteditable', 'false');

    createRoot(node)
      .render(
        <Provider store={store}>
          <AudioPlayerSettings
            src={src}
            canPause={canPause}
            limitRepetitions={limitRepetitions}
            maxRepetitions={maxRepetitions}
          />
        </Provider>,
      );

    return node;
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      canPause: node.getAttribute('data-canpause'),
      limitRepetitions: node.getAttribute('data-limitrepetitions'),
      maxRepetitions: node.getAttribute('data-maxrepetitions'),
    };
  }
}

CustomAudioBlot.blotName = 'custom-audio';
CustomAudioBlot.tagName = 'div';
CustomAudioBlot.className = 'ql-audio';

export default CustomAudioBlot;
