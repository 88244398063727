import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, TableRow, Toggle } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';

import Input from 'components/common/Input';

import useStyles from './styles';

const CustomFieldInput = ({ field, getValue, setValue, getErrors, setErrors, index }) => {
  const classes = useStyles();

  const onLabelChange = (event) => {
    const newFields = getValue('customFields');

    newFields[index].label = event.target.value;

    setValue('customFields', newFields);
  };

  const onContentChange = (event) => {
    const newFields = getValue('customFields');

    newFields[index].content = event.target.value;

    setValue('customFields', newFields);
  };

  const onEnableStudentInput = (value) => {
    const newFields = getValue('customFields');

    newFields[index].studentInput = value;

    setValue('customFields', newFields);
  };

  const deleteField = () => {
    const newFields = getValue('customFields').filter((_, i) => i !== index);
    const newFieldErrors = getErrors('customFields').filter((_, i) => i !== index);

    setValue('customFields', newFields);
    setErrors('customFields', newFieldErrors);
  };

  return (
    <TableRow>
      <TableCell colSpan="2" className={classes.tableCell}>
        {lang.test.physicalVersion.extraFieldLabel}
      </TableCell>
      <TableCell
        className={classes.tableCell}
        textAlign="center"
        colSpan="3"
      >
        <Input
          type="text"
          placeholder={lang.test.physicalVersion.extraFieldLabelPlaceholder}
          onChange={onLabelChange}
          value={field.label}
          errors={getErrors('customFields')[index].label}
          invalid={getErrors('customFields')[index].label.length !== 0}
        />
      </TableCell>
      <TableCell
        className={cx(classes.tableCell, { disabled: field.studentInput })}
        textAlign="center"
        colSpan="2"
      >
        {lang.test.physicalVersion.extraFieldContent}
      </TableCell>
      <TableCell
        className={cx(classes.tableCell, { disabled: field.studentInput })}
        textAlign="center"
        colSpan="3"
      >
        <Input
          type="text"
          placeholder={lang.test.physicalVersion.extraFieldContentPlaceholder}
          onChange={onContentChange}
          value={field.studentInput ? '' : field.content}
          errors={getErrors('customFields')[index].content}
          invalid={getErrors('customFields')[index].content.length !== 0}
          disabled={field.studentInput}
        />
      </TableCell>
      <TableCell
        className={classes.tableCell}
        textAlign="center"
      >
        <div className={classes.actionContainer}>
          <Toggle
            checked={field.studentInput}
            onChange={onEnableStudentInput}
          />
        </div>
      </TableCell>
      <TableCell
        className={classes.tableCell}
        textAlign="center"
      >
        <div className={classes.actionContainer}>
          <FontAwesomeIcon
            icon={faTrash}
            className={classes.deleteAction}
            onClick={deleteField}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

CustomFieldInput.propTypes = {
  field: PropTypes.object.isRequired,
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getErrors: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default CustomFieldInput;
