import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectTestRubric } from 'actions/testActions';

import AddRubric from 'components/test/rubrics/AddRubric';
import DisplayRubric from 'components/test/rubrics/DisplayRubric';
import EditTestRubric from 'components/test/rubrics/EditTestRubric';

import { CHOOSING, DISPLAY_RUBRIC, EDIT_RUBRIC } from './addRubricOptions';

const RubricTab = ({ refresh }) => {
  const rubric = useSelector(selectTestRubric);
  const [from, setFrom] = useState(!rubric ? CHOOSING : DISPLAY_RUBRIC);

  const afterSubmit = () => {
    setFrom(DISPLAY_RUBRIC);
    refresh();
  };

  if (from === DISPLAY_RUBRIC) {
    return (
      <DisplayRubric
        setFrom={setFrom}
      />
    );
  } else if (from === EDIT_RUBRIC) {
    return (
      <EditTestRubric
        afterSubmit={afterSubmit}
        cancel={() => setFrom(DISPLAY_RUBRIC)}
      />
    );
  } else {
    return (
      <AddRubric
        from={from}
        setFrom={setFrom}
        refresh={refresh}
      />
    );
  }
};

RubricTab.propTypes = {
  refresh: PropTypes.func,
};

export default RubricTab;
