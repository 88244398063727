import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  labelSelectorContainer: {
    display: 'flex',
    marginRight: '10px',
    alignItems: 'center',
    width: 'unset !important',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    labelSelectorContainer: {
      width: '100% !important',
      marginRight: '0px',
      '& .otl-dropdown': {
        width: '100%',
        display: 'unset !important',
      },
    },
  },
}));
