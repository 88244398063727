import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import AnswerZoneCard from '../AnswerZoneCard';
import Card from 'components/common/Card';

import useStyles from './styles';

const SectionAnswerZonesCard = ({ name, exercises, getValue, setValue, getErrors, previewExercise }) => {
  const classes = useStyles();

  const getSectionExercises = () => {
    if (!exercises) {
      return (
        <div className={classes.noExercisesSection}>
          {lang.test.physicalVersion.sectionWithoutExercises}
        </div>
      );
    }

    let exerciseCounter = 0;
    const sectionExercises = exercises.map((exercise) => {
      if (exercise.type !== 'information') {
        exerciseCounter++;
      }

      return (
        <AnswerZoneCard
          key={exercise.id}
          exercise={exercise}
          num={exerciseCounter}
          getValue={getValue}
          setValue={setValue}
          getErrors={getErrors}
          previewExercise={() => previewExercise(exercise.testExerciseId)}
        />
      );
    });

    return sectionExercises;
  };

  return (
    <Card
      header={name}
      width="100%"
      className={classes.sectionCard}
    >
      <div className={classes.sectionContent}>
        {getSectionExercises()}
      </div>
    </Card>
  );
};

SectionAnswerZonesCard.propTypes = {
  name: PropTypes.string,
  exercises: PropTypes.array,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  getErrors: PropTypes.func,
  previewExercise: PropTypes.func,
};

export default SectionAnswerZonesCard;
