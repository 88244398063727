import React, { useCallback, useState } from 'react';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import { selectUserEmail } from 'actions/userActions';
import api from 'api';
import { FREE } from 'constants/plans';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';
import PlansPill from 'components/common/plans/PlansPill';

import useStyles from './styles';

const AddTeacherModal = ({ open, close, reload, groupId, currentTeachers }) => {
  const classes = useStyles();
  const { spaceId } = useParams();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [addTeachersRequest] = useApi(api.addTeachers);
  const [getTeachersRequest] = useApi(api.getTeachers);
  const userEmail = useSelector(selectUserEmail);

  const [selectedTeachers, setSelectedTeachers] = useState([]);

  const validateNewOption = (inputValue, selectOptions, options) => {
    return isEmail(inputValue) && selectOptions.length === 0 && options.length === 0 && inputValue !== userEmail;
  };

  const save = useCallback(() => {
    if (loading) {
      return;
    }

    setLoading(true);

    const teachers = selectedTeachers.map(selectedTeacher => selectedTeacher.value);
    addTeachersRequest([groupId], { teachers }, ({ data }) => {
      if (data.status === 0) {
        close();
        setTimeout(() => {
          setSelectedTeachers([]);
          setLoading(false);
          toast.success(lang.group.successAddTeacher);
          reload();
        }, 300);
        return;
      }

      setLoading(false);
      toast.error(lang.oops);
    });
  }, [addTeachersRequest, close, groupId, loading, reload, selectedTeachers, toast]);

  const loadOptions = debounce(useCallback((inputValue, callback) => {
    if (inputValue.trim().length < 3) {
      return;
    }

    getTeachersRequest([], { schoolId: spaceId === 'p' ? undefined : spaceId, teacherIdentifier: inputValue.trim() }, ({ data }) => {
      if (data.status === 0) {
        const teachers = data.teachers.map(teacher => {
          const isFree = (!teacher.plan || teacher.plan.name === FREE);
          const isDisabled = teacher?.groupsCount >= teacher?.maxGroups && spaceId === PERSONAL_SPACE && isFree;
          const splitFullName = teacher.fullName.split(' ');
          const hasMoreThanOneName = teacher.fullName && splitFullName.length > 1;
          const firstAndLastName = splitFullName[0] + ' ' + splitFullName[splitFullName.length - 1];
          const teacherName = hasMoreThanOneName ? firstAndLastName : teacher.fullName;

          return ({
            value: teacher.id,
            label: (
              <div className={classes.optionWrapper}>
                <Render when={spaceId === PERSONAL_SPACE}>
                  <PlansPill
                    message={isFree ? lang.plans.free : lang.plans.premium}
                    isFree={isFree}
                    tip={isDisabled ? lang.groups.userNotAllowed : null}
                  />
                </Render>
                <div>
                  {teacherName}
                </div>
              </div>
            ),
            isDisabled: isDisabled,
          });
        });

        const filteredTeachers = teachers.filter((teacher) => !currentTeachers.some(currentTeacher => (currentTeacher.email === teacher.value || currentTeacher.username === teacher.value)));

        if (data.extraTeachers > 0) {
          filteredTeachers.push({ value: data.extraTeachers, label: lang.andXMore(data.extraTeachers), isDisabled: true });
        }

        callback(filteredTeachers);
      }
    });
  }, [classes.optionWrapper, currentTeachers, getTeachersRequest, spaceId]), 500);

  const actions = [
    {
      name: lang.cancel,
      onClick: close,
      color: 'black',
    },
    {
      name: lang.confirm,
      onClick: save,
      loading: loading,
    },
  ];

  return (
    <Modal
      header={lang.group.addTeacher}
      open={open}
      close={close}
      center
      transition
      actions={actions}
    >
      <Input
        type="select"
        label={lang.exercises.teacherFilter}
        placeholder={lang.exercises.teacherFilter}
        hint={(spaceId === 'p') ? lang.group.hintTargetsCreatable : lang.group.hintTargets}
        multiple
        isAsync
        creatable={spaceId === 'p'}
        loadOptions={loadOptions}
        value={selectedTeachers}
        onChange={setSelectedTeachers}
        validateNewOption={validateNewOption}
      />
    </Modal>
  );
};

AddTeacherModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  reload: PropTypes.func,
  currentTeachers: PropTypes.array,
  groupId: PropTypes.string,
};

export default AddTeacherModal;
