import { createUseStyles } from 'react-jss';

export default createUseStyles({
  inputWrapperLogo: {
    display: 'flex',
    gap: '0.5em',
    width: '100%',
    justifyContent: 'space-between',
  },
  inputWrapperGrade: {
    display: 'flex',
    gap: '0.5em',
    marginTop: '0.5em',
    width: '100%',
    justifyContent: 'space-between',
  },
  dropzone: {
    margin: '0.5em 0em',
  },
});
