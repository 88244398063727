import React, { useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { decrementTotalTests } from 'actions/userActions';
import api from 'api';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const DeleteTestModal = ({ open, close, testId, refresh }) => {
  const toast = useToast();
  const classes = useStyles();
  const [deleteTestRequest] = useApi(api.deleteTest);
  const dispatch = useDispatch();
  const { spaceId } = useParams();

  const _close = useCallback(() => {
    close();
    setTimeout(350);
  }, [close]);

  const deleteTest = () => {
    deleteTestRequest([testId], null, (res) => {
      if (res.data.status === 0) {
        if (spaceId === PERSONAL_SPACE) {
          dispatch(decrementTotalTests());
        }

        toast.success(lang.test.successDeleteTest);
        setTimeout(() => {
          refresh();
        }, 350);
      } else if (res.data.status === 2) {
        toast.error(lang.test.deleteErrorDoesNotExist);
        _close();
        setTimeout(() => {
          refresh();
        }, 350);
      } else {
        toast.error(lang.oops);
        _close();
      }
    });
  };

  const getActions = () => {
    return [
      {
        name: lang.delete,
        color: 'red',
        onClick: deleteTest,
      },
      {
        name: lang.cancel,
        color: 'black',
        onClick: _close,
      },
    ];
  };

  const getContent = () => {
    return (
      <div>
        <div className={classes.deleteTestInfo}>
          {lang.test.deleteTestInfo}
        </div>
        <div className={classes.deleteTestInfo}>
          {lang.test.deleteTestInfo2}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.deleteButton}>
      <Modal
        open={open}
        close={_close}
        header={lang.test.deleteTest}
        actions={getActions()}
        center
        transition
        small
      >
        {getContent()}
      </Modal>
    </div>
  );
};

DeleteTestModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  testId: PropTypes.string,
  refresh: PropTypes.func,
};

export default DeleteTestModal;
