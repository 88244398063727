import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { selectUserFeatureToggleMaxUsages, selectUserTotalFreeExercises } from 'actions/userActions';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import Modal from 'components/common/Modal';
import UpgradePremiumModal from 'components/common/plans/UpgradePremiumModal';
import AskExerciseType from 'components/exercises/exercise-form/flow/AskExerciseType';

const CreateExerciseModal = ({ open, setOpen, group }) => {
  const history = useHistory();
  const { spaceId } = useParams();
  const totalFreeExercises = useSelector(selectUserTotalFreeExercises);
  const createExerciseToggle = useFeature(toggles.createExercise, totalFreeExercises);
  const createExerciseToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createExercise));

  const actions = [
    {
      name: lang.cancel,
      onClick: () => setOpen(false),
      color: 'black',
    },
  ];

  const redirectToEditor = (type) => {
    history.push({
      pathname: routes.newExercise.ref(spaceId),
      search: `?type=${type}&group=${group.id}`,
    });
  };

  if (!createExerciseToggle) {
    return (
      <UpgradePremiumModal
        open={open}
        close={() => setOpen(false)}
        message={lang.plans.exceededExercises(createExerciseToggleMaxUsages)}
      />
    );
  }

  return (
    <Modal
      open={open}
      close={() => setOpen(false)}
      header={lang.exerciseForm.selectExerciseType}
      actions={actions}
      center
      transition
      medium
    >
      <AskExerciseType
        nextStep={redirectToEditor}
      />
    </Modal>
  );
};

CreateExerciseModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  group: PropTypes.object,
};

export default CreateExerciseModal;
