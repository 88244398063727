import { createUseStyles } from 'react-jss';

export default createUseStyles({
  groupsPage: {
    padding: '20px',
  },
  groupsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
});
