import React, { forwardRef, useEffect, useState, useReducer } from 'react';
import { useDndMonitor } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Spacer, Toggle } from '@intuitivo/outline';
import { Icon, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { removeTestItem, selectTestExercise, selectTestItems, updatePoints, selectTest } from 'actions/testActions';
import api from 'api';
import { CONNECTING, INFORMATION, PAUSE, SEGMENTATION } from 'constants/exerciseTypes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { deepCopy, quillIsEmpty } from 'utils';

import EntityActionsContainer from '../../../common/entity/EntityActionsContainer';
import EntityBody from '../../../common/entity/EntityBody';
import EntityContent from '../../../common/entity/EntityContent';
import EntityHeader from '../../../common/entity/EntityHeader';
import EntitySubHeader from '../../../common/entity/EntitySubHeader';
import EntityTitle from '../../../common/entity/EntityTitle';
import ExerciseJustification from '../../../exercises/exercise/exercise-answer/ExerciseJustification';
import EntityAction from '../../../exercises/exercise/exercise-header/EntityAction';
import EntityMover from '../../../exercises/exercise/exercise-header/EntityMover';
import ExerciseGrader from '../../../exercises/exercise/exercise-header/ExerciseGrader';
import ExerciseSavingSpinner from '../../../exercises/exercise/exercise-header/ExerciseSavingSpinner';
import { IDLE, SAVING, SUCCESS } from '../../../exercises/exercise/exercise-header/ExerciseSavingSpinner/states';
import ExerciseWeights from '../../../exercises/exercise/exercise-sub-header/ExerciseWeights';
import ExerciseStatement from '../../../exercises/exercise/ExerciseStatement';
import RemoveEntityModal from '../RemoveEntityModal';
import Button from 'components/common/Button';
import EntityActionSeparator from 'components/common/entity/EntityActionSeparator';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import PlansPill from 'components/common/plans/PlansPill';
import expressions from 'components/common/rich-text/FormulaModal/expressions';
import ContinueButton from 'components/exercises/exercise/ContinueButton';
import ExerciseAnswer from 'components/exercises/exercise/exercise-answer/ExerciseAnswer';
import ExerciseModelAnswer from 'components/exercises/exercise/ExerciseModelAnswer';
import CancelModal from 'components/exercises/exercise-form/CancelModal';
import ExerciseForm from 'components/exercises/exercise-form/ExerciseForm';
import { tableReducer } from 'components/exercises/exercise-form/flow/ask-for-table/AskForTable/reducer';
import ProcessNodeOptions from 'components/exercises/exercise-form/ProcessNodeOptions';
import SaveWarningModal from 'components/exercises/exercise-form/SaveWarningModal';

import useStyles from './styles';

const DEFAULT_EXTRA_TEXT = { ops: [] };

const EditableExercise = forwardRef((props, ref) => {
  const {
    id,
    num,
    testId,
    classificationType,
    testSectionId,
    refreshState,
    actionable,
    className,
    saveTestItemsOrder,
    isOverlay,
    attributes,
    listeners,
    transform,
    transition,
    isSorting,
    isDragging,
    node,
  } = props;

  const toast = useToast();
  const classes = useStyles();
  const testItems = useSelector(selectTestItems);
  const {
    publicationId,
    statement,
    type,
    order,
    choices,
    gaps,
    shuffleChoices,
    orderItems,
    correctTrueFalse,
    points,
    criteriaWeights,
    hasJustification,
    image,
    isCompact,
    isShortAnswer,
    characterLimit,
    option,
    extraText,
    extraTextStartExpanded,
    connectors,
    connections,
    enableMathSymbols,
    mathSymbols,
    modelAnswer,
    exerciseCells,
    gradingOption,
    hasWordCount,
  } = useSelector(selectTestExercise(id, testSectionId));
  const dispatch = useDispatch();
  const [removeTestExerciseRequest] = useApi(api.removeTestExercise);
  const [editTestExercisePointsRequest] = useApi(api.editTestExercisePoints);
  const [editPublicationExercisePointsRequest] = useApi(api.editPublicationExercisePoints);
  const [editTestExerciseCriteriaWeightRequest] = useApi(api.editTestExerciseCriteriaWeight);
  const [editPublicationExerciseCriteriaWeightRequest] = useApi(api.editPublicationExerciseCriteriaWeight);
  const [editTestExerciseRequest] = useApi(api.editTestExercise);
  const [editPublicationExerciseRequest] = useApi(api.editPublicationExercise);
  const [editTestExerciseIsCompactRequest] = useApi(api.editTestExerciseIsCompact);
  const horizontalChoicesToggle = useFeature(toggles.horizontalChoices);
  const connectingToggle = useFeature(toggles.connecting);
  const segmentationToggle = useFeature(toggles.segmentation);
  const modelAnswerToggle = useFeature(toggles.modelAnswer);
  const editPublicationToggle = useFeature(toggles.editPublication);

  const [edit, setEdit] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [saveWarningModal, setSaveWarningModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savingState, setSavingState] = useState(IDLE);
  const { publication } = useSelector(selectTest);

  const [currentStatement, setCurrentStatement] = useState(statement);
  const [currentChoices, setCurrentChoices] = useState(choices);
  const [currentPreviewChoices, setCurrentPreviewChoices] = useState(gaps);
  const [currentGaps, setCurrentGaps] = useState(gaps);
  const [currentOrderItems, setCurrentOrderItems] = useState(orderItems);
  const [currentCorrectTrueFalse, setCurrentCorrectTrueFalse] = useState(correctTrueFalse);
  const [currentHasJustification, setCurrentHasJustification] = useState(hasJustification);
  const [currentImage, setCurrentImage] = useState(image);
  const [currentIsCompact, setCurrentIsCompact] = useState(isCompact);
  const [currentIsShortAnswer, setCurrentIsShortAnswer] = useState(isShortAnswer);
  const [currentCharacterLimit, setCurrentCharacterLimit] = useState(characterLimit);
  const [currentOption, setCurrentOption] = useState(option);
  const [currentExtraText, setCurrentExtraText] = useState(extraText || DEFAULT_EXTRA_TEXT);
  const [currentExtraTextStartExpanded, setCurrentExtraTextStartExpanded] = useState(extraTextStartExpanded);
  const [currentShuffleChoices, setCurrentShuffleChoices] = useState(shuffleChoices);
  const [currentConnectors, setCurrentConnectors] = useState(connectors);
  const [currentConnections, setCurrentConnections] = useState(connections);
  const [currentEnableMathSymbols, setCurrentEnableMathSymbols] = useState(enableMathSymbols);
  const [currentMathSymbols, setCurrentMathSymbols] = useState(mathSymbols ?? expressions);
  const [currentModelAnswer, setCurrentModelAnswer] = useState(modelAnswer);
  const [currentGradingOption, setCurrentGradingOption] = useState(gradingOption);
  const [currentExerciseCells, tableDispatcher] = useReducer(tableReducer, exerciseCells);
  const [currentHasWordCount, setCurrentHasWordCount] = useState(hasWordCount);

  useEffect(() => {
    setCurrentStatement(statement);
    setCurrentPreviewChoices(choices);
    if (choices) {
      const orderedChoices = [...choices];
      setCurrentChoices(orderedChoices.sort((a, b) => {
        if (a.order !== null) {
          return a.order - b.order;
        }
        return new Date(a.createdAt) - new Date(b.createdAt);
      }));
    }
    setCurrentGaps(gaps);
    setCurrentOrderItems(orderItems);
    setCurrentCorrectTrueFalse(correctTrueFalse);
    setCurrentHasJustification(hasJustification);
    setCurrentImage(image);
    setCurrentIsCompact(isCompact);
    setCurrentIsShortAnswer(isShortAnswer);
    setCurrentCharacterLimit(characterLimit);
    setCurrentExtraText(extraText || DEFAULT_EXTRA_TEXT);
    setCurrentExtraTextStartExpanded(extraTextStartExpanded);
    setCurrentShuffleChoices(shuffleChoices);
    setCurrentConnectors(connectors);
    setCurrentConnections(connections);
    setCurrentEnableMathSymbols(enableMathSymbols);
    setCurrentMathSymbols(mathSymbols ?? expressions);
    setCurrentGradingOption(gradingOption);
    setCurrentHasWordCount(hasWordCount);
    tableDispatcher({ type: 'SET_CELLS', payload: exerciseCells });
  }, [statement, choices, shuffleChoices, mathSymbols, enableMathSymbols, gaps, orderItems, correctTrueFalse, hasJustification, image, isCompact, isShortAnswer, characterLimit, extraText, extraTextStartExpanded, connectors, connections, gradingOption, exerciseCells, hasWordCount]);

  const hasMultipleCorrectChoices = choices?.filter(choice => choice.isCorrect).length > 1;

  useDndMonitor({
    onDragEnd: (event) => {
      const { active } = event;

      if (active.id === id && node) {
        node.current.scrollIntoView({ behaviour: 'smooth', block: 'center' });
      }
    },
  });

  const removeExercise = () => {
    setSavingState(SAVING);

    removeTestExerciseRequest([id], null, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          dispatch(removeTestItem(id, testSectionId));
          setTimeout(() => {
            setSavingState(IDLE);
          }, 300);
          return;
        } else if (data.status === 2) {
          toast.warning(lang.test.errorRemovePublished);
        } else {
          toast.error(lang.oops);
        }

        setSavingState(IDLE);
        setRemoveModal(false);
      }, 300);
    });
  };

  const onMove = (moveUp) => {
    if (moveUp && order === 0 && !testSectionId) {
      return;
    }

    if (!moveUp && order === testItems.length - 1 && !testSectionId) {
      return;
    }

    let newItems = deepCopy(testItems);

    if (testSectionId) {
      const section = newItems.find(item => item.id === testSectionId);
      if ((moveUp && order === 0) || (!moveUp && order === section.exercises.length - 1)) {
        const testExercise = section.exercises.find((item) => item.id === id);

        section.exercises = section.exercises.filter((item) => item.id !== id)
          .map((item, index) => ({ ...item, order: index }));

        const newSectionExercise = { ...testExercise, testSectionId: null };
        newItems.push(newSectionExercise);

        const order = newItems.length - 1;
        const newOrder = moveUp ? section.order : section.order + 1;
        newItems = arrayMove(newItems, order, newOrder)
          .map((item, index) => ({ ...item, order: index }));
      } else {
        const newOrder = moveUp ? order - 1 : order + 1;
        section.exercises = arrayMove(section.exercises, order, newOrder)
          .map((item, index) => ({ ...item, order: index }));
      }
    } else {
      const newOrder = moveUp ? order - 1 : order + 1;

      const targetItem = newItems[newOrder];

      if (targetItem.itemType === 'section') {
        const testExercise = newItems.find(item => item.id === id);

        if (!targetItem.exercises) {
          targetItem.exercises = [];
        }

        const newSectionExercise = {
          ...testExercise,
          testSectionId: targetItem.id,
        };

        if (moveUp) {
          targetItem.exercises.push(newSectionExercise);
        } else {
          targetItem.exercises.unshift(newSectionExercise);
        }

        targetItem.exercises = targetItem.exercises
          .map((item, index) => ({ ...item, order: index }));

        newItems = newItems.filter(item => item.id !== id)
          .map((item, index) => ({ ...item, order: index }));
      } else {
        newItems = arrayMove(newItems, order, newOrder)
          .map((item, index) => ({ ...item, order: index }));
      }
    }

    saveTestItemsOrder(newItems);
  };

  const savePointsHandler = (status, newPoints) => {
    setTimeout(() => {
      if (status === 0) {
        setSavingState(SUCCESS);
        setTimeout(() => {
          setSavingState(IDLE);
        }, 300);
        dispatch(updatePoints(id, newPoints, testSectionId));
        if (publication) {
        // TODO: This is a temporary toggle because of a functionality change after the feature is implemented
        // Check out https://intuitivo.atlassian.net/browse/DK-2383 for more information
          editPublicationToggle
            ? toast.success(lang.test.exercises.editPointsSuccessAfterPublish)
            : toast.success(lang.test.exercises.editPointsSuccess);
        } else {
          toast.success(lang.test.exercises.editPointsSuccess);
        }
        return;
      } else {
        toast.error(lang.oops);
      }

      setSavingState(IDLE);
    }, 300);
  };

  const savePoints = (newPoints) => {
    setSavingState(SAVING);

    if (publication) {
      editPublicationExercisePointsRequest([publication.id, id], { points: newPoints }, ({ data }) => {
        savePointsHandler(data.status, newPoints);
      });
    } else {
      editTestExercisePointsRequest([id], { points: newPoints }, ({ data }) => {
        savePointsHandler(data.status, newPoints);
      });
    }
  };

  const saveWeightHandler = (status) => {
    setTimeout(() => {
      if (status === 0) {
        setSavingState(SUCCESS);
        setTimeout(() => {
          setSavingState(IDLE);
        }, 300);
        if (publication) {
          // TODO: This is a temporary toggle because of a functionality change after the feature is implemented
          // Check out https://intuitivo.atlassian.net/browse/DK-2383 for more information
          editPublicationToggle
            ? toast.success(lang.test.exercises.editWeightsSuccessAfterPublish)
            : toast.success(lang.test.exercises.editWeightsSuccess);
        } else {
          toast.success(lang.test.exercises.editWeightsSuccess);
        }
      } else {
        toast.error(lang.oops);
        setSavingState(IDLE);
      }

      refreshState();
    }, 300);
  };

  const saveWeight = (criteriaWeightId, weight) => {
    setSavingState(SAVING);

    if (publication) {
      editPublicationExerciseCriteriaWeightRequest([publication.id, id], { publicationExerciseCriteriaWeightId: criteriaWeightId, weight }, ({ data }) => {
        saveWeightHandler(data.status, weight);
      });
    } else {
      editTestExerciseCriteriaWeightRequest([criteriaWeightId], { weight }, ({ data }) => {
        saveWeightHandler(data.status, weight);
      });
    }
  };

  const cancelEdit = () => {
    refreshState();
    setCurrentChoices(choices);
    setCurrentStatement(statement);
    setCurrentGaps(gaps);
    setCurrentCorrectTrueFalse(correctTrueFalse);
    setCurrentHasJustification(hasJustification);
    setCurrentImage(image);
    setCurrentIsShortAnswer(isShortAnswer);
    setCurrentCharacterLimit(characterLimit);
    setCurrentConnectors(connectors);
    setCurrentConnections(connections);
    setCurrentEnableMathSymbols(enableMathSymbols);
    setCurrentMathSymbols(mathSymbols ?? expressions);
    setCurrentOption(option);
    setCancelModal(false);
    setSaveWarningModal(false);
    setEdit(false);
  };

  const saveEditExercise = () => {
    if (quillIsEmpty(currentStatement)) {
      toast.warning(lang.exerciseForm.noEmptyStatement);
      return;
    }

    if (type === 'text') {
      if (isShortAnswer && !currentCharacterLimit) {
        toast.warning(lang.exerciseForm.characterLimitError);
        return;
      }
    }

    let newCurrentChoices;
    if (type === 'choices') {
      let hasCorrect = false;
      newCurrentChoices = currentChoices.map(choice => ({
        id: choice.id,
        value: choice.value,
        isCorrect: choice.isCorrect,
        identifier: choice.identifier,
        order: choice.order,
      }));
      for (let i = 0; i < newCurrentChoices.length; i++) {
        if (quillIsEmpty(newCurrentChoices[i].value)) {
          toast.warning(lang.exerciseForm.noEmptyOption);
          return;
        }

        hasCorrect = hasCorrect || newCurrentChoices[i].isCorrect;
      }

      if (!hasCorrect) {
        toast.warning(lang.exerciseForm.noAnswerChoiceDefined);
        return;
      }
    }

    if (type === 'true-false') {
      if (currentCorrectTrueFalse === null) {
        toast.warning(lang.exerciseForm.noAnswerChoiceDefined);
        return;
      }
    }

    let newGaps = [];
    if (type === 'filling') {
      const correctGaps = currentGaps.filter(gap => gap.isCorrect);

      if (correctGaps.length === 0) {
        toast.warning(lang.exerciseForm.noAnswerGapDefined);
        return;
      }

      if (currentOption === 'dropdown') {
        for (let i = 0; i < correctGaps.length; i++) {
          const correctGap = correctGaps[i];
          const optionGaps = currentGaps.filter(gap => !gap.isCorrect && gap.position === correctGap.position);
          if (optionGaps.length === 0) {
            toast.warning(lang.exerciseForm.noExtraWordDefined);
            return;
          }
        }
      }

      newGaps = currentGaps.map(gap => ({
        id: gap.id,
        text: gap.text,
        position: gap.position,
        isCorrect: gap.isCorrect,
        order: gap.order,
        identifier: gap.identifier,
      }));
    }

    const newCurrentOrderItems = [];

    if (type === 'ordering') {
      for (let i = 0; i < currentOrderItems.length; i++) {
        const item = currentOrderItems[i];

        if (quillIsEmpty(item.text)) {
          toast.warning(lang.exerciseForm.noEmptyItem);
          return;
        }

        newCurrentOrderItems.push({
          id: item.id,
          text: item.text,
          order: i,
          identifier: item.identifier,
        });
      }
    }

    if (type === 'caption') {
      const correctGaps = currentGaps.filter(gap => gap.isCorrect);

      if (correctGaps.length === 0) {
        toast.warning(lang.exerciseForm.noAnswerGapDefined);
        return;
      }

      for (let i = 0; i < correctGaps.length; i++) {
        const correctGap = correctGaps[i];

        if (correctGap.text.trim() === '') {
          toast.warning(lang.exerciseForm.noEmptyItem);
          return;
        }

        if (currentOption === 'dropdown') {
          const optionGaps = currentGaps.filter(gap => !gap.isCorrect && gap.gapCoords.x === correctGap.gapCoords.x && gap.gapCoords.y === correctGap.gapCoords.y);
          if (optionGaps.length === 0) {
            toast.warning(lang.exerciseForm.noExtraWordDefined);
            return;
          }
        }
      }

      newGaps = currentGaps.map(gap => ({
        id: gap.id,
        text: gap.text,
        gapCoords: gap.gapCoords,
        pointCoords: gap.pointCoords,
        isCorrect: gap.isCorrect,
        order: gap.order,
        identifier: gap.identifier,
      }));
    }

    let newConnectors = [];
    let newConnections = [];
    if (type === 'connecting') {
      if (currentConnectors.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectors);
        return;
      }

      if (currentConnectors.filter(el => el.columnOrder === 0).length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectorsLeft);
        return;
      }

      if (currentConnectors.filter(el => el.columnOrder === 1).length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectorsRight);
        return;
      }

      for (let i = 0; i < currentConnectors.length; i++) {
        if (quillIsEmpty(currentConnectors[i].value)) {
          toast.warning(lang.exerciseForm.connecting.noEmptyConnector);
          return;
        }
      }

      if (currentConnections.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnections);
        return;
      }

      newConnectors = currentConnectors.map(connector => ({
        id: connector.id,
        value: connector.value,
        columnOrder: connector.columnOrder,
        identifier: connector.identifier,
        order: connector.order,
      }));

      newConnections = currentConnections.map(connection => ({
        id: connection.id,
        start: currentConnectors.find(el => el.id === connection.start).order,
        end: currentConnectors.find(el => el.id === connection.end).order,
      }));
    }

    if (type === 'segmentation') {
      if (currentConnectors.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectors);
        return;
      }
      if (currentConnections.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnections);
        return;
      }

      newConnectors = currentConnectors.map(connector => ({
        id: connector.id,
        value: { ops: [''] },
        coords: connector.coords,
        columnOrder: 0,
        identifier: connector.identifier,
        order: connector.order,
      }));

      newConnections = currentConnections.map(connection => ({
        id: connection.id,
        start: currentConnectors.find(el => el.id === connection.start).order,
        end: currentConnectors.find(el => el.id === connection.end).order,
      }));
    }
    let newExerciseCells = [];
    if (type === 'table') {
      const headerCells = currentExerciseCells.filter(cell => (cell.row === 0 && cell.col !== 0) || (cell.row !== 0 && cell.col === 0));
      const hasSomeEmptyHeader = headerCells.some(({ value }) => quillIsEmpty(value));
      if (hasSomeEmptyHeader){
        toast.warning(lang.exerciseForm.table.noEmptyHeaderCell);
        return false;
      }

      newExerciseCells = currentExerciseCells.map(cell => ({
        id: cell.id,
        value: cell.value,
        row: cell.row,
        col: cell.col,
        identifier: cell.identifier,
        isCorrect: cell.isCorrect,
        exerciseChoiceId: cell.exerciseChoiceId,
      }));
    }
    const image = (currentImage && (type === 'caption' || type === 'segmentation') ? currentImage : '');

    setLoading(true);

    if (editPublicationToggle && publicationId) {
      editPublicationExerciseRequest([publicationId, id], {
        statement: currentStatement,
        choices: newCurrentChoices,
        gaps: newGaps,
        orderItems: newCurrentOrderItems,
        correctTrueFalse: currentCorrectTrueFalse,
        type: type,
        hasJustification: currentHasJustification,
        image: image,
        isShortAnswer: currentIsShortAnswer,
        characterLimit: currentCharacterLimit,
        option: currentOption,
        extraText: currentExtraText,
        extraTextStartExpanded: currentExtraTextStartExpanded,
        shuffleChoices: currentShuffleChoices,
        connectors: newConnectors,
        connections: newConnections,
        enableMathSymbols: currentEnableMathSymbols,
        mathSymbols: currentMathSymbols,
        modelAnswer: currentModelAnswer,
        gradingOption: currentGradingOption,
        exerciseCells: newExerciseCells,
        hasWordCount: currentHasWordCount,
        testId: testId,
      }, ({ data }) => {
        setTimeout(() => {
          if (data.status === 0) {
            toast.success(lang.exerciseForm.successExerciseEditedAfterPublish);
            setEdit(false);
            setSaveWarningModal(false);
            refreshState();
          } else {
            toast.error(lang.oops);
          }

          setLoading(false);
        }, 300);
      });
      return;
    }
    editTestExerciseRequest([id], {
      statement: currentStatement,
      choices: newCurrentChoices,
      gaps: newGaps,
      orderItems: newCurrentOrderItems,
      correctTrueFalse: currentCorrectTrueFalse,
      type: type,
      hasJustification: currentHasJustification,
      image: image,
      isShortAnswer: currentIsShortAnswer,
      characterLimit: currentCharacterLimit,
      option: currentOption,
      extraText: currentExtraText,
      extraTextStartExpanded: currentExtraTextStartExpanded,
      shuffleChoices: currentShuffleChoices,
      connectors: newConnectors,
      connections: newConnections,
      enableMathSymbols: currentEnableMathSymbols,
      mathSymbols: currentMathSymbols,
      modelAnswer: currentModelAnswer,
      gradingOption: currentGradingOption,
      exerciseCells: newExerciseCells,
      hasWordCount: currentHasWordCount,
    }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          toast.success(lang.exerciseForm.successExerciseEdited);
          setEdit(false);
          refreshState();
        } else {
          toast.error(lang.oops);
        }

        setLoading(false);
      }, 300);
    });
  };

  const actions = [
    {
      label: lang.cancel,
      onClick: () => setCancelModal(true),
      color: 'black',
    },
    {
      label: lang.save,
      onClick: publication ? () => setSaveWarningModal(true) : saveEditExercise,
      loading: loading,
    },
  ];

  const getActions = () => {
    if (!actions) {
      return;
    }

    return (
      <div className={classes.headerActions}>
        {actions.map((action, index) => (
          <Button
            key={index}
            loading={action.loading}
            onClick={action.onClick}
            black={action.color === 'black'}
            red={action.color === 'red'}
            className={classes.actionButton}
            sibling
          >
            {action.label}
          </Button>
        ))}
      </div>
    );
  };

  const style = {
    transform: isSorting ? (transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined) : undefined,
    transition,
    opacity: isDragging && !isOverlay ? '0.3' : '1',
    position: 'relative',
  };

  const processNode = (node) => {
    const drop = gaps.find(el => el.isCorrect && el.position === node.attribs['data-position']);

    if (!drop) {
      return null;
    }

    return (
      <ProcessNodeOptions
        key={drop.id}
        id={id}
        drop={drop}
        gaps={gaps}
        option={option}
        enableMathSymbols={currentEnableMathSymbols}
        preview
      />
    );
  };

  const instructions = [
    {
      shouldProcessNode: (node) => {
        return node.attribs && node.attribs['data-drop'];
      },
      processNode: processNode,
    },
  ];

  const setNewIsCompact = () => {
    editTestExerciseIsCompactRequest([id], { isCompact: !currentIsCompact }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setCurrentIsCompact(!currentIsCompact);
        }
      }, 200);
    });
  };

  return (
    <EntityBody
      _ref={ref}
      style={style}
      className={className}
    >
      <RemoveEntityModal
        open={removeModal}
        close={() => setRemoveModal(false)}
        onConfirm={removeExercise}
        loading={savingState === SAVING}
        type={type === 'information' ? 'information' : 'exercise'}
      />
      <EntityHeader
        attributes={attributes}
        listeners={listeners}
        isDraggable={actionable}
        isOverlay={isOverlay}
      >
        {actionable && (
          <EntityMover
            onMove={onMove}
          />
        )}
        <EntityTitle
          num={num}
          type={type}
          warning={type === 'caption' ? lang.exerciseForm.captionWarning : null}
        />
        {((type === CONNECTING && !connectingToggle) || (type === SEGMENTATION && !segmentationToggle)) && (
          <div className={classes.premiumPill}>
            <PlansPill
              tip={lang.plans.warningCreatePremiumExercise}
            />
          </div>
        )}
        <EntityActionsContainer>
          <ExerciseSavingSpinner
            state={savingState}
          />
          {type === 'choices' && actionable && horizontalChoicesToggle && (
            <div className={classes.compactedWrapper}>
              <div className={classes.compactedText}>
                {lang.test.compacted}
              </div>
              <Toggle
                checked={currentIsCompact}
                onChange={setNewIsCompact}
              />
            </div>
          )}
          {![INFORMATION, PAUSE].includes(type) && classificationType !== 'none' && (
            <>
              <ExerciseGrader
                dataTour="assessment-exercise-grade"
                grade={points}
                saveGrade={savePoints}
                loading={loading}
                defining
              />
              <EntityActionSeparator />
            </>
          )}
          {editPublicationToggle && !actionable && (
            <EntityAction
              dataTour="assessment-exercise-edit"
              icon={faPen}
              onClick={() => setEdit(true)}
              tip={lang.edit}
            />
          )}
          {actionable && (
            <>
              <EntityAction
                dataTour="assessment-exercise-edit"
                icon={faPen}
                onClick={() => setEdit(true)}
                tip={lang.edit}
              />
              <EntityAction
                dataTour="assessment-exercise-delete"
                icon={faTimes}
                onClick={() => setRemoveModal(true)}
                tip={lang.remove}
                className={classes.deleteExercise}
              />
            </>
          )}
        </EntityActionsContainer>
      </EntityHeader>

      {editPublicationToggle && !actionable && edit && (
        <EntitySubHeader>
          <div className={classes.warningWrapper}>
            <Icon
              className={classes.icon}
              icon={'warning' }
            />
            {lang.test.exercises.editPublishedExerciseWarning}
          </div>
        </EntitySubHeader>

      )}
      {!isOverlay && !isSorting && (
        <>
          {classificationType === 'rubric' && ![INFORMATION, PAUSE].includes(type) && (
            <EntitySubHeader>
              <ExerciseWeights
                criteriaWeights={criteriaWeights}
                saveWeight={saveWeight}
                defining
              />
            </EntitySubHeader>
          )}
          {!edit && (
            <EntityContent>
              <ExerciseStatement
                statement={statement}
                instructions={instructions}
              />
              <ExerciseAnswer
                exerciseId={id}
                type={type}
                choices={choices}
                gaps={gaps}
                orderItems={orderItems}
                correctTrueFalse={correctTrueFalse}
                image={image}
                dropAnswers={gaps}
                hasMultipleCorrectChoices={hasMultipleCorrectChoices}
                isCompact={currentIsCompact}
                isShortAnswer={isShortAnswer}
                option={option}
                connectors={connectors}
                connections={connections}
                exerciseCells={exerciseCells}
                enableMathSymbols={currentEnableMathSymbols}
                gradingOption={gradingOption}
                preview
                hasWordCount={hasWordCount}
              />
              {hasJustification && (
                <ExerciseJustification
                  enableMathSymbols={currentEnableMathSymbols}
                  hasWordCount={hasWordCount}
                />
              )}
              {!quillIsEmpty(extraText) && (
                <>
                  <Spacer px={10} />
                  <EntityExpandableText
                    text={extraText}
                    full={extraTextStartExpanded}
                  />
                </>
              )}
              {modelAnswerToggle && !quillIsEmpty(modelAnswer) && (
                <>
                  <Spacer px={10} />
                  <ExerciseModelAnswer
                    modelAnswer={modelAnswer}
                  />
                </>
              )}
              {type === PAUSE && (
                <ContinueButton />
              )}
            </EntityContent>
          )}
          {edit && (
            <>
              <ExerciseForm
                type={type}
                statement={currentStatement}
                setStatement={setCurrentStatement}
                choices={currentChoices}
                setChoices={setCurrentChoices}
                shuffleChoices={currentShuffleChoices}
                setShuffleChoices={setCurrentShuffleChoices}
                gaps={currentGaps}
                previewChoices={currentPreviewChoices}
                setGaps={setCurrentGaps}
                orderItems={currentOrderItems}
                setOrderItems={setCurrentOrderItems}
                correctTrueFalse={currentCorrectTrueFalse}
                setCorrectTrueFalse={setCurrentCorrectTrueFalse}
                hasJustification={currentHasJustification}
                setHasJustification={setCurrentHasJustification}
                image={currentImage}
                setImage={setCurrentImage}
                isShortAnswer={currentIsShortAnswer}
                setIsShortAnswer={setCurrentIsShortAnswer}
                characterLimit={currentCharacterLimit}
                setCharacterLimit={setCurrentCharacterLimit}
                option={currentOption}
                setOption={setCurrentOption}
                extraText={currentExtraText}
                setExtraText={setCurrentExtraText}
                extraTextStartExpanded={currentExtraTextStartExpanded}
                setExtraTextStartExpanded={setCurrentExtraTextStartExpanded}
                connectors={currentConnectors}
                setConnectors={setCurrentConnectors}
                connections={currentConnections}
                setConnections={setCurrentConnections}
                enableMathSymbols={currentEnableMathSymbols}
                setEnableMathSymbols={setCurrentEnableMathSymbols}
                mathSymbols={currentMathSymbols}
                setMathSymbols={setCurrentMathSymbols}
                modelAnswer={currentModelAnswer}
                setModelAnswer={setCurrentModelAnswer}
                gradingOption={currentGradingOption}
                setGradingOption={setCurrentGradingOption}
                exerciseCells={currentExerciseCells}
                tableDispatcher={tableDispatcher}
                hasWordCount={currentHasWordCount}
                setHasWordCount={setCurrentHasWordCount}
                restricted={!!publication}
                edit
              />
              <CancelModal
                open={cancelModal}
                close={() => setCancelModal(false)}
                cancel={cancelEdit}
              />
              <SaveWarningModal
                open={saveWarningModal}
                close={() => setSaveWarningModal(false)}
                save={saveEditExercise}
              />
              <div className={classes.editActions}>
                {getActions()}
              </div>
            </>
          )}
        </>
      )}
    </EntityBody>
  );
});

EditableExercise.displayName = 'EditableExercise';

EditableExercise.propTypes = {
  id: PropTypes.string,
  num: PropTypes.number,
  testId: PropTypes.string,
  classificationType: PropTypes.string,
  testSectionId: PropTypes.string,
  refreshState: PropTypes.func,
  actionable: PropTypes.bool,
  className: PropTypes.string,
  saveTestItemsOrder: PropTypes.func,
  isOverlay: PropTypes.bool,
  attributes: PropTypes.object,
  listeners: PropTypes.object,
  transform: PropTypes.object,
  transition: PropTypes.string,
  isSorting: PropTypes.bool,
  isDragging: PropTypes.bool,
  node: PropTypes.object,
};

export default EditableExercise;
