import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.backgroundColor2,
    padding: '10px 20px',
    touchAction: 'none',
    justifyContent: 'space-between',
    borderRadius: '17px 17px 0 0',
    width: 'calc(100% - 40px)',
    '&.isDraggable': {
      cursor: 'pointer',
    },
    '&.isOverlay': {
      cursor: 'grabbing',
    },
  },
}));
