import React from 'react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';

import FlowStep from '../FlowStep';

import useStyles from './styles';

const AskForTruth = ({ number, answer, setAnswer }) => {
  const classes = useStyles();

  const options = [
    {
      value: true,
      label: lang.test['true'],
      icon: faCheckCircle,
      dataTour: 'assessment-exercise-true-answer',
    },
    {
      value: false,
      label: lang.test['false'],
      icon: faTimesCircle,
    },
  ];

  return (
    <FlowStep
      dataTour="assessment-exercise-correct-answer"
      stepNumber={number}
      header={lang.exerciseForm.trueFalse}
    >
      <div className={classes.trueFalseContainer}>
        {options.map((option) => (
          <Button
            data-tour={option.dataTour}
            key={option.value}
            onClick={() => setAnswer(option.value)}
            className={cx(classes.trueFalseButton, { active: answer === option.value, isTrue: option.value, isFalse: !option.value })}
          >
            <FontAwesomeIcon
              icon={option.icon}
            />
            <div className={classes.buttonText}>
              {option.label}
            </div>
          </Button>
        ))}
      </div>
    </FlowStep>
  );
};

AskForTruth.propTypes = {
  number: PropTypes.number,
  answer: PropTypes.bool,
  setAnswer: PropTypes.func,
};

export default AskForTruth;
