import React from 'react';
import PropTypes from 'prop-types';

import { CAPTION, CHOICES, CONNECTING, FILLING, IMAGE, INFORMATION, ORDERING, SEGMENTATION, TABLE, TEXT, TRUEFALSE } from 'constants/exerciseTypes';

import ExerciseAnswerOrdering from '../exercise-answer-ordering/ExerciseAnswerOrdering';
import ExerciseAnswerTable from '../exercise-answer-table/ExerciseAnswerTable';
import ExerciseAnswerCaption from '../ExerciseAnswerCaption';
import ExerciseAnswerChoices from '../ExerciseAnswerChoices';
import ExerciseAnswerConnecting from '../ExerciseAnswerConnecting';
import ExerciseAnswerFilling from '../ExerciseAnswerFilling';
import ExerciseAnswerImage from '../ExerciseAnswerImage';
import ExerciseAnswerSegmentation from '../ExerciseAnswerSegmentation';
import ExerciseAnswerText from '../ExerciseAnswerText';
import ExerciseAnswerTrueFalse from '../ExerciseAnswerTrueFalse';

const ExerciseAnswer = ({ exerciseId, type, choices, exerciseCells, gaps, orderItems, orderingCorrect, answer, dropAnswers, setDropAnswers, correctTrueFalse, onAnswer, answerable, correction, image, color, hasMultipleCorrectChoices, isCompact, isShortAnswer, characterLimit, preview, option, shuffleChoices, connectors, connections, mathSymbols, enableMathSymbols, setWriting, gradingOption, hasWordCount, footer, onSelectionChange, commentBox }) => {

  if (type === TEXT) {
    return (
      <ExerciseAnswerText
        exerciseId={exerciseId}
        answer={answer}
        onAnswer={onAnswer}
        answerable={answerable}
        color={color}
        isShortAnswer={isShortAnswer}
        characterLimit={characterLimit}
        mathSymbols={mathSymbols}
        enableMathSymbols={enableMathSymbols}
        setWriting={setWriting}
        hasWordCount={hasWordCount}
        footer={footer}
        commentBox={commentBox}
        onSelectionChange={onSelectionChange}
      />
    );
  } else if (type === CHOICES) {
    return (
      <ExerciseAnswerChoices
        exerciseId={exerciseId}
        choices={choices}
        answer={answer}
        onAnswer={onAnswer}
        answerable={answerable}
        hasMultipleCorrectChoices={hasMultipleCorrectChoices}
        shuffleChoices={shuffleChoices}
        isCompact={isCompact}
      />
    );
  } else if (type === TRUEFALSE) {
    return (
      <ExerciseAnswerTrueFalse
        exerciseId={exerciseId}
        answer={answer}
        correct={correctTrueFalse}
        onAnswer={onAnswer}
        answerable={answerable}
      />
    );
  } else if (type === IMAGE) {
    return (
      <ExerciseAnswerImage
        answer={answer}
        answerable={answerable}
        onAnswer={onAnswer}
        color={color}
      />
    );
  } else if (type === FILLING) {
    return (
      <ExerciseAnswerFilling
        exerciseId={exerciseId}
        answer={answer}
        gaps={gaps}
        answerable={answerable}
        dropAnswers={dropAnswers || []}
        correction={correction}
        enableMathSymbols={enableMathSymbols}
        option={option}
      />
    );
  } else if (type === ORDERING) {
    return (
      <ExerciseAnswerOrdering
        exerciseId={exerciseId}
        orderItems={orderItems}
        isCorrection={correction}
        orderingCorrect={orderingCorrect}
        answerable={answerable}
        onAnswer={onAnswer}
      />
    );
  } else if (type === CAPTION) {
    return (
      <ExerciseAnswerCaption
        exerciseId={exerciseId}
        image={image}
        gaps={gaps}
        dropAnswers={dropAnswers || []}
        answerable={answerable}
        correction={correction}
        preview={preview}
        option={option}
        setDropAnswers={setDropAnswers}
        onAnswer={onAnswer}
        setWriting={setWriting}
      />
    );
  } else if (type === CONNECTING) {
    return (
      <ExerciseAnswerConnecting
        connectors={connectors}
        answer={answer}
        connections={connections}
        shuffleConnectors={shuffleChoices}
        answerable={answerable}
        onAnswer={onAnswer}
        correction={correction}
      />
    );
  } else if (type === SEGMENTATION) {
    return (
      <ExerciseAnswerSegmentation
        image={image}
        answer={answer}
        connectors={connectors}
        connections={connections}
        option={option}
        answerable={answerable}
        onAnswer={onAnswer}
        correction={correction}
      />
    );
  } else if (type === TABLE) {
    return (
      <ExerciseAnswerTable
        exerciseId={exerciseId}
        exerciseCells={exerciseCells}
        answerable={answerable}
        gradingOption={gradingOption}
        answer={answer}
        onAnswer={onAnswer}
        correction={correction}
      />
    );
  } else {
    return null;
  }
};

ExerciseAnswer.propTypes = {
  exerciseId: PropTypes.string,
  type: PropTypes.oneOf([TEXT, CHOICES, TRUEFALSE, IMAGE, FILLING, INFORMATION, ORDERING, CONNECTING, SEGMENTATION, CAPTION, TABLE]),
  choices: PropTypes.array,
  exerciseCells: PropTypes.array,
  gaps: PropTypes.array,
  orderItems: PropTypes.array,
  orderingCorrect: PropTypes.bool,
  answer: PropTypes.any,
  dropAnswers: PropTypes.array,
  correctTrueFalse: PropTypes.bool,
  onAnswer: PropTypes.func,
  answerable: PropTypes.bool,
  correction: PropTypes.bool,
  image: PropTypes.array,
  isCompact: PropTypes.bool,
  preview: PropTypes.bool,
  setDropAnswers: PropTypes.func,
  color: PropTypes.bool,
  hasMultipleCorrectChoices: PropTypes.bool,
  isShortAnswer: PropTypes.bool,
  option: PropTypes.string,
  shuffleChoices: PropTypes.bool,
  characterLimit: PropTypes.number,
  connectors: PropTypes.array,
  connections: PropTypes.array,
  enableMathSymbols: PropTypes.number,
  mathSymbols: PropTypes.object,
  setWriting: PropTypes.func,
  gradingOption: PropTypes.string,
  hasWordCount: PropTypes.bool,
  footer: PropTypes.element,
  onSelectionChange: PropTypes.func,
  commentBox: PropTypes.element,
};

export default ExerciseAnswer;
