import { CLOSE_FORMULA_MODAL, OPEN_FORMULA_MODAL } from 'actions/formulaModalActions';

const initialState = {
  open: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_FORMULA_MODAL:
      return {
        open: true,
        ...action.options,
      };
    case CLOSE_FORMULA_MODAL:
      return {
        open: false,
      };
    default:
      return state;
  }
};

export default reducer;
