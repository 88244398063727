import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  inputTable: {
    width: '100%',
    tableLayout: 'fixed',
    padding: '10px 20px',
  },
  tableCell: {
    border: 'none !important',
    marginTop: '10px',
  },
  tableHeader: {
    fontWeight: 'normal',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    inputTable: {
      width: '1238px',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    inputTable: {
      width: '1238px',
    },
  },
  [`@media (max-width: ${theme.md}px)`]: {
    inputTable: {
      width: '1238px',
    },
  },
}));
