import React, { useMemo } from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectAttemptId } from 'actions/studentAttemptActions';
import { selectUserIsTeacher } from 'actions/userActions';
import { LOCAL } from 'constants/environments';
import lang from 'lang';

import AudioPlayer from 'components/common/AudioPlayer';

import useStyles from './styles';

const AudioPlayerSettings = ({ src, canPause, limitRepetitions, maxRepetitions }) => {
  const classes = useStyles();
  const isTeacher = useSelector(selectUserIsTeacher);
  const attemptId = useSelector(selectAttemptId);

  const finalSrc = useMemo(() => {
    let finalSrc = src;
    if (process.env.REACT_APP_NODE_ENV === LOCAL) {
      const hostname = window.location.hostname;
      finalSrc = finalSrc.replace('localhost', hostname);
    }

    return finalSrc;
  }, [src]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <Render when={isTeacher && !attemptId}>
          <div className={classes.settingsDisplay}>
            {canPause ? lang.exerciseForm.audio.canPause : lang.exerciseForm.audio.cannotPause}
          </div>
          <Render when={limitRepetitions}>
            <div className={classes.settingsDisplay}>
              {lang.exerciseForm.audio.maxRepetitionsLabel}
              {': '}
              {maxRepetitions}
            </div>
          </Render>
        </Render>
        <AudioPlayer
          src={finalSrc}
          canPause={canPause}
          limitRepetitions={limitRepetitions}
          maxRepetitions={maxRepetitions}
        />
      </div>
    </div>
  );
};

AudioPlayerSettings.propTypes = {
  src: PropTypes.string,
  canPause: PropTypes.bool,
  limitRepetitions: PropTypes.bool,
  maxRepetitions: PropTypes.string,
};

export default AudioPlayerSettings;
