import lang from 'lang';
import { buildStep, buildOptions } from 'services/shepherd/utils';

export const options = buildOptions('tour-exercises');

export const steps = (isOnExercisePage, modelAnswerToggle) => [
  {
    id: 'sidebar-exercises',
    attachOn: 'right',
    ...(
      isOnExercisePage
        ? {
          buttons: ['next'],
          text: lang.tours.exercises.steps.sidebar,
        }
        : {
          text: lang.tours.exercises.steps.sidebarGoToPage,
          advanceOnClick: true,
        }
    ),
  },
  {
    id: 'create-new-exercise-button',
    text: lang.tours.exercises.steps.createExerciseButton,
    attachOn: 'bottom-left',
    advanceOnClick: true,
  },
  {
    id: 'assessment-create-exercise-type',
    text: lang.tours.exercises.steps.createExerciseType,
    attachOn: 'right',
    buttons: ['next'],
    delayTime: 500,
  },
  {
    id: 'ask-exercise-type-text',
    text: lang.tours.exercises.steps.createExerciseTypeText,
    attachOn: 'right',
    buttons: ['back'],
    advanceOnClick: true,
  },
  {
    id: 'exercise-editor-header-type-dropdown',
    text: lang.tours.exercises.steps.editorHeaderTypeDropdown,
    attachOn: 'bottom',
    buttons: ['next'],
  },
  {
    id: 'exercise-editor-header-group-dropdown',
    text: lang.tours.exercises.steps.editorHeaderGroupDropdown,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'ask-for-statement',
    text: lang.tours.exercises.steps.editorStatement,
    attachOn: 'right',
    buttons: ['back', 'next'],
    canClickTarget: true,
  },
  {
    id: 'ask-for-short-answer',
    text: lang.tours.exercises.steps.editorShortAnswer,
    attachOn: 'right',
    buttons: ['back', 'next'],
  },
  {
    id: 'ask-for-extra-text',
    text: lang.tours.exercises.steps.editorExtraText,
    attachOn: 'right',
    buttons: ['back', 'next'],
  },
  ...(modelAnswerToggle ? [
    {
      id: 'ask-for-model-answer',
      text: lang.tours.exercises.steps.editorModelAnswer,
      attachOn: 'right',
      buttons: ['back', 'next'],
    },
  ] : []),
  {
    id: 'preview-exercise',
    text: lang.tours.exercises.steps.editorPreview,
    attachOn: 'left',
    buttons: ['back', 'next'],
  },
  {
    id: 'exercise-settings-form-title',
    text: lang.tours.exercises.steps.editorSettingsFormTitle,
    attachOn: 'left',
    buttons: ['back', 'next'],
    canClickTarget: true,
  },
  {
    id: 'exercise-settings-form-visibility',
    text: lang.tours.exercises.steps.editorSettingsFormVisibility,
    attachOn: 'left',
    buttons: ['back', 'next'],
  },
  {
    id: 'exercise-settings-form-visibility-private',
    text: lang.tours.exercises.steps.editorSettingsFormVisibilityPrivate,
    attachOn: 'left',
    buttons: ['back'],
    advanceOnClick: true,
  },
  {
    id: 'exercise-labels-button',
    text: lang.tours.exercises.steps.editorLabelsButton,
    attachOn: 'right',
    buttons: ['back', 'next'],
  },
  {
    id: 'exercise-editor-header-save-exercise',
    text: lang.tours.exercises.steps.editorHeaderSaveExercise,
    attachOn: 'left-end',
    buttons: ['back'],
    advanceOnClick: true,
  },
  {
    id: 'single-exercise-0',
    text: lang.tours.exercises.steps.exerciseCard,
    attachOn: 'bottom',
    buttons: ['next'],
  },
  {
    id: 'single-exercise-0-right',
    text: lang.tours.exercises.steps.exerciseCardActions,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'exercise-filters',
    text: lang.tours.exercises.steps.exerciseFilters,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  // final step
  {
    id: 'tour-hub',
    text: lang.tours.exercises.steps.hub,
    attachOn: 'top-left',
    buttons: ['back', 'complete'],
  },
].map(buildStep);
