import React, { useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import api from 'api';
import useApi from 'hooks/common/useApi';
import useInput from 'hooks/common/useInput';
import lang from 'lang';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';

const GroupSettingsModal = ({ open, close, groupId, currentGroupName, reload }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [editGroupSettingsRequest] = useApi(api.editGroupSettings);

  const [groupName, setGroupName, groupNameErrors, setGroupNameErrors] = useInput(currentGroupName);

  const cleanupAndClose = () => {
    setTimeout(() => {
      setGroupName(currentGroupName);
      close();
    }, 250);
  };

  const save = () => {
    setLoading(true);
    let errors = false;
    const _groupNameErrors = [];

    const trimmedGroupName = groupName.trim();
    if (trimmedGroupName.length === 0) {
      errors = true;
      _groupNameErrors.push(lang.groups.errorNameRequired);
    }

    if (errors === true) {
      setTimeout(() => {
        setGroupNameErrors(_groupNameErrors);
        setLoading(false);
      }, 200);
      return;
    }

    setGroupNameErrors([]);

    editGroupSettingsRequest([groupId], { groupName: trimmedGroupName }, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        close();
        toast.success(lang.groups.successEditGroup);
        reload();
      } else {
        toast.error(lang.oops);
      }
    });
  };

  const actions = [
    {
      name: lang.cancel,
      onClick: cleanupAndClose,
      color: 'black',
    },
    {
      name: lang.confirm,
      onClick: save,
      loading: loading,
    },
  ];

  return (
    <Modal
      header={lang.group.groupSettings}
      open={open}
      close={close}
      center
      transition
      actions={actions}
    >
      <Input
        type="text"
        value={groupName}
        label={lang.groups.groupName}
        placeholder={lang.groups.groupName}
        onChange={(event) => setGroupName(event.target.value)}
        maxSize={100}
        errors={groupNameErrors}
      />
    </Modal>
  );
};

GroupSettingsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  groupId: PropTypes.string,
  currentGroupName: PropTypes.string,
  reload: PropTypes.func,
};

export default GroupSettingsModal;
