import React, { useCallback, useEffect, useRef, useState } from 'react';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';

import QuillRenderer from 'components/common/QuillRenderer';

import useStyles from './styles';

const EntityExpandableText = ({ text, full, answerable }) => {
  const classes = useStyles();

  const container = useRef();
  const [showMore, setShowMore] = useState(full);
  const [containerHeight, setContainerHeight] = useState(0);
  const [visibleHeight, setVisibleHeight] = useState(0);

  const updateHeight = useCallback(() => {
    const scrollHeight = container.current?.scrollHeight;

    setContainerHeight(scrollHeight);
    if (showMore) {
      setVisibleHeight(scrollHeight);
    } else {
      setVisibleHeight(scrollHeight > 100 ? 100 : scrollHeight);
    }
  }, [showMore]);

  useEffect(() => {
    setShowMore(full);
  }, [full]);

  useEffect(() => {
    updateHeight();

    const images = Array.from(container.current?.querySelectorAll('img'));

    if (!images || images.length === 0) {
      return;
    }

    images.forEach((image) => {
      image.addEventListener('load', updateHeight, { once: true });
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', updateHeight, { once: true });
      });
    };
  }, [updateHeight, text]);

  return (
    <div className={classes.container}>
      <div
        style={{ height: visibleHeight }}
        className={classes.textContainer}
      >
        <QuillRenderer
          value={text}
          className={cx(classes.text, { answerable: answerable })}
          _ref={container}
        />
      </div>
      <div
        onClick={() => setShowMore(!showMore)}
        className={cx(classes.showMore, { visible: containerHeight > 100 })}
      >
        <FontAwesomeIcon
          icon={showMore ? faChevronCircleUp : faChevronCircleDown}
        />
        {showMore ? lang.showLess : lang.showMore}
      </div>
    </div>
  );
};

EntityExpandableText.propTypes = {
  text: PropTypes.object,
  full: PropTypes.bool,
  answerable: PropTypes.bool,
};

export default EntityExpandableText;
