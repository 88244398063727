import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  rubricContainer: {
    backgroundColor: theme.cardBackground3,
    padding: '20px',
    width: 'calc(100%-40px)',
  },
  rubricName: {
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '18px',
    marginBottom: '20px',
  },
  rubricDescription: {
    textAlign: 'center',
    color: theme.textColor3,
    marginBottom: '40px',
  },
  rubricCriteriaContainer: {
    width: '100%',
  },
  performanceLevelsHeader: {
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: '10px',
  },
  performanceLevelsTableContainer: {
    width: '100%',
    overflowX: 'auto',
  },
  performanceLevelsTable: {
    width: '100%',
    height: '100%',
  },
  rubricTableCell: {
    border: 'none !important',
  },
  performanceLevelDescription: {
    backgroundColor: theme.white,
    border: `1px solid ${theme.cardBorder}`,
    padding: '10px',
    height: 'calc(100% - 22px)',
    minHeight: '150px',
    minWidth: '150px',
    borderRadius: '18px',
  },
  criteriaColumn: {
    position: 'sticky',
    left: 0,
    backgroundColor: theme.cardBackground3,
  },
}));
