import { createUseStyles } from 'react-jss';

export default createUseStyles({
  testInfoWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '0.5em',
  },
  testInfoSeparator: {
    margin: '0 0.5em',
  },
});
