import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  table: {
    '& .ant-table-container': {
      borderTop: 'none !important',
    },
    '& .ant-table-container > .ant-table-content > table > thead > tr > :nth-last-child(2)': {
      borderRadius: '0 0.5em 0 0',
      '&::before': {
        display: 'none',
      },
    },
    '&.byTable': {
      '& .ant-table-container > .ant-table-content > table > tbody': {
        borderRadius: '0 0.5em 0.5em 0',
        '& tr:not(:first-child) > td:last-child': {
          borderRadius: '0',
        },
      },
    },
  },
  row: {
    '&:not(.answerable)': {
      '&.isAdmin': {
        '& > td:not(:first-child)': {
          backgroundColor: '#fafafa',
        },
      },
      '&.incorrectTable': {
        '& > td:not(:first-child)': {
          backgroundColor: '#CE1F0166',
        },
      },
      '& > td:not(:first-child)': {
        backgroundColor: '#7DD88966',
      },
    },
    '& > :last-child': {
      borderRadius: '0 0.75em 0.75em 0',
    },
  },
}));
