import React, { useState } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';

const GapModal = ({ open, close, insert, value }) => {
  const [state, setState] = useState(value);

  const _insert = () => {
    insert(state);
    close();
  };

  const actions = [
    {
      name: lang.edit,
      color: 'blue',
      onClick: _insert,
    },
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.edit}
      center
      transition
      medium
      actions={actions}
    >
      <Input
        value={state}
        onChange={(e) => setState(e.target.value)}
        placeholder={lang.exerciseForm.statementPlaceholder}
      />
    </Modal>
  );
};

GapModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  insert: PropTypes.func,
  value: PropTypes.string,
};

export default GapModal;
