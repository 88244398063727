import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  sectionContainer: {
    marginTop: '20px',
  },
  tableWrapper: {
    width: '100%',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    tableWrapper: {
      display: 'block',
      width: 'calc(100vw - 297px)',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      maxWidth: 'fit-content',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    tableWrapper: {
      display: 'block',
      width: 'calc(100vw - 60px)',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      maxWidth: 'fit-content',
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    tableWrapper: {
      display: 'block',
      width: 'calc(100vw - 60px)',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      maxWidth: 'fit-content',
    },
  },
}));
