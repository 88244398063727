import { useSelector } from 'react-redux';

import { selectUserFeatureToggles } from 'actions/userActions';

const useFeature = (feature, currentUsages, newUsages) => {
  const userFeatureToggles = useSelector(selectUserFeatureToggles);

  if (!userFeatureToggles) {
    return false;
  }

  const featureToggle = userFeatureToggles.find(toggle => toggle.name === feature);

  let enabled = !!featureToggle;

  if (featureToggle?.maxUsages) {
    enabled = enabled && currentUsages < featureToggle.maxUsages;
  }

  if (featureToggle?.maxUsages && newUsages) {
    const usagesAvailable = featureToggle.maxUsages - currentUsages;
    enabled = enabled && newUsages <= usagesAvailable;
  }

  return enabled;
};

export default useFeature;
