import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  deleteTestInfo: {
    margin: '0 0 20px',
  },
  warningMessage: {
    color: theme.errorColor,
    fontWeight: 600,
  },
}));
