import React, { Fragment, useCallback, useState } from 'react';
import { faCopy, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import lang from 'lang';
import { exerciseTypeString } from 'utils';

import ExploreExercisePreviewModal from '../ExploreExercisePreviewModal';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import FilterPoolItem from 'components/common/filter-selector/FilterPoolItem';
import QuillRenderer from 'components/common/QuillRenderer';
import Drop from 'components/exercises/exercise/exercise-answer/exercise-answer-filling/Drop';

import { containerMotion } from './motions';
import useStyles from './styles';

const ExploreExercise = ({ exercise, dataTour }) => {
  const classes = useStyles();

  const { title, statement, exerciseType, usages, subjects, years, gaps } = exercise;

  const [open, setOpen] = useState(false);
  const [copy, setCopy] = useState({
    state: false,
    from: null,
  });

  const openCopy = useCallback((from) => {
    setCopy({
      state: true,
      from: from,
    });
    setOpen(true);
  }, []);

  const close = useCallback((exit) => {

    setCopy({
      state: false,
      from: null,
    });

    if (copy.from !== 'modal' || exit) {
      setOpen(false);
    }

  }, [copy]);

  const processNode = (node) => {
    const drop = gaps.find(el => el.position === node.attribs['data-position']);
    return (
      <Drop
        answerId={exercise.id}
        drop={drop}
      />
    );
  };

  const instructions = [
    {
      shouldProcessNode: (node) => {
        return node.attribs && node.attribs['data-drop'];
      },
      processNode: processNode,
    },
  ];

  const getPills = (type, items) => {
    let color;
    let secondColor;

    if (!items) {
      return null;
    }
    if (type === 'subject') {
      color = '#CB6EB7';
      secondColor = '#FEDFF7';
    } else {
      color = '#F79649';
      secondColor = '#FCE9DA';
    }

    const content = (
      <div className={classes.innerPill}>
        <div className={cx(classes.pillLabel, { hasMore: items.length > 1 })}>
          {items[0]}
        </div>
        {items.length > 1 ?
          <div className={classes.pillPlus} style={{ backgroundColor: secondColor, color: color }}>
            {` +${items.length - 1}`}
          </div>
          : null}
      </div>
    );

    return (
      <FilterPoolItem poolItem={{ name: content, color }} selectable />
    );
  };

  return (
    <Fragment>
      <motion.div
        layoutId={exercise.id}
        initial="rest"
        whileHover="hover"
        variants={containerMotion}
        className={classes.exerciseContainer}
        onClick={() => setOpen(true)}
      >
        <Card dataTour={dataTour} className={classes.exerciseCard}>
          <motion.div
            className={classes.exerciseContent}
          >

            <div className={classes.exerciseHeader}>

              <motion.div className={classes.exerciseType}>
                <span
                  className={classes.exerciseTypeText}
                  title={exerciseTypeString(exerciseType)}
                >
                  {lang.appKeywords.exercise}
                  <span> &#8226; </span>
                  {exerciseTypeString(exerciseType)}
                </span>
              </motion.div>

              <motion.div className={classes.exerciseUsages}>
                <FontAwesomeIcon
                  icon={faUserGroup}
                  className={classes.exerciseUsagesIcon}
                />
                {usages}
              </motion.div>
            </div>
            <motion.div className={classes.exerciseTitle}>
              {title}
            </motion.div>
            <div className={classes.filters}>
              {getPills('subject', subjects)}
              {getPills('year', years)}
            </div>
            <div className={classes.exerciseStatementContainer}>
              <QuillRenderer
                value={statement}
                className={classes.exerciseStatement}
                instructions={instructions}
              />
            </div>
          </motion.div>
          <Button className={classes.useButton} onClick={() => openCopy('list')}>
            <FontAwesomeIcon icon={faCopy} className={classes.icon} />
          </Button>
        </Card>
      </motion.div>
      <AnimatePresence>
        <ExploreExercisePreviewModal
          dataTour={`${dataTour}-preview-modal`}
          open={open}
          close={close}
          exercise={exercise}
          copy={copy}
          openCopy={() => openCopy('modal')}
        />
      </AnimatePresence>
    </Fragment >
  );
};

ExploreExercise.propTypes = {
  exercise: PropTypes.object,
  dataTour: PropTypes.string,
};

export default ExploreExercise;
