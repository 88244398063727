import { createUseStyles } from 'react-jss';

export default createUseStyles({
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  btn: {
    marginLeft: '6px',
  },
  cardWrapper: {
    display: 'flex',
  },
  sectionTimerWrapper: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  navigationHeaderContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
});
