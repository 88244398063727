import React, { useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import { quillIsEmpty } from 'utils';

import ExerciseForm from '../../../exercises/exercise-form/ExerciseForm';
import AddContentContainer from '../AddContentContainer';

const AddPauseItem = ({ cancel, afterAdd }) => {
  const { testId } = useParams();
  const toast = useToast();
  const [addPauseItemRequest, loading] = useApi(api.addPauseItem);

  const [statement, setStatement] = useState({});

  const save = () => {
    if (quillIsEmpty(statement)) {
      toast.warning(lang.exerciseForm.noEmptyStatement);
      return;
    }

    addPauseItemRequest([testId], { statement }, ({ data }) => {
      if (data.status === 0) {
        toast.success(lang.test.exercises.successAddPauseItem);
        afterAdd();
        return;
      }

      toast.error(lang.oops);
    });
  };

  const actions = [
    {
      label: lang.cancel,
      color: 'black',
      onClick: cancel,
    },
    {
      label: lang.confirm,
      onClick: save,
      loading: loading,
    },
  ];

  return (
    <AddContentContainer
      title={lang.test.exercises.addPauseItem}
      goBack={cancel}
      actions={actions}
    >
      <ExerciseForm
        type="pause"
        statement={statement}
        setStatement={setStatement}
        edit
      />
    </AddContentContainer>
  );
};

AddPauseItem.propTypes = {
  cancel: PropTypes.func,
  afterAdd: PropTypes.func,
};

export default AddPauseItem;
