import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { faCog, faCopy, faEye, faLock, faLockOpen, faPaperPlane, faPen, faTrash, faUser, faUserGroup, faVideo, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { v4 } from 'uuid';

import { incrementTotalExercises, selectUserFeatureToggleMaxUsages, selectUserSubjects, selectUserTotalFreeExercises, selectUserYears } from 'actions/userActions';
import api from 'api';
import { CONNECTING, SEGMENTATION } from 'constants/exerciseTypes';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useLoading from 'hooks/common/useLoading';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { exerciseTypeString, exerciseTypeIcon } from 'utils';

import DeleteExercisesModal from '../../DeleteExercisesModal';
import PreviewExerciseModal from '../../PreviewExerciseModal';
import SendExercisesModal from '../../SendExercisesModal';
import ExerciseLabels from '../ExerciseLabels';
import TemplateEntityCard from 'components/common/entity/TemplateEntityCard';
import Loading from 'components/common/Loading';
import PlansPill from 'components/common/plans/PlansPill';
import UpgradePremiumModal from 'components/common/plans/UpgradePremiumModal';
import QuillRenderer from 'components/common/QuillRenderer/index.js';
import Drop from 'components/exercises/exercise/exercise-answer/exercise-answer-filling/Drop';
import ExerciseSettingsModal from 'components/exercises/exercise-form/ExerciseSettingsModal';
import EntityInformation from 'components/tests/Entitynformation';

import useStyles from './styles';

const SingleExercise = ({ exercise, isSelected, check, selectable, wrapped, refresh, setFilters, labelOptions, groupId, fetchLabels, groups, dataTour }) => {
  const classes = useStyles();
  const toast = useToast();
  const history = useHistory();
  const { spaceId } = useParams();
  const exploreToggle = useFeature(toggles.explore);
  const [editTemplateExerciseSettingsRequest] = useApi(api.editTemplateExerciseSettings);
  const [duplicateTemplateExerciseRequest] = useApi(api.duplicateTemplateExercise);
  const [editTemplateExerciseLabelsRequest] = useApi(api.editTemplateExerciseLabels);
  const totalFreeExercises = useSelector(selectUserTotalFreeExercises);
  const createExerciseToggle = useFeature(toggles.createExercise, totalFreeExercises);
  const dispatch = useDispatch();
  const createExerciseToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createExercise));
  const connectingToggle = useFeature(toggles.connecting);
  const segmentationToggle = useFeature(toggles.segmentation);
  const userId = useSelector(state => state.user.data.id);
  const userSubjects = useSelector(selectUserSubjects);
  const userYears = useSelector(selectUserYears);

  const [loading, setLoading] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [media, setMedia] = useState([]);
  const [upgradePremiumModal, setUpgradePremiumModal] = useState(false);
  const [updateLabelsLoading, setUpdateLabelsLoading] = useLoading(false);

  const [exerciseSettings, setExerciseSettings] = useState({
    title: exercise?.title,
    isPublic: !!exercise.publicItemId,
    subjects: exercise.subjects?.map(el => el.id) ?? userSubjects.map(userSubject => userSubject.id),
    years: exercise.years?.map(el => el.id) ?? userYears.map(userYear => userYear.id),
  });

  const deleteDisabled = useMemo(() => {
    const isPersonalGroup = groups?.find(group => group.id === groupId && group.isPersonal);
    return exercise.authorId !== userId && !isPersonalGroup;
  }, [exercise, userId, groups, groupId]);

  const _close = () => {
    setTimeout(() => {
      setExerciseSettings({
        title: exercise?.title,
        isPublic: !!exercise.publicItemId,
        subjects: exercise.subjects?.map(el => el.id) ?? [],
        years: exercise.years?.map(el => el.id) ?? [],
      });
    }, 200);
    setShowSettingsModal(false);
  };

  const saveExerciseSettings = () => {
    setLoading(true);
    editTemplateExerciseSettingsRequest([exercise.id], exerciseSettings, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        refresh();
        setShowSettingsModal(false);
      } else {
        toast.error(lang.oops);
      }
    });
  };

  const checkExercise = (event) => {
    if (event.target.tagName === 'IMG') {
      return;
    }

    if (!(
      event.target.classList.toString().includes('exerciseContent') ||
      event.target.classList.toString().includes('exerciseStatement') ||
      event.target.classList.toString().includes('checkboxWrapper') ||
      event.target.classList.toString().includes('exerciseCheckbox') ||
      event.target.classList.toString().includes('infoText') ||
      event.target.tagName === 'path'
    )) {
      return;
    }

    check(exercise);
  };

  const duplicateTemplateExercise = () => {
    if (!createExerciseToggle) {
      setUpgradePremiumModal(true);
      return;
    }

    setLoading(true);
    if (exercise) {
      duplicateTemplateExerciseRequest([exercise.id], null, ({ data }) => {
        setLoading(false);
        if (data.status === 0) {
          if (spaceId === PERSONAL_SPACE) {
            dispatch(incrementTotalExercises(1));
          }
          toast.success(lang.exercises.successDuplicateExercise);
          refresh();
          return;
        }

        toast.error(lang.oops);
      });
    }
  };

  const updateExerciseLabels = (labels) => {
    if (updateLabelsLoading) {
      return;
    }

    setUpdateLabelsLoading(true);
    editTemplateExerciseLabelsRequest([exercise.id], { labels: labels.map(label => (label.id)) }, ({ data }) => {
      if (data.status === 0) {
        refresh();
        setUpdateLabelsLoading(false);
      } else {
        toast.error(lang.oops);
      }
    });
  };

  const getNotSelectableModals = () => {
    if (selectable) {
      return (
        <PreviewExerciseModal
          open={showPreviewModal}
          close={() => setShowPreviewModal(false)}
          templateExerciseId={exercise.id}
          refresh={refresh}
        />
      );
    }

    return (
      <Fragment>
        <UpgradePremiumModal
          open={upgradePremiumModal}
          close={() => setUpgradePremiumModal(false)}
          message={lang.plans.exceededExercises(createExerciseToggleMaxUsages)}
        />
        <DeleteExercisesModal
          open={showDeleteModal}
          close={() => setShowDeleteModal(false)}
          templateExerciseIds={[exercise.id]}
          refresh={refresh}
        />
        <SendExercisesModal
          open={showSendModal}
          close={() => setShowSendModal(false)}
          selectedExercises={[exercise]}
          groupId={groupId}
          groups={groups}
          refresh={refresh}
        />
        <PreviewExerciseModal
          open={showPreviewModal}
          close={() => setShowPreviewModal(false)}
          templateExerciseId={exercise.id}
          refresh={refresh}
        />
        <ExerciseSettingsModal
          open={showSettingsModal}
          close={_close}
          exerciseSettings={exerciseSettings}
          setExerciseSettings={setExerciseSettings}
          save={saveExerciseSettings}
        />
      </Fragment>
    );
  };

  const processNode = (node) => {

    const drop = {
      id: v4(),
      position: node.attribs['data-position'],
    };

    return (
      <Drop
        answerId={exercise.id}
        drop={drop}
      />
    );
  };

  const getExerciseTitle = () => {
    if (exercise.title) {
      return (
        <div className={classes.exerciseStatement}>
          {exercise.title}
        </div>
      );
    }

    const instructions = [
      {
        shouldProcessNode: (node) => {
          return node.attribs && node.attribs['data-drop'];
        },
        processNode: processNode,
      },
    ];

    return (
      <QuillRenderer
        className={classes.exerciseStatement}
        value={exercise.statement}
        instructions={instructions}
      />
    );
  };

  let actions;
  let extraActions;
  if (!selectable) {
    actions = [
      {
        icon: faEye,
        label: lang.preview,
        onClick: () => setShowPreviewModal(true),
        hideMedia: 'xs',
      },
      {
        icon: faPen,
        label: lang.edit,
        onClick: () => history.push(routes.editExercise.ref(spaceId, exercise.id)),
        hideMedia: 'sm',
      },
      {
        icon: faPaperPlane,
        label: lang.send,
        onClick: () => setShowSendModal(true),
        hideMedia: 'md',
      },
      {
        icon: faCopy,
        label: lang.duplicate,
        onClick: duplicateTemplateExercise,
        loading: loading,
        hideMedia: 'md',
      },
    ];

    extraActions = [
      {
        icon: faCog,
        label: lang.settings,
        onClick: () => setShowSettingsModal(true),
        hide: !exploreToggle,
      },
      {
        icon: faTrash,
        label: lang.delete,
        onClick: () => setShowDeleteModal(true),
        disabled: deleteDisabled,
        tip: deleteDisabled ? lang.deleteDisabled : null,
      },
    ];
  } else {
    actions = [
      {
        icon: faEye,
        label: lang.preview,
        onClick: () => setShowPreviewModal(true),
      },
    ];
  }

  const getMedia = () => {
    if (media.length === 0) {
      return [];
    }

    return media.map((media) => ({
      icon: media.icon,
      label: media.label,
    }));
  };

  useEffect(() => {
    const tempMedia = [];
    exercise.statement.ops.forEach(op => {
      if (op.insert.video && tempMedia.findIndex(el => el.icon === faVideo) === -1) {
        tempMedia.push({
          icon: faVideo,
          label: lang.video,
        });
      }
      if (op.insert.audio && tempMedia.findIndex(el => el.icon === faVolumeUp) === -1) {
        tempMedia.push({
          icon: faVolumeUp,
          label: lang.video,
        });
      }
    });

    if (exercise.choices) {
      exercise.choices.forEach(choice => {
        choice.value.ops.forEach(op => {
          if (op.insert.video && tempMedia.findIndex(el => el.icon === faVideo) === -1) {
            tempMedia.push({
              icon: faVideo,
              label: lang.video,
            });
          }
          if (op.insert.audio && tempMedia.findIndex(el => el.icon === faVolumeUp) === -1) {
            tempMedia.push({
              icon: faVolumeUp,
              label: lang.video,
            });
          }
        });
      });
    }
    setMedia(tempMedia);
  }, [exercise]);

  const exerciseInformations = [
    {
      icon: exerciseTypeIcon(exercise.type),
      label: exerciseTypeString(exercise.type),
    },
    {
      icon: faUser,
      label: exercise.authorName,
    },
    ...getMedia(),
    {
      icon: exercise.publicItemId ? faLockOpen : faLock,
      label: exercise.publicItemId ? lang.exerciseForm.exerciseSettings.public : lang.exerciseForm.exerciseSettings.private,
      hide: !exploreToggle,
    },
    ...(exercise.publicItemId ?
      [
        {
          icon: faUserGroup,
          label: exercise.usages + ' ' + lang.copies,
          hide: !exploreToggle,
        },
      ]
      : []),
    ...(!connectingToggle ?
      [
        {
          label: (
            <Render when={(exercise.type === CONNECTING && !connectingToggle) || (exercise.type === SEGMENTATION && !segmentationToggle)}>
              <div className={classes.premiumPill}>
                <PlansPill
                  tip={lang.plans.warningCreatePremiumExercise}
                />
              </div>
            </Render>
          ),
        },
      ]
      : []),
  ];

  return (
    <TemplateEntityCard
      dataTour={dataTour}
      className={cx(classes.exerciseCard, { wrapped })}
      isSelected={isSelected}
      selectable
      actions={actions}
      extraActions={extraActions}
      onClick={checkExercise}
    >
      {getNotSelectableModals()}
      <div
        className={cx(classes.exerciseContent, { isSelected, selectable })}
      >
        <div>
          <div className={classes.topContainer}>
            <ExerciseLabels
              groupId={groupId}
              exerciseLabels={exercise.labels}
              labelOptions={labelOptions}
              refresh={refresh}
              selectable={selectable}
              setFilters={setFilters}
              fetchLabels={fetchLabels}
              labelAction={updateExerciseLabels}
            />
            <Loading
              active={updateLabelsLoading}
              className={classes.labelsLoading}
            />
          </div>
          {getExerciseTitle()}
          <div className={classes.infoWrapper}>
            <EntityInformation
              informations={exerciseInformations}
            />
          </div>
        </div>
      </div>
    </TemplateEntityCard>
  );
};

SingleExercise.propTypes = {
  exercise: PropTypes.shape({
    exerciseId: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    publicItemId: PropTypes.string,
    usages: PropTypes.number,
    subjects: PropTypes.array,
    years: PropTypes.array,
    type: PropTypes.string,
    statement: PropTypes.object,
    authorName: PropTypes.string,
    authorId: PropTypes.string,
    labels: PropTypes.array,
    choices: PropTypes.array,
  }),
  isSelected: PropTypes.bool,
  check: PropTypes.func,
  selectable: PropTypes.bool,
  wrapped: PropTypes.bool,
  refresh: PropTypes.func,
  setFilters: PropTypes.func.isRequired,
  labelOptions: PropTypes.array,
  groupId: PropTypes.string,
  fetchLabels: PropTypes.func,
  groups: PropTypes.array,
  dataTour: PropTypes.string,
};

export default SingleExercise;
