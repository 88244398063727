import { createUseStyles } from 'react-jss';

export default createUseStyles({
  formulaWrapper: {
    display: 'inline-block',
    '&:not(.disabled)': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#cecece',
        borderRadius: '5px',
      },
    },
  },
});
