import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  noGroupsContainer: {
    width: '100%',
    textAlign: 'center',
    marginTop: '100px',
  },
  noGroupsLabel: {
    fontSize: '22px',
    fontWeight: '600',
    opacity: 0.4,
    marginBottom: '20px',
  },
  noGroupButton: {
    fontSize: '22px',
    padding: '20px 30px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    noGroupsLabel: {
      marginLeft: '5%',
      marginRight: '5%',
    },
  },
}));
