import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { INITIAL_STATE, attemptReducer, setCommentHovered, setCommenting, setEditingComment, setTextAnnotationFocused, setTextAnnotationHovered } from './reducer';

export const AttemptContext = createContext(null);

export const useAttemptContext = () => {
  return useContext(AttemptContext);
};

export const AttemptProvider = ({ children }) => {
  const [attempt, dispatch] = useReducer(attemptReducer, INITIAL_STATE);

  const setCommentingHandler = useCallback((commenting, exerciseId = null) => {
    dispatch(setCommenting(commenting, exerciseId));
  }, []);

  const setTextAnnotationHoveredHandler = useCallback((textAnnotationId) => {
    dispatch(setTextAnnotationHovered(textAnnotationId));
  }, []);

  const setTextAnnotationFocusedHandler = useCallback((textAnnotationId) => {
    dispatch(setTextAnnotationFocused(textAnnotationId));
  }, []);

  const setCommentHoveredHandler = useCallback((textAnnotationId) => {
    dispatch(setCommentHovered(textAnnotationId));
  }, []);

  const setEditingCommentHandler = useCallback((editingComment) => {
    dispatch(setEditingComment(editingComment));
  }, []);

  const context = useMemo(() => ({
    ...attempt,
    setCommenting: setCommentingHandler,
    setTextAnnotationHovered: setTextAnnotationHoveredHandler,
    setTextAnnotationFocused: setTextAnnotationFocusedHandler,
    setCommentHovered: setCommentHoveredHandler,
    setEditingComment: setEditingCommentHandler,
  }), [attempt, setCommentHoveredHandler, setCommentingHandler, setEditingCommentHandler, setTextAnnotationFocusedHandler, setTextAnnotationHoveredHandler]);

  return (
    <AttemptContext.Provider value={context}>
      {children}
    </AttemptContext.Provider>
  );
};

AttemptProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
