import React from 'react';
import { Button as OLButton } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Button = ({ type, onClick, black, red, green, gray, medium, large, stretch, loading, disabled, sibling, children, className, dataTour, style }) => {
  const classes = useStyles();

  return (
    <OLButton
      data-tour={dataTour}
      type={type}
      onClick={onClick}
      black={black}
      red={red}
      green={green}
      medium={medium}
      large={large}
      stretch={stretch}
      loading={loading}
      disabled={disabled}
      className={cx(classes.button, { black, red, green, gray, sibling }, className)}
      style={style}
    >
      {children}
    </OLButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  black: PropTypes.bool,
  red: PropTypes.bool,
  green: PropTypes.bool,
  gray: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  stretch: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  sibling: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  dataTour: PropTypes.string,
};

export default Button;
