import { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';

import api from 'api';
import { STUDENT_LOGIN_STRATEGY } from 'constants/cookies';
import { ATTEMPT } from 'constants/localStorageItems';
import { STUDENT_MICROSOFT_STRATEGY } from 'constants/loginStrategies';
import useApi from 'hooks/common/useApi';
import routes from 'routes';
import { addCookie } from 'utils';

const MicrosoftStudentLoginRedirect = () => {
  const [createAttemptRequest] = useApi(api.createAttempt, true);
  const { inProgress, accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0]);
  const history = useHistory();

  useEffect(() => {
    instance.handleRedirectPromise().then((response) => {
      if (inProgress === InteractionStatus.None && isAuthenticated) {
        const state = JSON.parse(response.state);
        const payload = {
          email: account.username,
          userName: account.name,
          publicationId: state.publicationId,
          password: state.password,
        };

        createAttemptRequest([], payload, ({ data }) => {
          if (data.status === 0) {
            addCookie(STUDENT_LOGIN_STRATEGY, STUDENT_MICROSOFT_STRATEGY);

            localStorage.setItem(ATTEMPT, JSON.stringify({
              id: data.attempt.id,
              createdAt: data.attempt.createdAt,
              endsAt: data.attempt.endsAt,
              publicationId: state.publicationId,
              name: data.attempt.userName,
            }));

            history.push(routes.attempt.ref(data.attempt.id));
          }
        });
      } else {
        history.push(routes.publication.ref(response.state));
      }
    });
  }, [instance, inProgress, history, account, createAttemptRequest, isAuthenticated]);

  return null;
};
export default MicrosoftStudentLoginRedirect;
