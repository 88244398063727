/**
 * Action constants
 */
const SET_TEST = 'SET_TEST';
const UPDATE_POINTS = 'UPDATE_POINTS';
const PUBLISH_TEST = 'PUBLISH_TEST';
const ADD_TEST_SECTION = 'ADD_TEST_SECTION';
const EDIT_TEST_SECTION = 'EDIT_TEST_SECTION';
const SET_TEST_ITEMS = 'SET_TEST_ITEMS';
const REMOVE_TEST_ITEM = 'REMOVE_TEST_ITEM';

/**
 * Action creators
 */
const setTest = (test) => ({
  type: SET_TEST,
  test: test,
});

const updatePoints = (itemId, points, sectionId) => ({
  type: UPDATE_POINTS,
  itemId: itemId,
  points: points,
  sectionId: sectionId,
});

const publishTest = (publication) => ({
  type: PUBLISH_TEST,
  publication: publication,
});

const addTestSection = (section) => ({
  type: ADD_TEST_SECTION,
  section: section,
});

const editTestSection = (sectionId, section) => ({
  type: EDIT_TEST_SECTION,
  sectionId: sectionId,
  section: section,
});

const setTestItems = (items) => ({
  type: SET_TEST_ITEMS,
  items: items,
});

const removeTestItem = (itemId, sectionId) => ({
  type: REMOVE_TEST_ITEM,
  itemId: itemId,
  sectionId: sectionId,
});

/**
 * Selectors
 */
const selectTest = (state) => {
  return state.test;
};

const selectTestName = (state) => {
  return state.test.name;
};

const selectTestIsPublished = (state) => {
  return state.test.isPublished;
};

const selectTestGroupId = (state) => {
  return state.test.groupId;
};

const selectTestGroupName = (state) => {
  return state.test.groupName;
};

const selectTestClassificationType = (state) => {
  return state.test.classificationType;
};

const selectTestItems = (state) => {
  return state.test.items;
};

const selectTestRubric = (state) => {
  return state.test.rubric;
};

const selectTestPublication = (state) => {
  return state.test.publication;
};

const selectTestSection = (id) => {
  return (state) => {
    const section = state.test.items.find((item) => item.id === id && item.itemType === 'section');

    if (!section) {
      return {};
    }

    section.sectionTotal = section.exercises ? section.exercises.reduce((partialSum, exercise) => partialSum + parseFloat(exercise.points), 0) : 0;
    return section;
  };
};

const selectTestExercise = (id, sectionId) => {
  return (state) => {
    if (sectionId) {
      const section = state.test.items
        .find((item) => item.id === sectionId && item.itemType === 'section');

      if (!section) {
        return {};
      }

      const testSectionExercise = section.exercises
        .find((exercise) => exercise.id === id);

      return testSectionExercise || {};
    }

    const testItem = state.test.items
      .find((item) => item.id === id && item.itemType === 'exercise');

    return testItem || {};
  };
};

export {
  SET_TEST,
  UPDATE_POINTS,
  PUBLISH_TEST,
  ADD_TEST_SECTION,
  EDIT_TEST_SECTION,
  SET_TEST_ITEMS,
  REMOVE_TEST_ITEM,
  setTest,
  updatePoints,
  publishTest,
  addTestSection,
  editTestSection,
  setTestItems,
  removeTestItem,
  selectTest,
  selectTestName,
  selectTestIsPublished,
  selectTestGroupId,
  selectTestGroupName,
  selectTestClassificationType,
  selectTestItems,
  selectTestRubric,
  selectTestPublication,
  selectTestSection,
  selectTestExercise,
};
