import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';

import Loading from 'components/common/Loading';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const EntityActions = ({ actions, selected, selectable, setSelected, groupId, entities }) => {
  const classes = useStyles();

  const [selectAll, setSelectAll] = useState(false);

  const itemAction = (action, disable) => {
    if (disable) {
      return;
    }
    action();
  };

  const getActions = () => {
    if (!actions || actions.length === 0) {
      return false;
    }

    return actions.map((action) => (
      <div
        key={action.label}
        className={classes.actions}
      >
        <Tooltip tip={action.tip}>
          <div
            className={cx(classes.optionsBtn, { isPublished: action.isPublished, disabled: action.disabled })}
            onClick={() => itemAction(action.onClick, action.disabled || action.loading)}
          >
            <Loading active={action.loading} className={classes.optionLoading} />
            <Render when={!action.loading}>
              <FontAwesomeIcon
                icon={action.icon}
              />
            </Render>
            <div className={classes.optionText}>
              {action.label}
            </div>
          </div>
        </Tooltip>
      </div>
    ));
  };

  const onClick = () => {
    if (!selectAll) {
      const allSelected = entities.filter(entity => !selected.find(selected => selected.id === entity.id));
      setSelected([...selected, ...allSelected]);
    } else {
      const allSelected = selected.filter(elem => !entities.find(entity => entity.id === elem.id));
      setSelected(allSelected);
    }
  };

  useEffect(() => {
    const allSelected = entities.every(entity => selected.find(elem => elem.id === entity.id));
    setSelectAll(allSelected);
  }, [selected, groupId, entities]);

  return (
    <div className={classes.actionWrapper}>
      <input
        type="checkbox"
        checked={selectAll}
        onClick={onClick}
        className={classes.exerciseCheckbox}
      />
      <Render when={!selected || !selected.length || selectable}>
        <div className={classes.selectAll}>
          {lang.selectAll}
        </div>
      </Render>
      <Render when={selected && selected.length && !selectable}>
        {getActions()}
      </Render>
    </div>
  );
};

EntityActions.propTypes = {
  actions: PropTypes.array,
  selected: PropTypes.array,
  selectable: PropTypes.bool,
  setSelected: PropTypes.func,
  groupId: PropTypes.string,
  entities: PropTypes.array,
};

export default EntityActions;
