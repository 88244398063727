/**
 * All expressions to be used in
 * formula helper buttons.
 */
const expressions = {};

expressions.operators = [
  { icon: '+', write: '+', added: true },
  { icon: '-', write: '-', added: true },
  { icon: '\\pm', cmd: '\\pm', added: true },
  { icon: '\\mp', cmd: '\\mp', added: true },
  { icon: '\\times', cmd: '\\times', added: true },
  { icon: '\\div', cmd: '\\div', added: true },
  { icon: '\\slash', cmd: '\\slash', added: true },
  { icon: '=', write: '=', added: true },
  { icon: '\\approx', cmd: '\\approx', added: true },
  { icon: '\\cong', cmd: '\\cong', added: true },
  { icon: '\\neq', cmd: '\\neq', added: true },
  { icon: '\\simeq', cmd: '\\simeq', added: true },
  { icon: '<', write: '<', added: true },
  { icon: '>', write: '>', added: true },
  { icon: '\\leq', cmd: '\\leq', added: true },
  { icon: '\\geq', cmd: '\\geq', added: true },
  { icon: '\\rightarrow', cmd: '\\rightarrow', added: true },
  { icon: '\\leftarrow', cmd: '\\leftarrow', added: true },
  { icon: '\\neg', cmd: '\\neg', added: true },
  { icon: '\\therefore', cmd: '\\therefore', added: true },
  { icon: '\\leftrightarrow', write: '\\leftrightarrow', added: true },
  { icon: '\\mapsto', write: '\\mapsto', added: true },
  { icon: '\\Rightarrow', write: '\\Rightarrow', added: true },
  { icon: '\\Leftarrow', write: '\\Leftarrow', added: true },
  { icon: '\\Longleftrightarrow', write: '\\Longleftrightarrow', added: true },
  { icon: '\\equiv', write: '\\equiv', added: true },
  { icon: '\u21CC', write: '\u21CC', name: 'rightleftharpoons', added: true },
  { icon: '\u21CB', write: '\u21CB', name: 'leftrightharpoons', added: true },
];

expressions.specials = [
  { icon: 'a^2', write: '^2', added: true },
  { icon: 'a^x', cmd: '^', added: true },
  { icon: 'a_x', cmd: '_', added: true },
  { icon: '\\sqrt{x}', cmd: '\\sqrt', added: true },
  { icon: '\\sqrt[n]{x}', write: '\\sqrt[n]{x}', after: 'Left', added: true },
  { icon: '\\frac{a}{x}', cmd: '\\dfrac', fontSize: 10, added: true },
  { icon: '\\left|x\\right|', write: '\\left|\\right|', after: 'Left', added: true },
  { icon: 'X^2_2', write: 'X^2_2', fontSize: 13, added: true },
  {
    icon: '^2_2X', multiple: [
      { write: '^2' },
      { write: '_2' },
      { write: 'X' },
    ],
    fontSize: 13, added: true,
  },
  { icon: '\\sin', write: '\\sin()', after: 'Left', added: true },
  { icon: '\\cos', write: '\\cos()', after: 'Left', added: true },
  { icon: '\\tan', write: '\\tan()', after: 'Left', added: true },
  { icon: '\\sin^{-1}', write: '\\sin^{-1}()', after: 'Left', width: 84, added: true },
  { icon: '\\cos^{-1}', write: '\\cos^{-1}()', after: 'Left', width: 84, added: true },
  { icon: '\\tan^{-1}', write: '\\tan^{-1}()', after: 'Left', width: 84, added: true },
  { icon: '\\log', write: '\\log()', after: 'Left', added: true },
  { icon: '\\log_{x}', write: '\\log_{ }', after: 'Left', fontSize: 14, added: true },
  { icon: '10^x', write: '10^{ }', after: 'Left', added: true },
  { icon: '\\ln', write: '\\ln()', after: 'Left', added: true },
  { icon: '\\mathrm{e}', write: '\\mathrm{e}', added: true },
  { icon: '\\sum_{k}^N', write: '\\sum_{k}^N', after: 'Left', fontSize: 9, added: true },
  { icon: '\\lim_{x}', write: '\\lim_{ }', after: 'Left', fontSize: 14, added: true },
  { icon: '\\overrightarrow{X A}', write: '\\overrightarrow{X A}', after: 'Left', added: true },
  { icon: 'x^\\circ', write: '{ }^\\circ', after: 'Left', added: true },
  { icon: '\\bar{x}', write: '\\bar{ }', after: 'Left', added: true },
  { icon: '\\dot{x}', write: '\\dot{ }', after: 'Left', fontSize: 17, added: true },
  { icon: '\\hat{x}', write: '\\hat{ }', after: 'Left', added: true },
  // { icon: '\\overarc{AB}', write: '\\overarc{ }', name: 'overarc', added: true },
  { icon: '\\overarc{AB}', write: '\\overarc{ }', name: 'overarc', added: true },
  { icon: '\\begin{cases} x \\\\ y \\\\ z \\end{cases}', write: '\\begin{cases} x \\\\ y \\\\ z \\end{cases}', name: 'cases_3', height: 84, added: true },
  { icon: '\\begin{cases} x \\\\ y \\end{cases}', write: '\\begin{cases} x \\\\ y \\end{cases}', name: 'cases_2', height: 84, added: true },
];

expressions.symbols = [
  { icon: '\\pi', cmd: '\\pi', added: true },
  { icon: '\\Delta', cmd: '\\Delta', added: true },
  { icon: '\\lambda', cmd: '\\lambda', added: true },
  { icon: '\\alpha', cmd: '\\alpha', added: true },
  { icon: '\\beta', cmd: '\\beta', added: true },
  { icon: '\\theta', cmd: '\\theta', added: true },
  { icon: '\\rho', cmd: '\\rho', added: true },
  { icon: '\\Omega', cmd: '\\Omega', added: true },
  { icon: '\\mu', cmd: '\\mu', added: true },
  { icon: '\\chi', cmd: '\\chi', added: true },
  { icon: '\\eta', cmd: '\\eta', added: true },
  { icon: '\\phi', cmd: '\\phi', added: true },
  { icon: '\\epsilon', cmd: '\\epsilon', added: true },
  { icon: '\\sigma', cmd: '\\sigma', added: true },
  { icon: '\\omega', cmd: '\\omega', added: true },
  { icon: '\\gamma', cmd: '\\gamma', added: true },
  { icon: '\\delta', cmd: '\\delta', added: true },
  { icon: '\\tau', cmd: '\\tau', added: true },
  { icon: '\\varphi', cmd: '\\varphi', added: true },
];

expressions.sets = [
  { icon: '\\notin', write: '\\notin', added: true },
  { icon: '\\nsubseteq', write: '\\nsubseteq', added: true },
  { icon: '\\nsupseteq', write: '\\nsupseteq', added: true },
  { icon: '\\subset', write: '\\subset', added: true },
  { icon: '\\supset', write: '\\supset', added: true },
  { icon: '\\notsubset', write: '\\notsubset', added: true },
  { icon: '\\notsupset', write: '\\notsupset', added: true },
  { icon: '\\subseteq', write: '\\subseteq', added: true },
  { icon: '\\supseteq', write: '\\supseteq', added: true },
  { icon: '\\cap', write: '\\cap', added: true },
  { icon: '\\cup', write: '\\cup', added: true },
  { icon: '\\in', write: '\\in', added: true },
  { icon: '\\ni', write: '\\ni', added: true },
  { icon: '\\wedge', write: '\\wedge', added: true },
  { icon: '\\vee', write: '\\vee', added: true },
  { icon: '\\forall', write: '\\forall', added: true },
  { icon: '\\exists', write: '\\exists', added: true },
  { icon: '\\nexists', write: '\\nexists', added: true },
  { icon: '\\emptyset', write: '\\emptyset', added: true },
  { icon: '\\infty', write: '\\infty', added: true },
  { icon: '%', write: '%', added: true },
  { icon: '|', write: '|', added: true },
  { icon: '\\backslash', write: '\\backslash', added: true },
  { icon: '\\wp', write: '\\wp', added: true },
];

expressions.numberSets = [
  { icon: '\\N', write: '\\N', added: true },
  { icon: '\\Z', write: '\\Z', added: true },
  { icon: '\\Q', write: '\\Q', added: true },
  { icon: '\\R', write: '\\R', added: true },
  { icon: '\\C', write: '\\C', added: true },
];

expressions.parentheses = [
  {
    icon: '({x})', multiple: [
      { cmd: ')', after: 'Left' },
      { cmd: '(' },
    ], added: true,
  },
  {
    icon: '[{x}]', multiple: [
      { cmd: ']', after: 'Left' },
      { cmd: '[' },
    ], added: true,
  },
  {
    icon: '\\left\\{x\\right\\}', multiple: [
      { cmd: '}', after: 'Left' },
      { cmd: '{' },
    ], added: true,
  },
  {
    icon: '\\parallel{x}\\parallel', multiple: [
      { cmd: '\\parallel', after: 'Left' },
      { cmd: ' ' },
      { cmd: '\\parallel' },
    ], added: true,
  },
  { icon: '[{x}[', write: '[ [', after: 'Left', added: true },
  { icon: ']{x}]', write: '] ]', after: 'Left', added: true },
  { icon: ']{x}[', write: '] [', after: 'Left', added: true },
];

export default expressions;
