import React, { useState, useCallback } from 'react';
import { faEye, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import { useTheme } from 'react-jss';

import lang from 'lang';
import { getQuestionType } from 'utils/statistics';

import PreviewTestExerciseModal from '../../physical-tab/answer-zone-settings/PreviewTestExerciseModal';
import PreviewTestAnswerModal from '../PreviewTestAnswerModal';
import QuestionTypeCharts from '../QuestionTypeCharts';
import ScoreCard from '../ScoreCard';
import EntityActionsContainer from 'components/common/entity/EntityActionsContainer';
import EntityBody from 'components/common/entity/EntityBody';
import EntityHeader from 'components/common/entity/EntityHeader';
import EntityTitle from 'components/common/entity/EntityTitle';

import useStyles from './styles';

export const ExerciseCards = ({ questions, tableRef }) => {

  const classes = useStyles();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPreviewModalId, setShowPreviewModalId] = useState(null);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [answerId, setAnswerId] = useState(null);
  const [attemptId, setAttemptId] = useState(null);
  const theme = useTheme();

  const handleRowClick = (id, answerId) => {
    setAnswerId(answerId);
    setAttemptId(id);
    setShowAnswerModal(true);
  };

  const cleanupAndClose = useCallback(() => {
    setShowAnswerModal(false);
    setAnswerId(null);
    setAttemptId(null);
  }, []);

  const columns = [
    {
      title: lang.test.stats.studentName,
      dataIndex: 'student',
    },
    {
      title: lang.test.stats.studentGrade,
      dataIndex: 'grade',
      sorter: {
        compare: (a, b) => parseFloat(a.grade) - parseFloat(b.grade),
      },
      defaultSortOrder: 'descend',
      render: (grade, record) => {
        if (record.highestRanking) {
          return (
            <div>
              <span>
                {grade}
                {' '}
                <FontAwesomeIcon icon={faTrophy} />
              </span>
            </div>
          );
        }
        return grade;
      },
    },
  ];

  const getGrades = (question) => {
    const grades = question.grades.map((grade) => ({
      ...grade,
      student: grade.userName,
      grade: grade.grade,
      answerId: grade.answerId,
      highestRanking: parseFloat(grade.grade) === Math.max(...question.grades.map(grade => parseFloat(grade.grade))),
    }));

    return grades.reduce((acc, grade) => ({
      ...acc,
      [grade.attemptId]: grade,
    }), {});
  };

  const getItems = () => {
    if (!questions ) {
      return null;
    }
    const items = questions.map((question) => {
      return (
        <div id={'card-' + question.testExerciseId} key={question.testExerciseId} ref={tableRef}>
          <EntityBody className={classes.testStatisticsCard}>
            <EntityHeader>
              <EntityTitle
                num={question.name + getQuestionType(question)}
                type={lang.test.stats.question}
              />
              <EntityActionsContainer>
                <div className={classes.eyePreview}>
                  <FontAwesomeIcon
                    icon={faEye}
                    onClick={() => {
                      setShowPreviewModal(true);
                      setShowPreviewModalId(question.testExerciseId);
                    }}
                  />
                </div>
                <div className={classes.maxGradeText}>
                  {question.maxGrade === '1.00'
                    ? +parseFloat(question.maxGrade) +
                      ' ' +
                      lang.test.grades.point
                    : parseFloat(question.maxGrade) +
                      ' ' +
                      lang.test.grades.points}
                </div>
              </EntityActionsContainer>
            </EntityHeader>
            <div className={classes.testStatistics}>
              <ScoreCard stats={question} total={question.maxGrade} />
              <QuestionTypeCharts question={question} />
            </div>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: theme.midnightGreen,
                  colorBgContainer: 'transparent',
                },
              }}
            >
              <Table
                ref={tableRef}
                className={classes.statsTable}
                columns={columns}
                dataSource={Object.keys(getGrades(question)).map((attemptId) => ({
                  ...getGrades(question)[attemptId],
                  id: attemptId,
                }))}
                pagination={false}
                scroll={{ y: 250 }}
                locale={{
                  triggerAsc: lang.test.stats.ascSort,
                  triggerDesc: lang.test.stats.descSort,
                  cancelSort: lang.test.stats.cancelSort,
                }}
                onRow={(record) => ({
                  onClick: () => handleRowClick(record.id, record.answerId),
                })}
              />
            </ConfigProvider>
          </EntityBody>
        </div>
      );
    });

    return items;
  };

  return (
    <>
      {getItems()}
      <PreviewTestAnswerModal
        open={showAnswerModal}
        close={() => cleanupAndClose()}
        answerId={answerId}
        attemptId={attemptId}
      />
      <PreviewTestExerciseModal
        open={showPreviewModal}
        close={() => {
          setShowPreviewModal(false);
          setShowPreviewModalId(null);
        }}
        testExerciseId={showPreviewModalId}
      />
    </>
  );
};

ExerciseCards.propTypes = {
  questions: PropTypes.array,
  tableRef: PropTypes.object,
};

export default ExerciseCards;
