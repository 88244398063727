import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  maxGradeText: {
    fontWeight: 600,
    fontSize: '14px',
    color: theme.textColor9,
    margin: '0 5px',
  },
  pointsText: {
    fontWeight: 600,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    marginRight: '4px',
    color: theme.textColor9,
  },
  graderWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5px',
  },
}));
