import React, { Fragment } from 'react';
import { Spacer } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import RubricFormStep from '../../RubricFormStep';
import Criteria from '../Criteria';
import Button from 'components/common/Button';

import useStyles from './styles';

const CriteriaStep = ({ getValue, setValue, getErrors, setErrors }) => {
  const classes = useStyles();

  const criteria = getValue('criteria');
  const criteriaErrors = getErrors('criteria');

  const addCriteria = () => {
    setValue('criteria', [
      ...criteria.map(criterion => ({ ...criterion, open: false })),
      {
        name: '',
        open: true,
        performanceLevels: [
          { level: 1, description: '' },
          { level: 2, description: '' },
          { level: 3, description: '' },
          { level: 4, description: '' },
          { level: 5, description: '' },
        ],
      },
    ]);
    setErrors('criteria', {
      ...criteriaErrors,
      criteria: [
        ...criteriaErrors.criteria,
        {
          name: [],
        },
      ],
    });
  };

  return (
    <RubricFormStep dataTour="rubric-criteria-step" header={lang.appKeywords.criteria}>
      <Render when={criteriaErrors.general.length > 0}>
        {criteriaErrors.general.map((error, index) => (
          <div
            key={index}
            className={classes.criteriaGeneralError}
          >
            {error}
          </div>
        ))}
      </Render>
      {criteria.map((criterion, criterionIndex) => (
        <Fragment key={criterion.id || criterionIndex}>
          <Criteria
            dataTour={`rubric-criteria-step-criteria-${criterionIndex}`}
            criterion={criterion}
            criterionIndex={criterionIndex}
            criteria={criteria}
            setCriteria={(value) => setValue('criteria', value)}
            setErrors={(value) => setErrors('criteria', value)}
            criteriaErrors={criteriaErrors}
          />
          <Spacer px={10} />
        </Fragment>
      ))}
      <Button dataTour="rubric-criteria-step-add-criteria-button" onClick={addCriteria}>
        {lang.rubrics.form.addCriteria}
      </Button>
    </RubricFormStep>
  );
};

CriteriaStep.propTypes = {
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  getErrors: PropTypes.func,
  setErrors: PropTypes.func,
};

export default CriteriaStep;
