import React, { useState } from 'react';
import { Icon, InputText, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserLoggedIn } from 'actions/userActions';
import api from 'api';
import { ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useInput from 'hooks/common/useInput';
import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const AskForPinModal = ({ open, close, attemptId }) => {
  const classes = useStyles();
  const toast = useToast();
  const [pin, setPin] = useInput('');
  const loggedIn = useSelector(selectUserLoggedIn);
  const [loading, setLoading] = useState(false);
  const [verifyExitPinRequest] = useApi(api.verifyExitPin, !loggedIn);

  const [showPassword, setShowPassword] = useState(false);

  const askForAppClose = async () => {
    await window.electron.closeApp();
  };

  const verifyExitPin = () => {
    setLoading(true);

    verifyExitPinRequest([attemptId], { exitPin: pin }, ({ data }) => {
      if (data.status === 0) {
        close();
        askForAppClose();
        return;
      }

      setLoading(false);
      if (data.status === 3) {
        toast.warning(lang.attempt.exitPinModal.wrongPin);
        return;
      }

      if (data.status === ERROR) {
        toast.error(lang.oops);

      }
    });
  };

  const actions = [
    {
      name: lang.confirm,
      color: 'red',
      loading: loading,
      onClick: verifyExitPin,
    },
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.attempt.exitPinModal.modalHeader}
      actions={actions}
      center
      transition
    >
      <InputText
        suffix={
          <Icon
            className={classes.icon}
            icon={showPassword ? 'eye-slash' : 'eye' }
            onClick={() => setShowPassword(!showPassword)}
          />
        }
        type={showPassword ? 'text' : 'password'}
        label={lang.publication.password}
        placeholder={lang.publication.password}
        _onChange={(value) => setPin(value)}
        value={pin}
      />
    </Modal>
  );
};

AskForPinModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  attemptId: PropTypes.string,
};

export default AskForPinModal;
