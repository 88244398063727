import { createUseStyles } from 'react-jss';

export default createUseStyles({
  tableWrapper: {
    padding: ['1.2rem', '1.2rem', '1.2rem', '0'],
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  addColumnButton: {
    transition: 'height 0.26s',
    transitionTimingFunction: 'ease-in-out',
    '&:hover': {
      backgroundColor: '#419BB4 !important',
    },
  },
  addColumnButtonWrapper: {
    marginLeft: '0.5em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2.75rem',
    '&:hover': {
      '& > button': {
        height: '100%',
      },
    },
  },
  addRowButton: {
    transition: 'width 0.3s',
    transitionTimingFunction: 'ease-in-out',
    width: '37px',
    '&:hover': {
      backgroundColor: '#419BB4 !important',
    },
  },
  addRowButtonWrapper: {
    width: 'calc(100% - 37px - 3.9em) !important',
    margin: '0 1.5em 1.2em 1.5em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      '& > button': {
        width: '100%',
      },
    },
  },
  tableHighlight: {
    border: '1px solid #419BB4 !important',
  },
  table: {
    width: 'calc(100% - 37px - 1.4em) !important',
    height: 'calc(100% - 37px - 1.4em) !important',
    '& .ant-table-container': {
      borderTop: 'none !important',
      borderInlineStart: 'none !important',
      borderEndStartRadius: '8px',
      boxShadow: 'inset -10px 0 8px -8px rgba(5, 5, 5, 0.06)',
      '::after': {
        boxShadow: 'unset !important',
      },
      '& > .ant-table-header > table': {
        '& > thead > tr': {
          '&:first-child': {
            '& > th': {
              border: 'none !important',
            },
          },
          '& > th': {
            padding: '24px',
            borderTop: '1px solid #F0F0F0',
            '&:first-child': {
              borderLeft: '1px solid #F0F0F0',
              borderTopLeftRadius: '8px',
            },
            '&:last-child::after': {
              borderRadius: '0 8px 0 0',
            },
          },
        },
      },
      '& > .ant-table-body > table': {
        '& > tbody > tr > th': {
          '&:first-child': {
            borderLeft: '1px solid #F0F0F0',
            width: '5% !important',
          },
          '&:last-child::after': {
            borderRadius: '0 8px 0 0',
          },
        },
        '& > tbody': {
          '& > tr:last-child': {
            '& > th': {
              borderRadius: '0 0 0 7px',
            },
          },
        },
      },
    },
  },
  exerciseChoiceWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  tableCell: {
    backgroundColor: '#419BB4CC !important',
    color: 'white',
    borderRadius: '0 8px 8px 0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#419BB4 !important',
    },
  },
});
