import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  countdownWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  clipboard: {
    color: theme.textColor5,
    marginLeft: '10px',
    cursor: 'pointer',
    '&.selected': {
      color: theme.successColor,
    },
  },
  bold: {
    fontWeight: 600,
  },
}));
