import { useRef, useEffect } from 'react';
import mediumZoom from 'medium-zoom';
import { useSelector } from 'react-redux';

const ZoomHandler = () => {
  const updateZoom = useSelector(state => state.page.updateZoom);
  const isDark = useSelector(state => state.page.isDark);

  const zoom = useRef(mediumZoom({
    background: isDark ? 'rgba(33, 33, 33, 0.9)' : 'rgba(245, 245, 245, 0.9)',
  }));

  /* Rerender zoom on images */
  useEffect(() => {
    zoom.current.detach('img.ql-image', 'img.zoom-image');
    zoom.current.attach('img.ql-image', 'img.zoom-image');
  }, [updateZoom]);

  /* Update overlay background */
  useEffect(() => {
    zoom.current.update({
      background: isDark ? 'rgba(33, 33, 33, 0.9)' : 'rgba(245, 245, 245, 0.9)',
    });
  }, [isDark]);

  return null;
};

export default ZoomHandler;
