import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  wrapper: {
    marginTop: '20px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageWrapper: {
    position: 'relative',
    overflowX: 'auto',
  },
  image: {
    width: '700px',
    height: '700px',
    objectFit: 'contain',
  },
  gapList: {
    minHeight: '35px',
    marginTop: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
  },
  scrollHint: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    display: 'none',
  },
  scrollHintLabel: {
    margin: '0 10px',
  },
  check: {
    color: theme.successColor,
  },
  inputDropdown: {
    borderRadius: '19px',
    minWidth: '10em',
    '&.isAdmin div[class$="-control"]': {
      border: `2px solid ${theme.inputBorder2}`,
    },
    '&.isCorrect div[class$="-control"]': {
      border: `2px solid ${theme.successColor}`,
    },
    '&.answerable div[class$="-control"]': {
      border: `1px solid ${theme.inputBorder2}`,
    },
    '& div[class$="-control"]': {
      border: `2px solid ${theme.errorColor}`,
    },
    '& div[class$="-control"] > :first-child': {
      height: '3em',
    },
    '&.correction div[class$="-placeholder"]': {
      position: 'relative',
    },
  },
  inputWrite: {
    verticalAlign: 'middle',
    display: 'inline-block',
    border: `1px solid ${theme.inputBorder2}`,
    minWidth: '10em',
    borderRadius: '18px',
    overflow: 'hidden',
    backgroundColor: 'white',
    '&.isCorrect': {
      border: `2px solid ${theme.successColor}`,
    },
    '&:not(.isCorrect)': {
      border: `2px solid ${theme.errorColor}`,
    },
    '&.answerable': {
      border: `1px solid ${theme.inputBorder2}`,
    },
    '& > div > div > div': {
      width: '80%',
    },
  },
  identifier: {
    color: 'grey',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  optionWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    scrollHint: {
      display: 'flex',
    },
  },
}));
