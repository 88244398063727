import React from 'react';
import PropTypes from 'prop-types';

import CountdownStages from 'components/common/CountdownStages';

const StudentTestCardRight = ({ test }) => {
  return (
    <CountdownStages info={test} />
  );
};

StudentTestCardRight.propTypes = {
  test: PropTypes.object,
};

export default StudentTestCardRight;
