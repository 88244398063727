import React from 'react';
import { Table } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectTestClassificationType } from 'actions/testActions';
import { selectUserFeatureToggleMaxUsages } from 'actions/userActions';
import lang from 'lang';
import toggles from 'toggles';

import SettingsInput from '../SettingsInput';
import Card from 'components/common/Card';

import useStyles from './styles';

const GeneralSettings = ({ getValue, setValue, getErrors }) => {
  const classes = useStyles();
  const classificationType = useSelector(selectTestClassificationType);
  const exportVersionsMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.exportVersions));

  const availableLocales = [
    { label: lang.en, value: 'en' },
    { label: lang.pt, value: 'pt' },
    { label: lang.esES, value: 'esES' },
    { label: lang.ptBR, value: 'ptBR' },
  ];

  const shuffleOptions = [
    { value: 'none', label: lang.test.publishModal.shuffleNoneLabel },
    { value: 'base', label: lang.test.publishModal.shuffleBaseLabel },
    { value: 'sections', label: lang.test.publishModal.shuffleSectionsLabel },
    { value: 'all', label: lang.test.publishModal.shuffleAllLabel },
  ];

  const setVersions = (event) => {
    const versions = parseInt(event.target.value);
    setValue('versions', versions || '');

    if (versions === 1) {
      setValue('shuffleType', shuffleOptions[0]);
    }
  };

  return (
    <Card header={lang.test.physicalVersion.generalSettings}>
      <Table className={classes.inputTable}>
        <SettingsInput
          type="number"
          label={lang.test.physicalVersion.versionsLabel}
          placeholder={lang.test.physicalVersion.versionsPlaceholder}
          onChange={setVersions}
          minSize={1}
          maxSize={exportVersionsMaxUsages}
          value={getValue('versions')}
          errors={getErrors('versions')}
          invalid={getErrors('versions').length !== 0}
          premium
        />
        <SettingsInput
          type="select"
          label={lang.test.physicalVersion.localeLabel}
          value={getValue('locale')}
          options={availableLocales}
          onChange={(value) => setValue('locale', value)}
        />
        <Render when={getValue('versions') > 1}>
          <SettingsInput
            type="select"
            label={lang.test.physicalVersion.shuffleExercisesLabel}
            value={getValue('shuffleType')}
            options={shuffleOptions}
            onChange={(value) => setValue('shuffleType', value)}
          />
        </Render>
        <Render when={classificationType === 'rubric'}>
          <SettingsInput
            type="toggle"
            label={lang.test.physicalVersion.includeRubric}
            hasTogglers
            canBeEnabled
            isEnabled={getValue('includeRubric')}
            onEnable={(value) => setValue('includeRubric', value)}
          />
        </Render>
      </Table>
    </Card>
  );
};

GeneralSettings.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getErrors: PropTypes.func.isRequired,
};

export default GeneralSettings;
