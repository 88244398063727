import { createUseStyles } from 'react-jss';

export default createUseStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    overflowX: 'auto',
  },
  image: {
    width: '700px',
    height: '700px',
    objectFit: 'contain',
  },
  imageWrapper: {
    position: 'relative',
    width: '700px',
    height: '700px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
  },
});
