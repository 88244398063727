import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'actions/userActions';
import lang from 'lang';

import ExerciseChoice from 'components/exercises/exercise/exercise-answer/ExerciseChoice';

import useStyles from './styles';

const ExerciseAnswerTrueFalse = ({ exerciseId, answer, onAnswer, correct, answerable }) => {
  const classes = useStyles();
  const isAdmin = useSelector(selectUserIsAdmin);

  return (
    <div>
      <div className={classes.choice}>
        <ExerciseChoice
          name={`${exerciseId}-true`}
          label={lang.test['true']}
          value={true}
          checked={answer === true}
          onCheck={onAnswer}
          correct={!isAdmin && correct === true}
          disabled={!answerable}
        />
      </div>
      <div className={classes.choice}>
        <ExerciseChoice
          name={`${exerciseId}-false`}
          label={lang.test['false']}
          value={false}
          checked={answer === false}
          onCheck={onAnswer}
          correct={!isAdmin && correct === false}
          disabled={!answerable}
        />
      </div>
    </div>
  );
};

ExerciseAnswerTrueFalse.propTypes = {
  exerciseId: PropTypes.string,
  answer: PropTypes.any,
  onAnswer: PropTypes.func,
  correct: PropTypes.bool,
  answerable: PropTypes.bool,
};

export default ExerciseAnswerTrueFalse;
