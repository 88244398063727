import React, { forwardRef, useState } from 'react';
import { useDndMonitor } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { faArrowRight, faArrowsLeftRight, faClock, faListUl, faPen, faStairs, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { editTestSection, removeTestItem, selectTest, selectTestClassificationType, selectTestItems, selectTestSection } from 'actions/testActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { deepCopy, quillIsEmpty } from 'utils';

import AddContent from '../AddContent';
import EditableExercise from '../EditableExercise';
import RemoveEntityModal from '../RemoveEntityModal';
import TestExerciseDrop from '../TestExerciseDrop';
import TestSectionForm from '../TestSectionForm';
import EntityActionsContainer from 'components/common/entity/EntityActionsContainer';
import EntityActionSeparator from 'components/common/entity/EntityActionSeparator';
import EntityBody from 'components/common/entity/EntityBody';
import EntityContent from 'components/common/entity/EntityContent';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import EntityExtraInfo from 'components/common/entity/EntityExtraInfo';
import EntityHeader from 'components/common/entity/EntityHeader';
import EntitySubHeader from 'components/common/entity/EntitySubHeader';
import EntityTitle from 'components/common/entity/EntityTitle';
import SortableItem from 'components/common/SortableItem';
import Tooltip from 'components/common/Tooltip';
import EntityAction from 'components/exercises/exercise/exercise-header/EntityAction';
import EntityMover from 'components/exercises/exercise/exercise-header/EntityMover';
import ExerciseGrade from 'components/exercises/exercise/exercise-header/ExerciseGrade';
import { IDLE, SAVING } from 'components/exercises/exercise/exercise-header/ExerciseSavingSpinner/states';

import useStyles from './styles';

const EditableTestSection = forwardRef((props, ref) => {
  const {
    id,
    actionable,
    fetchTest,
    testId,
    saveTestItemsOrder,
    isOverlay,
    collapse,
    attributes,
    listeners,
    transform,
    transition,
    isSorting,
    isDragging,
    node,
  } = props;

  const classes = useStyles();
  const classificationType = useSelector(selectTestClassificationType);
  const { name, description, order, presentation, navigation, duration, exercises, sectionTotal } = useSelector(selectTestSection(id));
  const testItems = useSelector(selectTestItems);
  const toast = useToast();
  const dispatch = useDispatch();
  const [deleteTestSectionRequest] = useApi(api.deleteTestSection);
  const [editTestSectionRequest] = useApi(api.editTestSection);
  const [editPublicationSectionRequest] = useApi(api.editPublicationSection);
  const editPublicationToggle = useFeature(toggles.editPublication);
  const { publication } = useSelector(selectTest);

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [savingState, setSavingState] = useState(IDLE);

  useDndMonitor({
    onDragEnd: (event) => {
      const { active } = event;

      if (active.id === id && node) {
        node.current.scrollIntoView({ behaviour: 'smooth', block: 'center' });
      }
    },
  });

  const removeSection = () => {
    setSavingState(SAVING);

    deleteTestSectionRequest([id], null, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          dispatch(removeTestItem(id));
          setTimeout(() => {
            setSavingState(IDLE);
          }, 300);
          return;
        } else if (data.status === 2) {
          toast.warning(lang.test.errorRemovePublished);
        } else {
          toast.error(lang.oops);
        }

        setSavingState(IDLE);
        setRemoveModal(false);
      }, 300);
    });
  };

  const onMove = (moveUp) => {
    if (moveUp && order === 0) {
      return;
    }

    if (!moveUp && order === testItems.length - 1) {
      return;
    }

    const newOrder = moveUp ? order - 1 : order + 1;

    let newItems = deepCopy(testItems);
    newItems = arrayMove(newItems, order, newOrder)
      .map((item, index) => ({ ...item, order: index }));

    saveTestItemsOrder(newItems);
  };

  const saveSectionHandler = (status, newSection) => {
    setTimeout(() => {
      setLoading(false);

      if (status === 0) {
        toast.success(lang.test.exercises.editSectionSuccessful);
        dispatch(editTestSection(id, newSection));
        setEdit(false);
        return;
      }

      toast.error(lang.oops);
    }, 300);
  };

  const saveSection = (newSection) => {
    setLoading(true);
    if (publication) {
      editPublicationSectionRequest([publication.id, id], newSection, ({ data }) => {
        saveSectionHandler(data.status, newSection);
      });
    } else {
      editTestSectionRequest([id], newSection, ({ data }) => {
        saveSectionHandler(data.status, newSection);
      });
    }
  };

  const getExercises = () => {
    if (!exercises) {
      return null;
    }

    let exerciseNum = 0;
    const sectionExercises = exercises.map((exercise) => {
      if (exercise.type !== 'information') {
        exerciseNum++;
      }

      return (
        <SortableItem
          key={exercise.id}
          id={exercise.id}
          component={
            <EditableExercise
              key={exercise.id}
              num={exerciseNum}
              testId={testId}
              classificationType={classificationType}
              actionable={actionable}
              testExerciseId={exercise.id}
              testSectionId={id}
              refreshState={fetchTest}
              className={classes.editableExercise}
              saveTestItemsOrder={saveTestItemsOrder}
            />
          }
        />
      );
    });

    return (
      <SortableContext
        items={exercises}
        strategy={verticalListSortingStrategy}
      >
        {sectionExercises}
      </SortableContext>
    );
  };

  const style = {
    transform: isSorting ? (transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined) : undefined,
    transition,
    opacity: isDragging && !isOverlay ? '0.3' : '1',
    position: 'relative',
  };

  return (
    <EntityBody
      header={name}
      _ref={ref}
      className={classes.section}
      style={style}
    >
      <RemoveEntityModal
        open={removeModal}
        close={() => setRemoveModal(false)}
        onConfirm={removeSection}
        loading={savingState === SAVING}
        type={'section'}
      />
      <EntityHeader
        attributes={attributes}
        listeners={listeners}
        isDraggable={actionable}
        isOverlay={isOverlay}
      >
        {actionable && (
          <EntityMover
            onMove={onMove}
          />
        )}
        <EntityTitle
          name={name}
        />
        <EntityActionsContainer>
          <div className={classes.sectionPresentationWrapper}>
            {duration && (
              <>
                <Tooltip
                  tip={lang.test.exercises.sectionTimerWarning}
                  left
                >
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                </Tooltip>
                <EntityExtraInfo
                  icon={faClock}
                  lang={duration + ' ' + (duration > 1 ? lang.time.minutes : lang.time.minute)}
                />
                <EntityActionSeparator />
              </>
            )}
            <EntityExtraInfo
              icon={presentation === 'full' ? faListUl : faStairs}
              lang={presentation === 'full' ? lang.test.presentationFull : lang.test.presentationIncremental}
            />
            {presentation === 'incremental' && (
              <>
                <EntityActionSeparator />
                <EntityExtraInfo
                  icon={navigation === 'linear' ? faArrowRight : faArrowsLeftRight}
                  lang={navigation === 'linear' ? lang.test.navigationLinear : lang.test.navigationNonLinear}
                />
              </>
            )}
            <EntityActionSeparator />
          </div>
          {classificationType !== 'none' && (
            <ExerciseGrade
              maxGrade={sectionTotal}
              hideGrade
            />
          )}
          {editPublicationToggle && !actionable && (
            <EntityAction
              icon={faPen}
              onClick={() => setEdit(true)}
              tip={lang.edit}
            />
          )}
          {actionable && (
            <>
              <EntityAction
                icon={faPen}
                onClick={() => setEdit(true)}
                tip={lang.edit}
              />
              <EntityAction
                icon={faTimes}
                onClick={() => setRemoveModal(true)}
                tip={lang.remove}
                className={classes.deleteSection}
              />
            </>
          )}
        </EntityActionsContainer>
      </EntityHeader>
      {!isOverlay && !collapse && (
        <>
          {!quillIsEmpty(description) && (
            <EntitySubHeader>
              <EntityExpandableText
                text={description}
              />
            </EntitySubHeader>
          )}
          <EntityContent>
            {!edit && (
              <>
                {isSorting && (!exercises || exercises.length === 0) && (
                  <TestExerciseDrop
                    id={`${id}-drop`}
                  />
                )}
                {getExercises()}
                {actionable && (
                  <AddContent
                    refresh={fetchTest}
                    sectionId={id}
                  />
                )}
              </>
            )}
            {edit && (
              <TestSectionForm
                initialName={name}
                initialDescription={description}
                initialPresentation={presentation}
                initialNavigation={navigation}
                initialDuration={duration}
                save={saveSection}
                loading={loading}
                cancel={() => setEdit(false)}
                restricted={!!publication}
              />
            )}
          </EntityContent>
        </>
      )}
    </EntityBody>
  );
});

EditableTestSection.displayName = 'EditableTestSection';

EditableTestSection.propTypes = {
  id: PropTypes.string,
  actionable: PropTypes.bool,
  testId: PropTypes.string,
  fetchTest: PropTypes.func,
  saveTestItemsOrder: PropTypes.func,
  isOverlay: PropTypes.bool,
  collapse: PropTypes.bool,
  attributes: PropTypes.object,
  listeners: PropTypes.object,
  transform: PropTypes.object,
  transition: PropTypes.string,
  isSorting: PropTypes.bool,
  isDragging: PropTypes.bool,
  node: PropTypes.object,
};

export default EditableTestSection;
