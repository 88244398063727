import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  orderSelectorContainer: {
    display: 'flex',
    marginLeft: '10px',
    alignItems: 'center',
    width: 'unset !important',
  },
  orderDropdownSelect: {
    backgroundColor: theme.inputBackground2,
    border: `1px solid ${theme.inputBorder2}`,
    padding: '0.45em',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  orderDropdownMenu: {
    maxHeight: '15em',
    overflowY: 'auto',
    zIndex: '50',
    minWidth: '13em',
    backgroundColor: `${theme.inputBackground2} !important`,
    border: `1px solid ${theme.inputBorder2} !important`,
    borderRadius: '10px',
    top: 'calc(100% + 9px)',
    right: '0',
    left: 'unset',
  },
  orderDropdownIcon: {
    marginLeft: '10px',
    fontSize: '10px',
  },
  orderDropdownItem: {
    display: 'flex',
    alignItems: 'center',
    margin: 'unset !important',
    border: 'none !important',
    '&:hover': {
      backgroundColor: theme.selectBackgroundHover,
      cursor: 'pointer',
    },
    '&.selected': {
      backgroundColor: theme.selectBackgroundHover,
    },
  },
  iconPlus: {
    marginLeft: '0.5em',
    marginRight: '0.5em',
    width: '1em !important',
  },

  [`@media (max-width: ${theme.xs}px)`]: {
    orderSelectorContainer: {
      width: '100% !important',
      marginLeft: '0px',
      marginRight: '0px',
      '& .otl-dropdown': {
        width: '100%',
        display: 'unset !important',
      },
    },
  },
}));
