import React, { useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserId } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const RemoveTeacherModal = ({ open, close, groupId, teachers, refresh }) => {
  const toast = useToast();
  const classes = useStyles();
  const [removeTeacherRequest] = useApi(api.removeTeacher);
  const userId = useSelector(selectUserId);

  const _close = useCallback(() => {
    close();
    setTimeout(350);
  }, [close]);

  const removeTeacher = () => {
    const teacherIdsToRemove = teachers.filter(teacher => teacher.selected && teacher.id !== userId).map(teacher => teacher.id);

    removeTeacherRequest([groupId], { teacherIds: teacherIdsToRemove }, ({ data }) => {
      if (data.status === 0) {
        toast.success(lang.group.successRemoveTeacher);
        _close();
        setTimeout(() => {
          refresh();
        }, 350);
      } else {
        toast.error(lang.common.internetWentWrong);
        _close();
      }
    });
  };

  const getActions = () => {
    return [
      {
        name: lang.remove,
        color: 'red',
        onClick: removeTeacher,
      },
      {
        name: lang.cancel,
        color: 'black',
        onClick: _close,
      },
    ];
  };

  const getContent = () => {
    return (
      <div>
        <div className={classes.removeTeacherInfo}>
          {lang.group.removeTeacherInfo}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.deleteButton}>
      <Modal
        open={open}
        close={_close}
        header={lang.group.removeTeacher}
        actions={getActions()}
        center
        transition
        small
      >
        {getContent()}
      </Modal>
    </div>
  );
};

RemoveTeacherModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  groupId: PropTypes.string,
  teachers: PropTypes.array,
  refresh: PropTypes.func,
};

export default RemoveTeacherModal;
