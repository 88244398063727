const styles = (theme) => ({
  container: (styles) => ({
    ...styles,
    '&.invalid > div': {
      borderColor: '#fb6045',
    },
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: theme.shade4,
    color: theme.textColor,
    border: `1px solid ${theme.inputBorder3}`,
    borderRadius: '18px',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  valueContainer: (styles) => ({
    ...styles,
    maxHeight: '200px',
    overflowY: 'auto',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: theme.textColor3,
    fontSize: '14px',
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: theme.shade4,
    color: theme.textColor,
    borderRadius: '18px',
    overflow: 'hidden',
    width: 'unset',
    minWidth: '100%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: '9999 !important',
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: `${data.color || theme.textColor3}33`,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color || theme.textColor3,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color || theme.textColor3,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: data.color || theme.textColor3,
      color: theme.textColorContrast,
    },
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: (isSelected || isFocused) ? theme.inputBackground : theme.backgroundColor,
    opacity: isDisabled ? 0.5 : 1,
    color: theme.textColor,
    cursor: isDisabled ? 'not-allowed' : 'default',
    '&:hover': {
      ...styles[':hover'],
      backgroundColor: theme.inputBackground,
    },
  }),
});

export default styles;
