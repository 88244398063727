import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  section: {
    marginBottom: '20px',
    '&.answerable': {
      userSelect: 'none',
    },
  },
  sectionDescription: {
    '& > p': {
      margin: '0',
    },
  },
  exercisesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
  icon: {
    fontSize: '75px',
  },
  lockedSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
  },
}));
