import React, { useState } from 'react';
import { Button } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { highlightColors } from 'constants/annotationColors';
import lang from 'lang';

import { useAttemptContext } from '../CorrectionTab/context';
import DeleteAnnotationCommentModal from '../DeleteAnnotationCommentModal';
import QuillRenderer from 'components/common/QuillRenderer';
import RichText from 'components/common/rich-text/RichText';
import AnnotationBlot from 'components/common/rich-text/RichText/AnnotationBlot';

import useStyles from './styles';

const CommentBox = ({ annotate, textAnnotationId, comment, handleSaveComment, startEditing, setAnnotations, setAnnotate, setAddComment, canEdit }) => {
  const classes = useStyles();
  const { focusedTextAnnotationId, hoveredTextAnnotationId, hoveredCommentId, setCommentHovered, setCommenting, setEditingComment } = useAttemptContext();

  const [deleteAnnotationCommentModal, setDeleteAnnotationCommentModal] = useState(false);
  const [isEditing, setIsEditing] = useState(startEditing);
  const [newComment, setNewComment] = useState(comment);

  const cancel = () => {
    setAddComment?.(false);
    setCommenting(false);
    setEditingComment(false);
    setIsEditing(false);
  };

  const edit = () => {
    setIsEditing(true);
    setEditingComment(true);
  };

  const save = () => {
    handleSaveComment(textAnnotationId, newComment);
    setIsEditing(false);
    setEditingComment(false);
  };

  const onMouseEnter = () => {
    if (annotate) {
      return;
    }

    setCommentHovered(textAnnotationId);
    if (!focusedTextAnnotationId) {
      AnnotationBlot.setAllUnfocused();
      AnnotationBlot.setIsActive(textAnnotationId, true);
    }
  };

  const onMouseLeave = () => {
    if (annotate) {
      return;
    }

    setCommentHovered(null);
    if (!focusedTextAnnotationId) {
      AnnotationBlot.setAllDefault();
    }
  };
  const annotations = Array.from(document.querySelectorAll(`[data-id*="${textAnnotationId}"]`));

  let mainAnnotation = annotations.find(annotation => annotation.getAttribute('data-id') === textAnnotationId);

  if (!mainAnnotation) {
    mainAnnotation = annotations.find(annotation => annotation.getAttribute('data-id').endsWith(textAnnotationId))
      ?? annotations.find(annotation => annotation.getAttribute('data-id').includes(textAnnotationId));
  }

  const annotationColor = mainAnnotation.getAttribute('data-color');

  const highlightColor = highlightColors.find(color => color.name === annotationColor);

  const otherAnnotationFocused = focusedTextAnnotationId && focusedTextAnnotationId !== textAnnotationId;
  const otherAnnotationHovered = hoveredTextAnnotationId && hoveredTextAnnotationId !== textAnnotationId;
  const otherCommentHovered = hoveredCommentId && hoveredCommentId !== textAnnotationId;

  return (
    <div
      className={cx(classes.commentBox, { otherFocused: !annotate && (((otherAnnotationHovered || otherCommentHovered) && !focusedTextAnnotationId) || otherAnnotationFocused), editing: isEditing })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        backgroundColor: highlightColor?.default + '33',
        borderColor: highlightColor?.default,
      }}
    >
      {isEditing ? (
        <RichText
          className={classes.commentArea}
          value={newComment}
          placeholder={lang.test.grades.commentBoxPlaceholder}
          onChange={(_content, _delta, _source, editor) => setNewComment(editor.getContents())}
          footer={
            <>
              <Button
                sibling
                styleType='outlined'
                className={classes.cancelButton}
                onClick={cancel}
              >
                {lang.cancel}
              </Button>
              {isEditing && (
                <Button
                  sibling
                  onClick={save}
                >
                  {lang.save}
                </Button>
              )}
            </>
          }
          enableMathSymbols={isEditing}
          noHeader
          disabled={!isEditing}
        />
      ) : (
        <>
          <QuillRenderer
            value={comment}
          />
          <div className={classes.previewFooter}>
            {!isEditing && canEdit && (
              <>
                <DeleteAnnotationCommentModal
                  open={deleteAnnotationCommentModal}
                  close={() => setDeleteAnnotationCommentModal(false)}
                  textAnnotationId={textAnnotationId}
                  setAnnotations={setAnnotations}
                  setAnnotate={setAnnotate}
                />
                <Button
                  onClick={() => setDeleteAnnotationCommentModal(true)}
                  styleType='outlined'
                >
                  {lang.delete}
                </Button>
                <Button
                  onClick={edit}
                >
                  {lang.edit}
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

CommentBox.propTypes = {
  annotate: PropTypes.bool,
  textAnnotationId: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  handleSaveComment: PropTypes.func.isRequired,
  startEditing: PropTypes.bool,
  setAnnotations: PropTypes.func,
  setAnnotate: PropTypes.func,
  setAddComment: PropTypes.func,
  canEdit: PropTypes.bool,
};

export default CommentBox;
