import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import LabelSelector from '../LabelSelector';
import OrderSelector from '../OrderSelector';
import TypeSelector from '../TypeSelector';
import FilterPool from 'components/common/filter-selector/FilterPool';
import Loading from 'components/common/Loading';
import UserSelector from 'components/exercises/exercise-bank/exercise-filter/UserSelector';

import useStyles from './styles.js';

const Filters = ({ users, types, labels, order, setOrder, filters, setFilters, loading }) => {
  const classes = useStyles();

  const poolItemAction = (poolItem) => {
    if (filters.users.indexOf(poolItem) !== -1) {
      setFilters({ ...filters, users: filters.users.filter(itemToRemove => itemToRemove.id !== poolItem.id) });
    } else if (filters.types.indexOf(poolItem) !== -1) {
      setFilters({ ...filters, types: filters.types.filter(itemToRemove => itemToRemove.id !== poolItem.id) });
    } else if (filters.labels.indexOf(poolItem) !== -1) {
      setFilters({ ...filters, labels: filters.labels.filter(itemToRemove => itemToRemove.id !== poolItem.id) });
    }
  };

  return (
    <div data-tour="exercise-filters">
      <div className={classes.searchContainer}>
        <div className={classes.filtersContainer}>
          <div className={classes.header}>
            {lang.exercises.filters}
          </div>
          <div className={classes.filterSelectors}>
            <UserSelector
              users={users}
              filters={filters || []}
              setFilters={setFilters}
              disabled={!!users.some(user => user.disabled)}
            />
            <TypeSelector
              types={types}
              filters={filters || []}
              setFilters={setFilters}
            />
            <LabelSelector
              labels={labels}
              filters={filters || []}
              setFilters={setFilters}
            />
            <Loading
              active={loading}
              className={classes.loading}
            />
          </div>
        </div>
        <div className={classes.orderContainer}>
          <div className={classes.header}>
            {lang.exercises.order}
          </div>
          <div className={classes.filterSelectors}>
            <OrderSelector
              order={order}
              setOrder={setOrder}
            />
          </div>
        </div>
      </div>
      <div className={classes.filters}>
        <FilterPool
          poolItems={[...filters.users, ...filters.types, ...filters.labels]}
          poolItemAction={poolItemAction}
        />
      </div>
    </div>
  );
};

Filters.propTypes = {
  users: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  setOrder: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
export default Filters;
