import React from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import enUS from 'antd/es/date-picker/locale/en_US';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { getDayJSValue, getHumanDatetimeFormat } from 'utils/datetime';

import useStyles from './styles';

const DatePicker = ({ errors, type, name, placeholder, value, defaultValue, locale = 'pt', onChange, onFocus, onBlur, disabled, _ref, id, allowClear = true }) => {
  const classes = useStyles();

  const dayJSValue = getDayJSValue(value);
  const dayJSDefaultValue = getDayJSValue(defaultValue);
  const humanFormat = getHumanDatetimeFormat(type, locale);

  return (
    <AntdDatePicker
      locale={locale === 'pt' ? ptBR : enUS}
      onChange={onChange}
      showTime={type === 'datetime'}
      format={humanFormat}
      className={cx('otl-inputText', classes.datepicker, { invalid: errors && errors.length !== 0 })}
      value={dayJSValue}
      defaultValue={dayJSDefaultValue}
      name={name}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      autoComplete={'no'}
      ref={_ref}
      id={id}
      allowClear={allowClear}
    />
  );
};

DatePicker.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.bool,
  ]),
  type: PropTypes.oneOf(['date', 'datetime']),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  locale: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  _ref: PropTypes.object,
  id: PropTypes.string,
  allowClear: PropTypes.bool,
};

export default DatePicker;
