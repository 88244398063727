import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  playerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '40px',
    fontSize: '16px',
  },
  audioControls: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    backgroundColor: '#ededed',
    padding: '10px 20px',
    borderRadius: '40px',
  },
  playButton: {
    cursor: 'pointer',
    fontSize: '20px',
    '&.disabled': {
      opacity: 0.5,
      cursor: 'default',
    },
  },
  timeDisplay: {
    display: 'flex',
    alignItems: 'center',
  },
  playerErrorMessage: {
    margin: '10px 0 0 10px',
    color: theme.errorColor,
    fontSize: '15px',
  },
}));
