import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const EntitySubHeader = ({ children, colors }) => {
  const classes = useStyles();

  const style = {
    backgroundColor: colors?.bodyColor,
  };

  return (
    <div
      className={classes.subHeader}
      style={style}
    >
      {children}
    </div>
  );
};

EntitySubHeader.propTypes = {
  children: PropTypes.any,
  colors: PropTypes.object,
};

export default EntitySubHeader;
