import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  statsTable: {
    marginTop: '7px',
    padding: '35px 35px',
    '& tr[class^="ant-table-row"]': {
      cursor: 'pointer',
    },
  },
}));
