import React, { useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserLang } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';

const PublishGradesModal = ({ open, close, publicationId, gradesPublished, setGradesPublished }) => {
  const locale = useSelector(selectUserLang);
  const [publishGradesRequest] = useApi(api.publishGrades);
  const modelAnswerToggle = useFeature(toggles.modelAnswer);

  const [loading, setLoading] = useState(false);
  const [showModelAnswer, setShowModelAnswer] = useState(false);

  const publishGrades = () => {
    setLoading(true);

    publishGradesRequest([publicationId], { gradesPublished: !gradesPublished, locale, showModelAnswer }, ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          setLoading(false);
          setGradesPublished(!gradesPublished);
          close();
        }, 300);
        return;
      }

      setTimeout(() => {
        setLoading(false);
      }, 200);
    });
  };

  const getActions = () => ([
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
    {
      name: lang.publish,
      onClick: publishGrades,
      loading: loading,
    },
  ]);

  return (
    <Modal
      open={open}
      close={close}
      header={lang.test.grades.publishCorrections}
      actions={getActions()}
      center
      transition
      medium
    >
      {lang.test.grades.publishCorrectionsInformation}
      {modelAnswerToggle ? (
        <>
          <Spacer px={20} />
          <Input
            type="checkbox"
            label={lang.test.grades.showModelAnswer}
            value={showModelAnswer}
            onChange={(event) => setShowModelAnswer(event.target.checked)}
          />
        </>
      ) : null}
    </Modal>
  );
};

PublishGradesModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  publicationId: PropTypes.string,
  gradesPublished: PropTypes.bool,
  setGradesPublished: PropTypes.func,
};

export default PublishGradesModal;
