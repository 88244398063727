import React from 'react';
import { InputText, InputArea } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';

import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import Dropzone from '../Dropzone';
import RichText from '../rich-text/RichText';
import Select from '../Select';

import useStyles from './styles';

const Input = ({ label, hint, errors, optional, type, name, placeholder, isAsync, creatable, validateNewOption, loadOptions, value, defaultValue, rows, onDrop, multiple, options, minSize, maxSize, step, pattern, locale, onChange, onFocus, onBlur, required, disabled, accept, large, inline, className, enableMathSymbols, mathSymbols, _ref, id, menuPlacement, autoComplete, allowClear, dataTour, minified }) => {
  const classes = useStyles();

  const getHint = () => {
    if (!hint) {
      return null;
    }

    return (
      <div className={cx(classes.headerItem, classes.hint)}>
        {hint}
      </div>
    );
  };

  const getInput = () => {
    if (type === 'date' || type === 'datetime') {
      return (
        <DatePicker
          locale={locale}
          onChange={onChange}
          type={type}
          value={value}
          defaultValue={defaultValue}
          name={name}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          autoComplete={'no'}
          ref={_ref}
          id={id}
          errors={errors}
          allowClear={allowClear}
        />
      );
    }

    if (type === 'textarea') {
      return (
        <InputArea
          name={name}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          rows={rows}
          minLength={minSize}
          maxLength={maxSize}
          pattern={pattern}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
          invalid={errors && errors.length !== 0}
          disabled={disabled}
          large={large}
          _ref={_ref}
          id={id}
          className={classes.inputText}
          autoComplete={autoComplete}
        />
      );
    }

    if (type === 'richtext') {
      return (
        <RichText
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={(_content, _delta, _source, editor) => onChange(editor.getContents())}
          onBlur={onBlur}
          rref={_ref}
          className={classes.inputText}
          disabled={disabled}
          inline={inline}
          mathSymbols={mathSymbols}
          enableMathSymbols={enableMathSymbols}
          autoComplete={autoComplete}
          minified={minified}
        />
      );
    }

    if (type === 'file') {
      return (
        <Dropzone
          placeholder={placeholder}
          value={value}
          minSize={minSize}
          maxSize={maxSize}
          invalid={errors && errors.length !== 0}
          disabled={disabled}
          onDrop={onDrop}
          multiple={multiple}
          accept={accept}
          autoComplete={autoComplete}
        />
      );
    }

    if (type === 'select') {
      return (
        <Select
          name={name}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          multiple={multiple}
          options={options}
          isAsync={isAsync}
          creatable={creatable}
          validateNewOption={validateNewOption}
          menuPlacement={menuPlacement}
          loadOptions={loadOptions}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          invalid={errors && errors.length !== 0}
          disabled={disabled}
          ref={_ref}
          id={id}
          autoComplete={autoComplete}
        />
      );
    }

    return (
      <InputText
        type={type}
        name={name}
        placeholder={placeholder}
        value={(value || value === 0) ? value : ''}
        defaultValue={defaultValue}
        minLength={minSize}
        maxLength={maxSize}
        min={minSize}
        max={maxSize}
        step={step}
        pattern={pattern}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
        invalid={errors && errors.length !== 0}
        disabled={disabled}
        large={large}
        _ref={_ref}
        id={id}
        className={classes.inputText}
        autoComplete={autoComplete}
      />
    );
  };

  const getErrors = () => {
    if (!errors || typeof errors === 'boolean' || errors.length === 0) {
      return;
    }

    return (
      <div className={classes.errorsContainer}>
        {errors?.map(error => (
          <div key={error}>
            {error}
          </div>
        ))}
      </div>
    );
  };

  if (type === 'checkbox') {
    return (
      <div data-tour={dataTour} className={className}>
        <Checkbox
          name={name}
          label={label}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
          invalid={errors === true || (errors && errors.length !== 0)}
          disabled={disabled}
          _ref={_ref}
          id={id}
          autoComplete={autoComplete}
        />
        {getErrors()}
        {getHint()}
      </div>
    );
  }

  return (
    <div data-tour={dataTour} className={className}>
      <Render when={label}>
        <div className={classes.headerItem}>
          <label
            className={classes.headerItem}
            htmlFor={id}
          >
            {label}
            <Render when={optional}>
              <span className={classes.optional}>
                {' ('}
                {lang.optional}
                {')'}
              </span>
            </Render>
          </label>
          {getHint()}
        </div>
      </Render>
      {getInput()}
      {getErrors()}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.bool,
  ]),
  optional: PropTypes.bool,

  type: PropTypes.oneOf(['text', 'number', 'password', 'email', 'date', 'datetime', 'select', 'textarea', 'file', 'checkbox']),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,

  rows: PropTypes.number,

  onDrop: PropTypes.func,
  multiple: PropTypes.bool,

  options: PropTypes.arrayOf(PropTypes.object),

  isAsync: PropTypes.bool,
  loadOptions: PropTypes.func,
  creatable: PropTypes.bool,
  validateNewOption: PropTypes.func,
  menuPlacement: PropTypes.string,

  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  step: PropTypes.number,
  pattern: PropTypes.string,
  locale: PropTypes.string,
  minified: PropTypes.bool,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,

  required: PropTypes.bool,
  disabled: PropTypes.bool,
  accept: PropTypes.array,

  large: PropTypes.bool,
  inline: PropTypes.bool,

  enableMathSymbols: PropTypes.bool,
  mathSymbols: PropTypes.object,

  className: PropTypes.string,
  _ref: PropTypes.object,
  id: PropTypes.string,

  autoComplete: PropTypes.string,
  dataTour: PropTypes.string,
  allowClear: PropTypes.bool,
};

export default Input;
