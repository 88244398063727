import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import PropTypes from 'prop-types';

const SortableItem = ({ id, component }) => {
  const sortableProps = useSortable({ id });

  const item = React.cloneElement(component, { id, ...sortableProps, ref: sortableProps.setNodeRef });

  return item;
};

SortableItem.propTypes = {
  id: PropTypes.string,
  component: PropTypes.any,
};

export default SortableItem;
