import React, { useEffect } from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setGroups } from 'actions/exploreActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import usePublicItems from 'hooks/explore/usePublicItems';

import EndMessage from '../EndMessage';
import ExerciseListHeader from '../ExerciseListHeader';
import ExploreExercise from '../ExploreExercise';
import ExploreTest from '../ExploreTest';
import NoResults from '../NoResults';
import Loading from 'components/common/Loading';

import useStyles from './styles';

const ExploreExercisesList = () => {
  const classes = useStyles();
  const { spaceId } = useParams();
  const dispatch = useDispatch();
  const [getSchoolGroupsRequest] = useApi(api.getSchoolGroups);

  const [items, totalItems, initialLoading, loading, next, hasMore, noResults] = usePublicItems();

  useEffect(() => {
    getSchoolGroupsRequest([spaceId], null, ({ data }) => {
      if (data.status === 0) {
        dispatch(setGroups(data.groups));
      }
    });
  }, [getSchoolGroupsRequest, dispatch, spaceId]);

  if (!items) {
    return null;
  }

  return (
    <div data-tour="explore-exercises-list" className={classes.exercisesContainer}>
      <Render when={noResults}>
        <NoResults />
      </Render>
      <Render when={!noResults}>
        <ExerciseListHeader
          resultSize={totalItems}
        />
        <Loading active={initialLoading} />
        <Render when={!initialLoading}>
          <InfiniteScroll
            dataLength={items.length}
            next={next}
            hasMore={hasMore}
            loader={<Loading active={loading} />}
            className={classes.scroller}
            endMessage={<EndMessage />}
          >
            <div className={classes.exerciseItemsContainer}>
              {items.map((item, index) => {
                if (item.publicItemType === 'test') {
                  return (
                    <div
                      className={classes.large}
                      key={item.id}
                    >
                      <ExploreTest
                        dataTour={`explore-exercises-card-${index}`}
                        test={item}
                      />
                    </div>);
                } else {
                  return (
                    <div
                      className={classes.exerciseItem}
                      key={item.id}
                    >
                      <ExploreExercise
                        dataTour={`explore-exercises-card-${index}`}
                        exercise={item}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </InfiniteScroll>
        </Render>
      </Render>
    </div>
  );
};

export default ExploreExercisesList;
