import { createUseStyles } from 'react-jss';

export default createUseStyles(theme => ({
  exerciseContainer: {
    padding: '22.5px',
    height: '241px',
    width: '260px',
    cursor: 'pointer',
  },
  exerciseCard: {
    width: '100% !important',
    height: '100% !important',
    backgroundColor: theme.white,
    border: 'none',
    borderRadius: '18px',
    boxShadow: '0px 4px 4px #00000040',
    '& .otl-cardContentContainer': {
      height: '100%',
      width: '100%',
    },
    padding: '25px 21px',
    display: 'flex',
  },
  exerciseContent: {
    backgroundColor: theme.white,
    borderRadius: '2px',
    height: '80%',
  },
  exerciseHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '18px',
  },
  exerciseType: {
    borderRadius: '100000px',
    padding: '7px 10px',
    fontWeight: '600',
    color: theme.exerciseColor,
    backgroundColor: theme.exerciseColorTransparency,
    fontSize: '12px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '60%',
  },
  exerciseTypeSeparator: {
    margin: '0 7px',
  },
  exerciseUsages: {
    fontSize: '12px',
    fontWeight: '600',
    color: theme.exerciseColor,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
  exerciseUsagesIcon: {
    fontSize: '17px',
    marginRight: '5px',
  },
  exerciseTitle: {
    padding: '0px 20px 10px 0px',
    fontWeight: 600,
    fontSize: '14px',
  },
  exerciseStatementContainer: {
    fontSize: '12px',
    '& p': {
      margin: '0',
    },
    marginTop: '14px',
  },
  exerciseStatement: {
    width: '100%',
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '3',
    boxOrient: 'vertical',
    margin: '6px 0',
    '& p': {
      margin: '0 !important',
      paddingBottom: '1px',
      '& .gap': {
        minHeight: '25px',
        height: '25px',
        backgroundColor: 'white',
        border: `1px solid ${theme.buttonBorder3}`,
        borderRadius: '4px',
        width: '50px',
        margin: '0 3px 0 3px',
      },
    },
    '& img.ql-image': {
      maxHeight: '20px !important',
      marginTop: '2px !important',
    },
    '& audio': {
      height: '20px',
      width: '225px',
    },
    '& iframe.ql-video': {
      display: 'none',
    },
  },
  exerciseFooter: {
    height: '20%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  useButton: {
    width: '43px',
    height: '36px',
    borderRadius: '18px 0 18px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    right: '-21px',
    marginLeft: 'auto',
    bottom: '-27px',
    backgroundColor: theme.exerciseColor,
    '&:hover': {
      backgroundColor: '#1B8866',
    },
  },
  icon: {
    transform: 'scale(1.50)',
  },
  exerciseInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '75%',
  },
  exerciseInfoItem: {
    fontSize: '14px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  exerciseInfoItemIconContainer: {
    fontSize: '18px',
    width: '30px',
  },
  exerciseInfoItemText: {
    width: 'calc(100% - 30px)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  filters: {
    marginTop: '5px',
    marginBottom: '18px',
    maxHeight: '45%',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.25em',
  },
  innerPill: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  pillLabel: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.hasMore': {
      maxWidth: 'calc(100% - 16px - 0.25em)',
    },
  },
  pillPlus: {
    borderRadius: '4px',
    padding: '1px 1px 1px 2px',
    color: '#e5e5e5',
    marginLeft: '0.5em',
  },
}));
