import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  input: {
    marginTop: '20px',
  },
  button: {
    margin: '20px 0',
    marginBottom: '5px',
    border: 'none',
  },
  loginStrategiesSeparator: {
    marginTop: '20px',
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  },
  separatorLine: {
    width: '100%',
    borderBottom: '1px solid #ddd',
  },
  authButtonsContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}));
