import React, { useEffect } from 'react';
import { OutlineProvider } from '@intuitivo-pt/outline-ui';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'react-jss';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import usePublicFeatureToggles from 'hooks/common/usePublicFeatureToggles';
import useServerClock from 'hooks/common/useServerClock';
import useFeature from 'hooks/useFeature';
import Router from 'Router';
import theme from 'theme';
import toggles from 'toggles';

import AuthHandler from 'components/common/handlers/AuthHandler';
import ToastHandler from 'components/common/handlers/ToastHandler';
import ZoomHandler from 'components/common/handlers/ZoomHandler';
import Error from 'components/common/layout/Error';
import Layout from 'components/common/layout/Layout';

const App = () => {
  const themeType = useSelector(state => state.page.isDark) ? 'dark' : 'light';
  const iaveToggle = useFeature(toggles.iave);

  useServerClock();
  usePublicFeatureToggles();

  useEffect(() => {
    const link = document.querySelector('link[rel~=\'icon\']');
    if (iaveToggle) {
      link.href = `${process.env.PUBLIC_URL}/favicon-iave.jpg`;
    }
  }, [iaveToggle]);

  return (
    <OutlineProvider>
      <ThemeProvider theme={{ ...theme.general, ...theme[themeType] }}>
        <BrowserRouter>
          <Sentry.ErrorBoundary fallback={<Error code={'500'} />}>
            <Layout navbar sidebar>
              <Router />
            </Layout>
            <AuthHandler />
            <ToastHandler />
            <ZoomHandler />
          </Sentry.ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </OutlineProvider>
  );
};

export default App;
