import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

import lang from 'lang';

import theme from '../../../../../theme.js';
import DonutChart from '../DonutChart/index.js';

import useStyles from './styles';

export const PieChartTwoLevel = ({ positiveGrades, negativeGrades, question }) => {
  const classes = useStyles();

  const donutChartData = [
    { name: lang.test.stats.positive, value: positiveGrades },
    { name: lang.test.stats.negative, value: negativeGrades },
  ];

  const donutChartColors = [theme.light.successColor, theme.light.errorColor];
  const pieChartColors = ['#534B52', '#77A0B7', '#FFD25C', '#A691C5', '#EC6543', '#E6EFE9'];

  const multipleChoiceAnswers = question.value?.map((item) => ({
    id: item.id,
    value: item.value[0].insert,
  })) ?? [];

  const choiceCounts = {};
  choiceCounts[null] = 0;
  multipleChoiceAnswers.forEach(option => {
    choiceCounts[option.id] = 0;
  });

  question.grades.forEach(item => {
    if (item.choice in choiceCounts) {
      choiceCounts[item.choice]++;
    }
  });

  const pieChartData = [
    { name: lang.test.stats.noAnswer, value: choiceCounts[null] },
    ...multipleChoiceAnswers.map(option => ({
      name: option.value,
      value: choiceCounts[option.id],
    })),
  ];

  return (
    choiceCounts[null] === question.numberOfStudents ?
      <ResponsiveContainer className={classes.chart} debounce={300} width={'50%'} height={200}>
        <DonutChart positiveGrades={question.positiveGrades} negativeGrades={question.negativeGrades} />
      </ResponsiveContainer>
      :
      <PieChart width={225} height={200}>
        <Pie
          data={pieChartData}
          width={100}
          dataKey="value"
          outerRadius={40}
          isAnimationActive={true}
        >
          {pieChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={pieChartColors[index % pieChartColors.length]} />
          ))}
        </Pie>
        <Pie
          data={donutChartData}
          dataKey="value"
          labelLine={true}
          width={100}
          innerRadius={45}
          outerRadius={60}
          isAnimationActive={true}
          label={(entry) => `${(entry.percent * 100).toFixed(0)}%`}
        >
          {donutChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={donutChartColors[index % donutChartColors.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
  );
};

PieChartTwoLevel.propTypes = {
  positiveGrades: PropTypes.number.isRequired,
  negativeGrades: PropTypes.number.isRequired,
  question: PropTypes.array,
};

export default PieChartTwoLevel;
