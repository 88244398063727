import React, { useCallback, useMemo, useRef } from 'react';
import { Button, Icon } from '@intuitivo-pt/outline-ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';

import lang from 'lang';

import FlowStep from '../../FlowStep';
import HeaderCell from '../HeaderCell';
import TableCell from '../TableCell';

import useStyles from './styles';
const AskForTable = ({ number, exerciseCells, tableDispatcher, restricted }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  const handleTableCellChange = useCallback((value, key) => {
    tableDispatcher({ type: 'SET_CELL_VALUE', payload: { key, value } });
  }, [tableDispatcher]);

  const handleTableChoiceCellChange = useCallback((row, col) => {
    tableDispatcher({ type: 'SET_CHOICE_CELL', payload: { row, col } });
  }, [tableDispatcher]);
  const handleAddColumn = async () => {
    await tableDispatcher({ type: 'ADD_COLUMN' });
    const table = tableRef.current.querySelector('.ant-table-body');
    table.scrollTo({ left: table.scrollWidth, behavior: 'smooth' });

    const header = document.querySelector('.ant-table-header table thead tr:nth-child(2) th:last-of-type > div:nth-of-type(2)');
    header.classList.add(classes.tableHighlight);

    setTimeout(() => {
      header.classList.remove(classes.tableHighlight);
    }, 500);
  };

  const handleAddRow = async () => {
    await tableDispatcher({ type: 'ADD_ROW' });
    const table = tableRef.current.querySelector('.ant-table-body');
    table.scrollTo({ top: table.scrollHeight, behavior: 'smooth' });

    const header = tableRef.current.querySelector('.ant-table-body table tbody tr:last-child > th:last-of-type > div:nth-of-type(2)');

    header.classList.add(classes.tableHighlight);

    setTimeout(() => {
      header.classList.remove(classes.tableHighlight);
    }, 500);
  };

  const removeColumn = useCallback((col) => {
    tableDispatcher({ type: 'REMOVE_COLUMN', payload: { col } });
  }, [tableDispatcher]);

  const removeRow = useCallback((row) => {
    tableDispatcher({ type: 'REMOVE_ROW', payload: { row } });
  }, [tableDispatcher]);

  const deletionRow = useMemo(() => {
    return [
      {
        fixed: 'left',
        onHeaderCell: () => ({
          col: -1,
          row: 0,
          style: {
            border: 'none',
            backgroundColor: '#FFFFFF',
            padding: '0 0 1em 0',
          },
          onChange: () => {},
        }),
        onCell: (_, rowIndex) => ({
          col: -1,
          row: rowIndex + 1,
          style: {
            border: 'none',
            borderRight: '1px solid #f0f0f0',
            backgroundColor: '#FFFFFF',
          },
          removeRow: () => removeRow(rowIndex + 1),
        }),
      },
    ];
  }, [removeRow]);

  const computedHeaderCells = useMemo(() => {
    return exerciseCells
      ?.filter(el => el.row === 0)
      ?.map(el => ({
        onHeaderCell: () => ({
          col: el.col,
          row: -1,
          value: null,
          restricted,
          style: {
            borderRight: 'none',
            backgroundColor: '#FFFFFF',
            padding: '0 0 1em 0',
          },
          onChange: () => {},
          removeColumn: () => removeColumn(el.col),
        }),
        children: [
          {
            ...el,
            dataIndex: el.col,
            key: el.id,
            ...(el.col === 0 || el.col === -1) && { fixed: 'left' },
            width: '150px',
            onHeaderCell: () => ({
              key: el.id,
              col: el.col,
              row: el.row,
              value: el.value,
              restricted,
              onChange: (value) => handleTableCellChange(value, el.id),
              removeColumn: () => removeColumn(el.col),
              style: {
                fontsWeight: 'normal !important',
                backgroundColor: el.col === -1 && '#FFFFFF',
                padding: '24px',
                border: el.col === -1 && 'none',
                borderRight: '1px solid #f0f0f0',
              },
            }),
            onCell: ({ row }) => {
              const cell = exerciseCells.find(cell => cell.row === row && cell.col === el.col);
              return {
                cell: el.id,
                col: el.col,
                row: row,
                value: cell?.value,
                isCorrect: cell?.isCorrect,
                restricted,
                onChange: (value) => el.col === 0 && handleTableCellChange(value, cell.id),
                onClick: handleTableChoiceCellChange,
                style: {
                  fontsWeight: 'normal !important',
                  backgroundColor: el.col === -1 && '#FFFFFF',
                  padding: '24px',
                  border: el.col === -1 && 'none',
                  borderRight: '1px solid #f0f0f0',
                },
              };
            },
          },
        ],
      }))
      ?? [];
  }, [exerciseCells, handleTableCellChange, handleTableChoiceCellChange, removeColumn, restricted]);

  const computedRowCells = useMemo(() => exerciseCells
    ?.filter(el => el.col === 0 && el.row !== 0)
    ?.map(el => {
      const rowCellMap = exerciseCells
        .filter(cell => cell.row === el.row && cell.col !== 0 && cell.row !== 0)
        .reduce((acc, cell) => ({ ...acc, key: cell.id, [cell.col]: cell }), {});

      return {
        ...el,
        ...rowCellMap,
      };
    })
    ?? []
  , [exerciseCells]);

  return (
    <FlowStep
      stepNumber={number}
      header={lang.exerciseForm.typeTable}
    >
      <div className={classes.tableWrapper}>
        <Table
          ref={tableRef}
          components={{
            header: {
              cell: HeaderCell,
            },
            body: {
              cell: TableCell,
            },
          }}
          bordered
          scroll={{ x: 'max-content', y: 190 }}
          className={classes.table}
          dataSource={computedRowCells}
          columns={restricted ? computedHeaderCells : [...deletionRow, ...computedHeaderCells]}
          pagination={false}
          tableLayout="fixed"
        />
        {!restricted &&
          <div className={classes.addColumnButtonWrapper}>
            <Button sibling className={classes.addColumnButton} onClick={handleAddColumn}>
              <Icon icon="plus" size="lg" />
            </Button>
          </div>
        }
      </div>
      {!restricted &&
      <div className={classes.addRowButtonWrapper}>
        <Button sibling className={classes.addRowButton} onClick={handleAddRow}>
          <Icon icon="plus" size="lg" />
        </Button>
      </div>
      }
    </FlowStep>
  );
};

AskForTable.propTypes = {
  number: PropTypes.number.isRequired,
  exerciseCells: PropTypes.array.isRequired,
  tableDispatcher: PropTypes.func.isRequired,
  restricted: PropTypes.bool,
};

export default AskForTable;
