export const highlightColors = [
  {
    name: 'red',
    'default': '#ff9494',
    overlap: '#e55c5c',
  },
  {
    name: 'yellow',
    'default': '#fff860',
    overlap: '#ded400',
  },
  {
    name: 'green',
    'default': '#54e083',
    overlap: '#43b269',
  },
  {
    name: 'blue',
    'default': '#a8e0ff',
    overlap: '#33b6ff',
  },
];
