import React, { useState, Fragment, useCallback, useEffect } from 'react';
import { faArrowLeft, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { selectTestGroupId, selectTestGroupName } from 'actions/testActions';
import { selectHasPersonalSpace, selectUserFullName, selectUserId, selectUserSchools } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import { cleanAndSortGroups } from 'utils';

import Button from 'components/common/Button';
import IconBtn from 'components/common/IconBtn';
import Input from 'components/common/Input';
import Tooltip from 'components/common/Tooltip';
import ExerciseBank from 'components/exercises/exercise-bank/ExerciseBank';

import useStyles from './styles';

const SelectExercises = ({ sectionId, cancel, afterSubmit }) => {
  const classes = useStyles();
  const toast = useToast();
  const { spaceId, testId } = useParams();
  const userId = useSelector(selectUserId);
  const userName = useSelector(selectUserFullName);
  const schools = useSelector(selectUserSchools);
  const hasPersonalSpace = useSelector(selectHasPersonalSpace);
  const groupId = useSelector(selectTestGroupId);
  const groupName = useSelector(selectTestGroupName);
  const [getGroupsRequest] = useApi(api.getGroups);
  const [addExercisesRequest] = useApi(api.addExercises);

  const schoolOptions = schools?.map(school => ({ value: school.id, label: school.name }));

  if (hasPersonalSpace) {
    schoolOptions.push({ value: 'p', label: lang.appKeywords.personalSpace });
  }

  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState({ value: groupId, label: groupName });
  const [school, setSchool] = useState(schoolOptions.find(currentSchool => currentSchool.value === spaceId));
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [addLoading, setAddLoading] = useState(false);

  const userFilters = school.value !== 'p' && school.value !== spaceId ? [{ id: userId, name: userName, disabled: true }] : null;

  const groupOptions = groups.map(group => ({ value: group?.id, label: group.label, schoolId: group.schoolId }))
    .filter(group => group.schoolId === (school?.value !== 'p' ? school?.value : null));

  const fetchGroups = useCallback(() => {
    getGroupsRequest([spaceId], null, ({ data }) => {
      if (data.status === 0) {
        const currentGroups = cleanAndSortGroups(data.groups);
        const currentGroup = currentGroups.find(currentGroup => currentGroup.id === groupId);

        setGroups(currentGroups);
        setGroup({
          value: currentGroup.id,
          label: currentGroup.label,
        });
      }
    });
  }, [getGroupsRequest, spaceId, groupId]);

  const _setSchool = (school) => {
    setSchool(school);

    const firstSchoolGroup = groups.map(group => ({ value: group.id, label: group.label, schoolId: group.schoolId }))
      .filter(group => group.schoolId === (school?.value !== 'p' ? school?.value : null))[0];

    setGroup(firstSchoolGroup);
  };

  const handleAdd = () => {
    setAddLoading(true);

    const templateExerciseIds = selectedExercises.map(exercise => exercise.id);

    addExercisesRequest([testId], { templateExerciseIds, sectionId }, ({ data }) => {
      setAddLoading(false);

      if (data.status === 0) {
        toast.success(lang.test.successAddExercises);
        afterSubmit();
      } else {
        toast.error(lang.oops);
      }
    });
  };

  const clearSelected = () => {
    if (addLoading) {
      return;
    }

    setSelectedExercises([]);
  };

  const goBack = () => {
    if (addLoading) {
      return;
    }

    cancel();
  };

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return (
    <Fragment>
      <div className={classes.header}>
        <div className={classes.titleWrap}>
          <Render when={cancel}>
            <IconBtn
              icon={faArrowLeft}
              tip={lang.goBack}
              onClick={goBack}
            />
          </Render>
          <div className={classes.title}>
            {lang.test.selectExercises}
          </div>
        </div>
        <div className={classes.selectorWrapper}>
          <div className={classes.groupWrapper}>
            <div className={classes.label}>
              {lang.appKeywords.spaces}
            </div>
            <div className={classes.schoolWrapper}>
              <Render when={school.value !== 'p' && school.value !== spaceId}>
                <Tooltip
                  tip={lang.test.selectExercisesFromOtherSpace}
                  left
                >
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                </Tooltip>
              </Render>
              <div className={classes.schoolSelector}>
                <Input
                  type="select"
                  value={school}
                  placeholder={lang.appKeywords.space}
                  onChange={(option) => _setSchool(option)}
                  options={schoolOptions}
                />
              </div>
            </div>
          </div>
          <div className={classes.groupWrapper}>
            <div className={classes.label}>
              {lang.group.title}
            </div>
            <div>
              <Input
                type="select"
                value={group}
                placeholder={lang.appKeywords.group}
                onChange={(option) => setGroup(option)}
                options={groupOptions}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={cx(classes.selectedExercisesWrap, 'otl-noselect')}>
        <div>
          {selectedExercises.length}
          {' '}
          {lang.test.selectedExercises}
        </div>
        <div className={classes.selectedExercisesSeparator}>
          {'·'}
        </div>
        <div className={classes.clearSelected} onClick={clearSelected}>
          {lang.clear}
        </div>
      </div>
      <ExerciseBank
        groupId={group?.value}
        selected={selectedExercises}
        setSelected={setSelectedExercises}
        userFilters={userFilters}
        selectable
        wrapped
      />
      <div className={classes.btnContainer}>
        <Button
          onClick={handleAdd}
          className={classes.btn}
          disabled={selectedExercises.length === 0}
          loading={addLoading}
          sibling
        >
          {lang.test.addExercises}
        </Button>
        <Button
          onClick={goBack}
          className={classes.btn}
          black
          sibling
        >
          {lang.cancel}
        </Button>
      </div>
    </Fragment>
  );
};

SelectExercises.propTypes = {
  sectionId: PropTypes.string,
  cancel: PropTypes.func,
  afterSubmit: PropTypes.func,
};

export default SelectExercises;
