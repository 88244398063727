import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  exercisesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px auto',
    width: '1220px',
    '& .infinite-scroll-component__outerdiv': {
      width: '100%',
    },
  },
  exerciseListHeader: {
    width: '100%',
    padding: '20px 0 10px 0',
    fontWeight: 600,
    fontSize: '25px',
  },
  scroller: {
    overflow: 'unset !important',
  },
  exerciseItemsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(305px, 1fr))',
    width: '100%',
    gridAutoFlow: 'dense',
  },
  large: {
    gridColumn: 'auto / span 2',
    gridRow: 'auto / span 2',
  },
  endMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  endMessageSeparator: {
    width: '80%',
    borderBottom: '1px solid #bebebe',
    margin: '54px auto 36px auto',
  },
  endMessage: {
    color: '#636363',
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '20px',
  },
  scrollBackToTopButton: {
    textAlign: 'center',
    margin: '0 auto 20px auto',
  },
  scrollBackToTopIcon: {
    margin: '0 10px',
  },
  [`@media (max-width: ${theme.xl}px)`]: {
    exercisesContainer: {
      width: '915px',
    },
  },
  [`@media (max-width: ${theme.lg}px)`]: {
    exercisesContainer: {
      width: '610px',
    },
  },
  [`@media (max-width: ${theme.md}px)`]: {
    exercisesContainer: {
      width: '610px',
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    exercisesContainer: {
      width: '305px',
    },
    large: {
      gridColumn: 'auto',
      gridRow: 'auto',
    },
  },
}));
