import React, { useCallback, useState } from 'react';
import { DndContext, DragOverlay, pointerWithin } from '@dnd-kit/core';
import { Spacer } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectAnswer, selectAttemptId, updateAnswer } from 'actions/studentAttemptActions';
import { selectUserLoggedIn } from 'actions/userActions';
import api from 'api';
import { ANSWER } from 'constants/answerTypes';
import { WRITE } from 'constants/exerciseOptions';
import { CAPTION, FILLING } from 'constants/exerciseTypes';
import { ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { cleanOps, quillIsEmpty } from 'utils';

import EntityBody from '../../common/entity/EntityBody';
import EntityContent from '../../common/entity/EntityContent';
import EntityHeader from '../../common/entity/EntityHeader';
import EntitySubHeader from '../../common/entity/EntitySubHeader';
import EntityTitle from '../../common/entity/EntityTitle';
import ExerciseJustification from '../../exercises/exercise/exercise-answer/ExerciseJustification';
import ExerciseSavingSpinner from '../../exercises/exercise/exercise-header/ExerciseSavingSpinner';
import { IDLE, SAVING, SUCCESS } from '../../exercises/exercise/exercise-header/ExerciseSavingSpinner/states';
import ExerciseWeights from '../../exercises/exercise/exercise-sub-header/ExerciseWeights';
import ExerciseStatement from '../../exercises/exercise/ExerciseStatement';
import AnswerFlagger from '../AnswerFlagger';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import ScrollableDndContainer from 'components/common/ScrollableDndContainer';
import InnerGap from 'components/exercises/exercise/exercise-answer/exercise-answer-filling/InnerGap';
import ExerciseAnswer from 'components/exercises/exercise/exercise-answer/ExerciseAnswer';
import ProcessNodeOptions from 'components/exercises/exercise-form/ProcessNodeOptions';

import useStyles from './styles';

const AnswerableExercise = ({ num, answerId, sectionId, setSavingAnswer, setWriting, setPendingAnswer }) => {
  const classes = useStyles();
  const toast = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedIn = useSelector(selectUserLoggedIn);
  const attemptId = useSelector(selectAttemptId);
  const [answerExerciseRequest] = useApi(api.answerExercise, !loggedIn);
  const {
    statement, enableMathSymbols, mathSymbols, type, choices, hasMultipleCorrectChoices, gaps,
    answer, hasJustification, justification, criteriaWeights, image, isCompact, isShortAnswer,
    characterLimit, option, extraText, extraTextStartExpanded, connectors, connections,
    exerciseCells, gradingOption, hasWordCount, isFlagged,
  } = useSelector(selectAnswer(answerId, sectionId));
  const quillAnswersToggle = useFeature(toggles.quillAnswers);
  const iaveToggle = useFeature(toggles.iave);
  const asyncAnswersToggle = useFeature(toggles.asyncAnswers);
  const studentFlaggingToggle = useFeature(toggles.studentFlagging);

  const [savingState, setSavingState] = useState(IDLE);
  const [dropAnswers, setDropAnswers] = useState(answer ? answer : []);
  const [active, setActive] = useState(null);

  const answerExercise = useCallback((newAnswer) => {
    if ([FILLING, CAPTION].includes(type) && option === WRITE) {
      newAnswer.forEach(answer => {
        if (answer.text) {
          answer.text = type === FILLING ? cleanOps(answer.text) : answer.text;
        }
      });
    }

    if (asyncAnswersToggle) {
      setPendingAnswer(answerId, sectionId, {
        answer: newAnswer,
        createdAt: new Date().getTime(),
        type: ANSWER,
      });
      return;
    }

    setSavingAnswer(true);
    setSavingState(SAVING);
    answerExerciseRequest([attemptId, answerId], { answer: newAnswer }, ({ data }) => {
      setSavingAnswer(false);
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
          setTimeout(() => {
            setSavingState(IDLE);
          }, 300);
          dispatch(updateAnswer(answerId, newAnswer, sectionId));
          return;
        }

        if (data.status === 2 || data.status === 3) {
          toast.warning(lang.test.finishTestEnded);
          history.push('/');
          setSavingState(IDLE);
          return;
        }

        if (data.status !== ERROR) {
          toast.error(lang.oops);
          setSavingState(IDLE);
        }
      }, 300);
    });
  }, [setSavingAnswer, type, option, asyncAnswersToggle, answerExerciseRequest, attemptId, answerId, setPendingAnswer, dispatch, sectionId, toast, history]);

  const findDropAnswer = (dropAnswersCopy, over) => {
    let drop;
    if (type === 'filling') {
      drop = dropAnswersCopy.find(item => item.position === over.position);
    } else if (type === 'caption') {
      drop = dropAnswersCopy.find(item => item.gapCoords === over.gapCoords);
    }
    return drop;
  };

  const handleDragEnd = ({ over, active }) => {
    setActive(null);
    const dropAnswersCopy = [...dropAnswers];

    if (!over || over.id === 'NULL') {
      const dropToEmpty = dropAnswersCopy.find(item => item.gapId === active.id);
      if (dropToEmpty) {
        dropToEmpty.gapId = null;
      }
    } else {
      const drop = findDropAnswer(dropAnswersCopy, over.data.current);
      const existingAnswer = dropAnswersCopy.find(item => item.gapId === active.id);

      if (existingAnswer) {
        existingAnswer.gapId = null;
      }
      drop.gapId = active.id;
    }

    setDropAnswers(dropAnswersCopy);
    answerExercise(dropAnswersCopy);
  };

  const handleDragStart = ({ active }) => {
    const gapInfo = gaps.find(gap => gap.id === active.id);
    setActive(gapInfo);
  };

  const onDragOver = ({ over, active }) => {
    const dropAnswersCopy = [...dropAnswers];
    if (!over || over.id === 'NULL') {
      const dropToEmpty = dropAnswersCopy.find(item => item.gapId === active.id);
      if (dropToEmpty) {
        dropToEmpty.gapId = null;
      }
    }
    setDropAnswers(dropAnswersCopy);
  };

  const processNode = (node) => {
    let item;
    const drop = dropAnswers.find(el => el.position === node.attribs['data-position']);
    if (!drop) {
      return null;
    }
    const gapAnswer = dropAnswers.find(answer => answer.position === drop.position);

    if (gapAnswer) {
      item = gaps.find(gap => gap.id === gapAnswer.gapId);
    } else {
      item = null;
    }

    return (
      <ProcessNodeOptions
        id={answerId}
        drop={drop}
        gaps={gaps}
        option={option}
        item={item}
        dropAnswers={dropAnswers}
        setDropAnswers={setDropAnswers}
        onAnswer={answerExercise}
        mathSymbols={mathSymbols}
        enableMathSymbols={enableMathSymbols}
        answerable
        setWriting={setWriting}
      />
    );
  };

  const instructions = [
    {
      shouldProcessNode: (node) => {
        return node.attribs && node.attribs['data-drop'];
      },
      processNode: processNode,
    },
  ];

  return (
    <EntityBody id={answerId} className={cx(classes.exerciseWrapper, { answerable: true, isFlagged })}>
      <EntityHeader>
        {!iaveToggle ? (
          <EntityTitle
            num={num}
            type={type}
          />
        ) : (
          <div />
        )}
        <ExerciseSavingSpinner
          state={savingState}
        />
        {studentFlaggingToggle && (
          <AnswerFlagger
            answerId={answerId}
            sectionId={sectionId}
          />
        )}
      </EntityHeader>
      {criteriaWeights && (
        <EntitySubHeader>
          <ExerciseWeights
            criteriaWeights={criteriaWeights ? criteriaWeights : []}
          />
        </EntitySubHeader>
      )}
      <EntityContent>
        <DndContext
          collisionDetection={pointerWithin}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragOver={onDragOver}
        >
          <ScrollableDndContainer>
            <ExerciseStatement
              statement={statement}
              instructions={instructions}
            />
            <ExerciseAnswer
              exerciseId={answerId}
              type={type}
              choices={choices}
              gaps={gaps}
              orderItems={answer}
              answer={answer}
              onAnswer={answerExercise}
              answerable
              dropAnswers={dropAnswers}
              setDropAnswers={setDropAnswers}
              image={image}
              hasMultipleCorrectChoices={hasMultipleCorrectChoices}
              isCompact={isCompact}
              isShortAnswer={isShortAnswer}
              characterLimit={characterLimit}
              option={option}
              connectors={connectors}
              connections={connections}
              mathSymbols={mathSymbols}
              enableMathSymbols={enableMathSymbols}
              setWriting={setWriting}
              gradingOption={gradingOption}
              exerciseCells={exerciseCells}
              hasWordCount={hasWordCount}
            />
            <DragOverlay
              style={{ width: 'unset' }}
            >
              {active ? <InnerGap item={active} draggable isDragging /> : null}
            </DragOverlay>
          </ScrollableDndContainer>
        </DndContext>
        {hasJustification && (
          <ExerciseJustification
            exerciseId={answerId}
            sectionId={sectionId}
            num={num}
            justification={justification ?? (quillAnswersToggle ? {} : '')}
            setSavingState={setSavingState}
            setSavingAnswer={setSavingAnswer}
            mathSymbols={mathSymbols}
            enableMathSymbols={enableMathSymbols}
            answerable
            setWriting={setWriting}
            setPendingAnswer={setPendingAnswer}
            hasWordCount={hasWordCount}
          />
        )}
        {!quillIsEmpty(extraText) && (
          <>
            <Spacer px={10} />
            <EntityExpandableText
              text={extraText}
              full={extraTextStartExpanded}
              answerable
            />
          </>
        )}
      </EntityContent>
    </EntityBody>
  );
};

AnswerableExercise.propTypes = {
  num: PropTypes.number.isRequired,
  answerId: PropTypes.string.isRequired,
  sectionId: PropTypes.string,
  setSavingAnswer: PropTypes.func.isRequired,
  setWriting: PropTypes.func,
  setPendingAnswer: PropTypes.func,
};

export default AnswerableExercise;
