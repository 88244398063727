import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    cursor: 'default',
  },
  modal: {
    '&.isOnGoing': {
      overflow: 'visible',
    },
    '& > div:first-of-type': {
      borderRadius: '24px 24px 0 0',
    },
  },
  navigationContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
});
