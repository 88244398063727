import React, { useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from 'components/common/Button';

import useStyles from './styles';

const PageHeader = ({ path, actions, noSeparators, invisible }) => {
  const classes = useStyles();

  const initialDropdowns = {};
  if (path) {
    path.forEach(item => {
      if (item.type === 'dropdown') {
        initialDropdowns[item.id] = false;
      }
    });
  }

  const [dropdown, setDropdown] = useState(initialDropdowns);

  const setSingleDropdown = (id, value) => {
    const newDropdown = { ...dropdown };
    newDropdown[id] = value;
    setDropdown(newDropdown);
  };

  const onDropdownItemClick = (item, value) => {
    item.onChange(value);
    setDropdown(false);
  };

  const getItem = (item) => {
    if (item.type === 'text') {
      return (
        <div className={classes.textItem}>
          {item.text}
        </div>
      );
    }

    if (item.type === 'link') {
      return (
        <Link
          to={item.to}
          className={classes.linkItem}
        >
          <span>
            {item.text}
          </span>
        </Link>
      );
    }

    if (item.type === 'dropdown') {
      return (
        <Dropdown
          close={() => setSingleDropdown(item.id, false)}
        >
          <div
            onClick={() => setSingleDropdown(item.id, !dropdown[item.id])}
            className={classes.dropdownItem}
            title={item.current?.label}
          >
            <div className={classes.dropdownItemLabel}>
              {item.current?.label}
            </div>
            <FontAwesomeIcon
              className={classes.dropdownArrow}
              icon={dropdown[item.id] ? faChevronUp : faChevronDown}
            />
          </div>
          <DropdownMenu
            open={dropdown[item.id]}
            className={classes.dropdownMenu}
          >
            {item.values?.map((value) => (
              <DropdownMenuItem
                key={value.id}
                className={cx(classes.dropdownMenuItem, { selected: value.id === item.current?.id })}
                onClick={() => onDropdownItemClick(item, value)}
              >
                <div
                  title={value.label}
                  className={classes.dropdownMenuItemLabel}
                >
                  {value.label}
                </div>
              </DropdownMenuItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      );
    }
  };

  const getNavigator = () => {
    if (!path) {
      return;
    }

    return (
      <div className={cx(classes.headerNavigator, { isInvisible: invisible })}>
        {path.map((item, index) => (
          <Render
            key={index}
            when={!item.hide}
          >
            <div data-tour={item.dataTour} className={classes.item}>
              {getItem(item)}
            </div>
            <Render when={!noSeparators}>
              <span className={classes.separator}>
                /
              </span>
            </Render>
          </Render>
        ))}
      </div>
    );
  };

  const getActions = () => {
    if (!actions) {
      return;
    }

    return (
      <div className={classes.headerActions}>
        {actions.map((action, index) => (
          <Render
            key={index}
            when={!action.hide}
          >
            {action.type === 'icon' ?
              <div
                onClick={action.onClick}
                className={classes.actionWrapper}
              >
                <FontAwesomeIcon
                  icon={action.icon}
                  className={classes.actionIcon}
                />
                <div className={classes.iconLabel}>
                  {action.label}
                </div>
              </div>
              :
              <Button
                dataTour={action.dataTour}
                loading={action.loading}
                onClick={action.onClick}
                black={action.color === 'black'}
                red={action.color === 'red'}
                className={classes.actionButton}
                sibling={actions.filter(el => el.type !== 'icon').length > 1}
              >
                {action.label}
              </Button>
            }
          </Render>
        ))}
      </div>
    );
  };

  return (
    <div className={classes.headerWrap}>
      {getNavigator()}
      {getActions()}
    </div>
  );
};

PageHeader.propTypes = {
  path: PropTypes.array,
  actions: PropTypes.array,
  noSeparators: PropTypes.bool,
  invisible: PropTypes.bool,
};

export default PageHeader;
