import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { PT, selectUserLang } from 'actions/userActions';
import lang from 'lang';
import { deepCopy } from 'utils';

import Button from 'components/common/Button';
import { StaticMathField } from 'components/MathQuill';

import useStyles from './styles';

const SymbolsButtons = ({ mathSymbols, setMathSymbols }) => {
  const classes = useStyles();
  const locale = useSelector(selectUserLang);

  const isAllSelected = useMemo(() => {
    for (const key in mathSymbols) {
      for (const mathSymbol of mathSymbols[key]) {
        if (!mathSymbol.added) {
          return false;
        }
      }
    }

    return true;
  }, [mathSymbols]);

  const selectSymbol = (selectedSymbol) => {
    setMathSymbols(mathSymbols => {
      const newMathSymbols = deepCopy(mathSymbols);

      for (const key in newMathSymbols) {
        for (const mathSymbol of newMathSymbols[key]) {
          if (mathSymbol.icon === selectedSymbol.icon) {
            mathSymbol.added = !mathSymbol.added;
          }
        }
      }

      return newMathSymbols;
    });
  };

  const selectAll = () => {
    setMathSymbols(mathSymbols => {
      const newMathSymbols = deepCopy(mathSymbols);

      for (const key in newMathSymbols) {
        for (const mathSymbol of newMathSymbols[key]) {
          mathSymbol.added = !isAllSelected;
        }
      }

      return newMathSymbols;
    });
  };

  const buildBtn = (btn) => {
    let icon = btn.icon;

    if (icon === '\\sin') {
      icon = locale === PT ? 'sen' : '\\sin';
    }

    if (icon === '\\tan') {
      icon = locale === PT ? 'tg' : '\\tan';
    }

    if (icon === '\\sin^{-1}') {
      icon = locale === PT ? 'sen^{-1}' : '\\sin^{-1}';
    }

    if (icon === '\\tan^{-1}') {
      icon = locale === PT ? 'tg^{-1}' : '\\tan^{-1}';
    }

    return (
      <Button
        key={icon}
        onClick={() => selectSymbol(btn)}
        className={cx(classes.formulaBtn, { added: btn.added })}
        style={{ width: btn.width, height: btn.height, fontSize: btn.fontSize }}
        black
        sibling
      >
        <StaticMathField style={{ pointerEvents: 'none' }}>
          {icon}
        </StaticMathField>
      </Button>
    );
  };

  const buildSections = (sections) => {
    const innerSections = sections.map(innerSection => {
      const btns = mathSymbols[innerSection.name].map(exp => buildBtn(exp));
      return (
        <div key={innerSection.name} className={classes.innerSection}>
          {btns}
        </div>
      );
    });

    return (
      <div className={classes.innerSectionsContainer}>
        {innerSections}
      </div>
    );
  };

  const getButtons = () => {
    const sections = [
      { name: 'specials' },
      { name: 'operators' },
      { name: 'symbols' },
      { name: 'sets' },
      { name: 'numberSets' },
      { name: 'parentheses' },
    ];

    return (
      <div className={classes.outerSectionsContainer}>
        {buildSections(sections)}
      </div>
    );
  };

  return (
    <div>
      <div className={classes.selectAllBtnContainer}>
        <Button
          onClick={selectAll}
          className={cx(classes.formulaBtn, { added: isAllSelected })}
          style={{ width: '100px', fontSize: '16' }}
          black
        >
          {lang.selectAll}
        </Button>
      </div>
      {getButtons()}
    </div>
  );
};

SymbolsButtons.propTypes = {
  setMathSymbols: PropTypes.func,
  mathSymbols: PropTypes.object,
};

export default SymbolsButtons;
