import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Row } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useParams } from 'react-router';

import api from 'api';
import useApi from 'hooks/common/useApi';
import useLoading from 'hooks/common/useLoading';
import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';

import Header from 'components/group/Header';
import RemoveTeacherModal from 'components/group/RemoveTeacherModal';
import Shortcuts from 'components/group/Shortcuts';
import TeacherActions from 'components/group/TeacherActions';
import TeachersList from 'components/group/TeachersList';

import useStyles from './styles';

const Group = () => {
  const { loaded } = usePageLogic(lang.group.title, true);
  const { groupId } = useParams();
  const toast = useToast();
  const classes = useStyles();
  const [getGroupRequest] = useApi(api.getGroup);

  const [group, setGroup] = useState(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [teachersToRemove, setTeachersToRemove] = useState([]);
  const [teachersLoading, setTeachersLoading] = useLoading(false);

  const feedRemoveModal = (teachers) => {
    setTeachersToRemove(teachers);
    setShowRemoveModal(true);
  };

  const close = () => {
    const newTeachers = teachersToRemove.map(teacher => ({
      ...teacher,
      selected: false,
    }));

    setTeachersToRemove(newTeachers);
    setShowRemoveModal(false);
  };

  const fetchGroup = useCallback(() => {
    if (groupId) {
      setTeachersLoading(true);
      getGroupRequest([groupId], null, ({ data }) => {
        setTeachersLoading(false);
        if (data.status === 0) {
          loaded();
          setGroup(data.group);
          setTeachersToRemove(data.group.teachers);
        } else {
          toast.error(lang.oops);
        }
      });
    }
  }, [getGroupRequest, groupId, loaded, setTeachersLoading, toast]);

  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  if (!group) {
    return null;
  }

  return (
    <Fragment>
      <Header
        group={group}
        fetchGroup={fetchGroup}
      />
      <Row className={classes.row}>
        <Col xl={12} className={classes.mobileInvisible}>
          <Shortcuts
            totalTests={group.totalTests}
            totalExercises={group.totalExercises}
            totalRubrics={group.totalRubrics}
          />
        </Col>
        <Col xl={8}>
          <TeacherActions
            teachers={teachersToRemove}
            setTeachers={setTeachersToRemove}
            setRemoveTeachersModal={setShowRemoveModal}
          />
          <TeachersList
            teachers={teachersToRemove}
            feedRemoveModal={feedRemoveModal}
            setTeachersToRemove={setTeachersToRemove}
            loading={teachersLoading}
          />
        </Col>
      </Row>
      <RemoveTeacherModal
        open={showRemoveModal}
        close={() => close()}
        groupId={groupId}
        teachers={teachersToRemove}
        refresh={fetchGroup}
      />
    </Fragment>
  );
};

export default Group;
