import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) =>({
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '0.5em',
  },
  dropdown: {
    display: 'flex',
    justifyContent: 'center',
  },
  menu: {
    display: 'flex !important',
    minWidth: 'unset !important',
    borderRadius: '0',
    backgroundColor: theme.inputBackground2,
    border: `1px solid ${theme.inputBorder2} !important`,
  },
  button: {
    marginLeft: '0.5em',
    width: '2.5em',
    height: '2.5em',
    border: 'unset !important',
  },
  item: {
    borderBottom: 'unset !important',
    margin: 'unset !important',
    '&:hover': {
      backgroundColor: theme.shade1,
      cursor: 'pointer',
    },
  },
  'delete': {
    borderBottom: 'unset !important',
    margin: 'unset !important',
    backgroundColor: '#B12B2B',
    '&:hover': {
      backgroundColor: '#B12B2BCC',
      cursor: 'pointer',
    },
  },
}));
