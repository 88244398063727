import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Toggle, InputArea } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';

import { closeFormulaModal, selectFormulaModalOptions } from 'actions/formulaModalActions';
import { PT, selectUserIsTeacher, selectUserLang } from 'actions/userActions';
import { macros } from 'constants/katexMacros';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { cleanLatex, validateLatex } from 'utils';

import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import { EditableMathField, StaticMathField } from 'components/MathQuill';

import expressions from './expressions';
import useStyles from './styles';

const FormulaModal = () => {
  const classes = useStyles();
  const toast = useToast();
  const locale = useSelector(selectUserLang);
  const isDark = useSelector(state => state.page.isDark);
  const iaveToggle = useFeature(toggles.iave);
  const isTeacher = useSelector(selectUserIsTeacher);
  const dispatch = useDispatch();
  const { open, insert, value, enableMathSymbols = false, mathSymbols = expressions } = useSelector(selectFormulaModalOptions);

  const [advancedMode, setAdvancedMode] = useState(false);
  const [advancedValue, setAdvancedValue] = useState(value ?? '');

  const mqRef = useRef();

  useEffect(() => {
    if (open === true) {
      mqRef.current.focus();
    }
  }, [open]);

  const mounted = (mq) => {
    mqRef.current = mq;
  };

  const close = useCallback(() => {
    dispatch(closeFormulaModal());
  }, [dispatch]);

  const insertFormula = useCallback(() => {
    let value;

    if (advancedMode) {
      if (advancedValue.trim() === '') {
        toast.warning(lang.exerciseForm.noEmptyFormula);
        return;
      }
      value = advancedValue;
    } else {
      const formulaLatex = mqRef.current.latex();

      if (formulaLatex.trim() === '') {
        toast.warning(lang.exerciseForm.noEmptyFormula);
        mqRef.current.focus();
        return;
      }
      value = formulaLatex;
    }

    value = cleanLatex(value);

    const isValid = validateLatex(value);
    if (!isValid) {
      toast.error(lang.exerciseForm.noInvalidFormula);
      return;
    }

    if (insert) {
      insert(value);
      mqRef.current.empty();
      setAdvancedValue(value);
      close();
    }
  }, [insert, close, toast, advancedMode, advancedValue]);

  const buildBtn = (btn) => {
    let icon = btn.icon;
    let write = btn.write;

    if (icon === '\\sin') {
      icon = locale === PT ? 'sen' : '\\sin';
      write = locale === PT ? 'sen()' : '\\sin()';
    }

    if (icon === '\\tan') {
      icon = locale === PT ? 'tg' : '\\tan';
      write = locale === PT ? 'tg()' : '\\tan()';
    }

    if (icon === '\\sin^{-1}') {
      icon = locale === PT ? 'sen^{-1}' : '\\sin^{-1}';
      write = locale === PT ? 'sen^{-1}()' : '\\sin^{-1}()';
    }

    if (icon === '\\tan^{-1}') {
      icon = locale === PT ? 'tg^{-1}' : '\\tan^{-1}';
      write = locale === PT ? 'tg^{-1}()' : '\\tan^{-1}()';
    }

    const addSymbol = () => {
      if (btn.multiple) {
        btn.multiple.forEach((el) => {
          if (el.cmd) {
            mqRef.current.cmd(el.cmd);
          } else if (el.write) {
            mqRef.current.write(el.write);
          }

          if (el.after) {
            mqRef.current.keystroke(el.after);
          }
        });
      } else {
        if (btn.cmd) {
          mqRef.current.cmd(btn.cmd);
        } else if (write) {
          mqRef.current.write(write);
        }

        if (btn.after) {
          mqRef.current.keystroke(btn.after);
        }
      }
      mqRef.current.focus();
    };

    return (
      <Button
        key={icon}
        onClick={addSymbol}
        className={classes.formulaBtn}
        style={{ width: btn.width, height: btn.height, fontSize: btn.fontSize, display: (!btn.added && enableMathSymbols) ? 'none' : 'flex' }}
        black
        sibling
      >
        <StaticMathField style={{ pointerEvents: 'none' }}>
          {icon}
        </StaticMathField>
      </Button>
    );
  };

  const buildSections = (sections) => {
    const innerSections = sections.map(innerSection => {
      const btns = mathSymbols?.[innerSection.name].map(exp => buildBtn(exp)) ?? [];
      return (
        <div key={innerSection.name} className={classes.innerSection}>
          {btns}
        </div>
      );
    });

    return (
      <div className={classes.innerSectionsContainer}>
        {innerSections}
      </div>
    );
  };

  const getButtons = () => {
    const sections = [
      { name: 'specials' },
      { name: 'operators' },
      { name: 'symbols' },
      { name: 'sets' },
      { name: 'numberSets' },
      { name: 'parentheses' },
    ];

    return (
      <div className={classes.outerSectionsContainer}>
        {buildSections(sections)}
      </div>
    );
  };

  const processAdvanced = () => {
    const cleaned = cleanLatex(advancedValue);
    return window.katex.renderToString(cleaned, {
      throwOnError: false,
      macros,
    });
  };

  const getModalButtons = () => {
    return (
      <div className={classes.modalButtonsWrapper}>
        <Button
          onClick={insertFormula}
          sibling
        >
          {lang.add}
        </Button>
        <Button
          onClick={close}
          sibling
          black
        >
          {lang.cancel}
        </Button>
      </div>
    );
  };

  const getContent = () => {
    if (advancedMode) {
      return (
        <div>
          <div className={classes.advancedPreviewWrap}>
            <div className={classes.advancedPreviewLabel}>
              {lang.common.preview}
            </div>
            <div
              className={classes.advancedPreview}
              dangerouslySetInnerHTML={{ __html: processAdvanced() }}
            />
          </div>
          <div className={classes.advancedReferenceWrap}>
            <a
              href="https://katex.org/docs/supported.html"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.advancedReference}
            >
              {lang.exerciseForm.formulaReference}
            </a>
          </div>
          <InputArea
            value={advancedValue}
            onChange={(event) => setAdvancedValue(event.target.value)}
            placeholder={lang.exerciseForm.formulaLabel}
            className={classes.advancedInput}
            dark={isDark}
          />
          {getModalButtons()}
        </div>
      );
    } else {
      return (
        <div>
          <div className={classes.formulaLabel}>
            {lang.exerciseForm.formulaLabel}
            {':'}
          </div>
          <EditableMathField
            latex={value ? value : ''}
            mathquillDidMount={mounted}
            className={classes.formulaInput}
          />
          {getModalButtons()}
          {getButtons()}
        </div>
      );
    }
  };

  return (
    <Modal
      open={open}
      close={close}
      header={lang.exerciseForm.formulaHeader}
      center
      transition
      small
      opacity={0.001}
      className={classes.modal}
    >
      <div className={classes.toggleWrap}>
        <Render when={!iaveToggle || isTeacher}>
          <div className={classes.toggleLabel}>
            {lang.exerciseForm.advancedMode}
          </div>
          <Toggle
            checked={advancedMode}
            onChange={(checked) => setAdvancedMode(checked)}
            dark={isDark}
          />
        </Render>
      </div>
      {getContent()}
    </Modal>
  );
};

export default FormulaModal;
