import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

export const ScoreNumber = ({ statClass, statInfo, children }) => {
  const classes = useStyles();

  return (
    <>
      <Row
        className={classes.statNumberTitle}
      >
        {statClass}
        {statInfo &&
        <Tooltip tip={statInfo}>
          <FontAwesomeIcon
            icon={faInfoCircle}
            className={classes.statsInfoIcon}
          />
        </Tooltip>
        }
      </Row>
      {children}
    </>
  );
};

ScoreNumber.propTypes = {
  statClass: PropTypes.string,
  statInfo: PropTypes.string,
  children: PropTypes.any,
};

export default ScoreNumber;
