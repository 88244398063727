import React, { forwardRef, useMemo } from 'react';
import { useDndMonitor } from '@dnd-kit/core';
import { faGripVertical, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';
import { renderQuill } from 'utils';

import RichText from 'components/common/rich-text/RichText';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const OrderItem = forwardRef(({ ...props }, ref) => {
  const classes = useStyles();
  const { item, index, isDragging, isSorting, attributes, listeners, updateItem, deleteItem, itemsLength, answerable, offset, node, restricted } = props;

  const yTransformWithOffset = useMemo(() => {
    const overlayOffsetTransform = parseInt(props.style?.transform?.split(',')[1].split('px')[0] ?? 0);
    return overlayOffsetTransform + offset;
  }, [offset, props.style]);

  const style = {
    ...props.style,
    boxShadow: isDragging ? '2px 5px 10px #00000066' : undefined,
    transform: isDragging ? `translate3d(0px, ${yTransformWithOffset}px, 0px) scale3d(1.01, 1.01, 1)` : undefined,
    touchAction: 'none',
  };

  useDndMonitor({
    onDragEnd: (event) => {
      const { active } = event;
      if (active.id === item.id && node) {
        node.current.scrollIntoView({ behaviour: 'smooth', block: 'center' });
      }
    },
  });

  return (
    <div
      style={style}
      className={classes.itemWrapper}
      {...props}
      {...(answerable ? attributes : {})}
      {...(answerable ? listeners : {})}
      ref={ref}
    >
      <FontAwesomeIcon
        icon={faGripVertical}
        className={classes.handle}
        {...(!answerable ? attributes : {})}
        {...(!answerable ? listeners : {})}
      />
      {!answerable &&
      <>
        <RichText
          placeholder={lang.exerciseForm.item + ' ' + (index + 1)}
          value={item.text}
          onChange={(_content, _delta, _source, editor) => updateItem(item.id, editor.getContents())}
          className={classes.optionInput}
        />
        {!restricted &&
        <Tooltip
          tip={itemsLength <= 2 ? null : lang.exerciseForm.removeItem}
          left
        >
          <FontAwesomeIcon
            icon={faTimes}
            className={cx(classes.deleteBtn, { disabled: itemsLength <= 2 })}
            onClick={() => deleteItem(item.id)}
          />
        </Tooltip>
        }
      </>
      }
      {answerable &&
        <div
          className={cx(classes.statement, { collapse: isSorting || isDragging })}
          dangerouslySetInnerHTML={{ __html: renderQuill(item.text, {}, { preview: true }) }}
        />
      }
    </div>
  );
});

OrderItem.displayName = 'OrderItem';

OrderItem.propTypes = {
  id: PropTypes.string,
  item: PropTypes.object,
  index: PropTypes.number,
  updateItem: PropTypes.func,
  deleteItem: PropTypes.func,
  itemsLength: PropTypes.number,
  isDragging: PropTypes.bool,
  isSorting: PropTypes.bool,
  attributes: PropTypes.object,
  listeners: PropTypes.object,
  answerable: PropTypes.bool,
  offset: PropTypes.number,
  node: PropTypes.node,
  style: PropTypes.object,
  restricted: PropTypes.bool,
};

export default OrderItem;
