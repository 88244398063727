import React, { useState, useEffect, useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';
import PreviewableExercise from 'components/exercises/PreviewableExercise';

import useStyles from './styles';

const PreviewTestExerciseModal = ({ open, close, testExerciseId, refresh }) => {
  const toast = useToast();
  const classes = useStyles();
  const [getTestExerciseRequest] = useApi(api.getTestExercise);

  const [exercise, setExercise] = useState(null);

  const _close = useCallback(() => {
    close();
    setTimeout(() => {
      setExercise(null);
    }, 350);
  }, [close]);

  useEffect(() => {
    if (open === true && exercise === null) {
      getTestExerciseRequest([testExerciseId], null, ({ data }) => {
        if (data.status === 0) {
          setExercise(data.exercise);
        } else if (data.status === 2 || data.status === 3) {
          toast.error(lang.exercises.previewErrorDoesNotExist);
          _close();
          setTimeout(() => {
            refresh();
          }, 350);
        } else {
          toast.error(lang.common.internetWentWrong);
          _close();
        }
      });
    }
  }, [getTestExerciseRequest, testExerciseId, exercise, open, _close, refresh, toast]);

  const actions = [
    {
      name: lang.close,
      color: 'black',
      onClick: () => _close(),
    },
  ];

  const getContent = () => {
    if (exercise === null) {
      return (
        <Loading active />
      );
    }

    return (
      <PreviewableExercise
        id={testExerciseId}
        type={exercise.type}
        statement={exercise.statement}
        choices={exercise.choices}
        gaps={exercise.gaps}
        orderItems={exercise.orderItems}
        correctTrueFalse={exercise.correctTrueFalse}
        hasJustification={exercise.hasJustification}
        image={exercise.image}
        isShortAnswer={exercise.isShortAnswer}
        option={exercise.option}
        extraText={exercise.extraText}
        extraTextStartExpanded={exercise.extraTextStartExpanded}
        connectors={exercise.connectors}
        connections={exercise.connections}
        enableMathSymbols={exercise.enableMathSymbols}
        gradingOption={exercise.gradingOption}
        exerciseCells={exercise.exerciseCells}
        noCard
      />
    );
  };

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className={classes.modalContainer}
    >
      <Modal
        open={open}
        close={_close}
        header={lang.exercises.previewExercise}
        actions={actions}
        center
        transition
        large
      >
        {getContent()}
      </Modal>
    </div>
  );
};

PreviewTestExerciseModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  testExerciseId: PropTypes.string,
  refresh: PropTypes.func,
};

export default PreviewTestExerciseModal;
