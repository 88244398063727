import React from 'react';
import PropTypes from 'prop-types';
import ReactCountdown from 'react-countdown';

const Countdown = ({ date, renderer, onComplete }) => {
  const getDate = () => {
    const newDate = typeof date === 'string' ? (new Date(date)).getTime() : date;
    return newDate;
  };

  return (
    <ReactCountdown
      date={getDate()}
      renderer={renderer}
      onComplete={onComplete}
    />
  );
};

Countdown.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  renderer: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
};
export default Countdown;
