import React, { useState } from 'react';
import { DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import PropTypes from 'prop-types';

import lang from 'lang';

import DraggableExtraGap from '../ask-for-extra-gaps/DraggableExtraGap';
import ExtraGap from '../ask-for-extra-gaps/ExtraGap';
import FlowStep from '../FlowStep';

import useStyles from './styles';

const AskForOrderGaps = ({ type, number, gaps, setGaps }) => {
  const classes = useStyles();

  const [active, setActive] = useState(null);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    setActive(null);

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      setGaps((gaps) => {
        const oldIndex = gaps.map(el => el.id).indexOf(active.id);
        const newIndex = gaps.map(el => el.id).indexOf(over.id);

        const orderedGaps = arrayMove(gaps, oldIndex, newIndex);
        return orderedGaps.map((oldGap, index) => ({
          ...oldGap,
          order: index,
        }));
      });
    }
  };

  const handleDragStart = (event) => {
    const { active } = event;
    setActive({
      item: gaps.find(el => el.id === active.id),
      index: gaps.map(el => el.id).indexOf(active.id),
    });
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  return (
    <FlowStep
      stepNumber={number}
      header={lang.exerciseForm.gap.orderGaps}
    >
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
      >
        <SortableContext
          items={gaps}
          strategy={verticalListSortingStrategy}
        >
          <div className={classes.gapContainer}>
            {gaps.map((gap, index) => (
              <DraggableExtraGap
                key={gap.id}
                id={gap.id}
                item={gap}
                index={index}
                type={type}
                draggable={true}
                isCorrect
              />
            ))}
          </div>
        </SortableContext>
        <DragOverlay>
          {active ? <ExtraGap type={type} item={active.item} index={active.index} isDragging /> : null}
        </DragOverlay>
      </DndContext>
    </FlowStep>
  );
};

AskForOrderGaps.propTypes = {
  type: PropTypes.string,
  number: PropTypes.number,
  gaps: PropTypes.array,
  setGaps: PropTypes.func,
};

export default AskForOrderGaps;
