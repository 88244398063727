import { createUseStyles } from 'react-jss';

export default createUseStyles({
  modal: {
    '& > div:first-of-type': {
      borderRadius: '24px 24px 0 0',
    },
  },
  label: {
    marginBottom: '0.5em',
  },
});
