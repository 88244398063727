import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend, Rectangle, LabelList } from 'recharts';

import lang from 'lang';

import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

export const GradeDistributionChart = ({ scores, total, question }) => {
  const classes = useStyles();

  const gradeIntervals = [];

  for (let i = 0; i < 100; i += 10) {
    if (i < 90){
      const start = i;
      const end = i + 10;
      const gradeInterval = `${start}%`;
      const studentCount = scores.filter(score => (score * 100) / total >= start && (score * 100) / total < end).length;
      gradeIntervals.push({ gradeInterval, studentCount });
    } else if (i >= 90){
      const lastIntervalStudentCount = scores.filter(score => (score * 100) / total >= 90 && (score * 100) / total <= 100).length;
      question ? gradeIntervals.push({ gradeInterval: '90%-100%', studentCount: lastIntervalStudentCount }) : gradeIntervals.push({ gradeInterval: '90%', studentCount: lastIntervalStudentCount });
    }
  }

  return (
    <>
      <ResponsiveContainer className={classes.chart} debounce={100} width={'95%'} height={200}>
        <BarChart
          width={550}
          height={250}
          data={gradeIntervals}
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {question ?
            <XAxis dataKey="gradeInterval" scale="band" />
            :
            <XAxis dataKey="gradeInterval" scale="band" label={{ value: '100%', position: 'right', offset: -15, fill: '#019a39' }} />
          }
          <YAxis
            domain={[0, scores.length]}
            label={{
              value: lang.test.stats.studentCount,
              style: { textAnchor: 'middle' },
              angle: -90,
              position: 'left',
              offset: 0,
            }}
          />
          <Legend />
          <Bar dataKey="studentCount" minPointSize={5} fill="#8884d8" name={
            <span>
              {lang.test.stats.gradeCount}
              <Tooltip tip={lang.test.stats.gradeCountTip} place="right">
                <FontAwesomeIcon icon={faInfoCircle} className={classes.statsInfoIcon} />
              </Tooltip>
            </span>
          } activeBar={<Rectangle stroke="#1c195e" />} barSize={100}
          >
            <LabelList dataKey="studentCount" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>

  );
};

GradeDistributionChart.propTypes = {
  scores: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  question: PropTypes.bool,
};

export default GradeDistributionChart;
