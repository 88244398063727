import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { zeroPad } from 'react-countdown';

const MinifiedCountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return null;
  }

  return (
    <div style={{ textAlign: 'right', fontSize: '20px' }}>
      <Render when={days > 0}>
        <span>
          {zeroPad(days)}
        </span>
        :
      </Render>
      <span>
        {zeroPad(hours)}
      </span>
      :
      <span>
        {zeroPad(minutes)}
      </span>
      :
      <span>
        {zeroPad(seconds)}
      </span>
    </div>
  );
};

MinifiedCountdownRenderer.propTypes = {
  days: PropTypes.number,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  completed: PropTypes.bool,
};

export default MinifiedCountdownRenderer;
