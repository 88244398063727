import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  addFilterContainer: {
    position: 'absolute',
    backgroundColor: theme.white,
    color: theme.textColor,
    top: '25px',
    borderRadius: '4px',
    cursor: 'default',
  },
  tabsContainer: {
    width: '300px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    '& .otl-tabsHeaders, .otl-tabsTab, .otl-tabHeader, .otl-tabHeader.active': {
      border: 'none',
      backgroundColor: theme.white,
    },
    '&.subjects': {
      '& .otl-tabHeader.active': {
        borderBottom: '2px solid #CB6EB7',
      },
    },
    '& .otl-tabHeader.active': {
      borderBottom: '2px solid #F79649',
    },
    '& .otl-tabHeaderInner': {
      padding: '0 10px',
    },
    '& .otl-tabsTab': {
      padding: '10px',
    },
  },
  tab: {
    border: 'none',
  },
}));
