import Cookies from 'js-cookie';

import { BOOT_USER, LANG_SET, PT, SET_SPACE_ID_PREFERENCE, SET_FEATURE_TOGGLES, SET_PREFERENCES, INCREMENT_TOTAL_TESTS, DECREMENT_TOTAL_TESTS, INCREMENT_TOTAL_EXERCISES, DECREMENT_TOTAL_EXERCISES, INCREMENT_TOTAL_RUBRICS, DECREMENT_TOTAL_RUBRICS, INCREMENT_TOTAL_GROUPS, DECREMENT_TOTAL_GROUPS } from 'actions/userActions';
import lang from 'lang';

const defaultLang = Cookies.get('session_lang') || PT;
lang.setLanguage(defaultLang);

const initialState = {
  loggedIn: false,
  data: {},
  lang: defaultLang,
};

lang.setLanguage(initialState.lang);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOT_USER:
      return {
        ...state,
        loggedIn: true,
        data: action.data,
      };
    case LANG_SET:
      return {
        ...state,
        lang: action.lang,
      };
    case SET_PREFERENCES:
      return {
        ...state,
        data: {
          ...state.data,
          subjects: action.subjects,
          years: action.years,
        },
      };
    case SET_FEATURE_TOGGLES:
      return {
        ...state,
        data: {
          ...state.data,
          featureToggles: action.featureToggles,
        },
      };
    case SET_SPACE_ID_PREFERENCE:
      return {
        ...state,
        data: {
          ...state.data,
          spaceIdPreference: action.spaceIdPreference,
        },
      };
    case INCREMENT_TOTAL_TESTS:
      return {
        ...state,
        data: {
          ...state.data,
          freeUsages: {
            ...state.data.freeUsages,
            totalTests: state.data.freeUsages.totalTests + action.amount,
          },
        },
      };
    case DECREMENT_TOTAL_TESTS:
      return {
        ...state,
        data: {
          ...state.data,
          freeUsages: {
            ...state.data.freeUsages,
            totalTests: state.data.freeUsages.totalTests - action.amount,
          },
        },
      };
    case INCREMENT_TOTAL_EXERCISES:
      return {
        ...state,
        data: {
          ...state.data,
          freeUsages: {
            ...state.data.freeUsages,
            totalExercises: state.data.freeUsages.totalExercises + action.amount,
          },
        },
      };
    case DECREMENT_TOTAL_EXERCISES:
      return {
        ...state,
        data: {
          ...state.data,
          freeUsages: {
            ...state.data.freeUsages,
            totalExercises: state.data.freeUsages.totalExercises - action.amount,
          },
        },
      };
    case INCREMENT_TOTAL_RUBRICS:
      return {
        ...state,
        data: {
          ...state.data,
          freeUsages: {
            ...state.data.freeUsages,
            totalRubrics: state.data.freeUsages.totalRubrics + action.amount,
          },
        },
      };
    case DECREMENT_TOTAL_RUBRICS:
      return {
        ...state,
        data: {
          ...state.data,
          freeUsages: {
            ...state.data.freeUsages,
            totalRubrics: state.data.freeUsages.totalRubrics - action.amount,
          },
        },
      };
    case INCREMENT_TOTAL_GROUPS:
      return {
        ...state,
        data: {
          ...state.data,
          freeUsages: {
            ...state.data.freeUsages,
            totalGroups: state.data.freeUsages.totalGroups + action.amount,
          },
        },
      };
    case DECREMENT_TOTAL_GROUPS:
      return {
        ...state,
        data: {
          ...state.data,
          freeUsages: {
            ...state.data.freeUsages,
            totalGroups: state.data.freeUsages.totalGroups - action.amount,
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
