import React, { useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';

import useStyles from './styles.js';

const OrderSelector = ({ order, setOrder }) => {
  const classes = useStyles();

  const [dropdown, setDropdown] = useState(false);

  const options = [
    { value: 'newest', label: lang.exercises.orderNewest },
    { value: 'oldest', label: lang.exercises.orderOldest },
    { value: 'last_modified', label: lang.exercises.orderLastModified },
  ];

  const getOption = () => {
    return options.find(option => option.value === order).label;
  };

  const onOrderChange = (value) => {
    setOrder(value);
    setDropdown(false);
  };

  return (
    <div className={classes.orderSelectorContainer}>
      <Dropdown close={() => setDropdown(false)}>
        <div
          className={classes.orderDropdownSelect}
          onClick={() => setDropdown(!dropdown)}
        >
          {getOption()}
          <FontAwesomeIcon
            icon={faChevronDown}
            className={classes.orderDropdownIcon}
          />
        </div>
        <DropdownMenu
          className={classes.orderDropdownMenu}
          open={dropdown}
        >
          {options.map((option) => (
            <DropdownMenuItem
              key={option.value}
              className={cx(classes.orderDropdownItem, { selected: order === option.value })}
              onClick={() => onOrderChange(option.value)}
            >
              {option.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

OrderSelector.propTypes = {
  order: PropTypes.string.isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default OrderSelector;
