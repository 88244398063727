import React, { useState, useEffect, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { pageError } from 'actions/pageActions';
import api from 'api';
import { ATTEMPT } from 'constants/localStorageItems';
import { FINISHED, IS_INSIDE, IS_ONGOING, UPCOMING } from 'constants/publicationStages';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { getErrorCode } from 'utils';

import LangSelector from 'components/common/LangSelector';
import Logo from 'components/common/Logo';
import ConfirmPassword from 'components/publications/ConfirmPassword';
import PublicationForm from 'components/publications/PublicationForm';
import PublicationHeader from 'components/publications/PublicationHeader';
import ReenterAttempt from 'components/publications/ReenterAttempt';

import { ReactComponent as LogoIAVE } from './logo_iave.module.svg';
import { ReactComponent as LogoIAVEWhite } from './logo_iave_branco.svg';
import { ReactComponent as LogoPRR } from './logo_prr.module.svg';
import useStyles from './styles';

const Publication = () => {
  const classes = useStyles();
  const { pageLoading, loaded } = usePageLogic(lang.tests.title, false);
  const { publicationId } = useParams();
  const iaveToggle = useFeature(toggles.iave);
  const clockOffset = useSelector(state => state.page.clockOffset);
  const [getPublicationBasicRequest] = useApi(api.getPublicationBasic, true);
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);

  const [publication, setPublication] = useState(null);
  const [currentAttempt, setCurrentAttempt] = useState(null);
  const [stage, setStage] = useState(null);

  const getInitialState = useCallback(() => {
    const now = new Date().getTime() - clockOffset;
    const isUpcoming = now < publication.startsAt;
    let isInsideWindow, isOngoing;

    if (publication.testType === 'test') {
      isInsideWindow = (now > publication.startsAt) && (now < publication.windowClosesAt) && !currentAttempt;
      const isTestOngoing = (now > publication.windowClosesAt) && (now < publication.endsAt);
      const isAttemptOngoing = currentAttempt !== null && (now < currentAttempt.endsAt);
      isOngoing = isTestOngoing || isAttemptOngoing;
    } else if (publication.testType === 'worksheet') {
      isInsideWindow = false;
      isOngoing = (now > publication.startsAt) && (now < publication.endsAt);
    }

    if (isUpcoming) {
      return UPCOMING;
    } else if (isInsideWindow) {
      return IS_INSIDE;
    } else if (isOngoing) {
      return IS_ONGOING;
    } else {
      return FINISHED;
    }
  }, [publication, clockOffset, currentAttempt]);

  const constructState = useCallback((publicationInfo) => {
    const startsAt = (new Date(publicationInfo.startsAt)).getTime();
    let windowClosesAt, endsAt;

    if (publicationInfo.testType === 'test') {
      windowClosesAt = startsAt + (publicationInfo.attemptWindow * 60 * 1000);
      endsAt = windowClosesAt + (publicationInfo.duration * 60 * 1000);
    } else if (publicationInfo.testType === 'worksheet') {
      endsAt = (new Date(publicationInfo.endsAt)).getTime();
    }

    setPublication({
      teacherName: publicationInfo.teacherName,
      testName: publicationInfo.testName,
      testType: publicationInfo.testType,
      password: publicationInfo.password ? publicationInfo.password : null,
      startsAt: startsAt,
      windowClosesAt: windowClosesAt,
      endsAt: endsAt,
    });
  }, []);

  const fetchPublicationBasic = useCallback(() => {
    getPublicationBasicRequest([publicationId], null, ({ data }) => {
      loaded();
      if (data.status === 0) {
        constructState(data.publication);
      } else if (data.status === -2) {
        dispatch(pageError(getErrorCode(data.status)));
      }
    });
  }, [getPublicationBasicRequest, publicationId, constructState, dispatch, loaded]);

  const saveCurrentAttempt = useCallback((attempt) => {
    if (attempt === null) {
      localStorage.removeItem(ATTEMPT);
    } else {
      localStorage.setItem(ATTEMPT, JSON.stringify({
        id: attempt.id,
        createdAt: attempt.createdAt,
        endsAt: attempt.endsAt,
        publicationId: publicationId,
        name: attempt.userName,
      }));
    }

    setCurrentAttempt(attempt);
  }, [publicationId]);

  const getCurrentAttempt = useCallback(() => {
    const currentAttemptString = localStorage.getItem(ATTEMPT);

    if (!currentAttemptString) {
      return null;
    }

    const currentAttempt = JSON.parse(currentAttemptString);

    return {
      ...currentAttempt,
      createdAt: new Date(currentAttempt.createdAt),
      endsAt: new Date(currentAttempt.endsAt),
    };
  }, []);

  useEffect(() => {
    if (pageLoading) {
      fetchPublicationBasic();
    }
  }, [pageLoading, fetchPublicationBasic]);

  useEffect(() => {
    if (publication) {
      setStage(getInitialState());
      loaded();
    }
  }, [publication, loaded, getInitialState]);

  useEffect(() => {
    const currentAttempt = getCurrentAttempt();
    if (currentAttempt === null) {
      return;
    }

    // If the current attempt is not from the same publication, we discard it
    if (currentAttempt.publicationId !== publicationId) {
      saveCurrentAttempt(null);
      return;
    }

    setCurrentAttempt(currentAttempt);
  }, [publicationId, getCurrentAttempt, saveCurrentAttempt]);

  const canEnterTest = useMemo(() => {
    if (!publication) {
      return false;
    }

    // If the user has already started an attempt, he can retake it even if it is outside the attempt window
    if (currentAttempt !== null) {
      return currentAttempt.publicationId === publicationId && stage !== FINISHED;
    }

    if (publication.testType === 'test') {
      return stage === IS_INSIDE;
    }

    if (publication.testType === 'worksheet') {
      return stage === IS_ONGOING;
    }
  }, [publication, stage, currentAttempt, publicationId]);

  return (
    <div className={cx(classes.pageContainer, { iave: iaveToggle })}>
      {!iaveToggle && (
        <div className={classes.langSelectorContainer}>
          <LangSelector />
        </div>
      )}
      <div className={cx(classes.logoContainer, { iave: iaveToggle })}>
        {iaveToggle
          ? (
            <div>
              <LogoIAVEWhite className={classes.iaveLogoWhite} />
            </div>
          )
          : <Logo large dark />
        }
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.formContainer}>
          <div className={classes.publicationContainer}>
            <PublicationHeader
              publication={publication}
              canEnterTest={canEnterTest}
              currentAttempt={currentAttempt}
              stage={stage}
              setStage={setStage}
            />
            {canEnterTest && !!publication?.password && !isPasswordConfirmed && (
              <ConfirmPassword
                publication={publication}
                setIsPasswordConfirmed={setIsPasswordConfirmed}
                password={password}
                setPassword={setPassword}
              />
            )}
            {canEnterTest &&
              (!publication?.password || isPasswordConfirmed) &&
              (!currentAttempt || currentAttempt.publicationId !== publicationId) && (
              <PublicationForm
                saveCurrentAttempt={saveCurrentAttempt}
                password={password}
              />
            )}
            {canEnterTest &&
              (!publication?.password || isPasswordConfirmed) &&
              currentAttempt && currentAttempt.publicationId === publicationId && (
              <ReenterAttempt
                currentAttempt={currentAttempt}
                saveCurrentAttempt={saveCurrentAttempt}
              />
            )}
          </div>
        </div>
        {iaveToggle && (
          <div className={classes.iaveLogosContainer}>
            <LogoIAVE className={classes.iaveLogo} />
            <LogoPRR className={classes.prrLogo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Publication;
