import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  exerciseContent: {
    padding: '20px',
  },
  icon: {
    marginRight: '10px',
    fontSize: '20px',
  },
  actionsWrapper: {
    display: 'flex',
    padding: '2px',
    borderLeft: `1px solid ${theme.separationColor}`,
    '&.hidingType': {
      borderLeft: 'unset',
    },
  },
  deleteExercise: {
    color: theme.deleteTestExercise,
  },
  editActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0px 35px 20px',
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    marginLeft: '10px',
    fontSize: '12px',
  },
  compactedWrapper: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    justifyContent: 'flex-end',
  },
  compactedText: {
    marginRight: '10px',
  },
  premiumPill: {
    marginLeft: '10px',
  },
  warningWrapper: {
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    actionsWrapper: {
      border: 'none',
    },
  },
}));
