import React, { useCallback, useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import { incrementTotalGroups, selectUserEmail, selectUserFeatureToggleMaxUsages, selectUserTotalFreeGroups } from 'actions/userActions';
import api from 'api';
import { FREE } from 'constants/plans';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useInput from 'hooks/common/useInput';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';
import PlansPill from 'components/common/plans/PlansPill';
import UpgradePremiumModal from 'components/common/plans/UpgradePremiumModal';

import useStyles from './styles';

const CreateGroupModal = ({ open, setCreateGroupModal, reload }) => {
  const classes = useStyles();
  const { spaceId } = useParams();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [createGroupRequest] = useApi(api.createGroup);
  const [getTeachersRequest] = useApi(api.getTeachers);
  const totalFreeGroups = useSelector(selectUserTotalFreeGroups);
  const createGroupToggle = useFeature(toggles.createGroup, totalFreeGroups);
  const createGroupToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createGroup));
  const dispatch = useDispatch();
  const userEmail = useSelector(selectUserEmail);

  const [groupName, setGroupName, groupNameErrors, setGroupNameErrors] = useInput('');
  const [selectedTeachers, setSelectedTeachers] = useInput([]);

  const close = useCallback(() => {
    setCreateGroupModal(false);
    setGroupName('');
    setSelectedTeachers([]);
  }, [setCreateGroupModal, setGroupName, setSelectedTeachers]);

  const validateNewOption = (inputValue, selectOptions, options) => {
    return isEmail(inputValue) && selectOptions.length === 0 && options.length === 0 && inputValue !== userEmail;
  };

  const save = useCallback(() => {
    if (loading) {
      return;
    }

    setLoading(true);
    let errors = false;
    const _groupNameErrors = [];

    const trimmedGroupName = groupName.trim();
    if (trimmedGroupName.length === 0) {
      errors = true;
      _groupNameErrors.push(lang.groups.errorNameRequired);
    }

    if (errors === true) {
      setTimeout(() => {
        setGroupNameErrors(_groupNameErrors);
        setLoading(false);
      }, 200);
      return;
    }

    setGroupNameErrors([]);

    const teachers = selectedTeachers.map(selectedTeacher => selectedTeacher.value);
    createGroupRequest([], { teachers, groupName: trimmedGroupName, schoolId: spaceId === 'p' ? undefined : spaceId }, ({ data }) => {
      if (data.status === 0) {
        close();
        setTimeout(() => {
          setLoading(false);
          if (spaceId === PERSONAL_SPACE) {
            dispatch(incrementTotalGroups());
          }
          toast.success(lang.groups.successCreateGroup);
          reload();
        }, 250);
      } else {
        setLoading(false);
        toast.error(lang.oops);
      }
    });
  }, [close, createGroupRequest, dispatch, groupName, loading, reload, selectedTeachers, setGroupNameErrors, spaceId, toast]);

  const loadOptions = (inputValue, callback) => {
    if (inputValue.trim().length < 3) {
      return;
    }

    getTeachersRequest([], { schoolId: spaceId === 'p' ? undefined : spaceId, teacherIdentifier: inputValue.trim() }, ({ data }) => {
      if (data.status === 0) {
        const teachers = data.teachers.map(teacher => {
          const isFree = (!teacher.plan || teacher.plan.name === FREE);
          const isDisabled = teacher.groupsCount && teacher.maxGroups && teacher.groupsCount >= teacher.maxGroups && spaceId === PERSONAL_SPACE && isFree;
          const splitFullName = teacher.fullName.split(' ');
          const hasMoreThanOneName = splitFullName.length > 1;
          const firstAndLastName = splitFullName[0] + ' ' + splitFullName[splitFullName.length - 1];
          const teacherName = hasMoreThanOneName ? firstAndLastName : teacher.fullName;

          return ({
            value: teacher.id,
            label: (
              <div className={classes.optionWrapper}>
                <Render when={spaceId === PERSONAL_SPACE}>
                  <PlansPill
                    message={isFree ? lang.plans.free : lang.plans.premium}
                    isFree={isFree}
                    tip={ isDisabled ? lang.groups.userNotAllowed : null }
                  />
                </Render>
                <div>
                  {teacherName}
                </div>
              </div>
            ),
            isDisabled: isDisabled,
          });
        });

        callback(teachers);
      }
    });
  };

  const actions = [
    {
      name: lang.cancel,
      onClick: close,
      color: 'black',
    },
    {
      name: lang.confirm,
      onClick: save,
      loading: loading,
      dataTour: 'create-group-modal-confirm-button',
    },
  ];

  if (!createGroupToggle) {
    return (
      <UpgradePremiumModal
        open={open}
        close={close}
        message={lang.plans.exceededGroups(createGroupToggleMaxUsages)}
      />
    );
  }

  return (
    <Modal
      header={lang.groups.createNewGroup}
      open={open}
      close={close}
      center
      transition
      actions={actions}
    >
      <Input
        dataTour="create-group-modal-name-input"
        type="text"
        value={groupName}
        label={lang.groups.groupName}
        placeholder={lang.groups.groupName}
        onChange={(event) => setGroupName(event.target.value)}
        maxSize={100}
        errors={groupNameErrors}
      />
      <Spacer px={15} />
      <Input
        dataTour="create-group-modal-teachers-input"
        type="select"
        label={lang.exercises.teacherFilter}
        placeholder={lang.exercises.teacherFilter}
        hint={lang.groups.hintTargets}
        multiple
        isAsync
        creatable={spaceId === 'p'}
        loadOptions={loadOptions}
        value={selectedTeachers}
        onChange={setSelectedTeachers}
        validateNewOption={validateNewOption}
      />
    </Modal>
  );
};

CreateGroupModal.propTypes = {
  open: PropTypes.bool,
  setCreateGroupModal: PropTypes.func,
  reload: PropTypes.func,
};

export default CreateGroupModal;
