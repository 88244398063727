import { createUseStyles } from 'react-jss';

export default createUseStyles({
  noResultsContainer: {
    textAlign: 'center',
    padding: '76px 0 10px 0',
  },
  noResultsHeader: {
    fontWeight: 600,
    fontSize: '24px',
  },
  noResultsIconTimes: {
    marginTop: '36px',
    width: '25px',
    height: '25px',
  },
  noResultsIconInbox: {
    width: '105px',
    height: '105px',
    marginBottom: '36px',
  },
  noResultsDescription: {
    fontSize: '18px',
  },
  noResultsDescriptionBottom: {
    marginBottom: '36px',
  },
});
