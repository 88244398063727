import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import { useAttemptContext } from '../CorrectionTab/context';
import Modal from 'components/common/Modal';

const DeleteAnnotationCommentModal = ({ open, close, textAnnotationId, setAnnotations, setAnnotate }) => {
  const { setCommenting } = useAttemptContext();

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
    {
      name: lang.delete,
      color: 'red',
      onClick: () => {
        setAnnotations((prevAnnotations) => prevAnnotations.filter((annotation) => annotation.id !== textAnnotationId));
        setCommenting(false);
        setAnnotate(true);
        close();
      },
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.test.grades.deleteAnnotationHeader}
      actions={actions}
      center
      transition
      small
    >
      <div>
        {lang.test.grades.deleteAnnotationCommentDescription}
      </div>
    </Modal>
  );
};

DeleteAnnotationCommentModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  textAnnotationId: PropTypes.string,
  setAnnotations: PropTypes.func,
  setAnnotate: PropTypes.func,
};

export default DeleteAnnotationCommentModal;
