import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const CancelModal = ({ open, close, cancel }) => {
  const classes = useStyles();

  const actions = [
    {
      name: lang.cancel,
      onClick: close,
      color: 'black',
    },
    {
      name: lang.discard,
      onClick: cancel,
      color: 'red',
    },
  ];

  return (
    <Modal
      header={lang.rubrics.form.discardRubric}
      open={open}
      close={close}
      center
      transition
      actions={actions}
    >
      <div className={classes.discardDescription}>
        {lang.discardWarning}
      </div>
    </Modal>
  );
};

CancelModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  cancel: PropTypes.func,
};

export default CancelModal;
