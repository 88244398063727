const CHOOSING = 0;
const ADD_EXISTING_RUBRIC = 1;
const CREATE_RUBRIC = 2;
const DISPLAY_RUBRIC = 3;
const EDIT_RUBRIC = 4;

export {
  CHOOSING,
  ADD_EXISTING_RUBRIC,
  CREATE_RUBRIC,
  DISPLAY_RUBRIC,
  EDIT_RUBRIC,
};
