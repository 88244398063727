import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import DraggableGap from '../exercise-answer-filling/DraggableGap';
import InnerGap from '../exercise-answer-filling/InnerGap';

import useStyles from './styles';

const ExerciseAnswerFilling = ({ gaps, answerable, dropAnswers, correction, option }) => {
  const classes = useStyles();

  if (!dropAnswers && answerable) {
    return null;
  }

  return (
    <Render when={!option || option === null || option === 'dragDrop'}>
      <div className={classes.wrapper}>
        <div>
          {correction ? lang.exercises.gapListUnused : lang.exercises.gapList}
        </div>
        <div className={classes.gapList}>
          <Render when={answerable}>
            {gaps
              .filter(el => !dropAnswers.some(elem => elem.gapId === el.id))
              .map((gap) => (
                <DraggableGap
                  key={gap.id}
                  item={gap}
                  draggable={answerable}
                />
              ))
            }
          </Render>
          <Render when={!answerable}>
            {gaps
              .filter(el => !dropAnswers.some(elem => elem.gapId === el.id))
              .map((gap) => (
                <InnerGap
                  key={gap.id}
                  item={correction ? gap : { ...gap, isCorrect: null }}
                  draggable={answerable}
                  identifier={gap.identifier}
                />
              ))}
          </Render>
        </div>
      </div >
    </Render>
  );
};

ExerciseAnswerFilling.propTypes = {
  gaps: PropTypes.array,
  answerable: PropTypes.bool,
  dropAnswers: PropTypes.array,
  correction: PropTypes.bool,
  option: PropTypes.string,
};

export default ExerciseAnswerFilling;
