import { createUseStyles } from 'react-jss';

export default createUseStyles({
  itemsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'unset !important',
    flexWrap: 'wrap',
    gap: '0.25em',
  },
});
