import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  exerciseSpinner: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    right: '0',
    gap: '5px',
    position: 'absolute',
  },
  exerciseWrapper: {
    position: 'relative',
    '&.answerable': {
      userSelect: 'none',
    },
    '&.isFlagged': {
      border: '2px solid #fe7c03',
    },
  },
}));
