import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  statsInfoIcon: {
    marginLeft: '5px',
  },
  statNumberTitle: {
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'left',
    marginBottom: '15px',
    marginTop: '5px',
    color: theme.textColor3,
  },
  statNumber: {
    marginTop: '5px',
    marginBottom: '25px',
    fontSize: '19px',
    display: 'flex',
    justifyContent: 'left',
    color: theme.successColor,
    '&.failed': {
      color: theme.errorColor,
    },
  },
  durationTitle: {
    fontSize: '15px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    color: theme.textColor3,
    marginBottom: '15px',
    marginTop: '5px',
  },
  duration: {
    marginTop: '5px',
    marginBottom: '25px',
    fontSize: '19px',
  },
  durationCol: {
    minWidth: '150px',
  },
}));
