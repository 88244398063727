import React, { useState } from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';

import lang from 'lang';

import CountdownRenderer from 'components/common/countdown/CountdownRenderer';

import useStyles from './styles';

const CountdownStages = ({ info }) => {
  const classes = useStyles();
  const clockOffset = useSelector(state => state.page.clockOffset);

  const startsAt = (new Date(info.startsAt)).getTime();

  let endsAt, windowClosesAt;
  if (info.type === 'test') {
    windowClosesAt = startsAt + (info.attemptWindow * 60 * 1000);
    endsAt = windowClosesAt + (info.duration * 60 * 1000);
  } else if (info.type === 'worksheet') {
    endsAt = ((new Date(info.endsAt)).getTime());
  }

  const UPCOMING = 0;
  const IS_INSIDE = 1;
  const IS_ONGOING = 2;
  const FINISHED = 3;

  const getInitialState = () => {
    const now = new Date().getTime() - clockOffset;

    const isUpcoming = now < startsAt;

    let isInsideWindow, isOngoing;
    if (info.type === 'test') {
      isInsideWindow = now > startsAt && now < windowClosesAt && !info.attemptEndedAt;
      isOngoing = now > windowClosesAt && now < endsAt && !info.attemptEndedAt;
    } else if (info.type === 'worksheet') {
      isInsideWindow = now > startsAt && now < endsAt && !info.attemptEndedAt;
    }

    if (isUpcoming) {
      return UPCOMING;
    } else if (isInsideWindow) {
      return IS_INSIDE;
    } else if (isOngoing) {
      return IS_ONGOING;
    } else {
      return FINISHED;
    }
  };

  const [stage, setStage] = useState(getInitialState());

  return (
    <div className={classes.timerContainer}>
      <Render when={stage === UPCOMING}>
        {lang.tests.student.opensIn}
        <Countdown
          date={startsAt + clockOffset}
          renderer={CountdownRenderer}
          onComplete={() => setStage(IS_INSIDE)}
        />
      </Render>
      <Render when={stage === IS_INSIDE}>
        {lang.tests.student.openingClosesAt}
        <Countdown
          date={(info.type === 'test' ? windowClosesAt : endsAt) + clockOffset}
          renderer={CountdownRenderer}
          onComplete={() => setStage(IS_ONGOING)}
        />
      </Render>
      <Render when={stage === IS_ONGOING}>
        {lang.tests.student.testEndsAt}
        <Countdown
          date={endsAt + clockOffset}
          renderer={CountdownRenderer}
          onComplete={() => setStage(FINISHED)}
        />
      </Render>
      <Render when={stage === FINISHED}>
        {lang.finished}
      </Render>
    </div>
  );
};

CountdownStages.propTypes = {
  info: PropTypes.object,
};

export default CountdownStages;
