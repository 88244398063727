import React, { Fragment, useEffect, useState } from 'react';
import { faCheck, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserLang } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { localToServerMillis, serverToLocalMillis, secondsToMillis, getEpochDatetimeString, getEpochFromString } from 'utils/datetime';

import Countdown from 'components/common/countdown/Countdown';
import CountdownRenderer from 'components/common/countdown/CountdownRenderer';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const TestInfo = ({ open, close, testType, publication, setEdit, setIsOnGoing, action, loading }) => {
  const classes = useStyles();
  const toast = useToast();
  const locale = useSelector(selectUserLang);
  const clockOffset = useSelector(state => state.page.clockOffset);
  const [deletePublicationRequest] = useApi(api.deletePublication);
  const [createPublicationAttemptsRequest] = useApi(api.createPublicationAttempts);
  const createAttemptsToggle = useFeature(toggles.createAttempts);

  const [unpublishLoading, setUnpublishLoading] = useState(false);
  const [createAttemptsLoading, setCreateAttemptsLoading] = useState(false);
  const [state, setState] = useState(null);
  const [clipboarded, setClipboarded] = useState(false);

  const SCHEDULED = 0;
  const ONGOING = 1;
  const FINISHED = 2;
  const startsAtMillis = (new Date(publication?.startsAt)).getTime();

  const endsAtMillis = testType === 'test'
    ? startsAtMillis + secondsToMillis(publication?.attemptWindow + publication?.duration)
    : getEpochFromString(publication?.endsAt);

  const startsAtHumanString = getEpochDatetimeString(startsAtMillis, locale, true);
  const endsAtHumanString = getEpochDatetimeString(endsAtMillis, locale, true);

  useEffect(() => {
    const now = localToServerMillis(clockOffset);
    const isScheduled = startsAtMillis > now;
    const isOngoing = endsAtMillis > now;

    if (isScheduled) {
      setState(SCHEDULED);
      setIsOnGoing(false);
    } else if (isOngoing) {
      setState(ONGOING);
      setIsOnGoing(true);
    } else {
      setState(FINISHED);
      setIsOnGoing(false);
    }
  }, [startsAtMillis, setIsOnGoing, endsAtMillis, clockOffset]);

  const getHeader = () => {
    if (state === SCHEDULED) {
      return lang.test.stateScheduled;
    } else if (state === ONGOING) {
      return lang.test.stateOngoing;
    } else {
      return lang.test.stateFinished;
    }
  };

  const handleUnpublish = () => {
    setUnpublishLoading(true);

    deletePublicationRequest([publication?.id], null, ({ data }) => {
      setUnpublishLoading(false);
      if (data.status === 0) {
        toast.success(lang.test.publishModal.successUnpublishTest);
        close();
        setTimeout(() => {
          action();
        }, 350);
      } else {
        toast.error(lang.oops);
      }
    });
  };

  const handleCreateAttempts = () => {
    setCreateAttemptsLoading(true);
    createPublicationAttemptsRequest([publication?.id], null, ({ data }) => {
      setCreateAttemptsLoading(false);
      if (data.status === 0) {
        toast.success(lang.test.publishModal.successCreateAttempts);
        setTimeout(() => {
          close();
        }, 350);
      } else {
        toast.error(lang.oops);
      }
    });
  };

  const _onClick = () => {
    setIsOnGoing(true);
    setEdit(true);
  };

  const afterClipboard = (link) => {
    navigator.clipboard.writeText(link);
    setClipboarded(true);
    setTimeout(() => {
      setClipboarded(false);
    }, 1000);
  };

  const getSmallInfo = (what, info, isTime) => {
    return (
      <small>
        <span className={classes.bold}>
          {what}
          {': '}
        </span>
        {info}
        <Render when={isTime}>
          {' '}
          {info === 1 ? lang.time.minute : lang.time.minutes}
        </Render>
      </small>
    );
  };

  const getLink = (what, link) => {
    return (
      <div data-tour="assessment-publish-link" className={classes.link}>
        <small>
          <span className={classes.bold}>
            {what}
            {': '}
          </span>
          {link}
        </small>
        <Tooltip tip={clipboarded ? lang.test.copied : lang.copy} bottom>
          <FontAwesomeIcon
            icon={clipboarded ? faCheck : faClipboard}
            className={cx(classes.clipboard, { selected: clipboarded })}
            onClick={() => afterClipboard(link)}
          />
        </Tooltip>
      </div>
    );
  };

  const getCountdownLabel = () => {
    if (state === SCHEDULED) {
      return (
        <span className={classes.bold}>
          {lang.test.publishModal.opensIn}
        </span>
      );
    } else if (state === ONGOING) {
      return (
        <span className={classes.bold}>
          {lang.test.publishModal.endsIn}
        </span>
      );
    }
  };

  const onCountdownComplete = (newState) => {
    setState(newState);
    action();
  };

  const getCountdown = () => {
    if (state === FINISHED) {
      return;
    }

    return (
      <Fragment>
        <div>
          {getCountdownLabel()}
        </div>
        <Spacer px={10} />
        <div className={classes.countdownWrap}>
          <Render when={state === SCHEDULED}>
            <Countdown
              date={serverToLocalMillis(startsAtMillis, clockOffset)}
              renderer={CountdownRenderer}
              onComplete={() => onCountdownComplete(ONGOING)}
            />
          </Render>
          <Render when={state === ONGOING}>
            <Countdown
              date={serverToLocalMillis(endsAtMillis, clockOffset)}
              renderer={CountdownRenderer}
              onComplete={() => onCountdownComplete(FINISHED)}
            />
          </Render>
        </div>
        <Spacer px={20} />
      </Fragment>
    );
  };

  const getStartsAtLabel = () => {
    if (state === SCHEDULED) {
      return (
        <span className={classes.bold}>
          {lang.test.publishModal.scheduledFor}
        </span>
      );
    } else {
      return (
        <span className={classes.bold}>
          {lang.test.publishModal.openedOn}
        </span>
      );
    }
  };

  const getEndsAtLabel = () => {
    if (state === FINISHED) {
      return (
        <span className={classes.bold}>
          {lang.test.publishModal.endedOn}
        </span>
      );
    } else {
      return (
        <span className={classes.bold}>
          {lang.test.publishModal.endsOn}
        </span>
      );
    }
  };

  const actions = [
    ...(createAttemptsToggle && state !== FINISHED ? [
      {
        name: lang.test.publishModal.createAttempts,
        onClick: () => handleCreateAttempts(),
        loading: createAttemptsLoading,
      },
    ] : []),
    ...(state === SCHEDULED ? [
      {
        name: lang.test.publishModal.rescheduleTest,
        onClick: () => setEdit(true),
      },
      {
        name: lang.test.publishModal.unpublishTest,
        loading: unpublishLoading,
        color: 'red',
        onClick: () => handleUnpublish(),
      },
    ] : []),
    ...(state === ONGOING ? [
      {
        name: lang.test.publishModal.editPublication,
        onClick: () => _onClick(),
      },
    ] : []),
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={getHeader()}
      actions={actions}
      center
      transition
      medium
    >
      <Loading active={loading} />
      {!loading && (
        <div>
          {getCountdown()}
          <small>
            {getStartsAtLabel()}
            {' '}
            <time dateTime={startsAtMillis}>
              {startsAtHumanString}
            </time>
          </small>
          <Render when={testType === 'worksheet'}>
            <small>
              <Spacer />
              {getEndsAtLabel()}
              {' '}
              <time dateTime={endsAtMillis}>
                {endsAtHumanString}
              </time>
            </small>
          </Render>
          <Render when={testType === 'test'}>
            <Spacer />
            {getSmallInfo(lang.test.attemptWindow, publication?.attemptWindow, true)}
            <Spacer />
            {getSmallInfo(lang.test.duration, publication?.duration, true)}
          </Render>
          <Render when={publication?.password}>
            <Spacer />
            {getSmallInfo(lang.test.password, publication?.password)}
          </Render>
          <Spacer />
          {getLink(lang.test.link, `${routes.testsApp.ref()}${routes.publication.ref(publication?.id)}`)}
        </div>
      )}
    </Modal>
  );
};

TestInfo.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  testType: PropTypes.string,
  publication: PropTypes.object,
  setEdit: PropTypes.func,
  setIsOnGoing: PropTypes.func,
  action: PropTypes.func,
  loading: PropTypes.bool,
};

export default TestInfo;
