import React, { useCallback, useMemo } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, ConfigProvider } from 'antd';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'actions/userActions';
import { BY_ROW, BY_TABLE } from 'constants/gradingOptions';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import AnswerHeaderCell from '../AnswerHeaderCell';
import AnswerTableCell from '../AnswerTableCell';
import QuillRenderer from 'components/common/QuillRenderer';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const ExerciseAnswerTable = ({ exerciseCells, answer, onAnswer, answerable, gradingOption, correction }) => {
  const classes = useStyles();
  const exportIdentifiersToggle = useFeature(toggles.exportIdentifiers);
  const isAdmin = useSelector(selectUserIsAdmin);

  const correctRows = useMemo(() => {
    if (correction && !answerable && gradingOption === BY_ROW) {
      const cellsByRow = exerciseCells.reduce((acc, cell) => {
        if (cell.row !== 0 && cell.col !== 0) {
          acc[cell.row] = acc[cell.row] || [];
          acc[cell.row].push(cell);
        }
        return acc;
      }, {});

      return Object.entries(cellsByRow)
        .filter(([, rowCells]) => {
          const answerCellsInRow = rowCells.filter(cell => answer?.includes(cell.exerciseChoiceId));
          return answerCellsInRow.every(cell => cell.isCorrect) && answerCellsInRow.length === rowCells.filter(cell => cell.isCorrect).length;
        })
        .map(([rowNumber]) => Number(rowNumber));
    }
    return [];
  }, [answer, answerable, correction, exerciseCells, gradingOption]);

  const correctTable = useMemo(() => {
    if (correction && !answerable && gradingOption === BY_TABLE) {
      const correctCells = exerciseCells.filter(cell => cell.isCorrect === true && cell.row !== 0 && cell.col !== 0).map(cell => cell.exerciseChoiceId);
      if (correctCells.length === 0 && !answer){
        return true;
      } else {
        return answer?.every(choiceId => correctCells.includes(choiceId)) && answer?.length === correctCells.length;
      }
    }
    return false;
  }, [answer, answerable, correction, exerciseCells, gradingOption]);

  const setCell = useCallback((col, rowIndex) => {
    if (!onAnswer) {
      return;
    }
    const cell = exerciseCells.find(cell => cell.col === col && cell.row === rowIndex + 1 && cell.exerciseChoiceId !== null);

    exerciseCells.forEach(cell => {
      if (cell.row === rowIndex + 1 && cell.col === col){
        cell.isCorrect = !cell.isCorrect;
      }
    });

    const choiceChecked = answer?.find(checkedCellId => checkedCellId === cell.exerciseChoiceId);
    let selected = [];
    if (choiceChecked) {
      selected = answer.filter(checkedCellId => checkedCellId !== cell.exerciseChoiceId);
    } else {
      selected = [...(answer ?? []), cell.exerciseChoiceId];
    }
    onAnswer(selected);

  }, [answer, exerciseCells, onAnswer]);

  const generateHeaderCells = useCallback((exerciseCells, gradingOption) => {
    return exerciseCells
      ?.filter(el => el.row === 0)
      ?.map(el => ({
        ...el,
        dataIndex: el.col,
        key: el.id,
        col: el.col,
        onHeaderCell: () => {
          return {
            col: el.col,
            row: 0,
            value: <QuillRenderer value={el.value} />,
            style: {
              borderRight: gradingOption === BY_ROW ? 'none' : null,
              backgroundColor: el.col === 0 && '#FAFAFA',
              fontWeight: el.col === 0 && '600',
            },
          };
        },
        onCell: (_, rowIndex) => {
          const exerciseCell = exerciseCells?.find(cell => cell.row === rowIndex + 1 && cell.col === el.col);
          return {
            col: el.col,
            row: rowIndex + 1,
            isCell: true,
            correctRows: !isAdmin ? correctRows : null,
            correctTable: !isAdmin ? correctTable : null,
            identifier: exerciseCell.identifier,
            exerciseChoiceId: exerciseCell.exerciseChoiceId,
            isCorrect: !isAdmin && exerciseCell.isCorrect,
            style: {
              borderRight: gradingOption === BY_ROW ? 'none' : null,
              backgroundColor: el.col === 0 && '#FAFAFA',
              fontWeight: el.col === 0 && '600',
            },
            answer: answer?.includes(exerciseCell.exerciseChoiceId),
            onClick: () => el.col > 0 && setCell(el.col, rowIndex),
            answerable,
            correction,
            exportIdentifiersToggle,
          };
        },
      }),
      ) ?? [];
  },
  [answer, answerable, correctRows, correctTable, correction, exportIdentifiersToggle, setCell, isAdmin]);

  const computeRowCells = useCallback((exerciseCells) => {
    return exerciseCells
      ?.filter(el => el.col === 0 && el.row !== 0)
      ?.map(el => {
        const rowCellMap = exerciseCells
          .filter(cell => cell.row === el.row && cell.col !== 0 && cell.exerciseChoiceId !== null)
          .reduce((acc, cell) => ({ ...acc, key: cell.id, [cell.col]: cell }), {});

        return {
          ...el,
          ...rowCellMap,
          [el.col]: <QuillRenderer value={el.value} />,
        };
      })
        ?? [];
  }, []);

  const computedHeaderCells = useMemo(() => generateHeaderCells(exerciseCells, gradingOption), [gradingOption, exerciseCells, generateHeaderCells]);
  const computedRowCells = useMemo(() => computeRowCells(exerciseCells), [computeRowCells, exerciseCells]);

  const resultsCol = {
    onHeaderCell: () => {
      return {
        style: {
          display: 'none',
          border: 'none',
        },
      };
    },
    onCell: (_, rowIndex) => ({
      ...gradingOption === BY_TABLE && { rowSpan: rowIndex !== 0 ? 0 : 99999 },
      style: {
        width: '3rem',
        padding: '10px',
      },
      correctAnswers: !isAdmin && true,
    }),
  };

  const correctionCol = {
    onHeaderCell: () => {
      return {
        value:
  <Tooltip
    tip={gradingOption === BY_TABLE ? lang.exerciseForm.table.tableGradingExplanation : lang.exerciseForm.table.rowGradingExplanation}
    place="top"
  >
    <FontAwesomeIcon icon={faInfoCircle} />
  </Tooltip>,
        style: {
          border: 'none',
          backgroundColor: 'transparent',
          width: '3rem',
        },
      };
    },
    onCell: (_, rowIndex) => ({
      ...gradingOption === BY_TABLE && { rowSpan: rowIndex !== 0 ? 0 : 99999 },
      style: {
        maxWidth: '15px',
        padding: '10px',
        backgroundColor: correctTable || correctRows?.includes(rowIndex + 1) ? '#7DD88966' : '#CE1F0166',
      },
      correction: false,
      tableGrading: true,
      gradingOption,
      rowIndex,
      correctRows,
      correctTable,
    }),
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: 'unset',
        },
        components: {
          Table: {
            headerBg: '#FAFAFA',
            rowHoverBg: answerable ? '#F5F5F5' : '#7DD88966',
          },
        },
      }}
    >
      <Table
        components={{
          header: {
            cell: AnswerHeaderCell,
          },
          body: {
            cell: AnswerTableCell,
          },
        }}
        className={cx(classes.table, { byTable: gradingOption === BY_TABLE })}
        bordered
        rowClassName={cx(classes.row, { answerable, incorrectTable: !correctTable && correction, isAdmin })}
        dataSource={computedRowCells}
        columns={
          answerable ?
            computedHeaderCells
            :
            [
              ...computedHeaderCells,
              (!isAdmin && correction && !answerable) ? correctionCol : resultsCol,
            ]
        }
        pagination={false}
      />
    </ConfigProvider>
  );
};

ExerciseAnswerTable.propTypes = {
  exerciseCells: PropTypes.array,
  answerable: PropTypes.bool,
  gradingOption: PropTypes.string,
  answer: PropTypes.arrayOf(PropTypes.string),
  onAnswer: PropTypes.func,
  correction: PropTypes.bool,
};

export default ExerciseAnswerTable;
