import React, { Fragment, useState } from 'react';
import { closestCorners, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { selectTest, setTestItems } from 'actions/testActions';
import api from 'api';
import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import { deepCopy } from 'utils';

import SortableItem from 'components/common/SortableItem';
import AddContent from 'components/test/exercises/AddContent';
import EditableExercise from 'components/test/exercises/EditableExercise';
import EditableTestSection from 'components/test/exercises/EditableTestSection';
import TestExerciseDrop from 'components/test/exercises/TestExerciseDrop';

import useStyles from './styles';

const ExercisesTab = ({ fetchTest }) => {
  const classes = useStyles();
  const test = useSelector(selectTest);
  const dispatch = useDispatch();
  const toast = useToast();
  const [reorderTestItemsRequest] = useApi(api.reorderTestItems);

  const [activeItem, setActiveItem] = useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 5 } }),
  );

  const saveTestItemsOrder = (newItems) => {
    dispatch(setTestItems(newItems));

    const items = newItems
      .map(item => ({
        id: item.id,
        order: item.order,
        type: item.itemType,
        exercises: item.exercises ? item.exercises.map(exercise => ({
          id: exercise.id,
          order: exercise.order,
          type: exercise.itemType,
        })) : undefined,
      }));

    reorderTestItemsRequest([test.id], { items }, ({ data }) => {
      if (data.status !== 0) {
        toast.error(lang.oops);
      }
    });
  };

  const moveItem = (sectionId, order, newOrder) => {
    let newItems = deepCopy(test.items);

    if (sectionId) {
      const section = newItems.find(item => item.id === sectionId);
      section.exercises = arrayMove(section.exercises, order, newOrder)
        .map((item, index) => ({ ...item, order: index }));
    } else {
      newItems = arrayMove(newItems, order, newOrder)
        .map((item, index) => ({ ...item, order: index }));
    }

    saveTestItemsOrder(newItems);
  };

  const findContainerId = (items, id) => {
    if (id.includes('drop')) {
      const containerId = id.split('-drop')[0];
      return containerId === 'null' ? null : containerId;
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.id === id) {
        return null;
      }

      if (item.itemType === 'section' && item.exercises) {
        for (let j = 0; j < item.exercises.length; j++) {
          const exercise = item.exercises[j];

          if (exercise.id === id) {
            return item.id;
          }
        }
      }
    }
  };

  const findItem = (items, id) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.id === id) {
        return item;
      }

      if (item.itemType === 'section' && item.exercises) {
        for (let j = 0; j < item.exercises.length; j++) {
          const exercise = item.exercises[j];

          if (exercise.id === id) {
            return exercise;
          }
        }
      }
    }
  };

  const onDragStart = (event) => {
    const { active } = event;

    setActiveItem(findItem(test.items, active.id));
  };

  const onDragOver = (event) => {
    const { active, over } = event;

    const overId = over?.id;

    let newItems = test.items;

    if (!overId) {
      return;
    }

    const activeContainerId = findContainerId(newItems, active.id);
    const overContainerId = findContainerId(newItems, over.id);
    const activeItem = findItem(newItems, active.id);
    if (activeContainerId !== overContainerId && activeItem.itemType !== 'section') {
      if (activeContainerId !== null) {
        const activeContainer = newItems.find(item => item.id === activeContainerId);

        activeContainer.exercises = activeContainer.exercises
          .filter(exercise => exercise.id !== active.id)
          .map((item, index) => ({ ...item, order: index }));
      } else {
        let activeContainer = newItems;

        activeContainer = activeContainer
          .filter(item => item.id !== active.id)
          .map((item, index) => ({ ...item, order: index }));

        newItems = activeContainer;
      }

      if (overContainerId !== null) {
        const overContainer = newItems.find(item => item.id === overContainerId);

        if (!overContainer.exercises) {
          overContainer.exercises = [];
        }

        const newSectionExercise = {
          ...activeItem,
          testSectionId: overContainerId,
        };

        const containerOrder = test.items.find(item => item.id === overContainerId).order;
        if (activeItem.order < containerOrder) {
          overContainer.exercises.unshift(newSectionExercise);
        } else {
          overContainer.exercises.push(newSectionExercise);
        }

        overContainer.exercises = overContainer.exercises
          .map((item, index) => ({ ...item, order: index }));
      } else {
        let overContainer = newItems;

        overContainer.push({
          ...activeItem,
          testSectionId: overContainerId,
        });

        const order = overContainer.map(el => el.id).indexOf(active.id);
        let newOrder;
        if (overId.includes('drop')) {
          newOrder = parseInt(overId.split('drop-')[1]);
        } else {
          newOrder = overContainer.map(el => el.id).indexOf(over.id);
        }

        overContainer = arrayMove(overContainer, order, newOrder)
          .map((item, index) => ({ ...item, order: index }));

        newItems = overContainer;
      }

      setActiveItem({
        ...activeItem,
        testSectionId: overContainerId,
      });
      dispatch(setTestItems(newItems));
    }
  };

  const onDragEnd = (event) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      const activeContainerId = findContainerId(test.items, active.id);

      let order, newOrder;
      if (activeContainerId) {
        const container = findItem(test.items, activeContainerId);

        order = container.exercises.map(el => el.id).indexOf(active.id);
        newOrder = container.exercises.map(el => el.id).indexOf(over.id);
      } else {
        order = test.items.map(el => el.id).indexOf(active.id);
        newOrder = test.items.map(el => el.id).indexOf(over.id);
      }

      moveItem(activeItem.testSectionId, order, newOrder);
    } else {
      saveTestItemsOrder(test.items);
    }

    setActiveItem(null);
  };

  const getItems = () => {
    if (!test) {
      return null;
    }

    let exerciseNum = 0;
    const items = test.items.map((item, index) => {
      const actionable = !test.isPublished;

      if (item.itemType === 'section') {
        return (
          <Fragment key={item.id}>
            <Render when={activeItem && activeItem.testSectionId}>
              <TestExerciseDrop
                id={`null-drop-${index}`}
              />
            </Render>
            <SortableItem
              id={item.id}
              component={
                <EditableTestSection
                  actionable={actionable}
                  testId={test.id}
                  fetchTest={fetchTest}
                  saveTestItemsOrder={saveTestItemsOrder}
                  collapse={activeItem && (activeItem.itemType === 'section' || activeItem.type === PAUSE)}
                />
              }
            />
            <Render when={activeItem && activeItem.testSectionId && index === test.items.length - 1}>
              <TestExerciseDrop
                id={`null-drop-${index + 1}`}
              />
            </Render>
          </Fragment>
        );
      }

      if (item.itemType === 'exercise') {
        if (item.type !== INFORMATION && item.type !== PAUSE) {
          exerciseNum++;
        }

        return (
          <SortableItem
            key={item.id}
            id={item.id}
            component={
              <EditableExercise
                num={exerciseNum}
                testId={test.id}
                classificationType={test.classificationType}
                actionable={actionable}
                testExerciseId={item.id}
                refreshState={fetchTest}
                className={classes.editableExercise}
                saveTestItemsOrder={saveTestItemsOrder}
              />
            }
          />
        );
      }

      return null;
    });

    return (
      <DndContext
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragEnd={onDragEnd}
        modifiers={[restrictToVerticalAxis]}
        collisionDetection={closestCorners}
        sensors={sensors}
      >
        <SortableContext
          items={test.items}
          strategy={verticalListSortingStrategy}
        >
          {items}
        </SortableContext>
        <DragOverlay>
          {activeItem ?
            <Fragment>
              <Render when={activeItem.itemType === 'exercise'}>
                <EditableExercise
                  classificationType={test.classificationType}
                  id={activeItem.id}
                  testSectionId={activeItem.testSectionId}
                  refreshState={fetchTest}
                  className={classes.editableExercise}
                  isOverlay
                  actionable
                />
              </Render>
              <Render when={activeItem.itemType === 'section'}>
                <EditableTestSection
                  id={activeItem.id}
                  fetchTest={fetchTest}
                  isOverlay
                  actionable
                />
              </Render>
            </Fragment>
            :
            null
          }
        </DragOverlay>
      </DndContext>
    );
  };

  return (
    <Fragment>
      {getItems()}
      <Render when={!test.isPublished && !activeItem}>
        <AddContent
          refresh={fetchTest}
        />
      </Render>
    </Fragment>
  );
};

ExercisesTab.propTypes = {
  fetchTest: PropTypes.func.isRequired,
};

export default ExercisesTab;
