import React from 'react';
import { Col, Row } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';

import { calculateGradePercentage } from '../../../../../utils/statistics';
import ScoreNumber from '../ScoreNumber';

import useStyles from './styles';

export const ScoreCard = ({ stats, total }) => {
  const classes = useStyles();

  const getScore = (gradePercentage, gradeStat) => {
    return (
      <Row
        className={cx(classes.statNumber, { failed: !gradePercentage || gradePercentage < 50 })}
      >
        {gradePercentage + '%'}
        <span className={classes.gradeStat}>
          (
          {gradeStat}
          <span className={classes.gradeStatDivider}>
            /
          </span>
          {total}
          )
        </span>
      </Row>
    );
  };

  return (
    <>
      <Col
        xl={3}
        className={classes.statNumbers}
      >
        <ScoreNumber
          statClass={lang.test.stats.highest}
        >
          {getScore(calculateGradePercentage(stats.highestGrade, total), parseFloat(stats.highestGrade).toFixed(2))}
        </ScoreNumber>
        <ScoreNumber
          statClass={lang.test.stats.classAverage}
          statInfo={lang.test.stats.averageInfo}
        >
          {getScore(calculateGradePercentage(stats.averageGrade, total), parseFloat(stats.averageGrade).toFixed(2))}
        </ScoreNumber>
      </Col>
      <Col
        xl={3}
        className={classes.statNumbers}
      >
        <ScoreNumber
          statClass={lang.test.stats.lowest}
        >
          {getScore(calculateGradePercentage(stats.lowestGrade, total), parseFloat(stats.lowestGrade).toFixed(2))}
        </ScoreNumber>
        <ScoreNumber
          statClass={lang.test.stats.classMedian}
          statInfo={lang.test.stats.medianInfo}
        >
          {getScore(calculateGradePercentage(stats.medianGrade, total), parseFloat(stats.medianGrade).toFixed(2))}
        </ScoreNumber>

      </Col>
    </>
  );
};

ScoreCard.propTypes = {
  stats: PropTypes.exact({
    averageGrade: PropTypes.number,
    medianGrade: PropTypes.number,
    highestGrade: PropTypes.number,
    lowestGrade: PropTypes.number,
  }),
  total: PropTypes.string,
};

export default ScoreCard;
