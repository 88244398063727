import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  tabsContainer: {
    width: '100%',
    height: '30px',
    backgroundColor: theme.backgroundColor2,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  tabsWrap: {
    maxWidth: '1280px',
    width: '100%',
    height: '100%',
    marginLeft: '20px',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  tabHeader: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    marginRight: '20px',
    boxSizing: 'border-box',
    fontSize: '14px',
    cursor: 'pointer',
    color: theme.textColor5,
    transition: '100ms background-color',
    '&:hover': {
      borderBottom: `2px solid ${theme.accentColor3}`,
    },
    '&.active': {
      borderBottom: `2px solid ${theme.accentColor}`,
      color: theme.accentColor,
    },
  },
}));
