import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  exportResolutionsButton: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '-30px',
  },
  iconLabel: {
    marginTop: '0.25em',
    fontSize: '12px',
  },
  headerContainer: {
    padding: '30px 30px 15px 30px',
    backgroundColor: theme.backgroundColor2,
    display: 'flex',
    flexDirection: 'column',
  },
  headerSplitter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  header: {
    height: '1.25em',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '22px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    display: 'flex',
    color: theme.textColor5,
    paddingTop: '10px',
    gap: '10px',
  },
  descriptionIcon: {
    color: theme.textColor5,
    display: 'flex',
    justifyContent: 'center',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionButton: {
    marginTop: '-25px',
  },
  headerActions: {
    display: 'flex',
    marginRight: '40px',
    alignItems: 'center',
  },
  actionWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '30px',
    opacity: 0.6,
    fontSize: '20px',
    cursor: 'pointer',
    textAlign: 'center',
    '&.button': {
      opacity: 1,
    },
    '&:hover': {
      opacity: 0.9,
    },
    '&.active': {
      color: theme.successColor,
    },
    '&.disabled': {
      opacity: 0.3,
      cursor: 'default',
    },
  },
  actionTitle: {
    fontSize: '12px',
    marginTop: '5px',
  },
  actionLoading: {
    padding: 0,
    height: '20px',
    width: '20px',
  },
  extraActions: {
    height: '37px',
    width: '18px',
    fontSize: '20px',
    textAlign: 'center',
    marginLeft: '30px',
    opacity: 0.6,
    '&:hover': {
      opacity: 0.9,
    },
    '& svg': {
      marginTop: '9px',
    },
    '&.hide': {
      display: 'none',
    },
  },
  extraActionsDropdownMenu: {
    zIndex: '2',
    borderRadius: '18px',
    border: `1px solid ${theme.cardBorder} !important`,
    overflow: 'hidden',
  },
  extraActionsDropdownItem: {
    margin: 'unset !important',
    borderBottom: 'unset !important',
    backgroundColor: theme.cardBackground,
    '&:hover': {
      backgroundColor: theme.cardColor2,
      cursor: 'pointer',
    },
  },
  dropdownItemAction: {
    display: 'none',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    headerContainer: {
      padding: '20px',
    },
    headerRight: {
      marginTop: '20px',
    },
    header: {
      fontSize: '28px',
    },
    dropdownItemAction: {
      display: 'block',
      margin: 'unset !important',
      borderBottom: 'unset !important',
      backgroundColor: theme.cardBackground,
      '&:hover': {
        backgroundColor: theme.cardColor2,
        cursor: 'pointer',
      },
      '&.isActive': {
        color: theme.successColor,
      },
    },
    actionWrap: {
      display: 'none',
    },
    extraActions: {
      marginLeft: 'auto',
      '&.hide': {
        display: 'unset',
      },
    },
    headerActions: {
      marginRight: '0px',
    },
  },
  dropdownItemBtn: {
    opacity: 0.8,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '10px',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    headerActions: {
      marginRight: '0px',
    },
  },
}));
