import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  choiceWrap: {
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  choiceContent: {
    marginLeft: '6px',
    wordWrap: 'break-word !important',
    whiteSpace: 'pre-wrap',
    '& > p': {
      margin: '0',
    },
    '&.disabled': {
      pointerEvents: 'none',
      '& .ql-image': {
        pointerEvents: 'auto',
      },
      '& .ql-video': {
        pointerEvents: 'auto',
      },
      '& audio': {
        pointerEvents: 'auto',
      },
    },
  },
  correctIcon: {
    color: theme.successColor,
    marginLeft: '10px',
  },
  input: {
    appearance: 'none',
    minWidth: '20px',
    minHeight: '20px',
    border: `2px solid ${theme.buttonBackground2}`,
    backgroundClip: 'content-box',
    borderRadius: '50%',
    padding: '3px',
    cursor: 'pointer',
    '&: hover': {
      backgroundColor: theme.buttonBackgroundHover2,
    },
    '&: focus': {
      backgroundColor: theme.buttonBackgroundHover2,
    },
    '&:checked': {
      backgroundColor: theme.buttonBackground2,
    },
    '&.disabled': {
      pointerEvents: 'none',
      border: `2px solid ${theme.buttonBackground}`,
    },
    '&.hasMultipleCorrect': {
      borderRadius: '5px',
    },
  },
  identifier: {
    color: 'grey',
    margin: '0 5px 0 5px',
    fontSize: '14px',
    whiteSpace: 'noWrap',
  },
}));
