export const CHOICES = 'choices';
export const TEXT = 'text';
export const IMAGE = 'image';
export const TRUEFALSE = 'true-false';
export const FILLING = 'filling';
export const CAPTION = 'caption';
export const ORDERING = 'ordering';
export const INFORMATION = 'information';
export const PAUSE = 'pause';
export const CONNECTING = 'connecting';
export const SEGMENTATION = 'segmentation';
export const TABLE = 'table';
