import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  testCard: {
    margin: '8px 0',
    cursor: 'default !important',
    width: '100% !important',
    '& .otl-cardContentContainer': {
      padding: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
    },
  },
  contentWrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  testName: {
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '1',
    boxOrient: 'vertical',
    marginTop: '0 !important',
    fontSize: '17px',
    fontFamily: 'Varela Round',
  },
  testLessonWrap: {
    fontSize: '14px',
    color: theme.grey,
    display: 'flex',
    marginTop: '8px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '10px',
  },
  testInfoWrap: {
    fontSize: '12px',
    color: theme.grey,
    marginTop: '8px',
  },
  testInfo: {
    marginLeft: '4px',
  },
  testInfoSeparator: {
    margin: '0 10px',
  },
  testLeftWrap: {
    padding: '11px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    '&.student': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  testStatus: {
    fontSize: '14px',
    color: theme.black,
    padding: '5px',
    boxSizing: 'border-box',
    borderRadius: '3px',
    border: `1px solid ${theme.shade5}`,
  },
  loadingSpinner: {
    height: '26px',
  },
  rightWrap: {
    padding: '0 15px',
    backgroundColor: theme.cardColor3,
    display: 'flex',
    alignItems: 'center',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    rightWrap: {
      width: '100%',
      justifyContent: 'flex-end',
    },
  },

}));
