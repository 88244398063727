import React, { useState, useEffect } from 'react';
import { Spacer } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import api from 'api';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useInput from 'hooks/common/useInput';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { DATE_TIME_FORMAT, getNowDatetimeString } from 'utils/datetime';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';
import PlansPill from 'components/common/plans/PlansPill';

import useStyles from './styles';

const MAX_DURATION_MINS = 24 * 60; // 1 day
const MAX_ATTEMPT_WINDOW_MINS = 72 * 60; // 3 days

const PublishFormModal = ({ open, close, testId, testType, classificationType, publication, afterSubmit, isOnGoing, edit, setEdit }) => {
  const toast = useToast();
  const { spaceId } = useParams();
  const classes = useStyles();
  const [getSchoolClassesRequest] = useApi(api.getSchoolClasses);
  const [getSchoolStudentsRequest] = useApi(api.getSchoolStudents);
  const [createPublicationRequest] = useApi(api.createPublication);
  const [editOnGoingPublicationRequest] = useApi(api.editOnGoingPublication);
  const [editPublicationRequest] = useApi(api.editPublication);
  const navigationToggle = useFeature(toggles.navigation);
  const iaveToggle = useFeature(toggles.iave);
  const modelAnswerToggle = useFeature(toggles.modelAnswer);

  const [loading, setLoading] = useState(false);
  const [targetOptions, setTargetOptions] = useState([
    { label: lang.appKeywords.classes, options: [] },
    { label: lang.appKeywords.students, options: [] },
  ]);

  const typeOptions = [
    { value: 'full', label: `${lang.test.presentationFull}: ${lang.test.presentationFullTip}` },
    { value: 'incremental', label: `${lang.test.presentationIncremental}: ${lang.test.presentationIncrementalTip}` },
  ];

  const navigationOptions = [
    { value: 'linear', label: `${lang.test.navigationLinear}: ${lang.test.navigationLinearTip}` },
    { value: 'nonLinear', label: `${lang.test.navigationNonLinear}: ${lang.test.navigationNonLinearTip}` },
  ];

  const CLASS = 'class';
  const STUDENT = 'student';

  const shuffleOptions = [
    { value: 'none', label: lang.test.publishModal.shuffleNoneLabel },
    { value: 'base', label: lang.test.publishModal.shuffleBaseLabel },
    { value: 'sections', label: lang.test.publishModal.shuffleSectionsLabel },
    { value: 'all', label: lang.test.publishModal.shuffleAllLabel },
  ];
  const currentShuffleOption = edit ? shuffleOptions.find(option => option.value === publication.shuffleType) : shuffleOptions[0];

  const currentType = edit ? typeOptions.find(option => option.value === publication.type) : typeOptions[0];
  const currentNavigation = edit ? (navigationOptions.find(option => option.value === publication.navigation) ?? navigationOptions[0]) : navigationOptions[0];
  const currentStudents = publication?.students?.map(student => ({ value: student.userId, label: student.name, type: STUDENT })) ?? [];
  const currentClasses = publication?.classes?.map(_class => ({ value: _class.id, label: _class.name, type: CLASS })) ?? [];

  const [type, setType] = useState(currentType);
  const [navigation, setNavigation] = useState(currentNavigation);
  const [targets, setTargets, targetsErrors, setTargetsErrors] = useInput(iaveToggle ? currentClasses : [...currentClasses, ...currentStudents]);
  const [startsAt, setStartsAt, startsAtErrors, setStartsAtErrors] = useInput(edit ? publication.startsAt : getNowDatetimeString('datetime'));
  const [endsAt, setEndsAt, endsAtErrors, setEndsAtErrors] = useInput(edit ? publication.endsAt : getNowDatetimeString('datetime'));
  const [attemptWindow, setAttemptWindow, attemptWindowErrors, setAttemptWindowErrors] = useInput(edit ? publication.attemptWindow : undefined);
  const [duration, setDuration, durationErrors, setDurationErrors] = useInput(edit ? publication.duration : undefined);
  const [password, setPassword] = useInput(edit ? publication.password : '');
  const [exitPin, setExitPin] = useInput(edit ? publication.exitPin ?? '' : '');
  const [shuffleType, setShuffleType] = useState(currentShuffleOption);
  const [immediateFeedback, setImmediateFeedback] = useState(edit ? publication.immediateFeedback : false);
  const [showModelAnswer, setShowModelAnswer] = useState(edit ? publication.showModelAnswer : false);
  const [showRubric, setShowRubric] = useState(edit ? publication.showRubric : false);

  useEffect(() => {
    getSchoolClassesRequest([spaceId], null, ({ data }) => {
      if (data.status === 0) {
        const classes = data.classes.map(_class => ({
          value: _class.id,
          label: _class.name,
          type: CLASS,
        }));

        setTargetOptions(options => {
          const newOptions = [...options];
          newOptions[0].options = classes;
          return newOptions;
        });
      }
    });

    if (!iaveToggle) {
      getSchoolStudentsRequest([spaceId], null, ({ data }) => {
        if (data.status === 0) {
          const students = data.students.map(student => ({
            value: student.id,
            label: student.fullName,
            type: STUDENT,
          }));

          setTargetOptions(options => {
            const newOptions = [...options];
            newOptions[1].options = students;
            return newOptions;
          });
        }
      });
    }
  }, [getSchoolClassesRequest, getSchoolStudentsRequest, spaceId, iaveToggle]);

  const closeModal = () => {
    if (loading === false) {
      setTimeout(() => {
        setType(typeOptions[0]);
        setShuffleType(shuffleOptions[0]);
        setNavigation(navigationOptions[0]);
        setShowRubric(false);
        setTargets([]);
        setTargetsErrors([]);
        setStartsAt('');
        setStartsAtErrors([]);
        setEndsAt('');
        setEndsAtErrors([]);
        setAttemptWindow('');
        setAttemptWindowErrors([]);
        setDuration('');
        setDurationErrors([]);
        setPassword('');
      }, 300);

      close();
      setEdit(false);
    }
  };

  const validateData = () => {
    let errors = false;
    const _targetsErrors = [];
    const _startsAtErrors = [];
    const _endsAtErrors = [];
    const _attemptWindowErrors = [];
    const _durationErrors = [];

    if (!startsAt) {
      errors = true;
      _startsAtErrors.push(lang.test.publishModal.errorStartsAtRequired);
    } else {
      const now = new Date();
      now.setMinutes(now.getMinutes() - 1);
      const _startsAt = new Date(startsAt).getTime();
      if (_startsAt <= now.getTime() && !isOnGoing) {
        errors = true;
        _startsAtErrors.push(lang.test.publishModal.errorStartsAtPast);
      }
    }

    if (testType === 'worksheet') {
      if (!endsAt) {
        errors = true;
        _endsAtErrors.push(lang.test.publishModal.errorEndsAtRequired);
      } else {
        const _endsAt = new Date(endsAt).getTime();
        const _startsAt = new Date(startsAt).getTime();
        if (_startsAt >= _endsAt) {
          errors = true;
          _endsAtErrors.push(lang.test.publishModal.errorEndsAtBeforeStartsAt);
        }
      }
    }

    if (testType === 'test') {
      if (!attemptWindow) {
        errors = true;
        _attemptWindowErrors.push(lang.test.publishModal.errorAttemptWindowRequired);
      } else if (attemptWindow < 1 || attemptWindow > MAX_ATTEMPT_WINDOW_MINS) {
        errors = true;
        _attemptWindowErrors.push(lang.test.publishModal.errorAttemptWindowBoundaries);
      }

      if (!duration) {
        errors = true;
        _durationErrors.push(lang.test.publishModal.errorDurationRequired);
      } else if (duration < 1 || duration > MAX_DURATION_MINS) {
        errors = true;
        _durationErrors.push(lang.test.publishModal.errorDurationBoundaries);
      }
    }

    setTargetsErrors(_targetsErrors);
    setStartsAtErrors(_startsAtErrors);
    setEndsAtErrors(_endsAtErrors);
    setAttemptWindowErrors(_attemptWindowErrors);
    setDurationErrors(_durationErrors);

    return errors;
  };

  const handlePublish = () => {
    const errors = validateData();
    if (errors) {
      return;
    }

    let classes;
    let students;
    if (targets && targets.length !== 0) {
      classes = targets
        .filter(target => target.type === CLASS)
        .map(_class => _class.value);
      students = targets
        .filter(target => target.type === STUDENT)
        .map(student => student.value);
    }

    const newPublication = {
      testId: testId,
      type: type.value,
      classes: classes,
      students: students,
      startsAt: startsAt,
      password: password,
      exitPin: exitPin,
      shuffleType: shuffleType.value,
      immediateFeedback: immediateFeedback,
      showRubric: showRubric,
      navigation: type.value === 'incremental' ? navigation.value : null,
      showModelAnswer: showModelAnswer,
    };

    if (testType === 'test') {
      newPublication.attemptWindow = attemptWindow;
      newPublication.duration = duration;
    } else if (testType === 'worksheet') {
      newPublication.endsAt = endsAt;
    }

    setLoading(true);
    createPublicationRequest([], newPublication, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        toast.success(lang.test.publishModal.successPublishTest);
        setEdit(false);
        afterSubmit({
          testId: testId,
          id: data.publicationId,
          ...newPublication,
        });
      } else {
        toast.error(lang.oops);
      }
    });
  };

  const handleEditPublish = () => {
    const errors = validateData();
    if (errors) {
      return;
    }

    const classes = targets
      .filter(target => target.type === CLASS)
      .map(_class => _class.value);
    const students = targets
      .filter(target => target.type === STUDENT)
      .map(student => student.value);

    const newPublicationTime = {};

    if (testType === 'test') {
      newPublicationTime.attemptWindow = attemptWindow;
      newPublicationTime.duration = duration;
    } else if (testType === 'worksheet') {
      newPublicationTime.endsAt = endsAt;
    }

    const newPublication = {
      ...newPublicationTime,
      type: type.value,
      classes: isOnGoing ? publication.classes : classes,
      students: isOnGoing ? publication.students : students,
      startsAt: startsAt,
      password: password,
      exitPin: exitPin,
      shuffleType: shuffleType.value,
      immediateFeedback: immediateFeedback,
      showRubric: showRubric,
      navigation: type.value === 'incremental' ? navigation.value : null,
      showModelAnswer: showModelAnswer,
    };
    setLoading(true);

    if (isOnGoing) {
      const ongoingPublicationData = {
        ...newPublicationTime,
        students,
        classes,
        password,
        exitPin,
        immediateFeedback,
        showModelAnswer,
        showRubric,
      };

      editOnGoingPublicationRequest([publication.id], ongoingPublicationData, ({ data }) => {
        setTimeout(() => {
          setLoading(false);
          if (data.status === 0) {
            toast.success(lang.test.publishModal.successEditPublication);
            setEdit(false);
            afterSubmit({
              testId: testId,
              id: publication.id,
              ...newPublication,
            });
          } else if (data.status === 4) {
            toast.error(lang.test.publishModal.errorEditDuration(data.minTime));
          } else if (data.status === 5) {
            toast.error(lang.test.publishModal.errorEndsAtBeforeStartsAt);
          } else {
            toast.error(lang.oops);
          }
        }, 350);
      });
    } else {
      editPublicationRequest([publication.id], newPublication, ({ data }) => {
        setTimeout(() => {
          setLoading(false);
          if (data.status === 0) {
            toast.success(lang.test.publishModal.successRescheduleTest);
            setEdit(false);
            afterSubmit({
              testId: testId,
              id: publication.id,
              ...newPublication,
              students: data.newUserPublicationsInfo,
            });
          } else {
            toast.error(lang.oops);
          }
        }, 350);
      });
    }
  };

  const validDates = (current) => {
    return new Date(current.toDate().toDateString()) >= new Date(new Date().toDateString());
  };

  const saveDate = (date, setter) => {
    if (date?.isValid()) {
      setter(date.format(DATE_TIME_FORMAT));
    } else {
      setter(getNowDatetimeString(DATE_TIME_FORMAT));
    }
  };

  const saveInteger = (event, setter) => {
    const value = event.target.value;
    if (value === '') {
      setter('');
    }
    const intValue = parseInt(value);
    if (intValue) {
      setter(intValue);
    }
  };

  const _setImmediateFeedback = (checked) => {
    if (!checked) {
      setShowModelAnswer(false);
    }
    setImmediateFeedback(checked);
  };

  const actions = [
    {
      name: edit ? (isOnGoing ? lang.test.publishModal.editPublication : lang.reschedule) : lang.publish,
      loading: loading,
      onClick: edit ? handleEditPublish : handlePublish,
      dataTour: 'assessment-publish-form-submit',
    },
    {
      name: lang.cancel,
      color: 'black',
      onClick: edit ? () => setEdit(false) : closeModal,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={edit ? (isOnGoing ? lang.test.publishModal.editPublication : lang.test.publishModal.rescheduleTest) : lang.test.publishModal.publishTest}
      actions={actions}
      center
      transition
      className={cx(classes.modal, { isOnGoing })}
    >
      {!isOnGoing && (
        <>
          <Input
            dataTour="assessment-publish-form-format"
            type="select"
            label={lang.test.publishModal.testFormat}
            placeholder={lang.test.publishModal.testFormat}
            options={typeOptions}
            value={type}
            onChange={setType}
          />
          <Spacer px={20} />
          {type.value === 'incremental' && (
            <>
              <Input
                type="select"
                label={
                  <div className={classes.navigationContainer}>
                    {lang.test.publishModal.navigationType}
                    {!navigationToggle && (
                      <PlansPill
                        tip={lang.plans.premiumFeature}
                      />
                    )}
                  </div>
                }
                placeholder={lang.test.publishModal.navigationType}
                options={navigationOptions}
                value={navigation}
                onChange={setNavigation}
                disabled={!navigationToggle}
              />
              <Spacer px={20} />
            </>
          )}
        </>
      )}
      {spaceId !== PERSONAL_SPACE && (targetOptions.find(option => option.label === lang.appKeywords.students).options.length !== 0 || iaveToggle) && (
        <>
          <Input
            type="select"
            label={`${lang.test.publishModal.labelTargets} (${lang.optional})`}
            placeholder={`${lang.test.publishModal.labelTargets} (${lang.optional})`}
            hint={lang.test.publishModal.hintTargets}
            multiple
            options={targetOptions}
            value={targets}
            onChange={setTargets}
            errors={targetsErrors}
          />
          <Spacer px={20} />
        </>
      )}
      {!isOnGoing && (
        <>
          <Input
            dataTour="assessment-publish-form-start"
            type="datetime"
            locale={'pt'}
            label={lang.test.publishModal.labelStartsAt}
            placeholder={lang.test.publishModal.labelStartsAt}
            value={startsAt}
            onChange={(date) => saveDate(date, setStartsAt)}
            maxSize={100}
            isValidDate={validDates}
            readOnly
            errors={startsAtErrors}
            allowClear={false}
          />
          <Spacer px={20} />
        </>
      )}
      {testType === 'worksheet' && (
        <>
          <Input
            type="datetime"
            locale={'pt'}
            label={lang.test.publishModal.labelEndsAt}
            placeholder={lang.test.publishModal.labelEndsAt}
            value={endsAt}
            onChange={(date) => saveDate(date, setEndsAt)}
            maxSize={100}
            isValidDate={validDates}
            readOnly
            errors={endsAtErrors}
            allowClear={false}
          />
          <Spacer px={20} />
        </>
      )}
      {testType === 'test' && (
        <>
          <Input
            dataTour="assessment-publish-form-tolerance"
            type="number"
            label={lang.test.publishModal.labelAttemptWindow}
            placeholder={lang.test.publishModal.labelAttemptWindow}
            hint={lang.test.publishModal.hintAttemptWindow}
            minSize={1}
            maxSize={MAX_ATTEMPT_WINDOW_MINS}
            value={attemptWindow}
            onChange={(event) => saveInteger(event, setAttemptWindow)}
            errors={attemptWindowErrors}
          />
          <Spacer px={20} />
          <Input
            dataTour="assessment-publish-form-duration"
            type="number"
            label={lang.test.publishModal.labelDuration}
            placeholder={lang.test.publishModal.labelDuration}
            minSize={1}
            maxSize={MAX_DURATION_MINS}
            value={duration}
            onChange={(event) => saveInteger(event, setDuration)}
            errors={durationErrors}
          />
          <Spacer px={20} />
        </>
      )}
      <Input
        type="text"
        dataTour="assessment-publish-form-password"
        label={lang.test.publishModal.labelPassword + ' (' + lang.optional + ')'}
        placeholder={lang.test.publishModal.labelPassword + ' (' + lang.optional + ')'}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />
      <Spacer px={20} />
      {spaceId !== PERSONAL_SPACE && (
        <>
          <Input
            type="text"
            label={lang.test.publishModal.labelExitPin + ' (' + lang.optional + ')'}
            placeholder={lang.test.publishModal.labelExitPin + ' (' + lang.optional + ')'}
            hint={lang.test.publishModal.hintExitPin}
            value={exitPin}
            onChange={(event) => setExitPin(event.target.value)}
          />
        </>
      )}
      <h4>
        {lang.otherOptions}
      </h4>
      {!isOnGoing && (
        <>
          <Input
            dataTour="assessment-publish-form-shuffle"
            type="select"
            label={lang.test.publishModal.testShuffleLabel}
            options={shuffleOptions}
            value={shuffleType}
            onChange={setShuffleType}
            hint={shuffleType.value !== 'none' ? lang.test.publishModal.hintInformationBlocksShuffle : null}
            menuPlacement="top"
          />
          <Spacer px={10} />
        </>
      )}
      <Input
        dataTour="assessment-publish-form-feedback"
        type="checkbox"
        label={lang.test.publishModal.immediateFeedbackLabel}
        value={immediateFeedback}
        onChange={(event) => _setImmediateFeedback(event.target.checked)}
      />
      <Spacer px={10} />
      {modelAnswerToggle && (
        <>
          <Input
            dataTour="assessment-publish-form-model-answer"
            type="checkbox"
            label={lang.test.publishModal.modelAnswerLabel}
            value={showModelAnswer}
            onChange={(event) => setShowModelAnswer(event.target.checked)}
            disabled={!immediateFeedback}
          />
          <Spacer px={10} />
        </>
      )}
      {classificationType === 'rubric' && (
        <>
          <Input
            type="checkbox"
            label={lang.test.publishModal.showRubricLabel}
            value={showRubric}
            onChange={(event) => setShowRubric(event.target.checked)}
          />
          <Spacer px={10} />
        </>
      )}
    </Modal>
  );
};

PublishFormModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  testId: PropTypes.string,
  testType: PropTypes.string,
  classificationType: PropTypes.string,
  publication: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    startsAt: PropTypes.string,
    endsAt: PropTypes.string,
    duration: PropTypes.number,
    attemptWindow: PropTypes.number,
    password: PropTypes.string,
    exitPin: PropTypes.string,
    classes: PropTypes.array,
    students: PropTypes.array,
    shuffleType: PropTypes.oneOf(['all', 'none']),
    immediateFeedback: PropTypes.bool,
    showRubric: PropTypes.bool,
    navigation: PropTypes.oneOf(['linear', 'nonLinear', null]),
    showModelAnswer: PropTypes.bool,
  }),
  isOnGoing: PropTypes.bool,
  edit: PropTypes.bool,
  setEdit: PropTypes.func,
  afterSubmit: PropTypes.func,
};

export default PublishFormModal;
