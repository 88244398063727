import { createUseStyles } from 'react-jss';

export default createUseStyles({
  reenterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  continueAs: {
    width: '100%',
  },
  newStudent: {
    marginTop: '5px',
    marginLeft: '10px',
    fontSize: '12px',
  },
  newStudentLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});
