import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  exerciseUnfocused: {
    opacity: '0.2',
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: '8px',
  },
  annotateIcon: {
    marginRight: '5px',
  },
  commentBoxContainer: {
    position: 'absolute',
    width: '34%',
    top: '0',
    right: 'calc(-34% - 40px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '&.isInsideSection': {
      position: 'absolute',
      width: '34%',
      top: '0',
      right: 'calc(-34% - 60px)',
    },
    '&.isUpwards': {
      top: 'unset',
      bottom: '0',
    },
  },
}));
