import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  loginHeader: {
    paddingTop: '10px',
    marginBottom: '24px',
  },
  loginTitle: {
    fontSize: '24px',
  },
  loginSubtitle: {
    fontSize: '15px',
    color: theme.textColor2,
  },
  timerContainer: {
    '&.hidden': {
      display: 'none',
    },
  },
}));
