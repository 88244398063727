import lang from 'lang';
import { buildStep, buildOptions } from 'services/shepherd/utils';

export const options = buildOptions('explore');

const motionDelay = 300;
const requestDelay = 500;

export const steps = (isOnExplorePage) => [
  {
    id: 'sidebar-explore',
    attachOn: 'right',
    ...(
      isOnExplorePage
        ? {
          buttons: ['next'],
          text: lang.tours.explore.steps.sidebar,
        }
        : {
          text: lang.tours.explore.steps.sidebarExplore,
          advanceOnClick: true,
        }
    ),
  },
  {
    id: 'explore-exercises-list',
    text: lang.tours.explore.steps.exerciseList,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'explore-header',
    text: lang.tours.explore.steps.header,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'explore-header-text-input',
    text: lang.tours.explore.steps.headerTextInput,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'explore-header-content-dropdown',
    text: lang.tours.explore.steps.headerContentDropdown,
    attachOn: 'left',
    buttons: ['back', 'next'],
  },
  {
    id: 'explore-header-filters',
    text: lang.tours.explore.steps.headerFilters,
    attachOn: 'left',
    buttons: ['back', 'next'],
  },
  {
    id: 'explore-exercises-card-0',
    text: lang.tours.explore.steps.exerciseCardClick,
    attachOn: 'right',
    advanceOnClick: true,
    buttons: ['back'],
  },
  {
    id: 'explore-exercises-card-0-preview-modal',
    text: lang.tours.explore.steps.exerciseCardPreview,
    attachOn: 'right',
    delayTime: motionDelay,
    buttons: ['next'],
  },
  {
    id: 'explore-exercises-card-0-preview-modal-copy-button',
    text: lang.tours.explore.steps.exerciseCardPreviewCopy,
    attachOn: 'right',
    advanceOnClick: true,
    buttons: ['back'],
  },
  {
    id: 'explore-exercises-card-0-preview-modal-select-group-container',
    text: lang.tours.explore.steps.exerciseCardPreviewSelectGroup,
    attachOn: 'right',
    buttons: ['back', 'next'],
  },
  {
    id: 'explore-exercises-card-0-preview-modal-select-group-submit',
    text: lang.tours.explore.steps.exerciseCardPreviewSubmit,
    attachOn: 'right',
    advanceOnClick: true,
    buttons: ['back'],
  },
  {
    id: 'explore-exercises-card-0-final',
    dataTour: 'explore-exercises-card-0',
    text: lang.tours.explore.steps.exerciseCardClosed,
    attachOn: 'right',
    delayTime: motionDelay + requestDelay,
    buttons: ['back', 'next'],
  },
  // final step
  {
    id: 'tour-hub',
    text: lang.tours.explore.steps.hub,
    attachOn: 'top-left',
    buttons: ['back', 'complete'],
  },
].map(buildStep);
