import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectTestItems } from 'actions/testActions';

import AnswerZoneCard from '../AnswerZoneCard';
import PreviewTestExerciseModal from '../PreviewTestExerciseModal';
import SectionAnswerZonesCard from '../SectionAnswerZonesCard';

const AnswerZoneSettings = ({ getValue, setValue, getErrors }) => {
  const [currentExerciseId, setCurrentExerciseId] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const items = useSelector(selectTestItems);

  const previewExercise = (testExerciseId) => {
    setCurrentExerciseId(testExerciseId);
    setShowPreviewModal(true);
  };

  const cleanupAndClose = () => {
    setShowPreviewModal(false);
    setCurrentExerciseId(null);
  };

  const getItems = () => {
    let exerciseCounter = 0;
    const testItems = items.map((item) => {
      if (item.type !== 'information' && item.type !== 'pause') {
        exerciseCounter++;
      }

      if (item.itemType === 'section') {
        return (
          <SectionAnswerZonesCard
            key={item.id}
            name={item.name}
            exercises={item.exercises}
            getValue={getValue}
            setValue={setValue}
            getErrors={getErrors}
            previewExercise={previewExercise}
          />
        );
      }

      return (
        <AnswerZoneCard
          key={item.id}
          exercise={item}
          num={exerciseCounter}
          getValue={getValue}
          setValue={setValue}
          getErrors={getErrors}
          previewExercise={() => previewExercise(item.testExerciseId)}
        />
      );
    });

    return testItems;
  };

  return (
    <Fragment>
      {getItems()}
      <PreviewTestExerciseModal
        open={showPreviewModal}
        close={() => cleanupAndClose()}
        testExerciseId={currentExerciseId}
      />
    </Fragment>
  );
};

AnswerZoneSettings.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getErrors: PropTypes.func.isRequired,
};

export default AnswerZoneSettings;
