import { RESET, SET_PAGE, SET_PAGES, SET_TESTS, START_LOADING, STOP_LOADING } from 'actions/testsPageActions';

const initialState = {
  tests: [],
  pages: 1,
  page: 1,
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TESTS:
      return {
        ...state,
        tests: action.tests,
      };
    case SET_PAGES:
      return {
        ...state,
        pages: action.pages,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
