import React, { Fragment, useState } from 'react';
import { faArrowRight, faArrowsLeftRight, faListUl, faStairs, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacer } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import useInput from 'hooks/common/useInput';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import PlansPill from 'components/common/plans/PlansPill';
import Tooltip from 'components/common/Tooltip';
import CardSelector from 'components/test/card-selector/CardSelector';

import useStyles from './styles';

const TestSectionForm = ({ save, cancel, loading, initialName, initialDescription, initialPresentation, initialNavigation, initialDuration, restricted }) => {
  const classes = useStyles();
  const navigationToggle = useFeature(toggles.navigation);
  const sectionTimerToggle = useFeature(toggles.sectionTimer);

  const presentationOptions = [
    { value: 'full', label: lang.test.presentationFull, tip: lang.test.presentationFullTip, icon: faListUl },
    { value: 'incremental', label: lang.test.presentationIncremental, tip: lang.test.presentationIncrementalTip, icon: faStairs },
  ];

  const navigationOptions = [
    { value: 'linear', label: lang.test.navigationLinear, tip: lang.test.navigationLinearTip, icon: faArrowRight },
    { value: 'nonLinear', label: lang.test.navigationNonLinear, tip: lang.test.navigationNonLinearTip, icon: faArrowsLeftRight },
  ];

  const [name, setName, nameErrors, setNameErrors] = useInput(initialName || '');
  const [description, setDescription] = useState(initialDescription);
  const [duration, setDuration] = useState(initialDuration || null);
  const [presentation, setPresentation] = useState(initialPresentation ? presentationOptions.find(option => option.value === initialPresentation) : presentationOptions[0]);
  const [navigation, setNavigation] = useState(initialNavigation ? navigationOptions.find(option => option.value === initialNavigation) : navigationOptions[0]);

  const confirm = () => {
    let valid = true;
    const newNameErrors = [];

    if (!name) {
      newNameErrors.push(lang.test.exercises.sectionNameRequiredError);
      valid = false;
    }

    setNameErrors(newNameErrors);
    if (!valid) {
      return;
    }

    save({ name, description, navigation: presentation.value === 'incremental' ? navigation.value : null, presentation: presentation.value, duration });
  };

  return (
    <Fragment>
      <Input
        type="text"
        label={lang.test.exercises.sectionNameLabel}
        placeholder={lang.test.exercises.sectionNameLabel}
        value={name}
        onChange={(event) => setName(event.target.value)}
        errors={nameErrors}
      />
      <Spacer px={20} />
      <Input
        type="richtext"
        label={lang.test.exercises.sectionDescriptionLabel}
        placeholder={lang.test.exercises.sectionDescriptionLabel}
        value={description}
        onChange={(value) => setDescription(value)}
      />
      <Spacer px={20} />
      {!restricted && (
        <>
          <div className={classes.cardWrapper}>
            <CardSelector
              headerLabel={lang.test.exercises.sectionPresentation}
              options={presentationOptions}
              selected={presentation}
              setSelected={setPresentation}
            />
          </div>
          <Spacer px={20} />
          {presentation.value === 'incremental' && (
            <>
              <div className={classes.cardWrapper}>
                <CardSelector
                  headerLabel={
                    <div className={classes.navigationHeaderContainer}>
                      {lang.test.exercises.sectionNavigation}
                      {!navigationToggle && (
                        <PlansPill
                          tip={lang.plans.premiumFeature}
                        />
                      )}
                    </div>
                  }
                  options={navigationOptions}
                  selected={navigation}
                  setSelected={setNavigation}
                  disabled={!navigationToggle}
                />
              </div>
              <Spacer px={20} />
            </>
          )}
          <Input
            type="number"
            label={
              <div className={classes.sectionTimerWrapper}>
                {lang.test.exercises.sectionTimerLabel}
                <Tooltip
                  tip={lang.test.exercises.sectionTimerWarning}
                  right
                >
                  <FontAwesomeIcon icon={faTriangleExclamation} className={classes.warningIcon} />
                </Tooltip>
                {!sectionTimerToggle && (
                  <PlansPill
                    tip={lang.plans.premiumFeature}
                  />
                )}
              </div>
            }
            placeholder={lang.test.exercises.sectionTimerLabel}
            value={duration}
            onChange={(event) => setDuration(parseFloat(event.target.value))}
            disabled={!sectionTimerToggle}
          />
          <Spacer px={20} />
        </>
      )}
      <div className={classes.btnContainer}>
        <Button
          className={classes.btn}
          onClick={cancel}
          black
          sibling
        >
          {lang.cancel}
        </Button>
        <Button
          className={classes.btn}
          loading={loading}
          onClick={confirm}
          sibling
        >
          {lang.confirm}
        </Button>
      </div>
    </Fragment>
  );
};

TestSectionForm.propTypes = {
  save: PropTypes.func,
  cancel: PropTypes.func,
  loading: PropTypes.bool,
  initialName: PropTypes.string,
  initialDescription: PropTypes.object,
  initialNavigation: PropTypes.string,
  initialPresentation: PropTypes.string,
  initialDuration: PropTypes.number,
  restricted: PropTypes.bool,
};

export default TestSectionForm;
