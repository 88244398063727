import { createUseStyles } from 'react-jss';

export default createUseStyles({
  swatches: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  lower: {
    marginTop: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    marginLeft: '0.5em',
  },
  preview: {
    minHeight: '2em',
    minWidth: '2em',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.5em',
  },
  previewWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelName: {
    whiteSpace: 'nowrap',
    marginLeft: '0.5em',
  },
  remove: {
    width: '100%',
    height: '100%',
    marginLeft: '0.5em',
    marginRight: '0.5em',
    opacity: '50%',
    '&:hover': {
      opacity: '100%',
      cursor: 'pointer',
    },
  },
  checkbox: {
    '& :first-child': {
      border: 'none',
    },
    '& input': {
      width: '1.15em',
      height: '1.15em',
    },
    '& span': {
      paddingLeft: '0.5em',
    },
  },
});
