import React, { useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { decrementTotalExercises } from 'actions/userActions';
import api from 'api';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const DeleteExercisesModal = ({ open, close, templateExerciseIds, refresh }) => {
  const toast = useToast();
  const classes = useStyles();
  const [deleteTemplateExercisesRequest] = useApi(api.deleteTemplateExercises);
  const dispatch = useDispatch();
  const { spaceId } = useParams();

  const _close = useCallback(() => {
    close();
    setTimeout(350);
  }, [close]);

  const deleteExercise = () => {
    deleteTemplateExercisesRequest([], { templateExerciseIds }, ({ data }) => {
      if (data.status === 0) {
        toast.success(templateExerciseIds.length === 1 ? lang.exercises.successDeleteExercise : lang.exercises.successDeleteExercises);
        setTimeout(() => {
          if (spaceId === PERSONAL_SPACE) {
            dispatch(decrementTotalExercises(templateExerciseIds.length));
          }

          refresh();
          close();
        }, 350);
      } else if (data.status === 2) {
        toast.error(lang.exercises.deleteErrorDoesNotExist);
        _close();
        setTimeout(() => {
          refresh();
        }, 350);
      } else {
        toast.error(lang.oops);
        _close();
      }
    });
  };

  const getActions = () => {
    return [
      {
        name: lang.delete,
        color: 'red',
        onClick: deleteExercise,
      },
      {
        name: lang.cancel,
        color: 'black',
        onClick: _close,
      },
    ];
  };

  const getContent = () => {
    return (
      <div>
        <div className={classes.deleteExerciseInfo}>
          {templateExerciseIds.length === 1 ? lang.exercises.deleteExerciseInfo : lang.exercises.deleteExercisesInfo}
        </div>
        <div className={classes.deleteExerciseInfo}>
          {lang.exercises.deleteExerciseInfo2}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classes.deleteButton}
      onClick={(event) => event.stopPropagation()}
    >
      <Modal
        open={open}
        close={_close}
        header={templateExerciseIds.length === 1 ? lang.exercises.deleteExercise : lang.exercises.deleteExercises}
        actions={getActions()}
        center
        transition
        small
      >
        {getContent()}
      </Modal>
    </div>
  );
};

DeleteExercisesModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  templateExerciseIds: PropTypes.array,
  refresh: PropTypes.func,
};

export default DeleteExercisesModal;
