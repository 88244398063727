import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';

import lang from 'lang';
import routes from 'routes';

import PageHeader from 'components/common/layout/PageHeader';

const Header = ({ groups, group, setGroup, setCreateExerciseModal, setSelectedExercises }) => {
  const history = useHistory();
  const { spaceId } = useParams();

  const onGroupChange = (newGroup) => {
    setGroup(newGroup);
    setSelectedExercises([]);
    history.push({
      search: newGroup.id ? `?group=${newGroup.id}` : '',
    });
  };

  const path = [
    {
      type: 'link',
      text: lang.appKeywords.exercises,
      to: routes.exercises.ref(spaceId),
    },
    {
      type: 'dropdown',
      id: 'groups',
      current: group,
      values: groups,
      onChange: onGroupChange,
    },
  ];

  const actions = [
    {
      label: lang.exercises.createNewExercise,
      onClick: () => setCreateExerciseModal(true),
      dataTour: 'create-new-exercise-button',
    },
  ];

  if (!group) {
    return null;
  }

  return (
    <PageHeader
      path={path}
      actions={actions}
    />
  );
};

Header.propTypes = {
  groups: PropTypes.array,
  group: PropTypes.object,
  setGroup: PropTypes.func,
  setCreateExerciseModal: PropTypes.func,
  setSelectedExercises: PropTypes.func,
};

export default Header;
