import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacer, Table, TableCell, TableRow } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import lang from 'lang';
import { sortPerformanceLevels } from 'utils';

import Expandable from 'components/common/Expandable';
import Input from 'components/common/Input';

import useStyles from './styles';

const Criteria = ({ criterion, criteria, setCriteria, setErrors, criterionIndex, criteriaErrors, dataTour }) => {
  const classes = useStyles();

  const changeCriterionName = (newName) => {
    const newCriteria = criteria.map((criterion) => ({ ...criterion }));
    newCriteria[criterionIndex].name = newName;
    setCriteria(newCriteria);
  };

  const changePerformanceLevelDescription = (newDescription, level) => {
    const newCriteria = criteria.map((criterion) => ({
      ...criterion,
      performanceLevels: criterion.performanceLevels.map((level) => ({ ...level })),
    }));
    const levelIndex = newCriteria[criterionIndex].performanceLevels.findIndex(currLevel => currLevel.level === level);
    newCriteria[criterionIndex].performanceLevels[levelIndex].description = newDescription;
    setCriteria(newCriteria);
  };

  const removeCriterion = () => {
    const newCriteria = criteria.filter((_, index) => index !== criterionIndex);
    setCriteria(newCriteria);
    const newCriteriaErrors = {
      ...criteriaErrors,
      criteria: criteriaErrors.criteria.filter((_, index) => index !== criterionIndex),
    };
    setErrors(newCriteriaErrors);
  };

  const setCriteriaOpen = (open) => {
    const newCriteria = criteria.map((criterion) => ({ ...criterion, open: false }));
    newCriteria[criterionIndex].open = open;
    setCriteria(newCriteria);
  };

  return (
    <div data-tour={dataTour} className={classes.criteriaContainer}>
      <Expandable
        title={criterion.name || lang.rubrics.form.criteriaNameLabel}
        open={criterion.open}
        setOpen={setCriteriaOpen}
        className={classes.expandable}
      >
        <Input
          dataTour={`${dataTour}-name`}
          type="text"
          label={lang.rubrics.form.criteriaNameLabel}
          placeholder={lang.rubrics.form.criteriaNameLabel}
          value={criterion.name}
          onChange={(event) => changeCriterionName(event.target.value)}
          errors={criteriaErrors.criteria[criterionIndex].name}
          maxSize={100}
        />
        <Spacer px={20} />
        <div className={classes.performanceLevelsHeader}>
          {lang.rubrics.performanceLevels}
        </div>
        <div data-tour={`${dataTour}-table`} className={classes.performanceLevelsTableContainer}>
          <Table >
            <TableRow header>
              <TableCell textAlign="center" className={classes.rubricTableCell}>
                5
              </TableCell>
              <TableCell textAlign="center" className={classes.rubricTableCell}>
                4
              </TableCell>
              <TableCell textAlign="center" className={classes.rubricTableCell}>
                3
              </TableCell>
              <TableCell textAlign="center" className={classes.rubricTableCell}>
                2
              </TableCell>
              <TableCell textAlign="center" className={classes.rubricTableCell}>
                1
              </TableCell>
            </TableRow>
            <TableRow key={criterion.id}>
              {sortPerformanceLevels(criterion.performanceLevels).map((level, levelIndex) => (
                <TableCell
                  key={level.id || levelIndex}
                  className={classes.rubricTableCell}
                >
                  <Input
                    type="textarea"
                    rows={10}
                    placeholder={lang.rubrics.form.performanceLevelDescriptionLabel}
                    value={level.description}
                    onChange={(event) => changePerformanceLevelDescription(event.target.value, level.level)}
                    className={classes.performanceLevelDescription}
                    maxSize={2000}
                  />
                </TableCell>
              ))}
            </TableRow>
          </Table>
        </div>
      </Expandable>
      <span className={classes.removeButton} data-tour={`${dataTour}-remove-button`}>
        <FontAwesomeIcon
          icon={faTimes}
          className={classes.criteriaRemoveButton}
          onClick={removeCriterion}
        />
      </span>
    </div>
  );
};

Criteria.propTypes = {
  criterion: PropTypes.object,
  criteria: PropTypes.array,
  setCriteria: PropTypes.func,
  setErrors: PropTypes.func,
  criterionIndex: PropTypes.number,
  criteriaErrors: PropTypes.object,
  dataTour: PropTypes.string,
};

export default Criteria;
