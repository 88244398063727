import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { NEWEST_ORDER, OLDEST_ORDER, RELEVANCY_ORDER, selectOrder, selectSearch, setOrder, USAGES_ORDER } from 'actions/exploreActions';
import lang from 'lang';

import Input from 'components/common/Input';
import ResponsiveLang from 'components/common/ResponsiveLang';

import useStyles from './styles';

const ExerciseListHeader = ({ resultSize }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const search = useSelector(selectSearch);
  const order = useSelector(selectOrder);

  const orderOptions = useMemo(() => ([
    { label: lang.relevancy, value: RELEVANCY_ORDER },
    { label: lang.explore.numberOfUses, value: USAGES_ORDER },
    { label: lang.newest, value: NEWEST_ORDER },
    { label: lang.oldest, value: OLDEST_ORDER },
  ]), []);
  const DEFAULT_ORDER_OPTION = orderOptions[1];

  const [selectedOrder, setSelectedOrder] = useState(DEFAULT_ORDER_OPTION);

  useEffect(() => {
    const defaultOrder = orderOptions.find(option => option.value === order);
    setSelectedOrder(defaultOrder);
  }, [orderOptions, order]);

  const onOrderChange = (newOrder) => {
    setSelectedOrder(newOrder);
    dispatch(setOrder(newOrder.value));
  };

  if (search) {
    return (
      <div className={classes.exerciseListHeader}>
        <div className={classes.headerTitle}>
          {`${lang.results} (${resultSize})`}
          <div className={classes.orderContainer}>
            <label htmlFor="order-select">
              {lang.orderBy}
              :
            </label>
            <Input
              id="order-select"
              type="select"
              value={selectedOrder}
              onChange={onOrderChange}
              options={orderOptions}
              className={classes.selectInput}
            />
          </div>
        </div>
        <div className={classes.exerciseListHeaderSeparator} />
      </div>
    );
  }

  return (
    <div className={classes.exerciseListHeader}>
      <ResponsiveLang
        lang={lang.explore.contentsListHeader}
        lg={lang.explore.contentsListHeaderLg}
        xs={lang.explore.contentsListHeaderXs}
      />
    </div>
  );
};

ExerciseListHeader.propTypes = {
  resultSize: PropTypes.number,
};

export default ExerciseListHeader;
