import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { zeroPad } from 'react-countdown';

import lang from 'lang';

const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return null;
  }

  let daysStr = lang.time.days;
  if (days === 1) {
    daysStr = lang.time.day;
  }

  let hoursStr = lang.time.hours;
  if (hours === 1) {
    hoursStr = lang.time.hour;
  }

  let minutesStr = lang.time.minutes;
  if (minutes === 1) {
    minutesStr = lang.time.minute;
  }

  let secondsStr = lang.time.seconds;
  if (seconds === 1) {
    secondsStr = lang.time.second;
  }

  const stringStyle = {
    fontSize: '10px',
  };

  const spacerStyle = {
    display: 'inline-block',
    width: '10px',
  };

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <Render when={days > 0}>
        <span>
          {zeroPad(days, 0)}
        </span>
        <span style={stringStyle}>
          {daysStr}
        </span>

        <div style={spacerStyle} />
      </Render>

      <span>
        {zeroPad(hours, 0)}
      </span>
      <span style={stringStyle}>
        {hoursStr}
      </span>

      <div style={spacerStyle} />

      <span>
        {zeroPad(minutes, 0)}
      </span>
      <span style={stringStyle}>
        {minutesStr}
      </span>

      <div style={spacerStyle} />

      <span>
        {zeroPad(seconds, 0)}
      </span>
      <span style={stringStyle}>
        {secondsStr}
      </span>
    </div>
  );
};

CountdownRenderer.propTypes = {
  days: PropTypes.number,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  completed: PropTypes.bool,
};

export default CountdownRenderer;
