import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  input: {
    width: '100%',
    fontWeight: 'normal !important',
    border: '1px solid #f0f0f0',
    borderRadius: '8px',
    transition: 'border 0.48s',
    transitionTimingFunction: 'ease-out',
    '& [class^="headerItem"]': {
      fontSize: '11px',
      position: 'absolute',
      top: 'calc(100% - 13px)',
      zIndex: 1,
      opacity: '70%',
    },
    '& .quill': {
      backgroundColor: 'unset !important',
    },
    '& .ql-container': {
      backgroundColor: 'white !important',
      borderRadius: '8px',
      '& .ql-editor': {
        '&.ql-blank::before': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis !important',
        },
      },
    },
  },
  removeButton: {
    height: '30px',
    width: '30px',
    minWidth: '30px !important',
    border: '2px solid #ef444426 !important',
    color: '#ef4444 !important',
    '& > svg': {
      fontSize: '1.2em',
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
      border: '2px solid #ef4444 !important',
    },
  },
  removeRowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    width: '30px',
    top: '10px',
    left: 'calc(50% - 15px)',
    minWidth: '30px !important',
    border: '2px solid #ef444426 !important',
    color: '#ef4444 !important',
    '& > svg': {
      fontSize: '1.2em',
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
      border: '2px solid #ef4444 !important',
    },
  },
  headerCellContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  headerCellRowContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  headerColIdentifiers: {
    fontSize: '11px',
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translateY(-50%)',
    zIndex: 1,
    opacity: '30%',
  },
  headerRowIdentifiers: {
    fontSize: '11px',
    position: 'absolute',
    top: '5px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
    opacity: '30%',
  },
  buttonSpacer: {
    width: '30px',
    height: '30px',
  },
}));
