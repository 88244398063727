import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import useFeature from 'hooks/useFeature';
import routes from 'routes';
import toggles from 'toggles';

import PrivateRoute from 'components/common/PrivateRoute';
import Attempt from 'pages/Attempt';
import ExerciseEditor from 'pages/ExerciseEditor';
import Exercises from 'pages/Exercises';
import Explore from 'pages/Explore';
import Group from 'pages/Group';
import Groups from 'pages/Groups';
import Logout from 'pages/Logout';
import MicrosoftLoginRedirect from 'pages/MicrosoftLoginRedirect';
import MicrosoftStudentLoginRedirect from 'pages/MicrosoftStudentLoginRedirect';
import NoMatch from 'pages/NoMatch';
import Publication from 'pages/Publication';
import RubricEditor from 'pages/RubricEditor';
import Rubrics from 'pages/Rubrics';
import Test from 'pages/Test';
import Tests from 'pages/Tests';

const Router = () => {
  const spaceId = window.location.pathname.split('/')[2];
  const userData = useSelector(state => state.user.data);
  const exploreToggle = useFeature(toggles.explore);

  return (
    <Switch>
      <PrivateRoute exact path={routes.home.def}>
        <Redirect to={routes.tests.ref(userData.spaceIdPreference ?? (userData.hasPersonalSpace ? 'p' : userData.schools && userData.schools[0].id))} />
      </PrivateRoute>
      <PrivateRoute exact path={routes.school.def}>
        <Redirect to={routes.tests.ref(spaceId)} />
      </PrivateRoute>
      <PrivateRoute exact path={routes.tests.def}>
        <Tests />
      </PrivateRoute>
      <PrivateRoute exact path={routes.test.def}>
        <Test />
      </PrivateRoute>
      <PrivateRoute exact path={routes.exercises.def}>
        <Exercises />
      </PrivateRoute>
      <PrivateRoute exact path={routes.newExercise.def}>
        <ExerciseEditor />
      </PrivateRoute>
      <PrivateRoute exact path={routes.editExercise.def}>
        <ExerciseEditor />
      </PrivateRoute>
      <PrivateRoute exact path={routes.groups.def}>
        <Groups />
      </PrivateRoute>
      <PrivateRoute exact path={routes.group.def}>
        <Group />
      </PrivateRoute>
      <Route exact path={routes.attempt.def}>
        <Attempt />
      </Route>
      <Route exact path={routes.publication.def}>
        <Publication />
      </Route>
      <PrivateRoute exact path={routes.rubrics.def}>
        <Rubrics />
      </PrivateRoute>
      <PrivateRoute exact path={routes.newRubric.def}>
        <RubricEditor />
      </PrivateRoute>
      <PrivateRoute exact path={routes.editRubric.def}>
        <RubricEditor />
      </PrivateRoute>
      {exploreToggle &&
        <PrivateRoute exact path={routes.explore.def}>
          <Explore />
        </PrivateRoute>
      }
      <Route exact path={routes.loginMicrosoft.def}>
        <MicrosoftLoginRedirect />
      </Route>
      <Route exact path={routes.studentLoginMicrosoft.def}>
        <MicrosoftStudentLoginRedirect />
      </Route>
      <Route exact path={routes.logout.def}>
        <Logout />
      </Route>
      <PrivateRoute path={'*'}>
        <NoMatch />
      </PrivateRoute>
    </Switch>
  );
};

export default Router;
