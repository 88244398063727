import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  connectorWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  card: {
    borderRadius: '10px',
    backgroundColor: theme.cardColor2,
    width: '100%',
  },
  header: {
    borderRadius: '9px 9px 0 0',
    backgroundColor: theme.cardColor,
    padding: '0.25em',
    fontSize: '10px',
  },
  body: {
    padding: '0.5em',
  },
  ringWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  outerRing: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '0.25em',
    border: `2px solid ${theme.white}00`,
    '&:hover': {
      '&.answerable': {
        border: `2px solid ${theme.buttonBackground2}`,
        cursor: 'pointer',
      },
    },
  },
  innerRing: {
    width: '1em',
    height: '1em',
    borderRadius: '50%',
    backgroundColor: '#595959CC',
    '&.active': {
      backgroundColor: theme.buttonBackground2,
    },
    '&.used': {
      backgroundColor: theme.buttonBackground2,
    },
  },
  connectorContent: {
    marginLeft: '6px',
    wordWrap: 'break-word !important',
    whiteSpace: 'pre-wrap',
    '& > p': {
      margin: '0',
    },
    '&.disabled': {
      pointerEvents: 'none',
      '& .ql-image': {
        pointerEvents: 'auto',
      },
      '& .ql-video': {
        pointerEvents: 'auto',
      },
      '& audio': {
        pointerEvents: 'auto',
      },
    },
  },
  identifier: {
    color: 'grey',
    margin: '0 5px 0 5px',
    fontSize: '14px',
    whiteSpace: 'noWrap',
  },
}));
