import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import PropTypes from 'prop-types';

import OrderItem from '../OrderItem';

const DraggableOrderItem = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
    isDragging,
    node,
  } = useSortable({ id: props.id });

  const style = {
    transform: isSorting ? (transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined) : undefined,
    transition,
    touchAction: 'none',
    opacity: isDragging ? '40%' : '100%',
  };

  return (
    <OrderItem
      ref={setNodeRef}
      style={style}
      id={props.id}
      item={props.item}
      answerable={props.answerable}
      attributes={attributes}
      listeners={listeners}
      isDragging={isDragging}
      isSorting={isSorting}
      node={node}
      restricted={props.restricted}
      {...props}
    />
  );
};

DraggableOrderItem.propTypes = {
  id: PropTypes.string,
  item: PropTypes.object,
  index: PropTypes.number,
  updateItem: PropTypes.func,
  deleteItem: PropTypes.func,
  itemsLength: PropTypes.number,
  answerable: PropTypes.bool,
  restricted: PropTypes.bool,
};

export default DraggableOrderItem;
