import { createUseStyles } from 'react-jss';

export default createUseStyles({
  totalPonderations: {
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '18px',
  },
  totalPonderationsHeader: {
    fontWeight: 600,
  },
  totalPonderationsWeights: {
    marginTop: '5px',
  },
});
