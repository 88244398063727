import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
    marginLeft: '10px',
    '&.noBack': {
      marginLeft: 'unset',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  btn: {
    marginLeft: '6px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    header: {
      flexWrap: 'wrap',
      margin: 'unset',
    },
  },
}));
