import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'actions/userActions';
import { shuffleArr } from 'utils';

import ExerciseChoice from 'components/exercises/exercise/exercise-answer/ExerciseChoice';

import useStyles from './styles';

const ExerciseAnswerChoices = ({ exerciseId, choices, answer, onAnswer, answerable, hasMultipleCorrectChoices, isCompact, shuffleChoices }) => {
  const classes = useStyles();
  const isAdmin = useSelector(selectUserIsAdmin);

  if (shuffleChoices) {
    shuffleArr(choices);
  }

  const onCheck = useCallback((choiceId) => {
    if (!onAnswer) {
      return;
    }

    if (hasMultipleCorrectChoices) {
      const choiceChecked = answer?.find(checkedChoiceId => checkedChoiceId === choiceId);

      if (choiceChecked) {
        const selected = answer.filter(checkedChoiceId => checkedChoiceId !== choiceId);
        onAnswer(selected);
      } else {
        const selected = [...(answer ?? []), choiceId];
        onAnswer(selected);
      }
    } else {
      onAnswer(choiceId === answer ? null : choiceId);
    }
  }, [onAnswer, hasMultipleCorrectChoices, answer]);

  return (
    <div className={cx(classes.compactedChoices, { isCompact })}>
      {choices.map(choice => {
        let checked;
        if (hasMultipleCorrectChoices && answer) {
          checked = answer.filter(exerciseChoiceId => exerciseChoiceId === choice.id).length > 0;
        } else {
          checked = answer === choice.id;
        }

        const choiceComponent = (
          <ExerciseChoice
            key={choice.id}
            name={`${exerciseId}-${choice.id}`}
            label={choice.value}
            value={choice.id}
            checked={checked}
            onCheck={onCheck}
            correct={!isAdmin && choice.isCorrect}
            identifier={choice.identifier}
            disabled={!answerable}
            hasMultipleCorrect={hasMultipleCorrectChoices}
          />
        );

        if (isCompact) {
          return (
            <div key={choice.id} className={classes.choice}>
              {choiceComponent}
            </div>
          );
        }

        return choiceComponent;

      })}
    </div>
  );
};

ExerciseAnswerChoices.propTypes = {
  exerciseId: PropTypes.string,
  choices: PropTypes.array,
  answer: PropTypes.any,
  onAnswer: PropTypes.func,
  answerable: PropTypes.bool,
  hasMultipleCorrectChoices: PropTypes.bool,
  isCompact: PropTypes.bool,
  shuffleChoices: PropTypes.bool,
};

export default ExerciseAnswerChoices;
