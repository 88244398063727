import React from 'react';
import { Toggle } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import FlowStep from '../FlowStep';
import SymbolsButtons from 'components/common/rich-text/SymbolsButtons';

import useStyles from './styles';

const AskForJustification = ({ number, hasJustification, setHasJustification, enableMathSymbols, setEnableMathSymbols, mathSymbols, setMathSymbols, setModelAnswer, hasWordCount, setHasWordCount }) => {
  const classes = useStyles();
  const wordCountToggle = useFeature(toggles.wordCount);

  const onChangeJustification = (checked) => {
    setHasJustification(checked);

    if (!checked) {
      setModelAnswer(null);
    }
  };

  return (
    <FlowStep
      stepNumber={number}
      header={lang.exerciseForm.justificationLabel}
    >
      <div
        className={classes.wrapper}
      >
        <div className={classes.toggleWrapper}>
          <div
            className={classes.label}
          >
            {lang.exerciseForm.justificationText}
          </div>

          <Toggle
            className={classes.toggle}
            checked={hasJustification}
            onChange={onChangeJustification}
          />
        </div>
        {hasJustification && (
          <>
            {wordCountToggle && (
              <div className={classes.toggleWrapper}>
                {lang.exerciseForm.wordCountLabel}
                <Toggle
                  checked={hasWordCount}
                  onChange={(checked) => setHasWordCount(checked)}
                />
              </div>
            )}
            <div className={classes.toggleWrapper}>
              {lang.exerciseForm.useMathSymbols}
              <Toggle
                checked={enableMathSymbols}
                onChange={(checked) => setEnableMathSymbols(checked)}
              />
            </div>
            {enableMathSymbols && (
              <div className={classes.toggleWrapper}>
                <SymbolsButtons
                  mathSymbols={mathSymbols}
                  setMathSymbols={setMathSymbols}
                />
              </div>
            )}
          </>
        )}
      </div>
    </FlowStep>
  );
};

AskForJustification.propTypes = {
  number: PropTypes.number,
  hasJustification: PropTypes.bool,
  setHasJustification: PropTypes.func,
  enableMathSymbols: PropTypes.bool,
  setEnableMathSymbols: PropTypes.func,
  mathSymbols: PropTypes.bool,
  setMathSymbols: PropTypes.func,
  setModelAnswer: PropTypes.func,
  hasWordCount: PropTypes.bool,
  setHasWordCount: PropTypes.func,
};

export default AskForJustification;
