import React, { useState, Fragment } from 'react';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectTestItems, selectTestName } from 'actions/testActions';
import { selectSchoolName, selectUserFeatureToggleMaxUsages, selectUserLang } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useForm from 'hooks/useForm';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { getNowDatetimeString } from 'utils/datetime';

import AnswerZoneSettings from '../answer-zone-settings/AnswerZoneSettings';
import AssessmentExportModal from '../AssessmentExportModal';
import GeneralSettings from '../GeneralSettings';
import HeaderSettings from '../header-settings/HeaderSettings';
import TabHeader from '../TabHeader';

import { DOCUMENT_DOWNLOAD, DOCUMENT_GENERATION, DOCUMENT_IDLE } from './constants';

const PhysicalTab = () => {
  const toast = useToast();
  const stateLocale = useSelector(selectUserLang);
  const { testId, spaceId } = useParams();
  const testName = useSelector(selectTestName);
  const items = useSelector(selectTestItems);
  const schoolName = useSelector(selectSchoolName(spaceId));
  const [exportTestRequest] = useApi(api.exportTest);
  const exportVersionsMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.exportVersions));

  const getSettingsForm = () => {
    const inputs = [
      { label: 'versions', initialValue: 1 },
      { label: 'locale', initialValue: { label: lang[stateLocale], value: stateLocale } },
      { label: 'shuffleType', initialValue: { value: 'none', label: lang.test.publishModal.shuffleNoneLabel } },
      { label: 'includeRubric', initialValue: false },

      { label: 'schoolLogo', initialValue: { value: '', enabled: false } },
      { label: 'schoolName', initialValue: { value: spaceId === 'p' ? '' : schoolName, enabled: spaceId !== 'p' } },
      { label: 'class', initialValue: { value: '', studentInput: true, enabled: true } },
      { label: 'subject', initialValue: { value: '', enabled: false } },
      { label: 'testName', initialValue: { value: testName, enabled: true } },
      { label: 'displayVersion', initialValue: false },
      { label: 'schoolYear', initialValue: { value: '', studentInput: true, enabled: true } },
      { label: 'date', initialValue: { value: getNowDatetimeString('date'), studentInput: true, enabled: true } },
      { label: 'testDuration', initialValue: { value: 10, enabled: false } },
      { label: 'askForName', initialValue: true },
      { label: 'askForCode', initialValue: false },
      { label: 'askForGrade', initialValue: true },
      { label: 'customFields', initialValue: [] },
    ];

    items.forEach(item => {
      if (item.itemType === 'section' && item.exercises) {
        item.exercises.forEach(exercise => {
          inputs.push({ label: exercise.testExerciseId, initialValue: { value: 5, enabled: true, showLines: true } });
        });
      } else if (item.itemType === 'exercise') {
        inputs.push({ label: item.testExerciseId, initialValue: { value: 5, enabled: true, showLines: true } });
      }
    });

    return inputs;
  };

  const [getValue, setValue, getErrors, setErrors] = useForm(getSettingsForm());

  const [documentModal, setDocumentModal] = useState(false);
  const [documentState, setDocumentState] = useState(DOCUMENT_IDLE);
  const [documentURL, setDocumentURL] = useState(null);

  const getClearCustomFieldErrors = () => {
    const customFieldsNumber = getValue('customFields').length;
    const newCustomFieldErrors = [];
    for (let i = 0; i < customFieldsNumber; i++) {
      newCustomFieldErrors.push({ label: [], content: [] });
    }
    return newCustomFieldErrors;
  };

  const validateGeneralSettings = () => {
    let valid = true;
    const versionsErrors = [];

    if (getValue('versions') <= 0) {
      versionsErrors.push(lang.test.physicalVersion.versionError);
      valid = false;
    }

    if (!valid) {
      setTimeout(() => {
        setErrors('versions', versionsErrors);
      }, 350);
    }

    return valid;
  };

  const validateHeaderSettings = () => {
    let valid = true;
    const testNameErrors = [];
    const schoolNameErrors = [];
    const schoolLogoErrors = [];
    const subjectErrors = [];
    const schoolYearErrors = [];
    const classErrors = [];
    const dateErrors = [];
    const testDurationErrors = [];

    const schoolLogo = getValue('schoolLogo');
    if (schoolLogo.enabled && schoolLogo.value.length === 0) {
      schoolLogoErrors.push(lang.test.physicalVersion.schoolLogoEmptyError);
      valid = false;
    }

    const schoolName = getValue('schoolName');
    if (schoolName.enabled && schoolName.value.length === 0) {
      schoolNameErrors.push(lang.test.physicalVersion.schoolNameEmptyError);
      valid = false;
    }

    const _class = getValue('class');
    if (_class.enabled && !_class.studentInput && _class.value.length === 0) {
      classErrors.push(lang.test.physicalVersion.classEmptyError);
      valid = false;
    }

    const subject = getValue('subject');
    if (subject.enabled && subject.value.length === 0) {
      subjectErrors.push(lang.test.physicalVersion.subjectEmptyError);
      valid = false;
    }

    const testName = getValue('testName');
    if (testName.enabled && testName.value.length === 0) {
      testNameErrors.push(lang.test.physicalVersion.testNameEmptyError);
      valid = false;
    }

    const schoolYear = getValue('schoolYear');
    if (schoolYear.enabled && !schoolYear.studentInput && schoolYear.value.length === 0) {
      schoolYearErrors.push(lang.test.physicalVersion.schoolYearEmptyError);
      valid = false;
    }

    const date = getValue('date');
    if (date.enabled && !date.studentInput && date.value === null) {
      dateErrors.push(lang.test.physicalVersion.dateEmptyError);
      valid = false;
    }

    const testDuration = getValue('testDuration');
    if (testDuration.enabled && !testDuration.value && testDuration.value !== 0) {
      testDurationErrors.push(lang.test.physicalVersion.testDurationEmptyError);
      valid = false;
    }

    const customFields = getValue('customFields');
    const customFieldErrors = getClearCustomFieldErrors();
    customFields.forEach((field, index) => {
      if (field.label.length === 0) {
        customFieldErrors[index].label = [lang.test.physicalVersion.customFieldEmptyLabelError];
        valid = false;
      }

      if (!field.studentInput && field.content.length === 0) {
        customFieldErrors[index].content = [lang.test.physicalVersion.customFieldEmptyContentError];
        valid = false;
      }
    });

    if (!valid) {
      setTimeout(() => {
        setErrors('testName', testNameErrors);
        setErrors('schoolName', schoolNameErrors);
        setErrors('schoolLogo', schoolLogoErrors);
        setErrors('subject', subjectErrors);
        setErrors('schoolYear', schoolYearErrors);
        setErrors('class', classErrors);
        setErrors('date', dateErrors);
        setErrors('testDuration', testDurationErrors);
        setErrors('customFields', customFieldErrors);
      }, 350);
    }

    return valid;
  };

  const handleExport = () => {
    let valid = true;

    valid = validateGeneralSettings();
    valid &= validateHeaderSettings();

    if (!valid) {
      return;
    }

    setErrors('testName', []);
    setErrors('schoolName', []);
    setErrors('schoolLogo', []);
    setErrors('subject', []);
    setErrors('schoolYear', []);
    setErrors('class', []);
    setErrors('date', []);
    setErrors('testDuration', []);
    setErrors('customFields', getClearCustomFieldErrors());
    const exercisesSettings = [];
    items.forEach(item => {
      if (item.itemType === 'section' && item.exercises) {
        item.exercises.forEach(exercise => {
          exercisesSettings.push({ id: exercise.testExerciseId, value: getValue(exercise.testExerciseId) });
        });
      } else if (item.itemType === 'exercise') {
        exercisesSettings.push({ id: item.testExerciseId, value: getValue(item.testExerciseId) });
      }
    });

    if (exportVersionsMaxUsages && getValue('versions') > exportVersionsMaxUsages) {
      toast.warning(lang.plans.warningExportTestMaxUsages(exportVersionsMaxUsages));
      return;
    }

    const testDurationInput = getValue('testDuration');

    setDocumentState(DOCUMENT_GENERATION);
    setDocumentModal(true);
    exportTestRequest([testId], {
      generalSettings: {
        versions: getValue('versions'),
        locale: getValue('locale').value,
        shuffleType: getValue('shuffleType').value,
        includeRubric: getValue('includeRubric'),
      },
      headerSettings: {
        testName: getValue('testName'),
        schoolName: getValue('schoolName'),
        schoolLogo: getValue('schoolLogo'),
        subject: getValue('subject'),
        schoolYear: getValue('schoolYear'),
        'class': getValue('class'),
        date: getValue('date'),
        testDuration: { ...testDurationInput, value: testDurationInput.value ? testDurationInput.value : 0 },
        displayVersion: getValue('displayVersion'),
        askForName: getValue('askForName'),
        askForCode: getValue('askForCode'),
        askForGrade: getValue('askForGrade'),
        customFields: getValue('customFields'),
      },
      exercisesSettings,
    }, ({ data }) => {
      if (data.status === 0) {
        toast.text(lang.waitTime);

        const intervalId = setInterval(() => {
          Axios.head(routes.s3.ref(data.path)).then((res) => {
            if (res.status !== 403 && res.status !== 404) {
              clearInterval(intervalId);
              setDocumentURL(data.path);
              setDocumentState(DOCUMENT_DOWNLOAD);
              setErrors('versions', []);
            }
          }).catch((err) => {
            if (err.response.status !== 403 && err.response.status !== 404) {
              clearInterval(intervalId);
              setDocumentState(DOCUMENT_IDLE);
              setDocumentModal(false);
              toast.error(lang.oops, 'error');
            }
          });
        }, 1000);

        return;
      }

      if (data.status === 2) {
        setTimeout(() => {
          toast.error(lang.test.physicalVersion.noExercises);
          setDocumentState(DOCUMENT_IDLE);
          setDocumentModal(false);
        }, 350);
        return;
      }

      if (data.status === 3) {
        setTimeout(() => {
          setErrors('versions', [lang.test.physicalVersion.notEnoughExercises]);
          setDocumentState(DOCUMENT_IDLE);
          setDocumentModal(false);
        }, 350);
        return;
      }

      if (data.status === 4) {
        setTimeout(() => {
          toast.error(lang.test.physicalVersion.noRubric);
          setDocumentState(DOCUMENT_IDLE);
          setDocumentModal(false);
        }, 350);
        return;
      }

      setTimeout(() => {
        toast.error(lang.oops);
        setDocumentState(DOCUMENT_IDLE);
        setDocumentModal(false);
      }, 350);
    });
  };

  const exerciseNeedsSettings = (exercise) => {
    return exercise.type === 'text'
    || exercise.type === 'image'
    || (exercise.type === 'choices' && exercise.hasJustification)
    || (exercise.type === 'true-false' && exercise.hasJustification);
  };

  return (
    <Fragment>
      <AssessmentExportModal
        open={documentModal}
        close={() => setDocumentModal(false)}
        documentURL={documentURL}
        documentState={documentState}
        setDocumentState={setDocumentState}
      />
      <TabHeader
        handleExport={handleExport}
      />
      <GeneralSettings
        getValue={getValue}
        setValue={setValue}
        getErrors={getErrors}
      />
      <HeaderSettings
        getValue={getValue}
        setValue={setValue}
        getErrors={getErrors}
        setErrors={setErrors}
      />
      <Render when={items.some(item => exerciseNeedsSettings(item) ||
      (item.itemType === 'section' && item.exercises?.some(exercise => exerciseNeedsSettings(exercise))))}
      >
        <AnswerZoneSettings
          getValue={getValue}
          setValue={setValue}
          getErrors={getErrors}
        />
      </Render>
    </Fragment>
  );
};

export default PhysicalTab;
