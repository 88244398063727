
import React, { forwardRef } from 'react';
import { faCheck, faGripVertical, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'actions/userActions';
import useFeature from 'hooks/useFeature';
import toggles from 'toggles';
import { renderGap } from 'utils';

import useStyles from './styles';

const InnerGap = forwardRef(({ ...props }, ref) => {
  const { item, draggable, isCorrection, isDragging, identifier } = props;
  const classes = useStyles();
  const identifiersToggle = useFeature(toggles.exportIdentifiers);
  const isAdmin = useSelector(selectUserIsAdmin);

  return (
    <div
      className={cx(classes.gap,
        {
          draggable: draggable,
          isCorrect: !isAdmin && item.isCorrectAnswer,
          isWrong: item.answer ? !isAdmin && !item.isCorrectAnswer : null,
          isCorrection: isCorrection,
        })}
      style={{
        cursor: draggable ? 'grabbing' : undefined,
        transform: draggable ? 'scale3d(1.15, 1.15, 1)' : undefined,
        boxShadow: draggable && isDragging ? '2px 2px 2px #00000066' : undefined,
      }}
      {...props}
      ref={ref}
    >
      {draggable ? <FontAwesomeIcon className={classes.handle} icon={faGripVertical} /> : null}
      <div className={cx(classes.gapInfo, { isDragging })}>
        <div
          dangerouslySetInnerHTML={{ __html: renderGap(item) }}
        />
      </div>
      <Render when={identifiersToggle && identifier}>
        <div className={classes.identifier}>
          (
          {identifier}
          )
        </div>
      </Render>
      <Render when={!isAdmin && item.answer && !isCorrection}>
        {item.isCorrectAnswer ?
          <FontAwesomeIcon className={classes.check} icon={faCheck} />
          :
          <FontAwesomeIcon className={classes.wrong} icon={faXmark} />
        }
      </Render>
    </div>
  );

});

InnerGap.propTypes = {
  isDragging: PropTypes.bool,
  item: PropTypes.object,
  draggable: PropTypes.bool,
  isCorrection: PropTypes.bool,
  identifier: PropTypes.string,
};

InnerGap.displayName = 'InnerGap';

export default InnerGap;
