import React, { useCallback, useRef, useState } from 'react';
import { DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { createSnapModifier } from '@dnd-kit/modifiers';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import lang from 'lang';
import { restrictToBoundingRect } from 'utils';

import Caption from '../../Caption';
import InnerCaption from '../../InnerCaption';
import FlowStep from '../FlowStep';

import useStyles from './styles';

const AskForCaptions = ({ number, image, gaps, setGaps, restricted }) => {
  const classes = useStyles();

  const [active, setActive] = useState(null);
  const [beginCoords, setBeginCoords] = useState(null);
  const [endCoords, setEndCoords] = useState(null);
  const [scrollValue, setScrollValue] = useState(0);
  const [verticalScrollValue, setVerticalScrollValue] = useState(0);

  const imageRef = useRef();
  const ref = useRef();

  const removeCaption = useCallback((captionId) => {
    setGaps(gaps => {
      const gapToDelete = gaps.find(el => el.id === captionId);
      return gaps.filter(gap => (gap.id !== captionId && (gap.gapCoords?.x !== gapToDelete.gapCoords.x && gap.gapCoords?.y !== gapToDelete.gapCoords.y)));
    });
  }, [setGaps]);

  const editCaption = useCallback((gapId, value) => {
    setGaps(gaps => {
      const newGaps = [...gaps];
      const gapToEdit = newGaps.find(el => el.id === gapId);
      gapToEdit.text = value;
      return newGaps;
    });
  }, [setGaps]);

  const computeLine = (beginCoords, endCoords) => {

    if (!endCoords) {
      endCoords = beginCoords;
    }

    const imageSideSize = 700;

    const x1 = (beginCoords.x / 100) * imageSideSize, y1 = (beginCoords.y / 100) * imageSideSize;

    const x2 = (endCoords.x / 100) * imageSideSize, y2 = (endCoords.y / 100) * imageSideSize;

    const a = x1 - x2,
      b = y1 - y2,
      length = Math.sqrt(a * a + b * b);

    const sx = (x1 + x2) / 2,
      sy = (y1 + y2) / 2;

    const x = sx - length / 2,
      y = sy;

    const alpha = Math.PI - Math.atan2(-b, a);

    const style = {
      position: 'absolute',
      border: '1px solid black',
      width: length + 'px',
      height: '0px',
      transform: 'rotate(' + alpha + 'rad)',
      top: y + 'px',
      left: x + 'px',
      backgroundColor: 'black',
    };

    const pointStyle = {
      backgroundColor: 'black',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      position: 'absolute',
      top: y2 - 4 + 'px',
      left: x2 - 4 + 'px',
    };

    const gapStyle = {
      position: 'absolute',
      top: y1 - 19 + 'px',
      left: x1 - 52 + 'px',
    };

    return {
      style,
      pointStyle,
      gapStyle,
    };
  };

  const drawAuxiliarLine = useCallback(() => {

    if (!beginCoords || !endCoords) {
      return null;
    }

    const { style, pointStyle } = computeLine(beginCoords, endCoords);

    return (
      <>
        <div style={style} />
        <div style={pointStyle} />
      </>
    );

  }, [beginCoords, endCoords]);

  const getCaptions = useCallback(() => {
    return gaps.filter(el => el.isCorrect).map(gap => {
      if (!gap.gapCoords && !gap.pointCoords) {
        return null;
      }
      const { style, pointStyle, gapStyle } = computeLine(gap.gapCoords, gap.pointCoords);
      const lineStyle = {
        ...style,
        display: gap.id === active ? 'none' : 'initial',
      };
      const pointStyleNew = {
        ...pointStyle,
        display: gap.id === active ? 'none' : 'initial',
      };
      return (
        <>
          <div style={lineStyle} />
          <div style={pointStyleNew} />
          <Caption
            key={gap.id}
            caption={gap}
            removeCaption={() => removeCaption(gap.id)}
            editCaption={editCaption}
            gap={gap}
            styles={gapStyle}
            restricted={restricted}
          />
        </>
      );
    });

  }, [gaps, active, editCaption, restricted, removeCaption]);

  const roundToGridIncrement = (num) => {
    return Math.ceil(num / 10) * 10;
  };

  const onClick = (event) => {

    if (!event.target.classList.toString().includes('imageWrapper')) {
      return;
    }

    if (active) {
      setActive(null);
      return;
    }
    const rect = imageRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const imageSideSize = 700, captionWidth = 105, captionHeight = 38;

    let xWithOffset = x, yWithOffset = y;

    if (x + (captionWidth / 2) > imageSideSize) {
      xWithOffset = x - captionWidth;
    } else if (x - (captionWidth / 2) < 0) {
      xWithOffset = x + captionWidth;
    }

    if (y + (captionHeight / 2) > imageSideSize) {
      yWithOffset = y - captionHeight;
    } else if (y - (captionHeight / 2) < 0) {
      yWithOffset = y + captionHeight;
    }

    const caption = {
      id: v4(),
      text: '',
      gapCoords: {
        x: roundToGridIncrement(xWithOffset) * 100 / imageSideSize,
        y: roundToGridIncrement(yWithOffset) * 100 / imageSideSize,
      },
      pointCoords: {
        x: roundToGridIncrement(x) * 100 / imageSideSize,
        y: roundToGridIncrement(y) * 100 / imageSideSize,
      },
      isCorrect: true,
      identifier: `caption_${gaps.length + 1}`,
    };

    setGaps(gaps => {
      const newGaps = [...gaps];
      newGaps.push(caption);
      return newGaps;
    });
  };

  const activationConstraint = {
    distance: 10,
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint,
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint,
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  const computeCoords = (coords, delta) => {

    if (!coords) {
      return undefined;
    }

    const imageSideSize = 700;
    const halfGapWidth = 65;
    const halfGapHeight = 20;

    let x = coords.x * imageSideSize / 100, y = coords.y * imageSideSize / 100;

    const deltaX = delta.x - ref.current.scrollLeft;
    const deltaY = delta.y - document.documentElement.scrollTop;

    x += deltaX;
    y += deltaY;

    x -= scrollValue - ref.current.scrollLeft;
    y -= verticalScrollValue - document.documentElement.scrollTop;

    if (x >= imageSideSize) {
      x = imageSideSize - halfGapWidth;
    }

    if (x <= 0) {
      x = halfGapWidth;
    }

    if (y >= imageSideSize) {
      y = imageSideSize - halfGapHeight;
    }

    if (y <= 0) {
      y = halfGapHeight;
    }

    x = x * 100 / imageSideSize;
    y = y * 100 / imageSideSize;

    return { x, y };
  };

  const onDragEnd = (event) => {
    const { delta, active } = event;
    setScrollValue(ref.current.scrollLeft);
    setVerticalScrollValue(document.documentElement.scrollTop);

    if (!beginCoords && !endCoords) {
      return;
    }

    setBeginCoords(null);
    setEndCoords(null);
    setActive(null);

    const copyGaps = [...gaps];
    const gapToMove = copyGaps.find(el => el.id === active.id);

    if (!gapToMove.pointCoords) {
      gapToMove.pointCoords = gapToMove.gapCoords;
    }

    const newCoords = computeCoords(gapToMove.gapCoords, delta);

    copyGaps.forEach(gap => {
      if (gap.pointCoords?.x === gapToMove.pointCoords?.x && gap.pointCoords?.y === gapToMove.pointCoords?.y) {
        gap.gapCoords = newCoords;
      }
    });

    setGaps(copyGaps);
  };

  const onDragMove = ({ active, delta }) => {
    const gapToMove = gaps.find(el => el.id === active.id);
    const endCoordsTemp = computeCoords(gapToMove.gapCoords ?? beginCoords, delta);
    setBeginCoords(endCoordsTemp);
  };

  const onDragStart = ({ active }) => {

    setScrollValue(ref.current.scrollLeft !== 0 ? ref.current.scrollLeft : 0);
    setVerticalScrollValue(document.documentElement.scrollTop !== 0 ? document.documentElement.scrollTop : 0);
    const gapToMove = gaps.find(el => el.id === active.id);

    setActive(active.id);

    if (gapToMove.pointCoords) {
      setBeginCoords(gapToMove.gapCoords);
      setEndCoords(gapToMove.pointCoords);
    } else {
      setBeginCoords(gapToMove.pointCoords);
    }
  };

  const onDragCancel = () => {
    setBeginCoords(null);
    setEndCoords(null);
  };

  const restrictToElement = ({ draggingNodeRect, transform }) => {

    if (!imageRef.current) {
      return null;
    }

    const boundingRect = imageRef.current.getBoundingClientRect();
    const scrollableOffset = ref.current.scrollLeft;

    if (!draggingNodeRect || !boundingRect) {
      return transform;
    }

    return restrictToBoundingRect(transform, draggingNodeRect, boundingRect, scrollableOffset);
  };

  const snapToGridModifier = createSnapModifier(10);

  return (
    <FlowStep
      stepNumber={number}
      header={restricted ? lang.exerciseForm.editCaptions : lang.exerciseForm.createCaptions}
      subHeader={restricted ? lang.exerciseForm.editCaptionsDescription : lang.exerciseForm.createCaptionsDescription}
    >
      {restricted ? (
        <div className={classes.wrapper} ref={ref}>
          <div className={classes.imageWrapper} style={{ backgroundImage: `url(${image})` }} ref={imageRef}>
            {getCaptions()}
            {drawAuxiliarLine()}
          </div>
        </div>
      ) : (
        <DndContext
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragMove={onDragMove}
          onDragCancel={onDragCancel}
          sensors={sensors}
          modifiers={[snapToGridModifier]}
        >
          <div className={classes.wrapper} ref={ref}>
            <div className={classes.imageWrapper} onClick={onClick} style={{ backgroundImage: `url(${image})` }} ref={imageRef}>
              {getCaptions()}
              {drawAuxiliarLine()}
              <DragOverlay
                modifiers={[restrictToElement]}
              >
                {active ? <InnerCaption gap={gaps.find(el => el.id === active)} /> : null}
              </DragOverlay>
            </div>
          </div>
        </DndContext>
      )}
    </FlowStep>
  );
};

AskForCaptions.propTypes = {
  number: PropTypes.number,
  image: PropTypes.array,
  gaps: PropTypes.array,
  setGaps: PropTypes.func,
  restricted: PropTypes.bool,
};

export default AskForCaptions;
