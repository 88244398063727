import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useTheme } from 'theming';

import useStyles from './styles.js';

const FilterPoolItem = ({ poolItem, itemAction, selectable }) => {
  const classes = useStyles();
  const theme = useTheme();

  const onClick = () => {
    if (itemAction) {
      itemAction(poolItem);
    }
  };

  if (poolItem.type === 'label') {
    return (
      <div className={classes.labelItem}>
        {poolItem.name}
      </div>
    );
  }

  if (poolItem.type === 'action') {
    return (
      <motion.div
        id={poolItem.id}
        whileHover={{ backgroundColor: poolItem.hoverColor || theme.defaultLabelHoverColor, transition: { duration: 0.1 } }}
        className={cx(classes.item, classes.action)}
        style={{ backgroundColor: poolItem.color || theme.defaultLabelColor }}
        onClick={poolItem.action}
        {...poolItem.extraProps}
      >
        <div className={classes.itemName}>
          {poolItem.name}
        </div>
        <Render when={poolItem.icon}>
          <FontAwesomeIcon
            icon={poolItem.icon}
            className={classes.actionIcon}
          />
        </Render>
      </motion.div>
    );
  }

  return (
    <div
      className={classes.item}
      style={{ backgroundColor: poolItem.color ? poolItem.color : theme.defaultLabelColor }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={classes.itemName}>
        {poolItem.name}
      </div>
      <Render when={!selectable && !poolItem.disabled}>
        <div
          className={classes.remove}
          onClick={onClick}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </Render>
    </div>
  );
};

FilterPoolItem.propTypes = {
  poolItem: PropTypes.object.isRequired,
  itemAction: PropTypes.func,
  selectable: PropTypes.bool,
};
export default FilterPoolItem;
