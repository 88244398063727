import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  extraGap: {
    color: theme.textColor5,
    display: 'flex',
    gap: '0.5em',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.buttonBackground3,
    border: `1px solid ${theme.buttonBorder3}`,
    borderRadius: '10px',
    padding: '10px',
    width: 'auto',
    transition: 'background-color 100ms',
    fontSize: '12px',
    height: '18px',
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: theme.buttonBackground3,
    },
    '&.isCorrect': {
      border: `2px solid ${theme.successColor}`,
    },
    '&.restricted': {
      height: 'auto',
    },
  },
  caption: {
    width: '100%',
  },
  remove: {
    alignItems: 'center',
    display: 'flex',
    right: '0',
    height: '100%',
    borderRadius: '10px',
    cursor: 'pointer',
    opacity: '0.6',
    '&:hover': {
      opacity: '1',
    },
  },
}));
