import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  flagButton: {
    color: '#fe7c03 !important',
    borderColor: '#fe7c03 !important',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#fe7c0333 !important',
    },
    '& svg': {
      color: '#fe7c03 !important',
    },
    '&:hover .flag-slash': {
      display: 'block',
    },
  },
  flagSlash: {
    position: 'absolute',
    display: 'none',
  },
}));
