import { createUseStyles } from 'react-jss';

export default createUseStyles({
  statement: {
    alignItems: 'center',
    gap: '2px',
    wordBreak: 'break-word',
    marginBottom: '10px',
    '& p': {
      marginTop: '0',
      marginBottom: '0',
      lineHeight: '25px',
      '& .katex .base': {
        position: 'relative',
        whiteSpace: 'normal',
        display: 'inline',
      },
    },
  },
});
