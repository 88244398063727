import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import useStyles from './styles';

const EntityInformation = ({ informations }) => {
  const classes = useStyles();

  return (
    <div className={classes.testInfoWrap}>
      {informations.map((info, index) => (
        <Render
          key={info.label}
          when={!info.hide}
        >
          <div className={classes.iconContainer}>
            <FontAwesomeIcon
              icon={info.icon}
            />
          </div>
          <div className={classes.testInfo}>
            {info.label}
          </div>
          <Render when={index !== informations.filter(el => !el.hide).length - 1}>
            <div className={classes.testInfoSeparator}>
              •
            </div>
          </Render>
        </Render>
      ))}
    </div>
  );
};

EntityInformation.propTypes = {
  informations: PropTypes.array,
};

export default EntityInformation;
