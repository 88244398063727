import React, { Fragment } from 'react';
import { faDatabase, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectTestIsPublished, selectTestRubric } from 'actions/testActions';
import lang from 'lang';

import Button from 'components/common/Button';
import PreviewableRubric from 'components/rubrics/PreviewableRubric';
import { CHOOSING, EDIT_RUBRIC } from 'components/test/tabs/RubricTab/addRubricOptions';

import useStyles from './styles';

const DisplayRubric = ({ setFrom }) => {
  const classes = useStyles();
  const rubric = useSelector(selectTestRubric);
  const isPublished = useSelector(selectTestIsPublished);

  return (
    <Fragment>
      <Render when={!isPublished}>
        <Button
          className={classes.changeRubricButton}
          onClick={() => setFrom(CHOOSING)}
          medium
          gray
        >
          <FontAwesomeIcon
            icon={faDatabase}
          />
          <div className={classes.changeRubricButtonText}>
            {lang.test.rubric.changeRubric}
          </div>
        </Button>
        <Button
          className={classes.changeRubricButton}
          onClick={() => setFrom(EDIT_RUBRIC)}
          medium
          gray
        >
          <FontAwesomeIcon
            icon={faPen}
          />
          <div className={classes.changeRubricButtonText}>
            {lang.test.rubric.editRubric}
          </div>
        </Button>
      </Render>
      <PreviewableRubric
        rubric={rubric}
      />
    </Fragment>
  );
};

DisplayRubric.propTypes = {
  setFrom: PropTypes.func,
};

export default DisplayRubric;
