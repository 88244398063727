import React from 'react';
import Quill from 'quill';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'react-jss';
import { Provider } from 'react-redux';

import store from 'store';
import theme from 'theme';
import { parseQlFormula } from 'utils';

import EditableGap from '../EditableGap';

const Embed = Quill.import('blots/embed');

class GapBlot extends Embed {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('value', JSON.stringify(value));
    node.setAttribute('contenteditable', 'false');

    if (value.formula) {
      node.innerHTML = parseQlFormula(`<span class="ql-formula">${value.formula}</span>`);
    } else {
      node.innerText = value;
    }

    createRoot(node)
      .render(
        <Provider store={store}>
          <ThemeProvider theme={{ ...theme.general, ...theme['light'] }}>
            <EditableGap
              node={node}
            />
          </ThemeProvider>
        </Provider>,
      );

    return node;
  }

  deleteAt() {
    return false;
  }

  static formats(domNode) {
    return {
      position: domNode.getAttribute('position'),
    };
  }

  format(name, value) {
    if (name === 'position') {
      this.domNode.setAttribute('position', value);
    } else {
      super.format(name, value);
    }
  }

  static value(node) {
    return JSON.parse(node.getAttribute('value'));
  }
}

GapBlot.blotName = 'gap';
GapBlot.tagName = 'span';
GapBlot.className = 'ql-gap';

export default GapBlot;
