import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  changeRubricButton: {
    border: `1px solid ${theme.buttonBorder4}`,
    margin: '0 10px 10px 0',
  },
  changeRubricButtonText: {
    marginLeft: '8px',
  },
}));
