import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import api from 'api';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import useQuery from 'hooks/common/useQuery';
import lang from 'lang';
import { cleanAndSortGroups } from 'utils';

import RubricBank from 'components/rubrics/RubricBank';
import RubricsPageHeader from 'components/rubrics/RubricsPageHeader';

const Rubrics = () => {
  const { loaded } = usePageLogic(lang.rubrics.title, true);
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const { spaceId } = useParams();
  const [getSchoolGroupsRequest] = useApi(api.getSchoolGroups);
  const [getGroupsRequest] = useApi(api.getGroups);

  const [schoolGroups, setSchoolGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(null);
  const [selectedRubrics, setSelectedRubrics] = useState([]);

  const fetchGroups = useCallback(() => {
    const schoolId = spaceId === 'p' ? null : spaceId;
    getGroupsRequest([schoolId], null, ({ data }) => {
      if (data.status === 0) {
        const newGroups = cleanAndSortGroups(data.groups);
        setGroups(newGroups);
      }
    });
  }, [getGroupsRequest, spaceId]);

  const fetchSchoolGroups = useCallback(() => {
    getSchoolGroupsRequest([spaceId], null, ({ data }) => {
      if (data.status === 0) {
        loaded();

        const newGroups = cleanAndSortGroups(data.groups);

        setSchoolGroups(newGroups);

        const currentGroupId = query().get('group');
        const group = newGroups.find(group => group.id === currentGroupId);

        if (group) {
          setGroup(group);
        } else {
          setGroup(newGroups[0]);
          history.replace({
            search: `?group=${newGroups[0].id}`,
          });
        }
      }
    });
  }, [getSchoolGroupsRequest, loaded, query, history, spaceId]);

  useEffect(() => {
    fetchGroups();
    fetchSchoolGroups();
  }, [fetchGroups, fetchSchoolGroups]);

  useEffect(() => {
    const currentGroupId = query().get('group');
    const group = schoolGroups.find(group => group.id === currentGroupId);

    if (group) {
      setGroup(group);
    } else {
      setGroup(schoolGroups[0]);
    }
  }, [location, schoolGroups, query]);

  return (
    <Fragment>
      <RubricsPageHeader
        groups={schoolGroups}
        group={group}
        setGroup={setGroup}
        setSelectedRubrics={setSelectedRubrics}
      />
      <RubricBank
        groupId={group?.id}
        groups={groups}
        selected={selectedRubrics}
        setSelected={setSelectedRubrics}
      />
    </Fragment>
  );
};

export default Rubrics;
