import React from 'react';
import PropTypes from 'prop-types';

import UpgradePremium from '../UpgradePremium';
import Modal from 'components/common/Modal';

const UpgradePremiumModal = ({ open, close, message }) => (
  <Modal
    open={open}
    close={close}
    center
    transition
    large
  >
    <UpgradePremium
      message={message}
    />
  </Modal>
);

UpgradePremiumModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  message: PropTypes.string,
};

export default UpgradePremiumModal;
