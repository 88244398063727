import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import useStyles from './styles';

const FlowStep = ({ stepNumber, subStepNumber, header, subHeader, children, dataTour }) => {
  const classes = useStyles();

  return (
    <div data-tour={dataTour} className={classes.flowStepCard}>
      <div className={classes.flowStepCardHeader}>
        <div className={classes.headerWrapper}>
          <Render when={stepNumber}>
            {stepNumber}
            {'.'}
          </Render>
          <Render when={subStepNumber}>
            {subStepNumber}
          </Render>
          {' '}
          {header}
        </div>
        <Render when={subHeader}>
          <div className={classes.flowStepCardSubHeader}>
            {subHeader}
          </div>
        </Render>
      </div>
      {children}
    </div>
  );
};

FlowStep.propTypes = {
  stepNumber: PropTypes.number,
  subStepNumber: PropTypes.number,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  dataTour: PropTypes.string,
  children: PropTypes.any,
};

export default FlowStep;
