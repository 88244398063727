import React, { useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Spacer } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserIsSuperadmin } from 'actions/userActions';
import lang from 'lang';

import GroupCard from '../GroupCard';
import EntityActions from 'components/common/entity/EntityActions';
import NoEntity from 'components/common/NoEntity';
import DeleteGroupsModal from 'components/group/DeleteGroupsModal';

import useStyles from './styles';

const GroupList = ({ groups, setCreateGroupModal, reload }) => {
  const classes = useStyles();
  const isSuperadmin = useSelector(selectUserIsSuperadmin);

  const [selected, setSelected] = useState([]);
  const [deleteGroupsModal, setDeleteGroupsModal] = useState(false);

  const getGroups = () => {
    if (groups.length === 0) {
      return (
        <NoEntity
          message={lang.groups.noGroups}
          action={() => setCreateGroupModal(true)}
        />
      );
    }

    return groups.map((group, index) => (
      <GroupCard
        key={group.id}
        dataTour={`group-card-${index}`}
        group={group}
        reload={reload}
        setSelected={setSelected}
        isSelected={!!selected.find(selected => selected.id === group.id)}
      />
    ));
  };

  const actions = [
    {
      icon: faTrash,
      label: lang.delete,
      onClick: () => setDeleteGroupsModal(true),
    },
  ];

  return (
    <div className={classes.groupsPage}>
      <Render when={isSuperadmin && groups.length > 0}>
        <DeleteGroupsModal
          open={deleteGroupsModal}
          close={() => setDeleteGroupsModal(false)}
          groupIds={selected.map(selected => selected.id)}
          reload={reload}
        />
        <EntityActions
          actions={actions}
          entities={groups}
          selected={selected}
          setSelected={setSelected}
        />
        <Spacer px={20} />
      </Render>
      <div className={classes.groupsWrapper}>
        {getGroups()}
      </div>
    </div>
  );
};

GroupList.propTypes = {
  groups: PropTypes.array,
  setCreateGroupModal: PropTypes.func,
  reload: PropTypes.func,
};

export default GroupList;
