import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

class AudioBlot extends BlockEmbed {
  static create(src) {
    const node = super.create(src);
    node.setAttribute('src', src);
    node.setAttribute('controls', true);
    return node;
  }

  static value(node) {
    return node.getAttribute('src');
  }
}

AudioBlot.blotName = 'audio';
AudioBlot.tagName = 'audio';
AudioBlot.className = 'ql-audio';

export default AudioBlot;
