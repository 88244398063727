import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  exerciseUnfocused: {
    opacity: '0.2',
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: '8px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  annotateIcon: {
    marginRight: '5px',
  },
  premiumPill: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  colorButton: {
    cursor: 'pointer',
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    minWidth: '0',
    padding: '0',
    '&.active': {
      height: '34px',
      width: '34px',
    },
  },
  colorsWrapper: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  commentBoxContainer: {
    position: 'absolute',
    width: '34%',
    top: '0',
    right: 'calc(-34% - 40px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '&.isInsideSection': {
      position: 'absolute',
      width: '34%',
      top: '0',
      right: 'calc(-34% - 60px)',
    },
    '&.isUpwards': {
      top: 'unset',
      bottom: '0',
    },
  },
  deleteAnnotationButton: {
    marginRight: 'auto',
  },
}));
