import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    bottom: '1rem',
    right: '1rem',
  },
  button: {
    padding: '8px',
    height: 'fit-content',
    backgroundColor: '#20A078 !important',
    color: 'white !important',
    '&:hover': {
      backgroundColor: '#1B8866 !important',
    },
  },
  shadowed: {
    boxShadow: `0px 4px 4px ${theme.shadowColor}`,
  },
  menu: {
    maxHeight: '20rem',
    width: '25em',
    overflowY: 'auto',
    zIndex: '50',
    border: `0px solid ${theme.inputBorder2} !important`,
    borderRadius: '10px',
    backgroundColor: `${theme.cardColor3} !important`,
  },
  separator: {
    borderBottom: `1px solid ${theme.textColor11}`,
    width: '100%',
  },
  menuHeader: {
    padding: '10px',
  },
  menuHeaderTitle: {
    color: theme.midnightGreen,
    margin: '6px 0',
    fontSize: '20px',
    fontWeight: 600,
  },
  menuHeaderParagraph: {
    color: theme.textColor,
    margin: '4px 0',
    fontSize: '12px',
  },
  menuItem: {
    color: '#565454', // sidebar color
    // backgroundColor: `${theme.inputBackground2} !important`,
    borderBottom: 'none !important',
    margin: '0 !important',
  },
  guideIcon: {
    fontSize: '24px',
  },
  guideSection: {
    display: 'grid',
    gridTemplateColumns: '32px auto 70px',
    justifyItems: 'center',
    alignItems: 'center',
    gap: '8px',
  },
  guideContent: {
    justifySelf: 'start',
  },
  guideTitle: {
    fontSize: '14px',
  },
  guideSubtitle: {
    fontSize: '12px',
    height: '27.59px', // fixed height for 2 lines of text
    color: theme.textColor4,
  },
}));
