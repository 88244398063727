import React, { useState } from 'react';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { quillIsEmpty } from 'utils';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import PlansPill from 'components/common/plans/PlansPill';
import QuillRenderer from 'components/common/QuillRenderer';

import useStyles from './styles';

const ExerciseFeedback = ({ feedback, onChange, onBlur, defining, dataTour }) => {
  const classes = useStyles();
  const feedbackToggle = useFeature(toggles.feedback);

  const [editorOpen, setEditorOpen] = useState(defining && feedback);

  if (!feedback && !defining) {
    return null;
  }

  if (defining && !editorOpen) {
    return (
      <div data-tour={dataTour} className={classes.feedbackContainer}>
        <Button dataTour={`${dataTour}-button`} onClick={() => setEditorOpen(true)} disabled={!feedbackToggle}>
          <FontAwesomeIcon
            icon={faComment}
            className={classes.commentIcon}
          />
          {lang.test.grades.addComment}
        </Button>
        {!feedbackToggle &&
          <PlansPill
            tip={lang.plans.premiumFeature}
          />
        }
      </div>
    );
  }

  const getFeedback = () => {
    if (!defining) {
      return (
        <QuillRenderer
          className={classes.feedback}
          value={feedback}
        />
      );
    }

    return (
      <Input
        type='richtext'
        placeholder={lang.exerciseForm.statementPlaceholder}
        value={feedback}
        onChange={(event) => onChange(event)}
        onBlur={(_range, _source, editor) => onBlur(editor.getContents())}
      />
    );
  };

  if (!defining && quillIsEmpty(feedback)) {
    return null;
  }

  return (
    <div className={classes.giveFeedbackContainer}>
      <div className={classes.feedbackLabel}>
        {lang.comment}
        {':'}
      </div>
      {getFeedback()}
    </div>
  );
};

ExerciseFeedback.propTypes = {
  feedback: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  defining: PropTypes.bool,
  dataTour: PropTypes.string,
};

export default ExerciseFeedback;
