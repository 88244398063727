import React from 'react';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTheme } from 'theming';

import useStyles from './styles.js';

const RandomizerSwatch = ({ color, setColor }) => {
  const classes = useStyles();
  const theme = useTheme();

  const getRandomColor = () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  };

  return (
    <div className={classes.randomSwatchWrapper}>
      <div className={classes.randomSwatch} style={{ backgroundColor: color ? color : theme.defaultLabelColor }} onClick={() => setColor(getRandomColor())}>
        <FontAwesomeIcon
          icon={faSync}
          className={classes.icon}
        />
      </div>
    </div>
  );
};

RandomizerSwatch.propTypes = {
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired,
};
export default RandomizerSwatch;
