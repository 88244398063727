import React, { useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import { setStudentAttempt } from 'actions/studentAttemptActions';
import api from 'api';
import { studentLoginRequest as studentLoginAuthRequest } from 'authConfig';
import useApi from 'hooks/common/useApi';
import useInput from 'hooks/common/useInput';
import useLoading from 'hooks/common/useLoading';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import MicrosoftButton from 'components/common/MicrosoftButton';

import useStyles from './styles';

const PublicationForm = ({ password, saveCurrentAttempt }) => {
  const classes = useStyles();
  const { publicationId } = useParams();
  const azureOauthToggle = useFeature(toggles.azureOauth);
  const iaveToggle = useFeature(toggles.iave);
  const guestAttemptsToggle = useFeature(toggles.guestAttempts);
  const history = useHistory();
  const toast = useToast();
  const [createAttemptRequest] = useApi(api.createAttempt, true);
  const dispatch = useDispatch();

  const [name, setName, nameErrors, setNameErrors] = useInput('');
  const [email, setEmail, emailErrors, setEmailErrors] = useInput('');
  const [loading, setLoading] = useLoading(false);

  const handleCreateAttemptResponse = useCallback(({ status, attempt }) => {
    setLoading(false);
    if (status === 0) {
      saveCurrentAttempt(attempt);

      history.push(routes.attempt.ref(attempt.id));
    } else if (status === 4) {
      toast.info(lang.tests.student.testNotOpen);
    } else if (status === 5) {
      toast.error(lang.tests.student.testWindowClosed);
    } else if (status === 6) {
      toast.error(lang.tests.student.testComplete);
    } else if (status === 7) {
      toast.error(lang.tests.student.testCompleteTime);
    } else {
      toast.error(lang.oops);
    }
  }, [history, saveCurrentAttempt, setLoading, toast]);

  const createAttempt = useCallback((name, email) => {
    if (loading) {
      return;
    }

    const newAttempt = {
      publicationId: publicationId,
      userName: name,
      email: email ? email : undefined,
      password: password,
    };

    setLoading(true);
    dispatch(setStudentAttempt(null));
    createAttemptRequest([], newAttempt, ({ data }) => {
      handleCreateAttemptResponse(data);
    });
  }, [createAttemptRequest, handleCreateAttemptResponse, loading, password, publicationId, setLoading, dispatch]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    let errors = false;
    const _nameErrors = [];
    const _emailErrors = [];

    const trimmedName = name.trim();
    if (trimmedName.length === 0) {
      errors = true;
      _nameErrors.push(lang.publication.errorNameRequired);
    }

    if (email && !isEmail(email)) {
      errors = true;
      _emailErrors.push(lang.publication.errorEmail);
    }

    if (errors) {
      setNameErrors(_nameErrors);
      setEmailErrors(_emailErrors);
      return;
    }

    setNameErrors([]);
    setEmailErrors([]);

    createAttempt(name, email);
  }, [createAttempt, email, name, setEmailErrors, setNameErrors]);

  const handleMicrosoftAuthenticated = useCallback((name, email) => {
    createAttempt(name, email);
  }, [createAttempt]);

  return (
    <div>
      <div className={classes.authButtonsContainer}>
        {azureOauthToggle && (
          <MicrosoftButton
            label={lang.login.loginWithMicrosoft}
            request={{
              ...studentLoginAuthRequest,
              state: JSON.stringify({
                publicationId: publicationId,
                password: password,
              }),
            }}
            handleAuthenticated={handleMicrosoftAuthenticated}
            postLogoutRedirectUri={`${routes.testsApp.ref()}${routes.publication.ref(publicationId)}`}
          />
        )}
        {guestAttemptsToggle && (
          <Button
            onClick={() => createAttempt(lang.guest)}
            sibling
            loading={loading}
          >
            {lang.publication.continueAsGuest}
          </Button>
        )}
      </div>
      {!iaveToggle &&
        (azureOauthToggle || guestAttemptsToggle) && (
        <div className={classes.loginStrategiesSeparator}>
          <div className={classes.separatorLine} />
          {lang.or.toUpperCase()}
          <div className={classes.separatorLine} />
        </div>
      )}
      {!iaveToggle && (
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            className={classes.input}
            value={name}
            label={lang.publication.studentName}
            placeholder={lang.publication.studentName}
            onChange={(event) => setName(event.target.value)}
            maxSize={100}
            errors={nameErrors}
          />
          <Input
            type="email"
            className={classes.input}
            value={email}
            label={lang.email}
            hint={lang.publication.emailHint}
            placeholder={lang.email}
            onChange={(event) => setEmail(event.target.value)}
            errors={emailErrors}
            optional
          />
          <Button
            type="submit"
            className={classes.button}
            stretch
            loading={loading}
          >
            {lang.submit}
          </Button>
        </form>
      )}
    </div>
  );
};

PublicationForm.propTypes = {
  password: PropTypes.string,
  saveCurrentAttempt: PropTypes.func.isRequired,
};

export default PublicationForm;
