import { createUseStyles } from 'react-jss';

export default createUseStyles({
  endMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  endMessageSeparator: {
    width: '80%',
    borderBottom: '1px solid #bebebe',
    margin: '54px auto 36px auto',
  },
  endMessage: {
    color: '#636363',
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '20px',
  },
  scrollBackToTopButton: {
    textAlign: 'center',
    margin: '0 auto 20px auto',
  },
  scrollBackToTopIcon: {
    margin: '0 10px',
  },
});
