import { createUseStyles } from 'react-jss';

export default createUseStyles({
  feedbackContainer: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  feedbackLabel: {
    fontWeight: '600',
    marginBottom: '10px',
  },
  feedback: {
    wordBreak: 'break-word',
    '& > p': {
      marginTop: '0',
      marginBottom: '0',
    },
  },
  commentIcon: {
    marginRight: '10px',
  },
  giveFeedbackContainer: {
    marginTop: '20px',
  },
});
