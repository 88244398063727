import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    width: '100%',
  },
  answerWrapper: {
    display: 'flex',
    gap: '0.5em',
  },
  gridContainer: {
    display: 'grid',
    columnGap: '0.5em',
    padding: '10px',
    gridTemplateColumns: '10% minmax(auto, 700px) 5%',
    '&.isCorrection': {
      gridTemplateColumns: '5% minmax(auto, 700px) 5%',
    },
  },
  order: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.isFirst': {
      borderRadius: '4px 4px 0px 0px',
    },
    '&.isLast': {
      borderRadius: '0px 0px 4px 4px',
    },
    '&.isCorrect': {
      backgroundColor: '#b7e9bf',
    },
    '&.isWrong': {
      backgroundColor: '#F4897BB3',
    },
  },
  correctionOrder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    backgroundColor: '#6dcfe7B3',
    '&.isFirst': {
      borderRadius: '4px 4px 0px 0px',
    },
    '&.isLast': {
      borderRadius: '0px 0px 4px 4px',
    },
  },
  studentOrderHeader: {
    placeSelf: 'center',
    marginBottom: '0.25em',
    display: 'flex',
    alignItems: 'center',
    gap: '0.25em',
    '& > svg': {
      color: '#CE1F01',
    },
    '&.isCorrect': {
      '& > svg': {
        color: theme.successColor,
      },
    },
  },
  correctionOrderHeader: {
    placeSelf: 'center',
    marginBottom: '0.25em',
    display: 'flex',
    alignItems: 'center',
    gap: '0.25em',
    '& > svg': {
      color: theme.buttonBackground2,
    },
  },
  headerLabel: {

  },
  [`@media (max-width: ${theme.md}px)`]: {
    headerLabel: {
      display: 'none',
    },
    gridContainer: {
      '&.isCorrection': {
        gridTemplateColumns: '10% auto 10%',
      },
    },
  },
}));
