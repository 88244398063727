import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  flowStepCard: {
    border: `1px solid ${theme.cardBorder3}`,
    marginBottom: '30px',
    borderRadius: '18px',
    backgroundColor: '#FFFFFF',
  },
  flowStepCardHeader: {
    fontSize: '14px',
    fontWeight: '600',
    padding: '10px',
    backgroundColor: theme.cardHeaderBackground3,
    borderRadius: '18px 18px 0 0',
  },
  flowStepCardSubHeader: {
    fontSize: '13px',
    fontWeight: '500',
    padding: '10px 0 0 0',
    color: theme.textColor7,
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));
