import React, { useRef } from 'react';
import { Button, Icon } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';

import useStyles from './styles';

const HeaderCell = ({ col, row, value, onChange, removeColumn, removeRow, children, restricted, ...restProps }) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const exportIdentifiersToggle = useFeature(toggles.exportIdentifiers);

  if (restProps.className.includes('ant-table-cell-scrollbar')) {
    return (
      <td {...restProps} />
    );
  }

  if (row === -1) {
    if (restricted) {
      return;
    } else {
      return (
        <th {...restProps} >
          <div className={classes.headerCellContent}>
            {col !== -1 && col !== 0 && col !== 1 && col !== 2 ?
              <Button styleType="outlined" sibling color="danger" className={classes.removeButton} onClick={removeColumn}>
                <Icon icon="remove" size="lg" />
              </Button>
              :
              <div className={classes.buttonSpacer} />
            }
          </div>
        </th>
      );
    }
  }

  if (col === -1) {
    return (
      <th {...restProps} >
        <div className={classes.headerCellContent}>
          {row !== 0 && row !== 1 && row !== 2 ? (
            <Button styleType="outlined" sibling color="danger" className={classes.removeButton} onClick={removeRow}>
              <Icon icon="remove" size="lg" />
            </Button>
          )
            :
            (
              <div className={classes.buttonSpacer} />
            )
          }
        </div>
      </th>
    );
  }

  return (
    <th {...restProps} >
      {exportIdentifiersToggle && (
        <>
          {row === 0 && (
            <div className={classes.headerRowIdentifiers}>
              {String.fromCharCode(65 + col)}
            </div>
          )}
          {col === 0 && (
            <div className={classes.headerColIdentifiers}>
              {row}
            </div>
          )}
        </>
      )}
      <Input
        ref={inputRef}
        className={classes.input}
        {...(col === 0 && row === 0 && { label: `(${lang.optional})` })}
        inline
        minified
        type="richtext"
        value={value}
        placeholder={lang.exerciseForm.table.headerPlaceholder}
        onChange={onChange}
      />
    </th>
  );
};

HeaderCell.propTypes = {
  col: PropTypes.number.isRequired,
  row: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  removeColumn: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  restricted: PropTypes.bool,
};

export default HeaderCell;
