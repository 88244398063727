import { CAPTION, CHOICES, CONNECTING, FILLING, IMAGE, INFORMATION, PAUSE, SEGMENTATION, TEXT, TRUEFALSE, TABLE } from 'constants/exerciseTypes';

const updateAttemptAnswerGrade = (attempt, answerId, grade, sectionId) => {
  const newAttempt = { ...attempt };

  let answer;
  if (sectionId) {
    const section = newAttempt.items
      .find((item) => item.id === sectionId && item.itemType === 'section');

    if (!section) {
      return;
    }

    answer = section.exercises
      .find((exercise) => exercise.id === answerId);
  } else {
    answer = newAttempt.items
      .find((item) => item.id === answerId && item.itemType === 'exercise');
  }

  answer.grade = grade;

  return newAttempt;
};

const updateAttemptCriteriaGrade = (attempt, answerId, criteriaGradeId, grade, sectionId) => {
  const newAttempt = { ...attempt };

  newAttempt.items = attempt.items.map((item) => {
    if (item.exercises && item.exercises.length !== 0) {
      return {
        ...item,
        exercises: item.exercises.map(exercise => ({
          ...exercise,
          criteriaGrades: exercise.criteriaGrades ? exercise.criteriaGrades.map(criteriaGrade => ({
            ...criteriaGrade,
            grade: criteriaGrade.id === criteriaGradeId ? grade : criteriaGrade.grade,
          })) : null,
        })),
      };
    } else {
      return {
        ...item,
        criteriaGrades: item.criteriaGrades ? item.criteriaGrades.map(criteriaGrade => ({
          ...criteriaGrade,
          grade: criteriaGrade.id === criteriaGradeId ? grade : criteriaGrade.grade,
        })) : null,
      };
    }
  });

  let item;

  if (sectionId) {
    const section = newAttempt.items.find(item => item.id === sectionId);
    item = section.exercises.find(exercise => exercise.id === answerId);
  } else {
    item = newAttempt.items.find(item => item.id === answerId);
  }

  let exerciseGrade = 0;
  item.criteriaGrades.forEach(criteriaGrade => {
    exerciseGrade += (criteriaGrade.grade / 5) * (criteriaGrade.weight / 100);
  });
  exerciseGrade *= item.maxGrade;
  item.grade = exerciseGrade;

  newAttempt.criteriaTotals = attempt.criteriaTotals.map(criteria => ({
    ...criteria,
    grade: 0,
  }));

  const exercises = newAttempt.items.flatMap((item) => item.itemType === 'section' ? (item.exercises || []) : item)
    .filter(item => ![INFORMATION, PAUSE].includes(item.type));

  exercises.forEach(exercise => {
    exercise.criteriaGrades.forEach(criteriaGrade => {
      const criteriaTotal = newAttempt.criteriaTotals.find(criteriaTotal => criteriaTotal.rubricCriteriaId === criteriaGrade.rubricCriteriaId);
      criteriaTotal.grade += parseInt(criteriaGrade.grade) * ((parseInt(criteriaGrade.weight) / 100) * parseInt(exercise.maxGrade));
    });
  });

  newAttempt.criteriaTotals.forEach(criteriaTotal => {
    let criteriaTotalPoints = 0;

    exercises.forEach(exercise => {
      const criteriaGrade = exercise.criteriaGrades.find(criteriaGrade => criteriaGrade.rubricCriteriaId === criteriaTotal.rubricCriteriaId);
      criteriaTotalPoints += (parseInt(criteriaGrade.weight) / 100) * parseInt(exercise.maxGrade);
    });

    criteriaTotal.grade /= criteriaTotalPoints;
    criteriaTotal.grade = Math.round(criteriaTotal.grade);
  });

  return newAttempt;
};

const updateAttemptFeedback = (attempt, answerId, feedback, sectionId) => {
  const newAttempt = { ...attempt };

  let answer;
  if (sectionId) {
    const section = newAttempt.items
      .find((item) => item.id === sectionId && item.itemType === 'section');

    if (!section) {
      return;
    }

    answer = section.exercises
      .find((exercise) => exercise.id === answerId);
  } else {
    answer = newAttempt.items
      .find((item) => item.id === answerId && item.itemType === 'exercise');
  }

  if (!answer) {
    return attempt;
  }

  answer.feedback = feedback;

  return newAttempt;
};

const getAnswer = (exercise) => {
  switch (exercise.type) {
    case CHOICES:
      if (exercise.valueChoices) {
        return exercise.valueChoices;
      } else if (exercise.valueMultipleChoices) {
        return exercise.valueMultipleChoices;
      }
      break;
    case TEXT:
      return exercise.isShortAnswer ? exercise.valueText : exercise.quillAnswer;
    case IMAGE:
      return exercise.valueImages ? exercise.valueImages.map(image => image.value) : [];
    case TRUEFALSE:
      return exercise.valueTrueFalse;
    case FILLING:
      return exercise.valueGaps;
    case CAPTION:
      return exercise.valueGaps;
    case CONNECTING:
      return exercise.valueConnections;
    case SEGMENTATION:
      return exercise.valueConnections;
    case TABLE:
      return exercise.valueMultipleChoices;
    default:
      return null;
  }
};

export {
  updateAttemptAnswerGrade,
  updateAttemptCriteriaGrade,
  updateAttemptFeedback,
  getAnswer,
};
