import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { faPauseCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { LOCAL } from 'constants/environments';
import lang from 'lang';
import { getDuration } from 'utils/datetime';

import useStyles from './styles';

const VideoPlayer = ({ src, canPause, limitRepetitions, maxRepetitions, attemptId, updateMediaRepetitionsRequest, getAttemptMediaRequest }) => {
  const classes = useStyles();
  const videoRef = useRef();

  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [repetitions, setRepetitions] = useState(0);
  const [canPlayThrough, setCanPlayThrough] = useState(false);

  const disabled = useMemo(() => {
    return !canPlayThrough || (!playing && limitRepetitions && repetitions >= parseInt(maxRepetitions) && currentTime === 0) || (playing && !canPause);
  }, [canPlayThrough, playing, limitRepetitions, repetitions, maxRepetitions, canPause, currentTime]);

  const finalSrc = useMemo(() => {
    let finalSrc = src;
    if (process.env.REACT_APP_NODE_ENV === LOCAL) {
      const hostname = window.location.hostname;
      finalSrc = finalSrc.replace('localhost', hostname);
    }

    return finalSrc;
  }, [src]);

  useEffect(() => {
    if (attemptId && limitRepetitions) {
      getAttemptMediaRequest([attemptId, src], null, ({ data }) => {
        if (data.status === 0) {
          setRepetitions(data.attemptMedia.repetitions);
        }
      });
    }
  }, [attemptId, limitRepetitions, src, getAttemptMediaRequest]);

  const onClickPlay = useCallback(() => {
    if (disabled) {
      return;
    }

    if (!playing && currentTime === 0) {
      setRepetitions(repetitions + 1);

      if (attemptId && limitRepetitions) {
        updateMediaRepetitionsRequest([attemptId, src], null, ({ data }) => {
          if (data.status === 0) {
            setRepetitions(data.repetitions);
          }
        });
      }
    }

    if (playing && canPause) {
      videoRef.current.pause();
    }

    if (!playing) {
      videoRef.current.play();
    }

    setPlaying(canPause ? !playing : true);

  }, [disabled, canPause, limitRepetitions, repetitions, attemptId, src, updateMediaRepetitionsRequest, currentTime, playing]);

  const onEnded = () => {
    setPlaying(false);
    setCurrentTime(0);
  };

  return (
    <span className={classes.playerContainer}>
      <video
        src={finalSrc}
        ref={videoRef}
        onLoadedData={(event) => setDuration(event.target.duration)}
        onTimeUpdate={(event) => setCurrentTime(event.target.currentTime)}
        onCanPlayThrough={() => setCanPlayThrough(true)}
        onEnded={onEnded}
      />
      <span className={classes.videoControls}>
        <FontAwesomeIcon
          icon={playing && canPause ? faPauseCircle : faPlayCircle}
          onClick={onClickPlay}
          className={cx(classes.playButton, { disabled })}
        />
        <span className={classes.timeDisplay}>
          {getDuration(currentTime, 'seconds', 'mm:ss')}
          {' / '}
          {getDuration(duration, 'seconds', 'mm:ss')}
        </span>
        <Render when={limitRepetitions}>
          <span className={classes.repetitionDisplay}>
            {`${repetitions}/${maxRepetitions} ${lang.exerciseForm.audio.repetitions}`}
          </span>
        </Render>
      </span>
    </span>
  );
};

VideoPlayer.propTypes = {
  src: PropTypes.string,
  canPause: PropTypes.bool,
  limitRepetitions: PropTypes.bool,
  maxRepetitions: PropTypes.number,
  attemptId: PropTypes.string,
  updateMediaRepetitionsRequest: PropTypes.func,
  getAttemptMediaRequest: PropTypes.func,
};

export default VideoPlayer;
