const route = (def, ref) => ({
  def: def,
  ref: ref || (() => def),
});

const getAppUrl = (app) => {
  if (process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'local') {
    const appUrl = `${window.origin.slice(0, window.origin.length - 4)}${app}${window.origin.slice(window.origin.length - 3)}`;
    return appUrl;
  } else {
    const appUrl = `${window.origin.slice(0, window.origin.indexOf('//') + 2)}${app}${window.origin.slice(window.origin.indexOf('.'))}`;
    return appUrl;
  }
};

const routes = {
  help: route(`${process.env.REACT_APP_WEBSITE_URL}/suporte`),
  feedback: route(`${process.env.REACT_APP_WEBSITE_URL}/feedback`),
  tutorial: route(`${process.env.REACT_APP_WEBSITE_URL}/tutoriais`),
  recommendSchool: route(`${process.env.REACT_APP_WEBSITE_URL}/recomendar-instituicao`),

  s3: route(null, (path) => `${process.env.REACT_APP_S3_URL}/${path}`),

  auth: route(null, () => getAppUrl(process.env.REACT_APP_AUTH_APP)),
  testsApp: route(null, () => getAppUrl(process.env.REACT_APP_TESTS_APP)),
  profile: route(null, () => `${getAppUrl(process.env.REACT_APP_AUTH_APP)}/user`),
  authLogout: route(null, () => `${getAppUrl(process.env.REACT_APP_AUTH_APP)}/logout`),
  limbo: route(null, () => `${getAppUrl(process.env.REACT_APP_AUTH_APP)}/limbo`),

  teachers: route(null, () => `${getAppUrl(process.env.REACT_APP_ADMIN_APP)}/teachers`),
  students: route(null, () => `${getAppUrl(process.env.REACT_APP_ADMIN_APP)}/students`),
  events: route(null, () => `${getAppUrl(process.env.REACT_APP_ADMIN_APP)}/events`),
  exams: route(null, () => `${getAppUrl(process.env.REACT_APP_ADMIN_APP)}/exams`),
  classes: route(null, () => `${getAppUrl(process.env.REACT_APP_ADMIN_APP)}/classes`),

  home: route('/'),
  school: route('/s/:spaceId', (spaceId) => `/s/${spaceId}`),
  tests: route('/s/:spaceId/tests', (spaceId, groupId) => `/s/${spaceId}/tests${groupId ? `?group=${groupId}` : ''}`),
  test: route('/s/:spaceId/test/:testId', (spaceId, testId) => `/s/${spaceId}/test/${testId}`),
  exercises: route('/s/:spaceId/exercises', (spaceId, groupId) => `/s/${spaceId}/exercises${groupId ? `?group=${groupId}` : ''}`),
  newExercise: route('/s/:spaceId/exercise/new', (spaceId) => `/s/${spaceId}/exercise/new`),
  editExercise: route('/s/:spaceId/exercise/:exerciseId/edit', (spaceId, exerciseId) => `/s/${spaceId}/exercise/${exerciseId}/edit`),
  groups: route('/s/:spaceId/groups', (spaceId) => `/s/${spaceId}/groups`),
  group: route('/s/:spaceId/group/:groupId', (spaceId, groupId) => `/s/${spaceId}/group/${groupId}`),
  attempt: route('/attempt/:attemptId', (attemptId) => `/attempt/${attemptId}`),
  publication: route('/publication/:publicationId', (publicationId) => `/publication/${publicationId}`),
  rubrics: route('/s/:spaceId/rubrics', (spaceId, groupId) => `/s/${spaceId}/rubrics${groupId ? `?group=${groupId}` : ''}`),
  newRubric: route('/s/:spaceId/rubric/new', (spaceId) => `/s/${spaceId}/rubric/new`),
  editRubric: route('/s/:spaceId/rubric/:rubricId/edit', (spaceId, rubricId) => `/s/${spaceId}/rubric/${rubricId}/edit`),
  explore: route('/s/:spaceId/explore', (spaceId) => `/s/${spaceId}/explore`),
  loginMicrosoft: route('/login/microsoft'),
  studentLoginMicrosoft: route('/student-login/microsoft'),
  logout: route('/logout'),
};

export default routes;
