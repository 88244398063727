import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  teachersTable: {
    padding: '40px 30px',
  },
  headerCell: {
    borderTop: 'none !important',
    borderBottom: `2px solid ${theme.tableBorder} !important`,
    paddingBottom: '3px',
  },
  teacherCell: {
    borderTop: 'none !important',
  },
  mobileInvisible: {
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    mobileInvisible: {
      display: 'none',
    },
  },
}));
