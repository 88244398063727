import { createUseStyles } from 'react-jss';

export default createUseStyles({
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    justifyContent: 'flex-end',
  },
});
