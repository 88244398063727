import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  trueFalseContainer: {
    display: 'flex',
    padding: '15px 13px',
    gap: '0.5em',
  },
  trueFalseButton: {
    color: theme.textColor5,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.buttonBackground3,
    border: `1px solid ${theme.buttonBorder3}`,
    borderRadius: '0',
    padding: '10px',
    width: '180px',
    transition: 'background-color 100ms',
    '&.isTrue': {
      '&:focus': {
        boxShadow: `0 0 0 0.2rem ${theme.selectedColor2}`,
      },
      '&:hover': {
        backgroundColor: '#abddbe',
      },
      '&.active': {
        color: theme.textColorContrast,
        backgroundColor: theme.selectedColor2,
      },
    },
    '&.isFalse': {
      '&:focus': {
        boxShadow: `0 0 0 0.2rem ${theme.removeColor}`,
      },
      '&:hover': {
        backgroundColor: '#edcac3',
      },
      '&.active': {
        color: theme.textColorContrast,
        backgroundColor: theme.removeColor,
      },
    },
  },
  buttonText: {
    marginLeft: '6px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    trueFalseContainer: {
      flexWrap: 'wrap',
      gap: '0.5em',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    trueFalseContainer: {
      justifyContent: 'space-around',
    },
  },

}));
