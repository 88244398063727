import React from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'actions/userActions';
import { quillIsEmpty } from 'utils';

import InnerGap from '../InnerGap';

import useStyles from './styles';

const CorrectionGap = ({ item, correctionItem }) => {
  const classes = useStyles();
  const isAdmin = useSelector(selectUserIsAdmin);

  return (
    <>
      <Render when={!item || !item.text || (item.position && quillIsEmpty({ ops: JSON.parse(item.text) }))}>
        <div className={cx(classes.gap, { empty: true })}>
          <FontAwesomeIcon className={classes.wrong} icon={faXmark} />
        </div>
      </Render>
      <Render when={item && item.text && !(item.position && quillIsEmpty({ ops: JSON.parse(item.text) }))}>
        <InnerGap
          item={item}
        />
      </Render>
      <Render when={(item && !item.isCorrectAnswer && !isAdmin) || !item}>
        <InnerGap
          item={correctionItem}
          isCorrection
        />
      </Render>
    </>
  );
};

CorrectionGap.propTypes = {
  item: PropTypes.object,
  correctionItem: PropTypes.object,
};

export default CorrectionGap;
