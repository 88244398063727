import React from 'react';
import lang from 'lang.js';
import PropTypes from 'prop-types';

import FilterSelector from 'components/common/filter-selector/FilterSelector';

import useStyles from './styles.js';

const UserSelector = ({ users, filters, setFilters, disabled }) => {
  const classes = useStyles();

  const itemAction = (itemtoAdd) => {
    setFilters({ ...filters, users: [...filters.users, itemtoAdd] });
  };

  return (
    <div className={classes.userSelectorContainer}>
      <FilterSelector
        itemAction={itemAction}
        itemOptions={users}
        currentItems={filters.users}
        contextPlaceholder={lang.exercises.teacherFilter}
        disabled={disabled}
      />
    </div>
  );
};

UserSelector.propTypes = {
  users: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default UserSelector;
