import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  card: {
    borderRadius: '18px',
    border: 'none',
    backgroundColor: theme.cardBackground,
    boxShadow: '0 4px 4px #00000040',
  },
  rightWrap: {
    paddingRight: '15px',
    backgroundColor: theme.cardColor3,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0 18px 18px 0',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  optionsBtn: {
    fontSize: '20px',
    height: '53px',
    width: '59px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '20px',
    opacity: 0.8,
    '&.isPublished': {
      color: theme.successColor,
    },
    '&:hover': {
      opacity: 0.9,
      cursor: 'pointer',
    },
    '& svg': {
      marginTop: '9px',
    },
    '&.disabled': {
      opacity: 0.3,
      cursor: 'default',
    },
  },
  optionLoading: {
    padding: 0,
    height: '30px',
    width: '30px',
  },
  optionText: {
    marginTop: '5px',
    fontSize: '12px',
  },
  optionsBtnBig: {
    height: '37px',
    width: '18px',
    fontSize: '20px',
    textAlign: 'center',
    marginLeft: '15px',
    opacity: 0.4,
    '&:hover': {
      opacity: 0.8,
    },
    '& svg': {
      marginTop: '9px',
    },
    '&.mobileOnly': {
      display: 'none',
    },
  },
  optionsDropdown: {
    zIndex: '2',
    borderRadius: '10px',
    border: `1px solid ${theme.cardBorder} !important`,
    boxShadow: `0 2px 3px ${theme.shadowColor}`,
    overflow: 'hidden',
  },
  dropdownItem: {
    margin: 'unset !important',
    borderBottom: 'unset !important',
    backgroundColor: theme.cardBackground,
    '&:hover': {
      backgroundColor: theme.cardColor2,
      cursor: 'pointer',
    },
    '&.mobile': {
      display: 'none',
    },
    '&.disabled': {
      opacity: 0.5,
      cursor: 'default',
    },
  },
  dropdownItemAction: {
    display: 'none',
  },
  dropdownItemBtn: {
    opacity: 0.8,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '10px',
    },
  },
  dropdownOptionLoading: {
    padding: 0,
    height: '25px',
    width: '25px',
  },
  childrenWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    borderRadius: '18px 0 0 18px',
    '&:hover': {
      backgroundColor: theme.cardBackground5,
      '& > $checkboxWrapper': {
        backgroundColor: theme.cardBackground5,
        borderRadius: '18px 0 0 18px',
      },
    },
    '&.isSelected': {
      backgroundColor: theme.cardBackground5,
      '&:hover': {
        backgroundColor: theme.cardBackgroundHover5,
        '& > $checkboxWrapper': {
          backgroundColor: theme.cardBackgroundHover5,
        },
      },
    },
  },
  exerciseCheckbox: {
    cursor: 'pointer',
  },
  checkboxWrapper: {
    padding: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  [`@media (max-width: ${theme.xl}px)`]: {
    actions: {
      '&.xl': {
        display: 'none',
      },
    },
    dropdownItem: {
      '&.mobile.xl': {
        display: 'block',
      },
    },
  },
  [`@media (max-width: ${theme.lg}px)`]: {
    actions: {
      '&.lg': {
        display: 'none',
      },
    },
    dropdownItem: {
      '&.mobile.lg': {
        display: 'block',
      },
    },
  },
  [`@media (max-width: ${theme.md}px)`]: {
    actions: {
      '&.md': {
        display: 'none',
      },
    },
    dropdownItem: {
      '&.mobile.md': {
        display: 'block',
      },
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    actions: {
      '&.sm': {
        display: 'none',
      },
    },
    dropdownItem: {
      '&.mobile.sm': {
        display: 'block',
      },
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    actions: {
      '&.xs': {
        display: 'none',
      },
    },
    optionsBtn: {
      display: 'none',
    },
    optionsBtnBig: {
      '&.mobileOnly': {
        display: 'block',
      },
    },
    dropdownItem: {
      '&.mobile.xs': {
        display: 'block',
      },
    },
    dropdownItemAction: {
      display: 'block',
      margin: 'unset !important',
      borderBottom: 'unset !important',
      backgroundColor: theme.cardBackground,
      '&:hover': {
        backgroundColor: theme.cardColor2,
        cursor: 'pointer',
      },
      '&.isPublished': {
        color: theme.successColor,
      },
    },
    childrenWrapper: {
      '&.wrapped': {
        flexWrap: 'nowrap',
      },
      flexWrap: 'wrap',
    },
  },
}));
