import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    marginBottom: '5px',
  },
  orderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    marginBottom: '5px',
  },
  header: {
    fontSize: '12px',
    color: theme.textColor3,
  },
  filterSelectors: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
  },
  order: {
    marginTop: '0.25em',
    display: 'flex',
    alignItems: 'center',
  },
  filters: {
    marginTop: '5px',
  },
  actionWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    },
  },
  actionBtn: {
    fontSize: '30px',
  },
  loading: {
    width: '16px',
    height: '16px',
  },
  exerciseActionsWrapper: {
    width: '100%',
    marginTop: '5px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    searchContainer: {
      display: 'unset',
    },
    filterSelectors: {
      display: 'unset',
    },
    orderContainer: {
      alignItems: 'unset',
      marginTop: '0.5em',
    },
    filtersContainer: {
      width: '100%',
    },
  },
}));
