import React, { useState } from 'react';
import { faCheck, faChevronDown, faChevronUp, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setFeatureToggles, setSpaceIdPreference } from 'actions/userActions';
import api from 'api';
import { FREE } from 'constants/plans';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import useStyles from './styles';

const SchoolDropdown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.data);
  const toast = useToast();
  const history = useHistory();
  const [updateSpaceIdPreference] = useApi(api.updateSpaceIdPreference);
  const plansToggle = useFeature(toggles.plans);

  const [dropdown, setDropdown] = useState(false);

  const handleChangeSpace = (spaceId) => {
    const currentSpaceId = window.location.pathname.split('/')[2];
    if (currentSpaceId !== spaceId) {
      updateSpaceIdPreference([], { spaceId }, ({ data }) => {
        if (data.status === 0) {
          dispatch(setFeatureToggles(data.featureToggles));
        } else if (data.status === 2) {
          toast.error(lang.noPermissionSpace);
        } else if (data.status !== 0) {
          toast.error(lang.oops);
        }
        dispatch(setSpaceIdPreference(spaceId));
      });
      history.push(routes.tests.ref(spaceId));
    }

    setDropdown(false);
  };

  const getCurrentSpace = () => {
    const currentSpace = user.schools ? user.schools.find(el => el.id === window.location.pathname.split('/')[2]) : null;

    return (
      <div className={classes.currentSpace}>
        {
          currentSpace || (plansToggle && user.plan && user.plan.name !== FREE) ?
            <FontAwesomeIcon
              icon={faStar}
              className={classes.premiumBadgeIcon}
            />
            : null
        }
        {currentSpace ? currentSpace.name : lang.appKeywords.personalSpace}
      </div>
    );
  };

  return (
    <Dropdown
      close={() => setDropdown(false)}
      className={classes.dropdown}
      top
    >
      <div
        onClick={() => setDropdown(!dropdown)}
        className={classes.dropdownToggler}
        role="button"
      >
        {getCurrentSpace()}
        <div className={classes.arrow}>
          <FontAwesomeIcon icon={dropdown ? faChevronUp : faChevronDown} />
        </div>
      </div>
      <DropdownMenu
        open={dropdown}
        className={classes.dropdownMenu}
      >
        <Render when={user.isTeacher && user.hasPersonalSpace}>
          <DropdownMenuItem
            className={classes.dropdownMenuItem}
            onClick={() => handleChangeSpace('p')}
          >
            <div className={classes.iconSlot}>
              <Render when={window.location.pathname.split('/')[2] === 'p'}>
                <FontAwesomeIcon icon={faCheck} />
              </Render>
            </div>
            {lang.appKeywords.personalSpace}
            <Render when={user.plan && user.plan.name !== FREE}>
              <FontAwesomeIcon
                icon={faStar}
                className={classes.premiumBadgeIcon}
              />
            </Render>
          </DropdownMenuItem>
        </Render>
        {user.schools ? user.schools.map(school => (
          <DropdownMenuItem
            className={classes.dropdownMenuItem}
            onClick={() => handleChangeSpace(school.id)}
            key={school.id}
          >
            <div className={classes.iconSlot}>
              <Render when={window.location.pathname.split('/')[2] === school.id}>
                <FontAwesomeIcon icon={faCheck} />
              </Render>
            </div>
            {school.name}
            <FontAwesomeIcon
              icon={faStar}
              className={classes.premiumBadgeIcon}
            />
          </DropdownMenuItem>
        )) : []}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SchoolDropdown;
