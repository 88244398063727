import React from 'react';
import { DropdownMenuItem } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles from './styles.js';

const FilterSelectorItem = ({ item, itemAction, selected, noPadding }) => {
  const classes = useStyles();

  const _itemAction = () => {
    if (itemAction) {
      itemAction(item);
    }
  };
  return (
    <DropdownMenuItem className={cx(classes.itemContainer, { selected, noPadding })} onClick={_itemAction}>
      <Render when={item.color}>
        <div className={classes.square} style={{ backgroundColor: item.color }} />
      </Render>
      {item.name}
    </DropdownMenuItem >

  );
};

FilterSelectorItem.propTypes = {
  item: PropTypes.object.isRequired,
  itemAction: PropTypes.func,
  selected: PropTypes.bool,
  noPadding: PropTypes.bool,
};
export default FilterSelectorItem;
