import { createUseStyles } from 'react-jss';

export default createUseStyles({
  playerContainer: {
    display: 'flex',
    borderRadius: '40px',
    fontSize: '16px',
    position: 'relative',
    '& div:first-child': {
      width: '100% !important',
      height: '100% !important',
    },
    '& video': {
      height: 'unset',
      width: '100%',
    },
  },
  videoControls: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    backgroundColor: '#edededB3',
    padding: '10px 20px',
    position: 'absolute',
    bottom: '0',
  },
  playButton: {
    cursor: 'pointer',
    fontSize: '20px',
    '&.disabled': {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  timeDisplay: {
    display: 'flex',
    alignItems: 'center',
  },
  repetitionDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
  },
});
