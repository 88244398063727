import { createUseStyles } from 'react-jss';

export default createUseStyles({
  tableCell: {
    border: 'none !important',
    marginTop: '10px',
    '&.disabled': {
      opacity: '0.4',
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  deleteAction: {
    cursor: 'pointer',
  },
});
