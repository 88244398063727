import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  testStatisticsCard: {
    width: '100% !important',
    '& .otl-cardContentContainer': {
      padding: 0,
    },
    height: '100%',
    borderRadius: '18px',
    overflow: 'hidden',
    boxShadow: '0 4px 4px #00000040',
    marginBottom: '15px',
  },
  testStatistics: {
    padding: '25px 5px 5px 5px',
    height: '100%',
    width: '100% !important',
    paddingBottom: '15px',
    display: 'flex',
    flexFlow: 'row-wrap',
    justifyContent: 'space-evenly',
  },
  maxGradeText: {
    fontWeight: 600,
    fontSize: '15px',
    color: theme.textColor9,
    margin: '0 5px',
  },
  eyePreview: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  statsTable: {
    marginTop: '7px',
    padding: '35px 35px',
    '& tr[class^="ant-table-row"]': {
      cursor: 'pointer',
    },
  },
}));
