import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import PropTypes from 'prop-types';

import useStyles from './styles';

const TestExerciseDrop = ({ id }) => {
  const classes = useStyles();
  const { setNodeRef } = useDroppable({ id });

  return (
    <div
      className={classes.testExerciseDrop}
      ref={setNodeRef}
    />
  );
};

TestExerciseDrop.propTypes = {
  id: PropTypes.string,
};

export default TestExerciseDrop;
