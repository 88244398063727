import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    overflowX: 'auto',
  },
  image: {
    width: '700px',
    height: '700px',
    objectFit: 'contain',
  },
  imageWrapper: {
    position: 'relative',
    width: '700px',
    height: '700px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
  },
  outerRing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: `2px solid ${theme.white}00`,
    '&:hover': {
      '&.answerable': {
        border: `2px solid ${theme.buttonBackground2}`,
        cursor: 'pointer',
      },
    },
  },
  innerRing: {
    width: '50%',
    height: '50%',
    borderRadius: '50%',
    border: '2px solid #595959',
    '&.active': {
      border: `2px solid ${theme.buttonBackground2}`,
    },
  },
  identifier: {
    position: 'absolute',
    top: '-75%',
    backgroundColor: `${theme.buttonBackground}B0`,
    padding: '0.15em',
    borderRadius: '6px',
    zIndex: '5',
    '&:hover': {
      backgroundColor: `${theme.buttonBackground}00`,
      color: `${theme.buttonBackground}00`,
    },
  },
  arrowDelete: {
    color: theme.removeColor,
    backgroundColor: 'white',
    borderRadius: '50%',
    cursor: 'pointer',
  },
}));
