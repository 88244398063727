/**
 * Action constants
 */
const SET_SEARCH = 'SET_SEARCH';
const SET_FILTERS = 'SET_FILTERS';
const SET_ORDER = 'SET_ORDER';
const SET_GROUPS = 'SET_GROUPS';
const SET_ITEMS = 'SET_ITEMS';
const INCREASE_USAGES = 'INCREASE_USAGES';
/**
 * Other constants
 */
const RELEVANCY_ORDER = 'relevancy';
const USAGES_ORDER = 'usages';
const NEWEST_ORDER = 'newest';
const OLDEST_ORDER = 'oldest';

const DEFAULT_SEARCH_TYPE = 'all';

/**
 * Action creators
 */
const setSearch = (search, searchType) => ({
  type: SET_SEARCH,
  search: search,
  searchType: searchType ?? DEFAULT_SEARCH_TYPE,
  order: search ? RELEVANCY_ORDER : USAGES_ORDER,
});

const setFilters = (filters) => ({
  type: SET_FILTERS,
  filters: filters,
});

const setOrder = (order) => ({
  type: SET_ORDER,
  order: order,
});

const setGroups = (groups) => ({
  type: SET_GROUPS,
  groups: groups,
});

const setItems = (items) => ({
  type: SET_ITEMS,
  items: items,
});

const increaseUsages = (itemId) => ({
  type: INCREASE_USAGES,
  itemId: itemId,
});

/**
 * Selectors
 */
const selectSearch = (state) => {
  return state.explore.search;
};

const selectSearchType = (state) => {
  return state.explore.searchType;
};

const selectFilters = (state) => {
  return state.explore.filters;
};

const selectOrder = (state) => {
  return state.explore.order;
};

const selectGroups = (state) => {
  return state.explore.groups;
};

const selectItems = (state) => {
  return state.explore.items;
};

export {
  SET_SEARCH,
  SET_FILTERS,
  SET_ORDER,
  SET_GROUPS,
  SET_ITEMS,
  INCREASE_USAGES,
  RELEVANCY_ORDER,
  USAGES_ORDER,
  NEWEST_ORDER,
  OLDEST_ORDER,
  DEFAULT_SEARCH_TYPE,
  setSearch,
  setFilters,
  setOrder,
  setGroups,
  setItems,
  increaseUsages,
  selectSearch,
  selectSearchType,
  selectFilters,
  selectOrder,
  selectGroups,
  selectItems,
};
