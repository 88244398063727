import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import Button from 'components/common/Button';

import useStyles from './styles';

const TabHeader = ({ handleExport, loading }) => {
  const classes = useStyles();

  return (
    <div className={classes.tabHeader}>
      <h1 className={classes.tabHeaderTitle}>
        {lang.test.physicalVersion.exportTest}
      </h1>
      <Button
        onClick={handleExport}
        loading={loading}
      >
        {lang.export}
      </Button>
    </div>
  );
};

TabHeader.propTypes = {
  handleExport: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default TabHeader;
