import React, { useCallback, useState } from 'react';
import { faFlag as faFlagRegular } from '@fortawesome/free-regular-svg-icons';
import { faFlag, faSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectAnswer, selectAttemptId, setAnswerIsFlagged } from 'actions/studentAttemptActions';
import { selectUserLoggedIn } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import useStyles from './styles';

const AnswerFlagger = ({ answerId, sectionId }) => {
  const classes = useStyles();
  const toast = useToast();
  const dispatch = useDispatch();
  const loggedIn = useSelector(selectUserLoggedIn);
  const attemptId = useSelector(selectAttemptId);
  const [flagAnswerRequest] = useApi(api.flagAnswer, !loggedIn);
  const { isFlagged } = useSelector(selectAnswer(answerId, sectionId));

  const [flaggingLoading, setFlaggingLoading] = useState(false);

  const flagAnswer = useCallback(() => {
    setFlaggingLoading(true);
    flagAnswerRequest([attemptId, answerId], { isFlagged: !isFlagged }, ({ data }) => {
      if (data.status === 0) {
        dispatch(setAnswerIsFlagged(answerId, sectionId, !isFlagged));
      }

      if (data.status !== 0) {
        toast.error(lang.oops);
      }

      setFlaggingLoading(false);
    });
  }, [answerId, attemptId, dispatch, flagAnswerRequest, isFlagged, sectionId, toast]);

  return (
    <Button
      styleType='outlined'
      className={classes.flagButton}
      onClick={flagAnswer}
      loading={flaggingLoading}
    >
      <FontAwesomeIcon icon={isFlagged ? faFlag : faFlagRegular} />
      {isFlagged && <FontAwesomeIcon icon={faSlash} className={cx(classes.flagSlash, 'flag-slash')} />}
      {!isFlagged && lang.flag}
    </Button>
  );
};

AnswerFlagger.propTypes = {
  answerId: PropTypes.string.isRequired,
  sectionId: PropTypes.string,
};

export default AnswerFlagger;
