import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';

import lang from 'lang';
import routes from 'routes';

import CancelModal from '../rubric-form/CancelModal';
import PageHeader from 'components/common/layout/PageHeader';

const RubricsEditorPageHeader = ({ groups, group, setGroup, saveRubric, loading }) => {
  const history = useHistory();
  const { spaceId, rubricId } = useParams();

  const [cancelModal, setCancelModal] = useState(false);

  const onGroupChange = (newGroup) => {
    setGroup(newGroup);
    history.push({
      search: `?groupId=${newGroup.id}`,
    });
  };

  const path = [
    {
      type: 'text',
      text: rubricId ? lang.rubrics.editRubric : lang.rubrics.createRubric,
    },
    {
      type: 'text',
      text: lang.in,
    },
    {
      type: 'dropdown',
      id: 'groups',
      current: group,
      values: groups,
      onChange: onGroupChange,
      dataTour: 'rubrics-editor-page-header-group-dropdown',
    },
  ];

  const actions = [
    {
      label: lang.cancel,
      onClick: () => setCancelModal(true),
      color: 'black',
    },
    {
      label: lang.save,
      onClick: saveRubric,
      loading: loading,
      dataTour: 'rubrics-editor-page-header-save-button',
    },
  ];

  if (!group) {
    return null;
  }

  return (
    <Fragment>
      <CancelModal
        open={cancelModal}
        close={() => setCancelModal(false)}
        cancel={() => history.push(routes.rubrics.ref(spaceId, group.id))}
      />
      <PageHeader
        path={path}
        actions={actions}
        noSeparators
      />
    </Fragment>
  );
};

RubricsEditorPageHeader.propTypes = {
  groups: PropTypes.array,
  group: PropTypes.object,
  setGroup: PropTypes.func,
  saveRubric: PropTypes.func,
  loading: PropTypes.bool,
};

export default RubricsEditorPageHeader;
