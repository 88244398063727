import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  chart: {
    fontSize: '11px',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
}));
