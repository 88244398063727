import { createUseStyles } from 'react-jss';

export default createUseStyles({
  pauseItemCard: {
    width: '100% !important',
    padding: '20px',
    backgroundColor: '#fff',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pauseIcon: {
    fontSize: '100px',
  },
  statement: {
    padding: '0 10%',
    marginTop: '20px',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '10px',
    marginTop: '20px',
  },
});
