import React, { useState } from 'react';
import { faPlus, faSearch, faInfoCircle, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { selectFilters, setFilters as setSelectedFilters, setSearch } from 'actions/exploreActions';
import { selectUserSubjects, selectUserYears, selectUserCurriculum } from 'actions/userActions';
import useExploreFilters from 'hooks/explore/useExploreFilters';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import AddFilterDropdown from '../AddFilterDropdown';
import FilterPool from 'components/common/filter-selector/FilterPool';
import IconBtn from 'components/common/IconBtn';
import Input from 'components/common/Input';
import ResponsiveLang from 'components/common/ResponsiveLang';

import background from './background.svg';
import useStyles from './styles';

const ExploreHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userSubjects = useSelector(selectUserSubjects);
  const userYears = useSelector(selectUserYears);
  const userCurriculum = useSelector(selectUserCurriculum);
  const selectedFilters = useSelector(selectFilters);
  const [filters, setFilters] = useExploreFilters();
  const exploreCurriculumToggle = useFeature(toggles.exploreCurriculum);

  const searchTypes = [
    {
      type: 'all',
      label: lang.explore.searchAll,
    },
    {
      type: 'tests',
      label: lang.appKeywords.assessments,
    },
    {
      type: 'exercises',
      label: lang.appKeywords.exercises,
    },
  ];
  const [searchType, setSearchType] = useState(searchTypes[0]);
  const [searchInput, setSearchInput] = useState('');
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [contentDropdown, setContentDropdown] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const onSearch = (event) => {
    event.preventDefault();
    setIsSearching(false);
    if (searchInput !== '') {
      setIsSearching(true);
    }
    dispatch(setSearch(searchInput, searchType.type));
  };

  const searchTypeChange = (item) => {
    setSearchType(item);
    setContentDropdown(false);
    dispatch(setSearch(searchInput, item.type));
  };

  const toggleFilter = (selectedFilter) => {
    const newFilters = [...filters];
    const filter = newFilters.find(filter => filter.id === selectedFilter.id);

    let newSelectedFilters;
    if (filter.active) {
      newSelectedFilters = selectedFilters.filter(filter => filter.id !== selectedFilter.id);
    } else {
      newSelectedFilters = [...selectedFilters];
      newSelectedFilters.push(selectedFilter);
    }

    filter.active = !filter.active;
    setFilters(newFilters);
    dispatch(setSelectedFilters(newSelectedFilters));
  };

  return (
    <div
      data-tour="explore-header"
      style={{ backgroundImage: `url(${background})` }}
      className={classes.headerContainer}
    >
      <div className={classes.headerContent}>
        {exploreCurriculumToggle && (
          <div className={classes.curriculum}>
            {`${lang.curricula.curriculum}: `}
            <span className={classes.curriculumName}>
              {lang.curricula[userCurriculum.name]}
            </span>
          </div>
        )}
        {!isSearching && (
          <div className={classes.headerTitle}>
            <ResponsiveLang
              lang={lang.explore.header}
              xs={lang.explore.headerXs}
            />
          </div>
        )}
        <div className={cx(classes.exploreSearchContainer, { isSearching })}>
          <div className={classes.exploreSearchBarContainer}>
            <FontAwesomeIcon
              icon={faSearch}
              className={classes.exploreSearchIcon}
            />
            <form
              onSubmit={onSearch}
              className={classes.exploreSearchForm}
            >
              <Input
                dataTour="explore-header-text-input"
                placeholder={lang.explore.searchPlaceholder}
                value={searchInput}
                onChange={(event) => setSearchInput(event.target.value)}
                className={classes.exploreSearchBar}
              />
            </form>
            <div>
              |
            </div>
            <Dropdown close={() => setContentDropdown(false)} >
              <div
                data-tour="explore-header-content-dropdown"
                onClick={() => setContentDropdown(!contentDropdown)}
                className={classes.dropdownItem}
              >
                <div className={classes.dropdownItemLabel}>
                  {searchType.label}
                </div>
                <FontAwesomeIcon
                  className={classes.dropdownArrow}
                  icon={contentDropdown ? faChevronUp : faChevronDown}
                />
              </div>
              <DropdownMenu
                open={contentDropdown}
                className={classes.dropdownMenu}
              >
                {searchTypes.filter(el => el.type !== searchType.type).map(item => (
                  <DropdownMenuItem
                    key={item.type}
                    className={cx(classes.dropdownMenuItem, { selected: item.type === searchType.type })}
                    onClick={() => searchTypeChange(item)}
                  >
                    {item.label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div data-tour="explore-header-filters" className={classes.filterContainer}>
            <AddFilterDropdown
              open={filterDropdown}
              close={() => setFilterDropdown(false)}
              filters={filters}
              toggleFilter={toggleFilter}
            />
            <FilterPool
              poolItemAction={toggleFilter}
              poolItems={[
                {
                  id: 'filter-by-label',
                  name: `${lang.filterBy}:`,
                  type: 'label',
                },
                ...selectedFilters,
                {
                  id: 'add-filter',
                  name: lang.explore.addFilter,
                  icon: faPlus,
                  type: 'action',
                  action: () => setFilterDropdown(!filterDropdown),
                  extraProps: {
                    tabIndex: '0',
                  },
                },
              ]}
            />
            {userSubjects.length === 0 && userYears.length === 0 && (
              <div className={classes.infoBtn}>
                <IconBtn
                  icon={faInfoCircle}
                  tip={lang.explore.infoTip}
                  onClick={() => window.location = routes.profile.ref()}
                  iconStyle={{ fontSize: '20px' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreHeader;
