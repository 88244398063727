import React, { useEffect, useState, useReducer } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { selectTestGroupId } from 'actions/testActions';
import { incrementTotalExercises, selectUserFeatureToggleMaxUsages, selectUserTotalFreeExercises } from 'actions/userActions';
import api from 'api';
import { BY_ROW } from 'constants/exerciseGradingOptions';
import { DRAG_DROP } from 'constants/exerciseOptions';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { quillIsEmpty } from 'utils';

import AddContentContainer from '../AddContentContainer';
import UpgradePremium from 'components/common/plans/UpgradePremium';
import expressions from 'components/common/rich-text/FormulaModal/expressions';
import ExerciseForm from 'components/exercises/exercise-form/ExerciseForm';
import { tableReducer } from 'components/exercises/exercise-form/flow/ask-for-table/AskForTable/reducer';
import AskExerciseType from 'components/exercises/exercise-form/flow/AskExerciseType';

const DEFAULT_STATEMENT = { ops: [] };
const DEFAULT_TYPE = null;
const DEFAULT_CORRECT_TRUE_FALSE = null;
const DEFAULT_HAS_JUSTIFICATION = false;
const DEFAULT_GAPS = [];
const DEFAULT_IMAGE = null;
const DEFAULT_IS_SHORT_ANSWER = false;
const DEFAULT_CHARACTER_LIMIT = null;
const DEFAULT_OPTION = DRAG_DROP;
const DEFAULT_GRADING_OPTION = BY_ROW;
const DEFAULT_CHOICES = [
  { id: v4(), value: {}, isCorrect: false, identifier: 'choice_1', order: 0 },
  { id: v4(), value: {}, isCorrect: false, identifier: 'choice_2', order: 1 },
  { id: v4(), value: {}, isCorrect: false, identifier: 'choice_3', order: 2 },
  { id: v4(), value: {}, isCorrect: false, identifier: 'choice_4', order: 3 },
];
const DEFAULT_SHUFFLE_CHOICES = false;
const DEFAULT_ORDER_ITEMS = [
  { id: v4(), text: {}, identifier: 'order_1' },
  { id: v4(), text: {}, identifier: 'order_2' },
  { id: v4(), text: {}, identifier: 'order_3' },
  { id: v4(), text: {}, identifier: 'order_4' },
];
const DEFAULT_CONNECTORS = [
  { id: v4(), value: {}, columnOrder: 0, identifier: 'connector_1', order: 0 },
  { id: v4(), value: {}, columnOrder: 0, identifier: 'connector_2', order: 1 },
  { id: v4(), value: {}, columnOrder: 0, identifier: 'connector_3', order: 2 },
  { id: v4(), value: {}, columnOrder: 1, identifier: 'connector_4', order: 3 },
  { id: v4(), value: {}, columnOrder: 1, identifier: 'connector_5', order: 4 },
  { id: v4(), value: {}, columnOrder: 1, identifier: 'connector_6', order: 5 },
];
const DEFAULT_TABLE_CELLS = [
  {
    id: v4(),
    row: 0,
    col: 0,
    value: null,
    identifier: 'A_0',
    isCorrect: null,
  },
  {
    id: v4(),
    row: 1,
    col: 0,
    value: null,
    identifier: 'A_1',
    isCorrect: null,
  },
  {
    id: v4(),
    row: 2,
    col: 0,
    value: null,
    identifier: 'A_2',
    isCorrect: null,
  },
  {
    id: v4(),
    row: 0,
    col: 1,
    value: null,
    identifier: 'B_0',
    isCorrect: null,
  },
  {
    id: v4(),
    row: 1,
    col: 1,
    value: null,
    identifier: 'B_1',
    isCorrect: false,
    originalIdentifier: 'B_1',
  },
  {
    id: v4(),
    row: 2,
    col: 1,
    value: null,
    identifier: 'B_2',
    isCorrect: false,
    originalIdentifier: 'B_2',
  },
  {
    id: v4(),
    row: 0,
    col: 2,
    value: null,
    identifier: 'C_0',
    isCorrect: null,
  },
  {
    id: v4(),
    row: 1,
    col: 2,
    value: null,
    identifier: 'C_1',
    isCorrect: false,
    originalIdentifier: 'C_1',
  },
  {
    id: v4(),
    row: 2,
    col: 2,
    value: null,
    identifier: 'C_2',
    isCorrect: false,
    originalIdentifier: 'C_2',
  },
];
const DEFAULT_CONNECTIONS = [];
const DEFAULT_EXTRA_TEXT = { ops: [] };
const DEFAULT_EXTRA_TEXT_START_EXPANDED = false;
const DEFAULT_MODEL_ANSWER = null;
const DEFAULT_HAS_WORD_COUNT = true;

const CreateTestExercise = ({ sectionId, cancel, afterAdd }) => {
  const { testId, spaceId } = useParams();
  const toast = useToast();
  const groupId = useSelector(selectTestGroupId);
  const [createTemplateExerciseRequest] = useApi(api.createTemplateExercise);
  const [addExercisesRequest] = useApi(api.addExercises);
  const totalFreeExercises = useSelector(selectUserTotalFreeExercises);
  const createExerciseToggle = useFeature(toggles.createExercise, totalFreeExercises);
  const dispatch = useDispatch();
  const createExerciseToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createExercise));

  const [addLoading, setAddLoading] = useState(false);

  const [statement, setStatement] = useState(DEFAULT_STATEMENT);
  const [currentType, setCurrentType] = useState(DEFAULT_TYPE);
  const [correctTrueFalse, setCorrectTrueFalse] = useState(DEFAULT_CORRECT_TRUE_FALSE);
  const [hasJustification, setHasJustification] = useState(DEFAULT_HAS_JUSTIFICATION);
  const [gaps, setGaps] = useState(DEFAULT_GAPS);
  const [image, setImage] = useState(DEFAULT_IMAGE);
  const [isShortAnswer, setIsShortAnswer] = useState(DEFAULT_IS_SHORT_ANSWER);
  const [characterLimit, setCharacterLimit] = useState(DEFAULT_CHARACTER_LIMIT);
  const [option, setOption] = useState(DEFAULT_OPTION);
  const [gradingOption, setGradingOption] = useState(DEFAULT_GRADING_OPTION);
  const [choices, setChoices] = useState(DEFAULT_CHOICES);
  const [shuffleChoices, setShuffleChoices] = useState(DEFAULT_SHUFFLE_CHOICES);
  const [orderItems, setOrderItems] = useState(DEFAULT_ORDER_ITEMS);
  const [extraText, setExtraText] = useState(DEFAULT_EXTRA_TEXT);
  const [extraTextStartExpanded, setExtraTextStartExpanded] = useState(DEFAULT_EXTRA_TEXT_START_EXPANDED);
  const [connectors, setConnectors] = useState(DEFAULT_CONNECTORS);
  const [connections, setConnections] = useState(DEFAULT_CONNECTIONS);
  const [enableMathSymbols, setEnableMathSymbols] = useState(false);
  const [mathSymbols, setMathSymbols] = useState(expressions);
  const [modelAnswer, setModelAnswer] = useState(DEFAULT_MODEL_ANSWER);
  const [hasWordCount, setHasWordCount] = useState(DEFAULT_HAS_WORD_COUNT);
  const [exerciseCells, tableDispatcher] = useReducer(tableReducer, DEFAULT_TABLE_CELLS);

  const reset = () => {
    setStatement(DEFAULT_STATEMENT);
    setCurrentType(DEFAULT_TYPE);
    setCorrectTrueFalse(DEFAULT_CORRECT_TRUE_FALSE);
    setHasJustification(DEFAULT_HAS_JUSTIFICATION);
    setGaps(DEFAULT_GAPS);
    setImage(DEFAULT_IMAGE);
    setIsShortAnswer(DEFAULT_IS_SHORT_ANSWER);
    setCharacterLimit(DEFAULT_CHARACTER_LIMIT);
    setOption(DEFAULT_OPTION);
    setGradingOption(DEFAULT_GRADING_OPTION);
    setChoices(DEFAULT_CHOICES);
    setShuffleChoices(DEFAULT_SHUFFLE_CHOICES);
    setOrderItems(DEFAULT_ORDER_ITEMS);
    setExtraText(DEFAULT_EXTRA_TEXT);
    setExtraTextStartExpanded(DEFAULT_EXTRA_TEXT_START_EXPANDED);
    setConnectors(DEFAULT_CONNECTORS);
    setConnections(DEFAULT_CONNECTIONS);
    setEnableMathSymbols(true);
    setModelAnswer(DEFAULT_MODEL_ANSWER);
    setHasWordCount(DEFAULT_HAS_WORD_COUNT);
    tableDispatcher({ type: 'SET_CELLS', payload: DEFAULT_TABLE_CELLS });
  };

  const saveExercise = () => {
    if (quillIsEmpty(statement)) {
      toast.warning(lang.exerciseForm.noEmptyStatement);
      return;
    }

    const newExercise = {
      statement: statement,
      type: currentType,
      groupId: groupId,
      image: (image && currentType === 'caption') ? image : '',
      labels: [],
      hasJustification: hasJustification,
      option: option,
      gradingOption: gradingOption,
      extraText: extraText,
      extraTextStartExpanded: extraTextStartExpanded,
      shuffleChoices: shuffleChoices,
      enableMathSymbols: enableMathSymbols,
      mathSymbols: mathSymbols,
      modelAnswer: modelAnswer,
      hasWordCount: hasWordCount,
    };

    if (currentType === 'text') {
      if (isShortAnswer && !characterLimit) {
        toast.warning(lang.exerciseForm.characterLimitError);
        return;
      }
      newExercise.isShortAnswer = isShortAnswer;
      newExercise.characterLimit = characterLimit;
    }

    if (currentType === 'choices') {
      let hasCorrect = false;
      for (let i = 0; i < choices.length; i++) {
        if (quillIsEmpty(choices[i].value)) {
          toast.warning(lang.exerciseForm.noEmptyOption);
          return;
        }

        hasCorrect = hasCorrect || choices[i].isCorrect;
      }

      if (!hasCorrect) {
        toast.warning(lang.exerciseForm.noAnswerChoiceDefined);
        return;
      }

      newExercise.choices = choices.map(choice => ({
        value: choice.value,
        isCorrect: choice.isCorrect,
        identifier: choice.identifier,
        order: choice.order,
      }));
    }

    if (currentType === 'true-false') {
      if (correctTrueFalse === null) {
        toast.warning(lang.exerciseForm.noAnswerChoiceDefined);
        return;
      }

      newExercise.correctTrueFalse = correctTrueFalse;
    }

    if (currentType === 'filling') {
      const correctGaps = gaps.filter(gap => gap.isCorrect);

      if (correctGaps.length === 0) {
        toast.warning(lang.exerciseForm.noAnswerGapDefined);
        return;
      }

      if (option === 'dropdown') {
        for (let i = 0; i < correctGaps.length; i++) {
          const correctGap = correctGaps[i];
          const optionGaps = gaps.filter(gap => !gap.isCorrect && gap.position === correctGap.position);
          if (optionGaps.length === 0) {
            toast.warning(lang.exerciseForm.noExtraWordDefined);
            return;
          }
        }
      }

      newExercise.gaps = gaps.map(gap => ({
        position: gap.position,
        text: gap.text,
        isCorrect: gap.isCorrect,
        order: gap.order,
        identifier: gap.identifier,
      }));
    }

    if (currentType === 'ordering') {
      const newOrderItems = [];

      for (let i = 0; i < orderItems.length; i++) {
        const item = orderItems[i];

        if (quillIsEmpty(item.text)) {
          toast.warning(lang.exerciseForm.noEmptyItem);
          return;
        }

        newOrderItems.push({
          text: item.text,
          order: i,
          identifier: item.identifier,
        });
      }
      newExercise.orderItems = newOrderItems;
    }

    if (currentType === 'caption') {
      const correctGaps = gaps.filter(gap => gap.isCorrect);

      if (correctGaps.length === 0) {
        toast.warning(lang.exerciseForm.noAnswerGapDefined);
        return;
      }

      for (let i = 0; i < correctGaps.length; i++) {
        const correctGap = correctGaps[i];

        if (correctGap.text.trim() === '') {
          toast.warning(lang.exerciseForm.noEmptyItem);
          return;
        }

        if (option === 'dropdown') {
          const optionGaps = gaps.filter(gap => !gap.isCorrect && gap.gapCoords.x === correctGap.gapCoords.x && gap.gapCoords.y === correctGap.gapCoords.y);
          if (optionGaps.length === 0) {
            toast.warning(lang.exerciseForm.noExtraWordDefined);
            return;
          }
        }

        newExercise.gaps = gaps.map(gap => ({
          text: gap.text,
          gapCoords: gap.gapCoords,
          pointCoords: gap.pointCoords,
          isCorrect: gap.isCorrect,
          order: gap.order,
          identifier: gap.identifier,
        }));
      }
    }

    if (currentType === 'connecting') {
      if (connectors.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectors);
        return;
      }

      if (connectors.filter(el => el.columnOrder === 0).length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectorsLeft);
        return;
      }

      if (connectors.filter(el => el.columnOrder === 1).length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectorsRight);
        return;
      }

      for (let i = 0; i < connectors.length; i++) {
        if (quillIsEmpty(connectors[i].value)) {
          toast.warning(lang.exerciseForm.connecting.noEmptyConnector);
          return;
        }
      }

      if (connections.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnections);
        return;
      }

      newExercise.connectors = connectors.map(connector => ({
        value: connector.value,
        columnOrder: connector.columnOrder,
        identifier: connector.identifier,
        order: connector.order,
      }));

      newExercise.connections = connections.map(connection => ({
        start: connectors.find(el => el.id === connection.start).order,
        end: connectors.find(el => el.id === connection.end).order,
      }));
    }

    if (currentType === 'segmentation') {
      if (connectors.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectors);
        return;
      }

      if (connections.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnections);
        return;
      }

      newExercise.connectors = connectors.map(connector => ({
        value: { ops: [''] },
        coords: connector.coords,
        columnOrder: 0,
        identifier: connector.identifier,
        order: connector.order,
      }));

      newExercise.connections = connections.map(connection => ({
        start: connectors.find(el => el.id === connection.start).order,
        end: connectors.find(el => el.id === connection.end).order,
      }));

      newExercise.image = image;
    }

    if (currentType === 'table') {
      const hasEmptyHeaderCells = exerciseCells
        .filter(cell => (cell.row === 0 && cell.col !== 0) || (cell.row !== 0 && cell.col === 0))
        .some(cell => quillIsEmpty(cell.value));
      if (hasEmptyHeaderCells) {
        toast.warning(lang.exerciseForm.table.noEmptyHeaderCell);
        return false;
      }

      newExercise.exerciseCells = exerciseCells.map(cell => ({
        value: cell.value,
        row: cell.row,
        col: cell.col,
        identifier: cell.identifier,
        isCorrect: cell.isCorrect,
      }));
    }

    setAddLoading(true);
    createTemplateExerciseRequest([], newExercise, ({ data }) => {
      if (data.status === 0) {
        if (spaceId === PERSONAL_SPACE) {
          dispatch(incrementTotalExercises(1));
        }

        addExercisesRequest([testId], { templateExerciseIds: [data.templateExercise.id], sectionId }, ({ data }) => {
          setAddLoading(false);

          if (data.status === 0) {
            toast.success(lang.test.successAddExercises);
            reset();
            afterAdd();
          } else {
            toast.error(lang.oops);
          }
        });
      } else {
        toast.error(lang.oops);
      }
    });
  };

  const nexStep = (type) => {
    if (!currentType) {
      setCurrentType(type);
    } else {
      saveExercise();
    }
  };

  const goBack = () => {
    if (!currentType) {
      cancel();
    }

    reset();
  };

  const actions = [
    {
      label: lang.cancel,
      color: 'black',
      onClick: goBack,
    },
    {
      label: lang.confirm,
      onClick: nexStep,
      loading: addLoading,
      hide: !currentType,
      dataTour: 'assessment-exercise-submit',
    },
  ];

  useEffect(() => {
    if (currentType === 'segmentation') {
      setConnectors([]);
      setOption(null);
    }
  }, [currentType]);

  return (
    <AddContentContainer
      title={lang.test.addExercises}
      goBack={goBack}
      actions={actions}
    >
      {!createExerciseToggle &&
        <UpgradePremium
          message={lang.plans.exceededExercises(createExerciseToggleMaxUsages)}
        />
      }
      {createExerciseToggle && !currentType &&
        <AskExerciseType
          selected={currentType}
          setSelected={setCurrentType}
          nextStep={nexStep}
          cancel={cancel}
          goBack={goBack}
        />
      }
      {createExerciseToggle && currentType &&
        <ExerciseForm
          type={currentType}
          statement={statement}
          setStatement={setStatement}
          shuffleChoices={shuffleChoices}
          setShuffleChoices={setShuffleChoices}
          choices={choices}
          setChoices={setChoices}
          gaps={gaps}
          setGaps={setGaps}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          correctTrueFalse={correctTrueFalse}
          setCorrectTrueFalse={setCorrectTrueFalse}
          hasJustification={hasJustification}
          setHasJustification={setHasJustification}
          image={image}
          setImage={setImage}
          isShortAnswer={isShortAnswer}
          setIsShortAnswer={setIsShortAnswer}
          characterLimit={characterLimit}
          setCharacterLimit={setCharacterLimit}
          option={option}
          setOption={setOption}
          gradingOption={gradingOption}
          setGradingOption={setGradingOption}
          extraText={extraText}
          setExtraText={setExtraText}
          extraTextStartExpanded={extraTextStartExpanded}
          setExtraTextStartExpanded={setExtraTextStartExpanded}
          connectors={connectors}
          setConnectors={setConnectors}
          connections={connections}
          setConnections={setConnections}
          enableMathSymbols={enableMathSymbols}
          setEnableMathSymbols={setEnableMathSymbols}
          mathSymbols={mathSymbols}
          setMathSymbols={setMathSymbols}
          modelAnswer={modelAnswer}
          setModelAnswer={setModelAnswer}
          exerciseCells={exerciseCells}
          tableDispatcher={tableDispatcher}
          edit
          hasWordCount={hasWordCount}
          setHasWordCount={setHasWordCount}
        />
      }
    </AddContentContainer>
  );
};

CreateTestExercise.propTypes = {
  sectionId: PropTypes.string,
  cancel: PropTypes.func,
  afterAdd: PropTypes.func,
};

export default CreateTestExercise;
