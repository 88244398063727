/**
 * Action constants
 */
const OPEN_FORMULA_MODAL = 'OPEN_FORMULA_MODAL';
const CLOSE_FORMULA_MODAL = 'CLOSE_FORMULA_MODAL';

/**
 * Action creators
 */
const openFormulaModal = (options) => ({
  type: OPEN_FORMULA_MODAL,
  options,
});

const closeFormulaModal = () => ({
  type: CLOSE_FORMULA_MODAL,
});

/**
 * Selectors
 */
const selectFormulaModalOptions = (state) => {
  return state.formulaModal;
};

export {
  // Action constants
  OPEN_FORMULA_MODAL,
  CLOSE_FORMULA_MODAL,
  // Action creators
  openFormulaModal,
  closeFormulaModal,
  // Selectors
  selectFormulaModalOptions,
};
