import { createUseStyles } from 'react-jss';

export default createUseStyles({
  wrapper: {
    display: 'flex',
  },
  container: {
    backgroundColor: '#cecece',
    borderRadius: '22px',
  },
  settingsDisplay: {
    padding: '5px 10px 0 10px',
  },
});
