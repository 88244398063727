import React, { Fragment, useState } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { selectTestGroupId } from 'actions/testActions';
import { selectUserFeatureToggleMaxUsages, selectUserTotalFreeRubrics } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import useForm from 'hooks/useForm';
import lang from 'lang';
import toggles from 'toggles';
import { validateRubric } from 'utils/rubrics';

import Button from 'components/common/Button';
import IconBtn from 'components/common/IconBtn';
import UpgradePremium from 'components/common/plans/UpgradePremium';
import RubricForm from 'components/rubrics/rubric-form/RubricForm';

import useStyles from './styles';

const CreateTestRubric = ({ cancel, afterSubmit }) => {
  const classes = useStyles();
  const toast = useToast();
  const { testId } = useParams();
  const groupId = useSelector(selectTestGroupId);
  const [createTemplateRubricRequest] = useApi(api.createTemplateRubric);
  const [setTestRubricRequest] = useApi(api.setTestRubric);
  const totalFreeRubrics = useSelector(selectUserTotalFreeRubrics);
  const createRubricToggle = useFeature(toggles.createRubric, totalFreeRubrics);
  const createRubricToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createRubric));

  const [loading, setLoading] = useState(false);
  const [getValue, setValue, getErrors, setErrors] = useForm([
    { label: 'name', initialValue: '' },
    { label: 'description', initialValue: '' },
    {
      label: 'criteria',
      initialValue: [
        {
          name: '',
          open: true,
          performanceLevels: [
            { level: 1, description: '' },
            { level: 2, description: '' },
            { level: 3, description: '' },
            { level: 4, description: '' },
            { level: 5, description: '' },
          ],
        },
      ],
      initialErrors: {
        general: [],
        criteria: [
          {
            name: [],
          },
        ],
      },
    },
  ]);

  const createTestRubric = () => {
    const newTemplateRubric = {
      name: getValue('name'),
      description: getValue('description'),
      criteria: getValue('criteria').map(criterion => ({
        name: criterion.name,
        performanceLevels: criterion.performanceLevels,
      })),
      groupId: groupId,
    };

    if (!validateRubric(newTemplateRubric, setErrors)) {
      return;
    }

    setLoading(true);
    createTemplateRubricRequest([], newTemplateRubric, ({ data }) => {
      if (data.status === 0) {
        setTestRubricRequest([testId], { templateRubricId: data.templateRubricId }, ({ data }) => {
          if (data.status === 0) {
            setTimeout(() => {
              setLoading(false);
              toast.success(lang.test.rubric.successSetRubric);
              afterSubmit();
            }, 300);
            return;
          }

          setTimeout(() => {
            setLoading(false);
            toast.error(lang.oops);
          }, 200);
        });

        return;
      }

      setTimeout(() => {
        setLoading(false);
        toast.error(lang.oops);
      }, 200);
    });
  };

  const goBack = () => {
    if (loading) {
      return;
    }

    cancel();
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <div className={classes.titleWrap}>
          <Render when={cancel}>
            <IconBtn
              icon={faArrowLeft}
              tip={lang.goBack}
              onClick={goBack}
            />
          </Render>
          <div className={classes.title}>
            {lang.test.rubric.createRubric}
          </div>
        </div>
      </div>
      <Render when={!createRubricToggle}>
        <UpgradePremium
          message={lang.plans.exceededRubrics(createRubricToggleMaxUsages)}
        />
      </Render>
      <Render when={createRubricToggle}>
        <RubricForm
          getValue={getValue}
          setValue={setValue}
          getErrors={getErrors}
          setErrors={setErrors}
        />
        <div className={classes.btnContainer}>
          <Button
            onClick={createTestRubric}
            className={classes.btn}
            loading={loading}
          >
            {lang.create}
          </Button>
          <Button
            onClick={goBack}
            className={classes.btn}
            black
          >
            {lang.cancel}
          </Button>
        </div>
      </Render>
    </Fragment>
  );
};

CreateTestRubric.propTypes = {
  cancel: PropTypes.func,
  afterSubmit: PropTypes.func,
};

export default CreateTestRubric;
