import React, { createRef, Fragment, useEffect, useState } from 'react';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import lang from 'lang';

import Button from 'components/common/Button';
import Input from 'components/common/Input';

import useStyles from './styles';

const ExerciseWeights = ({ criteriaWeights, saveWeight, defining }) => {
  const classes = useStyles();
  const history = useHistory();
  const toast = useToast();

  const [currentWeights, setCurrentWeights] = useState(criteriaWeights);
  const [totalWeight, setTotalWeight] = useState(0);

  useEffect(() => {
    if (criteriaWeights) {
      setCurrentWeights(currentWeights => {
        currentWeights.forEach(weight => {
          weight.ref = createRef();
        });
        return currentWeights;
      });
    }
  }, [criteriaWeights]);

  useEffect(() => {
    let newTotalWeight = 0;

    if (currentWeights) {
      currentWeights.forEach((currentWeight) => {
        newTotalWeight += currentWeight.weight;
      });
    }

    setTotalWeight(newTotalWeight);
  }, [currentWeights]);

  const onSubmit = (event, criteriaWeight) => {
    event.preventDefault();
    criteriaWeight.ref.current.blur();
  };

  const updateWeight = (criteriaWeightId, newWeight) => {
    const newWeights = currentWeights.map(weight => ({ ...weight }));

    const weightIndex = newWeights.findIndex(weight => weight.id === criteriaWeightId);
    newWeights[weightIndex].weight = newWeight;

    setCurrentWeights(newWeights);
  };

  const _saveWeight = (criteriaWeight) => {
    if (!criteriaWeight.weight && criteriaWeight.weight !== 0) {
      toast.warning(lang.test.rubric.notEmpty);
      return;
    }

    if (criteriaWeight.weight < 0) {
      toast.warning(lang.test.rubric.negativeValueError);
      return;
    }

    if (criteriaWeight.weight > 100) {
      toast.warning(lang.test.rubric.weightTooHigh);
      return;
    }

    saveWeight(criteriaWeight.id, criteriaWeight.weight);
  };

  const getWeights = () => {
    if (currentWeights) {
      return currentWeights.map(criteriaWeight => (
        <Render
          key={criteriaWeight.id}
          when={defining || criteriaWeight.weight !== 0}
        >
          <div className={classes.criteriaWeight}>
            <Render when={!defining}>
              <span className={classes.criteriaText}>
                {criteriaWeight.weight}
                %
              </span>
            </Render>
            <span className={classes.criteriaText}>
              {criteriaWeight.criteriaName}
            </span>
            <Render when={defining}>
              <form onSubmit={(event) => onSubmit(event, criteriaWeight)}>
                <Input
                  type="number"
                  value={criteriaWeight.weight}
                  className={classes.weightInput}
                  onChange={(event) => updateWeight(criteriaWeight.id, parseInt(event.target.value))}
                  onBlur={() => _saveWeight(criteriaWeight)}
                  minSize={0}
                  maxSize={100}
                  errors={totalWeight !== 100}
                  _ref={criteriaWeight.ref}
                />
              </form>
              <span className={classes.criteriaText}>
                %
              </span>
            </Render>
          </div>
        </Render>
      ));
    }

    return (
      <Fragment>
        {lang.test.rubric.noRubric}
        <Button onClick={() => history.replace({ search: '?tab=rubric' })}>
          {lang.add}
        </Button>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {getWeights()}
      <Render when={currentWeights && totalWeight !== 100}>
        <div className={classes.errorTotal}>
          {lang.test.rubric.errorTotal}
        </div>
      </Render>
    </Fragment>
  );
};

ExerciseWeights.propTypes = {
  criteriaWeights: PropTypes.array,
  saveWeight: PropTypes.func,
  defining: PropTypes.bool,
};

export default ExerciseWeights;
