import { deepCopy } from 'utils';

const setAnswerIsFlagged = (studentAttempt, answerId, sectionId, isFlagged) => {
  const newStudentAttempt = deepCopy(studentAttempt);

  let item;
  let navigationItem;

  if (sectionId) {
    const section = newStudentAttempt.attemptItems
      .find((item) => item.id === sectionId && item.itemType === 'section');
    const navigationSection = newStudentAttempt.navigationItems.find(el => el.id === sectionId && el.itemType === 'section');

    if (!section || !navigationSection) {
      return;
    }

    item = section.exercises
      .find((exercise) => exercise.answerId === answerId);
    navigationItem = navigationSection.exercises.find(el => el.id === item.answerId);
  } else {
    item = newStudentAttempt.attemptItems
      .find((item) => item.answerId === answerId && item.itemType === 'exercise');
    navigationItem = newStudentAttempt.navigationItems.find(el => el.id === answerId && el.itemType === 'exercise');
  }

  item.isFlagged = isFlagged;
  navigationItem.isFlagged = isFlagged;

  return newStudentAttempt;
};

export default setAnswerIsFlagged;
