import React, { forwardRef } from 'react';
import { faGripVertical, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import lang from 'lang';

import Input from 'components/common/Input';

import useStyles from './styles';

const InnerCaption = forwardRef(({ ...props }, ref) => {
  const classes = useStyles();
  const { removeCaption, editCaption, gap, restricted } = props;

  const onChange = (event) => {
    editCaption(gap.id, event.target.value);
  };

  return (
    <div
      className={classes.captionCard}
      {...props}
      ref={ref}
    >
      {!restricted &&
      <FontAwesomeIcon
        icon={faGripVertical}
      />
      }
      <Input
        type="text"
        placeholder={lang.exerciseForm.caption}
        onChange={(event) => onChange(event)}
        value={gap.text}
      />
      {!restricted &&
      <FontAwesomeIcon
        icon={faTimes}
        className={classes.icon}
        onClick={removeCaption}
      />
      }
    </div>
  );
});

InnerCaption.propTypes = {
  removeCaption: PropTypes.func,
  editCaption: PropTypes.func,
  gap: PropTypes.object,
  styles: PropTypes.object,
  restricted: PropTypes.bool,
};
InnerCaption.displayName = 'InnerCaption';

export default InnerCaption;
