import React from 'react';
import lang from 'lang.js';
import PropTypes from 'prop-types';

import FilterSelector from 'components/common/filter-selector/FilterSelector';

import useStyles from './styles.js';

const TypeSelector = ({ types, filters, setFilters }) => {
  const classes = useStyles();

  const itemAction = (itemtoAdd) => {
    setFilters({ ...filters, types: [...filters.types, itemtoAdd] });
  };

  return (
    <div className={classes.typeSelectorContainer} >
      <FilterSelector
        itemAction={itemAction}
        itemOptions={types}
        currentItems={filters.types}
        contextPlaceholder={lang.exercises.typeFilter}
      />
    </div>
  );
};

TypeSelector.propTypes = {
  types: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};
export default TypeSelector;
