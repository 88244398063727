import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  compactedChoices: {
    display: 'grid',
    rowGap: '10px',
    padding: '10px',
    gridTemplateColumns: 'min-content max-content fit-content(100%) min-content',
    alignItems: 'center',
    '&.isCompact': {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, auto)',
      gap: '10px',
    },
  },
  choice: {
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  [`@media (max-width: ${theme.lg}px)`]: {
    compactedChoices: {
      '&.isCompact': {
        gridTemplateColumns: 'repeat(4, auto)',
      },
    },
  },
  [`@media (max-width: ${theme.md}px)`]: {
    compactedChoices: {
      '&.isCompact': {
        gridTemplateColumns: 'repeat(3, auto)',
      },
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    compactedChoices: {
      '&.isCompact': {
        gridTemplateColumns: 'repeat(2, auto)',
      },
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    compactedChoices: {
      '&.isCompact': {
        gridTemplateColumns: 'repeat(1, auto)',
      },
    },
  },
}));
