import { SET_ATTEMPT, UPDATE_GRADE, UPDATE_CRITERIA_GRADE, UPDATE_FEEDBACK } from 'actions/attemptActions';
import { updateAttemptAnswerGrade, updateAttemptCriteriaGrade, updateAttemptFeedback } from 'utils/attempts';

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ATTEMPT:
      return action.attempt;
    case UPDATE_GRADE:
      return updateAttemptAnswerGrade(state, action.answerId, action.grade, action.sectionId);
    case UPDATE_CRITERIA_GRADE:
      return updateAttemptCriteriaGrade(state, action.answerId, action.criteriaGradeId, action.grade, action.sectionId);
    case UPDATE_FEEDBACK:
      return updateAttemptFeedback(state, action.answerId, action.feedback, action.sectionId);
    default:
      return state;
  }
};

export default reducer;
