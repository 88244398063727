import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  attemptContainer: {
    width: '100%',
    textAlign: 'center',
    color: theme.textColor2,
  },
  attemptHeader: {
    marginBottom: '20px',
    width: '100% !important',
    borderRadius: '18px',
    overflow: 'hidden',
    boxShadow: '0 4px 4px #00000040',
  },
  attemptInfoContainer: {
    padding: '10px 20px 0px 20px',
  },
  headerStudentName: {
    fontSize: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoSection: {
    color: theme.textColor2,
    fontSize: '14px',
    marginBottom: '10px',
  },
  infoHeader: {
    fontWeight: '600',
    marginBottom: '5px',
  },
  grade: {
    color: theme.successColor,
    '&.failed': {
      color: theme.errorColor,
    },
  },
  linkHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  clipboard: {
    color: theme.textColor5,
    marginLeft: '10px',
    cursor: 'pointer',
    '&.selected': {
      color: theme.successColor,
    },
    '&.disabled': {
      opacity: 0.6,
      cursor: 'default',
    },
  },
  // Admin attempt header
  adminInfoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  adminAttemptHeader: {
    marginBottom: '20px',
    width: '100% !important',
    borderRadius: '18px',
    overflow: 'hidden',
    boxShadow: '0 4px 4px #00000040',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  adminInfoContainer: {
    padding: '10px 20px 0px 20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropdownButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 10px 8px 8px',
  },
  dropdownIcon: {
    marginLeft: '10px',
    verticalAlign: 'middle',
    fontSize: '12px',
  },
  validationIcon: {
    verticalAlign: 'middle',
  },
  flexCol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  startCol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  adminInfoHeader: {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  adminInfoHeaderSection: {
    color: theme.textColor3,
    opacity: '0.75',
  },
  adminInfoValue: {
    marginLeft: '5px',
    fontWeight: '500',
  },
  dropdownWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));
