import { deepCopy } from 'utils';

const updateSectionEnded = (studentAttempt, sectionId) => {
  const newStudentAttempt = deepCopy(studentAttempt);

  if (sectionId) {
    const section = newStudentAttempt.attemptItems
      .find((item) => item.id === sectionId && item.itemType === 'section');

    if (!section) {
      return;
    }

    section.sectionEnded = true;
  }

  return newStudentAttempt;
};

export default updateSectionEnded;
