import { createUseStyles } from 'react-jss';

export default createUseStyles({
  tableCell: {
    border: 'none',
    marginTop: '10px',
    '&.disabled': {
      opacity: '0.4',
    },
  },
  toggle: {
    display: 'flex',
    justifyContent: 'center',
    '&.disabled': {
      opacity: '0.4',
    },
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
  },
});
