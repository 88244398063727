import React from 'react';
import { Toggle } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { DRAG_DROP, DROPDOWN, WRITE } from 'constants/exerciseOptions';
import { CAPTION, FILLING } from 'constants/exerciseTypes';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import FlowStep from '../FlowStep';
import Card from 'components/common/Card';
import SymbolsButtons from 'components/common/rich-text/SymbolsButtons';
import InnerGap from 'components/exercises/exercise/exercise-answer/exercise-answer-filling/InnerGap';

import useStyles from './styles';

const AskForGapOptions = ({ type, number, option, setOption, setGaps, mathSymbols, setMathSymbols, enableMathSymbols, setEnableMathSymbols }) => {
  const classes = useStyles();
  const ftgDropdownToggle = useFeature(toggles.ftgDropdown);
  const captionDropdownToggle = useFeature(toggles.captionDropdown);
  const ftgWriteToggle = useFeature(toggles.ftgWrite);
  const captionWriteToggle = useFeature(toggles.captionWrite);

  const onClick = (gap) => {
    setOption(gap);
    setGaps(gaps => gaps.filter(el => el.isCorrect));
  };

  const optionValues = [
    {
      name: lang.exerciseForm.gap.dragDropOption,
      example: (
        <div className={classes.draggableGap}>
          <InnerGap
            item={{
              text: lang.exerciseForm.gap.word,
            }}
            draggable
          />
        </div>
      ),
      visible: true,
      value: DRAG_DROP,
    },
    {
      name: lang.exerciseForm.gap.dropdownOption,
      example: (
        <select
          className={classes.gap}
          disabled
        >
          <option value="" disabled selected>
            {lang.exerciseForm.gap.word}
          </option>
        </select>
      ),
      visible: (type === FILLING && ftgDropdownToggle) || (type === CAPTION && captionDropdownToggle),
      value: DROPDOWN,
    },
    {
      name: lang.exerciseForm.gap.writeOption,
      example: (
        <div className={classes.gap}>
          {lang.exerciseForm.gap.word}
        </div>
      ),
      visible: (type === FILLING && ftgWriteToggle) || (type === CAPTION && captionWriteToggle),
      value: WRITE,
    },
  ];

  return (
    <FlowStep
      stepNumber={number}
      header={lang.exerciseForm.gap.choosingGapExtraOption}
    >
      <div className={classes.extraOptionsContainer}>
        {optionValues.map((optionValue) => (
          <Render
            key={optionValue.name}
            when={optionValue.visible}
          >
            <Card
              className={cx(classes.optionCard, { selected: option === optionValue.value })}
              onClick={() => onClick(optionValue.value)}
            >
              <div className={classes.header}>
                {optionValue.name}
              </div>
              <div className={classes.wrapper}>
                <div className={classes.example}>
                  {lang.exerciseForm.gap.example}
                  {':'}
                  {optionValue.example}
                </div>
                <div className={classes.correction}>
                  {lang.exerciseForm.gap.correctionAuto}
                </div>
              </div>
            </Card>
          </Render>
        ))}
      </div>
      <Render when={type === FILLING && option === WRITE}>
        <div className={classes.toggleWrapper}>
          {lang.exerciseForm.useMathSymbols}
          <Toggle
            checked={enableMathSymbols}
            onChange={(checked) => setEnableMathSymbols(checked)}
          />
        </div>
        <Render when={enableMathSymbols}>
          <div className={classes.toggleWrapper}>
            <SymbolsButtons
              mathSymbols={mathSymbols}
              setMathSymbols={setMathSymbols}
            />
          </div>
        </Render>
      </Render>
    </FlowStep>
  );
};

AskForGapOptions.propTypes = {
  type: PropTypes.string,
  number: PropTypes.number,
  option: PropTypes.string,
  setOption: PropTypes.func,
  setGaps: PropTypes.func,
  enableMathSymbols: PropTypes.bool,
  setEnableMathSymbols: PropTypes.func,
  mathSymbols: PropTypes.object,
  setMathSymbols: PropTypes.func,
};

export default AskForGapOptions;
