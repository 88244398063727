import React from 'react';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { BY_ROW, BY_TABLE } from 'constants/gradingOptions';

import AnswerHeaderCell from '../AnswerHeaderCell';
import ExerciseChoice from 'components/exercises/exercise/exercise-answer/ExerciseChoice';

import useStyles from './styles';

const AnswerTableCell = ({ col, row, onClick, answer, answerable, correctAnswers, correction, gradingOption, rowIndex, correctRows, correctTable, tableGrading, ...restProps }) => {
  const classes = useStyles();
  let childNode = restProps.children;

  if (col === 0) {
    childNode = (
      <AnswerHeaderCell
        col={col}
        row={row}
        {...restProps}
      />
    );
  } else if (restProps.isCell){
    const correctRow = !!correctRows?.includes(row);
    childNode = (
      <td {...restProps}
        onClick={() => onClick()} className={cx(classes.exerciseCell, { correction: !answerable, correctRow, correctTable, rowGrading: !!correctRows })}
      >
        <div className={classes.exerciseChoiceWrapper} >
          <ExerciseChoice
            name={`${row}-${col}`}
            hasMultipleCorrect
            checked={(answerable || correction ) ? answer : restProps.isCorrect}
            disabled={!answerable}
          />
          {restProps.isCorrect && correction &&
          <td className={classes.correctionWrapper}>
            <FontAwesomeIcon icon={faCheck} className={classes.correctCellIcon} />
          </td>
          }
        </div>
        { restProps.exportIdentifiersToggle && !answerable &&
          <div className={classes.identifier}>
            {restProps.identifier}
          </div>
        }
      </td>
    );
  } else if (correctAnswers) {
    childNode = (
      <td className={classes.exerciseChoiceWrapper} {...restProps}>
        <FontAwesomeIcon icon={faCheck} className={classes.correctIcon} />
      </td>
    );
  } else if (tableGrading) {
    const isCorrect = gradingOption === BY_TABLE
      ? correctTable
      : gradingOption === BY_ROW ? correctRows?.includes(rowIndex + 1) : false;
    const iconProps = isCorrect ? { icon: faCheck, className: classes.correctIcon } : { icon: faXmark, className: classes.incorrectIcon };
    childNode = (
      <td className={classes.exerciseChoiceWrapper} {...restProps}>
        <FontAwesomeIcon {...iconProps} />
      </td>
    );
  }

  return childNode;
};

AnswerTableCell.propTypes = {
  col: PropTypes.number.isRequired,
  row: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  answer: PropTypes.array.isRequired,
  answerable: PropTypes.bool,
  correctAnswers: PropTypes.bool,
  correction: PropTypes.bool,
  rowIndex: PropTypes.number,
  gradingOption: PropTypes.string,
  correctRows: PropTypes.array,
  correctTable: PropTypes.array,
  tableGrading: PropTypes.bool,
};

export default AnswerTableCell;
