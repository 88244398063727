import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import Button from 'components/common/Button';

import useStyles from './styles';

const NoEntity = ({ message, action }) => {
  const classes = useStyles();

  return (
    <div className={classes.noGroupsContainer}>
      <div className={classes.noGroupsLabel}>
        {message}
      </div>
      <Render when={action}>
        <Button
          className={classes.noGroupButton}
          onClick={action}
        >
          {lang.create}
        </Button>
      </Render>
    </div>
  );
};

NoEntity.propTypes = {
  message: PropTypes.string,
  action: PropTypes.func,
};

export default NoEntity;
