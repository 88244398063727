import React, { useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import api from 'api';
import useApi from 'hooks/common/useApi';
import useLoading from 'hooks/common/useLoading';
import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const DeleteGroupsModal = ({ open, close, groupIds, reload }) => {
  const toast = useToast();
  const classes = useStyles();
  const [deleteGroupsRequest] = useApi(api.deleteGroups);

  const [loading, setLoading] = useLoading(false);

  const deleteGroups = useCallback(() => {
    setLoading(true);
    deleteGroupsRequest([], { groupIds }, ({ data }) => {
      if (data.status === 0) {
        close();
        setLoading(false);
        toast.success(lang.group.successDeleteGroup);
        reload();
      } else {
        setLoading(false);
        toast.error(lang.oops);
      }
    });
  }, [close, deleteGroupsRequest, groupIds, reload, setLoading, toast]);

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
    {
      name: lang.delete,
      color: 'red',
      onClick: deleteGroups,
      loading: loading,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={groupIds.length === 1 ? lang.group.deleteGroup : lang.group.deleteGroups}
      actions={actions}
      center
      transition
      small
    >
      <div className={classes.deleteGroupInfo}>
        {groupIds.length === 1 ? lang.group.deleteGroupInfo : lang.group.deleteGroupsInfo}
      </div>
    </Modal>
  );
};

DeleteGroupsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  groupIds: PropTypes.string,
  reload: PropTypes.func,
};

export default DeleteGroupsModal;
