import React, { useState, Fragment } from 'react';
import { faGear, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { selectUserIsSuperadmin, selectUserIsTeacher } from 'actions/userActions';
import lang from 'lang';
import routes from 'routes';

import AddTeacherModal from '../AddTeacherModal';
import DeleteGroupsModal from '../DeleteGroupsModal';
import GroupSettingsModal from '../GroupSettingsModal';
import LeaveGroupModal from '../LeaveGroupModal';
import SecondaryPageHeader from 'components/common/layout/SecondaryPageHeader';

const Header = ({ group, fetchGroup }) => {
  const history = useHistory();
  const { spaceId, groupId } = useParams();
  const isSuperadmin = useSelector(selectUserIsSuperadmin);
  const isTeacher = useSelector(selectUserIsTeacher);

  const [leaveModal, setLeaveModal] = useState(false);
  const [addTeacherModal, setAddTeacherModal] = useState(false);
  const [groupSettingsModal, setGroupSettingsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const actions = [
    {
      label: lang.add,
      icon: faUserPlus,
      type: 'icon',
      onClick: () => setAddTeacherModal(true),
      dataTour: 'group-header-add-teacher-button',
    },
    {
      label: lang.settings,
      icon: faGear,
      type: 'icon',
      onClick: () => setGroupSettingsModal(true),
    },
  ];

  const extraActions = [
    {
      label: lang.group.leaveGroup,
      onClick: () => setLeaveModal(true),
      hide: !isTeacher,
    },
    {
      label: lang.group.deleteGroup,
      onClick: () => setDeleteModal(true),
      hide: !isSuperadmin,
    },
  ];

  return (
    <Fragment>
      <DeleteGroupsModal
        open={deleteModal}
        close={() => setDeleteModal(false)}
        groupIds={[group.id]}
        reload={() => history.push(routes.groups.ref(spaceId))}
      />
      <LeaveGroupModal
        open={leaveModal}
        close={() => setLeaveModal(false)}
        groupId={group.id}
        refresh={() => history.push(routes.groups.ref(spaceId))}
      />
      <AddTeacherModal
        open={addTeacherModal}
        close={() => setAddTeacherModal(false)}
        groupId={groupId}
        reload={fetchGroup}
        currentTeachers={group.teachers}
      />
      <GroupSettingsModal
        open={groupSettingsModal}
        close={() => setGroupSettingsModal(false)}
        groupId={group.id}
        currentGroupName={group.name}
        reload={fetchGroup}
      />
      <SecondaryPageHeader
        header={group.name}
        actions={actions}
        extraActions={extraActions}
      />
    </Fragment>
  );
};

Header.propTypes = {
  group: PropTypes.object,
  fetchGroup: PropTypes.func,
};

export default Header;
