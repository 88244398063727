import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import ExerciseSettingsForm from '../../ExerciseSettingsForm';
import FlowStep from '../FlowStep';

import useStyles from './styles';

const AskForExerciseSettings = ({ exerciseSettings, setExerciseSettings }) => {
  const classes = useStyles();

  const header = (
    <div className={classes.header}>
      {lang.exerciseForm.exerciseSettings.label}
    </div>
  );

  return (
    <FlowStep
      header={header}
    >
      <div className={classes.settingsContainer}>
        <ExerciseSettingsForm
          exerciseSettings={exerciseSettings}
          setExerciseSettings={setExerciseSettings}
        />
      </div>
    </FlowStep>
  );
};

AskForExerciseSettings.propTypes = {
  exerciseSettings: PropTypes.object,
  setExerciseSettings: PropTypes.func,
};

export default AskForExerciseSettings;
