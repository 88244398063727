import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  addRubricContainer: {
    marginBottom: '20px',
    padding: '20px',
    backgroundColor: theme.backgroundColor,
  },
  contentWrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    addRubricContainer: {
      '& > .otl-cardContentContainer': {
        padding: '20px 10px',
      },
    },
  },
}));
