import React, { useCallback, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';

import LabelEditorModal from '../../LabelEditorModal';
import FilterPool from 'components/common/filter-selector/FilterPool';
import FilterSelectorItem from 'components/common/filter-selector/FilterSelectorItem';

import useStyles from './styles.js';

const ExerciseLabels = ({ groupId, exerciseLabels, selectable, refresh, labelOptions, setFilters, fetchLabels, labelAction }) => {
  const classes = useStyles();

  const [showLabelModal, setShowLabelModal] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const addLabel = (label) => {
    if (exerciseLabels) {
      labelAction([...exerciseLabels, label]);
      setDropdown(false);
      return;
    }

    labelAction([label]);
    setDropdown(false);
  };

  const removeLabel = (labelToRemove) => {
    labelAction(exerciseLabels.filter(label => label.id !== labelToRemove.id));
  };

  const onClick = (event) => {
    setDropdown(!dropdown);
    event.stopPropagation();
  };

  const closeLabelEditor = useCallback(() => setShowLabelModal(false), []);

  return (
    <div className={classes.labelContainer}>
      <LabelEditorModal
        open={showLabelModal}
        close={closeLabelEditor}
        exerciseLabels={exerciseLabels || []}
        updateExerciseLabels={labelAction}
        setFilters={setFilters}
        groupId={groupId}
        fetchLabels={fetchLabels}
        labelOptions={labelOptions}
        refresh={refresh}
      />
      <FilterPool
        poolItems={exerciseLabels || []}
        selectable={selectable}
        poolItemAction={removeLabel}
      />
      <Render when={!selectable}>
        <Dropdown close={() => setDropdown(false)}>
          <Button
            data-tour="exercise-labels-button"
            onClick={onClick}
            className={cx(classes.addLabelButton, { onlyChild: !exerciseLabels || exerciseLabels.length === 0 })}
          >
            <Render when={!exerciseLabels || exerciseLabels.length === 0}>
              <span className={classes.addLabelText}>
                {lang.exercises.labels.addLabel}
              </span>
            </Render>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <DropdownMenu
            className={classes.labelsDropdownMenu}
            open={dropdown}
          >
            <Render when={labelOptions.length === 0}>
              <DropdownMenuItem className={classes.noOptions}>
                {lang.noOptionsAvailable}
              </DropdownMenuItem>
            </Render>
            {labelOptions.map((label) => (
              <Render
                key={label.id}
                when={!exerciseLabels || !exerciseLabels.some(item => item.id === label.id)}
              >
                <FilterSelectorItem
                  item={label}
                  currentItems={exerciseLabels}
                  itemAction={() => addLabel(label)}
                />
              </Render>
            ))}
            <DropdownMenuItem
              className={classes.labelDropdownMenuItem}
              onClick={() => setShowLabelModal(true)}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={classes.iconPlus}
              />
              {lang.exercises.labels.editLabels}
            </DropdownMenuItem>
          </DropdownMenu>
        </Dropdown>
      </Render>
    </div>
  );
};

ExerciseLabels.propTypes = {
  groupId: PropTypes.string,
  selectable: PropTypes.bool,
  refresh: PropTypes.func,
  labelOptions: PropTypes.array,
  exerciseLabels: PropTypes.array,
  setFilters: PropTypes.func,
  fetchLabels: PropTypes.func,
  labelAction: PropTypes.func,
};

export default ExerciseLabels;
