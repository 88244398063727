import lang from 'lang';
import { buildStep, buildOptions } from 'services/shepherd/utils';

export const options = buildOptions('groups');

export const steps = (isOnGroupsPage) => [
  {
    id: 'sidebar-groups',
    attachOn: 'right',
    ...(
      isOnGroupsPage
        ? {
          buttons: ['next'],
          text: lang.tours.groups.steps.sidebar,
        }
        : {
          text: lang.tours.groups.steps.sidebarGoToPage,
          advanceOnClick: true,
        }
    ),
  },
  {
    id: 'groups-create-new-group-button',
    text: lang.tours.groups.steps.createNewGroup,
    attachOn: 'bottom-left',
    advanceOnClick: true,
  },
  {
    id: 'create-group-modal-name-input',
    text: lang.tours.groups.steps.formNameInput,
    attachOn: 'right',
    buttons: ['back', 'next'],
    delayTime: 500,
    canClickTarget: true,
  },
  {
    id: 'create-group-modal-teachers-input',
    text: lang.tours.groups.steps.formTeachersInput,
    attachOn: 'right',
    buttons: ['back', 'next'],
  },
  {
    id: 'create-group-modal-confirm-button',
    text: lang.tours.groups.steps.formConfirm,
    attachOn: 'right',
    advanceOnClick: true,
    buttons: ['back'],
  },
  {
    id: 'group-card-0',
    text: lang.tours.groups.steps.groupCard,
    attachOn: 'bottom',
    advanceOnClick: true,
  },
  {
    id: 'teachers-list',
    text: lang.tours.groups.steps.teachersTable,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'group-header-add-teacher-button',
    text: lang.tours.groups.steps.addTeacherButton,
    attachOn: 'bottom-left',
    buttons: ['back', 'next'],
  },
  {
    id: 'group-shortcuts',
    text: lang.tours.groups.steps.groupShortcuts,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'secondary-page-header-extra-actions-dropdown',
    text: lang.tours.groups.steps.leaveGroup,
    attachOn: 'bottom-left',
    buttons: ['back', 'next'],
  },
  {
    id: 'tour-hub',
    text: lang.tours.groups.steps.hub,
    attachOn: 'top-left',
    buttons: ['back', 'complete'],
  },
].map(buildStep);
