import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  exerciseCard: {
    cursor: 'default !important',
    marginBottom: '16px',
    backgroundColor: theme.cardBackground,
    width: '100% !important',
    '& > .otl-cardContentContainer': {
      padding: 0,
      display: 'flex',
      alignItems: 'stretch',
    },
    '&.wrapped': {
      backgroundColor: theme.shade1,
    },
  },
  exerciseContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '8px 10px',
    cursor: 'pointer !important',
  },
  topContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  labelsLoading: {
    width: '20px',
    height: '20px',
  },
  exerciseStatement: {
    width: '100%',
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '1',
    boxOrient: 'vertical',
    fontSize: '14px',
    margin: '6px 0',
    '& p': {
      margin: '0 !important',
      '& .gap': {
        minHeight: '27px',
        backgroundColor: '#e7e7e7',
        border: '1px solid #d8d8d8',
        borderRadius: '4px',
        width: '50px',
        margin: '0 3px 0 3px',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginBottom: '0.25em',
        boxShadow: 'inset 2px 2px 2px #d8d8d8',
      },
    },
    '& img.ql-image': {
      maxHeight: '20px !important',
      marginTop: '2px !important',
    },
    '& audio': {
      height: '20px',
    },
    '& iframe.ql-video': {
      display: 'none',
    },
  },
  infoWrapper: {
    fontSize: '10px',
    color: theme.grey,
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
  },
  mediaItem: {
    display: 'flex',
    fontSize: '15px',
    alignItems: 'center',
    marginLeft: '0.5em',
  },
  infoText: {
    color: theme.textColor2,
    marginLeft: '5px',
  },
  separator: {
    margin: '0 5px',
  },
  entityCardWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));
