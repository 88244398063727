import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  actionsWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '&.actioning': {
      position: 'unset',
      bottom: 'unset',
    },
  },
  moveAction: {
    marginRight: '15px',
    marginLeft: '0 !important',
    fontSize: '16px !important',
    color: theme.textColor6,
    opacity: 0.6,
    '&:hover': {
      color: theme.textColor6,
      opacity: 0.8,
    },
  },
  actionsSpinner: {
    width: '20px',
    marginRight: '14px',
  },
}));
