import 'shepherd.js/dist/css/shepherd.css';

import 'assets/css/shepherd-theme.css';
import lang from 'lang';
import { waitForElementToExist } from 'utils';

const actionButtons = {
  back: {
    text: lang.tours.back,
    classes: 'shepherd-button-back',
    type: 'back',
  },
  next: {
    text: lang.tours.next,
    classes: 'shepherd-button-next',
    type: 'next',
  },
  complete: {
    text: lang.tours.complete,
    classes: 'shepherd-button-complete',
    type: 'complete',
  },
};

/**
 *
 * @param {number} time
 * @returns {Promise<never>}
 */
const delay = (time) => new Promise(resolve => setTimeout(resolve, time));

/**
 *
 * @param {{
 *  id: string
 *  dataTour?: string
 *  text: string
 *  attachOn: 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end' | 'left' | 'left-start' | 'left-end'
 *  buttons: (keyof actionButtons)[]
 *  advanceOnClick: boolean
 *  delayTime: number
 *  canClickTarget: boolean
 *  modal: boolean
 * }} _
 * @returns {Shepherd.Step.StepOptions}
 */
export const buildStep = ({ id, dataTour, text, attachOn, buttons = [], advanceOnClick = false, delayTime = 0, canClickTarget = false, modal = false }) => ({
  id,
  text,
  ...(!modal && {
    attachTo: {
      element: `[data-tour="${dataTour ?? id}"]`,
      on: attachOn,
    },
  }),
  beforeShowPromise: async () => {
    if (modal) {
      return Promise.resolve();
    }
    await waitForElementToExist(`[data-tour="${dataTour ?? id}"]`);
    delayTime && await delay(delayTime);
  },
  buttons: buttons.map(key => actionButtons[key]),
  ...(advanceOnClick && {
    advanceOn: {
      selector: `[data-tour="${id}"]`,
      event: 'click',
    },
  }),
  canClickTarget: advanceOnClick ? true : canClickTarget,
});

/**
 *
 * @param {string} tourName
 * @returns {Shepherd.Tour.TourOptions}
 */
export const buildOptions = (tourName) => ({
  useModalOverlay: true,
  tourName,
  defaultStepOptions: {
    classes: 'intuitivo-shepherd-theme',
    scrollTo: { behavior: 'smooth', block: 'center' },
    cancelIcon: {
      enabled: true,
    },
  },
});
