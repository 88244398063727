import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  toggleWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '10px',
  },
  toggleLabel: {
    marginRight: '4px',
  },
  formulaLabel: {
    marginBottom: '5px',
  },
  formulaInput: {
    color: 'black',
    backgroundColor: 'white',
    minHeight: '60px',
    width: '100%',
    fontSize: '22px !important',
    display: 'flex !important',
    alignItems: 'center',
    paddingLeft: '3px',
    marginBottom: '0.5em',
  },
  outerSectionsContainer: {
    width: '100%',
  },
  tabs: {
    '& .otl-tabHeader.active': {
      backgroundColor: theme.shade4 + ' !important',
      borderBottomColor: theme.shade4 + ' !important',
    },
    '& .otl-tabsTab': {
      backgroundColor: theme.shade4 + ' !important',
    },
  },
  sectionTab: {
    minHeight: '365px',
  },
  innerSectionsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    gap: '15px',
  },
  innerSection: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
  },
  formulaBtn: {
    width: '40px',
    height: '40px',
    backgroundColor: theme.shade1 + ' !important',
    color: theme.textColor,
    marginRight: '4px',
    marginBottom: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  advancedPreviewWrap: {
    backgroundColor: theme.shade4,
    padding: '20px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  advancedPreviewLabel: {
    marginBottom: '10px',
  },
  advancedPreview: {
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  advancedReferenceWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },
  advancedReference: {
    textDecoration: 'none',
    color: theme.textColor2,
    cursor: 'pointer',
    '&:hover': {
      color: theme.textColor,
      textDecoration: 'underline',
    },
  },
  advancedInput: {
    height: '150px',
  },
  modalButtonsWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: '0.25em',
    marginBottom: '1em',
  },
  modal: {
    border: '1px solid grey !important',
    margin: '0 10px 0 auto',
  },
}));
