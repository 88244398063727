import { createUseStyles } from 'react-jss';

export default createUseStyles({
  swatchWrapper: {
    width: '3em',
    height: '3em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  swatch: {
    width: '2.5em',
    height: '2.5em',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'width 150ms, height 150ms',
    '&:hover': {
      cursor: 'pointer',
      width: '3em',
      height: '3em',
    },
  },
});
