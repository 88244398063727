import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import lang from 'lang';

import CustomFieldInput from '../CustomFieldInput';
import Button from 'components/common/Button';

import useStyles from './styles';

const CustomFields = ({ getValue, setValue, getErrors, setErrors }) => {
  const classes = useStyles();

  const addField = () => {
    setValue('customFields', [...getValue('customFields'), { label: '', content: '', studentInput: false }]);
    setErrors('customFields', [...getErrors('customFields'), { label: [], content: [] }]);
  };

  return (
    <div className={classes.extraFields}>
      <h2 className={classes.extraFieldsHeaderTitle}>
        {lang.test.physicalVersion.extraFields}
      </h2>
      <Table className={classes.inputTable}>
        {getValue('customFields').map((field, index) => (
          <CustomFieldInput
            field={field}
            getValue={getValue}
            setValue={setValue}
            getErrors={getErrors}
            setErrors={setErrors}
            index={index}
            key={index}
          />
        ))}
      </Table>
      <Button
        black
        onClick={addField}
      >
        <FontAwesomeIcon
          icon={faPlus}
          className={classes.extraFieldButtonIcon}
        />
        {lang.test.physicalVersion.extraFieldButton}
      </Button>
    </div>
  );
};

CustomFields.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getErrors: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default CustomFields;
