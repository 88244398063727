import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  dropzoneWrapper: {
    '&.color': {
      '& > div[class^="dropzone"]': {
        backgroundColor: theme.white,
        border: `2px dashed ${theme.exerciseColor}`,
      },
    },
  },
}));
