import React, { useCallback, useState } from 'react';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import { Dropdown } from 'antd';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { selectUserIsSuperadmin, selectUserIsTeacher } from 'actions/userActions';
import lang from 'lang';
import routes from 'routes';
import { deepCopy } from 'utils';

import Card from 'components/common/Card';
import Input from 'components/common/Input';
import AddTeacherModal from 'components/group/AddTeacherModal';
import DeleteGroupsModal from 'components/group/DeleteGroupsModal';
import GroupSettingsModal from 'components/group/GroupSettingsModal';
import LeaveGroupModal from 'components/group/LeaveGroupModal';

import useStyles from './styles';

const SETTINGS = 'SETTINGS';
const DELETE = 'DELETE';
const LEAVE = 'LEAVE';
const ADD_TEACHERS = 'ADD_TEACHERS';

const GroupCard = ({ group, reload, setSelected, isSelected, dataTour }) => {
  const classes = useStyles();
  const history = useHistory();
  const { spaceId } = useParams();
  const isSuperadmin = useSelector(selectUserIsSuperadmin);
  const isTeacher = useSelector(selectUserIsTeacher);

  const [settingsModal, setSettingsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [leaveModal, setLeaveModal] = useState(false);
  const [addTeacherModal, setAddTeacherModal] = useState(false);

  const onItemClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();

    if (key === SETTINGS) {
      setSettingsModal(true);
    }

    if (key === DELETE) {
      setDeleteModal(true);
    }

    if (key === LEAVE) {
      setLeaveModal(true);
    }

    if (key === ADD_TEACHERS) {
      setAddTeacherModal(true);
    }
  };

  const selectGroup = useCallback((event) => {
    event.stopPropagation();

    setSelected(selected => {
      let newSelected = deepCopy(selected);

      const selectGroup = newSelected.find(selected => selected.id === group.id);

      if (selectGroup) {
        newSelected = newSelected.filter(selected => selected.id !== group.id);
      } else {
        newSelected = [
          ...newSelected,
          group,
        ];
      }

      return newSelected;
    });
  }, [group, setSelected]);

  const actions = [
    {
      key: SETTINGS,
      label: lang.group.groupSettings,
    },
    {
      key: ADD_TEACHERS,
      label: lang.group.addTeacher,
    },
    ...(isSuperadmin ? [
      {
        key: DELETE,
        label: lang.group.deleteGroup,
      },
    ] : []),
    ...(isTeacher ? [
      {
        key: LEAVE,
        label: lang.group.leaveGroup,
      },
    ] : []),
  ];

  return (
    <div>
      <GroupSettingsModal
        open={settingsModal}
        close={() => setSettingsModal(false)}
        groupId={group.id}
        currentGroupName={group.label}
        reload={reload}
      />
      <DeleteGroupsModal
        open={deleteModal}
        close={() => setDeleteModal(false)}
        groupIds={[group.id]}
        reload={reload}
      />
      <LeaveGroupModal
        open={leaveModal}
        close={() => setLeaveModal(false)}
        groupId={group.id}
        refresh={reload}
      />
      <AddTeacherModal
        open={addTeacherModal}
        close={() => setAddTeacherModal(false)}
        groupId={group.id}
        reload={reload}
        currentTeachers={group.teachers}
      />
      <Card
        dataTour={dataTour}
        className={classes.groupCard}
        onClick={() => history.push(routes.group.ref(spaceId, group.id))}
      >
        <Dropdown
          menu={{ items: actions, onClick: onItemClick }}
          trigger="click"
        >
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            className={cx(classes.groupActionsIcon, classes.settings)}
            onClick={(e) => e.stopPropagation()}
          />
        </Dropdown>
        <Render when={isSuperadmin}>
          <div
            className={cx(classes.groupActionsIcon, classes.selector)}
            onClick={selectGroup}
          >
            <Input
              type="checkbox"
              value={isSelected}
              className={classes.selectorInput}
            />
          </div>
        </Render>
        <div
          className={classes.groupName}
          title={group.label}
        >
          {group.label}
        </div>
        <div className={classes.usersCount}>
          {group.usersCount}
          {' '}
          {group.usersCount === '1' ? lang.groups.teacher : lang.groups.teachers}
        </div>
      </Card>
    </div>
  );
};

GroupCard.propTypes = {
  group: PropTypes.object,
  reload: PropTypes.func,
  setSelected: PropTypes.func,
  isSelected: PropTypes.bool,
  dataTour: PropTypes.string,
};

export default GroupCard;
