import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  wrapper: {
    padding: '15px 30px',
  },
  filterWrap: {
    width: '300px',
    backgroundColor: theme.shade2,
    padding: '10px',
    boxSizing: 'border-box',
    marginLeft: '20px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.1)',
  },
  rubricsWrap: {
    width: '100%',
  },
  actionsWrapper: {
    paddingBottom: '10px',
  },
  pagination: {
    '& .otl-paginationPageItem': {
      width: '37px',
      height: '37px',
    },
    '& .otl-paginationPageItem.selected': {
      width: '35px !important',
      height: '35px !important',
      backgroundColor: theme.paginationBackground,
      borderRadius: '0',
      border: `1px solid ${theme.paginationBorder}`,
    },
    '& .otl-paginationPageItem:hover': {
      width: '37px',
      height: '37px',
      backgroundColor: theme.paginationBackground,
      borderRadius: '0',
    },
    '& .otl-paginationPageItem.disabled:hover': {
      backgroundColor: 'inherit',
      cursor: 'inherit',
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    wrapper: {
      padding: '15px 15px',
    },
  },
}));
