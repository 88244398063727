import React, { useState } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import ExerciseSettingsForm from '../ExerciseSettingsForm';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const ExerciseSettingsModal = ({ open, close, exerciseSettings, setExerciseSettings, save, isEditing }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [titleErrors, setTitleErrors] = useState([]);
  const [subjectsErrors, setSubjectsErrors] = useState([]);
  const [yearsErrors, setYearsErrors] = useState([]);

  const _save = () => {
    setLoading(true);
    let errors = false;
    const _titleErrors = [];
    const _subjectsErrors = [];
    const _yearsErrors = [];
    if (exerciseSettings.title.trim().length === 0) {
      errors = true;
      _titleErrors.push(lang.exerciseForm.exerciseSettings.errorTitleRequired);
    }

    if (exerciseSettings.isPublic) {
      if (exerciseSettings.subjects.length === 0) {
        errors = true;
        _subjectsErrors.push(lang.exerciseForm.exerciseSettings.errorSubjectRequired);
      }
      if (exerciseSettings.years.length === 0) {
        errors = true;
        _yearsErrors.push(lang.exerciseForm.exerciseSettings.errorYearRequired);
      }
    }

    setTitleErrors(_titleErrors);
    setSubjectsErrors(_subjectsErrors);
    setYearsErrors(_yearsErrors);

    if (errors === true) {
      setTimeout(() => {
        setLoading(false);
      }, 200);
      return;
    }

    setLoading(false);
    save();
  };

  const actions = [
    {
      name: isEditing ? lang.close : lang.save,
      onClick: isEditing ? close : _save,
      loading: loading,
      color: isEditing ? 'black' : 'blue',
    },
  ];

  return (
    <Modal
      header={lang.exerciseForm.exerciseSettings.label}
      open={open}
      close={close}
      center
      transition
      actions={actions}
      className={classes.modal}
    >
      <ExerciseSettingsForm
        exerciseSettings={exerciseSettings}
        setExerciseSettings={setExerciseSettings}
        titleErrors={titleErrors}
        yearsErrors={yearsErrors}
        subjectsErrors={subjectsErrors}
      />
    </Modal>
  );
};

ExerciseSettingsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  exerciseSettings: PropTypes.object,
  setExerciseSettings: PropTypes.func,
  save: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default ExerciseSettingsModal;
