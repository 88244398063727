import React from 'react';

import lang from 'lang';

import Button from 'components/common/Button';

import useStyles from './styles';

const ContinueButton = () => {
  const classes = useStyles();

  return (
    <div className={classes.continueButtonContainer}>
      <Button disabled>
        {lang.continue}
      </Button>
    </div>
  );
};

export default ContinueButton;
