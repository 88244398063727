import React from 'react';
import { TableCell, TableRow, Toggle } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserFeatureToggleMaxUsages } from 'actions/userActions';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';
import PlansPill from 'components/common/plans/PlansPill';

import useStyles from './styles';

const SettingsInput = ({ label, type, accept, placeholder, onChange, onDrop, value, options, minSize, maxSize, errors, hasTogglers, canBeStudentInput, isStudentInput, onEnableStudentInput, canBeEnabled, isEnabled, onEnable, premium }) => {
  const classes = useStyles();
  const exportVersionsToggle = useFeature(toggles.exportVersions);
  const exportVersionsMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.exportVersions));

  return (
    <TableRow>
      <TableCell
        className={cx(classes.tableCell, { disabled: (canBeEnabled && !isEnabled) })}
        colSpan={type === 'toggle' ? '10' : '2'}
      >
        <div className={classes.labelWrapper}>
          {label}
          <Render when={!exportVersionsToggle && premium}>
            <PlansPill
              tip={lang.plans.warningExportTestMaxUsages(exportVersionsMaxUsages)}
            />
          </Render>
        </div>
      </TableCell>
      <Render when={type !== 'toggle'}>
        <TableCell
          className={cx(classes.tableCell, { disabled: (canBeStudentInput && isStudentInput) || (canBeEnabled && !isEnabled) })}
          colSpan={hasTogglers ? '8' : '10'}
        >
          <Input
            type={type}
            readOnly={type === 'date' || type === 'datetime'}
            accept={accept}
            placeholder={placeholder}
            onChange={onChange}
            onDrop={onDrop}
            value={value}
            minSize={minSize}
            maxSize={maxSize}
            disabled={(canBeStudentInput && isStudentInput) || (canBeEnabled && !isEnabled) || (!exportVersionsToggle && premium && exportVersionsMaxUsages === 1)}
            errors={isStudentInput ? [] : errors}
            invalid={!isStudentInput && errors && errors.length !== 0}
            options={options}
          />
        </TableCell>
      </Render>
      <Render when={canBeStudentInput}>
        <TableCell className={cx(classes.tableCell)}>
          <div className={cx(classes.toggle, { disabled: !isEnabled })}>
            <Toggle
              checked={isStudentInput && !!isEnabled}
              onChange={onEnableStudentInput}
              disabled={!isEnabled}
            />
          </div>
        </TableCell>
      </Render>
      <Render when={!canBeStudentInput && canBeEnabled}>
        <TableCell className={cx(classes.tableCell)} />
      </Render>
      <Render when={canBeEnabled}>
        <TableCell className={cx(classes.tableCell)}>
          <div className={classes.toggle}>
            <Toggle
              checked={isEnabled}
              onChange={onEnable}
            />
          </div>
        </TableCell>
      </Render>
    </TableRow>
  );
};

SettingsInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  accept: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onDrop: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.array,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  errors: PropTypes.array,
  hasTogglers: PropTypes.bool,
  canBeStudentInput: PropTypes.bool,
  isStudentInput: PropTypes.bool,
  onEnableStudentInput: PropTypes.func,
  canBeEnabled: PropTypes.bool,
  isEnabled: PropTypes.bool,
  onEnable: PropTypes.func,
  premium: PropTypes.bool,
};

export default SettingsInput;
