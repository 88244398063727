export const SET_COMMENTING = 'SET_COMMENTING';
export const SET_TEXT_ANNOTATION_HOVERED = 'SET_TEXT_ANNOTATION_HOVERED';
export const SET_TEXT_ANNOTATION_FOCUSED = 'SET_TEXT_ANNOTATION_FOCUSED';
export const SET_COMMENT_HOVERED = 'SET_COMMENT_HOVERED';
export const SET_EDITING_COMMENT = 'SET_EDITING_COMMENT';

export const setCommenting = (commenting, exerciseId) => ({
  type: SET_COMMENTING,
  commenting,
  exerciseId,
});

export const setTextAnnotationHovered = (textAnnotationId) => ({
  type: SET_TEXT_ANNOTATION_HOVERED,
  hoveredTextAnnotationId: textAnnotationId,
});

export const setTextAnnotationFocused = (textAnnotationId) => ({
  type: SET_TEXT_ANNOTATION_FOCUSED,
  focusedTextAnnotationId: textAnnotationId,
});

export const setCommentHovered = (textAnnotationId) => ({
  type: SET_COMMENT_HOVERED,
  hoveredCommentId: textAnnotationId,
});

export const setEditingComment = (editingComment) => ({
  type: SET_EDITING_COMMENT,
  editingComment: editingComment,
});

export const attemptReducer = (attempt, action) => {
  switch (action.type) {
    case SET_COMMENTING:
      return {
        ...attempt,
        commenting: action.commenting,
        commentingExerciseId: action.exerciseId,
      };
    case SET_TEXT_ANNOTATION_HOVERED:
      return {
        ...attempt,
        hoveredTextAnnotationId: action.hoveredTextAnnotationId,
      };
    case SET_TEXT_ANNOTATION_FOCUSED:
      return {
        ...attempt,
        focusedTextAnnotationId: action.focusedTextAnnotationId,
      };
    case SET_COMMENT_HOVERED:
      return {
        ...attempt,
        hoveredCommentId: action.hoveredCommentId,
      };
    case SET_EDITING_COMMENT:
      return {
        ...attempt,
        editingComment: action.editingComment,
      };
    default:
      return attempt;
  }
};

export const INITIAL_STATE = {
  commenting: false,
  commentingExerciseId: null,
  hoveredTextAnnotationId: null,
  focusedTextAnnotationId: null,
  hoveredCommentId: null,
  editingComment: false,
};
