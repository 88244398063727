import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import useStyles from './styles';

const EntityExtraInfo = ({ lang, icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <FontAwesomeIcon
        icon={icon}
      />
      <div className={classes.text}>
        {lang}
      </div>
    </div>
  );
};

EntityExtraInfo.propTypes = {
  lang: PropTypes.string,
  icon: PropTypes.string,
};

export default EntityExtraInfo;
