import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  datepicker: {
    color: '#2d2d2d',
    borderRadius: '18px',
    borderColor: theme.inputBorder3,
    '&.invalid': {
      borderColor: theme.errorColor,
      '&:hover': {
        borderColor: theme.errorColor,
      },
    },
    '&:hover': {
      borderColor: theme.inputBorder3,
    },
  },
}));
