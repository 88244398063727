import { createUseStyles } from 'react-jss';

export default createUseStyles({
  commentBox: {
    borderWidth: '3px',
    borderStyle: 'solid',
    borderRadius: '5px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    opacity: '1',
    transition: 'opacity 150ms',
    minHeight: '70px',
    padding: '10px',
    '&.otherFocused': {
      opacity: 0.2,
    },
    '&.editing': {
      padding: '0',
    },
  },
  commentArea: {
    width: '100%',
    height: '100%',
    border: 'none',
    boxSizing: 'border-box',
    resize: 'none',
    '& .quill': {
      backgroundColor: 'unset !important',
    },
  },
  cancelButton: {
    order: -1,
    marginRight: 'auto',
    opacity: '0.9',
    borderWidth: '1px !important',
    '&:hover': {
      opacity: '1',
    },
  },
  previewFooter: {
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
});
