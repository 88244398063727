import React from 'react';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectAttemptItems } from 'actions/studentAttemptActions';
import { NON_LINEAR } from 'constants/navigationTypes';
import useFeature from 'hooks/useFeature';
import toggles from 'toggles';

import AttemptActions from '../AttemptActions';
import Card from 'components/common/Card';
import QuillRenderer from 'components/common/QuillRenderer';

import useStyles from './styles';

const PauseItem = ({ nextItem, previousItem, navigation, statement, finishTest, flushPendingAnswers, pendingAnswers }) => {
  const classes = useStyles();
  const iaveToggle = useFeature(toggles.iave);

  const items = useSelector(selectAttemptItems);

  return (
    <Card className={classes.pauseItemCard}>
      <div className={classes.cardContent}>
        <FontAwesomeIcon
          icon={faPause}
          className={classes.pauseIcon}
        />
        <div className={classes.statement}>
          <QuillRenderer
            value={statement}
          />
        </div>
        <div className={classes.buttonsContainer}>
          <AttemptActions
            nextItem={nextItem}
            previousItem={previousItem}
            navigation={navigation}
            isLast={items[0]?.isLast}
            isFirst={items[0]?.order === 0}
            canMovePrevious={navigation === NON_LINEAR && !iaveToggle}
            canMoveNext
            finishable
            finishTest={finishTest}
            flushPendingAnswers={flushPendingAnswers}
            pendingAnswers={pendingAnswers}
          />
        </div>
      </div>
    </Card>
  );
};

PauseItem.propTypes = {
  nextItem: PropTypes.func,
  previousItem: PropTypes.func,
  navigation: PropTypes.string,
  statement: PropTypes.object,
  finishTest: PropTypes.func,
  flushPendingAnswers: PropTypes.func,
  pendingAnswers: PropTypes.object,
};

export default PauseItem;
