import React from 'react';
import PropTypes from 'prop-types';

const AnswerHeaderCell = ({ col, row, value, children, answerable, ...restProps }) => {

  if (restProps.className.includes('ant-table-cell-scrollbar')) {
    return (
      <td {...restProps} />
    );
  }

  return (
    <th {...restProps} >

      {value}
      {children}
    </th>
  );
};

AnswerHeaderCell.propTypes = {
  col: PropTypes.number.isRequired,
  row: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  answerable: PropTypes.bool,
};

export default AnswerHeaderCell;
