import React from 'react';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';

import lang from 'lang';

import { useAttemptContext } from '../CorrectionTab/context';
import Modal from 'components/common/Modal';
import AnnotationBlot from 'components/common/rich-text/RichText/AnnotationBlot';

const DeleteAnnotationModal = ({ open, close, textAnnotationId, setAnnotations, answer, setAnswer, setTextAnnotationFocused, setSelectedAnnotation, setAnnotate }) => {
  const { setCommenting } = useAttemptContext();

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
    {
      name: lang.delete,
      color: 'red',
      onClick: () => {
        const newAnswerOps = answer.ops.map(op => {
          if (op.attributes && op.attributes.annotation?.id === textAnnotationId) {
            return { insert: op.insert, attributes: omit(op.attributes, ['annotation']) };
          } else if (op.attributes && op.attributes.annotation?.id.includes(textAnnotationId)) {
            const newId = op.attributes.annotation.id.split('|').filter(id => id !== textAnnotationId).join('|');
            const newIdList = newId.split('|');
            const lastId = newIdList[newIdList.length - 1];
            const lastIdOp = answer.ops.find(op => op.attributes?.annotation?.id === lastId);

            return { insert: op.insert, attributes: { ...op.attributes, annotation: { ...op.attributes.annotation, color: lastIdOp.attributes.annotation.color, id: newId } } };
          }
          return op;
        });

        AnnotationBlot.setIsActive(textAnnotationId, false);
        setAnnotations((prevAnnotations) => prevAnnotations.filter((annotation) => annotation.id !== textAnnotationId));
        setAnswer({ ops: newAnswerOps });
        setTextAnnotationFocused(null);
        setSelectedAnnotation(null);
        setAnnotate(true);
        setCommenting(false);

        close();
      },
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.test.grades.deleteAnnotationHeader}
      actions={actions}
      center
      transition
      small
    >
      <div>
        {lang.test.grades.deleteAnnotationDescription1}
      </div>
      <div>
        {lang.test.grades.deleteAnnotationDescription2}
      </div>
    </Modal>
  );
};

DeleteAnnotationModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  textAnnotationId: PropTypes.string,
  setAnnotations: PropTypes.func,
  answer: PropTypes.object,
  setAnswer: PropTypes.func,
  setTextAnnotationFocused: PropTypes.func,
  setSelectedAnnotation: PropTypes.func,
  setAnnotate: PropTypes.func,
};

export default DeleteAnnotationModal;
