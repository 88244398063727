const increaseUsages = (explore, itemId) => {
  const newExplore = { ...explore };

  const item = newExplore.items.find((item) => item.id === itemId);

  if (item) {
    item.usages++;
  }

  return newExplore;
};

export {
  increaseUsages,
};
