import React from 'react';
import { faPlus, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import { ADD_EXISTING_EXERCISE, CREATE_INFO_BLOCK, CREATE_NEW_EXERCISE, CREATE_PAUSE_ITEM, CREATE_SECTION } from 'constants/testContentTypes';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Button from 'components/common/Button';

import useStyles from './styles';

const ContentTypeSelector = ({ setFrom, insideSection }) => {
  const classes = useStyles();
  const pauseItemToggle = useFeature(toggles.pauseItem);

  return (
    <div className={classes.container}>
      <Button
        dataTour="assessment-create-new-exercise"
        className={classes.optionButton}
        onClick={() => setFrom(CREATE_NEW_EXERCISE)}
        large
        gray
        sibling
      >
        <FontAwesomeIcon icon={faPlus} />
        <div className={classes.fromOptionText}>
          {lang.test.createNewExercise}
        </div>
      </Button>
      <Button
        className={classes.optionButton}
        onClick={() => setFrom(ADD_EXISTING_EXERCISE)}
        large
        gray
        sibling
      >
        <FontAwesomeIcon icon={faDatabase} />
        <div className={classes.fromOptionText}>
          {lang.test.selectExerciseFromDb}
        </div>
      </Button>
      <Button
        className={classes.optionButton}
        onClick={() => setFrom(CREATE_INFO_BLOCK)}
        large
        gray
        sibling
      >
        <FontAwesomeIcon icon={faPlus} />
        <div className={classes.fromOptionText}>
          {lang.test.addInformationBlock}
        </div>
      </Button>
      <Render when={pauseItemToggle && !insideSection}>
        <Button
          className={classes.optionButton}
          onClick={() => setFrom(CREATE_PAUSE_ITEM)}
          large
          gray
          sibling
        >
          <FontAwesomeIcon icon={faPlus} />
          <div className={classes.fromOptionText}>
            {lang.test.exercises.addPauseItem}
          </div>
        </Button>
      </Render>
      <Render when={!insideSection}>
        <Button
          className={classes.optionButton}
          onClick={() => setFrom(CREATE_SECTION)}
          large
          gray
          sibling
        >
          <FontAwesomeIcon icon={faPlus} />
          <div className={classes.fromOptionText}>
            {lang.test.exercises.addSection}
          </div>
        </Button>
      </Render>
    </div>
  );
};

ContentTypeSelector.propTypes = {
  setFrom: PropTypes.func,
  insideSection: PropTypes.bool,
};

export default ContentTypeSelector;
