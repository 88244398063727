import React from 'react';

import lang from 'lang';

import Loading from 'components/common/Loading';

import useStyles from './styles';

const LoadingDocumentGeneration = () => {
  const classes = useStyles();

  return (
    <div className={classes.downloadContainer}>
      {lang.test.attemptsExport.waitWhileGenerateDocuments}
      <Loading active />
    </div>
  );
};

export default LoadingDocumentGeneration;
