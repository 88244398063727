import { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { pageError, pageLoaded, pageLoading as pageLoadingAction, setAuthenticated as setAuthenticatedAction, showLayout } from 'actions/pageActions';
import { selectUserLang } from 'actions/userActions';
import useFeature from 'hooks/useFeature';
import toggles from 'toggles';
import { getErrorCode } from 'utils';

const usePageLogic = (title, layout, authenticated = true) => {
  const lang = useSelector(selectUserLang);
  const userData = useSelector(state => state.user.data);
  const loggedIn = useSelector(state => state.user.loggedIn);
  const iaveToggle = useFeature(toggles.iave);
  const setPageTitle = useCallback((title) => document.title = iaveToggle ? `${title} | IAVE` : `${title} | Intuitivo`, [iaveToggle]);
  const dispatch = useDispatch();
  const { spaceId } = useParams();

  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    dispatch(pageLoadingAction());
    dispatch(pageError(null));
    dispatch(showLayout(layout));
    dispatch(setAuthenticatedAction(authenticated));
  }, [dispatch, layout, authenticated]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const loaded = useCallback(() => {
    dispatch(pageLoaded());
    setPageLoading(false);
  }, [dispatch]);

  const reload = useCallback(() => {
    dispatch(pageLoadingAction());
    setPageLoading(true);
  }, [dispatch]);

  const setPageError = useCallback((code) => {
    dispatch(pageError(getErrorCode(code)));
  }, [dispatch]);

  const setAuthenticated = useCallback((authenticated) => {
    dispatch(setAuthenticatedAction(authenticated));
  }, [dispatch]);

  useEffect(() => {
    if (!spaceId || !loggedIn) {
      return;
    }

    const schools = userData.schools.map(school => school.id);
    const spaces = userData.hasPersonalSpace ? ['p', ...schools] : schools;

    if (!spaces.includes(spaceId)) {
      setPageError(1);
      loaded();
    }
  }, [setPageError, userData, spaceId, loaded, loggedIn]);

  if (title) {
    document.title = title + (iaveToggle ? ' | IAVE' : ' | Intuitivo');
  } else {
    document.title = iaveToggle ? 'IAVE' : 'Intuitivo';
  }

  return {
    lang,
    pageLoading,
    loaded,
    reload,
    setPageTitle,
    setPageError,
    setAuthenticated,
  };
};

export default usePageLogic;
