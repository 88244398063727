import React, { useCallback, useEffect, useState } from 'react';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Xarrow, { Xwrapper, useXarrow } from 'react-xarrows';

import { selectUserIsAdmin } from 'actions/userActions';
import { shuffleArr } from 'utils';

import ExerciseConnector from '../ExerciseConnector';

import useStyles from './styles';

const ExerciseAnswerConnecting = ({ shuffleConnectors, connectors, connections, answerable, onAnswer, answer, correction }) => {
  const classes = useStyles();
  const isAdmin = useSelector(selectUserIsAdmin);

  const [activeLeft, setActiveLeft] = useState(null);
  const [activeRight, setActiveRight] = useState(null);

  if (shuffleConnectors) {
    shuffleArr(connectors);
  }

  useEffect(() => {
    if (activeLeft && activeRight) {
      const newConnections = [...answer];
      const duplicateCheck = newConnections.some(connection => connection.start === activeLeft && connection.end === activeRight);
      if (!duplicateCheck) {
        newConnections.push({
          id: `${activeLeft}_${activeRight}`,
          start: activeLeft,
          end: activeRight,
        });
      }
      setActiveLeft(null);
      setActiveRight(null);
      onAnswer(newConnections);
    }
  }, [activeLeft, activeRight, answer, onAnswer]);

  const deleteConnection = useCallback((connectionId) => {
    const copyConnections = [...answer];
    const newConnections = copyConnections.filter(el => el.id !== connectionId);
    onAnswer(newConnections);
  }, [answer, onAnswer]);

  const getArrows = useCallback(() => {
    let correctionArrows = null;
    let answerArrows = null;
    if (correction) {
      correctionArrows = !isAdmin && connections.map(connection => (
        <Xarrow
          key={connection.id}
          start={connection.start + '_connector'}
          end={connection.end + '_connector'}
          showHead={false}
          curveness={0.15}
          lineColor={'#419bb480'}
          dashness={true}
        />
      ));
      answerArrows = answer.map(connection => {
        const color = isAdmin ? '#419bb4' : connection.isCorrect ? '#019a39' : '#ce1f01';

        return (
          <Xarrow
            key={connection.id}
            start={connection.start + '_connector'}
            end={connection.end + '_connector'}
            showHead={false}
            curveness={0.15}
            lineColor={color}
          />
        );
      });
    }

    if (answerable) {
      answerArrows = answer.map(connection => (
        <Xarrow
          key={connection.id}
          start={connection.start + '_connector'}
          end={connection.end + '_connector'}
          showHead={false}
          curveness={0.15}
          lineColor={'#419bb4'}
          arrowBodyProps={{ onClick: () => deleteConnection(connection.id) }}
          SVGcanvasStyle={{ cursor: 'pointer' }}
          labels={answerable ? { middle: <FontAwesomeIcon icon={faCircleXmark} className={classes.arrowDelete} onClick={() => deleteConnection(connection.id)} ></FontAwesomeIcon> } : null}
        />
      ));
    }

    if (!answerable && !correction) {
      correctionArrows = connections.map(connection => (
        <Xarrow
          key={connection.id}
          start={connection.start + '_connector'}
          end={connection.end + '_connector'}
          showHead={false}
          curveness={0.15}
          lineColor={'#419bb4'}
        />
      ));
    }

    return (
      <>
        {correctionArrows}
        {answerArrows}
      </>
    );
  }, [connections, answer, answerable, correction, classes, deleteConnection, isAdmin]);

  return (
    <div onLoad={useXarrow()} className={cx(classes.columnWrapper, { answerable: answerable })}>
      <div className={classes.leftColumn}>
        {connectors.filter(el => el.columnOrder === 0).map(connector => (
          <ExerciseConnector
            id={connector.id}
            key={connector.id}
            value={connector.value}
            identifier={connector.identifier}
            used={(answerable ? answer : connections).some(el => el.start === connector.id)}
            left={connector.columnOrder === 0}
            answerable={answerable}
            activate={setActiveLeft}
            active={answerable && activeLeft === connector.id}
            correction={correction}
          />
        ))}
      </div>
      <div className={classes.rightColumn}>
        {connectors.filter(el => el.columnOrder === 1).map(connector => (
          <ExerciseConnector
            id={connector.id}
            key={connector.id}
            value={connector.value}
            identifier={connector.identifier}
            used={(answerable ? answer : connections).some(el => el.end === connector.id)}
            right={connector.columnOrder === 1}
            answerable={answerable}
            activate={setActiveRight}
            active={answerable && activeRight === connector.id}
            correction={correction}
            disabled={!answerable}
          />
        ))}
      </div>
      <Xwrapper>
        {getArrows()}
      </Xwrapper>
    </div>
  );
};

ExerciseAnswerConnecting.propTypes = {
  shuffleConnectors: PropTypes.bool,
  connectors: PropTypes.array,
  connections: PropTypes.array,
  answerable: PropTypes.bool,
  onAnswer: PropTypes.func,
  answer: PropTypes.array,
  correction: PropTypes.bool,
};

export default ExerciseAnswerConnecting;
