import React from 'react';
import { Spacer } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import lang from 'lang';

import RubricFormStep from '../RubricFormStep';
import Input from 'components/common/Input';

const GeneralStep = ({ getValue, setValue, getErrors }) => (
  <RubricFormStep header={lang.general}>
    <Input
      dataTour="rubric-form-step-general-name"
      type="text"
      label={lang.rubrics.form.rubricNameLabel}
      placeholder={lang.rubrics.form.rubricNameLabel}
      value={getValue('name')}
      onChange={(event) => setValue('name', event.target.value)}
      errors={getErrors('name')}
      maxSize={100}
    />
    <Spacer px={20} />
    <Input
      dataTour="rubric-form-step-general-description"
      type="textarea"
      label={`${lang.rubrics.form.rubricDescriptionLabel} (${lang.optional})`}
      placeholder={lang.rubrics.form.rubricDescriptionLabel}
      value={getValue('description')}
      onChange={(event) => setValue('description', event.target.value)}
      errors={getErrors('description')}
      maxSize={2000}
    />
  </RubricFormStep>
);

GeneralStep.propTypes = {
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  getErrors: PropTypes.func,
};

export default GeneralStep;
