import { deepCopy } from 'utils';

const setNavigationCurrentItem = (studentAttempt, currentItem, sectionId) => {
  const newStudentAttempt = deepCopy(studentAttempt);

  if (sectionId) {
    const navigationSection = newStudentAttempt.navigationItems.find(el => el.answerSectionId === sectionId && el.itemType === 'section');

    if (!navigationSection) {
      return;
    }

    navigationSection.currentItem = currentItem;
    const attemptSection = newStudentAttempt.attemptItems.find((item) => item.answerSectionId === sectionId && item.itemType === 'section');
    if (attemptSection) {
      attemptSection.currentItem = currentItem;
    }

    if (newStudentAttempt.currentItem !== navigationSection.order && studentAttempt.testType !== 'full') {
      newStudentAttempt.currentItem = navigationSection.order;
    }

  } else {
    newStudentAttempt.currentItem = currentItem;
  }
  return newStudentAttempt;
};

export default setNavigationCurrentItem;
