import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  extraFields: {
    padding: '10px 20px 20px 20px',
  },
  inputTable: {
    width: '100%',
    tableLayout: 'fixed',
  },
  tableCell: {
    border: 'none !important',
    marginTop: '10px',
  },
  extraFieldsHeaderTitle: {
    fontWeight: 600,
    fontSize: '16px',
  },
  extraFieldButtonIcon: {
    marginRight: '5px',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    inputTable: {
      width: '1200px',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    inputTable: {
      width: '1200px',
    },
  },
  [`@media (max-width: ${theme.md}px)`]: {
    inputTable: {
      width: '1200px',
    },
  },
}));
