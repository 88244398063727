import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  exerciseCard: {
    marginTop: '20px',
  },
  answerZone: {
    padding: '10px 20px',
  },
  exerciseInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  previewExercise: {
    fontSize: '20px',
  },
  input: {
    '& :first-child': {
      border: 'none',
    },
    '& input': {
      width: '1.15em',
      height: '1.15em',
    },
    '& span': {
      paddingLeft: '0.5em',
    },
  },
  typeWrapper: {
    marginTop: '0.5em',
    display: 'flex',
    marginBottom: '0.5em',
  },
  type: {
    fontSize: '14px',
    color: theme.textColor2,
    marginLeft: '0.5em',
  },
  actionWrap: {
    paddingRight: '40px',
    cursor: 'pointer',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  actionBtn: {
    fontSize: '20px',
  },
  exerciseStatement: {
    width: '100%',
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '1',
    boxOrient: 'vertical',
    '& p': {
      paddingRight: '0.5em',
      minHeight: '20px',
      marginTop: '0 !important',
      marginBottom: '0 !important',
    },
    '& img.ql-image': {
      maxHeight: '20px !important',
      marginTop: '2px !important',
    },
    '& .gap': {
      backgroundColor: 'white',
      border: `1px solid ${theme.buttonBorder3}`,
      borderRadius: '4px',
      width: '50px',
      margin: '0 3px 0 3px',
    },
  },
  inputTable: {
    width: '100%',
    tableLayout: 'fixed',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    inputTable: {
      tableLayout: 'unset',
    },
  },
}));
