import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { pageError } from 'actions/pageActions';
import { selectPages, selectTests, setPages, setTests, selectLoading, startLoading, stopLoading, selectPage, setPage, reset } from 'actions/testsPageActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import useQuery from 'hooks/common/useQuery';
import useTour from 'hooks/common/useTour';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import { introductionTourOptions, introductionTourSteps } from 'services/shepherd';
import toggles from 'toggles';
import { cleanAndSortGroups, getCookie, getErrorCode, removeCookie } from 'utils';

import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import NoEntity from 'components/common/NoEntity';
import TestFormModal from 'components/test/TestFormModal';
import Header from 'components/tests/Header';
import TestCard from 'components/tests/test-card/TestCard';

import useStyles from './styles';

const Tests = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const { spaceId } = useParams();
  const { loaded } = usePageLogic(lang.tests.title, true);

  const isDark = useSelector(state => state.page.isDark);
  const isTeacher = useSelector(state => state.user.data.isTeacher);
  const username = useSelector(state => state.user.data.username);
  const tests = useSelector(selectTests);
  const pages = useSelector(selectPages);
  const loading = useSelector(selectLoading);
  const page = useSelector(selectPage);

  const [getGroupTestsRequest] = useApi(api.getGroupTests);
  const [getStudentPublicationsRequest] = useApi(api.getStudentPublications);
  const [getSchoolGroupsRequest] = useApi(api.getSchoolGroups);
  const [getGroupsRequest] = useApi(api.getGroups);
  const iaveToggle = useFeature(toggles.iave);
  const statisticsToggle = useFeature(toggles.statistics);

  const [schoolGroups, setSchoolGroups] = useState([]);
  const [schoolGroup, setSchoolGroup] = useState(null);
  const [groups, setGroups] = useState([]);
  const [testFormModal, setTestFormModal] = useState(false);
  const [logTourEvent] = useApi(api.logTourEvent);

  const [introductionTour, setIntroductionTourSteps] = useTour(
    introductionTourOptions,
    [],
    useCallback(() => logTourEvent([], { tour: 'introduction', action: 'start' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'introduction', action: 'complete' }), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'introduction', action: 'cancel' }), [logTourEvent]),
  );

  useEffect(() => {
    const tourTestId = getCookie('tour_test_id');
    if (tourTestId) {
      setIntroductionTourSteps(introductionTourSteps(tourTestId, statisticsToggle));
      introductionTour.start();
      removeCookie('tour_test_id');
    }
  }, [introductionTour, setIntroductionTourSteps, statisticsToggle]);

  const fetchTests = useCallback(() => {
    if (isTeacher) {
      if (!schoolGroup) {
        return;
      }

      dispatch(startLoading());
      getGroupTestsRequest([schoolGroup.id], { page }, ({ data }) => {
        dispatch(stopLoading());
        if (data.status === 0) {
          dispatch(setTests(data.tests));
          dispatch(setPages(data.pages));
        }
      });
    } else {
      dispatch(startLoading());
      getStudentPublicationsRequest([page], null, ({ data }) => {
        loaded();
        dispatch(stopLoading());
        if (data.status === 0) {
          let newTests = data.tests;
          if (iaveToggle) {
            const studentNumber = parseInt(username.split('-')[1]);

            if (studentNumber) {
              const oddStudentNumber = studentNumber % 2 === 1;
              const version = oddStudentNumber ? 'v1' : 'v2';
              newTests = data.tests.filter(test =>
                test.groupName.includes(version) || (!test.groupName.includes('v1') && !test.groupName.includes('v2')),
              );
            }
          }

          dispatch(setTests(newTests));
          dispatch(setPages(data.pages));
        } else if (data.status === -2) {
          dispatch(pageError(getErrorCode(data.status)));
        }
      });
    }
  }, [dispatch, getGroupTestsRequest, getStudentPublicationsRequest, isTeacher, loaded, schoolGroup, page, iaveToggle, username]);

  const fetchSchoolGroups = useCallback(() => {
    getSchoolGroupsRequest([spaceId], null, ({ data }) => {
      if (data.status === 0) {
        loaded();

        const newGroups = cleanAndSortGroups(data.groups);

        setSchoolGroups(newGroups);

        const currentGroupId = query().get('group');
        const group = newGroups.find(group => group.id === currentGroupId);

        if (group) {
          setSchoolGroup(group);
        } else {
          setSchoolGroup(newGroups[0]);
          if (!newGroups[0]) {
            return;
          }
          history.replace({
            search: `?group=${newGroups[0].id}`,
          });
        }
      }
    });
  }, [getSchoolGroupsRequest, loaded, query, history, spaceId]);

  const fetchGroups = useCallback(() => {
    getGroupsRequest([], null, ({ data }) => {
      if (data.status === 0) {
        loaded();
        const newGroups = cleanAndSortGroups(data.groups);
        setGroups(newGroups);
      }
    });
  }, [getGroupsRequest, loaded]);

  useEffect(() => {
    if (isTeacher) {
      fetchSchoolGroups();
      fetchGroups();
    }
  }, [isTeacher, fetchSchoolGroups, fetchGroups]);

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  useEffect(() => () => dispatch(reset()), [dispatch]);

  useEffect(() => {
    dispatch(setPage(1));
    const currentGroupId = query().get('group');
    const schoolGroup = schoolGroups.find(group => group.id === currentGroupId);

    if (schoolGroup) {
      setSchoolGroup(schoolGroup);
    } else {
      setSchoolGroup(schoolGroups[0]);
    }
  }, [dispatch, location, schoolGroups, query]);

  const getModals = () => {
    if (!isTeacher) {
      return;
    }

    return (
      <TestFormModal
        open={testFormModal}
        close={() => setTestFormModal(false)}
        group={schoolGroup}
        groups={schoolGroups}
        refresh={fetchTests}
      />
    );
  };

  const _setPage = (_page) => dispatch(setPage(_page));

  const _setGroup = (group) => {
    _setPage(1);
    setSchoolGroup(group);
  };

  const getTests = () => {
    if (tests.length === 0) {
      return (
        <NoEntity
          message={isTeacher ? lang.tests.teacher.noTests : lang.tests.student.noTests}
          action={isTeacher ? () => setTestFormModal(true) : null}
        />
      );
    }

    return tests.map((test) => (
      <TestCard
        key={test.id}
        test={test}
        groups={groups}
        fetchTests={fetchTests}
        setTests={setTests}
      />
    ));
  };

  return (
    <Fragment>
      {getModals()}
      <Header
        groups={schoolGroups}
        group={schoolGroup}
        setGroup={_setGroup}
        setTestFormModal={setTestFormModal}
      />
      <div className={classes.wrapper}>
        <Render when={!isTeacher}>
          <div className={classes.refreshButtonWrapper}>
            <Button
              className={classes.refreshButton}
              onClick={fetchTests}
              loading={loading}
              gray
            >
              <div>
                {lang.refresh}
              </div>
              <FontAwesomeIcon icon={faRefresh} />
            </Button>
          </div>
        </Render>
        <Pagination
          pages={pages > 0 ? pages : 1}
          top
          bottom
          page={page}
          setPage={_setPage}
          dark={isDark}
          className={classes.pagination}
        >
          <Loading active={loading} />
          <Render when={!loading}>
            <div className={classes.testsWrap}>
              {getTests()}
            </div>
          </Render>
        </Pagination>
      </div>
    </Fragment>
  );
};

export default Tests;
