import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  homeLink: {
    color: theme.textColor,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  themeToggler: {
    cursor: 'pointer',
  },
  localeButton: {
    marginRight: '20px',
  },
  main: {
    backgroundColor: theme.backgroundColor,
    minHeight: '100vh',
    color: theme.textColor,
    width: '100%',
    '&.layoutWidth': {
      width: 'calc(100% - 237px)',
    },
  },
  navbarSpacer: {
  },
  sidebarSpacer: {
    paddingLeft: '237px',
  },
  layoutWrapper: {
    '& > .otl-wrapper > .otl-wrapperInnerPadding': {
      padding: '0',
    },
  },
  loading: {
    display: 'none',
  },
  appNotAvailableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10vh',
  },
  logo: {
    marginTop: '20vh',
  },
  iaveLogosContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: '10px',
    width: '100%',
    marginTop: '20px',
  },
  iaveLogo: {
    width: '15%',
  },
  prrLogo: {
    width: '45%',
  },
  appNotAvailable: {
    textAlign: 'center',
    fontSize: '40px',
    marginTop: '10vh',
    color: theme.textColor,
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    main: {
      '&.layoutWidth': {
        width: '100%',
      },
    },
    sidebarSpacer: {
      padding: 'unset',
    },
    navbarSpacer: {
      paddingTop: '56px',
    },
  },
}));
