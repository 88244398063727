import React, { createRef, useEffect, useState } from 'react';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import Input from 'components/common/Input';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const ExerciseGrader = ({ grade, maxGrade, saveGrade, defining, disabled, dataTour }) => {
  const classes = useStyles();
  const toast = useToast();

  const [currentGrade, setCurrentGrade] = useState(grade || 0);
  const inputRef = createRef();

  useEffect(() => {
    setCurrentGrade(grade);
  }, [grade]);

  const onSubmit = (event) => {
    event.preventDefault();
    inputRef.current.blur();
  };

  const _saveGrade = () => {
    if (!currentGrade && currentGrade !== 0) {
      toast.warning(lang.test.grades.notEmpty);
      setCurrentGrade(grade);
      return;
    }

    if (defining && currentGrade < 0) {
      toast.warning(lang.test.grades.negativeValueError);
      setCurrentGrade(grade);
      return;
    }

    if (defining && currentGrade > 10000) {
      toast.warning(lang.test.grades.pointsTooHigh);
      setCurrentGrade(grade);
      return;
    }

    if (!defining && currentGrade > maxGrade) {
      toast.warning(lang.test.grades.maxValueExceeded);
      setCurrentGrade(grade);
      return;
    }

    saveGrade(currentGrade);
  };

  const isGradeInvalid = () => {
    return (!defining && currentGrade > maxGrade)
      || (defining && currentGrade < 0)
      || (defining && currentGrade > 10000);
  };

  return (
    <div data-tour={dataTour} className={classes.graderWrapper}>
      <Tooltip tip={disabled && !defining ? lang.test.grades.unavailableStudent : null}>
        <form onSubmit={onSubmit} tabIndex="-1">
          <Input
            type="number"
            value={currentGrade}
            onChange={(event) => setCurrentGrade(parseFloat(event.target.value))}
            placeholder={lang.appKeywords.grade}
            className={classes.gradeInput}
            invalid={isGradeInvalid()}
            onBlur={_saveGrade}
            disabled={disabled}
            step={0.01}
            _ref={inputRef}
          />
        </form>
      </Tooltip>
      <Render when={!defining && maxGrade}>
        <div className={classes.maxGradeText}>
          {' / '}
          {parseFloat(maxGrade).toFixed(2)}
        </div>
      </Render>
      <div className={classes.pointsText}>
        {currentGrade === 1 ? lang.test.grades.point : lang.test.grades.points}
      </div>
    </div>
  );
};

ExerciseGrader.propTypes = {
  grade: PropTypes.number,
  maxGrade: PropTypes.number,
  saveGrade: PropTypes.func.isRequired,
  defining: PropTypes.bool,
  disabled: PropTypes.bool,
  dataTour: PropTypes.bool,
};

export default ExerciseGrader;
