import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  responsiveLang: {
    '&.xl': {
      display: 'none',
    },
    '&.lg': {
      display: 'none',
    },
    '&.md': {
      display: 'none',
    },
    '&.sm': {
      display: 'none',
    },
    '&.xs': {
      display: 'none',
    },
  },
  [`@media (max-width: ${theme.xl}px)`]: {
    responsiveLang: {
      display: 'none',
      '&.xl': {
        display: 'unset',
      },
      '&.lg': {
        display: 'none',
      },
      '&.md': {
        display: 'none',
      },
      '&.sm': {
        display: 'none',
      },
      '&.xs': {
        display: 'none',
      },
    },
  },
  [`@media (max-width: ${theme.lg}px)`]: {
    responsiveLang: {
      display: 'none',
      '&.xl': {
        display: 'none',
      },
      '&.lg': {
        display: 'unset',
      },
      '&.md': {
        display: 'none',
      },
      '&.sm': {
        display: 'none',
      },
      '&.xs': {
        display: 'none',
      },
    },
  },
  [`@media (max-width: ${theme.md}px)`]: {
    responsiveLang: {
      display: 'none',
      '&.xl': {
        display: 'none',
      },
      '&.lg': {
        display: 'none',
      },
      '&.md': {
        display: 'unset',
      },
      '&.sm': {
        display: 'none',
      },
      '&.xs': {
        display: 'none',
      },
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    responsiveLang: {
      display: 'none',
      '&.xl': {
        display: 'none',
      },
      '&.lg': {
        display: 'none',
      },
      '&.md': {
        display: 'none',
      },
      '&.sm': {
        display: 'unset',
      },
      '&.xs': {
        display: 'none',
      },
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    responsiveLang: {
      display: 'none',
      '&.xl': {
        display: 'none',
      },
      '&.lg': {
        display: 'none',
      },
      '&.md': {
        display: 'none',
      },
      '&.sm': {
        display: 'none',
      },
      '&.xs': {
        display: 'unset',
      },
    },
  },
}));
