import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px',
  },
  tooltipContainer: {
    '& .otl-tooltip': {
      bottom: '75%',
    },
  },
  typeOption: {
    marginRight: '10px',
    marginTop: '10px',
    height: '170px !important',
    width: '170px !important',
    fontSize: '14px',
    borderRadius: '10px',
    backgroundColor: theme.cardBackground2,
    border: `1px solid ${theme.cardBorder2}`,
    position: 'relative',
    transition: 'background-color 0.1s',
    '&:hover': {
      backgroundColor: theme.cardBackgroundHover2,
      border: `1px solid ${theme.cardBorderHover2}`,
    },
    '& .otl-cardContentContainer': {
      padding: '0',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&.soon': {
      opacity: 0.4,
    },
  },
  newTag: {
    position: 'absolute',
    top: '5px',
    left: '5px',
    backgroundColor: '#269FEB30',
    padding: '3px 10px',
    borderRadius: '50px',
    color: '#2671EB',
    fontWeight: '600',
  },
  premiumTag: {
    marginTop: '10px',
  },
  typeOptionIcon: {
    fontSize: '40px',
  },
  typeOptionText: {
    textAlign: 'center',
    marginTop: '13px',
  },
  selection: {
    width: '16px !important',
    height: '16px !important',
    borderRadius: '100%',
    backgroundColor: theme.backgroundColor,
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: 'unset',
    border: `1px solid ${theme.selectedHover}`,
    '&.selected': {
      width: '17px !important',
      height: '17px !important',
      color: theme.selectedColor,
      border: 'none',
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    typeOption: {
      height: '90px !important',
      width: '90px !important',
    },
    typeOptionIcon: {

      fontSize: '25px',
    },
    typeOptionText: {

      marginTop: '13px',
      fontSize: '12px',
    },
  },
}));
