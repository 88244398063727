import Quill from 'quill';

const Inline = Quill.import('blots/inline');
class AnnotationBlot extends Inline {
  static create(value) {
    const node = super.create(value);

    const isOverlap = value.id.includes('|');
    node.classList.add(`ql-annotation-${value.color}`);
    if (isOverlap) {
      node.classList.add('overlap');
    }

    node.setAttribute('data-id', value.id || 'comment-blot');
    node.setAttribute('data-color', value.color);

    return node;
  }

  static formats(node) {
    return {
      id: node.getAttribute('data-id'),
      color: node.getAttribute('data-color'),
    };
  }

  static setIsActive(textAnnotationId, isActive) {
    const annotations = Array.from(document.querySelectorAll(`[data-id*="${textAnnotationId}"]`));

    let mainAnnotation = annotations.find(annotation => annotation.getAttribute('data-id') === textAnnotationId);

    if (!mainAnnotation) {
      mainAnnotation = annotations.find(annotation => annotation.getAttribute('data-id').endsWith(textAnnotationId))
        ?? annotations.find(annotation => annotation.getAttribute('data-id').includes(textAnnotationId));
    }

    const color = mainAnnotation.getAttribute('data-color');

    annotations.forEach(annotation => {
      if (!annotation) {
        return;
      }

      if (isActive) {
        annotation.classList.remove('ql-annotation-red');
        annotation.classList.remove('ql-annotation-yellow');
        annotation.classList.remove('ql-annotation-green');
        annotation.classList.remove('ql-annotation-blue');
        annotation.classList.remove('unfocused');
        annotation.classList.add(`ql-annotation-${color}`);
        annotation.classList.add('active');
      } else {
        annotation.classList.remove('active');
      }
    });
  }

  static setAllInactive() {
    const annotations = document.querySelectorAll('.ql-annotation');

    annotations.forEach(annotation => {
      const annotationId = annotation.getAttribute('data-id');
      this.setIsActive(annotationId, false);
    });
  }

  static setAllUnfocused() {
    const annotations = document.querySelectorAll('.ql-annotation');

    annotations.forEach(annotation => {
      annotation.classList.remove('active');
      annotation.classList.add('unfocused');
    });
  }

  static setAllDefault() {
    const annotations = document.querySelectorAll('.ql-annotation');

    annotations.forEach(annotation => {
      const colorName = annotation.getAttribute('data-color');
      const id = annotation.getAttribute('data-id');
      const isOverlap = id.includes('|');

      annotation.classList.remove('ql-annotation-red');
      annotation.classList.remove('ql-annotation-yellow');
      annotation.classList.remove('ql-annotation-green');
      annotation.classList.remove('ql-annotation-blue');
      annotation.classList.remove('unfocused');
      annotation.classList.remove('active');
      annotation.classList.add(`ql-annotation-${colorName}`);
      if (isOverlap) {
        annotation.classList.add('overlap');
      }

    });
  }
}

AnnotationBlot.blotName = 'annotation';
AnnotationBlot.tagName = 'span';
AnnotationBlot.className = 'ql-annotation';

export default AnnotationBlot;
