import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  statNumbers: {
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingRight: '15px',
    marginTop: '5px',
  },
  statNumber: {
    marginTop: '5px',
    marginBottom: '25px',
    fontSize: '19px',
    display: 'flex',
    justifyContent: 'left',
    color: theme.successColor,
    '&.failed': {
      color: theme.errorColor,
    },
  },
  gradeStat: {
    display: 'inline',
    marginLeft: '5px',
  },
  gradeStatDivider: {
    padding: '0 5px',
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    gradeStat: {
      display: 'none',
    },
  },
}));
