import { useCallback, useMemo } from 'react';
import Shepherd from 'shepherd.js';

/**
 * Hook to use shepherd tour
 *
 * @param {Shepherd.Tour.TourOptions} tourOptions
 * @param {Shepherd.Step.StepOptions[]} stepOptions
 * @param {() => void | Promise<void>} onStart
 * @param {() => void | Promise<void>} onComplete
 * @param {() => void | Promise<void>} onCancel
 * @returns {[Shepherd.Tour, {(steps: Shepherd.Step.StepOptions[]) => void}]}
 */
const useTour = (tourOptions, stepOptions, onStart, onComplete, onCancel) => {

  const tour = useMemo(() => {
    const _tour = new Shepherd.Tour(tourOptions);

    _tour.on('start', onStart);
    _tour.on('complete', onComplete);
    _tour.on('cancel', onCancel);

    return _tour;
  }, [tourOptions, onStart, onComplete, onCancel]);

  const setSteps = useCallback(
    /**
     * Take a set of steps and formats to use actions on the buttons in the current context
     * @param {Shepherd.Step.StepOptions[]} steps
     * @returns {void}
     */
    (steps) => {
      // Return nothing if there are no steps
      if (!steps.length) {
        return [];
      }

      const parsedStepsForAction = steps.map((step, index) => {
        if (step.buttons) {
          step.buttons = step.buttons.map(({ action, type, ...rest }) => ({
            action: type ? tour[type] : action,
            type,
            ...rest,
          }));
        }

        return {
          ...step,
          when: {
            show: function () {
              const currentStep = tour.getCurrentStep();
              const currentStepElement = currentStep.getElement();
              const header = currentStepElement.querySelector('.shepherd-header');
              const cancelIcon = header.querySelector('.shepherd-cancel-icon');
              // progress
              const percentage = (index + 1) / steps.length * 100;
              const progress = document.createElement('div');
              progress.className = 'shepherd-progress';
              // fill
              const progressFill = document.createElement('div');
              progressFill.className = 'shepherd-progress-fill';
              progressFill.style.width = `${percentage}%`;
              // bar
              const progressBar = document.createElement('div');
              progressBar.className = 'shepherd-progress-bar';
              progressBar.appendChild(progressFill);
              // place everything in the DOM
              progress.appendChild(progressBar);
              header && header.insertBefore(progress, cancelIcon);
            },
          },
        };
      });

      return parsedStepsForAction
        .reverse()
        .forEach((step) => tour.addStep(step, 0));
    }, [tour]);

  setSteps(stepOptions);

  return [tour, setSteps];
};

export default useTour;
