import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { faFileAlt, faLock, faLockOpen, faPlusCircle, faStopwatch, faTable, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectUserSubjects, selectUserYears } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useInput from 'hooks/common/useInput';
import useExploreFilters from 'hooks/explore/useExploreFilters';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { sortPreferencesByActive } from 'utils';

import CardSelector from '../card-selector/CardSelector';
import FilterPool from 'components/common/filter-selector/FilterPool';
import Input from 'components/common/Input';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const SettingsModal = ({ open, close, test, refresh }) => {
  const toast = useToast();
  const history = useHistory();
  const classes = useStyles();
  const exploreToggle = useFeature(toggles.explore);
  const [editTestRequest] = useApi(api.editTest);
  const userSubjects = useSelector(selectUserSubjects);
  const userYears = useSelector(selectUserYears);

  const [filters] = useExploreFilters();
  const [selectedSubjects, setSelectedSubjects] = useState(test.subjects ? test.subjects.map(userSubject => userSubject.id) : []);
  const [selectedYears, setSelectedYears] = useState(test.years ? test.years.map(userYear => userYear.id) : []);
  const [loading, setLoading] = useState(false);
  const [name, setName, nameErrors, setNameErrors] = useInput(test.name);

  const classificationTypeOptions = useMemo(() => {
    return [
      { value: 'classic', label: lang.appKeywords.classic, tip: lang.test.testForm.classicClassificationTypeTip, icon: faPlusCircle },
      { value: 'rubric', label: lang.appKeywords.rubric, tip: lang.test.testForm.rubricClassificationTypeTip, icon: faTable },
      { value: 'none', label: lang.appKeywords.noClassification, tip: lang.test.testForm.noClassificationTypeTip, icon: faTimesCircle },
    ];
  }, []);
  const [classificationType, setClassificationType] = useState(classificationTypeOptions.find(option => option.value === test.classificationType));

  const typeOptions = useMemo(() => {
    return [
      { value: 'test', label: lang.appKeywords.test, tip: lang.test.testForm.testTypeTip, icon: faStopwatch },
      { value: 'worksheet', label: lang.appKeywords.worksheet, tip: lang.test.testForm.worksheetTypeTip, icon: faFileAlt },
    ];
  }, []);
  const [testType, setTestType] = useState(typeOptions.find(option => option.value === test.type));

  const privacyOptions = useMemo(() => {
    return [
      { value: 'public', label: lang.test.testForm.public, tip: lang.test.testForm.publicTip, icon: faLockOpen },
      { value: 'private', label: lang.test.testForm.private, tip: lang.test.testForm.privateTip, icon: faLock },
    ];
  }, []);
  const [privacyType, setPrivacyType] = useState(test.isPublic ? privacyOptions[0] : privacyOptions[1]);

  useEffect(() => {
    setSelectedSubjects(test.subjects.length !== 0 ? test.subjects.map(userSubject => userSubject.id) : userSubjects.map(userSubject => userSubject.id));
    setSelectedYears(test.years.length !== 0 ? test.years.map(userYear => userYear.id) : userYears.map(userYear => userYear.id));
    setName(test.name);
    setClassificationType(classificationTypeOptions.find(option => option.value === test.classificationType));
    setTestType(typeOptions.find(option => option.value === test.type));
    setPrivacyType(test.isPublic ? privacyOptions[0] : privacyOptions[1]);
  }, [test, setSelectedSubjects, setSelectedYears, setName, setClassificationType, setTestType, setPrivacyType, typeOptions, classificationTypeOptions, privacyOptions, userSubjects, userYears]);

  const toggleSubject = useCallback((selectedSubject) => {
    const isActive = !!selectedSubjects.find(subjectId => subjectId === selectedSubject.id);
    let newSelectedSubjects;
    if (isActive) {
      newSelectedSubjects = selectedSubjects.filter(subjectId => subjectId !== selectedSubject.id);
    } else {
      newSelectedSubjects = [...selectedSubjects, selectedSubject.id];
    }

    setSelectedSubjects(newSelectedSubjects);
  }, [selectedSubjects, setSelectedSubjects]);

  const toggleYear = useCallback((selectedYear) => {
    const isActive = !!selectedYears.find(yearId => yearId === selectedYear.id);
    let newSelectedYears;
    if (isActive) {
      newSelectedYears = selectedYears.filter(yearId => yearId !== selectedYear.id);
    } else {
      newSelectedYears = [...selectedYears, selectedYear.id];
    }

    setSelectedYears(newSelectedYears);
  }, [selectedYears, setSelectedYears]);

  const subjects = useMemo(() => filters
    .filter(filter => filter.type === 'subject')
    .map(filter => {
      const isActive = !!selectedSubjects.find(el => el === filter.id);

      return ({
        ...filter,
        type: 'action',
        active: isActive,
        color: isActive ? filter.color : null,
        hoverColor: isActive ? filter.hoverColor : null,
        action: () => toggleSubject(filter),
      });
    }), [selectedSubjects, filters, toggleSubject]);

  const years = useMemo(() => filters
    .filter(filter => filter.type === 'year')
    .map(filter => {
      const isActive = !!selectedYears.find(el => el === filter.id);

      return ({
        ...filter,
        type: 'action',
        active: isActive,
        color: isActive ? filter.color : null,
        hoverColor: isActive ? filter.hoverColor : null,
        action: () => toggleYear(filter),
      });
    }), [selectedYears, filters, toggleYear]);

  const closeModal = () => {
    if (!loading) {
      close();
      setTimeout(() => {
        setName(test ? test.name : '');
        setNameErrors([]);
        setClassificationType(classificationTypeOptions.find(option => option.value === test.classificationType));
        setTestType(typeOptions.find(option => option.value === test.type));
        setPrivacyType(test.isPublic ? privacyOptions[0] : privacyOptions[1]);
        setSelectedSubjects(test.subjects ? test.subjects.map(userSubject => userSubject.id) : []);
        setSelectedYears(test.years ? test.years.map(userYear => userYear.id) : []);
      }, 350);
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    let errors = false;
    const _nameErrors = [];

    if (name.length === 0) {
      errors = true;
      _nameErrors.push(lang.test.testForm.errorNameRequired);
    }

    if (errors === true) {
      setTimeout(() => {
        setNameErrors(_nameErrors);
        setLoading(false);
      }, 200);
      return;
    }

    if (privacyType.value === 'public' && test.items.length === 0) {
      toast.error(lang.test.testForm.errorNoExercises);
      setLoading(false);
      close();
      return;
    }

    setNameErrors([]);

    const testSettings = {
      name: name,
      type: testType.value,
      classificationType: classificationType.value,
      isPublic: privacyType.value === 'public' ? true : false,
      subjects: selectedSubjects,
      years: selectedYears,
    };

    editTestRequest([test.id], testSettings, ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          toast.success(lang.test.settingsModal.successEditSettings);
          setLoading(false);
          setClassificationType(classificationTypeOptions.find(option => option.value === classificationType.value));
          refresh();
          close();
          if (classificationType.value !== test.classificationType) {
            history.push({
              search: 'tab=exercises',
            });
          }
        }, 300);
        return;
      }

      setTimeout(() => {
        toast.error(lang.oops);
        setLoading(false);
      }, 200);
    });
  };

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: closeModal,
    },
    {
      name: lang.save,
      loading: loading,
      onClick: handleSubmit,
    },
  ];

  return (
    <Modal
      open={open}
      close={closeModal}
      header={lang.settings}
      actions={actions}
      center
      transition
      className={classes.modal}
    >
      <Input
        type="text"
        value={name}
        label={lang.test.testForm.testName}
        placeholder={lang.test.testForm.testName}
        onChange={(event) => setName(event.target.value)}
        maxSize={100}
        errors={nameErrors}
      />
      <Spacer px={20} />
      <Render when={!test.isPublished}>
        <CardSelector
          headerLabel={lang.test.testForm.testTypeLabel}
          options={typeOptions}
          selected={testType}
          setSelected={setTestType}
        />
        <Spacer px={20} />
        <CardSelector
          headerLabel={lang.test.testForm.classificationTypeLabel}
          options={classificationTypeOptions}
          selected={classificationType}
          setSelected={setClassificationType}
        />
        <Spacer px={20} />
      </Render>
      <Render when={exploreToggle}>
        <CardSelector
          headerLabel={lang.test.testForm.whoCanSee}
          options={privacyOptions}
          selected={privacyType}
          setSelected={setPrivacyType}
        />
        <Spacer px={20} />
        <Render when={privacyType.value === 'public'}>
          <div>
            <div className={classes.label}>
              {lang.exerciseForm.exerciseSettings.areas}
            </div>
            <FilterPool
              poolItems={sortPreferencesByActive(subjects)}
              numberOfVisibleItems={10}
            />
          </div>
          <Spacer px={15} />
          <div>
            <div className={classes.label}>
              {lang.exerciseForm.exerciseSettings.years}
            </div>
            <FilterPool
              poolItems={sortPreferencesByActive(years)}
              numberOfVisibleItems={10}
            />
          </div>
        </Render>
      </Render>

    </Modal>
  );
};

SettingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  test: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isPublic: PropTypes.bool.isRequired,
    groupId: PropTypes.string,
    type: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    classificationType: PropTypes.string,
    isPublished: PropTypes.bool,
    subjects: PropTypes.arrayOf(PropTypes.string),
    years: PropTypes.arrayOf(PropTypes.string),
  }),
  refresh: PropTypes.func,
};

export default SettingsModal;
