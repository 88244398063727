import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  criteriaGrade: {
    display: 'flex',
    alignItems: 'center',
  },
  gradeInput: {
    '& input': {
      width: '35px',
      height: '25px',
      padding: '0',
      minHeight: '0',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.textColor8,
      backgroundColor: theme.graderBackground,
      borderRadius: '2px',
      justifyContent: 'center',
      border: 0,
      margin: '0 5px',
      '&::-webkit-inner-spin-button': {
        appearance: 'none',
      },
      '&:hover': {
        backgroundColor: theme.graderBackgroundHover,
      },
      '&:focus': {
        border: `2px solid ${theme.graderBackground}`,
        backgroundColor: theme.graderBackground2,
      },
      '&::placeholder': {
        fontSize: '10px',
      },
      '&:disabled': {
        backgroundColor: `${theme.graderBackground} !important`,
        opacity: 0.4,
      },
      '&.invalid': {
        border: '2px solid #fb604599',
      },
    },
  },
  criteriaText: {
    fontWeight: 600,
    fontSize: '12px',
    color: theme.textColor9,
    marginRight: '5px',
  },
  errorTotal: {
    color: '#fb6045',
    fontSize: '14px',
  },
}));
