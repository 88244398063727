import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import Modal from 'components/common/Modal';

const RemoveEntityModal = ({ open, close, onConfirm, loading, type }) => {
  const _close = () => {
    if (loading) {
      return;
    }

    close();
  };

  const actions = [
    {
      name: lang.remove,
      color: 'red',
      loading: loading,
      onClick: onConfirm,
    },
    {
      name: lang.cancel,
      color: 'black',
      onClick: _close,
    },
  ];

  const getHeader = () => {
    switch (type) {
      case 'information':
        return lang.test.removeInformationBlockFromTest;
      case 'section':
        return lang.test.removeSectionFromTest;
      default:
        return lang.test.removeExerciseFromTest;
    }
  };

  const getContent = () => {
    switch (type) {
      case 'information':
        return lang.test.hintConfirmRemoveInformationBlock;
      case 'section':
        return lang.test.hintConfirmRemoveSection;
      default:
        return lang.test.hintConfirmRemove;
    }
  };

  return (
    <Modal
      open={open}
      close={_close}
      header={getHeader()}
      actions={actions}
      center
      transition
    >
      {getContent()}
    </Modal>
  );
};

RemoveEntityModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  onConfirm: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

export default RemoveEntityModal;
