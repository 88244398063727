import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  leftColumn: {
    display: 'grid',
    gridTemplateColumns: '80% 50px',
    alignContent: 'center',
    padding: '15px',
    gap: '0.5em',
  },
  rightColumn: {
    display: 'grid',
    gridTemplateColumns: '50px 80%',
    alignContent: 'center',
    padding: '15px',
    gap: '0.5em',
  },
  columnWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'relative',
  },
  arrowDelete: {
    color: theme.removeColor,
    backgroundColor: 'white',
    borderRadius: '50%',
    cursor: 'pointer',
  },
}));
