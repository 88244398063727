import React, { useCallback, useMemo } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import IconBtn from 'components/common/IconBtn';
import Input from 'components/common/Input';

import useStyles from './styles';

const TeacherActions = ({ teachers, setTeachers, setRemoveTeachersModal }) => {
  const classes = useStyles();

  const teachersSelectedIds = useMemo(() => {
    return teachers
      .filter(teacher => teacher.selected)
      .map(teacher => teacher.id);
  }, [teachers]);

  const selectAll = useCallback((event) => {
    const newTeachers = teachers
      .map(teacher => ({ ...teacher, selected: event.target.checked }));

    setTeachers(newTeachers);
  }, [teachers, setTeachers]);

  return (
    <div className={classes.teacherFilters}>
      <Input
        type="checkbox"
        label={lang.selectAll}
        onChange={selectAll}
      />
      <Render when={teachersSelectedIds.length !== 0}>
        <IconBtn
          icon={faTrash}
          text={lang.group.removeSelected}
          onClick={() => setRemoveTeachersModal(true)}
        />
      </Render>
    </div>
  );
};

TeacherActions.propTypes = {
  teachers: PropTypes.array,
  setTeachers: PropTypes.func,
  setRemoveTeachersModal: PropTypes.func,
};

export default TeacherActions;
