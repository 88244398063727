import React, { useMemo } from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import { quillIsEmpty } from 'utils';
import { getAnswer } from 'utils/attempts';

import ReviewableExercise from '../ReviewableExercise';
import EntityActionsContainer from 'components/common/entity/EntityActionsContainer';
import EntityBody from 'components/common/entity/EntityBody';
import EntityContent from 'components/common/entity/EntityContent';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import EntityHeader from 'components/common/entity/EntityHeader';
import EntitySubHeader from 'components/common/entity/EntitySubHeader';
import EntityTitle from 'components/common/entity/EntityTitle';
import ExerciseGrade from 'components/exercises/exercise/exercise-header/ExerciseGrade';

import useStyles from './styles';

const ReviewableSection = ({ section, classificationType }) => {
  const classes = useStyles();

  const { name, description, exercises } = section;

  const sectionTotal = useMemo(() => {
    return exercises.reduce((previous, current) => previous + current.maxGrade, 0);
  }, [exercises]);

  const sectionGrade = useMemo(() => {
    return exercises.reduce((previous, current) => previous + current.grade, 0);
  }, [exercises]);

  const getAnswers = () => {
    if (!exercises) {
      return null;
    }

    let exerciseNum = 0;
    return exercises.map((exercise) => {
      if (exercise.type !== 'information') {
        exerciseNum++;
      }

      exercise.answer = getAnswer(exercise);

      if (exercise.hasJustification) {
        exercise.justification = exercise.valueText ?? exercise.quillAnswer;
      }

      return (
        <div
          className={classes.reviewableExercise}
          key={exercise.id}
        >
          <ReviewableExercise
            num={exerciseNum}
            exercise={exercise}
            classificationType={classificationType}
          />
        </div>
      );
    });
  };

  return (
    <EntityBody
      className={classes.section}
    >
      <EntityHeader>
        <EntityTitle
          name={name}
        />
        <EntityActionsContainer>
          <Render when={classificationType !== 'none'}>
            <ExerciseGrade
              grade={sectionGrade}
              maxGrade={sectionTotal}
            />
          </Render>
        </EntityActionsContainer>
      </EntityHeader>
      <Render when={!quillIsEmpty(description)}>
        <EntitySubHeader>
          <EntityExpandableText
            text={description}
          />
        </EntitySubHeader>
      </Render>
      <EntityContent>
        {getAnswers()}
      </EntityContent>
    </EntityBody>
  );
};

ReviewableSection.propTypes = {
  section: PropTypes.object,
  classificationType: PropTypes.string,
};

export default ReviewableSection;
