const light = {
  isDark: false,

  backgroundColor: '#ffffff',
  backgroundColor2: '#efefef',

  accentColor: '#3d3d3d',
  accentColor2: '#2c527c',
  accentColor3: '#4a4a4a',
  accentColor4: '#454545',
  accentColor5: '#0e243d',
  accentColor6: '#2d2d2d',
  accentColor7: '#5d5d5d',

  cardColor: '#e1e1e1',
  cardColor2: '#d8d8d8',
  cardColor3: '#fbfbfb',

  inputBackground: '#e6e6e6',
  inputBackground2: '#f4f4f4',
  inputBorder2: '#e9e9e9',
  inputBorder3: '#dfdfdf',

  textColor: '#2d2d2d',
  textColor2: '#2d2d2d',
  textColor3: '#797979',
  textColor4: '#888888',
  textColor5: '#929292',
  textColor6: '#676767',
  textColor7: '#636363',
  textColor8: '#000000',
  textColor9: '#8c8c8c',
  textColor10: '#878787',
  textColor11: '#e8e8e8',

  textColorContrast: '#ffffff',
  textColorContrast2: '#ffffffab',

  separationColor: '#bbbbbb',

  deleteTestExercise: '#fb6045',

  shadowColor: '#ccc',

  selectBackground: '#efefef',
  selectBackgroundHover: '#e1e1e1',

  cardBackground: '#ededed',
  cardBorder: '#d9d9d9',
  cardBackground2: '#e0e0e0',
  cardBackgroundHover2: '#cccccc',
  cardBorder2: '#cbcbcb',
  cardBorderHover2: '#b6b6b6',
  cardBackground3: '#f5f5f5',
  cardBorder3: '#e1e1e1',
  cardHeaderBackground3: '#efefef',
  cardBackground4: '#f5f5f5',
  cardBackground5: '#d0d0d0',
  cardBackgroundHover5: '#b7b7b7',

  buttonBackground: '#d3d3d3',
  buttonBackgroundHover: '#b7b7b7',
  buttonBackground2: '#419bb4',
  buttonBackgroundHover2: '#347a8e',
  buttonBackground3: '#ececec',
  buttonBackgroundHover3: '#cccccc',
  buttonBorder3: '#d1d1d1',
  buttonBorderHover3: '#b7b7b7',
  buttonBorder4: '#e1e1e1',

  buttonBackgroundBlack: '#383838',
  buttonBackgroundHoverBlack: '#161616',
  buttonBackgroundRed: '#b12b2b',
  buttonBackgroundHoverRed: '#8d1717',
  buttonBackgroundGrey: '#f0f0f0',
  buttonBackgroundHoverGrey: '#cccccc',

  paginationBackground: '#efefef',
  paginationBorder: '#e1e1e1',

  modalCloseColor: '#9c9c9c',
  modalCloseBackgroundHover: '#d5d5d5',

  graderBackground: '#cdcdcd',
  graderBackground2: '#fdfdfd',
  graderBackgroundHover: '#adadad',

  selectedColor: '#3dc766',
  selectedHover: '#b1b1b1',
  unselectedColor2: '#929292',
  unselectedHover2: '#757575',
  selectedColor2: '#32e875',
  selectedHover2: '#26c962',

  removeColor: '#fb6045',

  listItemSelected: '#e1e1e1',
  listItemHover: '#ebebeb',

  tableBorder: '#dfe1e6',

  shade0: '#e0e0e0',
  shade1: '#ebebeb',
  shade2: '#f5f5f5',
  shade3: '#ffffff',
  shade4: '#ffffff',
  shade5: '#ffffff',

  successColor: '#019a39',
  errorColor: '#ce1f01',
  warningColor: '#c56e01',

  sidebarColor: '#FCFCFC',
  sidebarText: '#565454',
  subjectColor: '#CB6EB7',
  yearColor: '#F79649',
  exerciseColor: '#20A078',
  exerciseColorTransparency: '#00B0843B',
  testColor: '#6689A1',
  testColorTransparency: '#8BD0FF3B',
  testColorHover: '#295573',
};

const dark = {
  isDark: true,

  ...light,
};

const general = {
  font: '"Open Sans", sans-serif',
  logoFont: 'Varela Round',

  classesColor: '#32e875',
  threadsColor: '#f18805',
  testsColor: '#34c6ff',
  adminColor: '#ffff1f',

  defaultLabelColor: '#bebebe',
  defaultLabelHoverColor: '#999999',

  logoColor: '#34ec74',

  premiumColor: '#ffb229',

  white: '#ffffff',

  xs: 575,
  sm: 767,
  md: 991,
  lg: 1199,
  xl: 1399,

  midnightGreen: '#104F55',
  green: '#7DD889',
  mint: '#E6EFE9',
  airBlue: '#769FB6',
  grey: '#999999',
  orangeSoda: '#F06543',
};

const theme = {
  light,
  dark,
  general,
};

export default theme;
