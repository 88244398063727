import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import Card from 'components/common/Card';
import PreviewableRubric from 'components/rubrics/PreviewableRubric';

const PreviewRubric = ({ rubric }) => (
  <Card
    header={lang.preview}
    width="100%"
  >
    <PreviewableRubric
      rubric={rubric}
    />
  </Card>
);

PreviewRubric.propTypes = {
  rubric: PropTypes.object,
};

export default PreviewRubric;
