import { createUseStyles } from 'react-jss';

export default createUseStyles({
  exerciseItem: {
    marginTop: '25px',
  },
  sectionDescription: {
    '& > p': {
      margin: '0',
    },
  },
});
