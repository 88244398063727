import React, { useMemo, useState } from 'react';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faCopy, faUser, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useTheme } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useXarrow } from 'react-xarrows';

import { increaseUsages, selectGroups } from 'actions/exploreActions';
import { incrementTotalExercises, selectUserFeatureToggleMaxUsages, selectUserTotalFreeExercises } from 'actions/userActions';
import api from 'api';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { exerciseTypeString } from 'utils';

import Button from 'components/common/Button';
import Card from 'components/common/Card';
import FilterPoolItem from 'components/common/filter-selector/FilterPoolItem';
import Input from 'components/common/Input';
import UpgradePremium from 'components/common/plans/UpgradePremium';
import PreviewableExercise from 'components/exercises/PreviewableExercise';

import useStyles from './styles';

const ExploreExercisePreviewModal = ({ open, close, exercise, copy, openCopy, dataTour }) => {
  const classes = useStyles();
  const groups = useSelector(selectGroups);
  const toast = useToast();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [copyPublicTemplateExerciseRequest] = useApi(api.copyPublicTemplateExercise);
  const updateXarrow = useXarrow();
  const totalFreeExercises = useSelector(selectUserTotalFreeExercises);
  const createExerciseToggle = useFeature(toggles.createExercise, totalFreeExercises);
  const { spaceId } = useParams();
  const createExerciseToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createExercise));

  const { templateExerciseId, title, exerciseType, usages, subjects, years, exerciseAuthorName } = exercise;

  const groupOptions = useMemo(() => groups.map(group => ({
    label: group.isPersonal ? lang.appKeywords.personalGroup : group.name,
    value: group.id,
  })), [groups]);

  const [selectedGroup, setSelectedGroup] = useState(groupOptions[0]);
  const [loading, setLoading] = useState(false);

  const copyTemplateExercice = () => {
    setLoading(true);
    copyPublicTemplateExerciseRequest([templateExerciseId], { groupId: selectedGroup.value }, ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          if (spaceId === PERSONAL_SPACE) {
            dispatch(incrementTotalExercises(1));
          }
          toast.success(lang.explore.successCopyExercise);
          setLoading(false);
          close(true);
        }, 350);
        dispatch(increaseUsages(templateExerciseId));
        return;
      }

      setLoading(false);
      toast.error(lang.oops);
      close();
    });
  };

  const getPills = (type, items) => {
    let color;

    if (!items) {
      return null;
    }
    if (type === 'subject') {
      color = '#CB6EB7';
    } else {
      color = '#F79649';
    }

    return items.map(item => (
      <FilterPoolItem key={item} poolItem={{ name: item, color }} selectable />
    ));
  };

  const colors = {
    textColor: theme.white,
    headerColor: theme.exerciseColor,
    bodyColor: theme.exerciseColorTransparency,
  };

  return (
    <Render when={open}>
      <motion.div
        animate={{ backgroundColor: '#00000033' }}
        className={classes.overlay}
        onClick={() => close(true)}
      >
        <motion.div
          layoutId={exercise.id}
          onClick={(event) => event.stopPropagation()}
          onLayoutAnimationComplete={updateXarrow}
        >
          <Card dataTour={dataTour} className={classes.exerciseCard}>
            <Button
              dataTour={`${dataTour}-copy-button`}
              className={classes.useButton}
              onClick={copy.state ? () => close(false) : openCopy}
            >
              <FontAwesomeIcon icon={copy.state ? faArrowLeft : faCopy} className={classes.icon} />
              {
                !copy.state && lang.copy
              }
            </Button>
            <div
              className={classes.closeButton}
              onClick={close}
              role="button"
              aria-label="close-preview"
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                className={classes.closeButtonIcon}
              />
            </div>
            <Render when={createExerciseToggle}>
              <div className={classes.exerciseContent}>
                <motion.div className={classes.exerciseTitle}>
                  {title}
                </motion.div>
                <div className={classes.exerciseContentInfo}>
                  <motion.div className={classes.exerciseType}>
                    <span
                      className={classes.exerciseTypeText}
                      title={exerciseTypeString(exerciseType)}
                    >
                      {lang.appKeywords.exercise}
                      <span> &#8226; </span>
                      {exerciseTypeString(exerciseType)}
                    </span>
                  </motion.div>
                  <div className={classes.exerciseAuthor}>
                    <FontAwesomeIcon
                      icon={faUser}
                      className={classes.exerciseUsagesIcon}
                    />
                    {exerciseAuthorName}
                  </div>
                  <motion.div className={classes.exerciseUsages}>
                    <FontAwesomeIcon
                      icon={faUserGroup}
                      className={classes.exerciseUsagesIcon}
                    />
                    {usages}
                    {' '}
                    {lang.copies}
                  </motion.div>
                </div>
                <div className={classes.filters}>
                  {getPills('subject', subjects)}
                  {getPills('year', years)}
                </div>
                <Render when={!copy.state}>
                  <PreviewableExercise
                    {...exercise}
                    type={exerciseType}
                    colors={colors}
                    header={lang.appKeywords.exercise}
                  />
                </Render>
                <Render when={copy.state}>
                  <div data-tour={`${dataTour}-select-group-container`} className={classes.copyContainer}>
                    <div className={classes.copyInformation}>
                      {lang.explore.copyInformation}
                    </div>
                    <Input
                      type="select"
                      options={groupOptions}
                      value={selectedGroup}
                      onChange={(value) => setSelectedGroup(value)}
                    />
                  </div>
                </Render>
              </div>
              <Render when={copy.state}>
                <div className={classes.copyActions}>
                  <Button
                    className={classes.copyAction}
                    onClick={() => close()}
                    black
                  >
                    {lang.cancel}
                  </Button>
                  <Button
                    dataTour={`${dataTour}-select-group-submit`}
                    className={classes.copyAction}
                    onClick={copyTemplateExercice}
                    loading={loading}
                  >
                    {lang.confirm}
                  </Button>
                </div>
              </Render>
            </Render>
            <Render when={!createExerciseToggle}>
              <UpgradePremium
                message={lang.plans.exceededExercises(createExerciseToggleMaxUsages)}
              />
            </Render>
          </Card>
        </motion.div>
      </motion.div>
    </Render>
  );
};

ExploreExercisePreviewModal.propTypes = {
  open: PropTypes.object,
  close: PropTypes.func,
  exercise: PropTypes.object,
  copy: PropTypes.bool,
  openCopy: PropTypes.func,
  dataTour: PropTypes.string,
};

export default ExploreExercisePreviewModal;
