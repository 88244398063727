import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  extraOptionsContainer: {
    display: 'flex',
    padding: '15px 13px',
    gap: '1em',
    justifyContent: 'center',
  },
  header: {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    marginTop: '15px',
    height: '35px',
  },
  optionCard: {
    width: '267px !important',
    height: '149px',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '5px',
    '&:hover': {
      backgroundColor: theme.backgroundColor2,
    },
    '&.selected': {
      backgroundColor: theme.backgroundColor2,
      border: '3px solid #20CA7E',
    },
  },
  example: {
    display: 'flex',
    marginBottom: '14px',
    fontStyle: 'italic',
    fontSize: '12px',
    alignItems: 'center',
    gap: '0.5em',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '14px',
  },
  gap: {
    backgroundColor: 'white',
    border: `1px solid ${theme.buttonBorder3}`,
    borderRadius: '4px',
    width: '63px',
    height: '23px',
    display: 'flex',
    alignItems: 'center',
    color: `${theme.textColor}aa`,
  },
  draggableGap: {
    '& > div': {
      width: '63px',
      height: '23px',
      padding: '0 !important',
      minHeight: 'unset',
      cursor: 'pointer !important',
      color: theme.textColor4,
      fontSize: '11px',
    },
    marginLeft: '0.25em',
  },
  correction: {
    fontSize: '12px',
    fontWeight: '600',
    textAlign: 'center',
    marginTop: '15px',
  },
  toggleWrapper: {
    display: 'flex',
    padding: '0 0 15px 13px',
    gap: '0.5em',
    alignItems: 'center',
  },

  [`@media (max-width: ${theme.xs}px)`]: {
    trueFalseContainer: {
      flexWrap: 'wrap',
      gap: '0.5em',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    trueFalseContainer: {
      justifyContent: 'space-around',
    },
  },

}));
