import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import PropTypes from 'prop-types';

import InnerCaption from '../InnerCaption';

const Caption = ({ removeCaption, editCaption, gap, styles, restricted }) => {
  const draggable = useDraggable({
    id: gap.id,
    data: {
      supports: 'caption',
    },
  });

  const { attributes, listeners, setNodeRef, transform, isDragging } = restricted
    ? { attributes: {}, listeners: {}, setNodeRef: null, transform: null, isDragging: false }
    : draggable;

  const style = {
    transform: isDragging ? null : transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    cursor: transform ? 'grabbing' : 'pointer',
    boxShadow: isDragging ? '2px 5px 10px #00000066' : undefined,
    opacity: isDragging ? '0%' : '100%',
    touchAction: 'none',
    zIndex: '1',
    ...styles,
  };

  return (
    <InnerCaption
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      gap={gap}
      editCaption={editCaption}
      removeCaption={removeCaption}
      restricted={restricted}
    />
  );
};

Caption.propTypes = {
  removeCaption: PropTypes.func,
  editCaption: PropTypes.func,
  gap: PropTypes.object,
  styles: PropTypes.object,
  restricted: PropTypes.bool,
};

export default Caption;
