import React, { useCallback, useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectAttemptId } from 'actions/studentAttemptActions';
import { selectUserIsTeacher } from 'actions/userActions';
import { MAX_SIZE_1GB } from 'constants/fileMaxSizes';
import lang from 'lang';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';

const AudioModal = ({ open, close, insert }) => {
  const toast = useToast();
  const isTeacher = useSelector(selectUserIsTeacher);
  const attemptId = useSelector(selectAttemptId);

  const [files, setFiles] = useState([]);
  const [canPause, setCanPause] = useState(true);
  const [limitRepetitions, setLimitRepetitions] = useState(false);
  const [maxRepetitions, setMaxRepetitions] = useState(3);

  const onDrop = useCallback((filesToUpload) => {
    const fileToUpload = filesToUpload[0];

    if (!fileToUpload) {
      toast.error(lang.exerciseForm.audio.askForAudioError);
      return;
    }

    if (fileToUpload.size > MAX_SIZE_1GB) {
      toast.warning(lang.fileTooLarge1Gb);
      return;
    }

    setFiles([fileToUpload]);
  }, [toast, setFiles]);

  const onAdd = useCallback(() => {
    if (files && files.length !== 0) {
      insert(files[0], { canPause, limitRepetitions, maxRepetitions });
      setFiles([]);
      close();
      return;
    }

    toast.warning(lang.exerciseForm.audio.noAudioChosen);
  }, [files, close, insert, toast, canPause, limitRepetitions, maxRepetitions]);

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
    {
      name: lang.add,
      onClick: onAdd,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.exerciseForm.audio.audioHeader}
      actions={actions}
      center
      transition
      large
    >
      <Input
        type="file"
        placeholder={lang.exerciseForm.audio.addAudioLabel}
        value={files}
        accept="audio/*"
        onDrop={onDrop}
      />
      <Spacer px={20} />
      <Render when={isTeacher && !attemptId}>
        <Input
          type="checkbox"
          label={lang.exerciseForm.audio.canPauseLabel}
          value={canPause}
          onChange={(event) => setCanPause(event.target.checked)}
        />
        <Input
          type="checkbox"
          label={lang.exerciseForm.audio.limitRepetitionsLabel}
          value={limitRepetitions}
          onChange={(event) => setLimitRepetitions(event.target.checked)}
        />
        <Render when={limitRepetitions}>
          <Spacer px={20} />
          <Input
            type="number"
            label={lang.exerciseForm.audio.maxRepetitionsLabel}
            placeholder={lang.exerciseForm.audio.maxRepetitionsLabel}
            value={maxRepetitions}
            onChange={(event) => setMaxRepetitions(event.target.value)}
          />
        </Render>
        <Spacer px={20} />
      </Render>
    </Modal>
  );
};

AudioModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  insert: PropTypes.func,
};

export default AudioModal;
