import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  premiumPill: {
    borderRadius: '9px',
    padding: '0.5em 1.5em',
    fontSize: '12px',
    fontWeight: 600,
    backgroundColor: theme.midnightGreen,
    color: theme.green,
    width: 'fit-content',
    '&.isFree': {
      backgroundColor: 'grey',
      color: 'white',
    },
    '&.getPremium': {
      backgroundColor: theme.backgroundColor,
      border: '2px solid ' + theme.midnightGreen,
      color: theme.midnightGreen,
    },
    '&.isSchool': {
      color: theme.midnightGreen,
      backgroundColor: theme.green,
    },
  },
}));
