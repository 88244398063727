import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useFeature from 'hooks/useFeature';
import toggles from 'toggles';

import QuillRenderer from 'components/common/QuillRenderer';

import useStyles from './styles';

const ExerciseOrderItem = ({ name, label, identifier, disabled }) => {
  const classes = useStyles();
  const contentIsQuill = typeof label === 'object';
  const exportIdentifiersToggle = useFeature(toggles.exportIdentifiers);

  let hasImage = false;
  if (contentIsQuill && label.ops) {
    if (label.ops.some(op => op.insert.image)) {
      hasImage = true;
    }
  }

  const getLabel = () => {
    if (contentIsQuill) {
      return (
        <>
          <QuillRenderer
            className={cx(classes.labelContent, { hasImage, disabled })}
            value={label}
            htmlFor={name}
          />
          <Render when={exportIdentifiersToggle && identifier}>
            <div className={classes.identifier}>
              {`(${identifier})`}
            </div>
          </Render>
        </>
      );
    } else {
      return (
        <label className={cx(classes.labelContent, { disabled })} htmlFor={name}>
          {label}
        </label>
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={cx(classes.labelWrap, { hasImage })}>
        {getLabel()}
      </div>
    </div>
  );
};

ExerciseOrderItem.propTypes = {
  name: PropTypes.string,
  identifier: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
};

export default ExerciseOrderItem;
