import { createUseStyles } from 'react-jss';

export default createUseStyles({
  sectionCard: {
    marginTop: '20px',
  },
  sectionContent: {
    padding: '0 20px 20px 20px',
  },
  noExercisesSection: {
    marginTop: '20px',
  },
});
