import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  pageContainer: {
    minHeight: '100vh',
    display: 'flex',
  },
  langSelectorContainer: {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    backgroundColor: theme.midnightGreen,
    width: '50%',
    '&.iave': {
      backgroundColor: '#0082D7',
    },
  },
  publicationContainer: {
    width: '100%',
    margin: '20px 20%',
    padding: '30px',
    boxShadow: `0 0 5px ${theme.shadowColor}`,
    backgroundColor: theme.backgroundColor,
    borderRadius: '18px',
  },
  iaveLogosContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: '10px',
    width: '50%',
    position: 'absolute',
    bottom: '20px',
  },
  iaveLogo: {
    width: '20%',
  },
  iaveLogoWhite: {
    width: '100%',
  },
  prrLogo: {
    width: '60%',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    pageContainer: {
      flexDirection: 'column',
      backgroundColor: theme.midnightGreen,
      '&.iave': {
        backgroundColor: '#0082D7',
      },
    },
    logoContainer: {
      alignItems: 'start',
      width: '100%',
      paddingTop: '7vh',
    },
    contentContainer: {
      width: '100%',
      flexWrap: 'wrap',
    },
    formContainer: {
      width: '100%',
      marginTop: '3vh',
    },
    publicationContainer: {
      margin: '5%',
    },
    iaveLogosContainer: {
      position: 'unset',
    },
  },
}));
