import lang from 'lang';
import { buildStep, buildOptions } from 'services/shepherd/utils';

export const options = buildOptions('introduction');

export const steps = (testId, statisticsToggle, isFirstTime) => [
  ...(isFirstTime
    ? [
      {
        id: 'first-time-introduction-modal',
        modal: true,
        text: lang.tours.introduction.steps.introduction,
        buttons: ['next'],
      },
    ]
    : []
  ),
  {
    id: `test-card-${testId}`,
    text: lang.tours.introduction.steps.testCard,
    attachOn: 'bottom',
    buttons: ['next'],
  },
  {
    id: `test-card-${testId}-publish-button`,
    text: lang.tours.introduction.steps.testCardPublishButton,
    attachOn: 'right',
    buttons: ['back', 'next'],
  },
  {
    id: `test-card-${testId}-left`,
    text: lang.tours.introduction.steps.testCardLeft,
    attachOn: 'bottom',
    advanceOnClick: true,
    buttons: ['back'],
  },
  {
    id: 'assessment-tab-exercises',
    text: lang.tours.introduction.steps.tabExercises,
    attachOn: 'bottom',
    buttons: ['next'],
  },
  {
    id: 'assessment-tab-grades',
    text: lang.tours.introduction.steps.tabGrade,
    attachOn: 'bottom',
    advanceOnClick: true,
    buttons: ['back'],
  },
  ...(statisticsToggle
    ? [
      {
        id: 'assessment-tab-grades-statistics',
        text: lang.tours.introduction.steps.statistics,
        attachOn: 'bottom',
        buttons: ['next'],
      },
    ]
    : []
  ),
  {
    id: 'assessment-tab-grades-student-attempts',
    text: lang.tours.introduction.steps.attempts,
    attachOn: 'right',
    buttons: [
      ...(statisticsToggle ? ['back'] : []),
      'next',
    ],
  },
  {
    id: 'gradeable-attempt-container',
    text: lang.tours.introduction.steps.attemptContainer,
    attachOn: 'top',
    buttons: ['back', 'next'],
  },
  {
    id: 'student-attempt-header',
    text: lang.tours.introduction.steps.attemptHeader,
    attachOn: 'top',
    buttons: ['back', 'next'],
  },
  // Multiple Choice
  {
    id: 'gradeable-attempt-0',
    text: lang.tours.introduction.steps.multipleChoice,
    attachOn: 'top',
    buttons: ['back', 'next'],
  },
  {
    id: 'gradeable-attempt-0-grader',
    text: lang.tours.introduction.steps.multipleChoiceGrader,
    attachOn: 'left',
    buttons: ['back', 'next'],
  },
  // Open question
  {
    id: 'gradeable-attempt-1-grader',
    text: lang.tours.introduction.steps.openQuestionGrader,
    attachOn: 'left',
    buttons: ['back', 'next'],
  },
  {
    id: 'gradeable-attempt-1-feedback-button',
    text: lang.tours.introduction.steps.openQuestionFeedback,
    attachOn: 'top',
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-tab-grades-publish-grades-button',
    text: lang.tours.introduction.steps.publishGradesButton,
    attachOn: 'top',
    buttons: ['back', 'next'],
  },
  // stats tab
  ...(statisticsToggle
    ? [
      {
        id: 'assessment-tab-statistics',
        text: lang.tours.introduction.steps.tabStatistics,
        attachOn: 'bottom',
        buttons: ['back', 'next'],
      },
    ]
    : []
  ),
  // final steps
  {
    id: 'sidebar-assessments',
    text: lang.tours.introduction.steps.assessmentSidebarOption,
    attachOn: 'right',
    advanceOnClick: true,
  },
  {
    id: `extra-actions-dropdown-test-card-${testId}`,
    text: lang.tours.introduction.steps.testCardDropdown,
    attachOn: 'left',
    buttons: ['next'],
  },
  {
    id: 'tour-hub',
    text: lang.tours.introduction.steps.hub,
    attachOn: 'top-left',
    buttons: ['back', 'complete'],
  },
].map(buildStep);
