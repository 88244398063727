import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  bankWrap: {
    padding: '15px 30px',
    '&.wrapped': {
      padding: '20px 0',
    },
  },
  exercisesContainer: {
    '&.wrapped': {
      padding: '0 35px',
    },
  },
  exerciseActionsWrapper: {
    paddingBottom: '10px',
  },
  pagination: {
    '& .otl-paginationPageItem': {
      width: '37px',
      height: '37px',
    },
    '& .otl-paginationPageItem.selected': {
      width: '35px !important',
      height: '35px !important',
      backgroundColor: theme.paginationBackground,
      borderRadius: '0',
      border: `1px solid ${theme.paginationBorder}`,
    },
    '& .otl-paginationPageItem:hover': {
      width: '37px',
      height: '37px',
      backgroundColor: theme.paginationBackground,
      borderRadius: '0',
    },
    '& .otl-paginationPageItem.disabled:hover': {
      backgroundColor: 'inherit',
      cursor: 'inherit',
    },
  },
  noExercises: {
    color: theme.textColor2,
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    exercisesContainer: {
      '&.wrapped': {
        padding: 'unset',
      },
    },
  },
}));
