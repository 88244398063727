import React, { useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { serverToLocalMillis } from 'utils/datetime';

import AttemptRubricModal from '../AttemptRubricModal';
import Button from 'components/common/Button';
import Countdown from 'components/common/countdown/Countdown';
import CountdownRenderer from 'components/common/countdown/CountdownRenderer';
import LangSelector from 'components/common/LangSelector';

import useStyles from './styles';

const Header = ({ attempt, timesUp, fullWidth }) => {
  const classes = useStyles();
  const clockOffset = useSelector(state => state.page.clockOffset);
  const isAuthenticated = useIsAuthenticated();
  const iaveToggle = useFeature(toggles.iave);

  const [attemptRubricModal, setAttemptRubricModal] = useState(false);

  const logout = () => {
    window.location = routes.authLogout.ref() + `?redirectUri=${routes.testsApp.ref()}${routes.attempt.ref(attempt.id)}`;
  };

  return (
    <div className={cx(classes.headerContainer, { fullWidth })}>
      <div className={classes.headerLeft}>
        <div
          className={classes.testName}
          title={attempt.testName}
        >
          {attempt.testName}
        </div>
        <span title={attempt.userName}>
          {attempt.userName}
        </span>
      </div>
      <div className={classes.headerRight}>
        <Render when={isAuthenticated && ((attempt.hasEnded && attempt.immediateFeedback) || attempt.gradesPublished)}>
          <Button onClick={logout}>
            {lang.logout}
          </Button>
        </Render>
        <Render when={!iaveToggle}>
          <div className={classes.langSelectorContainer}>
            <LangSelector />
          </div>
        </Render>
        <Render when={attempt.classificationType === 'rubric' && (attempt.showRubric || attempt.gradesPublished)}>
          <AttemptRubricModal
            open={attemptRubricModal}
            close={() => setAttemptRubricModal(false)}
            rubric={attempt.rubric}
          />
          <div
            className={classes.action}
            onClick={() => setAttemptRubricModal(true)}
          >
            <FontAwesomeIcon
              icon={faEye}
              className={classes.actionIcon}
            />
            {lang.appKeywords.rubric}
          </div>
        </Render>
        <Render when={!attempt.hasEnded && !iaveToggle}>
          <div className={classes.time}>
            <div className={classes.endsHint}>
              {lang.test.timeRemaining}
            </div>
            <Countdown
              date={serverToLocalMillis(attempt.endsAt, clockOffset)}
              renderer={CountdownRenderer}
              onComplete={timesUp}
            />
          </div>
        </Render>
      </div>
    </div>
  );
};

Header.propTypes = {
  attempt: PropTypes.object,
  timesUp: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export default Header;
