import React, { useCallback, useState } from 'react';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin, selectUserLang } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useForm from 'hooks/useForm';
import lang from 'lang';
import routes from 'routes';

import ExportAttemptsForm from '../ExportAttemptsForm';
import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const IDLE = 'IDLE';
const GENERATING = 'GENERATING';
const ZIPPING = 'ZIPPING';
const DOWNLOAD = 'DOWNLOAD';
const DEFAULT_PATH = null;
const DEFAULT_EXPORT_STATE = IDLE;
const DEFAULT_TOTAL_ATTEMPTS = 0;
const DEFAULT_ATTEMPTS_EXPORTED = 0;
const DEFAULT_SCHOOL_LOGO = { value: '', enabled: false };
const DEFAULT_INCLUDE_GRADE = false;
const DEFAULT_INCLUDE_RUBRIC = false;
const DEFAULT_INCLUDE_MODEL_ANSWER = false;
const DEFAULT_INCLUDE_ANNOTATIONS = false;

const ExportAttemptsModal = ({ open, close, publicationId, classificationType }) => {
  const classes = useStyles();
  const toast = useToast();
  const locale = useSelector(selectUserLang);
  const [exportStudentAttemptsRequest,, abortExportStudentAttempts] = useApi(api.exportStudentAttempts);
  const [getPublicationExportRequest,, abortGetPublicationExport] = useApi(api.getPublicationExport);
  const [zipPublicationExportRequest,, abortZipPublicationExport] = useApi(api.zipPublicationExport);
  const isAdmin = useSelector(selectUserIsAdmin);

  const [path, setPath] = useState(DEFAULT_PATH);
  const [exportState, setExportState] = useState(DEFAULT_EXPORT_STATE);
  const [totalAttempts, setTotalAttempts] = useState(DEFAULT_TOTAL_ATTEMPTS);
  const [attemptsExported, setAttemptsExported] = useState(DEFAULT_ATTEMPTS_EXPORTED);
  const [intervalId, setIntervalId] = useState(null);
  const [getValue, setValue, getErrors, setErrors] = useForm([
    { label: 'schoolLogo', initialValue: DEFAULT_SCHOOL_LOGO },
    { label: 'includeGrade', initialValue: DEFAULT_INCLUDE_GRADE },
    { label: 'includeRubric', initialValue: DEFAULT_INCLUDE_RUBRIC },
    { label: 'includeModelAnswer', initialValue: DEFAULT_INCLUDE_MODEL_ANSWER },
    { label: 'includeAnnotations', initialValue: DEFAULT_INCLUDE_ANNOTATIONS },
  ]);

  const checkPublicationExportStatus = useCallback((publicationExportId, intervalId) => {
    getPublicationExportRequest([publicationExportId], null, ({ data }) => {
      if (data.status === 0) {
        if (data.publicationExport.status === 'EXPORTED') {
          clearInterval(intervalId);
          setExportState(DOWNLOAD);
        }
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
        clearInterval(intervalId);
      }
    });
  }, [getPublicationExportRequest, toast]);

  const zipExport = useCallback((publicationExportId) => {
    zipPublicationExportRequest([publicationExportId], null, ({ data }) => {
      if (data.status === 0) {
        const intervalId = setInterval(() => checkPublicationExportStatus(publicationExportId, intervalId), 1000);
        setIntervalId(intervalId);
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [zipPublicationExportRequest, checkPublicationExportStatus, toast]);

  const checkAttemptExportsStatus = useCallback((publicationExportId, intervalId) => {
    getPublicationExportRequest([publicationExportId], null, ({ data }) => {
      if (data.status === 0) {
        setAttemptsExported(data.publicationExport.attemptsExported + data.publicationExport.attemptsWithError);
        if (data.publicationExport.attemptsNotExported === 0) {
          clearInterval(intervalId);
          zipExport(publicationExportId);
          setExportState(ZIPPING);
        }
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
        clearInterval(intervalId);
      }
    });
  }, [getPublicationExportRequest, toast, zipExport]);

  const exportAttempts = () => {
    let valid = true;

    const schoolLogoErrors = [];

    const schoolLogo = getValue('schoolLogo');
    const includeGrade = getValue('includeGrade');
    const includeRubric = getValue('includeRubric');
    const includeModelAnswer = getValue('includeModelAnswer');
    const includeAnnotations = getValue('includeAnnotations');

    if (!isAdmin){
      if (schoolLogo.enabled && schoolLogo.value.length === 0) {
        schoolLogoErrors.push(lang.test.attemptsExport.schoolLogoEmptyError);
        valid = false;
      }

      if (!valid) {
        setTimeout(() => {
          setErrors('schoolLogo', schoolLogoErrors);
        }, 350);
        return;
      }

      setErrors('schoolLogo', []);
    }
    exportStudentAttemptsRequest([publicationId], { locale, includeGrade, schoolLogo, includeRubric, includeModelAnswer, includeAnnotations }, ({ data }) => {
      if (data.status === 0) {
        setExportState(GENERATING);
        setPath(data.publicationExportPath);
        setTotalAttempts(data.totalAttempts);
        const intervalId = setInterval(() => checkAttemptExportsStatus(data.publicationExportId, intervalId), 1000);
        setIntervalId(intervalId);
        return;
      } else if (data.status === 4) {
        toast.warning(lang.test.attemptsExport.exportAttemptsNotAvailable);
        _close();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  };

  const _close = () => {
    setTimeout(() => {
      setExportState(DEFAULT_EXPORT_STATE);
      setPath(DEFAULT_PATH);
      setTotalAttempts(DEFAULT_TOTAL_ATTEMPTS);
      setAttemptsExported(DEFAULT_ATTEMPTS_EXPORTED);
      setValue('schoolLogo', DEFAULT_SCHOOL_LOGO);
      setValue('includeGrade', DEFAULT_INCLUDE_GRADE);
      setValue('includeRubric', DEFAULT_INCLUDE_RUBRIC);
      setValue('includeModelAnswer', DEFAULT_INCLUDE_MODEL_ANSWER);
      setValue('includeAnnotations', DEFAULT_INCLUDE_ANNOTATIONS);
    }, 300);
    close();
    clearInterval(intervalId);
    abortExportStudentAttempts();
    abortGetPublicationExport();
    abortZipPublicationExport();
  };

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: _close,
    },
    ...(exportState === IDLE ? [
      {
        name: lang.export,
        onClick: exportAttempts,
      },
    ] : []),
  ];

  return (
    <Modal
      open={open}
      close={_close}
      header={lang.test.attemptsExport.exportAttemptsPDF}
      actions={actions}
      center
      transition
      small
    >
      {exportState === IDLE && !isAdmin &&
        <ExportAttemptsForm
          getValue={getValue}
          setValue={setValue}
          getErrors={getErrors}
          classificationType={classificationType}
          isAdmin={isAdmin}
        />
      }
      {exportState === IDLE && isAdmin &&
      <div>
        {lang.test.attemptsExport.downloadValidatedExplanation}
      </div>
      }
      {exportState === GENERATING &&
      <div className={classes.downloadContainer}>
        <div>
          {lang.test.attemptsExport.generatingPDFs}
        </div>
        <progress
          value={attemptsExported}
          max={totalAttempts}
          style={{ width: '400px' }}
        />
      </div>
      }
      {exportState === ZIPPING &&
        <div className={classes.downloadContainer}>
          <div>
            {lang.test.attemptsExport.zippingPDFs}
          </div>
          <Loading active />
        </div>
      }
      {exportState === DOWNLOAD &&
        <div className={classes.downloadContainer}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={classes.exportSuccessIcon}
          />
          <div>
            {lang.test.attemptsExport.generateSuccessDescription}
          </div>
          <Button
            onClick={() => window.open(routes.s3.ref(path), '_blank')}
            className={classes.downloadButton}
          >
            {lang.download}
          </Button>
        </div>
      }
    </Modal>
  );
};

ExportAttemptsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  publicationId: PropTypes.string,
  classificationType: PropTypes.string,
};

export default ExportAttemptsModal;
