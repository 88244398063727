
import React from 'react';
import { Table, ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import { useTheme } from 'react-jss';

import lang from 'lang';

import { calculateGradePercentage } from '../../../../../utils/statistics';

import useStyles from './styles';

const handleRowClick = (record) => {
  const cardElement = document.getElementById(`card-${record.key}`);
  if (cardElement) {
    cardElement.scrollIntoView({ behavior: 'smooth' });
  }
};

export const ExercisesTable = ({ questions, tableRef }) => {
  const classes = useStyles();
  const theme = useTheme();

  const columns = [
    {
      title: lang.test.stats.question,
      dataIndex: 'question',
    },
    {
      title: lang.test.stats.classAverage,
      dataIndex: 'averageGrade',
      sorter: {
        compare: (a, b) => parseFloat(a.averageGrade) - parseFloat(b.averageGrade),
      },
    },
    {
      title: lang.test.stats.classMedian,
      dataIndex: 'medianGrade',
      sorter: {
        compare: (a, b) => parseFloat(a.medianGrade) - parseFloat(b.medianGrade),
      },
    },
  ];

  const data = questions.map(question => ({
    key: question.testExerciseId,
    question: `Item ${question.name}`,
    averageGrade: `${question.normalizedGradeGraph}% (${question.averageGradeGraph} / ${question.maxGrade})`,
    medianGrade: `${calculateGradePercentage(question.medianGrade, question.maxGrade)}% (${question.medianGrade} / ${question.maxGrade})`,
  }));

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.midnightGreen,
          colorBgContainer: 'transparent',
        },
      }}
    >
      <Table
        ref={tableRef}
        className={classes.statsTable}
        columns={columns}
        dataSource={data}
        pagination={false}
        locale={{
          triggerAsc: lang.test.stats.ascSort,
          triggerDesc: lang.test.stats.descSort,
          cancelSort: lang.test.stats.cancelSort,
        }}
        onRow={(record) => ({
          onClick: () =>
            handleRowClick(record),
        })}
      />
    </ConfigProvider>
  );
};

ExercisesTable.propTypes = {
  questions: PropTypes.array,
  tableRef: PropTypes.object,
};

export default ExercisesTable;
