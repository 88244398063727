import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import { getAnswer } from 'utils/attempts';

import { useAttemptContext } from '../../grades-tab/CorrectionTab/context';
import ReviewableExercise from 'components/attempt/ReviewableExercise';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const PreviewTestAnswerModal = ({ open, close, refresh, answerId, attemptId }) => {
  const classes = useStyles();
  const toast = useToast();
  const [getAttemptAnswerRequest] = useApi(api.getAttemptAnswer);
  const [exerciseAttempt, setExerciseAttempt] = useState(null);
  const { commenting } = useAttemptContext();

  const _close = useCallback(() => {
    close();
    setTimeout(() => {
      setExerciseAttempt(null);
    }, 350);
  }, [close]);

  useEffect(() => {
    if (open === true && exerciseAttempt === null) {
      getAttemptAnswerRequest([attemptId, answerId], null, ({ data }) => {
        if (data.status === 0) {
          setExerciseAttempt(data.answer);
        } else {
          toast.error(lang.oops, 'error');
          _close();
        }
      });
    }
  }, [open, _close, refresh, attemptId, toast, getAttemptAnswerRequest, exerciseAttempt, answerId]);

  const actions = [
    {
      name: lang.close,
      color: 'black',
      onClick: () => _close(),
    },
  ];

  const getContent = () => {
    if (exerciseAttempt === null) {
      return (
        <Loading active />
      );
    }

    exerciseAttempt.answer = getAnswer(exerciseAttempt);
    if (exerciseAttempt.hasJustification) {
      exerciseAttempt.justification = exerciseAttempt.valueText ?? exerciseAttempt.quillAnswer;
    }

    return (
      <Row>
        <Col xl={commenting ? 9 : 12}>
          <ReviewableExercise
            exercise={exerciseAttempt}
          />
        </Col>
      </Row>
    );
  };

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className={classes.modalContainer}
    >
      <Modal
        open={open}
        close={_close}
        actions={actions}
        header={lang.test.stats.previewAnswer}
        center
        transition
        large
      >
        {getContent()}
      </Modal>
    </div>

  );
};

PreviewTestAnswerModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  refresh: PropTypes.func,
  answerId: PropTypes.string,
  attemptId: PropTypes.string,
};

export default PreviewTestAnswerModal;
