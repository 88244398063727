import React, { useState, useEffect, useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';

import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import routes from 'routes';

import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';
import PreviewableExercise from 'components/exercises/PreviewableExercise';

import useStyles from './styles';

const PreviewExerciseModal = ({ open, close, templateExerciseId, refresh }) => {
  const toast = useToast();
  const history = useHistory();
  const classes = useStyles();
  const { spaceId } = useParams();
  const [getTemplateExerciseRequest] = useApi(api.getTemplateExercise);

  const [exercise, setExercise] = useState(null);

  const _close = useCallback(() => {
    close();
    setTimeout(() => {
      setExercise(null);
    }, 350);
  }, [close]);

  useEffect(() => {
    if (open === true) {
      if (exercise === null) {
        getTemplateExerciseRequest([templateExerciseId], null, ({ data }) => {
          if (data.status === 0) {
            setExercise(data.exercise);
          } else if (data.status === 2 || data.status === 3) {
            toast.error(lang.exercises.previewErrorDoesNotExist);
            _close();
            setTimeout(() => {
              refresh();
            }, 350);
          } else {
            toast.error(lang.common.internetWentWrong);
            _close();
          }
        });
      }
    }
  }, [getTemplateExerciseRequest, templateExerciseId, exercise, open, _close, refresh, toast]);

  const getActions = () => {
    if (templateExerciseId) {
      return [
        {
          name: lang.edit,
          onClick: () => history.push(routes.editExercise.ref(spaceId, templateExerciseId)),
        },
        {
          name: lang.close,
          color: 'black',
          onClick: () => _close(),
        },
      ];
    } else {
      return [
        {
          name: lang.close,
          color: 'black',
          onClick: () => _close(),
        },
      ];
    }
  };

  const getContent = () => {
    if (exercise === null) {
      return (
        <Loading active />
      );
    }

    return (
      <PreviewableExercise
        id={templateExerciseId}
        type={exercise.type}
        statement={exercise.statement}
        choices={exercise.choices}
        gaps={exercise.gaps}
        orderItems={exercise.orderItems}
        correctTrueFalse={exercise.correctTrueFalse}
        hasJustification={exercise.hasJustification}
        image={exercise.image}
        isShortAnswer={exercise.isShortAnswer}
        option={exercise.option}
        extraText={exercise.extraText}
        extraTextStartExpanded={exercise.extraTextStartExpanded}
        connectors={exercise.connectors}
        connections={exercise.connections}
        enableMathSymbols={exercise.enableMathSymbols}
        modelAnswer={exercise.modelAnswer}
        exerciseCells={exercise.exerciseCells}
        gradingOption={exercise.gradingOption}
        noCard
        hasWordCount={exercise.hasWordCount}
      />
    );
  };

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className={classes.modalContainer}
    >
      <Modal
        open={open}
        close={_close}
        header={lang.exercises.previewExercise}
        actions={getActions()}
        center
        transition
        large
      >
        {getContent()}
      </Modal>
    </div>
  );
};

PreviewExerciseModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  templateExerciseId: PropTypes.string,
  refresh: PropTypes.func,
};

export default PreviewExerciseModal;
