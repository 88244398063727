import React from 'react';
import { Row, Col } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import lang from 'lang';

import ScoreNumber from '../ScoreNumber';

import useStyles from './styles';

export const TestTimesRow = ({ testTimes }) => {
  const classes = useStyles();
  const getTime = (statClass, time) => {

    return (
      <Col
        className={classes.durationCol}
      >
        <ScoreNumber
          statClass={statClass}
        >
          <div className={classes.duration}>
            {time}
          </div>
        </ScoreNumber>
      </Col>
    );
  };

  return (
    testTimes.testEnded ?
      <Row className={classes.durationsRow}>
        {getTime(lang.test.stats.averageTime, testTimes.averageTime)}
        {getTime(lang.test.stats.fastestTime, testTimes.fastestTime)}
        {getTime(lang.test.stats.slowestTime, testTimes.slowestTime)}
      </Row>
      :
      <Row className={classes.durationsRow}>
        {getTime(lang.test.stats.averageTime, lang.test.stats.inProgress)}
        {getTime(lang.test.stats.fastestTime, lang.test.stats.inProgress)}
        {getTime(lang.test.stats.slowestTime, lang.test.stats.inProgress)}
      </Row>
  );
};

TestTimesRow.propTypes = {
  testTimes: PropTypes.exact({
    fastestTime: PropTypes.number,
    slowestTime: PropTypes.number,
    averageTime: PropTypes.number,
    testEnded: PropTypes.bool,
  }),
};

export default TestTimesRow;
