import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import lang from 'lang';
import routes from 'routes';

import Button from 'components/common/Button';
import NoEntity from 'components/common/NoEntity';

import useStyles from './styles';

const FinishedAttempt = ({ sentence }) => {
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const { attemptId } = useParams();

  const logout = () => {
    window.location = routes.authLogout.ref() + `?redirectUri=${routes.testsApp.ref()}${routes.attempt.ref(attemptId)}`;
  };

  return (
    <div className={classes.content}>
      <NoEntity
        message={sentence}
      />
      <Render when={isAuthenticated}>
        <Button onClick={logout}>
          {lang.logout}
        </Button>
      </Render>
    </div>
  );
};

FinishedAttempt.propTypes = {
  sentence: PropTypes.string,
};

export default FinishedAttempt;
