import { faFileAlt, faPlusCircle, faStopwatch, faTable, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import lang from 'lang';

const getTestClassificationType = (classificationType) => {
  switch (classificationType) {
    case 'classic':
      return lang.appKeywords.classic;
    case 'rubric':
      return lang.appKeywords.rubric;
    case 'none':
      return lang.appKeywords.noClassification;
    default:
      return null;
  }
};

const getTestClassificationTypeIcon = (classificationType) => {
  switch (classificationType) {
    case 'classic':
      return faPlusCircle;
    case 'rubric':
      return faTable;
    case 'none':
      return faTimesCircle;
    default:
      return null;
  }
};

const getTestType = (type) => {
  switch (type) {
    case 'test':
      return lang.appKeywords.test;
    case 'worksheet':
      return lang.appKeywords.worksheet;
    default:
      return null;
  }
};

const getTestTypeIcon = (type) => {
  switch (type) {
    case 'test':
      return faStopwatch;
    case 'worksheet':
      return faFileAlt;
    default:
      return null;
  }
};

const updateExercisePoints = (test, itemId, points, sectionId) => {
  const newTest = { ...test };

  let item;
  if (sectionId) {
    const section = newTest.items
      .find((item) => item.id === sectionId && item.itemType === 'section');

    if (!section) {
      return;
    }

    item = section.exercises
      .find((exercise) => exercise.id === itemId);
  } else {
    item = newTest.items
      .find((item) => item.id === itemId && item.itemType === 'exercise');
  }

  item.points = points;

  return newTest;
};

export {
  getTestClassificationType,
  getTestClassificationTypeIcon,
  getTestType,
  getTestTypeIcon,
  updateExercisePoints,
};
