import { createUseStyles } from 'react-jss';

export default createUseStyles({
  timerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '20px 0',
  },
});
