import React, { useState, useEffect, useCallback, Fragment, useMemo } from 'react';
import { faPaperPlane, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import DeleteRubricsModal from '../DeleteRubricsModal';
import SendRubricModal from '../SendRubricModal';
import EntityActions from 'components/common/entity/EntityActions';
import Loading from 'components/common/Loading';
import NoEntity from 'components/common/NoEntity';
import RubricCard from 'components/rubrics/RubricCard';

import useStyles from './styles';

const RubricBank = ({ groupId, groups, selectable, selected, wrapped, setSelected, userFilters }) => {
  const classes = useStyles();
  const [getGroupTemplateRubricsRequest] = useApi(api.getGroupTemplateRubrics);
  const userId = useSelector(state => state.user.data.id);

  const [rubrics, setRubrics] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const containsOtherAuthors = useMemo(() => {
    return selected?.filter(rubric => rubric.authorId !== userId).length > 0;
  }, [selected, userId]);

  const deleteDisabled = useMemo(() => {
    const isPersonalGroup = groups.find(group => group.id === groupId && group.isPersonal);
    return containsOtherAuthors && !isPersonalGroup;
  }, [containsOtherAuthors, groups, groupId]);

  const fetchRubrics = useCallback(() => {
    if (!groupId) {
      return;
    }
    const usersList = userFilters?.map(user => user.id);

    setLoading(true);
    getGroupTemplateRubricsRequest([groupId], { page, users: usersList }, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        setRubrics(data.rubrics);
        setPages(data.pages);
      }
    });
  }, [getGroupTemplateRubricsRequest, groupId, page, userFilters]);

  useEffect(() => {
    fetchRubrics();
  }, [fetchRubrics]);

  useEffect(() => {
    setPage(1);
  }, [groupId]);

  const checkRubric = (rubric) => {
    if (wrapped) {
      setSelected(rubric);
      return;
    }
    const isAdded = selected.find(checked => checked.id === rubric.id);

    let newSelected = [];
    if (isAdded) {
      newSelected = selected.filter(selected => selected.id !== rubric.id);
    } else {
      newSelected = [...selected, rubric];
    }
    setSelected(newSelected);
  };

  const actions = [
    {
      icon: faPaperPlane,
      label: lang.send,
      onClick: () => setShowSendModal(true),
    },
    {
      icon: faTrash,
      label: lang.delete,
      onClick: () => setShowDeleteModal(true),
      disabled: deleteDisabled,
      tip: deleteDisabled ? lang.deleteDisabled : null,
    },
  ];

  const refresh = () => {
    fetchRubrics();
    setSelected([]);
  };

  const getRubrics = () => {
    if (rubrics.length === 0) {
      return (
        <NoEntity
          message={lang.rubrics.noRubrics}
        />
      );
    }

    return rubrics.map((rubric, index) => {

      let isSelected = false;
      if (selected) {
        isSelected = selected.find(checked => checked.id === rubric.id);
      }

      return (
        <RubricCard
          dataTour={`rubric-card-${index}`}
          key={rubric.id}
          rubric={rubric}
          fetchRubrics={fetchRubrics}
          selectable={selectable}
          selected={isSelected}
          setSelected={setSelected}
          check={checkRubric}
          groups={groups}
        />
      );
    });
  };

  const getNotSelectableModals = () => {
    if (!groupId) {
      return;
    }

    if (!selectable) {
      return (
        <Fragment>
          <SendRubricModal
            open={showSendModal}
            close={() => setShowSendModal(false)}
            selectedRubrics={selected}
            groupId={groupId}
            groups={groups}
            refresh={refresh}
          />
          <DeleteRubricsModal
            open={showDeleteModal}
            close={() => setShowDeleteModal(false)}
            templateRubricIds={selected.map(rubric => rubric.id)}
            refresh={refresh}
          />
        </Fragment>
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      {getNotSelectableModals()}
      <Pagination
        pages={pages > 0 ? pages : 1}
        top
        bottom
        page={page}
        setPage={setPage}
        className={classes.pagination}
      >
        <Loading active={loading} />
        <Render when={rubrics && rubrics.length !== 0 && !wrapped}>
          {groupId ?
            <div className={classes.actionsWrapper}>
              <EntityActions
                actions={actions}
                selected={selected}
                selectable={selectable}
                setSelected={setSelected}
                groupId={groupId}
                entities={rubrics}
              />
            </div>
            : null}
        </Render>
        <Render when={!loading}>
          <div className={classes.rubricsWrap}>
            {getRubrics()}
          </div>
        </Render>
      </Pagination>
    </div>
  );
};

RubricBank.propTypes = {
  groupId: PropTypes.object,
  groups: PropTypes.array,
  selectable: PropTypes.bool,
  wrapped: PropTypes.bool,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  userFilters: PropTypes.array,
};

export default RubricBank;
