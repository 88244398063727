import React from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import lang from 'lang';
import routes from 'routes';

import Button from 'components/common/Button';

import useStyles from './styles';

const DownloadDocument = ({ documentURL }) => {
  const classes = useStyles();

  return (
    <div className={classes.downloadContainer}>
      {lang.test.attemptsExport.clickBelowToDownloadDocuments}
      <Button
        onClick={() => window.open(routes.s3.ref(documentURL), '_blank')}
        className={classes.downloadButton}
      >
        <FontAwesomeIcon
          icon={faDownload}
          className={classes.downloadIcon}
        />
        {lang.download}
      </Button>
    </div>
  );
};

DownloadDocument.propTypes = {
  documentURL: PropTypes.string,
};

export default DownloadDocument;
