import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer } from 'recharts';

import DonutChart from '../DonutChart';
import GradeDistributionChart from '../GradeDistributionChart';
import PieChartTwoLevel from '../PieChartTwoLevel';

import useStyles from './styles';

export const QuestionTypeCharts = ({ question }) => {
  const classes = useStyles();

  const gradeCount = {};
  gradeCount['0.00'] = 0;
  gradeCount[question.maxGrade] = 0;

  question.grades.forEach((item) => {
    const grade = item.grade;
    if (gradeCount[grade]) {
      gradeCount[grade]++;
    } else {
      gradeCount[grade] = 1;
    }
  });

  question.grades.gradeCount = gradeCount;

  const chartData = Object.keys(gradeCount).map((grade) => ({
    grade,
    gradeCount: gradeCount[grade],
    averageGrade: question.averageGradeGraph,
  }));

  chartData.sort((a, b) => parseFloat(a.grade) - parseFloat(b.grade));
  const gradeValues = question.grades.map((grade) => parseFloat(grade.grade));

  return (
    (question.type === 'choices' && question.hasJustification === false) ?
      <ResponsiveContainer className={classes.chart} debounce={300} width={'30%'} height={200}>
        <PieChartTwoLevel positiveGrades={question.positiveGrades} negativeGrades={question.negativeGrades} question={question} />
      </ResponsiveContainer>
      :
      ((question.type === 'true-false' || question.type === 'ordering' || question.type === 'segmentation' || question.type === 'connecting') && question.hasJustification === false) ?
        <ResponsiveContainer className={classes.chart} debounce={300} width={'30%'} height={200}>
          <DonutChart positiveGrades={question.positiveGrades} negativeGrades={question.negativeGrades} />
        </ResponsiveContainer>
        :
        <ResponsiveContainer className={classes.chart} debounce={300} width={'30%'} height={200}>
          <GradeDistributionChart scores={gradeValues} total={question.maxGrade} question={true} />
        </ResponsiveContainer>
  );
};

QuestionTypeCharts.propTypes = {
  question: PropTypes.array,
};

export default QuestionTypeCharts;
