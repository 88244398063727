import React, { useState } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import FilterPoolItem from 'components/common/filter-selector/FilterPoolItem';

import useStyles from './styles.js';

const FilterPool = ({ poolItems, poolItemAction, selectable, numberOfVisibleItems }) => {
  const classes = useStyles();

  const [limit, setLimit] = useState(numberOfVisibleItems ?? null);

  const getItems = () => {
    let limitLength;
    if (numberOfVisibleItems <= poolItems.length && poolItems.length !== 0) {
      limitLength = limit;
    } else {
      limitLength = poolItems.length;
    }

    const items = [];

    const showMore = {
      id: 'showMore',
      name: lang.showMore + '...',
      type: 'action',
      color: '#419bb4',
      hoverColor: '#347a8e',
      action: () => setLimit(poolItems.length),
      extraProps: {
        tabIndex: '0',
      },
    };

    for (let i = 0; i < limitLength; i++) {
      const poolItem = poolItems[i];
      items.push(
        <FilterPoolItem
          key={poolItem.id}
          poolItem={poolItem}
          itemAction={poolItemAction}
          selectable={selectable}
        />,
      );
    }

    if (limitLength < poolItems.length) {
      items.push(
        <FilterPoolItem
          key={showMore.id}
          poolItem={showMore}
          itemAction={poolItemAction}
          selectable={selectable}
        />,
      );
    }

    return items;
  };

  return (
    <div className={classes.itemsContainer}>
      {getItems()}
    </div>
  );
};

FilterPool.propTypes = {
  poolItems: PropTypes.array.isRequired,
  poolItemAction: PropTypes.func,
  selectable: PropTypes.bool,
  numberOfVisibleItems: PropTypes.number,
};
export default FilterPool;
