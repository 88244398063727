import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  upgradePremiumContainer: {
    margin: '40px 0 40px 0',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    marginBottom: '60px',
  },
  message: {
    fontSize: '25px',
    marginBottom: '60px',
    color: theme.grey,
  },
  optionsContainer: {
    display: 'flex',
    gap: '20px',
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0px',
  },
  optionTitle: {
    fontWeight: 600,
    fontSize: '45px',
    marginBottom: '20px',
  },
  optionMessage: {
    fontSize: '25px',
    marginBottom: '60px',
    color: theme.grey,
  },
  ctaContainer: {
    marginTop: 'auto',
  },
  ctaButton: {
    fontSize: '20px',
    fontWeight: 600,
    padding: '30px 40px',
  },
  upgradeButton: {
    backgroundColor: theme.midnightGreen,
    color: theme.green,
    '&:hover': {
      backgroundColor: theme.midnightGreen,
      color: theme.green,
    },
  },
  recommendButton: {
    backgroundColor: theme.green,
    color: theme.midnightGreen,
    '&:hover': {
      backgroundColor: theme.green,
      color: theme.midnightGreen,
    },
  },
}));
