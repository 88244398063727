import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  itemRow: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '70%',
    '& > p': {
      margin: '0',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
    padding: '5px',
  },
  item: {
    color: theme.textColor5,
    display: 'flex',
    gap: '0.5em',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.buttonBackground3,
    border: `1px solid ${theme.buttonBorder3}`,
    borderRadius: '10px',
    padding: '10px',
    maxWidth: '70%',
    transition: 'background-color 100ms',
    fontSize: '12px',
    height: 'auto',
    marginLeft: '10px',
  },
}));
