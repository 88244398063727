import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  choicesContainer: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  choiceRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  deleteBtn: {
    fontSize: '20px',
    marginLeft: '10px',
    cursor: 'pointer',
    color: theme.removeColor,
    '&.disabled': {
      color: theme.unselectedColor2,
      cursor: 'not-allowed',
    },
  },
  newChoiceText: {
    marginLeft: '10px',
  },
  optionInput: {
    '& .ql-toolbar': {
      padding: '0',
      border: `1px solid ${theme.cardBorder3}`,
    },
    '& .ql-container': {
      border: `1px solid ${theme.cardBorder3}`,
    },
  },
}));
