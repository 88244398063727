import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import Modal from 'components/common/Modal';
import PreviewableRubric from 'components/rubrics/PreviewableRubric';

import useStyles from './styles';

const AttemptRubricModal = ({ open, close, rubric }) => {
  const classes = useStyles();

  const _close = useCallback(() => {
    setTimeout(() => {
      close();
    }, 350);
  }, [close]);

  const getTotalWeights = () => {
    if (!rubric) {
      return;
    }

    let totalWeights = '';

    rubric.criteria.forEach((criteria, index) => {
      let totalWeight = 0;
      let totalPoints = 0;

      criteria.weights.forEach((exerciseWeight) => {
        totalWeight += (parseInt(exerciseWeight.weight) / 100) * parseInt(exerciseWeight.points);
        totalPoints += parseInt(exerciseWeight.points);
      });

      totalWeight /= totalPoints;
      totalWeight *= 100;

      totalWeights += `${criteria.name} (${totalWeight.toFixed(2) || 0}%)${index === rubric.criteria.length - 1 ? '' : ', '}`;
    });

    return totalWeights;
  };

  const actions = [
    {
      name: lang.close,
      color: 'black',
      onClick: _close,
    },
  ];

  if (!rubric) {
    return null;
  }

  return (
    <Modal
      open={open}
      close={_close}
      header={lang.appKeywords.rubric}
      actions={actions}
      center
      transition
      large
    >
      <PreviewableRubric
        rubric={rubric}
      />
      <div className={classes.totalPonderations}>
        <div className={classes.totalPonderationsHeader}>
          {lang.test.rubric.totalPonderations}
        </div>
        <div className={classes.totalPonderationsWeights}>
          {getTotalWeights()}
        </div>
      </div>
    </Modal>
  );
};

AttemptRubricModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  rubric: PropTypes.object,
};

export default AttemptRubricModal;
