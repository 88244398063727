import Quill from 'quill';

import { LOCAL } from 'constants/environments';

const BlockEmbed = Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
  static create(src) {
    const node = super.create(src);
    const image = document.createElement('img');

    let newSrc = src;
    if (process.env.REACT_APP_NODE_ENV === LOCAL) {
      const hostname = window.location.hostname;
      newSrc = newSrc.replace('localhost', hostname);
    }

    image.setAttribute('src', newSrc);
    image.setAttribute('alt', '');

    node.appendChild(image);

    return node;
  }

  static value(node) {
    return node.getElementsByTagName('img')[0].getAttribute('src');
  }
}

ImageBlot.blotName = 'image';
ImageBlot.tagName = 'p';
ImageBlot.className = 'ql-image';

export default ImageBlot;
