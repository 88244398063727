import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  testStatisticsCard: {
    width: '100% !important',
    '& .otl-cardContentContainer': {
      padding: 0,
    },
    height: '100%',
    borderRadius: '18px',
    overflow: 'hidden',
    boxShadow: '0 4px 4px #00000040',
    marginBottom: '15px',
  },
  testStatistics: {
    padding: '25px 5px 5px 5px',
    height: '100%',
    width: '100% !important',
    paddingBottom: '15px',
    display: 'flex',
    flexFlow: 'row-wrap',
    justifyContent: 'space-evenly',
  },
  donutChart: {
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  noStudents: {
    textAlign: 'center',
    color: theme.textColor2,
  },
  advancedStatsBlock:
  {
    width: '100%',
    border: 'none',
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  advancedStatsBlockText: {
    width: '50% !important',
    '& .otl-cardContentContainer': {
      padding: 0,
    },
    position: 'absolute',
    justifyContent: 'center',
    zIndex: '1000',
  },
  advancedStatsBlockImage: {
    marginTop: '5%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: '0.3',
    filter: 'blur(3px)',
    width: '99%',
  },
  topButton: {
    position: 'fixed',
    bottom: 60,
    right: 15,
  },
}));
