import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  criteriaContainer: {
    display: 'flex',
  },
  performanceLevelsHeader: {
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: '10px',
  },
  performanceLevelsTableContainer: {
    width: '100%',
    overflowX: 'auto',
  },
  rubricTableCell: {
    border: 'none !important',
  },
  performanceLevelDescription: {
    minWidth: '150px !important',
    minHeight: '150px !important',
  },
  criteriaColumn: {
    position: 'sticky',
    left: 0,
    backgroundColor: theme.cardBackground3,
  },
  criteriaRemoveButton: {
    padding: '13px 10px',
    color: theme.removeColor,
    cursor: 'pointer',
  },
  expandable: {
    borderRadius: '18px',
  },
  removeButton: {
    height: 'fit-content',
  },
}));
