import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  gapContainer: {
    display: 'flex',
    padding: '15px 13px',
    gap: '0.5em',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  gapButton: {
    color: theme.textColor5,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.buttonBackground3,
    border: `1px solid ${theme.buttonBorder3}`,
    padding: '10px',
    width: 'auto',
    transition: 'background-color 100ms',
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: theme.buttonBackground3,
    },
    '&:hover': {
      backgroundColor: theme.buttonBackground,
    },
    '&.selected': {
      border: '3px solid #5ED0F0',
      borderRadius: '4px',
    },
    '&>div>p>.gap': {
      display: 'none',
    },
  },
  buttonText: {
    marginLeft: '6px',
  },
  noGaps: {
    color: theme.textColor7,
  },
  joinButton: {
    borderRadius: '6px',
    padding: '0.25em',
    height: 'fit-content',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  toggleWrapper: {
    display: 'flex',
    gap: '0.35em',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '300 !important',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    trueFalseContainer: {
      flexWrap: 'wrap',
      gap: '0.5em',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    trueFalseContainer: {
      justifyContent: 'space-around',
    },
  },
}));
