import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  menu: {
    maxHeight: '15em',
    overflowY: 'auto',
    zIndex: '50',
    minWidth: '13em',
    backgroundColor: `${theme.inputBackground2} !important`,
    border: `1px solid ${theme.inputBorder2} !important`,
    borderRadius: '10px',
    top: 'calc(100% + 9px)',
  },
  placeholder: {
    backgroundColor: theme.inputBackground2,
    border: `1px solid ${theme.inputBorder2}`,
    padding: '0.45em',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    '&.disabled': {
      opacity: '50%',
      cursor: 'default',
    },
  },
  icon: {
    marginLeft: '10px',
    fontSize: '10px',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: 'unset !important',
    '&:hover': {
      backgroundColor: theme.shade1,
      cursor: 'pointer',
    },
  },
  iconPlus: {
    marginLeft: '0.5em',
    marginRight: '0.5em',
    width: '1em !important',
  },
  value: {
    padding: '0 !important',
  },
  noOptions: {
    color: theme.textColor2,
    fontSize: '14px',
    margin: '0 !important',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    menu: {
      width: 'calc(100% - 2px)',
    },
  },
}));
