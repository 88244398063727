import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  exerciseCell: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px #419bb4B3',
    },
  },
  exerciseChoiceWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  removeButton: {
    height: '30px',
    width: '30px',
    minWidth: '30px !important',
    border: '2px solid #ef444426 !important',
    color: '#ef4444 !important',
    '& > svg': {
      fontSize: '1.2em',
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
      border: '2px solid #ef4444 !important',
    },
  },
}));
