import React, { useCallback, useState } from 'react';
import { Tabs, Tab, Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectAttemptId } from 'actions/studentAttemptActions';
import { selectUserIsTeacher } from 'actions/userActions';
import { MAX_SIZE_1GB } from 'constants/fileMaxSizes';
import { CUSTOM_VIDEO, YOUTUBE } from 'constants/videoTypes';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const YOUTUBE_TAB = 0;
const CUSTOM_VIDEO_TAB = 1;

const VideoModal = ({ open, close, insert }) => {
  const classes = useStyles();
  const toast = useToast();
  const videoControlToggle = useFeature(toggles.videoControl);
  const isTeacher = useSelector(selectUserIsTeacher);
  const attemptId = useSelector(selectAttemptId);

  const [videoUrl, setVideoUrl] = useState('');
  const [files, setFiles] = useState([]);
  const [currentTab, setCurrentTab] = useState(YOUTUBE_TAB);
  const [canPause, setCanPause] = useState(true);
  const [limitRepetitions, setLimitRepetitions] = useState(false);
  const [maxRepetitions, setMaxRepetitions] = useState(3);

  const onDrop = useCallback((filesToUpload) => {
    const fileToUpload = filesToUpload[0];

    if (!fileToUpload) {
      toast.error(lang.exerciseForm.askForImageError);
      return;
    }

    if (fileToUpload.size > MAX_SIZE_1GB) {
      toast.warning(lang.fileTooLarge1Gb);
      return;
    }

    setFiles([fileToUpload]);
  }, [toast, setFiles]);

  const onAdd = useCallback(() => {
    if (currentTab === YOUTUBE_TAB && videoUrl) {
      const youtubeLinkRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (!videoUrl.match(youtubeLinkRegex)) {
        toast.error(lang.exerciseForm.invalidUrl);
        return;
      }
      insert(videoUrl, YOUTUBE);
      setFiles([]);
      setVideoUrl('');
      close();
      return;
    }

    if (currentTab === CUSTOM_VIDEO_TAB && files && files.length !== 0) {
      insert(files[0], CUSTOM_VIDEO, canPause, limitRepetitions, maxRepetitions);
      setFiles([]);
      setVideoUrl('');
      close();
      return;
    }

    toast.warning(lang.exerciseForm.noVideoChosen);
  }, [currentTab, files, videoUrl, close, insert, toast, canPause, limitRepetitions, maxRepetitions]);

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
    {
      name: lang.add,
      onClick: onAdd,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.exerciseForm.videoHeader}
      actions={actions}
      center
      transition
      large
    >
      <Tabs
        className={classes.tabs}
        current={currentTab}
      >
        <Tab
          label={lang.exerciseForm.youtube}
          className={classes.sectionTab}
          setActive={() => setCurrentTab(YOUTUBE_TAB)}
        >
          <Input
            label={lang.exerciseForm.youtubeLink}
            value={videoUrl}
            onChange={(event) => setVideoUrl(event.target.value)}
          />
        </Tab>
        <Tab
          label={lang.exerciseForm.myVideo}
          className={classes.sectionTab}
          setActive={() => setCurrentTab(CUSTOM_VIDEO_TAB)}
        >
          <Input
            type="file"
            placeholder={lang.exerciseForm.addVideoLabel}
            value={files}
            accept="video/mp4,video/x-m4v,video/*"
            onDrop={onDrop}
          />
          <Render when={videoControlToggle && isTeacher && !attemptId}>
            <Spacer px={20} />
            <Input
              type="checkbox"
              label={lang.exerciseForm.video.canPauseLabel}
              value={canPause}
              onChange={(event) => setCanPause(event.target.checked)}
            />
            <Input
              type="checkbox"
              label={lang.exerciseForm.video.limitRepetitionsLabel}
              value={limitRepetitions}
              onChange={(event) => setLimitRepetitions(event.target.checked)}
            />
            <Render when={limitRepetitions}>
              <Spacer px={20} />
              <Input
                type="number"
                label={lang.exerciseForm.video.maxRepetitionsLabel}
                placeholder={lang.exerciseForm.video.maxRepetitionsLabel}
                value={maxRepetitions}
                onChange={(event) => setMaxRepetitions(event.target.value)}
              />
            </Render>
            <Spacer px={20} />
          </Render>
        </Tab>
      </Tabs>
    </Modal>
  );
};

VideoModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  insert: PropTypes.func,
};

export default VideoModal;
