import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  row: {
    margin: '0',
  },
  mobileInvisible: {
  },
  mobileVisible: {
    display: ' none',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    mobileInvisible: {
      display: 'none',
    },
    mobileVisible: {
      display: 'unset',
    },
  },
}));
