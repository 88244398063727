import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles from './styles';

const ResponsiveLang = ({ lang, xl, lg, md, sm, xs }) => {
  const classes = useStyles();

  return (
    <div>
      <span className={classes.responsiveLang}>
        {lang}
      </span>
      <span className={cx(classes.responsiveLang, { xl: true })}>
        {xl || lang}
      </span>
      <span className={cx(classes.responsiveLang, { lg: true })}>
        {lg || xl || lang}
      </span>
      <span className={cx(classes.responsiveLang, { md: true })}>
        {md || lg || xl || lang}
      </span>
      <span className={cx(classes.responsiveLang, { sm: true })}>
        {sm || md || lg || xl || lang}
      </span>
      <span className={cx(classes.responsiveLang, { xs: true })}>
        {xs || sm || md || lg || xl || lang}
      </span>
    </div>
  );
};

ResponsiveLang.propTypes = {
  lang: PropTypes.string,
  xl: PropTypes.string,
  lg: PropTypes.string,
  md: PropTypes.string,
  sm: PropTypes.string,
  xs: PropTypes.string,
};

export default ResponsiveLang;

