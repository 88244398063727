import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const EntityAction = ({ icon, tip, tipDirection = 'left', onClick, className, dataTour }) => {
  const classes = useStyles();

  return (
    <div data-tour={dataTour} onClick={onClick}>
      <Tooltip
        tip={tip}
        place={tipDirection}
      >
        <FontAwesomeIcon
          icon={icon}
          className={cx(classes.action, className)}
        />
      </Tooltip>
    </div>
  );
};

EntityAction.propTypes = {
  icon: PropTypes.object.isRequired,
  tip: PropTypes.string,
  tipDirection: PropTypes.string,
  onClick: PropTypes.func,
  dataTour: PropTypes.string,
  className: PropTypes.string,
};

export default EntityAction;
