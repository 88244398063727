import React from 'react';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';
import routes from 'routes';

import ALink from '../ALink';
import Button from 'components/common/Button';

import useStyles from './styles';

const MicrosoftButton = ({ label, request, handleAuthenticated, postLogoutRedirectUri }) => {
  const classes = useStyles();
  const { instance, accounts } = useMsal();
  const toast = useToast();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0]);

  const handleLoginRedirect = () => {
    instance.loginRedirect(request).catch(() => {
      toast.error(lang.oops);
    });
  };

  const _handleAuthenticated = () => {
    handleAuthenticated(account.name, account.username);
  };

  return (
    <div>
      <Button
        sibling
        onClick={isAuthenticated && handleAuthenticated ? _handleAuthenticated : handleLoginRedirect}
        className={classes.microsoftButton}
      >
        <img
          src={`${process.env.REACT_APP_S3_URL}/resources/microsoft-logo.svg`}
          alt=""
        />
        <Render when={!isAuthenticated || !handleAuthenticated}>
          {label}
        </Render>
        <Render when={isAuthenticated && handleAuthenticated}>
          {lang.continueWith}
          <div className={classes.fullName}>
            {account?.name}
          </div>
        </Render>
      </Button>
      <Render when={isAuthenticated && handleAuthenticated}>
        <div className={classes.logoutContainer}>
          {lang.notYou}
          <ALink href={`${routes.authLogout.ref()}${postLogoutRedirectUri ? `?redirectUri=${postLogoutRedirectUri}` : ''}`}>
            {lang.logout}
          </ALink>
        </div>
      </Render>
    </div>
  );
};

MicrosoftButton.propTypes = {
  label: PropTypes.string,
  request: PropTypes.object,
  handleAuthenticated: PropTypes.func,
  postLogoutRedirectUri: PropTypes.string,
};

export default MicrosoftButton;
