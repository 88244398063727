import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  gapContainer: {
    padding: '15px 13px',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25em',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  richTextWrapper: {
    padding: '10px',
    minWidth: '50%',
    maxWidth: '50%',
  },
  toggleWrapper: {
    display: 'flex',
    gap: '0.35em',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '300 !important',
  },
  extraGap: {
    color: theme.textColor5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.buttonBackground3,
    border: `1px solid ${theme.buttonBorder3}`,
    borderRadius: '10px',
    padding: '10px',
    width: 'auto',
    transition: 'background-color 100ms',
    fontSize: '12px',
    height: '18px',
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: theme.buttonBackground3,
    },
  },
  remove: {
    alignItems: 'center',
    display: 'flex',
    right: '0',
    height: '100%',
    marginLeft: '5px',
    borderRadius: '10px',
    cursor: 'pointer',
    opacity: '0.6',
    '&:hover': {
      opacity: '1',
    },
  },
  button: {
    marginTop: '0.5em',
  },
  headerFormulaWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5em',
  },
  formula: {
    marginLeft: '0.5em',
  },
  noExtraGaps: {
    color: theme.textColor3,
    padding: '20px 10px',
    width: '100%',
  },
}));
