import { createUseStyles } from 'react-jss';

export default createUseStyles({
  discardDescription: {
    marginBottom: '20px',
  },
  optionWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
});
