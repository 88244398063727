import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  logo: {
    '&.extraSmall': {
      width: '80px',
    },
    '&.small': {
      width: '120px',
    },
    '&.medium': {
      width: '190px',
    },
    '&.large': {
      width: '310px',
    },
  },
  iaveLogosContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px',
    gap: '10px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    logo: {
      '&.large': {
        width: '60px !important',
      },
    },
  },
}));
