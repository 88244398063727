import React from 'react';
import { Expandable as OLExpandable } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useStyles from './styles';

const Expandable = ({ title, description, open, setOpen, startOpen, beforeOpen, beforeClose, shadow, card, className, style, disabled, children }) => {
  const classes = useStyles();
  const isDark = useSelector(state => state.page.isDark);

  return (
    <OLExpandable
      title={title}
      description={description}
      open={open}
      setOpen={setOpen}
      startOpen={startOpen}
      beforeOpen={beforeOpen}
      beforeClose={beforeClose}
      dark={isDark}
      shadow={shadow}
      card={card}
      className={cx(className, classes.expandable)}
      style={style}
      disabled={disabled}
    >
      {children}
    </OLExpandable>
  );
};

Expandable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  startOpen: PropTypes.bool,
  beforeOpen: PropTypes.func,
  beforeClose: PropTypes.func,
  shadow: PropTypes.bool,
  card: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default Expandable;
