import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { openFormulaModal } from 'actions/formulaModalActions';
import lang from 'lang';
import { parseQlFormula } from 'utils';

import expressions from '../FormulaModal/expressions';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const EditableFormula = ({ node, disabled }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [value, setValue] = useState(node.getAttribute('value'));

  const openModal = () => {
    dispatch(openFormulaModal({
      value: node.getAttribute('value'),
      insert: (value) => {
        node.setAttribute('value', value);
        setValue(value);
      },
      enableMathSymbols: node.getAttribute('data-mathSymbols'),
      mathSymbols: node.getAttribute('data-mathSymbols') ? JSON.parse(node.getAttribute('data-mathSymbols')) : expressions,
    }));
  };

  return (
    <Tooltip
      tip={!disabled && lang.edit}
      place={'right'}
    >
      <span
        dangerouslySetInnerHTML={{ __html: parseQlFormula(`<span class="ql-formula">${value}</span>`) }}
        onClick={disabled ? () => {} : openModal}
        className={cx(classes.formulaWrapper, { disabled })}
      />
    </Tooltip>
  );
};

EditableFormula.propTypes = {
  node: PropTypes.node,
  disabled: PropTypes.bool,
};

export default EditableFormula;
