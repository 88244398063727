import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import PropTypes from 'prop-types';

const ScrollableDndContainer = ({ children }) => {
  const { setNodeRef } = useDroppable({ id: 'NULL' });

  return (
    <div ref={setNodeRef}>
      {children}
    </div>
  );
};

ScrollableDndContainer.propTypes = {
  children: PropTypes.any,
};

export default ScrollableDndContainer;
