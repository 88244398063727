import { faFilePdf, faFileImage as faFileImageR, faFileExcel, faFileWord, faFilePowerpoint } from '@fortawesome/free-regular-svg-icons';
import { faAlignLeft, faCheckCircle, faFileImage as faFileImageS, faPencilRuler, faTasks, faUsers, faFileAlt, faAlignRight, faInfo, faSortAmountUp, faArrowsTurnToDots, faCodeBranch, faCircleNodes, faTableCells } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import katex from 'katex';
import { QuillDeltaToHtmlConverter as qConverter } from 'quill-delta-to-html';

import { LOCAL } from 'constants/environments';
import { macros } from 'constants/katexMacros';
import lang from 'lang';

/**
 * Checks if an array has duplicates.
 */
const hasDuplicates = (arr) => {
  return arr.some(x => arr.indexOf(x) !== arr.lastIndexOf(x));
};

/**
 * Gives textarea on field a perfect height.
 */
const autoExpand = (field) => {
  field.style.height = 'inherit';
  const height = field.scrollHeight + 2;
  field.style.height = height + 'px';
};

/**
 * Returns the coresponding HTTP error code based on the API error.
 * @param {Number} error The error returned by the API.
 */
const getErrorCode = (error) => {
  switch (error) {
    case -2:
      return '404';
    case 1:
      return '404';
    default:
      return '500';
  }
};

const getCookie = (key) => {
  return Cookies.get(key);
};

const addCookie = (key, value, expires) => {
  const expiresDefault = new Date();
  expiresDefault.setMonth(expiresDefault.getMonth() + 1);

  let domain = process.env.REACT_APP_INTUITIVO_DOMAIN;
  if (process.env.REACT_APP_NODE_ENV === LOCAL) {
    const hostname = window.location.hostname;
    domain = hostname;
  }

  Cookies.set(key, value, { domain: domain, expires: expires || expiresDefault });
};

const removeCookie = (key) => {
  let domain = process.env.REACT_APP_INTUITIVO_DOMAIN;
  if (process.env.REACT_APP_NODE_ENV === LOCAL) {
    const hostname = window.location.hostname;
    domain = hostname;
  }

  Cookies.remove(key, { domain: domain });
};

/**
 * This helper functions returns a DOM matching a selector via query selectors.
 * If not found, it listens for DOM mutations until it is found
 * @param {string} selector Selector string
 * @returns {Promise<Element>} DOM element
 */
const waitForElementToExist = (selector) => {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    });
  });
};

const unEscape = (str) => {
  return str
    .split('&lt;').join('<')
    .split('&gt;').join('>')
    .split('&#x2F;').join('/')
    .split('&#x27;').join('\'')
    .split('&quot;').join('"')
    .split('&amp;').join('&');
};

const parseQlFormula = (htmlString) => {
  const finalArr = [];
  const cutted = htmlString.split('<span class="ql-formula">');
  finalArr.push(cutted[0]);
  for (let i = 1; i < cutted.length; i++) {
    const delimIndex = cutted[i].indexOf('</span>');
    const s = cutted[i].substring(0, delimIndex);
    const rest = cutted[i].substring(delimIndex);

    finalArr.push(katex.renderToString(unEscape(s), { macros }));
    finalArr.push(rest);
  }

  let finalHtml = '';
  finalArr.forEach(el => finalHtml += el);

  return finalHtml;
};

/**
 * Renders quill delta as HTML and returns it.
 */
const renderQuill = (content, config) => {
  const converter = new qConverter(content.ops, {
    encodeHtml: false,
    ...config,
  });

  converter.renderCustomWith((customOp) => {
    if (customOp.insert.type === 'audio' || customOp.insert.type === 'custom-audio') {
      let src, canPause, limitRepetitions, maxRepetitions;
      if (typeof customOp.insert.value === 'string') {
        src = customOp.insert.value;
        if (process.env.REACT_APP_NODE_ENV === LOCAL) {
          const hostname = window.location.hostname;
          src = src.replace('localhost', hostname);
        }

        canPause = true;
        limitRepetitions = false;
      }

      if (typeof customOp.insert.value === 'object') {
        src = customOp.insert.value.src;
        canPause = customOp.insert.value.canPause;
        limitRepetitions = customOp.insert.value.limitRepetitions;
        maxRepetitions = customOp.insert.value.maxRepetitions;
      }

      return `<audio
        oncontextmenu="return false;"
        src=${src}
        data-canpause=${canPause}
        data-limitrepetitions=${limitRepetitions}
        data-maxrepetitions=${maxRepetitions}
        controls
        controlsList="nodownload"
      ></audio>`;
    }

    if (customOp.insert.type === 'gap') {
      return `<span data-drop="true" data-position=${customOp.attributes.position}></span>`;
    }

    if (customOp.insert.type === 'custom-video') {
      let src, canPause, limitRepetitions, maxRepetitions;
      if (typeof customOp.insert.value === 'string') {
        src = customOp.insert.value;
        if (process.env.REACT_APP_NODE_ENV === LOCAL) {
          const hostname = window.location.hostname;
          src = src.replace('localhost', hostname);
        }

        canPause = true;
        limitRepetitions = false;
      }

      if (typeof customOp.insert.value === 'object') {
        src = customOp.insert.value.src;
        if (process.env.REACT_APP_NODE_ENV === LOCAL) {
          const hostname = window.location.hostname;
          src = src.replace('localhost', hostname);
        }

        canPause = customOp.insert.value.canPause;
        limitRepetitions = customOp.insert.value.limitRepetitions;
        maxRepetitions = customOp.insert.value.maxRepetitions;
      }

      return `<video
        oncontextmenu="return false;"
        src=${src}
        data-canpause=${canPause}
        data-limitrepetitions=${limitRepetitions}
        data-maxrepetitions=${maxRepetitions}
        controls
        controlsList="nodownload"
        ></video>`;
    }
  });

  converter.afterRender((_groupType, htmlString) => {
    return parseQlFormula(htmlString);
  });

  return converter.convert();
};

const quillIsEmpty = (quill) => {
  if (!quill || !quill.ops || quill.ops.length === 0) {
    return true;
  }

  return quill.ops.length === 1 && quill.ops[0].insert && quill.ops[0].insert.trim() === '';
};

/**
 * Cleans latex string by removing
 * all newlines and trimming.
 */
const cleanLatex = (latex) => {
  let newLatex = latex.toString();

  // Remove newlines
  newLatex = newLatex.replace(/(\r\n|\n|\r)/gm, '');

  // Trim whitespace
  newLatex = newLatex.trim();

  return newLatex;
};

/**
 * Validates if a latex string is valid.
 */
const validateLatex = (latex) => {
  try {
    katex.renderToString(latex, { macros });
    return true;
  } catch (e) {
    /* eslint-disable no-console */
    if (e instanceof katex.ParseError) {
      console.error(e);
      return false;
    } else {
      console.error(e);
      return false;
    }
    /* eslint-enable no-console */
  }
};

/**
 * Returns a string representing the
 * type of an exercise.
 */
const exerciseTypeString = (type) => {
  if (type === 'choices') {
    return lang.exerciseForm.typeChoices;
  } else if (type === 'text') {
    return lang.exerciseForm.typeText;
  } else if (type === 'true-false') {
    return lang.exerciseForm.typeTrueFalse;
  } else if (type === 'image') {
    return lang.exerciseForm.typeImage;
  } else if (type === 'filling') {
    return lang.exerciseForm.typeFilling;
  } else if (type === 'ordering') {
    return lang.exerciseForm.typeOrdering;
  } else if (type === 'information') {
    return lang.test.information;
  } else if (type === 'caption') {
    return lang.exerciseForm.typeCaption;
  } else if (type === 'connecting') {
    return lang.exerciseForm.typeConnecting;
  } else if (type === 'segmentation') {
    return lang.exerciseForm.typeSegmentation;
  } else if (type === 'table') {
    return lang.exerciseForm.typeTable;
  }
};

const classificationTestTypeString = (type) => {
  if (type === 'classic') {
    return lang.appKeywords.classic;
  } else if (type === 'rubric') {
    return lang.appKeywords.rubric;
  } else if (type === 'none') {
    return lang.appKeywords.noClassification;
  }
};

const testTypeString = (type) => {
  if (type === 'test') {
    return lang.appKeywords.test;
  } else if (type === 'worksheet') {
    return lang.appKeywords.worksheet;
  }
};

const urlToFile = async (url, filename, mimeType) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
};

const isValidURL = (str) => {
  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
  return regex.test(str);
};

const exerciseTypeIcon = (type) => {
  if (type === 'choices') {
    return faTasks;
  } else if (type === 'text') {
    return faAlignLeft;
  } else if (type === 'true-false') {
    return faCheckCircle;
  } else if (type === 'image') {
    return faFileImageS;
  } else if (type === 'filling') {
    return faAlignRight;
  } else if (type === 'ordering') {
    return faSortAmountUp;
  } else if (type === 'information') {
    return faInfo;
  } else if (type === 'caption') {
    return faArrowsTurnToDots;
  } else if (type === 'connecting') {
    return faCodeBranch;
  } else if (type === 'segmentation') {
    return faCircleNodes;
  } else if (type === 'table') {
    return faTableCells;
  }
};

const getIcon = (element) => {
  if (element === 'Tests' || element === 'Testes') {
    return faFileAlt;
  } else if (element === 'Exercises' || element === 'Exercícios') {
    return faPencilRuler;
  } else if (element === 'Groups' || element === 'Grupos') {
    return faUsers;
  }
};

/**
 * Retrieves the a file icon corresponding to the file extension.
 * @param {String} fileType The file extension
 */
const getFileIcon = (fileType) => {
  const fileTypeNormalized = fileType.toLowerCase();

  if (fileTypeNormalized === 'pdf') {
    return faFilePdf;
  }

  if (fileTypeNormalized === 'jpg' || fileTypeNormalized === 'png' || fileTypeNormalized === 'jpeg') {
    return faFileImageR;
  }

  if (fileTypeNormalized === 'xlsx' || fileTypeNormalized === 'csv' || fileTypeNormalized === 'tsv' || fileTypeNormalized === 'ods') {
    return faFileExcel;
  }

  if (fileTypeNormalized === 'docx' || fileTypeNormalized === 'odt') {
    return faFileWord;
  }

  if (fileTypeNormalized === 'pptx' || fileTypeNormalized === 'odp') {
    return faFilePowerpoint;
  }

  return faFileAlt;
};

const getFileType = (fileType) => {
  const fileTypeNormalized = fileType.toLowerCase();

  if (fileTypeNormalized === 'pdf') {
    return 'pdf';
  }

  if (fileTypeNormalized === 'jpg' || fileTypeNormalized === 'png' || fileTypeNormalized === 'jpeg') {
    return 'image';
  }

  if (fileTypeNormalized === 'xlsx' || fileTypeNormalized === 'csv' || fileTypeNormalized === 'tsv' || fileTypeNormalized === 'ods') {
    return 'spreadsheet';
  }

  if (fileTypeNormalized === 'docx' || fileTypeNormalized === 'odt') {
    return 'text';
  }

  if (fileTypeNormalized === 'pptx' || fileTypeNormalized === 'odp') {
    return 'slides';
  }

  return 'default';
};

const cleanAndSortGroups = (groups) => {
  const newGroups = groups.map(group => ({
    id: group.id,
    label: group.isPersonal ? lang.appKeywords.personalGroup : group.name,
    isPersonal: group.isPersonal,
    usersCount: group.usersCount,
    schoolId: group.schoolId,
    teachers: group.teachers,
  })).sort((a, b) => {
    if (a.label === b.label) {
      return 0;
    }

    if (a.isPersonal) {
      return -1;
    }

    if (b.isPersonal) {
      return 1;
    }

    if (a.label < b.label) {
      return -1;
    }

    if (a.label > b.label) {
      return 1;
    }

    return 0;
  });

  return newGroups;
};

const shuffleArr = (array) => {
  let m = array.length, t, i;
  // While there remain elements to shuffle…
  while (m) {

    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }
};

const shuffle = a => {
  return a.reduce((l, e, i) => {
    const j = Math.floor(Math.random() * (a.length - i) + i); // j is in [i, a.length[
    [a[i], a[j]] = [a[j], a[i]];
    return a;
  }, a);
};

const sortPerformanceLevels = (performanceLevels) => {
  return performanceLevels.sort((a, b) => {
    return a.level > b.level ? -1 : 1;
  });
};

const isJson = (item) => {
  item = typeof item !== 'string'
    ? JSON.stringify(item)
    : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === 'object' && item !== null) {
    return true;
  }

  return false;
};

const restrictToBoundingRect = (transform, rect, boundingRect) => {
  const value = {
    ...transform,
  };

  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top;
  } else if (rect.bottom + transform.y >= boundingRect.top + boundingRect.height) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom;
  }

  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left;
  } else if (rect.right + transform.x >= boundingRect.left + boundingRect.width) {
    value.x = boundingRect.left + boundingRect.width - rect.right;
  }

  return value;
};

const deepCopy = (obj) => {
  if (Object.prototype.toString.call(obj) === '[object Array]') {
    const out = [];
    let i = 0;
    const len = obj.length;
    for (; i < len; i++) {
      out[i] = deepCopy(obj[i]);
    }
    return out;
  }

  if (obj === null) {
    return null;
  }

  if (typeof obj === 'object') {
    const out = {};
    let i;
    for (i in obj) {
      if (i !== 'ref') {
        out[i] = deepCopy(obj[i]);
      }
    }
    return out;
  }
  return obj;
};

const sortPreferencesByActive = (preferences) => {
  return preferences.sort((a, b) => {
    if (a.active && !b.active) {
      return -1;
    } else if (!a.active && b.active) {
      return 1;
    }
    return 0;
  });
};

const renderGap = (gap) => {
  if (!gap?.text) {
    return null;
  }

  if (!isJson(gap.text)) {
    return gap.text === '' ? lang.exerciseForm.caption : gap.text;
  }

  const gapText = JSON.parse(gap.text);

  if (Array.isArray(gapText)) {
    return renderQuill({
      ops: gapText,
    }, { encodeHtml: true });
  }

  if (gapText.formula) {
    return katex.renderToString(gapText.formula, { macros });
  } else {
    return gapText.insert;
  }
};

const simpleCompare = (a, b) => {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};

const extractOpsText = (ops) => {
  if (!ops) {
    return '';
  }

  let opsText = '';
  ops.forEach(op => {
    if (op.insert) {
      if (op.insert.gap) {
        if (op.insert.gap.formula) {
          opsText += op.insert.gap.formula.trim() + ' ';
        } else {
          opsText += op.insert.gap.trim() + ' ';
        }
      } else if (op.insert.formula) {
        opsText += `$${op.insert.formula.trim()}$ `;
      } else if (op.insert.video) {
        opsText += `(video: ${op.insert.video.trim()}) `;
      } else if (op.insert.image) {
        opsText += `(image: ${op.insert.image.trim()}) `;
      } else if (op.insert.audio) {
        opsText += `(audio: ${op.insert.audio.trim()}) `;
      } else {
        opsText += op.insert.trim() + ' ';
      }
    }
  });

  opsText = opsText.replaceAll('"', '""');

  return opsText;
};

const extractOpsOnlyText = (ops) => {
  if (!ops) {
    return '';
  }

  let opsText = '';
  ops.forEach(op => {
    if (op.insert && typeof op.insert === 'string') {
      opsText += op.insert;
    }
  });

  opsText = opsText.replaceAll('"', '""');

  return opsText;
};

const cleanOps = (text) => {
  const trimmedText = JSON.parse(text);
  const firstElement = trimmedText[0];
  const lastElement = trimmedText[trimmedText.length - 1];

  if (firstElement.insert && typeof firstElement.insert === 'string') {
    firstElement.insert = firstElement.insert.trimStart();
  }

  if (lastElement.insert && typeof lastElement.insert === 'string') {
    lastElement.insert = lastElement.insert.trimEnd();
  }

  return JSON.stringify(trimmedText);
};

const base64ToFile = (base64String, fileName, mimeType) => {
  const byteString = atob(base64String.split(',')[1]);

  const byteArray = [];
  for (let i = 0; i < byteString.length; i++) {
    byteArray.push(byteString.charCodeAt(i));
  }

  const blob = new Blob([new Uint8Array(byteArray)], { type: mimeType });

  const file = new File([blob], fileName, { type: mimeType });

  return file;
};

export {
  hasDuplicates,
  autoExpand,
  getErrorCode,
  renderQuill,
  parseQlFormula,
  quillIsEmpty,
  cleanLatex,
  validateLatex,
  exerciseTypeString,
  testTypeString,
  classificationTestTypeString,
  urlToFile,
  isValidURL,
  exerciseTypeIcon,
  getFileIcon,
  getFileType,
  cleanAndSortGroups,
  getIcon,
  shuffleArr,
  shuffle,
  sortPerformanceLevels,
  unEscape,
  isJson,
  restrictToBoundingRect,
  deepCopy,
  sortPreferencesByActive,
  addCookie,
  getCookie,
  removeCookie,
  renderGap,
  simpleCompare,
  extractOpsText,
  cleanOps,
  waitForElementToExist,
  extractOpsOnlyText,
  base64ToFile,
};
