import React, { useCallback, useState } from 'react';
import { faFileAlt, faHandHoldingHand, faMapMarkedAlt, faUserGraduate, faUsers, faPencilRuler, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setPages, setTests, startLoading, stopLoading, setPage } from 'actions/testsPageActions';
import { selectUserSpaceIdPreference } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useQuery from 'hooks/common/useQuery';
import useTour from 'hooks/common/useTour';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import { assessmentTourOptions, assessmentTourSteps, groupsTourOptions, groupsTourSteps, introductionTourOptions, introductionTourSteps, exploreTourOptions, exploreTourSteps, exerciseTourOptions, exerciseTourSteps, rubricsTourOptions, rubricsTourSteps } from 'services/shepherd';
import toggles from 'toggles';

import Button from 'components/common/Button';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const OnboardingButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const query = useQuery();
  const statisticsToggle = useFeature(toggles.statistics);
  const modelAnswerToggle = useFeature(toggles.modelAnswer);

  const [logTourEvent] = useApi(api.logTourEvent);

  const [introductionTour, setIntroductionTourSteps] = useTour(
    introductionTourOptions,
    [],
    useCallback(() => logTourEvent([], { tour: 'introduction', action: 'start' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'introduction', action: 'complete' }), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'introduction', action: 'cancel' }), [logTourEvent]),
  );

  const [assessmentTour, setAssessmentTourSteps] = useTour(
    assessmentTourOptions,
    [],
    useCallback(() => logTourEvent([], { tour: 'assessment', action: 'start' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'assessment', action: 'complete' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'assessment', action: 'cancel' } ), [logTourEvent]),
  );

  const [exploreTour, setExploreTourSteps] = useTour(
    exploreTourOptions,
    [],
    useCallback(() => logTourEvent([], { tour: 'explore', action: 'start' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'explore', action: 'complete' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'explore', action: 'cancel' } ), [logTourEvent]),
  );
  const [groupTour, setGroupTourSteps] = useTour(
    groupsTourOptions,
    [],
    useCallback(() => logTourEvent([], { tour: 'group', action: 'start' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'group', action: 'complete' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'group', action: 'cancel' } ), [logTourEvent]),
  );

  const [exerciseTour, setExerciseTour] = useTour(
    exerciseTourOptions,
    [],
    useCallback(() => logTourEvent([], { tour: 'exercise', action: 'start' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'exercise', action: 'complete' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'exercise', action: 'cancel' } ), [logTourEvent]),
  );

  const [rubricsTour, setRubricsTourSteps] = useTour(
    rubricsTourOptions,
    [],
    useCallback(() => logTourEvent([], { tour: 'rubric', action: 'start' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'rubric', action: 'complete' } ), [logTourEvent]),
    useCallback(() => logTourEvent([], { tour: 'rubric', action: 'cancel' } ), [logTourEvent]),
  );

  const [dropdown, setDropdown] = useState(false);
  const currentSchoolId = useSelector(selectUserSpaceIdPreference);
  const [startTourRequest] = useApi(api.startTourTest);
  const [getGroupTestsRequest] = useApi(api.getGroupTests);

  const startAssessmentTour = useCallback(() => {
    const isOnAssessmentPage = history.location.pathname.endsWith('/tests');

    dispatch(startLoading());
    startTourRequest([], { schoolId: currentSchoolId }, ({ data: { status, test } }) => {
      if (status === 0) {
        const isOnCorrectGroupPage = query().get('group') === test.groupId;
        getGroupTestsRequest([test.groupId], { page: 1 }, ({ data: { status: _status, tests, pages } }) => {
          if (_status === 0) {
            if (isOnAssessmentPage && isOnCorrectGroupPage) {
              dispatch(setTests(tests));
              dispatch(setPages(pages));
              dispatch(setPage(1));
            } else {
              history.push(routes.tests.ref(currentSchoolId, test.groupId));
            }
            setIntroductionTourSteps(introductionTourSteps(test.id, statisticsToggle));
            introductionTour.start();
          } else {
            toast.error(lang.oops);
          }
          dispatch(stopLoading());
        });
      } else {
        toast.error(lang.oops);
        dispatch(stopLoading());
      }
    });
  }, [query, setIntroductionTourSteps, currentSchoolId, history, introductionTour, getGroupTestsRequest, dispatch, startTourRequest, toast, statisticsToggle]);

  const startCreateAssessmentTour = useCallback(() => {
    const isOnAssessmentPage = history.location.pathname.endsWith('/tests');
    setAssessmentTourSteps(assessmentTourSteps(isOnAssessmentPage, modelAnswerToggle));
    assessmentTour.start();
  }, [assessmentTour, history, setAssessmentTourSteps, modelAnswerToggle]);

  const startExploreTour = useCallback(() => {
    const isOnExplorePage = history.location.pathname.endsWith('/explore');
    setExploreTourSteps(exploreTourSteps(isOnExplorePage));
    exploreTour.start();
  }, [exploreTour, history, setExploreTourSteps]);

  const startGroupsTour = useCallback(() => {
    const isOnGroupsPage = history.location.pathname.endsWith('/groups');
    setGroupTourSteps(groupsTourSteps(isOnGroupsPage));
    groupTour.start();
  }, [groupTour, history, setGroupTourSteps]);

  const startExerciseTour = useCallback(() => {
    const isOnExercisePage = history.location.pathname.endsWith('/exercises');
    setExerciseTour(exerciseTourSteps(isOnExercisePage, modelAnswerToggle));
    exerciseTour.start();
  }, [exerciseTour, history, setExerciseTour, modelAnswerToggle]);

  const startRubricsTour = useCallback(() => {
    const isOnRubricsPage = history.location.pathname.endsWith('/rubrics');
    setRubricsTourSteps(rubricsTourSteps(isOnRubricsPage));
    rubricsTour.start();
  }, [rubricsTour, history, setRubricsTourSteps]);

  const tours = [
    {
      title: lang.tours.introduction.title,
      subtitle: lang.tours.introduction.subtitle,
      icon: faUserGraduate,
      onClick: () => startAssessmentTour(),
    },
    {
      title: lang.tours.assessments.title,
      subtitle: lang.tours.assessments.subtitle,
      icon: faFileAlt,
      onClick: () => startCreateAssessmentTour(),
    },
    {
      title: lang.tours.explore.title,
      subtitle: lang.tours.explore.subtitle,
      icon: faMapMarkedAlt,
      onClick: () => startExploreTour(),
    },
    {
      title: lang.tours.groups.title,
      subtitle: lang.tours.groups.subtitle,
      icon: faUsers,
      onClick: () => startGroupsTour(),
    },
    {
      title: lang.tours.exercises.title,
      subtitle: lang.tours.exercises.subtitle,
      icon: faPencilRuler,
      onClick: () => startExerciseTour(),
    },
    {
      title: lang.tours.rubrics.title,
      subtitle: lang.tours.rubrics.subtitle,
      icon: faTable,
      onClick: () => startRubricsTour(),
    },
  ];

  return (
    <div data-tour="tour-hub" className={classes.wrapper}>
      <Dropdown
        close={() => setDropdown(false)}
        left top
      >
        <Tooltip tip={lang.tours.tip} place="left">
          <Button className={[classes.button, classes.shadowed]} onClick={() => setDropdown(!dropdown)}>
            <FontAwesomeIcon
              icon={faHandHoldingHand}
              size="2x"
            />
          </Button>
        </Tooltip>
        <DropdownMenu
          className={[classes.menu, classes.shadowed]}
          open={dropdown}
        >
          <div className={classes.menuHeader}>
            <h3 className={classes.menuHeaderTitle}>
              {lang.tours.title}
            </h3>
            {lang.tours.subtitles.map(subtitle => (
              <p key={subtitle} className={classes.menuHeaderParagraph}>
                {subtitle}
              </p>
            ))}
          </div>
          <div className={classes.separator}></div>
          {tours.map(guide => (
            <DropdownMenuItem
              key={guide.title}
              className={classes.menuItem}
            >
              <div className={classes.guideSection}>
                <FontAwesomeIcon
                  icon={guide.icon}
                  className={classes.guideIcon}
                />
                <div className={classes.guideContent}>
                  <div className={classes.guideTitle}>
                    {guide.title}
                  </div>
                  <div className={classes.guideSubtitle}>
                    {guide.subtitle}
                  </div>
                </div>
                <Button
                  className={classes.button}
                  onClick={guide.onClick}
                >
                  {lang.tours.startTour}
                </Button>
              </div>

            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default OnboardingButton;
