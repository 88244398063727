import { createUseStyles } from 'react-jss';

export default createUseStyles({
  inputArea: {
    borderRadius: 0,
    '&.color': {
      backgroundColor: '#FFFFFF',
    },
  },
  fakeBox: {
    width: '100%',
    minHeight: '100px',
    fontSize: '14px',
    resize: 'none',
    boxSizing: 'border-box',
    padding: '10px',
    border: '1px solid grey',
    boxShadow: 'none',
    transition: 'box-shadow 200ms',
    backgroundColor: 'rgb(232, 232, 232)',
    borderColor: 'rgba(118, 118, 118, 0.3)',
    color: 'grey',
    '& p': {
      marginTop: '0',
      marginBottom: '0',
    },
  },
  characterLimitWarning: {
    marginLeft: '0.5em',
    fontSize: '14px',
    color: 'rgba(118, 118, 118, 1)',
  },
  richText: {
    '& .ql-toolbar': {
      borderBottom: '1px solid #e1e1e1',
    },
    '& .ql-editor': {
      minHeight: '150px',
    },
    '& .quill': {
      borderRadius: '0 0 18px 18px',
    },
    '&.disabled': {
      borderRadius: '18px !important',
      '& .quill': {
        borderRadius: '18px !important',
      },
    },
  },
  richTextContainer: {
    position: 'relative',
  },
  wordCount: {
    fontSize: '14px',
    marginTop: '12px',
  },
});
