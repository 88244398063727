const colors = [
  '#FFC107',
  '#FF9800',
  '#FF8A65',
  '#fb790e',
  '#FF5722',
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#3F51B5',
  '#CDDC39',
  '#8BC34A',
  '#60993e',
  '#4CAF50',
  '#009688',
  '#03A9F4',
  '#2196F3',
  '#00BCD4',
  '#49e0e6',
];

export default colors;
