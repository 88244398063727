import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CorrectionGap from '../CorrectionGap';
import DraggableGap from '../DraggableGap';

import useStyles from './styles';

const Drop = ({ item, drop, answerable, correction, correctionItem }) => {
  const classes = useStyles();

  const { setNodeRef: drops, isOver } = useDroppable({
    id: drop.id,
    data: {
      type: 'gap',
      position: drop.position,
      gapCoords: drop.gapCoords,
      pointCoords: drop.pointCoords,
    },
  });

  return (
    <span style={{ display: 'inline-block', verticalAlign: 'middle', marginBottom: '0.25em' }}>
      <div
        className={cx(classes.drop, { isEmpty: !item, isOver }, 'gap')}
        ref={drops}
      >
        <Render when={item && !correction}>
          <DraggableGap
            item={item}
            draggable={answerable}
          />
        </Render>
        <Render when={correction}>
          <CorrectionGap
            item={item}
            correctionItem={correctionItem}
          />
        </Render>
      </div>
    </span>
  );
};

Drop.propTypes = {
  item: PropTypes.object,
  drop: PropTypes.object,
  answerable: PropTypes.bool,
  correction: PropTypes.bool,
  correctionItem: PropTypes.object,
};

export default Drop;
