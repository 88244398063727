import React, { useCallback, useState } from 'react';
import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toggle } from '@intuitivo/outline';
import PropTypes from 'prop-types';
import Xarrow, { Xwrapper, useXarrow } from 'react-xarrows';
import { v4 } from 'uuid';

import lang from 'lang';

import ExerciseConnectorCreate from '../../ExerciseConnectorCreate';
import FlowStep from '../FlowStep';
import Button from 'components/common/Button';

import useStyles from './styles';

const AskForConnections = ({ number, shuffleConnectors, setShuffleConnectors, connectors, setConnectors, connections, setConnections, restricted }) => {
  const classes = useStyles();

  const [activeLeft, setActiveLeft] = useState(null);

  const addConnector = (columnOrder) => {
    const copyConnectors = [...connectors];
    copyConnectors.push({ id: v4(), value: {}, columnOrder: columnOrder, identifier: 'connector_' + (copyConnectors.length + 1).toString() });
    const newConnectors = copyConnectors.map((connector, index) => ({ ...connector, order: index }));
    setConnectors(newConnectors);
  };

  const deleteConnector = (connectorId) => {
    setConnectors(connectors => {
      const copyConnectors = [...connectors];
      const newConnectors = copyConnectors.filter(el => el.id !== connectorId);
      return newConnectors.map((connector, index) => ({ ...connector, order: index }));
    });
    setConnections(connections => {
      return connections.filter(el => el.start !== connectorId && el.end !== connectorId);
    });
  };

  const deleteConnection = (connectionId) => {
    setConnections(connections => {
      return connections.filter(el => el.id !== connectionId);
    });
  };

  const updateConnector = (connectorId, value) => {
    setConnectors(connectors =>
      connectors.map(connector => connector.id === connectorId ? { ...connector, value } : connector),
    );
  };

  const setActive = useCallback((connectorId, columnOrder) => {
    if (columnOrder === 0) {
      if (activeLeft === connectorId) {
        setActiveLeft(null);

      } else {
        setActiveLeft(connectorId);
      }
    } else {
      if (!activeLeft) {
        return;
      }
      setActiveLeft(null);
      setConnections(connections => {
        const newConnections = [...connections];
        const duplicateCheck = newConnections.some(connection => connection.start === activeLeft && connection.end === connectorId);
        if (!duplicateCheck) {
          newConnections.push({
            id: v4(),
            start: activeLeft,
            end: connectorId,
          });
        }
        return newConnections;
      });
    }
  }, [activeLeft, setConnections]);

  return (
    <FlowStep
      stepNumber={number}
      subHeader={lang.exerciseForm.choicesTip}
      header={
        <div className={classes.headerWrapper}>
          <div>
            {lang.exerciseForm.connecting.connections}
          </div>
          {!restricted && (
            <div className={classes.toggleWrapper}>
              {lang.exerciseForm.connecting.shuffleConnectors}
              <Toggle
                checked={shuffleConnectors}
                onChange={(checked) => setShuffleConnectors(checked)}
              />
            </div>
          )}
        </div>
      }
    >
      <div onLoad={useXarrow()} className={classes.columnWrapper}>
        <div className={classes.column}>
          {connectors.filter(el => el.columnOrder === 0).map((connector, index) => (
            <ExerciseConnectorCreate
              key={connector.id}
              index={index}
              connector={connector}
              deleteConnector={deleteConnector}
              updateConnector={updateConnector}
              setAction={() => setActive(connector.id, 0)}
              used={connections.find(el => el.start === connector.id)}
              active={activeLeft === connector.id}
              left
              restricted={restricted}
            />
          ))}
          {
            !restricted && (
              <Button onClick={() => addConnector(0)}>
                <FontAwesomeIcon icon={faPlus} />
                <div className={classes.newChoiceText}>
                  {lang.exerciseForm.connecting.addConnector}
                </div>
              </Button>
            )
          }
        </div>
        <div className={classes.column}>
          {connectors.filter(el => el.columnOrder === 1).map((connector, index) => (
            <ExerciseConnectorCreate
              key={connector.id}
              index={index}
              connector={connector}
              deleteConnector={deleteConnector}
              updateConnector={updateConnector}
              setAction={() => setActive(connector.id, 1)}
              used={connections.find(el => el.end === connector.id)}
              restricted={restricted}
            />
          ))}
          {
            !restricted && (
              <Button onClick={() => addConnector(1)}>
                <FontAwesomeIcon icon={faPlus} />
                <div className={classes.newChoiceText}>
                  {lang.exerciseForm.connecting.addConnector}
                </div>
              </Button>
            )
          }
        </div>
        <Xwrapper>
          {connections.map(connection => (
            <Xarrow
              key={connection.id}
              start={connection.start}
              end={connection.end}
              showHead={false}
              curveness={0.15}
              lineColor={'#419bb4'}
              arrowBodyProps={{ onClick: () => deleteConnection(connection.id) }}
              SVGcanvasStyle={{ cursor: 'pointer' }}
              labels={{ middle: <FontAwesomeIcon icon={faCircleXmark} className={classes.arrowDelete} onClick={() => deleteConnection(connection.id)} ></FontAwesomeIcon> }}
            />
          ))}
        </Xwrapper>
      </div>
    </FlowStep>
  );
};

AskForConnections.propTypes = {
  number: PropTypes.number,
  shuffleConnectors: PropTypes.bool,
  setShuffleConnectors: PropTypes.func,
  connectors: PropTypes.array,
  setConnectors: PropTypes.func,
  connections: PropTypes.array,
  setConnections: PropTypes.func,
  restricted: PropTypes.bool,
};

export default AskForConnections;
