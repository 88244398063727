import React, { useState, useEffect, useCallback, Fragment, useMemo, useReducer } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { v4 } from 'uuid';

import { incrementTotalExercises, selectUserSubjects, selectUserTotalFreeExercises, selectUserYears } from 'actions/userActions';
import api from 'api';
import { BY_ROW } from 'constants/exerciseGradingOptions';
import { DRAG_DROP, DROPDOWN } from 'constants/exerciseOptions';
import { CAPTION, CHOICES, FILLING, ORDERING, TEXT, TRUEFALSE, CONNECTING, SEGMENTATION, TABLE, IMAGE } from 'constants/exerciseTypes';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import useQuery from 'hooks/common/useQuery';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { cleanAndSortGroups, quillIsEmpty, shuffleArr } from 'utils';

import PlansPill from 'components/common/plans/PlansPill';
import expressions from 'components/common/rich-text/FormulaModal/expressions';
import Header from 'components/exercise-editor/Header';
import ExerciseLabels from 'components/exercises/exercise-bank/single-exercise/ExerciseLabels';
import ExerciseForm from 'components/exercises/exercise-form/ExerciseForm';
import { tableReducer } from 'components/exercises/exercise-form/flow/ask-for-table/AskForTable/reducer';

import useStyles from './styles';

const DEFAULT_TYPE = null;
const DEFAULT_OPTION = DRAG_DROP;
const DEFAULT_GRADING_OPTION = BY_ROW;
const DEFAULT_STATEMENT = { ops: [] };
const DEFAULT_GAPS = [];
const DEFAULT_IMAGE = null;
const DEFAULT_CORRECT_TRUE_FALSE = null;
const DEFAULT_HAS_JUSTIFICATION = false;
const DEFAULT_IS_SHORT_ANSWER = false;
const DEFAULT_CHARACTER_LIMIT = null;
const DEFAULT_SEGMENTATION_CONNECTORS = [];
const DEFAULT_CONNECTIONS = [];
const DEFAULT_EXTRA_TEXT = { ops: [] };
const DEFAULT_MODEL_ANSWER = null;
const DEFAULT_EXTRA_TEXT_START_EXPANDED = false;
const DEFAULT_SHUFFLE_CHOICES = false;
const DEFAULT_ENABLE_MATH_SYMBOLS = false;
const DEFAULT_WORD_COUNT = true;
const DEFAULT_TABLE_CELLS = [
  {
    id: v4(),
    row: 0,
    col: 0,
    value: null,
    identifier: 'A_0',
    isCorrect: null,
  },
  {
    id: v4(),
    row: 1,
    col: 0,
    value: null,
    identifier: 'A_1',
    isCorrect: null,
  },
  {
    id: v4(),
    row: 2,
    col: 0,
    value: null,
    identifier: 'A_2',
    isCorrect: null,
  },
  {
    id: v4(),
    row: 0,
    col: 1,
    value: null,
    identifier: 'B_0',
    isCorrect: null,
  },
  {
    id: v4(),
    row: 1,
    col: 1,
    value: null,
    identifier: 'B_1',
    isCorrect: false,
    originalIdentifier: 'B_1',
  },
  {
    id: v4(),
    row: 2,
    col: 1,
    value: null,
    identifier: 'B_2',
    isCorrect: false,
    originalIdentifier: 'B_2',
  },
  {
    id: v4(),
    row: 0,
    col: 2,
    value: null,
    identifier: 'C_0',
    isCorrect: null,
  },
  {
    id: v4(),
    row: 1,
    col: 2,
    value: null,
    identifier: 'C_1',
    isCorrect: false,
    originalIdentifier: 'C_1',
  },
  {
    id: v4(),
    row: 2,
    col: 2,
    value: null,
    identifier: 'C_2',
    isCorrect: false,
    originalIdentifier: 'C_2',
  },
];

const ExerciseEditor = () => {
  const { loaded } = usePageLogic(lang.exercises.title, true);
  const classes = useStyles();
  const query = useQuery();
  const { spaceId, exerciseId } = useParams();
  const history = useHistory();
  const toast = useToast();
  const exploreToggle = useFeature(toggles.explore);
  const userSubjects = useSelector(selectUserSubjects);
  const userYears = useSelector(selectUserYears);
  const orderingToggle = useFeature(toggles.ordering);
  const captionToggle = useFeature(toggles.caption);
  const tableExerciseToggle = useFeature(toggles.tableExercise);
  const [getTemplateExerciseRequest] = useApi(api.getTemplateExercise);
  const [getSchoolGroupsRequest] = useApi(api.getSchoolGroups);
  const [getGroupLabelsRequest] = useApi(api.getGroupLabels);
  const [editTemplateExerciseRequest] = useApi(api.editTemplateExercise);
  const [createTemplateExerciseRequest] = useApi(api.createTemplateExercise);
  const dispatch = useDispatch();
  const totalFreeExercises = useSelector(selectUserTotalFreeExercises);
  const createExerciseToggle = useFeature(toggles.createExercise, totalFreeExercises);
  const connectingToggle = useFeature(toggles.connecting);
  const segmentationToggle = useFeature(toggles.segmentation);

  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(null);
  const [labels, setLabels] = useState([]);
  const [exerciseLabels, setExerciseLabels] = useState([]);
  const [type, setType] = useState(DEFAULT_TYPE);
  const [option, setOption] = useState(DEFAULT_OPTION);
  const [gradingOption, setGradingOption] = useState(DEFAULT_GRADING_OPTION);
  const [statement, setStatement] = useState(DEFAULT_STATEMENT);
  const [gaps, setGaps] = useState(DEFAULT_GAPS);
  const [image, setImage] = useState(DEFAULT_IMAGE);
  const [correctTrueFalse, setCorrectTrueFalse] = useState(DEFAULT_CORRECT_TRUE_FALSE);
  const [hasJustification, setHasJustification] = useState(DEFAULT_HAS_JUSTIFICATION);
  const [isShortAnswer, setIsShortAnswer] = useState(DEFAULT_IS_SHORT_ANSWER);
  const [characterLimit, setCharacterLimit] = useState(DEFAULT_CHARACTER_LIMIT);
  const [extraText, setExtraText] = useState(DEFAULT_EXTRA_TEXT);
  const [extraTextStartExpanded, setExtraTextStartExpanded] = useState(DEFAULT_EXTRA_TEXT_START_EXPANDED);
  const [shuffleChoices, setShuffleChoices] = useState(DEFAULT_SHUFFLE_CHOICES);
  const [connections, setConnections] = useState(DEFAULT_CONNECTIONS);
  const [enableMathSymbols, setEnableMathSymbols] = useState(DEFAULT_ENABLE_MATH_SYMBOLS);
  const [mathSymbols, setMathSymbols] = useState(expressions);
  const [modelAnswer, setModelAnswer] = useState(DEFAULT_MODEL_ANSWER);
  const [exerciseCells, tableDispatcher] = useReducer(tableReducer, DEFAULT_TABLE_CELLS);
  const [hasWordCount, setHasWordCount] = useState(DEFAULT_WORD_COUNT);

  const DEFAULT_CHOICES = useMemo(() => [
    { id: v4(), value: {}, isCorrect: false, identifier: 'choice_1', order: 0 },
    { id: v4(), value: {}, isCorrect: false, identifier: 'choice_2', order: 1 },
    { id: v4(), value: {}, isCorrect: false, identifier: 'choice_3', order: 2 },
    { id: v4(), value: {}, isCorrect: false, identifier: 'choice_4', order: 3 },
  ], []);

  const DEFAULT_ORDER_ITEMS = useMemo(() => [
    { id: v4(), text: {}, identifier: 'order_1', order: 0 },
    { id: v4(), text: {}, identifier: 'order_2', order: 1 },
    { id: v4(), text: {}, identifier: 'order_3', order: 2 },
    { id: v4(), text: {}, identifier: 'order_4', order: 3 },
  ], []);

  const DEFAULT_CONNECTORS = useMemo(() => [
    { id: v4(), value: {}, columnOrder: 0, identifier: 'connector_1', order: 0 },
    { id: v4(), value: {}, columnOrder: 0, identifier: 'connector_2', order: 1 },
    { id: v4(), value: {}, columnOrder: 0, identifier: 'connector_3', order: 2 },
    { id: v4(), value: {}, columnOrder: 1, identifier: 'connector_4', order: 3 },
    { id: v4(), value: {}, columnOrder: 1, identifier: 'connector_5', order: 4 },
    { id: v4(), value: {}, columnOrder: 1, identifier: 'connector_6', order: 5 },
  ], []);

  const types = useMemo(() => [
    { id: CHOICES, label: lang.exerciseForm.typeChoices },
    { id: TRUEFALSE, label: lang.exerciseForm.typeTrueFalse },
    { id: TEXT, label: lang.exerciseForm.typeText },
    { id: IMAGE, label: lang.exerciseForm.typeImage },
    { id: FILLING, label: lang.exerciseForm.typeFilling },
    { id: ORDERING, label: lang.exerciseForm.typeOrdering, hide: !orderingToggle },
    { id: CAPTION, label: lang.exerciseForm.typeCaption, hide: !captionToggle },
    { id: CONNECTING, label: lang.exerciseForm.typeConnecting },
    { id: SEGMENTATION, label: lang.exerciseForm.typeSegmentation },
    { id: TABLE, label: lang.exerciseForm.typeTable, hide: !tableExerciseToggle },
  ], [orderingToggle, captionToggle, tableExerciseToggle]);

  const [choices, setChoices] = useState(DEFAULT_CHOICES);
  const [previewChoices, setPreviewChoices] = useState(DEFAULT_CHOICES);
  const [orderItems, setOrderItems] = useState(DEFAULT_ORDER_ITEMS);
  const [connectors, setConnectors] = useState(DEFAULT_CONNECTORS);

  const [exerciseSettings, setExerciseSettings] = useState({
    title: '',
    isPublic: exploreToggle,
    subjects: userSubjects.map(userSubject => userSubject.id),
    years: userYears.map(userYear => userYear.id),
  });

  const reset = () => {
    setType(DEFAULT_TYPE);
    setOption(DEFAULT_OPTION);
    setGradingOption(DEFAULT_GRADING_OPTION);
    setGaps(DEFAULT_GAPS);
    setImage(DEFAULT_IMAGE);
    setCorrectTrueFalse(DEFAULT_CORRECT_TRUE_FALSE);
    setHasJustification(DEFAULT_HAS_JUSTIFICATION);
    setIsShortAnswer(DEFAULT_IS_SHORT_ANSWER);
    setCharacterLimit(DEFAULT_CHARACTER_LIMIT);
    setChoices(DEFAULT_CHOICES);
    setOrderItems(DEFAULT_ORDER_ITEMS);
    setShuffleChoices(DEFAULT_SHUFFLE_CHOICES);
    setConnectors(DEFAULT_CONNECTORS);
    setConnections(DEFAULT_CONNECTIONS);
    setModelAnswer(DEFAULT_MODEL_ANSWER);
    setMathSymbols(expressions);
    tableDispatcher({ type: 'SET_CELLS', payload: DEFAULT_TABLE_CELLS });
  };

  const _setImage = (img) => {
    setGaps([]);
    setConnectors([]);
    setImage(img);
  };

  const fetchTemplateExercise = useCallback((groups) => {
    getTemplateExerciseRequest([exerciseId], null, ({ data }) => {
      if (data.status === 0) {
        loaded();

        setStatement(data.exercise.statement);
        setEnableMathSymbols(data.exercise.enableMathSymbols);
        setCorrectTrueFalse(data.exercise.correctTrueFalse);
        setExerciseLabels(data.exercise.labels);
        setHasJustification(data.exercise.hasJustification);
        setImage(data.exercise.image);
        setIsShortAnswer(data.exercise.isShortAnswer);
        setCharacterLimit(data.exercise.characterLimit);
        setExtraText(data.exercise.extraText || DEFAULT_EXTRA_TEXT);
        setExtraTextStartExpanded(data.exercise.extraTextStartExpanded);
        setShuffleChoices(data.exercise.shuffleChoices);
        setMathSymbols(data.exercise.mathSymbols ?? expressions);
        setModelAnswer(data.exercise.modelAnswer);
        setHasWordCount(data.exercise.hasWordCount);
        if (data.exercise.type === CHOICES) {
          setPreviewChoices(data.exercise.choices);
          const orderedChoices = [...data.exercise.choices];
          setChoices(orderedChoices.sort((a, b) => {
            if (a.order !== null) {
              return a.order - b.order;
            }
            return new Date(a.createdAt) - new Date(b.createdAt);
          }));
        }
        if (data.exercise.type === FILLING) {
          setGaps(data.exercise.gaps);
          setOption(data.exercise.option);
        }
        if (data.exercise.type === ORDERING) {
          const orderedOrderItems = [...data.exercise.orderItems];
          setOrderItems(orderedOrderItems.sort((a, b) => a.order - b.order));
        }
        if (data.exercise.type === CAPTION) {
          setGaps(data.exercise.gaps);
          setOption(data.exercise.option);
        }
        if (data.exercise.type === CONNECTING || data.exercise.type === SEGMENTATION) {
          setConnectors(data.exercise.connectors);
          setConnections(data.exercise.connections);
          setOption(data.exercise.option);
        }
        if (data.exercise.type === TABLE) {
          setGradingOption(data.exercise.gradingOption);
          setChoices(data.exercise.choices);
          tableDispatcher({ type: 'SET_CELLS', payload: data.exercise.exerciseCells });
        }
        if (data.exercise.isPublic) {
          setExerciseSettings({
            title: data.exercise.title,
            isPublic: true,
            subjects: data.exercise.subjects.map(el => el.id),
            years: data.exercise.years.map(el => el.id),
          });
        } else {
          setExerciseSettings(e => ({
            ...e,
            title: data.exercise.title,
            isPublic: false,
          }));
        }

        const group = groups.find(group => group.id === data.exercise.groupId);
        setGroup(group);

        const type = types.find(type => type.id === data.exercise.type);
        setType(type);

        history.replace({
          search: `?type=${type.id}&group=${group.id}`,
        });
      }
    });
  }, [getTemplateExerciseRequest, exerciseId, history, loaded, types]);

  const fetchGroups = useCallback(() => {
    getSchoolGroupsRequest([spaceId], null, ({ data }) => {
      if (data.status === 0) {
        const newGroups = cleanAndSortGroups(data.groups);
        setGroups(newGroups);

        if (!exerciseId) {
          const currentGroupId = query().get('group');
          const group = newGroups.find(group => group.id === currentGroupId);
          setGroup(group);

          const typeId = query().get('type');
          const currentType = types.find(type => type.id === typeId);
          setType(currentType || types[0]);

          loaded();
          return;
        }

        fetchTemplateExercise(newGroups);
      }
    });
  }, [getSchoolGroupsRequest, loaded, query, spaceId, types, exerciseId, fetchTemplateExercise]);

  const fetchLabels = useCallback(() => {
    if (group) {
      getGroupLabelsRequest([group.id], null, ({ data }) => {
        if (data.status === 0) {
          setLabels(data.labels);
        }
      });
    }
  }, [getGroupLabelsRequest, group]);

  const afterSaveHandler = ({ data }) => {
    if (data.status === 0) {
      if (spaceId === PERSONAL_SPACE) {
        dispatch(incrementTotalExercises(1));
      }
      toast.success(exerciseId ? lang.exerciseForm.successExerciseEdited : lang.exerciseForm.successExerciseCreated);
      history.push({
        pathname: routes.exercises.ref(spaceId),
        search: `?group=${group.id}`,
      });
    } else {
      toast.error(lang.oops);
    }

    setLoading(false);
  };

  const buildExercise = () => {
    const newLabels = exerciseLabels.map(label => label.id);

    const newExercise = {
      statement: statement,
      enableMathSymbols: enableMathSymbols,
      type: type.id,
      groupId: group.id,
      labels: newLabels,
      hasJustification: hasJustification,
      option: option,
      gradingOption: gradingOption,
      extraText: extraText,
      extraTextStartExpanded: extraTextStartExpanded,
      shuffleChoices: shuffleChoices,
      mathSymbols: mathSymbols,
      modelAnswer,
      hasWordCount,
    };

    if (quillIsEmpty(statement)) {
      toast.warning(lang.exerciseForm.noEmptyStatement);
      return false;
    }

    if (type.id === TEXT) {
      if (isShortAnswer && !characterLimit) {
        toast.warning(lang.exerciseForm.characterLimitError);
        return false;
      }
      newExercise.isShortAnswer = isShortAnswer;
      newExercise.characterLimit = characterLimit;
    }

    if (type.id === CHOICES) {
      let hasCorrect = false;
      for (let i = 0; i < choices.length; i++) {
        if (quillIsEmpty(choices[i].value)) {
          toast.warning(lang.exerciseForm.noEmptyOption);
          return false;
        }

        hasCorrect = hasCorrect || choices[i].isCorrect;
      }

      if (!hasCorrect) {
        toast.warning(lang.exerciseForm.noAnswerChoiceDefined);
        return false;
      }

      newExercise.choices = choices.map(choice => ({
        value: choice.value,
        isCorrect: choice.isCorrect,
        identifier: choice.identifier,
        order: choice.order,
      }));
    }

    if (type.id === TRUEFALSE) {
      if (correctTrueFalse === null) {
        toast.warning(lang.exerciseForm.noAnswerChoiceDefined);
        return false;
      }

      newExercise.correctTrueFalse = correctTrueFalse;
    }

    if (type.id === FILLING) {
      const correctGaps = gaps.filter(gap => gap.isCorrect);

      if (correctGaps.length === 0) {
        toast.warning(lang.exerciseForm.noAnswerGapDefined);
        return false;
      }

      if (option === DROPDOWN) {
        for (let i = 0; i < correctGaps.length; i++) {
          const correctGap = correctGaps[i];
          const optionGaps = gaps.filter(gap => !gap.isCorrect && gap.position === correctGap.position);
          if (optionGaps.length === 0) {
            toast.warning(lang.exerciseForm.noExtraWordDefined);
            return false;
          }
        }
      }

      newExercise.gaps = gaps.map(gap => ({
        text: gap.text,
        position: gap.position,
        isCorrect: gap.isCorrect,
        order: gap.order ?? null,
        identifier: gap.identifier,
      }));
    }

    if (type.id === ORDERING) {
      const newOrderItems = [];

      for (let i = 0; i < orderItems.length; i++) {
        const item = orderItems[i];

        if (quillIsEmpty(item.text)) {
          toast.warning(lang.exerciseForm.noEmptyItem);
          return false;
        }

        newOrderItems.push({
          text: item.text,
          order: i,
          identifier: item.identifier,
        });
      }
      newExercise.orderItems = newOrderItems;
    }

    if (type.id === CAPTION) {
      const correctGaps = gaps.filter(gap => gap.isCorrect);

      if (correctGaps.length === 0) {
        toast.warning(lang.exerciseForm.noAnswerGapDefined);
        return false;
      }

      for (let i = 0; i < correctGaps.length; i++) {
        const correctGap = correctGaps[i];

        if (correctGap.text.trim() === '') {
          toast.warning(lang.exerciseForm.noEmptyItem);
          return false;
        }

        if (option === DROPDOWN) {
          const optionGaps = gaps.filter(gap => !gap.isCorrect && gap.gapCoords.x === correctGap.gapCoords.x && gap.gapCoords.y === correctGap.gapCoords.y);
          if (optionGaps.length === 0) {
            toast.warning(lang.exerciseForm.noExtraWordDefined);
            return false;
          }
        }
      }

      newExercise.gaps = gaps.map(gap => ({
        text: gap.text,
        gapCoords: gap.gapCoords,
        pointCoords: gap.pointCoords,
        isCorrect: gap.isCorrect,
        order: gap.order ?? null,
        identifier: gap.identifier,
      }));

      newExercise.image = image;
    }

    if (type.id === CONNECTING) {
      if (connectors.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectors);
        return false;
      }

      if (connectors.filter(el => el.columnOrder === 0).length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectorsLeft);
        return false;
      }

      if (connectors.filter(el => el.columnOrder === 1).length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectorsRight);
        return false;
      }

      for (let i = 0; i < connectors.length; i++) {
        if (quillIsEmpty(connectors[i].value)) {
          toast.warning(lang.exerciseForm.connecting.noEmptyConnector);
          return false;
        }
      }

      if (connections.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnections);
        return false;
      }

      newExercise.connectors = connectors.map(connector => ({
        value: connector.value,
        columnOrder: connector.columnOrder,
        identifier: connector.identifier,
        order: connector.order,
      }));

      newExercise.connections = connections.map(connection => ({
        start: connectors.find(el => el.id === connection.start).order,
        end: connectors.find(el => el.id === connection.end).order,
      }));
    }

    if (type.id === SEGMENTATION) {
      if (connectors.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnectors);
        return false;
      }

      if (connections.length === 0) {
        toast.warning(lang.exerciseForm.connecting.noConnections);
        return false;
      }

      newExercise.connectors = connectors.map(connector => ({
        value: { ops: [''] },
        coords: connector.coords,
        columnOrder: 0,
        identifier: connector.identifier,
        order: connector.order,
      }));

      newExercise.connections = connections.map(connection => ({
        start: connectors.find(el => el.id === connection.start).order,
        end: connectors.find(el => el.id === connection.end).order,
      }));

      newExercise.image = image;
    }

    if (type.id === TABLE) {
      const headerCells = exerciseCells.filter(cell => (cell.row === 0 && cell.col !== 0) || (cell.row !== 0 && cell.col === 0));
      for (let i = 0; i < headerCells.length; i++) {
        const headerCell = headerCells[i];
        if (quillIsEmpty(headerCell.value)) {
          toast.warning(lang.exerciseForm.table.noEmptyHeaderCell);
          return false;
        }
      }

      newExercise.exerciseCells = exerciseCells.map(cell => ({
        value: cell.value,
        row: cell.row,
        col: cell.col,
        identifier: cell.identifier,
        isCorrect: cell.isCorrect,
        exerciseChoiceId: cell.exerciseChoiceId,
      }));
    }

    if (exploreToggle && exerciseSettings.title === '') {
      toast.warning(lang.exerciseForm.exerciseSettings.errorTitleRequired);
      return;
    }

    if (exploreToggle && exerciseSettings.isPublic && exerciseSettings.subjects.length === 0) {
      toast.warning(lang.exerciseForm.exerciseSettings.errorSubjectRequired);
      return;
    }

    if (exploreToggle && exerciseSettings.isPublic && exerciseSettings.years.length === 0) {
      toast.warning(lang.exerciseForm.exerciseSettings.errorYearRequired);
      return;
    }

    newExercise.settings = {
      title: exerciseSettings.title,
      isPublic: exerciseSettings.isPublic,
      subjects: exerciseSettings.subjects,
      years: exerciseSettings.years,
    };

    return newExercise;
  };

  const saveExercise = () => {
    const newExercise = buildExercise();

    if (!newExercise) {
      return;
    }

    setLoading(true);

    if (exerciseId) {
      editTemplateExerciseRequest([exerciseId], newExercise, afterSaveHandler);
      return;
    }

    createTemplateExerciseRequest([], newExercise, afterSaveHandler);
  };

  const onCancel = () => {
    history.push({
      pathname: routes.exercises.ref(spaceId),
      search: `?group=${group.id}`,
    });
  };

  const _setType = (newType) => {
    reset();
    setType(newType);
  };

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    fetchLabels();
  }, [fetchLabels]);

  useEffect(() => {
    const newPreviewChoices = [...choices];
    if (shuffleChoices) {
      shuffleArr(newPreviewChoices);
    }
    setPreviewChoices(newPreviewChoices);
  }, [choices, shuffleChoices]);

  useEffect(() => {
    if (type && type.id === SEGMENTATION && !exerciseId) {
      setConnectors(DEFAULT_SEGMENTATION_CONNECTORS);
      setOption(null);
    }
  }, [type, exerciseId]);

  if (!createExerciseToggle && !exerciseId) {
    history.push(routes.exercises.ref(spaceId));
    return false;
  }

  if (!group || !type) {
    return null;
  }

  return (
    <Fragment>
      <Header
        groups={groups}
        group={group}
        setGroup={setGroup}
        types={types.filter(el => !el.hide)}
        type={type}
        setType={_setType}
        onCancel={onCancel}
        saveExercise={saveExercise}
        loading={loading}
        invisible
      />
      <div className={classes.exerciseLabels}>
        {((type.id === CONNECTING && !connectingToggle) || (type.id === SEGMENTATION && !segmentationToggle)) && (
          <div className={classes.premiumPill}>
            <PlansPill
              tip={lang.plans.warningCreatePremiumExercise}
            />
          </div>
        )}
        <ExerciseLabels
          groupId={group.id}
          exerciseLabels={exerciseLabels}
          labelOptions={labels}
          refresh={fetchTemplateExercise}
          fetchLabels={fetchLabels}
          labelAction={setExerciseLabels}
        />
      </div>
      <ExerciseForm
        type={type.id}
        statement={statement}
        enableMathSymbols={enableMathSymbols}
        setEnableMathSymbols={setEnableMathSymbols}
        setStatement={setStatement}
        choices={choices}
        shuffleChoices={shuffleChoices}
        setShuffleChoices={setShuffleChoices}
        previewChoices={previewChoices}
        setChoices={setChoices}
        gaps={gaps}
        setGaps={setGaps}
        orderItems={orderItems}
        setOrderItems={setOrderItems}
        correctTrueFalse={correctTrueFalse}
        setCorrectTrueFalse={setCorrectTrueFalse}
        hasJustification={hasJustification}
        setHasJustification={setHasJustification}
        isShortAnswer={isShortAnswer}
        setIsShortAnswer={setIsShortAnswer}
        characterLimit={characterLimit}
        setCharacterLimit={setCharacterLimit}
        image={image}
        setImage={_setImage}
        option={option}
        setOption={setOption}
        gradingOption={gradingOption}
        setGradingOption={setGradingOption}
        extraText={extraText}
        setExtraText={setExtraText}
        extraTextStartExpanded={extraTextStartExpanded}
        setExtraTextStartExpanded={setExtraTextStartExpanded}
        connectors={connectors}
        setConnectors={setConnectors}
        connections={connections}
        setConnections={setConnections}
        mathSymbols={mathSymbols}
        setMathSymbols={setMathSymbols}
        exerciseSettings={exerciseSettings}
        setExerciseSettings={setExerciseSettings}
        modelAnswer={modelAnswer}
        setModelAnswer={setModelAnswer}
        exerciseCells={exerciseCells}
        tableDispatcher={tableDispatcher}
        hasWordCount={hasWordCount}
        setHasWordCount={setHasWordCount}
      />
    </Fragment>
  );
};

export default ExerciseEditor;
