import { createUseStyles } from 'react-jss';

export default createUseStyles({
  choice: {
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
});
