import React, { useState } from 'react';
import { DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Toggle } from '@intuitivo/outline';
import katex from 'katex';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import { macros } from 'constants/katexMacros';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { quillIsEmpty } from 'utils';

import FlowStep from '../../FlowStep';
import DraggableExtraGap from '../DraggableExtraGap';
import ExtraGap from '../ExtraGap';
import Button from 'components/common/Button';
import Input from 'components/common/Input';

import useStyles from './styles';

const AskForExtraGaps = ({ number, subNumber, gap, gaps, type, setGaps, dropdown, restricted }) => {
  const classes = useStyles();
  const orderedOptionsToggle = useFeature(toggles.orderDropdown);

  const [words, setWords] = useState(null);
  const [ordered, setOrdered] = useState(orderedOptionsToggle && gaps.filter(gap => gap.order).length > 0 ? true : false);
  const [active, setActive] = useState(null);

  const addGap = () => {
    let newGap = null;

    if (type === 'caption') {
      if (!words) {
        return;
      }
      newGap = {
        id: v4(),
        text: words,
        ...(gap ? { gapCoords: gap.gapCoords } : {}),
        ...(gap ? { pointCoords: gap.pointCoords } : {}),
        order: ordered ? gaps.length : null,
        identifier: dropdown ? `${gap.identifier}_option_${gaps.length + 1}` : `caption_${gaps.length + 1}`,
      };
    } else {
      if (quillIsEmpty(words)) {
        return;
      }

      newGap = {
        id: v4(),
        text: JSON.stringify(words.ops),
        position: gap ? gap.position : null,
        order: ordered ? (gaps.length) : null,
        identifier: dropdown ? `${gap.identifier}_option_${gaps.length + 1}` : `gap_${gaps.length + 1}`,
      };

    }
    setWords(null);
    setGaps(gaps => [...gaps, newGap]);
  };

  const areGapsAssociated = (gap1, gap2) => {
    if (gap1.position) {
      return gap1.position === gap2.position;
    } else if (gap1.gapCoords && gap2.gapCoords) {
      return gap1.gapCoords.x === gap2.gapCoords.x && gap1.gapCoords.y === gap2.gapCoords.y;
    }
  };

  const removeGap = (gapId) => {
    setGaps(gaps => (
      gaps.filter(el => el.id !== gapId).map((oldGap, index) => ({
        ...oldGap,
        order: dropdown ? (ordered && areGapsAssociated(oldGap, gap)) ? index : !areGapsAssociated(oldGap, gap) && oldGap.order !== null ? oldGap.order : null : null,
      }))
    ));
  };

  const renderGap = (word) => {
    if (type === 'caption') {
      return word.text;
    }
    const parsedWord = JSON.parse(word.text);

    if (typeof parsedWord.insert === 'string') {
      return parsedWord.insert;
    } else if (parsedWord.formula) {
      return katex.renderToString(parsedWord.formula, { macros });
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    setActive(null);

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      setGaps((gaps) => {
        const oldIndex = gaps.map(el => el.id).indexOf(active.id);
        const newIndex = gaps.map(el => el.id).indexOf(over.id);

        const orderedGaps = arrayMove(gaps, oldIndex, newIndex);
        return orderedGaps.map((oldGap, index) => ({
          ...oldGap,
          order: (ordered && areGapsAssociated(oldGap, gap)) ? index : !areGapsAssociated(oldGap, gap) && oldGap.order !== null ? oldGap.order : null,
        }));
      });
    }
  };

  const handleDragStart = (event) => {
    const { active } = event;
    setActive({
      item: gaps.find(el => el.id === active.id),
      index: gaps.map(el => el.id).indexOf(active.id),
    });
  };

  const _setOrdered = (value) => {
    setOrdered(value);
    setGaps(oldGaps => {
      return oldGaps.map((oldGap, index) => {
        return {
          ...oldGap,
          order: (value && areGapsAssociated(oldGap, gap)) ? index : !areGapsAssociated(oldGap, gap) && oldGap.order !== null ? oldGap.order : null,
        };
      });
    });
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  return (
    <FlowStep
      stepNumber={number}
      subStepNumber={subNumber ?? null}
      header={gap ? (
        <div className={classes.headerWrapper}>
          <div className={classes.headerFormulaWrapper}>
            {restricted ? lang.exerciseForm.gap.editGapOptions : lang.exerciseForm.gap.addGapOptions}
            <div className={classes.formula} dangerouslySetInnerHTML={{ __html: renderGap(gap) }} />
          </div>
          {orderedOptionsToggle && !restricted && (
            <div className={classes.toggleWrapper}>
              {lang.exerciseForm.gap.orderGaps}
              <Toggle
                checked={ordered}
                onChange={(checked) => _setOrdered(checked)}
              />
            </div>
          )}
        </div>)
        : (restricted ? lang.exerciseForm.gap.editExtraGaps : lang.exerciseForm.gap.addExtraGaps)}
      subHeader={restricted ? lang.exerciseForm.gap.editExtraGapsSub : lang.exerciseForm.gap.addExtraGapsSub}
    >
      <div className={classes.wrapper}>
        {!restricted && (
          <div className={classes.richTextWrapper}>
            <Input
              key={gaps}
              type={type === 'caption' ? 'textarea' : 'richtext'}
              value={words}
              onChange={(value) => setWords(type === 'caption' ? value.target.value : value)}
              rows={1}
            />
            <Button
              className={classes.button}
              onClick={addGap}
            >
              {lang.exerciseForm.gap.askForExtraGapsButton}
            </Button>
          </div>
        )}
        {restricted && !dropdown && gaps.filter(gap => !gap.isCorrect).length === 0 && (
          <div className={classes.noExtraGaps}>
            {lang.exerciseForm.gap.noExtraGaps}
          </div>
        )}
        <DndContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
        >
          <SortableContext
            items={gaps}
            strategy={verticalListSortingStrategy}
          >
            <div className={classes.gapContainer}>
              {(dropdown ? gaps : gaps.filter(gap => !gap.isCorrect)).map((gap, index) => (
                <DraggableExtraGap
                  key={gap.id}
                  id={gap.id}
                  item={gap}
                  index={index}
                  type={type}
                  removeGap={removeGap}
                  draggable={ordered}
                  restricted={restricted}
                  setGaps={setGaps}
                />
              ))}
            </div>
          </SortableContext>
          <DragOverlay>
            {active ? <ExtraGap type={type} item={active.item} index={active.index} isDragging /> : null}
          </DragOverlay>
        </DndContext>
      </div>
    </FlowStep>
  );
};

AskForExtraGaps.propTypes = {
  number: PropTypes.number,
  subNumber: PropTypes.number,
  gap: PropTypes.object,
  gaps: PropTypes.array,
  setGaps: PropTypes.func,
  type: PropTypes.string,
  dropdown: PropTypes.bool,
  restricted: PropTypes.bool,
};

export default AskForExtraGaps;
