import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles from './styles';

const EntityContent = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.exerciseContent, className)}>
      {children}
    </div>
  );
};

EntityContent.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default EntityContent;
