import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectAttempt, selectAttemptEndedAt, setAttempt } from 'actions/attemptActions';
import { selectTestPublication } from 'actions/testActions';
import api from 'api';
import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import StudentAttemptHeader from '../StudentAttemptHeader';
import Loading from 'components/common/Loading';
import GradeableExercise from 'components/test/tabs/grades-tab/GradeableExercise';
import GradeableSection from 'components/test/tabs/grades-tab/GradeableSection';

import useStyles from './styles';

const GradeableAttempt = ({ attemptId, classificationType, student, setStudents, total, testHasEnded, canBeFetch, setCanBeFetch }) => {
  const classes = useStyles();
  const attempt = useSelector(selectAttempt);
  const endedAt = useSelector(selectAttemptEndedAt);
  const publication = useSelector(selectTestPublication);
  const dispatch = useDispatch();
  const [getFullAttemptRequest] = useApi(api.getFullAttempt);
  const [loadingAttempt, setLoadingAttempt] = useState(true);

  const fetchAttempt = useCallback(() => {
    getFullAttemptRequest([attemptId], null, ({ data }) => {
      setTimeout(() => {
        setLoadingAttempt(false);

        if (data.status === 0) {
          dispatch(setAttempt(data.attempt));
        }
      }, 200);
    });
  }, [getFullAttemptRequest, attemptId, dispatch]);

  useEffect(() => {
    setLoadingAttempt(true);
    if (attemptId && student.startedAt) {
      fetchAttempt();
    } else {
      dispatch(setAttempt({
        student: {
          name: student && student.fullName,
          email: student && student.email,
        },
      }));
      setLoadingAttempt(false);
    }
  }, [attemptId, fetchAttempt, dispatch, student]);

  useEffect(() => {
    if (!(attemptId && student.startedAt) || !canBeFetch) {
      return;
    }

    let intervalId;
    if (!endedAt && publication.isOngoing) {
      intervalId = setInterval(() => {
        fetchAttempt();
      }, 10000);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [attemptId, publication, fetchAttempt, student, endedAt, canBeFetch]);

  const getGrade = () => {
    if (!attempt.items) {
      return lang.test.grades.noAttempt;
    }
    let grade = 0;

    for (let i = 0; i < attempt.items.length; i++) {
      const item = attempt.items[i];
      if (item.itemType === 'section' && item.exercises) {
        for (let j = 0; j < item.exercises.length; j++) {
          grade += parseFloat(item.exercises[j].grade);
        }
      } else if (item.itemType === 'exercise') {
        grade += parseFloat(item.grade);
      }
    }

    const totalGrade = grade || grade === 0 ? grade.toFixed(2) : '??';
    const gradePercentage = grade || grade === 0 ? ((grade / total) * 100).toFixed(2) : '??';

    return `${gradePercentage || '??'}% (${totalGrade || '??'} / ${total})`;
  };

  const getExercises = () => {
    if (!attempt || !attempt.id) {
      return;
    }

    let exerciseCounter = 0;

    return attempt.items.map((item, index) => {

      if (item.itemType === 'section') {
        return (
          <GradeableSection
            key={item.id}
            sectionId={item.id}
            testHasEnded={testHasEnded}
            setStudents={setStudents}
            setCanBeFetch={setCanBeFetch}
          />
        );
      }
      if (![INFORMATION, PAUSE].includes(item.type)) {
        exerciseCounter++;
      }
      return (
        <div
          className={classes.exerciseContainer}
          key={item.id}
        >
          <GradeableExercise
            dataTour={`gradeable-attempt-${index}`}
            num={exerciseCounter}
            classificationType={classificationType}
            disabled={!testHasEnded && !endedAt}
            setStudents={setStudents}
            exerciseId={item.id}
            setCanBeFetch={setCanBeFetch}
          />
        </div>
      );
    });
  };

  if (loadingAttempt) {
    return (
      <Loading active />
    );
  }

  return (
    <div data-tour="gradeable-attempt-container">
      <StudentAttemptHeader
        attempt={attempt}
        student={student}
        classificationType={classificationType}
        grade={getGrade()}
        setStudents={setStudents}
      />
      {getExercises()}
    </div>
  );
};

GradeableAttempt.propTypes = {
  attemptId: PropTypes.string,
  classificationType: PropTypes.string,
  student: PropTypes.object,
  setStudents: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  testHasEnded: PropTypes.bool,
  canBeFetch: PropTypes.string,
  setCanBeFetch: PropTypes.func,
};

export default GradeableAttempt;
