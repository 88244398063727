import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  button: {
    margin: '20px 0',
    marginBottom: '5px',
    border: 'none',
  },
  icon: {
    cursor: 'pointer',
  },
  passwordWrapper: {
    marginTop: '20px',
  },
}));
