import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import useStyles from './styles';

const ExerciseGrade = ({ grade, maxGrade, hideGrade }) => {
  const classes = useStyles();

  return (
    <div className={classes.graderWrapper}>
      <div className={classes.maxGradeText}>
        <Render when={!hideGrade}>
          {grade || grade === 0 ? parseFloat(grade).toFixed(2) : '??'}
          {' / '}
        </Render>
        {parseFloat(maxGrade).toFixed(2)}
      </div>
      <div className={classes.pointsText}>
        {maxGrade === 1 ? lang.test.grades.point : lang.test.grades.points}
      </div>
    </div>
  );
};

ExerciseGrade.propTypes = {
  grade: PropTypes.number,
  maxGrade: PropTypes.number,
  hideGrade: PropTypes.bool,
};

export default ExerciseGrade;
