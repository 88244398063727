import lang from 'lang';
import { buildStep, buildOptions } from 'services/shepherd/utils';

export const options = buildOptions('assessments');

export const steps = (isOnAssessmentPage, modelAnswerToggle) => [
  {
    id: 'sidebar-assessments',
    attachOn: 'right',
    ...(
      isOnAssessmentPage
        ? {
          buttons: ['next'],
          text: lang.tours.assessments.steps.sidebar,
        }
        : {
          text: lang.tours.assessments.steps.sidebarGoToPage,
          advanceOnClick: true,
        }
    ),
  },
  {
    id: 'create-assessment',
    text: lang.tours.assessments.steps.createButton,
    attachOn: 'bottom',
    advanceOnClick: true,
    buttons: ['back'],
  },
  {
    id: 'assessment-name',
    text: lang.tours.assessments.steps.nameInput,
    attachOn: 'right',
    delayTime: 500,
    buttons: ['back', 'next'],
    canClickTarget: true,
  },
  {
    id: 'assessment-type',
    text: lang.tours.assessments.steps.typeInput,
    attachOn: 'right',
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-classification-type',
    text: lang.tours.assessments.steps.classificationInput,
    attachOn: 'right',
    canClickTarget: false,
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-group',
    text: lang.tours.assessments.steps.groupInput,
    attachOn: 'right',
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-submit',
    text: lang.tours.assessments.steps.submitButton,
    attachOn: 'right',
    buttons: ['back'],
    advanceOnClick: true,
  },
  {
    id: 'assessment-tab-exercises',
    text: lang.tours.assessments.steps.exercisesTab,
    attachOn: 'bottom',
    buttons: ['next'],
  },
  {
    id: 'assessment-tab-grades',
    text: lang.tours.assessments.steps.gradesTab,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-tab-physical-version',
    text: lang.tours.assessments.steps.physicalTab,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-content-form',
    text: lang.tours.assessments.steps.contentForm,
    attachOn: 'top',
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-create-new-exercise',
    text: lang.tours.assessments.steps.createNewExercise,
    attachOn: 'top',
    buttons: ['back'],
    advanceOnClick: true,
  },
  {
    id: 'assessment-create-exercise-type',
    text: lang.tours.assessments.steps.exerciseType,
    attachOn: 'top',
    buttons: ['next'],
  },
  {
    id: 'assessment-create-true-false-exercise',
    text: lang.tours.assessments.steps.trueOrFalseExerciseType,
    attachOn: 'top',
    buttons: ['back'],
    advanceOnClick: true,
  },
  {
    id: 'ask-for-statement',
    text: lang.tours.assessments.steps.exerciseStatement,
    attachOn: 'top',
    buttons: ['next'],
    canClickTarget: true,
  },
  {
    id: 'assessment-exercise-correct-answer',
    text: lang.tours.assessments.steps.exerciseCorrectAnswer,
    attachOn: 'top',
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-exercise-true-answer',
    text: lang.tours.assessments.steps.exerciseTrue,
    attachOn: 'top',
    buttons: ['back'],
    advanceOnClick: true,
  },
  {
    id: 'assessment-exercise-submit',
    text: lang.tours.assessments.steps.exerciseSubmit,
    attachOn: 'top',
    buttons: ['back'],
    advanceOnClick: true,
  },
  {
    id: 'assessment-exercise-grade',
    text: lang.tours.assessments.steps.exerciseGrade,
    attachOn: 'left',
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-exercise-edit',
    text: lang.tours.assessments.steps.exerciseEdit,
    attachOn: 'left',
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-exercise-delete',
    text: lang.tours.assessments.steps.exerciseDelete,
    attachOn: 'left',
    buttons: ['back', 'next'],
  },
  // publish
  {
    id: 'assessment-publish',
    text: lang.tours.assessments.steps.publishButton,
    attachOn: 'bottom',
    buttons: ['back'],
    advanceOnClick: true,
  },
  {
    id: 'assessment-publish-form-format',
    text: lang.tours.assessments.steps.publishFormFormat,
    attachOn: 'right',
    buttons: ['back', 'next'],
    delayTime: 500,
    canClickTarget: true,
  },
  {
    id: 'assessment-publish-form-start',
    text: lang.tours.assessments.steps.publishFormStart,
    attachOn: 'right',
    buttons: ['back', 'next'],
    canClickTarget: true,
  },
  {
    id: 'assessment-publish-form-tolerance',
    text: lang.tours.assessments.steps.publishFormTolerance,
    attachOn: 'right',
    buttons: ['back', 'next'],
    canClickTarget: true,
  },
  {
    id: 'assessment-publish-form-duration',
    text: lang.tours.assessments.steps.publishFormDuration,
    attachOn: 'right',
    buttons: ['back', 'next'],
    canClickTarget: true,
  },
  {
    id: 'assessment-publish-form-password',
    text: lang.tours.assessments.steps.publishFormPassword,
    attachOn: 'right',
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-publish-form-shuffle',
    text: lang.tours.assessments.steps.publishFormShuffle,
    attachOn: 'right',
    buttons: ['back', 'next'],
    canClickTarget: true,
  },
  {
    id: 'assessment-publish-form-feedback',
    text: lang.tours.assessments.steps.publishFormFeedback,
    attachOn: 'right',
    buttons: ['back', 'next'],
  },
  ...(modelAnswerToggle ? [
    {
      id: 'assessment-publish-form-model-answer',
      text: lang.tours.assessments.steps.publishFormModelAnswer,
      attachOn: 'right',
      buttons: ['back', 'next'],
    },
  ] : []),
  {
    id: 'assessment-publish-form-submit',
    text: lang.tours.assessments.steps.publishFormSubmit,
    attachOn: 'right',
    buttons: ['back'],
    advanceOnClick: true,
  },
  {
    id: 'assessment-publish-link',
    text: lang.tours.assessments.steps.publishLink,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  {
    id: 'assessment-publish-close-modal',
    text: lang.tours.assessments.steps.publishCloseModal,
    attachOn: 'bottom',
    buttons: ['back'],
    advanceOnClick: true,
  },
  {
    id: 'assessment-publish',
    text: lang.tours.assessments.steps.publishButtonFinal,
    attachOn: 'bottom',
    buttons: ['back', 'next'],
  },
  // final step
  {
    id: 'tour-hub',
    text: lang.tours.assessments.steps.hub,
    attachOn: 'top-left',
    buttons: ['back', 'complete'],
  },
].map(buildStep);
