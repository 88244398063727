import React, { useCallback, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const CLOSE_TIMEOUT_MS = 350;

const DeleteAttemptModal = ({ open, close, attempt, refresh }) => {
  const toast = useToast();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [deleteAttemptRequest] = useApi(api.deleteAttempt);

  const _close = useCallback((doRefresh = false) => {
    close();
    doRefresh && refresh();
  }, [close, refresh]);

  const deleteAttempt = useCallback(() => {
    setLoading(true);
    deleteAttemptRequest([attempt.attemptId], null, ({ data }) => {
      if (data.status === 0) {
        toast.success(lang.test.grades.successDeleteAttempt);
        setTimeout(() => {
          _close(true);
          setLoading(false);
        }, CLOSE_TIMEOUT_MS);
      } else {
        toast.error(lang.oops);
        _close();
      }
    });
  }, [_close, attempt.attemptId, deleteAttemptRequest, toast]);

  const actions = [
    {
      name: lang.delete,
      color: 'red',
      onClick: deleteAttempt,
      loading,
    },
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.test.deleteTest}
      actions={actions}
      center
      transition
      small
    >
      <div>
        <div className={classes.deleteTestInfo}>
          {lang.test.grades.deleteAttemptConfirm(attempt.fullName)}
        </div>
        <div className={cx(classes.deleteTestInfo, classes.warningMessage)}>
          {lang.test.grades.deleteAttemptConfirmSubtitle}
        </div>
      </div>
    </Modal>
  );
};

DeleteAttemptModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  attempt: PropTypes.shape({
    attemptId: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  }).isRequired,
  refresh: PropTypes.func.isRequired,
};

export default DeleteAttemptModal;
