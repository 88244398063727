import React, { useMemo, useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectAttemptNavigationItems } from 'actions/studentAttemptActions';
import { LINEAR, NON_LINEAR } from 'constants/navigationTypes';
import { FULL } from 'constants/presentationTypes';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Button from 'components/common/Button';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const AttemptActions = ({ nextItem, previousItem, finishTest, savingAnswer, isLast, finishLoading, navigation, isFirst, finishable, canMovePrevious, canMoveNext, writing, flushPendingAnswers, pendingAnswers, sectionId }) => {
  const classes = useStyles();
  const asyncAnswersToggle = useFeature(toggles.asyncAnswers);
  const toast = useToast();
  const items = useSelector(selectAttemptNavigationItems);
  const publicationPresentation = useSelector((state) => state.studentAttempt.testType);
  const publicationNavigation = useSelector((state) => state.studentAttempt.navigation);
  const currentBaseItemOrder = useSelector((state) => state.studentAttempt.currentItem);

  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showConfirmNext, setShowConfirmNext] = useState(false);
  const [showConfirmPrevious, setShowConfirmPrevious] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [flushLoading, setFlushLoading] = useState(false);
  const pendingAnswersWithError = useMemo(() => [...pendingAnswers.values()].some((answer) => answer.errors > 0), [pendingAnswers]);
  const isLoading = savingAnswer || finishLoading || flushLoading;

  const flaggedItemsNum = useMemo(() => {
    return items.reduce((acc, item) => {
      if (publicationPresentation !== FULL && publicationNavigation !== NON_LINEAR && currentBaseItemOrder !== item.order) {
        return acc;
      }

      if (item.itemType === 'exercise' && item.isFlagged && !sectionId) {
        acc++;
      }

      if (item.itemType === 'section' && (!sectionId || sectionId === item.id)) {
        acc += item.exercises?.reduce((acc, exercise) => {
          if (exercise.isFlagged && (item.presentation === FULL || item.navigation === NON_LINEAR || item.currentItem === exercise.order)) {

            acc++;
          }

          return acc;
        }, 0);
      }

      return acc;
    }, 0);
  }, [currentBaseItemOrder, items, publicationNavigation, publicationPresentation, sectionId]);

  const currentBaseItem = useMemo(() => {
    return items.find((item) => item.order === currentBaseItemOrder);
  }, [currentBaseItemOrder, items]);

  const onClickNext = async () => {
    let valid = true;
    if (asyncAnswersToggle) {
      setFlushLoading(true);
      const flushResults = await flushPendingAnswers(true);
      valid = flushResults.every((successful) => successful);
      setFlushLoading(false);
    }

    if (navigation === LINEAR || !valid) {
      setShowConfirmNext(true);
    } else if (navigation === NON_LINEAR) {
      nextItem();
    }
  };

  const onClickPrevious = async () => {
    let valid = true;
    if (asyncAnswersToggle) {
      setFlushLoading(true);
      const flushResults = await flushPendingAnswers(true);
      valid = flushResults.every((successful) => successful);
      setFlushLoading(false);
    }

    if (valid) {
      previousItem();
    } else {
      setShowConfirmPrevious(true);
    }
  };

  const onClickFinish = async () => {
    if (asyncAnswersToggle) {
      setFlushLoading(true);
      await flushPendingAnswers(true);
      setFlushLoading(false);
    }

    setShowCompleteModal(true);
  };

  const onClickConfirm = () => {
    if (showConfirmNext) {
      nextItem();
    } else if (showConfirmPrevious) {
      previousItem();
    }
  };

  const onClickCancel = () => {
    setShowConfirmNext(false);
    setShowConfirmPrevious(false);
  };

  const saveAnswers = async () => {
    setSaveLoading(true);
    const flushResults = await flushPendingAnswers(true);
    const valid = flushResults.every((successful) => successful);

    if (valid) {
      toast.success(lang.attempt.answersSaved);
      nextItem();
    } else {
      toast.error(lang.attempt.errorSaveAnswers);
    }

    setShowConfirmNext(false);
    setShowConfirmPrevious(false);
    setSaveLoading(false);
  };

  const modalActions = [
    {
      name: lang.save,
      color: 'gray',
      loading: saveLoading,
      onClick: saveAnswers,
      hide: !pendingAnswersWithError,
    },
    {
      name: lang.common.finish,
      color: 'red',
      loading: finishLoading,
      onClick: finishTest,
    },
    {
      name: lang.cancel,
      color: 'black',
      onClick: () => setShowCompleteModal(false),
    },
  ];

  return (
    <div className={classes.testNextComplete}>
      <Modal
        open={showCompleteModal}
        close={() => setShowCompleteModal(false)}
        header={lang.test.finishTest}
        actions={modalActions}
        center
        transition
      >
        {flaggedItemsNum > 0 && lang.attempt.flaggedItems(flaggedItemsNum) + ' '}
        {pendingAnswersWithError ? lang.attempt.unsavedAnswersFinish : lang.test.finishHintConfirm}
      </Modal>
      {!showConfirmNext && !showConfirmPrevious && navigation === NON_LINEAR && !isFirst && canMovePrevious && (
        <Button
          onClick={onClickPrevious}
          disabled={isLoading || writing}
          loading={isLoading}
          sibling={!isLast || finishable}
        >
          {lang.attempt.previousItem}
        </Button>
      )}
      {!showConfirmNext && !showConfirmPrevious && navigation !== null && !isLast && canMoveNext && (
        <Button
          onClick={onClickNext}
          disabled={isLoading || writing}
          loading={isLoading}
          sibling={navigation === NON_LINEAR && !isFirst}
        >
          {lang.attempt.nextItem}
        </Button>
      )}
      {isLast && finishable && canMoveNext && (
        <Button
          red
          onClick={onClickFinish}
          disabled={isLoading}
          loading={isLoading}
          sibling={navigation === NON_LINEAR}
        >
          {lang.test.finishTest}
        </Button>
      )}
      <div className={classes.confirmContainer}>
        {pendingAnswersWithError && (showConfirmNext || showConfirmPrevious) && (
          <>
            {lang.attempt.unsavedAnswers}
          </>
        )}
        {(showConfirmNext || showConfirmPrevious) && (
          <>
            {
              !pendingAnswersWithError ?
                <div>
                  {(currentBaseItem?.itemType === 'exercise' || sectionId || currentBaseItem?.navigation === LINEAR) && flaggedItemsNum > 0 && lang.attempt.itemFlagged + ' ' + lang.attempt.areYouSureFlag}
                  {currentBaseItem?.itemType === 'section' && !sectionId && currentBaseItem?.navigation !== LINEAR && flaggedItemsNum > 0 && lang.attempt.flaggedItems(flaggedItemsNum) + ' ' + lang.attempt.areYouSureFlag}
                  <Spacer px={20} />
                  <div className={classes.confirmButtons}>
                    <Button
                      black
                      onClick={onClickCancel}
                      disabled={savingAnswer}
                      sibling
                    >
                      {lang.cancel}
                    </Button>
                    <Button
                      onClick={onClickConfirm}
                      disabled={isLoading}
                      loading={isLoading}
                      sibling
                    >
                      {lang.confirm}
                    </Button>
                  </div>
                </div>
                :
                <div className={classes.confirmButtons}>
                  <Button
                    onClick={onClickConfirm}
                    disabled={isLoading}
                    loading={isLoading}
                    sibling
                    red
                  >
                    {lang.attempt.continueWithoutSaving}
                  </Button>
                  <Button
                    onClick={saveAnswers}
                    disabled={saveLoading}
                    loading={saveLoading}
                    sibling
                  >
                    {lang.attempt.saveAndContinue}
                  </Button>
                </div>
            }
          </>
        )}
      </div>
    </div >
  );
};

AttemptActions.propTypes = {
  nextItem: PropTypes.func,
  previousItem: PropTypes.func,
  finishTest: PropTypes.func.isRequired,
  savingAnswer: PropTypes.bool,
  isLast: PropTypes.bool,
  finishLoading: PropTypes.bool,
  navigation: PropTypes.string,
  isFirst: PropTypes.bool,
  finishable: PropTypes.bool,
  canMovePrevious: PropTypes.bool,
  canMoveNext: PropTypes.bool,
  writing: PropTypes.bool,
  flushPendingAnswers: PropTypes.func,
  pendingAnswers: PropTypes.instanceOf(Map),
  sectionId: PropTypes.string,
};

export default AttemptActions;
