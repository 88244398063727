import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { faPaperPlane, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import DeleteExercisesModal from '../DeleteExercisesModal';
import SendExercisesModal from '../SendExercisesModal';
import EntityActions from 'components/common/entity/EntityActions';
import Loading from 'components/common/Loading';
import NoEntity from 'components/common/NoEntity';
import Filters from 'components/exercises/exercise-bank/exercise-filter/Filters';
import SingleExercise from 'components/exercises/exercise-bank/single-exercise/SingleExercise';

import useStyles from './styles';

const ExerciseBank = ({ groupId, selected, setSelected, selectable, wrapped, groups, setCreateExerciseModal, userFilters }) => {
  const classes = useStyles();
  const toast = useToast();
  const userId = useSelector(state => state.user.data.id);
  const isDark = useSelector(state => state.page.isDark);
  const [getGroupTemplateExercisesRequest] = useApi(api.getGroupTemplateExercises);
  const [getGroupUsersRequest] = useApi(api.getGroupUsers);
  const [getGroupLabelsRequest] = useApi(api.getGroupLabels);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);

  const [labels, setLabels] = useState([]);
  const [exercises, setExercises] = useState(null);
  const [users, setUsers] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    users: userFilters ?? [],
    types: [],
    labels: [],
  });

  const containsOtherAuthors = useMemo(() => {
    return selected?.filter(exercise => exercise.authorId !== userId).length > 0;
  }, [selected, userId]);

  const deleteDisabled = useMemo(() => {
    const isPersonalGroup = groups?.find(group => group.id === groupId && group.isPersonal);
    return containsOtherAuthors && !isPersonalGroup;
  }, [containsOtherAuthors, groups, groupId]);

  const _setFilters = (filters) => {
    setPage(1);
    setFilters(filters);
  };
  const [order, setOrder] = useState('newest');

  const types = [
    { id: 'choices', name: lang.exerciseForm.typeChoices },
    { id: 'text', name: lang.exerciseForm.typeText },
    { id: 'true-false', name: lang.exerciseForm.typeTrueFalse },
    { id: 'image', name: lang.exerciseForm.typeImage },
    { id: 'filling', name: lang.exerciseForm.typeFilling },
    { id: 'ordering', name: lang.exerciseForm.typeOrdering },
    { id: 'caption', name: lang.exerciseForm.typeCaption },
    { id: 'connecting', name: lang.exerciseForm.typeConnecting },
    { id: 'segmentation', name: lang.exerciseForm.typeSegmentation },
    { id: 'table', name: lang.exerciseForm.typeTable },
  ];

  const fetchTemplateExercises = useCallback(() => {
    if (groupId) {
      const usersList = filters.users.map(user => user.id);
      const typesList = filters.types.map(type => type.id);
      const labelsList = filters.labels.map(label => label.id);

      getGroupTemplateExercisesRequest([groupId], {
        page: page,
        users: usersList,
        types: typesList,
        labels: labelsList,
        order: order,
      }, ({ data }) => {
        setLoading(false);
        if (data.status === 0) {
          setExercises(data.exercises);
          setPages(data.pages);
        } else {
          toast.error(lang.oops);
        }
      });
    }
  }, [getGroupTemplateExercisesRequest, toast, groupId, filters, order, page]);

  const fetchUsers = useCallback(() => {
    if (groupId) {
      getGroupUsersRequest([groupId], null, ({ data }) => {
        if (data.status === 0) {
          setUsers(data.users);
        } else {
          toast.error(lang.oops);
        }
      });
    }
  }, [getGroupUsersRequest, toast, groupId]);

  const fetchLabels = useCallback(() => {
    if (groupId) {
      getGroupLabelsRequest([groupId], null, ({ data }) => {
        if (data.status === 0) {
          setLabels(data.labels);
        }
      });
    }
  }, [getGroupLabelsRequest, groupId]);

  useEffect(() => {
    setLoading(true);
    fetchTemplateExercises();
  }, [fetchTemplateExercises]);

  useEffect(() => {
    fetchLabels();
  }, [fetchLabels]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    setPage(1);
    setFilters({
      users: userFilters ?? [],
      types: [],
      labels: [],
    });
  }, [groupId, userFilters]);

  const checkExercise = (checkedExercise) => {
    const isAdded = selected.find((exercise) => exercise.id === checkedExercise.id);

    let newSelected = [];
    if (isAdded) {
      newSelected = selected.filter((exercise) => exercise.id !== checkedExercise.id);
    } else {
      newSelected = [...selected, checkedExercise];
    }

    setSelected(newSelected);
  };

  const getExercises = () => {
    if (!exercises) {
      return;
    }

    if (exercises.length === 0 && filters.users.length === 0 && filters.types.length === 0 && filters.labels.length === 0) {
      if (selectable) {
        return (
          <div className={classes.noExercises}>
            {lang.exercises.noExercises}
          </div>
        );
      }

      return (
        <NoEntity
          message={lang.exercises.noExercises}
          action={() => setCreateExerciseModal(true)}
        />
      );
    } else if (exercises.length === 0) {
      return (
        <div className={classes.noExercises}>
          {lang.exercises.noExercisesForFilters}
        </div>
      );
    }

    return exercises.map((exercise, index) => {
      const isFirst = index === 0;

      let isSelected = false;
      if (selected) {
        isSelected = selected.find(checked => checked.id === exercise.id);
      }

      return (
        <SingleExercise
          dataTour={`single-exercise-${index}`}
          key={exercise.id}
          exercise={exercise}
          isFirst={isFirst}
          isSelected={isSelected}
          refresh={fetchTemplateExercises}
          check={checkExercise}
          selectable={selectable}
          wrapped={wrapped}
          setFilters={setFilters}
          labelOptions={labels}
          groupId={groupId}
          fetchLabels={fetchLabels}
          groups={groups}
        />
      );
    });
  };

  const refresh = () => {
    fetchTemplateExercises();
    setSelected([]);
  };

  const getNotSelectableModals = () => {
    if (!selectable) {
      return (
        <Fragment>
          <DeleteExercisesModal
            open={showDeleteModal}
            close={() => setShowDeleteModal(false)}
            templateExerciseIds={selected.map(exercise => exercise.id)}
            refresh={refresh}
          />
          <SendExercisesModal
            open={showSendModal}
            close={() => setShowSendModal(false)}
            selectedExercises={selected}
            groupId={groupId}
            groups={groups}
            refresh={refresh}
          />
        </Fragment>
      );
    }
  };

  const actions = [
    {
      icon: faPaperPlane,
      label: lang.send,
      onClick: () => setShowSendModal(true),
    },
    {
      icon: faTrash,
      label: lang.delete,
      onClick: () => setShowDeleteModal(true),
      disabled: deleteDisabled,
      tip: deleteDisabled ? lang.deleteDisabled : null,
    },
  ];

  return (
    <div className={cx(classes.bankWrap, { wrapped })}>
      <div className={cx(classes.exercisesContainer, { wrapped })}>
        <Render when={(exercises && exercises.length !== 0) || filters.users.length !== 0 || filters.types.length !== 0 || filters.labels.length !== 0}>
          <Filters
            users={userFilters ?? users}
            types={types}
            labels={labels}
            order={order}
            setOrder={setOrder}
            filters={filters}
            setFilters={_setFilters}
            loading={loading}
            actions={actions}
            selected={!selectable ? selected : null}
          />
          {getNotSelectableModals()}
        </Render>
        <Pagination
          pages={pages > 0 ? pages : 1}
          top
          bottom
          page={page}
          setPage={setPage}
          dark={isDark}
          className={classes.pagination}
        >
          <Render when={(exercises && exercises.length !== 0) || filters.users.length !== 0 || filters.types.length !== 0 || filters.labels.length !== 0}>
            <div className={classes.exerciseActionsWrapper}>
              <EntityActions
                actions={actions}
                selected={selected}
                selectable={selectable}
                setSelected={setSelected}
                groupId={groupId}
                entities={exercises}
              />
            </div>
          </Render>
          <Loading active={!exercises} />
          <Render when={exercises}>
            {getExercises()}
          </Render>
        </Pagination>
      </div>
    </div>
  );
};

ExerciseBank.propTypes = {
  groupId: PropTypes.object,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  selectable: PropTypes.bool,
  wrapped: PropTypes.bool,
  groups: PropTypes.array,
  setCreateExerciseModal: PropTypes.func,
  userFilters: PropTypes.array,
};

export default ExerciseBank;
