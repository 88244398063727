import lang from 'lang';

export const calculatePercentage = (quotient, total) => {
  return (quotient / (total || 1)) * 100;
};

export const calculateGradePercentage = (classAverage, total) => {

  return calculatePercentage(classAverage, total).toFixed(2);
};

export const getQuestionType = (question) => {
  switch (question.type) {
    case 'choices':
      return ' (' + lang.exerciseForm.typeChoices + ')';
    case 'true-false':
      return ' (' + lang.exerciseForm.typeTrueFalse + ')';
    case 'text':
      return ' (' + lang.exerciseForm.typeText + ')';
    case 'ordering':
      return ' (' + lang.exerciseForm.typeOrdering + ')';
    case 'caption':
      return ' (' + lang.exerciseForm.typeCaption + ')';
    case 'segmentation':
      return ' (' + lang.exerciseForm.typeSegmentation + ')';
    case 'connecting':
      return ' (' + lang.exerciseForm.typeConnecting + ')';
    case 'filling':
      return ' (' + lang.exerciseForm.typeFilling + ')';
    case 'image':
      return ' (' + lang.exerciseForm.typeImage + ')';
    case 'table':
      return ' (' + lang.exerciseForm.typeTable + ')';
    default:
      return '';
  }
};
