import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  optionsContainer: {
    display: 'flex',
    padding: '15px 13px',
    justifyContent: 'center',
  },
  tooltip: {
    width: '400px',
  },
}));
