import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  subHeader: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '10px 20px',
    backgroundColor: theme.cardColor,
    gap: '20px',
  },
}));
