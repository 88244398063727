import React, { useCallback, useMemo, useState } from 'react';
import { faCog, faCopy, faLock, faLockOpen, faPaperPlane, faPencilRuler, faRocket, faTrash, faUser, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { selectTests, setTests } from 'actions/testsPageActions';
import { incrementTotalTests, selectUserFeatureToggleMaxUsages, selectUserTotalFreeTests } from 'actions/userActions';
import api from 'api';
import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useLoading from 'hooks/common/useLoading';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { getTestClassificationType, getTestClassificationTypeIcon, getTestType, getTestTypeIcon } from 'utils/tests';

import StudentTestCardRight from '../StudentTestCardRight';
import TemplateEntityCard from 'components/common/entity/TemplateEntityCard';
import UpgradePremiumModal from 'components/common/plans/UpgradePremiumModal';
import DeleteTestModal from 'components/test/DeleteTestModal';
import PublishModal from 'components/test/publish-modal/PublishModal';
import SendTestModal from 'components/test/SendTestModal';
import SettingsModal from 'components/test/SettingsModal';
import EntityInformation from 'components/tests/Entitynformation';
import PasswordModal from 'components/tests/test-card/PasswordModal';

import useStyles from './styles';

const TestCard = ({ test, groups, fetchTests }) => {
  const classes = useStyles();
  const history = useHistory();
  const isTeacher = useSelector(state => state.user.data.isTeacher);
  const clockOffset = useSelector(state => state.page.clockOffset);
  const { spaceId } = useParams();
  const toast = useToast();
  const exploreToggle = useFeature(toggles.explore);
  const [createAttemptRequest] = useApi(api.createAttempt);
  const [duplicateTestRequest] = useApi(api.duplicateTest);
  const totalFreeTests = useSelector(selectUserTotalFreeTests);
  const createTestToggle = useFeature(toggles.createTest, totalFreeTests);
  const dispatch = useDispatch();
  const createTestToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createTest));
  const userId = useSelector(state => state.user.data.id);
  const tests = useSelector(selectTests);

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const [createAttemptLoading, setCreateAttemptLoading] = useLoading(false);
  const [upgradePremiumModal, setUpgradePremiumModal] = useState(false);

  const totalItems = useMemo(() => {
    if (!test.items) {
      return;
    }

    const totalItems = test.items
      .filter(exercise => ![INFORMATION, PAUSE].includes(exercise.type))
      .reduce((total, item) => {
        if (item.itemType === 'section') {
          const sectionItems = item.exercises
            .filter(exercise => ![INFORMATION, PAUSE].includes(exercise.type));

          return total + sectionItems.length;
        }

        return total + 1;
      }, 0);

    return totalItems;
  }, [test.items]);

  const deleteDisabled = useMemo(() => {
    const isPersonalGroup = groups.find(group => group.id === test.groupId && group.isPersonal);
    return test.authorId !== userId && !isPersonalGroup;
  }, [test, userId, groups]);

  const createAttemptResponse = useCallback((data) => {
    setCreateAttemptLoading(false);

    if (data.status === 0) {
      history.push(routes.attempt.ref(data.attempt.id));
    } else if (data.status === 4) {
      toast.text(lang.tests.student.testNotOpen);
    } else if (data.status === 5) {
      toast.error(lang.tests.student.testWindowClosed);
    } else if (data.status === 6) {
      toast.error(lang.tests.student.testComplete);
    } else if (data.status === 7) {
      toast.error(lang.tests.student.testCompleteTime);
    } else {
      toast.error(lang.oops);
    }
  }, [history, setCreateAttemptLoading, toast]);

  const createAttempt = useCallback(() => {
    if (createAttemptLoading) {
      return;
    }

    setCreateAttemptLoading(true);
    createAttemptRequest([], { publicationId: test.id }, ({ data }) => {
      createAttemptResponse(data);
    });
  }, [createAttemptRequest, createAttemptResponse, createAttemptLoading, setCreateAttemptLoading, test.id]);

  const onTestClick = useCallback(() => {
    if (isTeacher) {
      history.push(routes.test.ref(spaceId, test.id));
    } else {
      const now = new Date().getTime() - clockOffset;
      const startsAt = (new Date(test.startsAt)).getTime();

      let endsAt, attemptEndsAt, windowClosesAt;
      let testInsideWindow, testOngoing;
      if (test.type === 'test') {
        windowClosesAt = startsAt + (test.attemptWindow * 60 * 1000);
        endsAt = windowClosesAt + (test.duration * 60 * 1000);
        attemptEndsAt = ((new Date(test.attemptStartedAt ?? now)).getTime()) + (test.duration * 60 * 1000);
        testInsideWindow = now > startsAt && now < windowClosesAt;
        testOngoing = now > windowClosesAt && now < endsAt;
      } else if (test.type === 'worksheet') {
        endsAt = ((new Date(test.endsAt)).getTime());
        attemptEndsAt = ((new Date(test.endsAt)).getTime());
        testInsideWindow = now > startsAt && now < endsAt;
      }

      const testNotStarted = now < startsAt;
      const testEnded = now > endsAt;
      const attemptEnded = now > attemptEndsAt || test.attemptEndedAt;
      const attemptCreated = test.attemptId && test.attemptStartedAt;

      if (testNotStarted) {
        toast.text(lang.tests.student.testNotOpen);
        return;
      }

      if (testEnded) {
        if ((test.gradesPublished || test.immediateFeedback) && test.attemptId) {
          history.push(routes.attempt.ref(test.attemptId));
        } else {
          toast.warning(lang.tests.student.testCompleteTime);
        }
        return;
      }

      if (test.hasPassword && !attemptEnded) {
        setShowPasswordModal(true);
        return;
      }

      if (attemptCreated && (!attemptEnded || test.immediateFeedback)) {
        history.push(routes.attempt.ref(test.attemptId));
        return;
      }

      if (testInsideWindow && !attemptCreated) {
        createAttempt();
        return;
      }

      if (testOngoing && !attemptCreated) {
        toast.warning(lang.tests.student.testWindowClosed);
        return;
      }

      if (attemptCreated && attemptEnded) {
        toast.warning(lang.tests.student.testComplete);
      }
    }
  }, [clockOffset, createAttempt, history, isTeacher, spaceId, test, toast]);

  const duplicateTest = () => {
    if (!createTestToggle) {
      setUpgradePremiumModal(true);
      return;
    }

    setLoading(true);
    const duplicatedTestName = lang.copyOf + ' ' + test.name;

    duplicateTestRequest([test.id], { name: duplicatedTestName }, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        if (spaceId === PERSONAL_SPACE) {
          dispatch(incrementTotalTests());
        }

        toast.success(lang.test.successDuplicateTest);
        fetchTests();
        return;
      }

      toast.error(lang.oops);
    });
  };

  const afterSubmit = useCallback((publication) => {
    const newTests = tests.map(test => ({ ...test }));

    const newTest = newTests.find(test => test.id === publication.testId);
    newTest.publication = { ...publication };
    newTest.isPublished = true;

    dispatch(setTests(newTests));
  }, [dispatch, tests]);

  const getModals = () => {
    if (isTeacher) {
      return (
        <div onClick={e => e.stopPropagation()}>
          <PublishModal
            open={showPublishModal}
            close={() => setShowPublishModal(false)}
            test={test}
            refresh={fetchTests}
            afterSubmit={afterSubmit}
          />
          <DeleteTestModal
            open={showDeleteModal}
            close={() => setShowDeleteModal(false)}
            testId={test.id}
            refresh={fetchTests}
          />
          <SendTestModal
            open={showSendModal}
            close={() => setShowSendModal(false)}
            test={test}
            groupId={test.groupId}
            groups={groups}
            refresh={fetchTests}
          />
          <SettingsModal
            open={showEditNameModal}
            close={() => setShowEditNameModal(false)}
            test={test}
            refresh={fetchTests}
          />
          <UpgradePremiumModal
            open={upgradePremiumModal}
            close={() => setUpgradePremiumModal(false)}
            message={lang.plans.exceededTests(createTestToggleMaxUsages)}
          />
        </div>
      );
    }

    return (
      <PasswordModal
        open={showPasswordModal}
        close={() => setShowPasswordModal(false)}
        publicationId={test.id}
        createAttemptResponse={createAttemptResponse}
      />
    );
  };

  let actions;
  let extraActions;

  if (isTeacher) {
    actions = [
      {
        icon: faRocket,
        label: test.isPublished ? lang.published : lang.publish,
        onClick: () => setShowPublishModal(true),
        isPublished: test.isPublished,
        hideMedia: 'sm',
        dataTour: `test-card-${test.id}-publish-button`,
      },
      {
        icon: faPaperPlane,
        label: lang.send,
        onClick: () => setShowSendModal(true),
        hideMedia: 'md',
      },
      {
        icon: faCopy,
        label: lang.duplicate,
        onClick: duplicateTest,
        loading: loading,
        hideMedia: 'md',
      },
    ];

    extraActions = [
      {
        icon: faCog,
        label: lang.settings,
        onClick: () => setShowEditNameModal(true),
      },
      {
        icon: faTrash,
        label: lang.delete,
        onClick: () => setShowDeleteModal(true),
        disabled: deleteDisabled,
        tip: deleteDisabled ? lang.deleteDisabled : null,
      },
    ];
  }

  const testInformations = [
    {
      icon: getTestTypeIcon(test.type),
      label: getTestType(test.type),
    },
    {
      icon: getTestClassificationTypeIcon(test.classificationType),
      label: getTestClassificationType(test.classificationType),
    },
    {
      icon: faPencilRuler,
      label: `${totalItems} ${totalItems === 1 ? lang.tests.teacher.exercise : lang.tests.teacher.exercises}`,
    },
    {
      icon: faUser,
      label: test.authorName,
    },
    {
      icon: test.isPublic ? faLockOpen : faLock,
      label: test.isPublic ? lang.contentSettings.public : lang.contentSettings.private,
      hide: !exploreToggle,
    },
    ...(test.isPublic ?
      [
        {
          icon: faUserGroup,
          label: test.usages + ' ' + lang.copies,
          hide: !exploreToggle,
        },
      ]
      : []),
  ];

  return (
    <TemplateEntityCard
      dataTour={`test-card-${test.id}`}
      className={classes.testCard}
      actions={actions}
      extraActions={extraActions}
      onClick={onTestClick}
    >
      {getModals()}
      <div
        className={cx(classes.testLeftWrap, { student: !isTeacher })}
      >
        <div className={classes.testName}>
          {test.name}
        </div>
        <Render when={isTeacher}>
          <div className={classes.testLessonWrap}>
            <div className={classes.iconContainer}>
              <FontAwesomeIcon
                icon={faUsers}
              />
            </div>
            <div className={classes.testLesson}>
              {test.groupIsPersonal ? lang.appKeywords.personalGroup : test.groupName}
            </div>
          </div>
          <div className={classes.testInfoWrap}>
            <EntityInformation
              informations={testInformations}
            />
          </div>
        </Render>
        <Render when={createAttemptLoading}>
          <Spinner
            className={classes.loadingSpinner}
          />
        </Render>
      </div>
      <Render when={!isTeacher}>
        <div className={classes.rightWrap}>
          <StudentTestCardRight
            test={test}
          />
        </div>
      </Render>
    </TemplateEntityCard>
  );
};

TestCard.propTypes = {
  test: PropTypes.object,
  groups: PropTypes.array,
  fetchTests: PropTypes.func,
};

export default TestCard;
