import React from 'react';
import { Toggle } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import FlowStep from '../FlowStep';
import Input from 'components/common/Input';
import SymbolsButtons from 'components/common/rich-text/SymbolsButtons';

import useStyles from './styles';

const AskForShortAnswer = ({ number, isShortAnswer, setIsShortAnswer, characterLimit, setCharacterLimit, enableMathSymbols, setEnableMathSymbols, mathSymbols, setMathSymbols, hasWordCount, setHasWordCount, restricted }) => {
  const classes = useStyles();
  const wordCountToggle = useFeature(toggles.wordCount);

  const saveInteger = (event) => {
    const value = event.target.value;
    if (value === '') {
      setCharacterLimit(null);
    }
    const intValue = parseInt(value);
    if (intValue) {
      setCharacterLimit(intValue);
    }
  };

  return (
    <FlowStep
      dataTour="ask-for-short-answer"
      stepNumber={number}
      header={lang.exerciseForm.shortAnswer}
    >
      <div
        className={classes.wrapper}
      >
        {!restricted && (
          <div className={classes.toggleWrapper}>
            <div
              className={classes.label}
            >
              {lang.exerciseForm.shortAnswerText}
            </div>

            <Toggle
              className={classes.toggle}
              checked={isShortAnswer}
              onChange={(checked) => setIsShortAnswer(checked)}
            />
          </div>
        )}
        {isShortAnswer && (
          <>
            {restricted &&
            <div className={classes.characterLimitEdit}>
              {lang.exerciseForm.characterLimit}
            </div>
            }
            <div className={classes.characterLimit}>
              <Input
                type="number"
                placeholder={lang.exerciseForm.characterLimit}
                value={characterLimit}
                onChange={(event) => saveInteger(event)}
                minSize={1}
              />
            </div>
          </>
        )}
        {wordCountToggle && (
          <div className={classes.toggleWrapper}>
            <div
              className={classes.label}
            >
              {lang.exerciseForm.wordCountLabel}
            </div>
            <Toggle
              className={classes.toggle}
              checked={hasWordCount}
              onChange={(checked) => setHasWordCount(checked)}
            />
          </div>
        )}
        <div className={classes.toggleWrapper}>
          {lang.exerciseForm.useMathSymbols}
          <Toggle
            checked={enableMathSymbols}
            onChange={(checked) => setEnableMathSymbols(checked)}
          />
        </div>
        {enableMathSymbols && (
          <div className={classes.toggleWrapper}>
            <SymbolsButtons
              mathSymbols={mathSymbols}
              setMathSymbols={setMathSymbols}
            />
          </div>
        )}
      </div>
    </FlowStep>
  );
};

AskForShortAnswer.propTypes = {
  number: PropTypes.number,
  isShortAnswer: PropTypes.bool,
  setIsShortAnswer: PropTypes.func,
  characterLimit: PropTypes.number,
  setCharacterLimit: PropTypes.func,
  enableMathSymbols: PropTypes.bool,
  setEnableMathSymbols: PropTypes.func,
  mathSymbols: PropTypes.bool,
  setMathSymbols: PropTypes.func,
  hasWordCount: PropTypes.bool,
  setHasWordCount: PropTypes.func,
  restricted: PropTypes.bool,
};

export default AskForShortAnswer;
