import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  tabs: {
    '& .otl-tabHeader.active': {
      backgroundColor: theme.shade4 + ' !important',
      borderBottomColor: theme.shade4 + ' !important',
    },
    '& .otl-tabsTab': {
      backgroundColor: theme.shade4 + ' !important',
    },
  },
}));
