import React, { useEffect, useMemo, useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { decrementTotalExercises, incrementTotalExercises, selectUserFeatureToggleMaxUsages, selectUserTotalFreeExercises } from 'actions/userActions';
import api from 'api';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useInput from 'hooks/common/useInput';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';
import PlansPill from 'components/common/plans/PlansPill';

import useStyles from './styles';

const SendExercisesModal = ({ open, close, selectedExercises, groupId, groups, refresh }) => {
  const classes = useStyles();
  const toast = useToast();
  const userId = useSelector(state => state.user.data.id);
  const schools = useSelector(state => state.user.data.schools);
  const hasPersonalSpace = useSelector(state => state.user.data.hasPersonalSpace);
  const [sendTemplateExerciseRequest] = useApi(api.sendTemplateExercise);
  const totalFreeExercises = useSelector(selectUserTotalFreeExercises);
  const createExerciseToggle = useFeature(toggles.createExercise, totalFreeExercises, selectedExercises.length);
  const iaveToggle = useFeature(toggles.iave);
  const createExerciseToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createExercise));
  const dispatch = useDispatch();
  const { spaceId } = useParams();

  const schoolOptions = schools?.map(school => ({ value: school.id, label: school.name }));

  if (hasPersonalSpace) {
    schoolOptions.push({ value: PERSONAL_SPACE, label: lang.appKeywords.personalSpace });
  }

  const [loading, setLoading] = useState(false);
  const [group, setGroup, groupErrors, setGroupErrors] = useInput(null);
  const [school, setSchool, schoolErrors, setSchoolErrors] = useInput(schoolOptions.find(currentSchool => currentSchool.value === spaceId));
  const [copy, setCopy] = useState(!createExerciseToggle ? false : true);
  const [sendLabels, setSendLabels] = useState(false);

  const groupOptions = groups.map(group => ({ value: group.id, label: group.label, schoolId: group.schoolId }))
    .filter(group => group.schoolId === (school?.value !== PERSONAL_SPACE ? school?.value : null))
    .filter(group => group.value !== groupId);

  const otherAuthors = useMemo(() => {
    return selectedExercises.filter(exercise => exercise.authorId !== userId);
  }, [selectedExercises, userId]);

  const containsOtherAuthors = useMemo(() => {
    return otherAuthors.length > 0;
  }, [otherAuthors]);

  const sendDisabled = useMemo(() => {
    if (iaveToggle) {
      return false;
    }

    if (!createExerciseToggle && copy && school.value === PERSONAL_SPACE && spaceId === PERSONAL_SPACE) {
      return true;
    }

    return false;
  }, [iaveToggle, createExerciseToggle, copy, school.value, spaceId]);

  const copyDisabled = useMemo(() => {
    if (iaveToggle) {
      return false;
    }

    if (!createExerciseToggle && school.value === PERSONAL_SPACE && spaceId === PERSONAL_SPACE) {
      return true;
    }

    if (containsOtherAuthors) {
      return true;
    }

    return false;
  }, [iaveToggle, createExerciseToggle, school.value, spaceId, containsOtherAuthors]);

  const sendLabelsDisabled = useMemo(() => !iaveToggle && containsOtherAuthors && spaceId !== PERSONAL_SPACE, [containsOtherAuthors, iaveToggle, spaceId]);

  useEffect(() => {
    if (containsOtherAuthors) {
      setCopy(true);
      return;
    }

    if (!createExerciseToggle && school.value === PERSONAL_SPACE && spaceId === PERSONAL_SPACE) {
      setCopy(false);
      return;
    }

    setCopy(true);
  }, [containsOtherAuthors, createExerciseToggle, school.value, spaceId]);

  const _setSchool = (school) => {
    setSchool(school);
    const schoolGroup = groups.find(group => group.schoolId === (school.value !== PERSONAL_SPACE ? school.value : null) && group.isPersonal);
    setGroup({
      value: schoolGroup.id,
      label: schoolGroup.label,
    });
  };

  const cancel = () => {
    close();
    setTimeout(() => {
      setGroup(null);
      setSchool(schoolOptions.find(currentSchool => currentSchool.value === spaceId));
      setGroupErrors(null);
      setCopy(copyDisabled);
      setSendLabels(false);
    }, 350);
  };

  const sendExercise = () => {
    if (!group) {
      setGroupErrors([selectedExercises.length === 1 ? lang.exercises.send.errorSendExerciseGroup : lang.exercises.send.errorSendExercisesGroup]);
      return;
    }

    if (!school) {
      setSchoolErrors([lang.exercises.send.errorSendExercisesSchool]);
      return;
    }

    for (let i = 0; i < selectedExercises.length; i++) {
      const exercise = selectedExercises[i];
      if (exercise.authorId !== userId && spaceId !== PERSONAL_SPACE && school.value === PERSONAL_SPACE) {
        toast.warning(lang.exercises.send.errorSendExercisesToMySpace);
        return;
      }
    }

    const templateExerciseIds = selectedExercises.map(exercice => exercice.id);

    setLoading(true);
    sendTemplateExerciseRequest([], { templateExerciseIds, groupId: group.value, copy, sendLabels }, ({ data }) => {
      if (data.status === 0) {
        toast.success(selectedExercises.length === 1 ? lang.exercises.send.successSendExercise : lang.exercises.send.successSendExercises);
        setTimeout(() => {
          if (school.value === PERSONAL_SPACE && (spaceId !== PERSONAL_SPACE || (spaceId === PERSONAL_SPACE && copy))) {
            dispatch(incrementTotalExercises(templateExerciseIds.length));
          } else if (spaceId === PERSONAL_SPACE && school.value !== PERSONAL_SPACE && !copy) {
            dispatch(decrementTotalExercises(templateExerciseIds.length));
          }

          setLoading(false);
          setGroup(null);
          setGroupErrors(null);
          setCopy(true);
          setSendLabels(false);
          refresh();
          close();
        }, 350);
        return;
      }

      setLoading(false);
      toast.error(lang.oops);
      close();
    });
  };

  const getActions = () => ([
    {
      name: lang.cancel,
      color: 'black',
      onClick: cancel,
    },
    {
      name: lang.send,
      onClick: sendExercise,
      loading: loading,
      disabled: sendDisabled,
      tip: sendDisabled && lang.plans.exceededExercisesTip(createExerciseToggleMaxUsages),
    },
  ]);

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Modal
        open={open}
        close={cancel}
        header={selectedExercises.length === 1 ? lang.exercises.send.sendExercise : lang.exercises.send.sendExercises}
        actions={getActions()}
        center
        transition
        small
      >
        <Input
          type="select"
          value={school}
          label={lang.appKeywords.spaces}
          placeholder={lang.appKeywords.space}
          onChange={(option) => _setSchool(option)}
          options={schoolOptions}
          errors={schoolErrors}
          disabled={containsOtherAuthors && spaceId !== PERSONAL_SPACE}
        />
        <Spacer px={15} />
        <Input
          type="select"
          value={group}
          label={lang.appKeywords.group}
          placeholder={lang.appKeywords.group}
          onChange={(option) => setGroup(option)}
          options={groupOptions}
          errors={groupErrors}
        />
        <Spacer px={15} />
        <div className={classes.keepCopyContainer}>
          <Input
            type="checkbox"
            value={copy}
            label={lang.exercises.send.keepCopy}
            onChange={(event) => setCopy(event.target.checked)}
            disabled={copyDisabled}
          />
          <Render when={!createExerciseToggle && school.value === PERSONAL_SPACE}>
            <PlansPill
              tip={lang.plans.exceededExercisesTip(createExerciseToggleMaxUsages)}
            />
          </Render>
        </div>
        <Input
          type="checkbox"
          value={sendLabels}
          label={lang.exercises.send.sendLabels}
          onChange={(event) => setSendLabels(event.target.checked)}
          disabled={sendLabelsDisabled}
        />
      </Modal>
    </div>
  );
};

SendExercisesModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  selectedExercises: PropTypes.array,
  groupId: PropTypes.string,
  groups: PropTypes.array,
  refresh: PropTypes.func,
};

export default SendExercisesModal;
