import { createUseStyles } from 'react-jss';

export default createUseStyles({
  tabHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  tabHeaderTitle: {
    fontWeight: '600',
    fontSize: '20px',
  },
  sectionHeaderTitle: {
    fontWeight: 'normal',
    marginTop: '0px',
  },
});
