import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  microsoftButton: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    color: theme.textColor,
    boxShadow: `0 0 5px ${theme.shadowColor}`,
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
  fullName: {
    fontWeight: 600,
  },
  logoutContainer: {
    marginTop: '10px',
    fontSize: '14px',
    display: 'flex',
    gap: '5px',
  },
}));
