import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

import lang from 'lang';

import useStyles from './styles';

export const MultiLineChart = ({ questions }) => {
  const classes = useStyles();

  return (
    <ResponsiveContainer className={classes.chart} debounce={100} width={'95%'} height={200}>
      <LineChart
        width={550}
        height={250}
        data={questions}
        margin={{
          top: 20,
          right: 50,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={item => `Item ${item.name}`} />
        <YAxis
          label={{
            value: `${lang.appKeywords.grade} (%)`,
            style: { textAnchor: 'middle' },
            angle: -90,
            position: 'left',
            offset: 0,
          }}
          domain={[0, 100]}
        />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="lowestGradeNormalized" name={lang.test.stats.lowest} stroke="#E4B363" />
        <Line type="monotone" dataKey="normalizedGradeGraph" name={lang.test.stats.avrgGrade} stroke="#8884d8" />
        <Line type="monotone" dataKey="highestGradeNormalized" name={lang.test.stats.highest} stroke="#82ca9d" />
        <ReferenceLine y={100} strokeWidth={0} />
      </LineChart>
    </ResponsiveContainer>
  );
};

MultiLineChart.propTypes = {
  questions: PropTypes.array,
};

export default MultiLineChart;
