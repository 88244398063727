import React from 'react';
import { faChalkboardUser, faChild, faCircleInfo, faComments, faFileAlt, faFileCircleCheck, faGear, faGraduationCap, faLayerGroup, faMapMarkedAlt, faPencilRuler, faQuestionCircle, faTable, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar as OLNavbar } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { pageError } from 'actions/pageActions';
import { selectUserIsAdmin, selectUserIsStudent, selectUserIsSuperadmin, selectUserIsTeacher, selectUserSpaceIdPreference } from 'actions/userActions';
import { PREMIUM } from 'constants/plans';
import { TEACHER } from 'constants/roles';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import LogoutButton from '../LogoutButton';
import SchoolDropdown from '../SchoolDropdown';
import ALink from 'components/common/ALink';
import Button from 'components/common/Button';
import LangSelector from 'components/common/LangSelector';
import Logo from 'components/common/Logo';
import PlansPill from 'components/common/plans/PlansPill';
import Tooltip from 'components/common/Tooltip';

import { ReactComponent as LogoIAVE } from './logo_iave.module.svg';
import useStyles from './styles';

const Sidebar = ({ open, setOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const spaceId = useSelector(selectUserSpaceIdPreference);
  const user = useSelector(state => state.user.data);
  const dispatch = useDispatch();
  const exploreToggle = useFeature(toggles.explore);
  const explorePageToggle = useFeature(toggles.explorePage);
  const isStudent = useSelector(selectUserIsStudent);
  const isTeacher = useSelector(selectUserIsTeacher);
  const iaveToggle = useFeature(toggles.iave);
  const plansToggle = useFeature(toggles.plans);
  const classesToggle = useFeature(toggles.classes);
  const isSuperadmin = useSelector(selectUserIsSuperadmin);
  const isAdmin = useSelector(selectUserIsAdmin);

  const page = history.location.pathname.split('/').pop().split('?')[0];

  const changePage = (route, isExternal) => {
    setOpen(false);
    dispatch(pageError(null));

    const spaceId = history.location.pathname.split('/')[2];
    const schools = user.schools.map(school => school.id);
    const spaces = user.hasPersonalSpace ? ['p', ...schools] : schools;

    if (route === page) {
      if (!spaces.includes(spaceId)) {
        history.push(routes[route].ref(spaces[0]));
        return;
      }
      return;
    }

    if (isExternal) {
      window.location = routes[route].ref();
      return;
    }

    if (!spaces.includes(spaceId)) {
      history.push(routes[route].ref(spaces[0]));
      return;
    }

    if (spaceId) {
      history.push(routes[route].ref(spaceId));
    } else {
      history.push(routes[route].ref(spaces[0]));

    }
  };

  const handleAccountRedirect = () => {
    window.location = routes.profile.ref();
  };

  const pageLinks = [
    {
      icon: faMapMarkedAlt,
      label: lang.explore.title,
      page: 'explore',
      hide: !isTeacher || !explorePageToggle || !exploreToggle,
      dataTour: 'sidebar-explore',
    },
    {
      icon: faUser,
      label: lang.appKeywords.teachers,
      page: 'teachers',
      hide: !isSuperadmin,
      isExternal: true,
    },
    {
      icon: faChild,
      label: lang.appKeywords.students,
      page: 'students',
      hide: !iaveToggle || (!isSuperadmin && !isAdmin),
      isExternal: true,
    },
    {
      icon: faFileAlt,
      label: lang.appKeywords.assessments,
      page: 'tests',
      hide: !isTeacher && !isStudent,
      dataTour: 'sidebar-assessments',
    },
    {
      icon: faPencilRuler,
      label: lang.appKeywords.exercises,
      page: 'exercises',
      hide: !isTeacher,
      dataTour: 'sidebar-exercises',
    },
    {
      icon: faTable,
      label: lang.appKeywords.rubrics,
      page: 'rubrics',
      dataTour: 'sidebar-rubrics',
      hide: !isTeacher,
    },
    {
      icon: faUsers,
      label: lang.appKeywords.groups,
      page: 'groups',
      dataTour: 'sidebar-groups',
      hide: !isTeacher && !isSuperadmin,
    },
    {
      icon: iaveToggle ? faLayerGroup : faChalkboardUser,
      label: iaveToggle ? lang.appKeywords.examGroupsMin : lang.appKeywords.classes,
      page: 'classes',
      hide: !isSuperadmin || !classesToggle,
      isExternal: true,
    },
    {
      icon: faCircleInfo,
      label: lang.appKeywords.events,
      page: 'events',
      hide: !iaveToggle || !isSuperadmin,
      isExternal: true,
    },
    {
      icon: faFileCircleCheck,
      label: lang.appKeywords.exams,
      page: 'exams',
      hide: !iaveToggle || (!isSuperadmin && !isAdmin),
      isExternal: true,
    },
  ];

  return (
    <>
      <OLNavbar className={cx(classes.sidebar, { open })}>
        <div className={classes.logoWrapper}>
          {iaveToggle ?
            <LogoIAVE className={classes.iaveLogo} />
            :
            <Logo small />
          }
          <Render when={plansToggle}>
            <Render when={user.role === TEACHER && user.plan && user.plan.name === PREMIUM}>
              <PlansPill
                message={spaceId !== 'p' ? lang.plans.schools : lang.plans.premium}
                isSchool={spaceId !== 'p'}
              />
            </Render>
            <Render when={user.role === TEACHER && user.plan && user.plan.name !== PREMIUM}>
              <ALink
                href={routes.profile.ref() + `?plan=${PREMIUM}`}
              >
                <PlansPill
                  message={spaceId !== 'p' ? lang.plans.schools : lang.plans.getPremium}
                  getPremium={spaceId === 'p'}
                  isSchool={spaceId !== 'p' }
                />
              </ALink>
            </Render>
          </Render>
        </div>
        {pageLinks.map((pageLink) => (
          <Render
            key={pageLink.label}
            when={!pageLink.hide}
          >
            <div
              className={cx(classes.pageLink, 'otl-noselect', { selected: page === pageLink.page })}
              onClick={() => changePage(pageLink.page, pageLink.isExternal)}
              data-tour={pageLink.dataTour}
            >
              <div className={classes.iconWrapper}>
                <FontAwesomeIcon
                  icon={pageLink.icon}
                  className={classes.pageIcon}
                />
              </div>
              <div className={classes.pageLabel}>
                {pageLink.label}
              </div>
            </div>
          </Render>
        ))}
        <Render when={isTeacher || isSuperadmin || isAdmin}>
          <div className={classes.separator}></div>
          <ALink
            href={routes.feedback.ref()}
            target="_blank"
          >
            <div
              className={cx(classes.pageLink, 'otl-noselect')}
            >
              <div className={classes.iconWrapper}>
                <FontAwesomeIcon
                  icon={faComments}
                  className={classes.pageIcon}
                />
              </div>
              <div className={classes.pageLabel}>
                {lang.feedback}
              </div>
            </div>
          </ALink>
          <ALink
            href={routes.help.ref()}
            target="_blank"
          >
            <div
              className={cx(classes.pageLink, 'otl-noselect')}
            >
              <div className={classes.iconWrapper}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={classes.pageIcon}
                />
              </div>
              <div className={classes.pageLabel}>
                {lang.help}
              </div>
            </div>
          </ALink>
          <ALink
            href={routes.tutorial.ref()}
            target="_blank"
          >
            <div
              className={cx(classes.pageLink, 'otl-noselect')}
            >
              <div className={classes.iconWrapper}>
                <FontAwesomeIcon
                  icon={faGraduationCap}
                  className={classes.pageIcon}
                />
              </div>
              <div className={classes.pageLabel}>
                {lang.tutorial}
              </div>
            </div>
          </ALink>
        </Render>
        <div className={classes.profileWrapper}>
          <div className={classes.userName}>
            {user.fullName && user.fullName.split(' ').length > 1 ? `${user.fullName.split(' ')[0]} ${user.fullName.split(' ')[user.fullName.split(' ').length - 1]}` : user.fullName}
          </div>
          <div className={classes.userEmail}>
            {user.email}
          </div>
          <Render when={isTeacher || isSuperadmin || isAdmin}>
            <SchoolDropdown />
          </Render>
          <div className={classes.buttons}>
            <LangSelector />
            <Render when={!iaveToggle || !isStudent}>
              <Tooltip tip={lang.account}>
                <Button className={cx(classes.button, classes.account)} onClick={handleAccountRedirect}>
                  <FontAwesomeIcon icon={faGear} />
                </Button>
              </Tooltip>
            </Render>
            <LogoutButton />
          </div>
        </div>
      </OLNavbar>
    </>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default Sidebar;
