import { PUBLISH_TEST, SET_TEST, ADD_TEST_SECTION, EDIT_TEST_SECTION, SET_TEST_ITEMS, REMOVE_TEST_ITEM, UPDATE_POINTS } from 'actions/testActions';
import removeTestItem from 'services/tests/removeTestItem';
import { updateExercisePoints } from 'utils/tests';

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEST:
      return action.test;

    case UPDATE_POINTS:
      return updateExercisePoints(state, action.itemId, action.points, action.sectionId);
    case PUBLISH_TEST:
      return {
        ...state,
        publication: action.publication,
        isPublished: true,
      };
    case ADD_TEST_SECTION:
      return {
        ...state,
        items: [
          ...state.items,
          {
            ...action.section,
            order: state.items.length,
            itemType: 'section',
            exercises: [],
          },
        ],
      };
    case EDIT_TEST_SECTION:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.itemType === 'section' && item.id === action.sectionId) {
            return {
              ...item,
              ...action.section,
            };
          }

          return item;
        }),
      };
    case SET_TEST_ITEMS:
      return {
        ...state,
        items: action.items,
      };
    case REMOVE_TEST_ITEM: {
      const newItems = removeTestItem(state.items, action.sectionId, action.itemId);

      return {
        ...state,
        items: newItems,
      };
    }
    default:
      return state;
  }
};

export default reducer;
