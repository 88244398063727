import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  statementInput: {
    '& .ql-toolbar': {
      border: 'none',
      borderBottom: `1px solid ${theme.cardBorder3}`,
    },
    '& .ql-container': {
      border: 'none',
    },
    '& .ql-editor': {
      minHeight: '150px',
    },
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  headerFormulaWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5em',
  },
  toggleWrapper: {
    display: 'flex',
    gap: '0.35em',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: '1em',
    border: `1px solid ${theme.cardBorder}`,
    borderRadius: '3px',
    padding: '5px',
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
  actionIcon: {
    fontSize: '20px',
    opacity: 0.6,
  },
}));
