import React from 'react';
import { faDatabase, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import lang from 'lang';

import Button from 'components/common/Button';
import { ADD_EXISTING_RUBRIC, CREATE_RUBRIC } from 'components/test/tabs/RubricTab/addRubricOptions';

import useStyles from './styles';

const AskRubricFrom = ({ setFrom }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        className={classes.optionButton}
        onClick={() => setFrom(CREATE_RUBRIC)}
        large
        gray
      >
        <FontAwesomeIcon
          icon={faPlus}
        />
        <div className={classes.fromOptionText}>
          {lang.test.rubric.createRubric}
        </div>
      </Button>
      <Button
        className={classes.optionButton}
        onClick={() => setFrom(ADD_EXISTING_RUBRIC)}
        large
        gray
      >
        <FontAwesomeIcon
          icon={faDatabase}
        />
        <div className={classes.fromOptionText}>
          {lang.test.rubric.selectRubricFromBank}
        </div>
      </Button>
    </div>
  );
};

AskRubricFrom.propTypes = {
  setFrom: PropTypes.func,
};

export default AskRubricFrom;
