import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useTheme } from 'theming';

import useStyles from './styles.js';

const Swatch = ({ swatchColor, color, setColor }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div key={swatchColor} className={classes.swatchWrapper}>
      <div className={classes.swatch} style={{ backgroundColor: swatchColor ? swatchColor : theme.textColor2 }} onClick={() => setColor(swatchColor)} >
        <Render when={swatchColor === color}>
          <FontAwesomeIcon
            icon={faCheck}
            className={classes.icon}
          />
        </Render>
      </div>
    </div>
  );
};

Swatch.propTypes = {
  swatchColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired,
};
export default Swatch;
