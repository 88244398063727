import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  headerContainer: {
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    height: '35vh',
    alignItems: 'center',
  },
  headerContent: {
    maxWidth: '902px',
    width: '90%',
  },
  headerTitle: {
    fontWeight: 600,
    fontSize: '30px',
    textAlign: 'center',
    padding: '108px 0 55px 0',
  },
  exploreSearchContainer: {
    padding: '0 0 104px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&.isSearching': {
      padding: '0',
    },
  },
  exploreSearchBarContainer: {
    width: '100%',
    backgroundColor: theme.white,
    display: 'flex',
    alignItems: 'center',
    background: '#fdf7ff',
    border: '1px solid #d9d9d9',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '45px',
  },
  exploreSearchIcon: {
    padding: '20px 11px 20px 16px',
  },
  exploreSearchForm: {
    width: '100%',
    marginRight: '20px',
  },
  exploreSearchBar: {
    width: '100%',
    marginRight: '20px',
    height: '100%',
    '& input': {
      height: '100%',
      border: 'none',
      padding: '0',
      fontSize: '17px',
      '&:focus': {
        boxShadow: 'none',
      },
      '&::placeholder': {
        color: '#b9b9b9',
      },
    },
  },
  filterContainer: {
    width: '90%',
    display: 'flex',
    padding: '12px 0',
  },
  filterLabel: {
    marginRight: '10px',
    whiteSpace: 'nowrap',
  },
  infoBtn: {
    marginLeft: '0.25em',
    marginTop: '0.1em',
    '& .otl-tooltip': {
      width: '250px',
      whiteSpace: 'normal',
    },
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    minWidth: '100px',
    justifyContent: 'space-between',
    borderRadius: '10px',
  },
  dropdownItemLabel: {
    height: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dropdownArrow: {
    fontSize: '15px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  dropdownMenu: {
    backgroundColor: `${theme.selectBackground} !important`,
    borderRadius: '10px',
    border: `1px solid ${theme.inputBorder3} !important`,
    top: 'calc(100% + 9px)',
    width: 'calc(100% - 2px)',
    zIndex: '1',
    overflow: 'hidden',
  },
  dropdownMenuItem: {
    borderBottom: 'none !important',
    margin: '0 !important',
    '&:hover': {
      backgroundColor: theme.selectBackgroundHover,
    },
    '&:nth-last-of-type(1)': {
      borderBottom: 'none !important',
    },
    '&.selected': {
      backgroundColor: theme.selectBackgroundHover,
    },
  },
  curriculum: {
    position: 'absolute',
    top: '0',
    right: '0',
    margin: '15px',
    fontSize: '14px',
    color: theme.textColor3,
    [`@media (max-width: ${theme.sm}px)`]: {
      top: '50px',
    },
  },
  curriculumName: {
    color: theme.textColor,
  },
}));
