import React, { useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { addTestSection, selectTestItems } from 'actions/testActions';
import { selectUserFeatureToggleMaxUsages } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import AddContentContainer from '../AddContentContainer';
import TestSectionForm from '../TestSectionForm';
import UpgradePremium from 'components/common/plans/UpgradePremium';

const CreateTestSection = ({ cancel, afterAdd }) => {
  const { testId } = useParams();
  const toast = useToast();
  const dispatch = useDispatch();
  const [addTestSectionRequest] = useApi(api.addTestSection);
  const testItems = useSelector(selectTestItems);
  const sections = testItems.filter(item => item.itemType === 'section');
  const sectionsToggle = useFeature(toggles.sections, sections.length);
  const sectionsToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.sections));

  const [loading, setLoading] = useState(false);

  const save = (newSection) => {
    setLoading(true);
    addTestSectionRequest([testId], newSection, ({ data }) => {
      setTimeout(() => {
        setLoading(false);

        if (data.status === 0) {
          toast.success(lang.test.exercises.addSectionSuccessful);
          dispatch(addTestSection(data.section));
          afterAdd();
          return;
        }

        toast.error(lang.oops);
      }, 300);
    });
  };

  return (
    <AddContentContainer
      title={lang.test.exercises.addSection}
      goBack={cancel}
    >
      <Render when={!sectionsToggle}>
        <UpgradePremium
          message={lang.plans.exceededSections(sectionsToggleMaxUsages)}
        />
      </Render>
      <Render when={sectionsToggle}>
        <Spacer px={20} />
        <TestSectionForm
          save={save}
          cancel={cancel}
          loading={loading}
        />
      </Render>
    </AddContentContainer>
  );
};

CreateTestSection.propTypes = {
  cancel: PropTypes.func,
  afterAdd: PropTypes.func,
};

export default CreateTestSection;
