import { createUseStyles } from 'react-jss';

export default createUseStyles({
  downloadAttemptsCSVContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '10px',
  },
});
