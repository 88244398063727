import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  footerContainer: {
    padding: '12px 10px 10px 10px',
    display: 'flex',
    justifyContent: 'end',
    gap: '8px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    fontSize: '20px',
  },
  modalCloseIcon: {
    cursor: 'pointer',
  },
  quillContainer: {
    width: '100%',
    position: 'relative',
    borderRadius: '0 0 18px 18px',
    border: '1px solid #ccc',
    '& .ql-tooltip.ql-editing': {
      left: '5px !important',
      top: '0px !important',
    },
    '&.noHeader': {
      borderRadius: '18px !important',
      '& .quill': {
        borderRadius: '18px !important',
      },
    },
    '& .quill': {
      borderRadius: '0 0 18px 18px',
    },
  },
  formatContainer: {
    display: 'flex !important',
    flexWrap: 'wrap',
    '&.noHeader': {
      display: 'none !important',
    },
  },
  formulaBtnContainer: {
    position: 'absolute',
    right: '10px',
    top: '14px',
  },
  formulaBtn: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    marginLeft: '10px',
    cursor: 'pointer',
    '&.disabled': {
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  soon: {
    opacity: 0.4,
  },
  formulas: {
    display: 'none !important',
    '&.show': {
      display: 'initial !important',
    },
  },
  footerFormulaButton: {
    borderWidth: '1px !important',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    quillContainer: {
      '& .ql-tooltip.ql-editing': {
        left: '0px !important',
        top: '0px !important',
        width: '100%',
        display: 'flex',
      },
    },
  },
}));
