import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  modelAnswerInput: {
    '& .ql-toolbar': {
      border: 'none',
      borderBottom: `1px solid ${theme.cardBorder3}`,
    },
    '& .ql-container': {
      border: 'none',
    },
    '& .ql-editor': {
      minHeight: '150px',
    },
  },
}));
