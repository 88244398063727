import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  headerContainer: {
    padding: '10px',
    backgroundColor: theme.shade0,
    position: 'fixed',
    width: 'calc(100% - 237px)',
    '&.fullWidth': {
      width: 'calc(100% - 20px)',
    },
    zIndex: '11',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerLeft: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '5px',
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
  },
  testName: {
    fontSize: '20px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  time: {
    display: 'flex',
    flexDirection: 'column',
  },
  endsHint: {
    fontSize: '12px',
  },
  grade: {
    fontWeight: '600',
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '12px',
    cursor: 'pointer',
  },
  actionIcon: {
    fontSize: '20px',
  },
}));
