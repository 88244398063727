import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  savingIcon: {
    marginLeft: 'auto',
    marginRight: '10px',
    width: '20px',
    height: '20px',
  },
  check: {
    color: theme.successColor,
  },
}));
