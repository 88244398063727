import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  identifier: {
    width: '40px',
    fontWeight: 'normal !important',
    fontSize: '11px',
    position: 'absolute',
    top: 'calc(100% - 17px)',
    left: 'calc(100% - 35px)',
    opacity: '30%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (max-width: 10px)': {
      opacity: '100%',
    },
  },
  exerciseCell: {
    '&.rowGrading': {
      '&.correctRow': {
        backgroundColor: '#7DD88966 !important',
      },
    },
    '&:not(.correction):hover': {
      cursor: 'pointer',
      boxShadow: 'inset 0 0 0 1px #419bb4B3',
    },
  },
  exerciseChoiceWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  correctionWrapper: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    left: '25px',
    height: '100%',
    width: '100%',
  },
  correctCellIcon: {
    color: theme.successColor,
    fontSize: '1em',
    width: '100%',
  },
  correctIcon: {
    color: theme.successColor,
    fontSize: '1.5em',
    width: '100%',
  },
  incorrectIcon: {
    color: theme.errorColor,
    fontSize: '1.5em',
    width: '100%',
  },
}));
