import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  inputArea: {
    borderRadius: 0,
  },
  wrapper: {
    marginTop: '20px',
  },
  gapList: {
    minHeight: '35px',
    marginTop: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
  },
  gap: {
    minHeight: '23px',
    cursor: 'pointer',
    padding: '2px 5px 0 5px',
    backgroundColor: theme.buttonBackground3,
    color: 'black',
    border: `1px solid ${theme.buttonBorder3}`,
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.buttonBackgroundHover3,
    },
    '&.disabled': {
      pointerEvents: 'none',
    },
  },
}));
