import React, { useEffect, useState } from 'react';
import { faFileAlt, faPlusCircle, faStopwatch, faTable, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Spacer } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { incrementTotalTests, selectUserFeatureToggleMaxUsages, selectUserTotalFreeTests } from 'actions/userActions';
import api from 'api';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useInput from 'hooks/common/useInput';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import CardSelector from '../card-selector/CardSelector';
import Input from 'components/common/Input';
import Modal from 'components/common/Modal';
import UpgradePremiumModal from 'components/common/plans/UpgradePremiumModal';

const TestFormModal = ({ open, close, group, groups }) => {
  const toast = useToast();
  const history = useHistory();
  const { spaceId } = useParams();
  const [createTestRequest] = useApi(api.createTest);
  const totalFreeTests = useSelector(selectUserTotalFreeTests);
  const createTestToggle = useFeature(toggles.createTest, totalFreeTests);
  const dispatch = useDispatch();
  const createTestToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createTest));

  const typeOptions = [
    { value: 'test', label: lang.appKeywords.test, tip: lang.test.testForm.testTypeTip, icon: faStopwatch },
    { value: 'worksheet', label: lang.appKeywords.worksheet, tip: lang.test.testForm.worksheetTypeTip, icon: faFileAlt },
  ];

  const classificationOptions = [
    { value: 'classic', label: lang.appKeywords.classic, tip: lang.test.testForm.classicClassificationTypeTip, icon: faPlusCircle },
    { value: 'rubric', label: lang.appKeywords.rubric, tip: lang.test.testForm.rubricClassificationTypeTip, icon: faTable },
    { value: 'none', label: lang.appKeywords.noClassification, tip: lang.test.testForm.noClassificationTypeTip, icon: faTimesCircle },
  ];

  const [loading, setLoading] = useState(false);
  const [name, setName, nameErrors, setNameErrors] = useInput('');
  const [currentGroup, setCurrentGroup, currentGroupErrors, setCurrentGroupErrors] = useInput(null);
  const [testType, setTestType] = useState(typeOptions[0]);
  const [classificationType, setClassificationType] = useState(classificationOptions[0]);

  const groupOptions = groups.map(group => ({ value: group.id, label: group.label }));

  useEffect(() => {
    setCurrentGroup(group ? { value: group.id, label: group.label } : null);
  }, [group, setCurrentGroup]);

  const closeModal = () => {
    if (!loading) {
      close();
      setTimeout(() => {
        setName('');
        setCurrentGroup(group ? { value: group.id, label: group.label } : null);
        setClassificationType(classificationOptions[0]);
        setNameErrors([]);
        setCurrentGroupErrors([]);
      }, 350);
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    let errors = false;
    const _nameErrors = [];
    const _groupErrors = [];

    if (name.trim().length === 0) {
      errors = true;
      _nameErrors.push(lang.test.testForm.errorNameRequired);
    }

    if (!currentGroup) {
      errors = true;
      _groupErrors.push(lang.test.testForm.errorGroupRequired);
    }

    setNameErrors(_nameErrors);
    setCurrentGroupErrors(_groupErrors);

    if (errors === true) {
      setTimeout(() => {
        setLoading(false);
      }, 200);
      return;
    }

    const newTest = {
      name: name,
      type: testType.value,
      classificationType: classificationType.value,
      groupId: currentGroup.value,
    };

    createTestRequest([], newTest, ({ data }) => {
      if (data.status === 0) {
        const testId = data.testId;

        toast.success(lang.test.testForm.successCreateTest);
        closeModal();

        setTimeout(() => {
          if (spaceId === PERSONAL_SPACE) {
            dispatch(incrementTotalTests());
          }

          history.push(routes.test.ref(spaceId, testId));
        }, 350);
        return;
      }

      setTimeout(() => {
        toast.error(lang.oops);
        setLoading(false);
      }, 200);
    });
  };

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: closeModal,
    },
    {
      name: lang.create,
      loading: loading,
      onClick: handleSubmit,
      dataTour: 'assessment-submit',
    },
  ];

  if (!createTestToggle) {
    return (
      <UpgradePremiumModal
        open={open}
        close={close}
        message={lang.plans.exceededTests(createTestToggleMaxUsages)}
      />
    );
  }

  return (
    <Modal
      open={open}
      close={closeModal}
      header={lang.test.testForm.createTest}
      actions={actions}
      center
      transition
    >
      <Input
        dataTour={'assessment-name'}
        type="text"
        value={name}
        label={lang.test.testForm.testName}
        placeholder={lang.test.testForm.testName}
        onChange={(event) => setName(event.target.value)}
        maxSize={100}
        errors={nameErrors}
      />
      <Spacer px={15} />
      <CardSelector
        dataTour={'assessment-type'}
        headerLabel={lang.test.testForm.testTypeLabel}
        options={typeOptions}
        selected={testType}
        setSelected={setTestType}
      />
      <Spacer px={15} />
      <CardSelector
        dataTour={'assessment-classification-type'}
        headerLabel={lang.test.testForm.classificationTypeLabel}
        options={classificationOptions}
        selected={classificationType}
        setSelected={setClassificationType}
      />
      <Spacer px={15} />
      <Input
        dataTour={'assessment-group'}
        type="select"
        value={currentGroup}
        label={lang.appKeywords.group}
        placeholder={lang.appKeywords.group}
        onChange={(option) => setCurrentGroup(option)}
        options={groupOptions}
        errors={currentGroupErrors}
      />
      <Spacer px={20} />
    </Modal>
  );
};

TestFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  group: PropTypes.object,
  groups: PropTypes.array,
};

export default TestFormModal;
