import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  shortcutsContainer: {
    padding: '40px 30px',
    display: 'flex',
  },
  card: {
    padding: '10px 20px',
    color: theme.textColor,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '&.onClick': {
      cursor: 'default',
    },
    '&.clickable.onClick': {
      cursor: 'pointer',
    },
  },
  icon: {
    width: '30px',
    height: '30px',
    marginTop: '20px',
  },
  linkText: {
    marginTop: '10px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    shortcutsContainer: {
      paddingBottom: '0px',
    },
  },
}));
