import React, { useCallback, useState } from 'react';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from 'theming';

import api from 'api';
import useApi from 'hooks/common/useApi';
import useLoading from 'hooks/common/useLoading';
import lang from 'lang';

import FilterSelector from 'components/common/filter-selector/FilterSelector';
import ColorPicker from 'components/common/label-selector/color-picker/ColorPicker';
import Modal from 'components/common/Modal';

import useStyles from './styles.js';

const LabelEditorModal = ({ open, close, exerciseLabels, updateExerciseLabels, setFilters, groupId, fetchLabels, labelOptions, refresh }) => {
  const classes = useStyles();
  const theme = useTheme();
  const toast = useToast();
  const isDark = useSelector(state => state.page.isDark);
  const [editLabelRequest] = useApi(api.editLabel);
  const [createLabelRequest] = useApi(api.createLabel);
  const [deleteLabelRequest] = useApi(api.deleteLabel);

  const [color, setColor] = useState('');
  const [name, setName] = useState('');
  const [label, setLabel] = useState(null);
  const [dropdown, setDropdown] = useState(false);
  const [loading, setLoading] = useLoading(false);

  const resetState = () => {
    setColor('');
    setName('');

    setLabel(null);
    setDropdown(false);
  };

  const cleanupAndClose = useCallback(() => {
    setTimeout(resetState, 500);
    close();
  }, [close]);

  const save = useCallback((event) => {
    if (loading) {
      return;
    }

    event.stopPropagation();
    const trimmedName = name.trim();

    if (trimmedName.length === 0) {
      toast.warning(lang.exercises.labels.errorNameRequired);
      return;
    }

    if (color.length === 0) {
      toast.warning(lang.exercises.labels.errorNoColorSelected);
      return;
    }

    setLoading(true);
    if (label) {
      editLabelRequest([label.id], { name: trimmedName, color: color }, ({ data }) => {
        setLoading(false);
        if (data.status === 0) {
          fetchLabels();
          setLabel({ id: label.id, name: trimmedName, color: color });

          if (setFilters) {
            setFilters(currentFilters => {
              const labelIndex = currentFilters.labels.findIndex(filterLabel => filterLabel.id === label.id);

              currentFilters.labels[labelIndex] = { id: label.id, name: trimmedName, color: color };

              cleanupAndClose(event);
              return {
                ...currentFilters,
                labels: currentFilters.labels,
              };
            });
          }

          toast.success(lang.exercises.labels.successEditLabel);
        } else if (data.status === 1) {
          toast.warning(lang.exercises.labels.errorNameRequired);
        } else if (data.status === 2) {
          toast.warning(lang.exercises.labels.repeatedLabelError);
        } else {
          toast.error(lang.oops);
        }
      });

      return;
    }

    createLabelRequest([], { name: trimmedName, color: color, groupId: groupId }, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        updateExerciseLabels([
          ...exerciseLabels,
          { id: data.labelId, name: trimmedName, color: color },
        ], refresh);
        fetchLabels();
        cleanupAndClose(event);
      } else if (data.status === 1) {
        toast.warning(lang.test.errorNameRequired);
      } else if (data.status === 2) {
        toast.warning(lang.exercises.labels.repeatedLabelError);
      } else {
        toast.error(lang.oops);
      }
    });
  }, [cleanupAndClose, color, createLabelRequest, editLabelRequest, exerciseLabels, fetchLabels, groupId, label, loading, name, refresh, setFilters, setLoading, toast, updateExerciseLabels]);

  const deleteLabel = () => {
    deleteLabelRequest([label.id], null, ({ data }) => {
      if (data.status === 0) {
        fetchLabels();
        resetState();
        refresh();
        toast.success(lang.exercises.labels.successDeleteLabel);
      } else {
        toast.error(lang.oops);
      }
    });
  };

  const getActions = () => {
    return [
      {
        name: lang.save,
        color: theme.textColor,
        onClick: save,
        loading: loading,
      },
      {
        name: lang.cancel,
        color: 'black',
        onClick: cleanupAndClose,
      },
    ];
  };

  const itemAction = (item) => {
    setLabel(item);
    setName(item.name);
    setColor(item.color);
  };

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Modal
        open={open}
        close={cleanupAndClose}
        header={lang.exercises.labels.labels}
        actions={getActions()}
        center
        transition
      >
        <div
          className={classes.filterWrapper}
          onClick={(event) => event.stopPropagation()}
        >
          <FilterSelector
            itemOptions={labelOptions}
            itemAction={itemAction}
            contextPlaceholder={lang.exercises.labels.addLabel}
            value={label}
            action={resetState}
            actionLabel={lang.exercises.labels.createLabel}
            actionIcon={faPlus}
          />
          <Render when={label}>
            <Dropdown
              close={() => setDropdown(false)}
              dark={isDark}
              className={classes.dropdown}
            >
              <Button
                onClick={() => setDropdown(true)}
                className={classes.button}
                red
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
              <DropdownMenu
                open={dropdown}
                className={classes.menu}
              >
                <DropdownMenuItem
                  onClick={() => deleteLabel()}
                  className={classes.delete}
                >
                  {lang.delete}
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => setDropdown(false)}
                  className={classes.item}
                >
                  {lang.cancel}
                </DropdownMenuItem>
              </DropdownMenu>
            </Dropdown>
          </Render>
        </div>
        <div>
          <ColorPicker
            color={color}
            setColor={setColor}
            name={name}
            setName={setName}
          />
        </div>
      </Modal>
    </div>
  );
};

LabelEditorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  exerciseLabels: PropTypes.array.isRequired,
  updateExerciseLabels: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  fetchLabels: PropTypes.func.isRequired,
  labelOptions: PropTypes.array,
  refresh: PropTypes.func,
};

export default LabelEditorModal;
