import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const SaveWarningModal = ({ open, close, save }) => {
  const classes = useStyles();

  const actions = [
    {
      name: lang.cancel,
      onClick: close,
      color: 'black',
    },
    {
      name: lang.save,
      onClick: save,
      color: 'blue',
    },
  ];

  return (
    <Modal
      header={lang.exerciseForm.editExercise}
      open={open}
      close={close}
      center
      transition
      actions={actions}
    >
      <div className={classes.saveWarningDescription}>
        {lang.exerciseForm.saveWarningContent}
      </div>
    </Modal>
  );
};

SaveWarningModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  save: PropTypes.func,
};

export default SaveWarningModal;
