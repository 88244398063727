import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { incrementTotalRubrics, selectUserTotalFreeRubrics } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import useQuery from 'hooks/common/useQuery';
import useFeature from 'hooks/useFeature';
import useForm from 'hooks/useForm';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { cleanAndSortGroups } from 'utils';
import { validateRubric } from 'utils/rubrics';

import RubricForm from 'components/rubrics/rubric-form/RubricForm';
import RubricsEditorPageHeader from 'components/rubrics/RubricsEditorPageHeader';

const RubricEditor = () => {
  const { loaded } = usePageLogic(lang.exercises.title, true);
  const query = useQuery();
  const { spaceId, rubricId } = useParams();
  const toast = useToast();
  const history = useHistory();
  const [getTemplateRubricRequest] = useApi(api.getTemplateRubric);
  const [getSchoolGroupsRequest] = useApi(api.getSchoolGroups);
  const [createTemplateRubricRequest] = useApi(api.createTemplateRubric);
  const [editTemplateRubricRequest] = useApi(api.editTemplateRubric);
  const dispatch = useDispatch();
  const totalFreeRubrics = useSelector(selectUserTotalFreeRubrics);
  const createRubricToggle = useFeature(toggles.createRubric, totalFreeRubrics);

  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(null);
  const [getValue, setValue, getErrors, setErrors] = useForm([
    { label: 'name', initialValue: '' },
    { label: 'description', initialValue: '' },
    {
      label: 'criteria',
      initialValue: [
        {
          name: '',
          open: true,
          performanceLevels: [
            { level: 1, description: '' },
            { level: 2, description: '' },
            { level: 3, description: '' },
            { level: 4, description: '' },
            { level: 5, description: '' },
          ],
        },
      ],
      initialErrors: {
        general: [],
        criteria: [
          {
            name: [],
          },
        ],
      },
    },
  ]);

  const fetchTemplateRubric = useCallback((groups) => {
    getTemplateRubricRequest([rubricId], null, ({ data }) => {
      if (data.status === 0) {
        setValue('name', data.rubric.name);
        setValue('description', data.rubric.description);
        setValue('criteria', data.rubric.criteria);

        const criteriaErrors = [];
        data.rubric.criteria.forEach(() => {
          criteriaErrors.push({
            name: [],
          });
        });
        setErrors('criteria', {
          general: [],
          criteria: criteriaErrors,
        });

        const groupId = query().get('group');
        if (!groupId) {
          const group = groups.find(group => group.id === data.rubric.groupId);
          setGroup(group);

          history.replace({
            search: `?group=${group.id}`,
          });
        } else {
          const group = groups.find(group => group.id === groupId);
          setGroup(group);
        }

        loaded();
        return;
      }

      toast.error(lang.oops);
    });
  }, [getTemplateRubricRequest, loaded, query, rubricId, toast, history, setErrors, setValue]);

  const fetchGroups = useCallback(() => {
    getSchoolGroupsRequest([spaceId], null, ({ data }) => {
      if (data.status === 0) {
        const newGroups = cleanAndSortGroups(data.groups);
        setGroups(newGroups);

        if (!rubricId) {
          const currentGroupId = query().get('group');
          const group = newGroups.find(group => group.id === currentGroupId);
          setGroup(group);

          loaded();
          return;
        }

        fetchTemplateRubric(newGroups);
        return;
      }

      toast.error(lang.oops);
    });
  }, [getSchoolGroupsRequest, loaded, query, spaceId, toast, fetchTemplateRubric, rubricId]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const buildNewTemplateRubric = () => ({
    name: getValue('name'),
    description: getValue('description'),
    criteria: getValue('criteria').map(criteria => ({
      name: criteria.name,
      performanceLevels: criteria.performanceLevels,
    })),
  });

  const createTemplateRubric = () => {
    const newTemplateRubric = buildNewTemplateRubric();
    newTemplateRubric.groupId = group.id;

    if (!validateRubric(newTemplateRubric, setErrors)) {
      return;
    }

    setLoading(true);
    createTemplateRubricRequest([], newTemplateRubric, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        dispatch(incrementTotalRubrics(1));
        history.push(routes.rubrics.ref(spaceId, group.id));
        toast.success(lang.rubrics.form.createRubricSuccess);
        return;
      }

      toast.error(lang.oops);
    });
  };

  const editTemplateRubric = () => {
    const newTemplateRubric = buildNewTemplateRubric();

    if (!validateRubric(newTemplateRubric, setErrors)) {
      return;
    }

    setLoading(true);
    editTemplateRubricRequest([rubricId], newTemplateRubric, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        history.push(routes.rubrics.ref(spaceId, group.id));
        toast.success(lang.rubrics.form.editRubricSuccess);
        return;
      }

      toast.error(lang.oops);
    });
  };

  if (!createRubricToggle && !rubricId) {
    history.push(routes.rubrics.ref(spaceId));
    return false;
  }

  if (!group) {
    return null;
  }

  return (
    <Fragment>
      <RubricsEditorPageHeader
        groups={groups}
        group={group}
        setGroup={setGroup}
        saveRubric={rubricId ? editTemplateRubric : createTemplateRubric}
        loading={loading}
      />
      <RubricForm
        getValue={getValue}
        setValue={setValue}
        getErrors={getErrors}
        setErrors={setErrors}
      />
    </Fragment>
  );
};

export default RubricEditor;
