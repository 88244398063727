import React from 'react';
import { Table, TableCell, TableRow } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';
import { sortPerformanceLevels } from 'utils';

import Loading from 'components/common/Loading';

import useStyles from './styles';

const PreviewableRubric = ({ rubric }) => {
  const classes = useStyles();

  if (!rubric) {
    return (
      <Loading active />
    );
  }

  return (
    <div className={classes.rubricContainer}>
      <div className={classes.rubricHeader}>
        <div className={classes.rubricName}>
          {rubric.name || lang.rubrics.form.rubricNameLabel}
        </div>
        <Render when={rubric.description}>
          <div className={classes.rubricDescription}>
            {rubric.description || lang.rubrics.form.rubricDescriptionLabel}
          </div>
        </Render>
      </div>
      <div className={classes.rubricCriteriaContainer}>
        <div className={classes.performanceLevelsHeader}>
          {lang.rubrics.performanceLevels}
        </div>
        <div className={classes.performanceLevelsTableContainer}>
          <Table className={classes.performanceLevelsTable}>
            <TableRow header>
              <TableCell textAlign="center" className={cx(classes.rubricTableCell, classes.criteriaColumn)}>
                {lang.appKeywords.criteria}
              </TableCell>
              <TableCell textAlign="center" className={classes.rubricTableCell}>
                5
              </TableCell>
              <TableCell textAlign="center" className={classes.rubricTableCell}>
                4
              </TableCell>
              <TableCell textAlign="center" className={classes.rubricTableCell}>
                3
              </TableCell>
              <TableCell textAlign="center" className={classes.rubricTableCell}>
                2
              </TableCell>
              <TableCell textAlign="center" className={classes.rubricTableCell}>
                1
              </TableCell>
            </TableRow>
            {rubric.criteria.map((criteria, criteriaIndex) => (
              <TableRow key={criteria.id || criteria.name + criteriaIndex}>
                <TableCell textAlign="center" className={cx(classes.rubricTableCell, classes.criteriaColumn)}>
                  {criteria.name || lang.rubrics.form.criteriaNameLabel}
                </TableCell>
                {sortPerformanceLevels(criteria.performanceLevels).map((level, levelIndex) => (
                  <TableCell
                    key={level.id || levelIndex}
                    className={classes.rubricTableCell}
                  >
                    <div className={classes.performanceLevelDescription}>
                      {level.description}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

PreviewableRubric.propTypes = {
  rubric: PropTypes.object,
};

export default PreviewableRubric;
