import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({

  gap: {
    minHeight: '25px',
    padding: '5px 10px',
    color: 'black',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#6dcfe7B3',
    border: 'none',
    '&.empty': {
      border: 'none',
      backgroundColor: '#F4897BB3',
      minWidth: '40px',
    },
  },
  wrong: {
    color: theme.errorColor,
    fontSize: '22px',
    '&.hideMargin': {
      marginLeft: 'unset',
    },
  },
}));
