import React from 'react';
import { Spacer } from '@intuitivo/outline';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import lang from 'lang';
import routes from 'routes';

import Button from 'components/common/Button';

import useStyles from './styles';

const ReenterAttempt = ({ currentAttempt, saveCurrentAttempt }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.reenterContainer}>
      <Spacer px={40} />
      <Button
        onClick={() => history.push(routes.attempt.ref(currentAttempt.id))}
        sibling
        className={classes.continueAs}
      >
        {lang.publication.continueAs(currentAttempt.name)}
      </Button>
      <div className={classes.newStudent}>
        {lang.publication.notYou}
        {' '}
        <span
          className={classes.newStudentLink}
          onClick={() => saveCurrentAttempt(null)}
        >
          {lang.publication.enterAsNewStudent}
        </span>
      </div>
    </div>
  );
};

ReenterAttempt.propTypes = {
  currentAttempt: PropTypes.object.isRequired,
  saveCurrentAttempt: PropTypes.func.isRequired,
};

export default ReenterAttempt;
