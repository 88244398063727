import { useCallback, useEffect, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';

import { selectFilters, selectItems, selectOrder, selectSearch, selectSearchType, setItems } from 'actions/exploreActions';
import api from 'api';
import { CANCELED, SUCCESS } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

const usePublicItems = () => {
  const toast = useToast();
  const search = useSelector(selectSearch);
  const searchType = useSelector(selectSearchType);
  const filters = useSelector(selectFilters);
  const order = useSelector(selectOrder);
  const dispatch = useDispatch();
  const [getPublicItemsRequest, loading, abortRequest] = useApi(api.getPublicItems);

  const items = useSelector(selectItems);
  const [totalItems, setTotalItems] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const fetchItems = useCallback((page) => {
    const subjectIds = filters
      .filter(filter => filter.type === 'subject')
      .map(subject => subject.id);
    const yearIds = filters
      .filter(filter => filter.type === 'year')
      .map(year => year.id);

    abortRequest();
    getPublicItemsRequest([], { page, search, subjectIds, yearIds, order, searchType }, ({ data }) => {
      setTimeout(() => {
        if (data.status === SUCCESS) {
          setInitialLoading(false);
          dispatch(setItems(data.items));
          setTotalItems(data.totalItems);
          setHasMore(data.hasMore);
          return;
        } else if (data.status === CANCELED) {
          return;
        }

        setInitialLoading(false);
        toast.error(lang.oops);
      }, 300);
    });
  }, [getPublicItemsRequest, toast, search, filters, order, searchType, dispatch, abortRequest]);

  const next = useCallback(() => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchItems(nextPage);
  }, [page, fetchItems]);

  useEffect(() => {
    setPage(1);
    dispatch(setItems([]));
    setInitialLoading(true);

    fetchItems(1);
  }, [fetchItems, dispatch]);

  const noResults = page === 1 && totalItems === 0 && !hasMore && !loading && !initialLoading;

  return [items, totalItems, initialLoading, loading, next, hasMore, noResults];
};

export default usePublicItems;
