import React, { createRef, Fragment } from 'react';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import Input from 'components/common/Input';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const ExerciseCriteriaGrader = ({ criteriaGrades, saveCriteriaGrade, updateGrade, disabled, readOnly }) => {
  const classes = useStyles();
  const toast = useToast();

  if (!criteriaGrades) {
    return null;
  }

  const criteriaGradesRefs = {};
  criteriaGrades.forEach(criteriaGrade => {
    criteriaGradesRefs[criteriaGrade.id] = createRef();
  });

  const onSubmit = (event, ref) => {
    event.preventDefault();
    ref.current.blur();
  };

  const _saveCriteriaGrade = (criteriaGradeId, newGrade) => {
    if (!newGrade && newGrade !== 0) {
      toast.warning(lang.test.grades.criteriaGradeNotEmptyError);
      return;
    }

    if (newGrade < 0) {
      toast.warning(lang.test.grades.criteriaGradeNegativeValueError);
      return;
    }

    if (newGrade > 5) {
      toast.warning(lang.test.grades.criteriaGradeTooHighError);
      return;
    }

    saveCriteriaGrade(criteriaGradeId, newGrade);
  };

  return (
    <Fragment>
      {criteriaGrades.map(criteriaGrade => (
        <Render
          when={criteriaGrade.weight !== 0}
          key={criteriaGrade.id}
        >
          <div className={classes.criteriaGrade}>
            <span className={classes.criteriaText}>
              {criteriaGrade.weight}
              {'% '}
              {criteriaGrade.criteriaName}
            </span>
            <Render when={!readOnly}>
              <Tooltip tip={disabled ? lang.test.grades.unavailableStudent : null}>
                <form onSubmit={(event) => onSubmit(event, criteriaGradesRefs[criteriaGrade.id])}>
                  <Input
                    type="number"
                    value={criteriaGrade.grade}
                    className={classes.gradeInput}
                    onChange={(event) => updateGrade(parseInt(event.target.value), criteriaGrade.id)}
                    onBlur={(event) => _saveCriteriaGrade(criteriaGrade.id, parseInt(event.target.value))}
                    minSize={0}
                    maxSize={5}
                    errors={criteriaGrade.grade > 5 || criteriaGrade.grade < 0}
                    disabled={disabled}
                    _ref={criteriaGradesRefs[criteriaGrade.id]}
                  />
                </form>
              </Tooltip>
            </Render>
            <span className={classes.criteriaText}>
              <Render when={readOnly}>
                {criteriaGrade.grade}
              </Render>
              {' / 5'}
            </span>
          </div>
        </Render>
      ))}
    </Fragment>
  );
};

ExerciseCriteriaGrader.propTypes = {
  criteriaGrades: PropTypes.array,
  saveCriteriaGrade: PropTypes.func,
  updateGrade: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default ExerciseCriteriaGrader;
