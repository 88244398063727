import React from 'react';
import { faCheckCircle, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toggle } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import FlowStep from '../FlowStep';
import Button from 'components/common/Button';
import RichText from 'components/common/rich-text/RichText';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const AskForChoices = ({ number, shuffleChoices, setShuffleChoices, choices, setChoices, restricted }) => {
  const classes = useStyles();
  const multipleCorrectChoicesToggles = useFeature(toggles.multipleCorrectChoices);
  const orderChoicesToggle = useFeature(toggles.orderChoicesToggle);
  const correctChoices = choices.filter(choice => choice.isCorrect).length;
  const toast = useToast();

  const addChoice = () => {
    setChoices(choices => {
      return [
        ...choices,
        {
          id: v4(),
          value: {},
          isCorrect: false,
          identifier: 'choice_' + (choices.length + 1).toString() },
      ];
    });
  };

  const updateAnswerRestricted = (choiceId) => {
    setChoices(choices => {
      const isCurrentChoiceCorrect = choices.find(choice => choice.id === choiceId).isCorrect;
      if (correctChoices === 1) {
        return choices.map(choice => ({ ...choice, isCorrect: choice.id === choiceId }));
      } else {
        if (correctChoices === 2 && isCurrentChoiceCorrect) {
          toast.error(lang.exerciseForm.editMultipleOptionFail);
          return choices;
        } else {
          return choices.map(choice => choice.id === choiceId ? { ...choice, isCorrect: !choice.isCorrect } : choice);
        }
      }
    });
  };

  const updateAnswer = (choiceId) => {
    setChoices(choices =>
      multipleCorrectChoicesToggles
        ? choices.map(choice => choice.id === choiceId ? { ...choice, isCorrect: !choice.isCorrect } : choice)
        : choices.map(choice => choice.id === choiceId ? { ...choice, isCorrect: true } : { ...choice, isCorrect: false }),
    );
  };

  const deleteChoice = (choiceId) => {
    if (choices.length < 3) {
      return;
    }

    setChoices(choices => {
      return choices.filter((choice) => choice.id !== choiceId);
    });
  };

  const updateChoice = (choiceId, value) => {
    setChoices(choices =>
      choices.map(choice => choice.id === choiceId ? { ...choice, value } : choice),
    );
  };

  const setOrdered = (value) => {
    setShuffleChoices(value);
    setChoices(choices =>
      choices.map((oldChoice, index) => ({
        ...oldChoice,
        order: index,
      })),
    );
  };

  const getChoicesSubheader = () => {
    if (!restricted) {
      return lang.exerciseForm.choicesTip;
    } else if (correctChoices === 1) {
      return lang.exerciseForm.choicesTip + ' ' + lang.exerciseForm.oneCorrectOption;
    } else if (correctChoices > 1) {
      return lang.exerciseForm.choicesTip + ' ' + lang.exerciseForm.multipleCorrectOptions;
    }
  };

  return (
    <FlowStep
      stepNumber={number}
      subHeader={getChoicesSubheader()}
      header={
        <div className={classes.headerWrapper}>
          <div>
            {lang.exerciseForm.choices}
          </div>
          {orderChoicesToggle && !restricted &&
          <div className={classes.toggleWrapper}>
            {lang.exerciseForm.choice.shuffleChoices}
            <Toggle
              checked={shuffleChoices}
              onChange={(checked) => setOrdered(checked)}
            />
          </div>
          }
        </div>
      }
    >
      <div className={classes.choicesContainer}>
        {choices.map((choice, index) => (
          <div
            key={choice.id}
            className={cx(classes.choiceRow, { active: false })}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={cx(classes.checkBtn, { active: choice.isCorrect })}
              onClick={() => restricted ? updateAnswerRestricted(choice.id) : updateAnswer(choice.id)}
            />
            <RichText
              placeholder={lang.exerciseForm.option + ' ' + (index + 1)}
              value={choice.value}
              onChange={(_content, _delta, _source, editor) => updateChoice(choice.id, editor.getContents())}
              className={classes.optionInput}
            />
            {!restricted &&
              <Tooltip
                tip={choices.length <= 2 ? null : lang.exerciseForm.removeOption}
                place={'left'}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className={cx(classes.deleteBtn, { disabled: choices.length <= 2 })}
                  onClick={() => deleteChoice(choice.id)}
                />
              </Tooltip>
            }
          </div>
        ))}
        {!restricted &&
          <Button onClick={addChoice}>
            <FontAwesomeIcon icon={faPlus} />
            <div className={classes.newChoiceText}>
              {lang.exerciseForm.addOption}
            </div>
          </Button>
        }
      </div>
    </FlowStep>
  );
};

AskForChoices.propTypes = {
  number: PropTypes.number,
  shuffleChoices: PropTypes.bool,
  setShuffleChoices: PropTypes.func,
  choices: PropTypes.array,
  setChoices: PropTypes.func,
  restricted: PropTypes.bool,
};

export default AskForChoices;
