import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';
import routes from 'routes';

import Button from 'components/common/Button';
import Logo from 'components/common/Logo';

import useStyles from './styles';

const UpgradePremium = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.upgradePremiumContainer}>
      <div className={cx(classes.section, classes.logo)}>
        <Logo medium />
      </div>
      <Render when={message}>
        <div className={cx(classes.section, classes.message)}>
          {message}
        </div>
      </Render>
      <div className={cx(classes.section, classes.optionsContainer)}>
        <div className={cx(classes.option, classes.getPremiumSection)}>
          <div className={cx(classes.section, classes.optionTitle)}>
            {lang.plans.getPremium}
          </div>
          <div className={cx(classes.section, classes.optionMessage)}>
            {lang.plans.unlimitedAccessMessage}
          </div>
          <div className={cx(classes.section, classes.ctaContainer)}>
            <Button
              className={cx(classes.ctaButton, classes.upgradeButton)}
              sibling
              onClick={() => window.location = (routes.profile.ref() + '?plan=premium')}
            >
              {lang.plans.upgrade}
            </Button>
          </div>
        </div>
        <div className={cx(classes.option, classes.recommendSchoolSection)}>
          <div className={cx(classes.section, classes.optionTitle)}>
            {lang.plans.recommendSchool}
          </div>
          <div className={cx(classes.section, classes.optionMessage)}>
            {lang.plans.dedicatedSpace}
          </div>
          <div className={cx(classes.section, classes.ctaContainer)}>
            <Button
              className={cx(classes.ctaButton, classes.recommendButton)}
              sibling
              onClick={() => window.open(routes.recommendSchool.ref(), '_blank')}
            >
              {lang.plans.recommend}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

UpgradePremium.propTypes = {
  message: PropTypes.string,
};

export default UpgradePremium;
