import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles from './styles';

const EntityHeader = ({ children, attributes, listeners, isDraggable, isOverlay, colors }) => {
  const classes = useStyles();

  const style = {
    color: colors?.textColor,
    backgroundColor: colors?.headerColor,
  };

  return (
    <div
      className={cx(classes.headerContainer, { isDraggable, isOverlay })}
      style={style}
      {...(isDraggable ? attributes : {})}
      {...(isDraggable ? listeners : {})}
    >
      {children}
    </div>
  );
};

EntityHeader.propTypes = {
  children: PropTypes.any.isRequired,
  attributes: PropTypes.object,
  listeners: PropTypes.object,
  isDraggable: PropTypes.bool,
  isOverlay: PropTypes.bool,
  colors: PropTypes.object,
};

export default EntityHeader;
