import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import { CREATE_NEW_EXERCISE, ADD_EXISTING_EXERCISE, CREATE_INFO_BLOCK, CREATE_SECTION, CREATE_PAUSE_ITEM } from '../../../../constants/testContentTypes';
import AddInformationBlock from '../AddInformationBlock';
import AddPauseItem from '../AddPauseItem';
import ContentTypeSelector from '../ContentTypeSelector';
import CreateTestExercise from '../CreateTestExercise';
import CreateTestSection from '../CreateTestSection';
import SelectExercises from '../SelectExercises';
import Card from 'components/common/Card';

import useStyles from './styles';

const AddContent = ({ refresh, sectionId }) => {
  const classes = useStyles();

  const [from, setFrom] = useState(null);

  const afterAdd = () => {
    setFrom(null);
    refresh();
  };

  const clearFrom = () => {
    setFrom(null);
  };

  const getContent = () => {
    if (from === null) {
      return (
        <div data-tour="assessment-content-form" className={classes.contentWrap}>
          <div className={classes.header}>
            <div className={classes.title}>
              {lang.test.addQuestion}
            </div>
          </div>
          <ContentTypeSelector
            setFrom={setFrom}
            insideSection={!!sectionId}
          />
        </div>
      );
    } else if (from === CREATE_NEW_EXERCISE) {
      return (
        <CreateTestExercise
          sectionId={sectionId}
          cancel={() => setFrom(null)}
          afterAdd={afterAdd}
        />
      );
    } else if (from === ADD_EXISTING_EXERCISE) {
      return (
        <SelectExercises
          sectionId={sectionId}
          cancel={() => setFrom(null)}
          afterSubmit={afterAdd}
        />
      );
    } else if (from === CREATE_INFO_BLOCK) {
      return (
        <AddInformationBlock
          sectionId={sectionId}
          cancel={() => setFrom(null)}
          afterAdd={afterAdd}
        />
      );
    } else if (from === CREATE_PAUSE_ITEM) {
      return (
        <AddPauseItem
          sectionId={sectionId}
          cancel={() => setFrom(null)}
          afterAdd={afterAdd}
        />
      );
    } else if (from === CREATE_SECTION) {
      return (
        <CreateTestSection
          cancel={() => setFrom(null)}
          afterAdd={clearFrom}
        />
      );
    }
  };

  return (
    <Fragment>
      <Card
        width={'100%'}
        height={500}
        className={classes.addExerciseContainer}
      >
        {getContent()}
      </Card>
    </Fragment>
  );
};

AddContent.propTypes = {
  refresh: PropTypes.func,
  sectionId: PropTypes.string,
};

export default AddContent;
