import React, { Fragment } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import Button from 'components/common/Button';
import IconBtn from 'components/common/IconBtn';

import useStyles from './styles';

const AddContentContainer = ({ title, actions, goBack, children }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.header}>
        <div className={classes.titleWrap}>
          <Render when={goBack}>
            <IconBtn
              icon={faArrowLeft}
              tip={lang.goBack}
              onClick={goBack}
            />
          </Render>
          <div className={classes.title}>
            {title}
          </div>
        </div>
      </div>
      {children}
      <div className={classes.btnContainer}>
        {actions && actions.filter(el => !el.hide).map(action => (
          <Button
            dataTour={action.dataTour}
            key={action.label}
            className={classes.btn}
            loading={action.loading}
            onClick={action.onClick}
            black={action.color === 'black'}
            red={action.color === 'red'}
            gray={action.color === 'gray'}
            green={action.color === 'green'}
            sibling
          >
            {action.label}
          </Button>
        ))}
      </div>
    </Fragment>
  );
};

AddContentContainer.propTypes = {
  title: PropTypes.string,
  goBack: PropTypes.func,
  actions: PropTypes.array,
  children: PropTypes.any,
};

export default AddContentContainer;
