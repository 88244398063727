import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    overflowX: 'auto',
  },
  image: {
    width: '700px',
    height: '700px',
    objectFit: 'contain',
  },
  imageWrapper: {
    position: 'relative',
    width: '700px',
    height: '700px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
  },
  outerRing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: `2px solid ${theme.white}00`,
    '&:hover': {
      '& div[class*="delete"]': {
        display: 'initial',
        top: '-50%',
        '&:hover': {
          transform: 'scale(1.5)',
        },
      },
      border: `2px solid ${theme.buttonBackground2}`,
      cursor: 'pointer',
    },
  },
  innerRing: {
    width: '50%',
    height: '50%',
    borderRadius: '50%',
    border: '2px solid #595959',
    '&.active': {
      border: `2px solid ${theme.buttonBackground2}`,
    },
    '&.used': {
      border: `2px solid ${theme.buttonBackground2}`,
    },
  },
  'delete': {
    display: 'none',
    position: 'absolute',
  },
  deleteIcon: {
    color: theme.removeColor,
    backgroundColor: theme.white,
    borderRadius: '50%',
  },
  arrowDelete: {
    color: theme.removeColor,
    backgroundColor: 'white',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  toggleWrapper: {
    display: 'flex',
    gap: '0.35em',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '300 !important',
  },
}));
