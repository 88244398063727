const removeTestItem = (items, sectionId, itemId) => {
  const sectionIndex = items.findIndex(item => item.id === sectionId);

  if (sectionIndex !== -1) {
    const removedExercise = items[sectionIndex].exercises.find(item => item.id === itemId);

    return [
      ...items.slice(0, sectionIndex),
      {
        ...items[sectionIndex],
        exercises: items[sectionIndex].exercises
          .filter(item => item.id !== itemId)
          .map(item => ({
            ...item,
            ...(item.order > removedExercise.order && { order: item.order - 1 }),
          })),
      },
      ...items.slice(sectionIndex + 1),
    ];
  } else {
    const removedItem = items.find(item => item.id === itemId);

    return items
      .filter(item => item.id !== itemId)
      .map(item => ({
        ...item,
        ...(item.order > removedItem.order && { order: item.order - 1 }),
      }));
  }
};

export default removeTestItem;
