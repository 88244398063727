import React, { Fragment } from 'react';
import { faTasks, faCheckCircle, faCamera, faAlignRight, faAlignJustify, faSortAmountUp, faCodeBranch, faArrowsTurnToDots, faCircleNodes, faTableCells } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import PlansPill from 'components/common/plans/PlansPill';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const AskExerciseType = ({ nextStep }) => {
  const classes = useStyles();
  const connectingToggle = useFeature(toggles.connecting);
  const segmentationToggle = useFeature(toggles.segmentation);
  const tableExerciseToggle = useFeature(toggles.tableExercise);

  const types = [
    { value: 'choices', text: lang.exerciseForm.typeChoices, icon: faTasks },
    { value: 'true-false', text: lang.exerciseForm.typeTrueFalse, icon: faCheckCircle, dataTour: 'assessment-create-true-false-exercise' },
    { value: 'text', text: lang.exerciseForm.typeText, icon: faAlignJustify, dataTour: 'ask-exercise-type-text' },
    { value: 'image', text: lang.exerciseForm.typeImage, icon: faCamera },
    { value: 'filling', text: lang.exerciseForm.typeFilling, icon: faAlignRight },
    { value: 'ordering', text: lang.exerciseForm.typeOrdering, icon: faSortAmountUp },
    { value: 'caption', text: lang.exerciseForm.typeCaption, icon: faArrowsTurnToDots },
    { value: 'connecting', text: lang.exerciseForm.typeConnecting, icon: faCodeBranch, premium: !connectingToggle },
    { value: 'segmentation', text: lang.exerciseForm.typeSegmentation, icon: faCircleNodes, premium: !segmentationToggle },
    { value: 'table', text: lang.exerciseForm.typeTable, icon: faTableCells, 'new': tableExerciseToggle, soon: !tableExerciseToggle },
  ];

  return (
    <Fragment>
      <div data-tour="assessment-create-exercise-type" className={classes.container}>
        {types.map((type) => (
          <div
            data-tour={type.dataTour}
            className={classes.tooltipContainer}
            key={type.value}
          >
            <Tooltip
              tip={type.soon ? lang.comingSoon : null}
            >
              <Card
                className={cx(classes.typeOption, { soon: type.soon })}
                onClick={type.soon ? null : () => nextStep(type.value)}
              >
                {type.new &&
                  <div className={classes.newTag}>
                    {lang.new}
                  </div>
                }
                <FontAwesomeIcon
                  icon={type.icon}
                  className={classes.typeOptionIcon}
                />
                <div className={classes.typeOptionText}>
                  {type.text}
                </div>
                {type.premium &&
                  <div className={classes.premiumTag}>
                    <PlansPill
                      tip={lang.plans.warningCreatePremiumExercise}
                    />
                  </div>
                }
              </Card>
            </Tooltip>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

AskExerciseType.propTypes = {
  nextStep: PropTypes.func,
};

export default AskExerciseType;
