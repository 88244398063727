import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  action: {
    color: theme.textColor5,
    cursor: 'pointer',
    transition: 'color 100ms',
    marginLeft: '15px',
    fontSize: '16px',
    '&:hover': {
      color: theme.textColor2,
    },
  },
}));
