import React from 'react';
import { Toggle } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import lang from 'lang';

import FlowStep from '../FlowStep';
import RichText from 'components/common/rich-text/RichText';

import useStyles from './styles';

const AskForExtraText = ({ number, extraText, setExtraText, extraTextStartExpanded, setExtraTextStartExpanded }) => {
  const classes = useStyles();

  const onChange = (editor, _source) => {
    if (_source === 'user') {
      setExtraText(editor.getContents());
    }
  };

  const header = (
    <div className={classes.header}>
      {lang.exerciseForm.extraText}
      <div className={classes.startOpenToggleContainer}>
        {lang.exerciseForm.extraTextStartOpen}
        <Toggle
          checked={extraTextStartExpanded}
          onChange={() => setExtraTextStartExpanded(!extraTextStartExpanded)}
        />
      </div>
    </div>
  );

  return (
    <FlowStep
      dataTour="ask-for-extra-text"
      stepNumber={number}
      header={header}
    >
      <RichText
        placeholder={lang.exerciseForm.extraTextPlaceholder}
        value={extraText}
        onChange={(_content, _delta, _source, editor) => {
          onChange(editor, _source);
        }}
        className={classes.extraTextInput}
      />
    </FlowStep>
  );
};

AskForExtraText.propTypes = {
  number: PropTypes.number,
  extraText: PropTypes.object,
  setExtraText: PropTypes.func,
  extraTextStartExpanded: PropTypes.bool,
  setExtraTextStartExpanded: PropTypes.func,
};

export default AskForExtraText;
