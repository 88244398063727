import React from 'react';
import PropTypes from 'prop-types';

import QuillRenderer from 'components/common/QuillRenderer';

import useStyles from './styles';

const ExerciseStatement = ({ statement, instructions }) => {
  const classes = useStyles();

  return (
    <QuillRenderer
      className={classes.statement}
      value={statement}
      instructions={instructions}
    />
  );
};

ExerciseStatement.propTypes = {
  statement: PropTypes.any.isRequired,
  instructions: PropTypes.array,
};

export default ExerciseStatement;
