import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  expandable: {
    backgroundColor: theme.cardBackground3,
    border: `1px solid ${theme.cardBorder3}`,
    borderRadius: '0',
    '& .otl-expandableHeader': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0px',
      backgroundColor: theme.cardHeaderBackground3,
      padding: '10px 20px',
      fontWeight: '600',
      borderBottom: `1px solid ${theme.cardBorder3}`,
      '& .otl-expandableHeaderTitle': {
        fontSize: '16px',
      },
      '& .otl-expandableHeaderIcon': {
        height: '20px',
      },
    },
  },
}));
