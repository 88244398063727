import React, { useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import { quillIsEmpty } from 'utils';

import ExerciseForm from '../../../exercises/exercise-form/ExerciseForm';
import AddContentContainer from '../AddContentContainer';

const AddInformationBlock = ({ cancel, afterAdd, sectionId }) => {
  const { testId } = useParams();
  const toast = useToast();
  const [addInformationBlockRequest] = useApi(api.addInformationBlock);

  const [addLoading, setAddLoading] = useState(false);

  const [statement, setStatement] = useState({});

  const save = () => {
    if (quillIsEmpty(statement)) {
      toast.warning(lang.exerciseForm.noEmptyStatement);
      return;
    }

    addInformationBlockRequest([testId], { statement, sectionId }, ({ data }) => {
      if (data.status === 0) {
        setAddLoading(false);
        toast.success(lang.test.successAddInformationBlock);
        afterAdd();
      } else {
        toast.error(lang.oops);
      }
    });
  };

  const actions = [
    {
      label: lang.cancel,
      color: 'black',
      onClick: cancel,
    },
    {
      label: lang.confirm,
      onClick: save,
      loading: addLoading,
    },
  ];

  return (
    <AddContentContainer
      title={lang.test.addInformationBlock}
      goBack={cancel}
      actions={actions}
    >
      <ExerciseForm
        type={'information'}
        statement={statement}
        setStatement={setStatement}
        edit
      />
    </AddContentContainer>
  );
};

AddInformationBlock.propTypes = {
  cancel: PropTypes.func,
  afterAdd: PropTypes.func,
  sectionId: PropTypes.string,
};

export default AddInformationBlock;
