import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';

import RichText from 'components/common/rich-text/RichText';

import useStyles from './styles';

const ExerciseConnectorCreate = ({ connector, deleteConnector, updateConnector, setAction, used, active, index, left, restricted }) => {
  const classes = useStyles();

  return (
    <div
      key={connector.id}
      className={classes.connectorWrapper}
    >
      {left && !restricted && (
        <FontAwesomeIcon
          icon={faTimes}
          className={cx(classes.deleteBtn)}
          onClick={() => deleteConnector(connector.id)}
        />
      )}
      <Render when={!left}>
        <div className={classes.outerRing} onClick={() => setAction(connector.id)}>
          <div id={connector.id} className={cx(classes.innerRing, { active: active, used: used })} />
        </div>
      </Render>
      <RichText
        placeholder={lang.exerciseForm.connecting.connector + ' ' + (index + 1)}
        value={connector.value}
        onChange={(_content, _delta, _source, editor) => updateConnector(connector.id, editor.getContents())}
        className={classes.optionInput}
      />
      <Render when={left}>
        <div className={classes.outerRing} onClick={() => setAction(connector.id)}>
          <div id={connector.id} className={cx(classes.innerRing, { active: active, used: used })} />
        </div>
      </Render>
      {!left && !restricted && (
        <FontAwesomeIcon
          icon={faTimes}
          className={cx(classes.deleteBtn)}
          onClick={() => deleteConnector(connector.id)}
        />
      )}
    </div>
  );
};

ExerciseConnectorCreate.propTypes = {
  connector: PropTypes.object,
  deleteConnector: PropTypes.func,
  updateConnector: PropTypes.func,
  setAction: PropTypes.func,
  used: PropTypes.bool,
  active: PropTypes.bool,
  index: PropTypes.number,
  left: PropTypes.bool,
  restricted: PropTypes.bool,
};

export default ExerciseConnectorCreate;
