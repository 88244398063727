import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  rubricCard: {
    margin: '4px 0',
    cursor: 'default !important',
    width: '100% !important',
    '& .otl-cardContentContainer': {
      padding: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
    },
    '&.selectable': {
      margin: '5px 0',
    },
  },
  rubricLeftWrap: {
    padding: '11px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    '&.student': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  rubricName: {
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '1',
    boxOrient: 'vertical',
    marginTop: '0 !important',
    fontSize: '17px',
    fontFamily: 'Varela Round',
  },
  rubricInfoWrap: {
    fontSize: '12px',
    color: theme.grey,
    display: 'flex',
    marginTop: '8px',
  },
  rubricInfoLabel: {
    marginLeft: '6px',
  },
}));
