import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.25em',
  },
  labelWrap: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: theme.buttonBackground3,
    border: `1px solid ${theme.buttonBorder3}`,
    maxWidth: '700px',
    minHeight: '38px',
    justifyContent: 'center',
    width: '100%',
  },
  labelContent: {
    marginLeft: '6px',
    wordWrap: 'break-word !important',
    whiteSpace: 'pre-wrap',
    '& > p': {
      margin: '0',
    },
    '&.disabled': {
      pointerEvents: 'none',
      '& .ql-video': {
        pointerEvents: 'auto',
      },
      '& audio': {
        pointerEvents: 'auto',
      },
    },
  },
  identifier: {
    color: 'grey',
    marginLeft: '0.25em',
  },
}));
