import React, { useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import lang from 'lang';

import FilterPool from 'components/common/filter-selector/FilterPool';

import useStyles from './styles';

const AddFilterDropdown = ({ open, close, filters, toggleFilter }) => {
  const classes = useStyles();
  const ref = useRef();

  const SUBJECTS_TAB = 0;
  const YEARS_TAB = 1;

  const [active, setActive] = useState(SUBJECTS_TAB);

  useEffect(() => {
    if (ref.current && open) {
      ref.current.focus();
    }
  }, [open, ref]);

  if (!document.getElementById('add-filter')) {
    return null;
  }

  const onBlur = (event) => {
    if (event.relatedTarget && event.relatedTarget.id === 'add-filter') {
      return;
    }

    close();
  };

  const subjects = filters
    .filter(filter => filter.type === 'subject')
    .map(filter => ({
      ...filter,
      type: 'action',
      color: filter.active ? filter.color : null,
      hoverColor: filter.active ? filter.hoverColor : null,
      action: () => toggleFilter(filter),
    }));

  const years = filters
    .filter(filter => filter.type === 'year')
    .map(filter => ({
      ...filter,
      type: 'action',
      color: filter.active ? filter.color : null,
      hoverColor: filter.active ? filter.hoverColor : null,
      action: () => toggleFilter(filter),
    }));

  return ReactDOM.createPortal(
    <Render when={open}>
      <div
        className={classes.addFilterContainer}
        tabIndex="-1"
        onBlur={onBlur}
        ref={ref}
      >
        <Tabs
          className={cx(classes.tabsContainer, { subjects: !active })}
          current={active}
        >
          <Tab
            label={lang.explore.subjectAreas}
            className={classes.tab}
            setActive={() => setActive(SUBJECTS_TAB)}
          >
            <FilterPool
              poolItems={subjects}
              poolItemAction={toggleFilter}
            />
          </Tab>
          <Tab
            label={lang.explore.schoolYears}
            className={classes.tab}
            setActive={() => setActive(YEARS_TAB)}
          >
            <FilterPool
              poolItems={years}
            />
          </Tab>
        </Tabs>
      </div>
    </Render>,
    document.getElementById('add-filter'),
  );
};

AddFilterDropdown.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  filters: PropTypes.array,
  toggleFilter: PropTypes.func,
};

export default AddFilterDropdown;
