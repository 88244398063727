import { useCallback, useRef } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { useToast } from '@intuitivo-pt/outline-ui';
import axios from 'axios';
import { AbortController } from 'node-abort-controller';

import { loginRequest } from 'authConfig';
import { AZURE_SID, LOGIN_STRATEGY, SESSION_NONCE } from 'constants/cookies';
import { LOCAL } from 'constants/environments';
import { LOCAL_STRATEGY, MICROSOFT_STRATEGY } from 'constants/loginStrategies';
import { DELETE, GET, PATCH, POST, PUT } from 'constants/requestMethods';
import { CANCELED, ERROR } from 'constants/responseCodes';
import lang from 'lang';
import routes from 'routes';
import { getCookie } from 'utils';

const useApi = (endpoint, skipAuth) => {
  const loadingRef = useRef(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const controller = useRef(new AbortController());
  const toast = useToast();

  const getToken = useCallback(() => {
    return new Promise((res, rej) => {
      const loginStrategy = getCookie(LOGIN_STRATEGY);

      if (loginStrategy === LOCAL_STRATEGY) {
        const token = getCookie(SESSION_NONCE);
        return res(token);
      } else if (loginStrategy === MICROSOFT_STRATEGY) {
        const sid = getCookie(AZURE_SID);

        instance.acquireTokenSilent({
          account: account,
          scopes: loginRequest.scopes,
        }).then((token) => {
          return res(token.idToken);
        }).catch(() => {
          instance.ssoSilent({
            sid: sid,
            ...loginRequest,
          }).catch(() => {
            instance.loginRedirect({
              sid: sid,
              ...loginRequest,
            });
          });
        });
        return;
      }

      return rej(null);
    });
  }, [account, instance]);

  const callApi = useCallback((path, method, body, headers, callback) => {
    const resHandler = (res) => {
      if (res.data.status === -1) {
        window.location = routes.auth.ref();
        return;
      }

      callback?.(res);
      loadingRef.current = false;
    };

    const errorHandler = (error) => {
      if (error.code === CANCELED) {
        callback({ data: { status: CANCELED } });
        return;
      }

      if (error.response.status === 401) {
        window.location = routes.auth.ref();
        return;
      }

      if (error.response.status !== 401) {
        toast.error(lang.internetWentWrong);
      }

      callback({ data: { status: ERROR } });
      loadingRef.current = false;
    };

    let finalApi = process.env.REACT_APP_TESTS_API_URL;
    if (process.env.REACT_APP_NODE_ENV === LOCAL) {
      const hostname = window.location.hostname;
      finalApi = finalApi.replace('localhost', hostname);
    }

    if (method === GET) {
      axios.get(finalApi + path, { headers, signal: controller.current.signal }).then(resHandler).catch(errorHandler);
    } else if (method === POST) {
      axios.post(finalApi + path, body, { headers, signal: controller.current.signal }).then(resHandler).catch(errorHandler);
    } else if (method === PUT) {
      axios.put(finalApi + path, body, { headers, signal: controller.current.signal }).then(resHandler).catch(errorHandler);
    } else if (method === PATCH) {
      axios.patch(finalApi + path, body, { headers, signal: controller.current.signal }).then(resHandler).catch(errorHandler);
    } else if (method === DELETE) {
      axios.delete(finalApi + path, { headers, signal: controller.current.signal }).then(resHandler).catch(errorHandler);
    }
  }, [toast]);

  const request = useCallback((params, data, callback) => {
    loadingRef.current = true;

    const path = endpoint.path(...params);

    const headers = {};
    let body;

    if (endpoint.contentType === 'multipart/form-data') {
      headers['Content-Type'] = endpoint.contentType;

      const formData = new FormData();

      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });

      body = formData;
    } else {
      body = data;
    }

    if (!skipAuth) {
      getToken().then((token) => {
        headers.Authorization = 'Bearer ' + token;

        callApi(path, endpoint.method, body, headers, callback);
      }).catch(() => {
        callback({ data: { status: ERROR } });
      });
      return;
    }

    callApi(path, endpoint.method, body, headers, callback);
  }, [getToken, callApi, endpoint, skipAuth]);

  const abortRequest = useCallback(() => {
    controller.current.abort();
    controller.current = new AbortController();
  }, [controller]);

  return [request, loadingRef.current, abortRequest];
};

export default useApi;
