import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import api from 'api';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';
import routes from 'routes';
import { cleanAndSortGroups } from 'utils';

import PageHeader from 'components/common/layout/PageHeader';
import CreateGroupModal from 'components/groups/CreateGroupModal';
import GroupList from 'components/groups/GroupList';

const Groups = () => {
  const { pageLoading, loaded, reload } = usePageLogic(lang.groups.title, true);
  const { spaceId } = useParams();
  const [getSchoolGroupsRequest] = useApi(api.getSchoolGroups);

  const [groups, setGroups] = useState([]);
  const [createGroupModal, setCreateGroupModal] = useState(false);

  const fetchGroups = useCallback(() => {
    getSchoolGroupsRequest([spaceId], null, ({ data }) => {
      if (data.status === 0) {
        loaded();

        const newGroups = cleanAndSortGroups(data.groups);
        const filteredGroups = newGroups.filter(group => !group.isPersonal);

        setGroups(filteredGroups);
      }
    });
  }, [getSchoolGroupsRequest, loaded, spaceId]);

  useEffect(() => {
    if (pageLoading) {
      fetchGroups();
    }
  }, [pageLoading, fetchGroups]);

  const path = [
    {
      type: 'link',
      text: lang.appKeywords.groups,
      to: routes.groups.ref(spaceId),
    },
  ];

  const actions = [
    {
      label: lang.groups.createNewGroup,
      onClick: () => setCreateGroupModal(true),
      dataTour: 'groups-create-new-group-button',
    },
  ];

  return (
    <Fragment>
      <PageHeader
        path={path}
        actions={actions}
      />
      <CreateGroupModal
        open={createGroupModal}
        setCreateGroupModal={setCreateGroupModal}
        reload={reload}
      />
      <GroupList
        groups={groups}
        setCreateGroupModal={setCreateGroupModal}
        reload={reload}
      />
    </Fragment>
  );
};

export default Groups;
