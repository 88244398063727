import React from 'react';
import { Spacer } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import PreviewRubric from '../PreviewRubric';
import CriteriaStep from '../steps/criteria-step/CriteriaStep';
import GeneralStep from '../steps/GeneralStep';

import useStyles from './styles';

const RubricForm = ({ getValue, setValue, getErrors, setErrors }) => {
  const classes = useStyles();

  return (
    <div className={classes.rubricFormContainer}>
      <GeneralStep
        getValue={getValue}
        setValue={setValue}
        getErrors={getErrors}
      />
      <Spacer px={20} />
      <CriteriaStep
        getValue={getValue}
        setValue={setValue}
        getErrors={getErrors}
        setErrors={setErrors}
      />
      <Spacer px={20} />
      <PreviewRubric
        rubric={{
          name: getValue('name'),
          description: getValue('description'),
          criteria: getValue('criteria'),
        }}
      />
    </div>
  );
};

RubricForm.propTypes = {
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  getErrors: PropTypes.func,
  setErrors: PropTypes.func,
};

export default RubricForm;
