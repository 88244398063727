import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px',
  },
  optionButton: {
    fontSize: '16px !important',
    border: `1px solid ${theme.buttonBorder4}`,
    padding: '15px 30px !important',
  },
  fromOptionText: {
    marginLeft: '8px',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    container: {
      flexDirection: 'column',
    },
    optionButton: {
      fontSize: '15px !important',
      margin: '0',
      width: '100%',
      marginTop: '10px',
    },
  },
}));
