import React, { useState } from 'react';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Loading from 'components/common/Loading';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const TemplateEntityCard = ({ children, isSelected, selectable, actions, extraActions, className, onClick, dataTour }) => {
  const classes = useStyles();

  const [dropdown, setDropdown] = useState(false);

  const itemAction = (action, disable) => {
    if (disable) {
      return;
    }
    action();
    setDropdown(false);
  };

  const getActions = () => {
    if (!actions || actions.length === 0) {
      return;
    }

    return actions.map((action) => (
      <div
        data-tour={action.dataTour}
        key={action.label}
        className={cx(classes.actions, { [action.hideMedia]: true })}
      >
        <div
          className={cx(classes.optionsBtn, { isPublished: action.isPublished })}
          onClick={() => itemAction(action.onClick, action.disabled || action.loading)}
        >
          <Loading active={action.loading} className={classes.optionLoading} />
          <Render when={!action.loading}>
            <FontAwesomeIcon
              icon={action.icon}
            />
          </Render>
          <div className={classes.optionText}>
            {action.label}
          </div>
        </div>
      </div>
    ));
  };

  const getExtraActions = () => {
    const allActions = [
      ...(actions || []).map(action => ({ ...action, mobile: true })),
      ...(extraActions || []),
    ];

    const mobileOnly = allActions.every(action => action.mobile);

    if ((!allActions || allActions.length === 0)) {
      return;
    }

    return (
      <Dropdown
        close={() => setDropdown(false)}
        left
      >
        <div
          data-tour={`extra-actions-dropdown-${dataTour}`}
          className={cx(classes.optionsBtnBig, { mobileOnly })}
          onClick={() => setDropdown(!dropdown)}
        >
          <FontAwesomeIcon
            icon={faEllipsisV}
          />
        </div>
        <DropdownMenu
          open={dropdown}
          className={classes.optionsDropdown}
        >
          {allActions.filter(el => !el.hide).map((action) => (
            <Tooltip tip={action.tip} key={action.label}>
              <DropdownMenuItem
                className={cx(classes.dropdownItem, { isPublished: action.isPublished, mobile: action.mobile, [action.hideMedia]: true, disabled: action.disabled })}
                onClick={() => itemAction(action.onClick, action.disabled || action.loading)}
              >
                <div className={classes.dropdownItemBtn}>
                  <Loading active={action.loading} className={classes.dropdownOptionLoading} />
                  <Render when={!action.loading}>
                    <FontAwesomeIcon
                      icon={action.icon}
                    />
                  </Render>
                  {action.label}
                </div>
              </DropdownMenuItem>
            </Tooltip>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <Card data-tour={dataTour} className={cx(classes.card, className)}>
      <div data-tour={`${dataTour}-left`} onClick={onClick} className={cx(classes.childrenWrapper, { isSelected })}>
        <Render when={selectable}>
          <div className={classes.checkboxWrapper}>
            <input
              type="checkbox"
              checked={isSelected}
              onClick={onClick}
              className={classes.exerciseCheckbox}
            />
          </div>
        </Render>
        {children}
      </div>

      <Render when={extraActions || actions}>
        <div data-tour={`${dataTour}-right`} className={classes.rightWrap}>
          {getActions()}
          {getExtraActions()}
        </div>
      </Render>
    </Card>
  );
};

TemplateEntityCard.propTypes = {
  children: PropTypes.any,
  isSelected: PropTypes.bool,
  selectable: PropTypes.bool,
  actions: PropTypes.array,
  extraActions: PropTypes.array,
  className: PropTypes.string,
  onClick: PropTypes.func,
  dataTour: PropTypes.string,
};

export default TemplateEntityCard;
