import React from 'react';
import { faTableCells, faTableList } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import { BY_ROW, BY_TABLE } from 'constants/exerciseGradingOptions';
import lang from 'lang';

import FlowStep from '../FlowStep';
import CardSelector from 'components/test/card-selector/CardSelector';

import useStyles from './styles';

const AskForGradingOption = ({ number, gradingOption, setGradingOption }) => {
  const classes = useStyles();

  const TABLE_GRADING_OPTIONS = [
    {
      value: BY_ROW,
      label: lang.exerciseForm.table.gradeByRow,
      tip: (
        <div className={classes.tooltip}>
          {lang.exerciseForm.table.gradeByRowTip}
        </div>
      ),
      icon: faTableList,
    },
    {
      value: BY_TABLE,
      label: lang.exerciseForm.table.gradeByTable,
      tip: (
        <div className={classes.tooltip}>
          {lang.exerciseForm.table.gradeByTableTip}
        </div>
      ),
      icon: faTableCells,
    },
  ];

  return (
    <FlowStep
      stepNumber={number}
      header={lang.exerciseForm.table.tableGrading}
    >
      <div className={classes.optionsContainer}>
        <CardSelector
          options={TABLE_GRADING_OPTIONS}
          selected={TABLE_GRADING_OPTIONS.find(el => el.value === gradingOption)}
          setSelected={(gradingOption) => setGradingOption(gradingOption.value)}
        />
      </div>
    </FlowStep>
  );
};

AskForGradingOption.propTypes = {
  number: PropTypes.number.isRequired,
  gradingOption: PropTypes.oneOf([BY_ROW, BY_TABLE]).isRequired,
  setGradingOption: PropTypes.func.isRequired,
};

export default AskForGradingOption;
