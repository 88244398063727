import React from 'react';

import useStyles from './styles';

const EntityActionSeparator = () => {
  const classes = useStyles();

  return (
    <div className={classes.actionSeparator} />
  );
};

export default EntityActionSeparator;
