import React from 'react';
import { Spinner } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import { SAVING } from './states';
import useStyles from './styles';

const ExerciseSavingSpinner = ({ state }) => {
  const classes = useStyles();

  if (state === SAVING) {
    return (
      <Spinner
        className={classes.savingIcon}
      />
    );
  } else {
    return null;
  }
};

ExerciseSavingSpinner.propTypes = {
  state: PropTypes.number,
};

export default ExerciseSavingSpinner;
