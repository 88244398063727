import React from 'react';
import { Card } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles from './styles';

const EntityBody = ({ id, children, _ref, noCard, style, className, colors, dataTour }) => {
  const classes = useStyles();

  const colorStyle = {
    ...style,
    backgroundColor: colors?.bodyColor,
  };

  return (
    <Card
      _ref={_ref}
      id={id}
      style={colorStyle}
      data-tour={dataTour}
      className={cx(classes.exerciseContainer, { noCard, colors }, className)}
    >
      {children}
    </Card>
  );
};

EntityBody.propTypes = {
  children: PropTypes.any.isRequired,
  _ref: PropTypes.object,
  id: PropTypes.string,
  noCard: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  colors: PropTypes.object,
  dataTour: PropTypes.string,
};

export default EntityBody;
