import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  discardDescription: {
    marginBottom: '20px',
  },
  label: {
    marginBottom: '0.5em',
  },
  error: {
    marginTop: '0.5em',
    color: theme.errorColor,
    fontSize: '14px',
  },
  modal: {
    overflowY: 'visible',
    '& > div:first-of-type': {
      borderRadius: '24px 24px 0 0',
    },
  },
}));
