import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserFeatureToggleMaxUsages } from 'actions/userActions';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Modal from 'components/common/Modal';
import UpgradePremiumModal from 'components/common/plans/UpgradePremiumModal';

import useStyles from './styles';

const BeforePublish = ({ open, close, testClassificationType, hasExercises, hasConnectingExercise, hasSegmentationExercise, hasRubric, areWeightsCorrect, sectionsInfo }) => {
  const classes = useStyles();
  const connectingToggle = useFeature(toggles.connecting);
  const segmentationToggle = useFeature(toggles.segmentation);
  const sectionsToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.sections));

  const hints = [
    {
      label: lang.test.publishModal.hintHaveExercises,
      checked: hasExercises,
    },
  ];

  if (sectionsInfo.hasSections) {
    hints.push({
      label: lang.test.publishModal.hintSectionsHaveExercises,
      checked: sectionsInfo.sectionsHaveExercises,
    });
  }

  if ((hasConnectingExercise && !connectingToggle) || (!segmentationToggle && hasSegmentationExercise)) {
    return (
      <UpgradePremiumModal
        open={open}
        close={() => close(false)}
        message={lang.plans.warningPublishTestWithPremiumExercise}
      />
    );
  }

  if (sectionsInfo.maxSectionExceeded) {
    return (
      <UpgradePremiumModal
        open={open}
        close={() => close(false)}
        message={lang.plans.warningPublishTestWithExceededSections(sectionsToggleMaxUsages)}
      />
    );
  }

  if (testClassificationType === 'rubric') {
    hints.push(
      {
        label: lang.test.publishModal.hintHasRubric,
        checked: hasRubric,
      },
      {
        label: lang.test.publishModal.hintAreWeightsCorrect,
        checked: areWeightsCorrect,
      },
    );
  }

  const actions = [
    {
      name: lang.ok,
      color: 'black',
      onClick: () => close(),
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.test.publishModal.beforePublishing}
      actions={actions}
      center
      transition
    >
      <div className={classes.preHint}>
        {lang.test.publishModal.publishPreHint}
      </div>
      {hints.map((hint, index) => (
        <div className={classes.hintRow} key={index}>
          <input
            className={classes.hintCheckbox}
            type="checkbox"
            checked={hint.checked}
          />
          <label className={classes.hintLabel}>
            {hint.label}
          </label>
        </div>
      ))}
    </Modal>
  );
};

BeforePublish.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  testClassificationType: PropTypes.string,
  hasExercises: PropTypes.bool,
  hasConnectingExercise: PropTypes.bool,
  hasSegmentationExercise: PropTypes.bool,
  hasRubric: PropTypes.bool,
  areWeightsCorrect: PropTypes.bool,
  sectionsInfo: PropTypes.object,
};

export default BeforePublish;
