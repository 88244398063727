import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

import lang from 'lang';

import theme from '../../../../../theme.js';

export const DonutChart = ({ positiveGrades, negativeGrades }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const donutChartData = [
    { name: lang.test.stats.positive, value: positiveGrades },
    { name: lang.test.stats.negative, value: negativeGrades },
  ];

  const donutChartColors = [theme.light.successColor, theme.light.errorColor];

  return (
    <PieChart width={225} height={200}>
      <Pie
        data={donutChartData}
        dataKey="value"
        labelLine={true}
        innerRadius={25}
        outerRadius={55}
        isAnimationActive={true}
        paddingAngle={3}
        label={(entry) => `${(entry.percent * 100).toFixed(0)}%`}
      >
        {donutChartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={donutChartColors[index % donutChartColors.length]} />
        ))}
      </Pie>
      {!isMobile && (
        <Legend />
      )}
      <Tooltip />
    </PieChart>
  );
};

DonutChart.propTypes = {
  positiveGrades: PropTypes.number.isRequired,
  negativeGrades: PropTypes.number.isRequired,
};

export default DonutChart;
