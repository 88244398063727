import React, { useMemo } from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectAttemptId } from 'actions/studentAttemptActions';
import { selectUserIsTeacher } from 'actions/userActions';
import { LOCAL } from 'constants/environments';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import VideoPlayer from 'components/common/VideoPlayer';

import useStyles from './styles';

const VideoPlayerSettings = ({ src, canPause, limitRepetitions, maxRepetitions }) => {
  const classes = useStyles();
  const videoControlToggle = useFeature(toggles.videoControl);
  const isTeacher = useSelector(selectUserIsTeacher);
  const attemptId = useSelector(selectAttemptId);

  const finalSrc = useMemo(() => {
    let finalSrc = src;
    if (process.env.REACT_APP_NODE_ENV === LOCAL) {
      const hostname = window.location.hostname;
      finalSrc = finalSrc.replace('localhost', hostname);
    }

    return finalSrc;
  }, [src]);

  return (
    <div className={classes.container}>
      <Render when={videoControlToggle && isTeacher && !attemptId}>
        <div className={classes.settingsDisplay}>
          {canPause ? lang.exerciseForm.video.canPause : lang.exerciseForm.video.cannotPause}
        </div>
        <Render when={limitRepetitions}>
          <div className={classes.settingsDisplay}>
            {lang.exerciseForm.video.maxRepetitionsLabel}
            {': '}
            {maxRepetitions}
          </div>
        </Render>
      </Render>
      <VideoPlayer
        src={finalSrc}
        canPause={canPause}
        limitRepetitions={limitRepetitions}
        maxRepetitions={maxRepetitions}
      />
    </div>
  );
};

VideoPlayerSettings.propTypes = {
  src: PropTypes.string,
  canPause: PropTypes.bool,
  limitRepetitions: PropTypes.bool,
  maxRepetitions: PropTypes.number,
};

export default VideoPlayerSettings;
