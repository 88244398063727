import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  maxValueLabel: {
    color: theme.textColor2,
  },
  gradeInput: {
    '& input': {
      width: '45px',
      height: '30px',
      padding: '0',
      minHeight: '0',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.textColor8,
      backgroundColor: theme.graderBackground,
      borderRadius: '2px',
      justifyContent: 'center',
      border: 0,
      '&::-webkit-inner-spin-button': {
        appearance: 'none',
      },
      '&:hover': {
        backgroundColor: theme.graderBackgroundHover,
      },
      '&:focus': {
        border: `2px solid ${theme.graderBackground}`,
        backgroundColor: theme.graderBackground2,
      },
      '&::placeholder': {
        fontSize: '10px',
      },
      '&:disabled': {
        backgroundColor: `${theme.graderBackground} !important`,
        opacity: 0.4,
      },
    },
  },
  saveButton: {
    marginLeft: '10px',
  },
  editIcon: {
    marginLeft: '10px',
  },
  graderButton: {
    backgroundColor: theme.backgroundColor2,
    border: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: theme.backgroundColor2,
      border: 0,
      padding: 0,
    },
  },
  maxGradeText: {
    fontWeight: 600,
    fontSize: '14px',
    color: theme.textColor9,
    margin: '0 5px',
  },
  pointsText: {
    fontWeight: 600,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    marginRight: '4px',
    color: theme.textColor9,
  },
  grade: {
    width: '20px',
    height: '20px',
    padding: '5px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.textColor8,
    backgroundColor: theme.graderBackground,
    borderRadius: '2px',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.graderBackgroundHover,
    },
  },
  graderWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  loading: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    pointsText: {
      display: 'none',
    },
  },
}));
