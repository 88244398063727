import { createUseStyles } from 'react-jss';

export default createUseStyles({
  testNextComplete: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5em',
  },
  confirmContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '0.5em',
  },
  confirmButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5em',
  },
});
