import React, { useState } from 'react';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import useStyles from './styles';

const TeachersListActions = ({ actions, teacherId }) => {
  const classes = useStyles();
  const [dropdown, setDropdown] = useState(false);

  const _action = (action, teacherId) => {
    setDropdown(false);
    action.onClick(teacherId);
  };

  return (
    <Dropdown
      close={() => setDropdown(false)}
      left
    >
      <div className={classes.testOptionsBtnBig} onClick={() => setDropdown(!dropdown)}>
        <FontAwesomeIcon
          icon={faEllipsisH}
        />
      </div>
      <DropdownMenu
        open={dropdown}
        className={classes.optionsDropdown}
      >
        {actions.map((action) => (
          <DropdownMenuItem
            key={action.name}
            className={classes.dropdownItem}
            onClick={() => _action(action, teacherId)}
          >
            {action.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

TeachersListActions.propTypes = {
  actions: PropTypes.array,
  teacherId: PropTypes.string,
};

export default TeachersListActions;
