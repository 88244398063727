import React from 'react';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import lang from 'lang';

import Button from 'components/common/Button';

import useStyles from './styles';

const EndMessage = () => {
  const classes = useStyles();

  return (
    <div className={classes.endMessageContainer}>
      <div className={classes.endMessageSeparator} />
      <div className={classes.endMessage}>
        {lang.explore.endMessage}
      </div>
      <Button
        className={classes.scrollBackToTopButton}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        black
      >
        <FontAwesomeIcon
          icon={faArrowUp}
          className={classes.scrollBackToTopIcon}
        />
        {lang.explore.scrollBackToTopMessage}
        <FontAwesomeIcon
          icon={faArrowUp}
          className={classes.scrollBackToTopIcon}
        />
      </Button>
    </div>
  );
};

export default EndMessage;
