import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';

import lang from 'lang';

import PageHeader from 'components/common/layout/PageHeader';
import CancelModal from 'components/exercises/exercise-form/CancelModal';

const Header = ({ groups, group, setGroup, types, type, setType, onCancel, saveExercise, loading, invisible }) => {
  const history = useHistory();
  const { exerciseId } = useParams();

  const [cancelModal, setCancelModal] = useState(false);

  const onTypeChange = (newType) => {
    setType(newType);
    history.push({
      search: `?type=${newType.id}&group=${group.id}`,
    });
  };

  const onGroupChange = (newGroup) => {
    setGroup(newGroup);
    history.push({
      search: `?type=${type.id}&group=${newGroup.id}`,
    });
  };

  const path = [
    {
      type: 'text',
      text: exerciseId ? lang.exerciseForm.editExerciseOf : lang.exerciseForm.createExerciseOf,
    },
    {
      type: 'dropdown',
      id: 'types',
      current: type,
      values: types,
      onChange: onTypeChange,
      dataTour: 'exercise-editor-header-type-dropdown',
    },
    {
      type: 'text',
      text: lang.in,
    },
    {
      type: 'dropdown',
      id: 'groups',
      current: group,
      values: groups,
      onChange: onGroupChange,
      dataTour: 'exercise-editor-header-group-dropdown',
    },
  ];

  const actions = [
    {
      type: 'button',
      label: lang.cancel,
      onClick: () => setCancelModal(true),
      color: 'black',
    },
    {
      type: 'button',
      label: lang.save,
      onClick: saveExercise,
      loading: loading,
      dataTour: 'exercise-editor-header-save-exercise',
    },
  ];

  if (!group || !type) {
    return null;
  }

  return (
    <Fragment>
      <CancelModal
        open={cancelModal}
        close={() => setCancelModal(false)}
        cancel={onCancel}
      />
      <PageHeader
        path={path}
        actions={actions.filter(el => !el.hide)}
        noSeparators
        invisible={invisible}
      />
    </Fragment>
  );
};

Header.propTypes = {
  groups: PropTypes.array,
  group: PropTypes.object,
  setGroup: PropTypes.func,
  types: PropTypes.array,
  type: PropTypes.object,
  setType: PropTypes.func,
  onCancel: PropTypes.func,
  saveExercise: PropTypes.func,
  loading: PropTypes.bool,
  invisible: PropTypes.bool,
};

export default Header;
