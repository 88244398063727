import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { selectUserFeatureToggleMaxUsages, selectUserTotalFreeRubrics } from 'actions/userActions';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import PageHeader from 'components/common/layout/PageHeader';
import UpgradePremiumModal from 'components/common/plans/UpgradePremiumModal';

const RubricsPageHeader = ({ groups, group, setGroup, setSelectedRubrics }) => {
  const history = useHistory();
  const { spaceId } = useParams();
  const totalFreeRubrics = useSelector(selectUserTotalFreeRubrics);
  const createRubricToggle = useFeature(toggles.createRubric, totalFreeRubrics);
  const createRubricToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createRubric));

  const [upgradePremiumModal, setUpgradePremiumModal] = useState(false);

  const onGroupChange = (newGroup) => {
    setGroup(newGroup);
    setSelectedRubrics([]);
    history.push({
      search: newGroup.id ? `?group=${newGroup.id}` : '',
    });
  };

  const path = [
    {
      type: 'link',
      text: lang.appKeywords.rubrics,
      to: routes.rubrics.ref(spaceId),
    },
    {
      type: 'dropdown',
      id: 'groups',
      current: group,
      values: groups,
      onChange: onGroupChange,
    },
  ];

  if (!group) {
    return null;
  }

  const createRubric = () => {
    if (!createRubricToggle) {
      setUpgradePremiumModal(true);
      return;
    }

    history.push({
      pathname: routes.newRubric.ref(spaceId),
      search: `?group=${group.id}`,
    });
  };

  const actions = [
    {
      label: lang.rubrics.createRubric,
      onClick: createRubric,
      dataTour: 'create-new-rubric-button',
    },
  ];

  return (
    <>
      <UpgradePremiumModal
        open={upgradePremiumModal}
        close={() => setUpgradePremiumModal(false)}
        message={lang.plans.exceededRubrics(createRubricToggleMaxUsages)}
      />
      <PageHeader
        path={path}
        actions={actions}
      />
    </>
  );
};

RubricsPageHeader.propTypes = {
  groups: PropTypes.array,
  group: PropTypes.object,
  setGroup: PropTypes.func,
  setSelectedRubrics: PropTypes.func,
};

export default RubricsPageHeader;
