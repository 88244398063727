import { createUseStyles } from 'react-jss';

export default createUseStyles({
  downloadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  downloadButton: {
    marginTop: '10px',
  },
  downloadIcon: {
    marginRight: '10px',
  },
});
