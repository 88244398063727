import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) =>({
  drop: {
    minHeight: '37px',
    borderRadius: '4px',
    backgroundColor: '#e7e7e7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    gap: '0.25em',
    border: '2px solid #d8d8d8',
    boxShadow: 'inset 2px 2px 2px #d8d8d8',
    minWidth: 'unset',
    '&.isEmpty': {
      minWidth: '50px',
    },
    '&:hover': {
      zIndex: '9999999',
    },
    '&.isOver': {
      border: `2px solid ${theme.buttonBackground2}`,
      zIndex: 0,
    },
  },
}));
