import React, { useState } from 'react';
import { Icon, InputText, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Button from 'components/common/Button';

import useStyles from './styles';

const ConfirmPassword = ({ publication, setIsPasswordConfirmed, password, setPassword }) => {
  const classes = useStyles();
  const { publicationId } = useParams();
  const toast = useToast();
  const [confirmTestPasswordRequest, loadingPassword] = useApi(api.confirmTestPassword, true);

  const [showPassword, setShowPassword] = useState(false);

  const confirmTestPassword = () => {
    confirmTestPasswordRequest([publicationId], { password }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setIsPasswordConfirmed(true);
          return;
        }

        if (data.status === 2) {
          toast.warning(lang.tests.student.testWrongPassword);
          return;
        }

        toast.error(lang.oops);
      }, 200);
    });
  };

  if (!publication || !publication.password) {
    return false;
  }

  return (
    <div className={classes.passwordWrapper}>
      <InputText
        suffix={
          <Icon
            className={classes.icon}
            icon={showPassword ? 'eye-slash' : 'eye' }
            onClick={() => setShowPassword(!showPassword)}
          />
        }
        type={showPassword ? 'text' : 'password'}
        label={lang.publication.password}
        placeholder={lang.publication.password}
        onInput={e => setPassword(e.currentTarget.value)}
        value={password}
        max={100}
      />
      <Button
        className={classes.button}
        stretch
        loading={loadingPassword}
        onClick={confirmTestPassword}
      >
        {lang.submit}
      </Button>

    </div>
  );
};

ConfirmPassword.propTypes = {
  publication: PropTypes.object,
  setIsPasswordConfirmed: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
};

export default ConfirmPassword;
