import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useFeature from 'hooks/useFeature';
import toggles from 'toggles';

import QuillRenderer from 'components/common/QuillRenderer';

import useStyles from './styles';

const ExerciseConnector = ({ id, value, used, identifier, disabled, left, right, answerable, activate, active, correction }) => {
  const classes = useStyles();
  const exportIdentifiersToggle = useFeature(toggles.exportIdentifiers);

  const onClick = () => {
    if (answerable) {
      activate(id);
    }
  };

  return (
    <>
      <Render when={right}>
        <div className={classes.ringWrapper}>
          <div className={cx(classes.outerRing, { answerable: answerable })} onClick={() => onClick()}>
            <div id={id + '_connector'} className={cx(classes.innerRing, { active: active, used: used && !correction })} />
          </div>
        </div>
      </Render>
      <div className={classes.card}>
        <Render when={exportIdentifiersToggle && identifier && !answerable}>
          <div className={classes.header}>
            <label className={classes.identifier}>
              {identifier}
            </label>
          </div>
        </Render>
        <Render when={!(exportIdentifiersToggle && identifier) && answerable}>
          <div className={classes.header} />
        </Render>
        <div className={classes.body}>
          <QuillRenderer
            className={cx(classes.connectorContent, { disabled })}
            value={value}
          />
        </div>
      </div>
      <Render when={left}>
        <div className={classes.ringWrapper}>
          <div className={cx(classes.outerRing, { answerable: answerable })} onClick={() => onClick()} >
            <div id={id + '_connector'} className={cx(classes.innerRing, { active: active, used: used && !correction })} />
          </div>
        </div>
      </Render>
    </>
  );
};

ExerciseConnector.propTypes = {
  id: PropTypes.string,
  used: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  value: PropTypes.any,
  identifier: PropTypes.string,
  disabled: PropTypes.bool,
  answerable: PropTypes.bool,
  active: PropTypes.bool,
  activate: PropTypes.func,
  correction: PropTypes.bool,
};

export default ExerciseConnector;
