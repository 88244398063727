import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setClock } from 'actions/pageActions';
import api from 'api';

import useApi from './useApi';

const useServerClock = () => {
  const dispatch = useDispatch();
  const [clockRequest] = useApi(api.clock, true);

  useEffect(() => {
    clockRequest([], null, ({ data }) => {
      if (data.status === 0) {
        const now = (new Date()).getTime();
        const clockOffset = (now - data.now);

        dispatch(setClock(clockOffset));
      }
    });
  }, [clockRequest, dispatch]);
};

export default useServerClock;
