import React from 'react';
import { Table, TableCell, TableRow } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserId } from 'actions/userActions';
import lang from 'lang';
import { deepCopy } from 'utils';

import TeachersListActions from '../TeachersListActions';
import Input from 'components/common/Input';
import Loading from 'components/common/Loading';

import useStyles from './styles';

const TeachersList = ({ teachers, setTeachersToRemove, feedRemoveModal, loading }) => {
  const classes = useStyles();
  const userId = useSelector(selectUserId);

  const getActions = () => {
    return [
      {
        name: lang.remove,
        onClick: (teacherId) => {
          const deleteTeacher = teachers.find(teacher => teacher.id === teacherId);
          deleteTeacher.selected = true;
          feedRemoveModal(teachers);
        },
      },
    ];
  };

  const onSelect = (event, teacherId) => {
    setTeachersToRemove(teachers => {
      const newTeachers = deepCopy(teachers);

      const newTeacher = newTeachers.find(newTeacher => newTeacher.id === teacherId);
      newTeacher.selected = event.target.checked;

      return newTeachers;
    });
  };

  if (loading) {
    return (
      <Loading active />
    );
  }

  return (
    <div data-tour="teachers-list">
      <Table className={classes.teachersTable}>
        <TableRow header>
          <TableCell className={classes.headerCell}>
            {lang.select}
          </TableCell>
          <TableCell className={classes.headerCell}>
            {lang.name}
          </TableCell>
          <TableCell className={cx(classes.headerCell, classes.mobileInvisible)}>
            {lang.email}
          </TableCell>
          <TableCell className={classes.headerCell}>
          </TableCell>
        </TableRow>
        {teachers.map((teacher) => (
          <TableRow key={teacher.id}>
            <TableCell className={classes.teacherCell}>
              <Render when={teacher.id !== userId}>
                <Input
                  type="checkbox"
                  value={teacher.selected}
                  onChange={(event) => onSelect(event, teacher.id)}
                />
              </Render>
            </TableCell>
            <TableCell className={classes.teacherCell}>
              {teacher.fullName}
            </TableCell>
            <TableCell className={cx(classes.teacherCell, classes.mobileInvisible)}>
              {teacher.email}
            </TableCell>
            <Render when={teacher.id !== userId}>
              <TableCell className={classes.teacherCell}>
                <TeachersListActions actions={getActions()} teacherId={teacher.id} />
              </TableCell>
            </Render>
          </TableRow>
        ))}
      </Table>
    </div>
  );
};

TeachersList.propTypes = {
  teachers: PropTypes.array,
  feedRemoveModal: PropTypes.func,
  setTeachersToRemove: PropTypes.func,
  loading: PropTypes.bool,
};

export default TeachersList;
