import React, { useEffect, useCallback, Fragment } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { pageError } from 'actions/pageActions';
import { selectTest, setTest } from 'actions/testActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';
import { getErrorCode } from 'utils';

import Header from 'components/test/Header';
import TestTabs from 'components/test/tabs/TestTabs';

const Test = () => {
  const toast = useToast();
  const { pageLoading, loaded, reload, setPageTitle } = usePageLogic(lang.test.title, true);
  const { testId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const test = useSelector(selectTest);
  const [getTestRequest] = useApi(api.getTest);

  const fetchTest = useCallback(() => {
    getTestRequest([testId], null, ({ data }) => {
      if (data.status === 0) {
        dispatch(setTest(data.test));
        setPageTitle(data.test.name);
      } else if (data.status === 1) {
        toast.error(lang.test.thisTestIsInvalid);
        history.push('/');
      } else if (data.status === -2) {
        dispatch(pageError(getErrorCode(data.status)));
      }
      loaded();
    });
  }, [getTestRequest, toast, loaded, testId, history, setPageTitle, dispatch]);

  useEffect(() => {
    if (pageLoading) {
      dispatch(setTest(null));
      fetchTest();
    }
  }, [fetchTest, pageLoading, dispatch]);

  if (!test || pageLoading) {
    return null;
  }

  return (
    <Fragment>
      <Header
        fetchTest={fetchTest}
        reload={reload}
      />
      <TestTabs
        fetchTest={fetchTest}
      />
    </Fragment>
  );
};

export default Test;
