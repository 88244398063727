import React, { Fragment, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectTestRubric } from 'actions/testActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useForm from 'hooks/useForm';
import lang from 'lang';
import { validateRubric } from 'utils/rubrics';

import Button from 'components/common/Button';
import RubricForm from 'components/rubrics/rubric-form/RubricForm';

import useStyles from './styles';

const EditTestRubric = ({ cancel, afterSubmit }) => {
  const classes = useStyles();
  const toast = useToast();
  const rubric = useSelector(selectTestRubric);
  const [editTestRubricRequest] = useApi(api.editTestRubric);

  const [loading, setLoading] = useState(false);
  const [getValue, setValue, getErrors, setErrors] = useForm([
    { label: 'name', initialValue: rubric.name },
    { label: 'description', initialValue: rubric.description },
    {
      label: 'criteria',
      initialValue: rubric.criteria,
      initialErrors: {
        general: [],
        criteria: rubric.criteria.map(() => ({ name: [] })),
      },
    },
  ]);

  const editTestRubric = () => {
    const newTestRubric = {
      name: getValue('name'),
      description: getValue('description'),
      criteria: getValue('criteria').map((criteria) => (
        {
          id: criteria.id,
          name: criteria.name,
          performanceLevels: criteria.performanceLevels,
        }),
      ),
    };

    if (!validateRubric(newTestRubric, setErrors)) {
      return;
    }

    setLoading(true);
    editTestRubricRequest([rubric.id], newTestRubric, ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          setLoading(false);
          toast.success(lang.test.rubric.successEditRubric);
          afterSubmit();
        }, 300);
        return;
      }

      setTimeout(() => {
        setLoading(false);
        toast.error(lang.oops);
      }, 200);
    });
  };

  const goBack = () => {
    if (loading) {
      return;
    }

    cancel();
  };

  return (
    <Fragment>
      <RubricForm
        getValue={getValue}
        setValue={setValue}
        getErrors={getErrors}
        setErrors={setErrors}
      />
      <div className={classes.btnContainer}>
        <Button
          onClick={goBack}
          className={classes.btn}
          black
        >
          {lang.cancel}
        </Button>
        <Button
          onClick={editTestRubric}
          className={classes.btn}
          loading={loading}
        >
          {lang.edit}
        </Button>
      </div>
    </Fragment>
  );
};

EditTestRubric.propTypes = {
  cancel: PropTypes.func,
  afterSubmit: PropTypes.func,
};

export default EditTestRubric;
