import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import AskRubricFrom from '../AskRubricFrom';
import CreateTestRubric from '../CreateTestRubric';
import SelectRubric from '../SelectRubric';
import Card from 'components/common/Card';
import { ADD_EXISTING_RUBRIC, CHOOSING, CREATE_RUBRIC, DISPLAY_RUBRIC } from 'components/test/tabs/RubricTab/addRubricOptions';

import useStyles from './styles';

const AddRubric = ({ from, setFrom, refresh }) => {
  const classes = useStyles();

  const afterSubmit = () => {
    setFrom(DISPLAY_RUBRIC);
    refresh();
  };

  const cancel = () => {
    setFrom(CHOOSING);
  };

  const getContent = () => {
    if (from === CHOOSING) {
      return (
        <div className={classes.contentWrap}>
          <div className={classes.header}>
            <div className={classes.title}>
              {lang.test.rubric.addRubric}
            </div>
          </div>
          <AskRubricFrom
            setFrom={setFrom}
          />
        </div>
      );
    } else if (from === ADD_EXISTING_RUBRIC) {
      return (
        <SelectRubric
          cancel={cancel}
          afterSubmit={afterSubmit}
        />
      );
    } else if (from === CREATE_RUBRIC) {
      return (
        <CreateTestRubric
          cancel={cancel}
          afterSubmit={afterSubmit}
        />
      );
    }
  };

  return (
    <Fragment>
      <Card
        width="100%"
        height={500}
        className={classes.addRubricContainer}
      >
        {getContent()}
      </Card>
    </Fragment>
  );
};

AddRubric.propTypes = {
  from: PropTypes.number,
  setFrom: PropTypes.func,
  refresh: PropTypes.func,
};

export default AddRubric;
