import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  durationsRow: {
    marginTop: '25px',
    marginBottom: '15px',
    fontSize: '15px',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  durationTitle: {
    fontSize: '15px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    color: theme.textColor3,
    marginBottom: '15px',
    marginTop: '5px',
  },
  duration: {
    marginTop: '5px',
    marginBottom: '25px',
    fontSize: '19px',
  },
  durationCol: {
    minWidth: '150px',
  },
}));
