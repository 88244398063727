import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  preHint: {
    color: theme.textColor2,
    marginBottom: '10px',
  },
  hintRow: {
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
  },
  hintCheckbox: {
    pointerEvents: 'none',
  },
  hintLabel: {
    marginLeft: '4px',
    fontSize: '15px',
    color: theme.textColor2,
  },
}));
