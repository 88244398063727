import { SET_STUDENT_ATTEMPT, SET_ATTEMPT_ITEMS, SET_ATTEMPT_SECTION_ITEMS, SET_NAVIGATION_CURRENT_ITEM, UPDATE_ANSWER, UPDATE_ATTEMPT_SECTION_ENDED, UPDATE_JUSTIFICATION, SET_ATTEMPT_NAVIGATION_ITEMS, SET_ANSWER_IS_FLAGGED } from 'actions/studentAttemptActions';
import setAnswerIsFlagged from 'services/student-attempt-service/setAnswerIsFlagged';
import setAttemptSectionItems from 'services/student-attempt-service/setAttemptSectionItems';
import setNavigationCurrentItem from 'services/student-attempt-service/setNavigationCurrentItem';
import updateItemAnswer from 'services/student-attempt-service/updateItemAnswer';
import updateItemJustification from 'services/student-attempt-service/updateItemJustification';
import updateSectionEnded from 'services/student-attempt-service/updateSectionEnded';

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STUDENT_ATTEMPT:
      return action.studentAttempt;
    case SET_ATTEMPT_ITEMS:
      return {
        ...state,
        attemptItems: action.attemptItems,
      };
    case SET_ATTEMPT_NAVIGATION_ITEMS:
      return {
        ...state,
        navigationItems: action.navigationItems,
      };
    case SET_ATTEMPT_SECTION_ITEMS:
      return {
        ...state,
        attemptItems: setAttemptSectionItems(state.attemptItems, action.sectionId, action.items),
      };
    case SET_NAVIGATION_CURRENT_ITEM:
      return setNavigationCurrentItem(state, action.currentItem, action.sectionId);
    case UPDATE_ANSWER:
      return updateItemAnswer(state, action.answerId, action.answer, action.sectionId);
    case UPDATE_JUSTIFICATION:
      return updateItemJustification(state, action.answerId, action.justification, action.sectionId);
    case UPDATE_ATTEMPT_SECTION_ENDED:
      return updateSectionEnded(state, action.sectionId);
    case SET_ANSWER_IS_FLAGGED:
      return setAnswerIsFlagged(state, action.answerId, action.sectionId, action.isFlagged);
    default:
      return state;
  }
};

export default reducer;
