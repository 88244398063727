import React, { useState } from 'react';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import { Button } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Loading from 'components/common/Loading';

import useStyles from './styles';

const SecondaryPageHeader = ({ header, extraInformations, actions, extraActions }) => {
  const classes = useStyles();

  const [dropdown, setDropdown] = useState(false);

  const getExtraInformations = () => {
    if (!extraInformations) {
      return;
    }

    return extraInformations.map((info) => (
      !info.hide && (
        <div key={info.label} className={classes.description}>
          {info.icon &&
            <div className={classes.descriptionIcon}>
              <FontAwesomeIcon
                icon={info.icon}
              />
            </div>
          }
          {info.label}
        </div>
      )
    ));
  };

  const getActions = () => {
    if (!actions) {
      return;
    }

    return actions.map((action) => (
      !action.hide && (
        <div
          key={action.label}
          data-tour={action.dataTour}
          className={cx(classes.actionWrap, { button: action.type === 'button', active: action.active, disabled: action.disabled || action.loading })}
        >
          <Loading active={action.loading} className={classes.actionLoading} />
          {!action.loading && (
            <>
              {action.type === 'icon' ?
                <div
                  className={classes.actionWrapper}
                  onClick={!action.disabled && action.onClick}
                >
                  <FontAwesomeIcon
                    icon={action.icon}
                    className={classes.actionIcon}
                  />
                  <div className={classes.iconLabel}>
                    {action.label}
                  </div>
                </div>
                :
                <div className={classes.actionButton}>
                  <Button
                    loading={action.loading}
                    onClick={action.onClick}
                    disabled={action.disabled}
                    sibling
                  >
                    <FontAwesomeIcon
                      icon={action.icon}
                    />
                    {action.label}
                  </Button>
                </div>
              }
              {action.component && action.component}
            </>
          )}
        </div>
      )
    ));
  };

  const getExtraActions = () => {
    if (!extraActions || (extraActions.length === 0 && actions.length === 0)) {
      return;
    }

    return (
      <Dropdown
        close={() => setDropdown(false)}
        left
      >
        <div
          data-tour="secondary-page-header-extra-actions-dropdown"
          className={cx(classes.extraActions, { hide: extraActions.filter(action => !action.hide).length === 0 })}
          onClick={() => setDropdown(!dropdown)}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
        <DropdownMenu
          open={dropdown}
          className={classes.extraActionsDropdownMenu}
        >
          {actions && actions.map((action) => (
            !action.hide && (
              <DropdownMenuItem
                key={action.label}
                className={cx(classes.dropdownItemAction, { isActive: action.active })}
                onClick={action.onClick}
              >
                <div className={classes.dropdownItemBtn}>
                  {action.icon && <FontAwesomeIcon icon={action.icon} />}
                  {action.label}
                </div>
              </DropdownMenuItem>
            )
          ))}
          {extraActions && extraActions.map((action) => (
            !action.hide && (
              <DropdownMenuItem
                key={action.label}
                className={classes.extraActionsDropdownItem}
                onClick={action.onClick}
              >
                {action.label}
              </DropdownMenuItem>
            )
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        {header}
      </div>
      <div className={classes.headerSplitter}>
        <div className={classes.headerLeft}>
          {getExtraInformations()}
        </div>
        <div className={classes.headerRight}>
          <div className={classes.headerActions}>
            {getActions()}
            {getExtraActions()}
          </div>
        </div>
      </div>
    </div>
  );
};

SecondaryPageHeader.propTypes = {
  header: PropTypes.string,
  extraInformations: PropTypes.array,
  actions: PropTypes.array,
  extraActions: PropTypes.array,
};

export default SecondaryPageHeader;
