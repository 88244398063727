import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
    marginLeft: '10px',
    '&.noBack': {
      marginLeft: 'unset',
    },
  },
  selectedExercisesWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '14px',
    color: theme.textColor2,
    marginRight: '35px',
  },
  selectedExercisesSeparator: {
    marginLeft: '5px',
    marginRight: '5px',
  },
  clearSelected: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.textColor,
      textDecoration: 'underline',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  btn: {
    marginLeft: '6px',
  },
  dropdown: {
    marginBottom: '10px',
    backgroundColor: theme.inputBackground2,
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #dfdfdf',
    padding: '5px 10px',
    width: '150px',
    justifyContent: 'space-between',
  },
  dropdownItemLabel: {
    height: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dropdownArrow: {
    fontSize: '10px',
    marginLeft: '10px',
  },
  dropdownMenu: {
    backgroundColor: `${theme.inputBackground2} !important`,
    borderRadius: '0',
    border: `1px solid ${theme.inputBorder2} !important`,
    top: 'calc(100% - 1px)',
    width: 'calc(100% - 2px)',
    zIndex: '1',
  },
  dropdownMenuItem: {
    borderBottom: 'none !important',
    margin: '0 !important',
    '&:hover': {
      backgroundColor: theme.selectBackgroundHover,
    },
    '&:nth-last-of-type(1)': {
      borderBottom: 'none !important',
    },
    '&.selected': {
      backgroundColor: theme.selectBackgroundHover,
    },
  },
  dropdownMenuItemLabel: {
    height: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    textAlign: 'end',
    marginBottom: '5px',
    fontSize: '12px',
    color: theme.textColor3,
  },
  selectorWrapper: {
    display: 'flex',
    gap: '15px',
    width: '100%',
    justifyContent: 'flex-end',
    marginBottom: '15px',
  },
  groupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
  },
  schoolWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '10px',
  },
  schoolSelector: {
    width: '100%',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    header: {
      flexWrap: 'wrap',
      margin: 'unset',
    },
    groupWrapper: {
      marginTop: '0.5em',
      marginLeft: 'auto',
    },
    selectedExercisesWrap: {
      marginRight: 'unset',
    },
  },
}));
