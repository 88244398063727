import React from 'react';
import { faInbox, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import lang from 'lang';

import useStyles from './styles';

const NoResults = () => {
  const classes = useStyles();

  return (
    <div className={classes.noResultsContainer}>
      <div className={classes.noResultsHeader}>
        {lang.explore.noResultsHeader}
      </div>
      <div>
        <FontAwesomeIcon
          icon={faTimes}
          className={classes.noResultsIconTimes}
        />
      </div>
      <div>
        <FontAwesomeIcon
          icon={faInbox}
          className={classes.noResultsIconInbox}
        />
      </div>
      <div className={classes.noResultsDescription}>
        {lang.explore.noResultsDescription}
      </div>
      <div className={cx(classes.noResultsDescription, classes.noResultsDescriptionBottom)}>
        {lang.explore.noResultsDescription2}
      </div>
    </div>
  );
};

export default NoResults;
