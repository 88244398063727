import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    backgroundColor: '#cecece',
    borderRadius: '22px',
    maxWidth: '50%',
    '& span[class^="playerContainer"]': {
      height: '100%',
      width: '100%',
      '& video': {
        borderRadius: '18px !important',
        marginBottom: '0.5em',
      },
      '& span[class^="videoControls"]': {
        borderRadius: '0 0 0 18px',
        bottom: '0.51em',
      },
    },
    padding: '0.5em 0.5em 0 0.5em',
  },
  settingsDisplay: {
    padding: '5px 10px 0 10px',
  },
});
