import React from 'react';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import lang from 'lang';

import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const EntityTitle = ({ num, type, name, warning }) => {
  const classes = useStyles();

  if (name) {
    return (
      <div className={classes.title}>
        {name}
      </div>
    );
  }

  const getWarning = () => {
    if (!warning) {
      return null;
    }

    return (
      <Tooltip
        tip={warning}
      >
        <FontAwesomeIcon icon={faTriangleExclamation} className={classes.warningIcon} />
      </Tooltip>
    );
  };

  const getTitle = () => {
    if (type === INFORMATION) {
      return lang.test.informationBlock;
    } else if (type === PAUSE) {
      return lang.test.exercises.pauseItem;
    } else {
      return `${lang.test.question} ` + (num ?? '');
    }
  };

  return (
    <>
      <div className={classes.title}>
        {getTitle()}
      </div>
      {getWarning()}
    </>
  );
};

EntityTitle.propTypes = {
  num: PropTypes.number,
  type: PropTypes.string,
  name: PropTypes.string,
  warning: PropTypes.string,
};

export default EntityTitle;
