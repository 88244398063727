import XLSX, { utils as xlsxUtils } from 'xlsx';

import lang from 'lang';

import addAttempts from './addAttempts';
import addHeader from './addHeader';

const getFileData = (attempts) => {

  const fileData = [[lang.test.grades.studentName]];
  const hasRubric = attempts[0].items.some(el => el.criteriaGrades) || attempts[0].items.some(el => el.exercises && el.exercises.length > 0 && el.exercises[0].criteriaGrades);
  const hasSections = attempts[0].items.some(el => el.exercises);

  const { fileDataWithHeader, merge, maxTotal } = addHeader(attempts, hasRubric, hasSections, fileData);

  const fileDataWithAttempts = addAttempts(attempts, fileDataWithHeader, maxTotal, hasSections, hasRubric);

  const wb = xlsxUtils.book_new();

  const ws = xlsxUtils.aoa_to_sheet(fileDataWithAttempts, { cellDates: true });
  ws['!merges'] = merge;
  ws['!cols'] = [{ wch: 36 }];
  for (let i = 1; i < fileDataWithAttempts[0].length - 2; i++) {
    ws['!cols'].push({ wch: 20 });
  }
  ws['!cols'].push({ wch: 15 });

  xlsxUtils.book_append_sheet(wb, ws, 'Notas');

  const wopts = { bookType: 'xlsx', bookSST: false, type: 'array' };
  const file = XLSX.write(wb, wopts);

  return file;
};

export default getFileData;
