import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval } from 'usehooks-ts';

import { updateAnswer, updateJustification } from 'actions/studentAttemptActions';
import { selectUserLoggedIn } from 'actions/userActions';
import api from 'api';
import { ANSWER, JUSTIFICATION } from 'constants/answerTypes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import toggles from 'toggles';

const FLUSH_POLLING_TIME = 2_000;

const useAnswerQueue = (attemptId) => {
  const loggedIn = useSelector(selectUserLoggedIn);
  const [answerExerciseRequest] = useApi(api.answerExercise, !loggedIn);
  const [answerJustificationRequest] = useApi(api.answerJustification, !loggedIn);
  const asyncAnswersToggle = useFeature(toggles.asyncAnswers);
  const dispatch = useDispatch();

  const [pendingAnswers, setPendingAnswers] = useState(new Map());

  const setPendingAnswer = useCallback((answerId, sectionId, pendingAnswer) => {
    setPendingAnswers(pendingAnswers => {
      const newPendingAnswers = new Map(pendingAnswers);

      const key = `${pendingAnswer.type}/${answerId}`;

      const oldPendingAnswer = newPendingAnswers.get(key);

      newPendingAnswers.set(key, {
        ...pendingAnswer,
        errors: 0,
        loading: oldPendingAnswer?.loading ?? false,
      });

      return newPendingAnswers;
    });

    if (pendingAnswer.type === ANSWER) {
      dispatch(updateAnswer(answerId, pendingAnswer.answer, sectionId));
    }

    if (pendingAnswer.type === JUSTIFICATION) {
      dispatch(updateJustification(answerId, pendingAnswer.justification, sectionId));
    }
  }, [dispatch]);

  const clearPendingAnswers = useCallback((answerIds) => {
    if (answerIds) {
      setPendingAnswers(pendingAnswers => {
        const newPendingAnswers = new Map(pendingAnswers);

        answerIds.forEach(answerId => {
          newPendingAnswers.delete(`${ANSWER}/${answerId}`);
          newPendingAnswers.delete(`${JUSTIFICATION}/${answerId}`);
        });

        return newPendingAnswers;
      });
      return;
    }

    setPendingAnswers(new Map());
  }, []);

  const removePendingAnswer = useCallback((key, createdAt) => {
    setPendingAnswers(pendingAnswers => {
      const newPendingAnswers = new Map(pendingAnswers);

      const newPendingAnswer = newPendingAnswers.get(key);

      if (newPendingAnswer && newPendingAnswer.createdAt <= createdAt) {
        newPendingAnswers.delete(key);
      }

      return newPendingAnswers;
    });
  }, []);

  const incrementPendingAnswerErrors = useCallback((key, pendingAnswer) => {
    setPendingAnswers(pendingAnswers => {
      const newPendingAnswers = new Map(pendingAnswers);

      const newPendingAnswer = newPendingAnswers.get(key);

      if (newPendingAnswer && newPendingAnswer.createdAt <= pendingAnswer.createdAt) {
        newPendingAnswers.set(key, {
          ...pendingAnswer,
          errors: pendingAnswer.errors + 1,
        });
      }

      return newPendingAnswers;
    });
  }, []);

  const setPendingAnswerLoading = useCallback((key, loading) => {
    setPendingAnswers(pendingAnswers => {
      const newPendingAnswers = new Map(pendingAnswers);

      const newPendingAnswer = newPendingAnswers.get(key);
      if (newPendingAnswer) {
        newPendingAnswers.set(key, {
          ...newPendingAnswer,
          loading,
        });
      }

      return newPendingAnswers;
    });
  }, []);

  const flushPendingAnswers = useCallback((forceFlush = false, answerIds) => {
    if (pendingAnswers.size === 0 || !asyncAnswersToggle) {
      return [];
    }

    const answerRequests = [];
    pendingAnswers.forEach((pendingAnswer, key) => {
      const splitAnswerId = key.split('/');
      const answerId = splitAnswerId[1];

      const shouldFlushAll = !answerIds;
      const answerExists = answerIds?.includes(answerId) ?? false;
      const answerSelected = shouldFlushAll || answerExists;
      const reachedMaxErrors = pendingAnswer.errors >= 3;
      const canFlushAnswer = (!reachedMaxErrors && !pendingAnswer.loading) || forceFlush;
      if (!answerSelected || !canFlushAnswer) {
        return;
      }

      setPendingAnswerLoading(key, true);
      answerRequests.push(new Promise(resolve => {
        if (pendingAnswer.type === ANSWER) {
          answerExerciseRequest([attemptId, answerId], { answer: pendingAnswer.answer }, ({ data }) => {
            setPendingAnswerLoading(key, false);

            if (data.status === 0) {
              removePendingAnswer(key, pendingAnswer.createdAt);
              resolve(true);
            }

            if (data.status !== 0) {
              incrementPendingAnswerErrors(key, pendingAnswer);
              resolve(false);
            }
          });
        }

        if (pendingAnswer.type === JUSTIFICATION) {
          answerJustificationRequest([attemptId, answerId], { justification: pendingAnswer.justification }, ({ data }) => {
            setPendingAnswerLoading(key, false);

            if (data.status === 0) {
              removePendingAnswer(key, pendingAnswer.createdAt);
              resolve(true);
            }

            if (data.status !== 0) {
              incrementPendingAnswerErrors(key, pendingAnswer);
              resolve(false);
            }
          });
        }
      }));
    });

    return Promise.all(answerRequests);
  }, [pendingAnswers, asyncAnswersToggle, attemptId, setPendingAnswerLoading, answerExerciseRequest, removePendingAnswer, incrementPendingAnswerErrors, answerJustificationRequest]);

  useInterval(() => {
    flushPendingAnswers();
  }, FLUSH_POLLING_TIME);

  return {
    setPendingAnswer,
    pendingAnswers,
    flushPendingAnswers,
    clearPendingAnswers,
  };
};

export default useAnswerQueue;
