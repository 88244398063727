import React, { useCallback, useMemo, useState } from 'react';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Xarrow, { Xwrapper, useXarrow } from 'react-xarrows';
import { v4 } from 'uuid';

import { selectUserIsAdmin } from 'actions/userActions';
import { LOCAL } from 'constants/environments';
import { LINE } from 'constants/exerciseOptions';
import useFeature from 'hooks/useFeature';
import toggles from 'toggles';

import useStyles from './styles';

const ExerciseAnswerSegmentation = ({ image, connectors, connections, onAnswer, option, answer, answerable, correction }) => {
  const classes = useStyles();
  const [activeStart, setActiveStart] = useState(null);
  const exportIdentifiersToggle = useFeature(toggles.exportIdentifiers);
  const updateXarrow = useXarrow();
  const isAdmin = useSelector(selectUserIsAdmin);

  const finalSrc = useMemo(() => {
    let finalSrc = image;
    if (finalSrc && process.env.REACT_APP_NODE_ENV === LOCAL) {
      const hostname = window.location.hostname;
      finalSrc = finalSrc.replace('localhost', hostname);
    }

    return finalSrc;
  }, [image]);

  const setActive = useCallback((connectorId) => {
    if (activeStart === connectorId) {
      setActiveStart(null);
      return;
    }

    if (!activeStart) {
      setActiveStart(connectorId);
    } else {
      const newConnections = [...answer];
      const isLineOption = LINE === option;
      const duplicateCheck = newConnections.some(connection =>
        (connection.start === activeStart && connection.end === connectorId) ||
        (isLineOption && connection.start === connectorId && connection.end === activeStart),
      );

      if (!duplicateCheck) {
        newConnections.push({
          id: v4(),
          start: activeStart,
          end: connectorId,
        });
      }

      setActiveStart(null);
      onAnswer(newConnections);
    }
  }, [activeStart, option, answer, onAnswer]);

  const deleteConnection = useCallback((connectionId) => {
    const copyConnections = [...answer];
    const newConnections = copyConnections.filter(el => el.id !== connectionId);
    onAnswer(newConnections);
  }, [onAnswer, answer]);

  const getContent = useCallback(() => {

    const imageSideSize = 700;
    let connectionsToRender = null;
    let connectorsToRender = null;
    let answerArrows = null;
    const dashedColor = '#419bb480';
    const correctColor = '#019a39';
    const wrongColor = '#ce1f01';
    const blueColor = '#419bb4';

    connectorsToRender = connectors.map(connector => {

      if (!connector.coords) {
        return null;
      }

      const x = (connector.coords.x / 100) * imageSideSize, y = (connector.coords.y / 100) * imageSideSize;
      const style = {
        position: 'absolute',
        width: '28px',
        height: '28px',
        top: y - 16 + 'px',
        left: x - 16 + 'px',
      };

      return (
        <div key={connector.id} className={cx(classes.outerRing, { answerable: answerable })} style={style} onClick={() => (answerable ? setActive(connector.id) : {})}>
          {!answerable && exportIdentifiersToggle &&
            <div className={classes.identifier}>
              {connector.identifier}
            </div>
          }
          <div id={connector.id + '_connector'} className={cx(classes.innerRing, { active: activeStart === connector.id })} />
        </div>
      );
    });

    if (correction) {
      connectionsToRender = !isAdmin && connections.filter(el =>
        !answer.find(answer => ((answer.start === el.start && answer.end === el.end) || (answer.start === el.end && answer.end === el.start)) && answer.isCorrect),
      ).map(connection => (
        <Xarrow
          key={connection.id}
          start={connection.start + '_connector'}
          end={connection.end + '_connector'}
          curveness={0}
          showHead={option !== LINE}
          lineColor={dashedColor}
          dashness={true}
          headColor={dashedColor}
        />
      ));

      answerArrows = answer.map(connection => {
        const color = isAdmin ? blueColor : connection.isCorrect ? correctColor : wrongColor;

        return (
          <Xarrow
            key={connection.id}
            start={connection.start + '_connector'}
            end={connection.end + '_connector'}
            curveness={0}
            lineColor={color}
            showHead={option !== LINE}
            headColor={color}
          />
        );
      });
    }

    if (answerable) {

      connectionsToRender = answer.map(connection => (
        <Xarrow
          key={connection.id}
          start={connection.start + '_connector'}
          end={connection.end + '_connector'}
          curveness={0}
          lineColor={blueColor}
          showHead={option !== LINE}
          headColor={blueColor}
          arrowBodyProps={{ onClick: () => deleteConnection(connection.id) }}
          SVGcanvasStyle={{ cursor: 'pointer' }}
          labels={{ middle: <FontAwesomeIcon icon={faCircleXmark} className={classes.arrowDelete} onClick={() => deleteConnection(connection.id)} ></FontAwesomeIcon> }}
        />
      ));
    }
    if (!answerable && !correction) {
      connectionsToRender = connections.map(connection => (
        <Xarrow
          key={connection.id}
          start={connection.start + '_connector'}
          end={connection.end + '_connector'}
          curveness={0}
          lineColor={blueColor}
          showHead={option !== LINE}
          headColor={blueColor}
        />
      ));
    }

    return (
      <>
        {connectorsToRender}
        {connectionsToRender}
        {answerArrows}
      </>
    );

  }, [classes, connections, connectors, option, answerable, answer, activeStart, deleteConnection, setActive, correction, exportIdentifiersToggle, isAdmin]);

  return (
    image &&
      <div onLoad={updateXarrow} className={classes.wrapper}>
        <div className={classes.imageWrapper} >
          <img src={finalSrc} className={classes.image} alt={'caption'} />
          <Xwrapper>
            {getContent()}
          </Xwrapper>
        </div>
      </div>
  );
};

ExerciseAnswerSegmentation.propTypes = {
  image: PropTypes.string,
  connectors: PropTypes.array,
  connections: PropTypes.array,
  option: PropTypes.string,
  answer: PropTypes.array,
  answerable: PropTypes.bool,
  onAnswer: PropTypes.func,
  correction: PropTypes.bool,
};

export default ExerciseAnswerSegmentation;
