import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addLabelButton: {
    fontSize: '8px',
    padding: '7px 10px',
    height: 'unset',
    border: 'none',
    borderRadius: '10px',
    marginLeft: '0.5em',
    backgroundColor: theme.buttonBackground,
    color: theme.textColor4,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.buttonBackgroundHover,
    },
    '&:focus': {
      backgroundColor: theme.buttonBackgroundHover,
      boxShadow: 'unset',
    },
    '&.onlyChild': {
      marginLeft: 'unset',
    },
  },
  addLabelText: {
    fontSize: '12px',
    marginRight: '5px',
  },
  labelsDropdownMenu: {
    maxHeight: '15em',
    overflowY: 'auto',
    zIndex: '50',
    minWidth: '13em',
    backgroundColor: `${theme.inputBackground2} !important`,
    border: `1px solid ${theme.inputBorder2} !important`,
    borderRadius: '10px',
    top: 'calc(100% + 9px)',
  },
  labelDropdownMenuItem: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    margin: 'unset !important',
    border: 'none !important',
    '&:hover': {
      backgroundColor: theme.shade1,
      cursor: 'pointer',
    },
  },
  iconPlus: {
    marginRight: '5px',
  },
  noOptions: {
    border: 'none !important',
    color: theme.textColor2,
    fontSize: '14px',
    margin: '0 !important',
  },
}));
