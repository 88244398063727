import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import routes from 'routes';

const PrivateRoute = ({ exact, path, children }) => {
  const isAuthenticated = useSelector(state => state.user.loggedIn);
  const appLoading = useSelector(state => state.page.appLoading);

  if (!isAuthenticated && !appLoading) {
    window.location = routes.auth.ref();
  }

  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string,
  exact: PropTypes.bool,
  children: PropTypes.any,
};

export default PrivateRoute;
