import React, { useCallback } from 'react';
import { Table, TableCell, TableRow } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import api from 'api';
import { MAX_SIZE_10MB } from 'constants/fileMaxSizes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import { DATE_FORMAT } from 'utils/datetime';

import SettingsInput from '../../SettingsInput';

import useStyles from './styles';

const PredefinedInputs = ({ getValue, setValue, getErrors }) => {
  const classes = useStyles();
  const toast = useToast();
  const [uploadFileRequest] = useApi(api.uploadFile);

  const onDrop = useCallback((files) => {
    const file = files[0];

    if (file.size > MAX_SIZE_10MB) {
      toast.warning(lang.fileTooLarge);
      return;
    }

    uploadFileRequest([], { file }, ({ data }) => {
      if (data.status === 0) {
        setValue('schoolLogo', { ...getValue('schoolLogo'), value: `${process.env.REACT_APP_S3_URL}/${data.key}` });
        return;
      }

      toast.error(lang.oops);
    });
  }, [uploadFileRequest, toast, setValue, getValue]);

  return (
    <Table className={classes.inputTable}>
      <TableRow header>
        <TableCell colSpan="10" className={classes.tableCell}></TableCell>
        <TableCell
          className={cx(classes.tableCell, classes.tableHeader)}
          textAlign="center"
        >
          {lang.test.physicalVersion.studentInput}
        </TableCell>
        <TableCell
          className={cx(classes.tableCell, classes.tableHeader)}
          textAlign="center"
        >
          {lang.enable}
        </TableCell>
      </TableRow>
      <SettingsInput
        type="file"
        accept={['image/*']}
        label={lang.test.physicalVersion.schoolLogo}
        placeholder={lang.test.physicalVersion.schoolLogoPlaceholder}
        onDrop={onDrop}
        value={getValue('schoolLogo').value ? [getValue('schoolLogo').value] : []}
        errors={getErrors('schoolLogo')}
        invalid={getErrors('schoolLogo').length !== 0}
        hasTogglers
        canBeEnabled
        isEnabled={getValue('schoolLogo').enabled}
        onEnable={value => setValue('schoolLogo', { ...getValue('schoolLogo'), enabled: value })}
      />
      <SettingsInput
        type="text"
        label={lang.test.physicalVersion.schoolName}
        placeholder={lang.test.physicalVersion.schoolNamePlaceholder}
        onChange={event => setValue('schoolName', { ...getValue('schoolName'), value: event.target.value })}
        value={getValue('schoolName').value}
        errors={getErrors('schoolName')}
        invalid={getErrors('schoolName').length !== 0}
        hasTogglers
        canBeEnabled
        isEnabled={getValue('schoolName').enabled}
        onEnable={value => setValue('schoolName', { ...getValue('schoolName'), enabled: value })}
      />
      <SettingsInput
        type="text"
        label={lang.test.physicalVersion.class}
        placeholder={lang.test.physicalVersion.classPlaceholder}
        onChange={event => setValue('class', { ...getValue('class'), value: event.target.value })}
        value={getValue('class').value}
        errors={getErrors('class')}
        invalid={getErrors('class').length !== 0}
        hasTogglers
        canBeStudentInput
        isStudentInput={getValue('class').studentInput}
        onEnableStudentInput={value => setValue('class', { ...getValue('class'), studentInput: value })}
        canBeEnabled
        isEnabled={getValue('class').enabled}
        onEnable={value => setValue('class', { ...getValue('class'), enabled: value })}
      />
      <SettingsInput
        type="text"
        label={lang.test.physicalVersion.subject}
        placeholder={lang.test.physicalVersion.subjectPlaceholder}
        onChange={event => setValue('subject', { ...getValue('subject'), value: event.target.value })}
        value={getValue('subject').value}
        errors={getErrors('subject')}
        invalid={getErrors('subject').length !== 0}
        hasTogglers
        canBeEnabled
        isEnabled={getValue('subject').enabled}
        onEnable={value => setValue('subject', { ...getValue('subject'), enabled: value })}
      />
      <SettingsInput
        type="text"
        label={lang.test.physicalVersion.testName}
        placeholder={lang.test.physicalVersion.testNamePlaceholder}
        onChange={event => setValue('testName', { ...getValue('testName'), value: event.target.value })}
        value={getValue('testName').value}
        errors={getErrors('testName')}
        invalid={getErrors('testName').length !== 0}
        hasTogglers
        canBeEnabled
        isEnabled={getValue('testName').enabled}
        onEnable={value => setValue('testName', { ...getValue('testName'), enabled: value })}
      />
      <SettingsInput
        type="toggle"
        label={lang.test.physicalVersion.displayVersion}
        hasTogglers
        canBeEnabled
        isEnabled={getValue('displayVersion')}
        onEnable={value => setValue('displayVersion', value)}
      />
      <SettingsInput
        type="text"
        label={lang.test.physicalVersion.schoolYear}
        placeholder={lang.test.physicalVersion.schoolYearPlaceholder}
        onChange={event => setValue('schoolYear', { ...getValue('schoolYear'), value: event.target.value })}
        value={getValue('schoolYear').value}
        errors={getErrors('schoolYear')}
        invalid={getErrors('schoolYear').length !== 0}
        hasTogglers
        canBeStudentInput
        isStudentInput={getValue('schoolYear').studentInput}
        onEnableStudentInput={value => setValue('schoolYear', { ...getValue('schoolYear'), studentInput: value })}
        canBeEnabled
        isEnabled={getValue('schoolYear').enabled}
        onEnable={value => setValue('schoolYear', { ...getValue('schoolYear'), enabled: value })}
      />
      <SettingsInput
        type="date"
        label={lang.test.physicalVersion.date}
        placeholder={lang.test.physicalVersion.datePlaceholder}
        onChange={date => setValue('date', { ...getValue('date'), value: date?.format(DATE_FORMAT) ?? null })}
        value={getValue('date').value}
        errors={getErrors('date')}
        invalid={getErrors('date').length !== 0}
        hasTogglers
        canBeStudentInput
        isStudentInput={getValue('date').studentInput}
        onEnableStudentInput={value => setValue('date', { ...getValue('date'), studentInput: value })}
        canBeEnabled
        isEnabled={getValue('date').enabled}
        onEnable={value => setValue('date', { ...getValue('date'), enabled: value })}
      />
      <SettingsInput
        type="number"
        label={lang.test.physicalVersion.testDuration}
        placeholder={lang.test.physicalVersion.testDurationPlaceholder}
        onChange={event => setValue('testDuration', { ...getValue('testDuration'), value: parseInt(event.target.value) })}
        value={getValue('testDuration').value}
        minSize={1}
        errors={getErrors('testDuration')}
        invalid={getErrors('testDuration').length !== 0}
        hasTogglers
        canBeEnabled
        isEnabled={getValue('testDuration').enabled}
        onEnable={value => setValue('testDuration', { ...getValue('testDuration'), enabled: value })}
      />
      <SettingsInput
        type="toggle"
        label={lang.test.physicalVersion.askForName}
        hasTogglers
        canBeEnabled
        isEnabled={getValue('askForName')}
        onEnable={value => setValue('askForName', value)}
      />
      <SettingsInput
        type="toggle"
        label={lang.test.physicalVersion.askForCode}
        hasTogglers
        canBeEnabled
        isEnabled={getValue('askForCode')}
        onEnable={value => setValue('askForCode', value)}
      />
      <SettingsInput
        type="toggle"
        label={lang.test.physicalVersion.askForGrade}
        hasTogglers
        canBeEnabled
        isEnabled={getValue('askForGrade')}
        onEnable={value => setValue('askForGrade', value)}
      />
    </Table>
  );
};

PredefinedInputs.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getErrors: PropTypes.func.isRequired,
};

export default PredefinedInputs;
