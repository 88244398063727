import React from 'react';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import lang from 'lang';

import FlowStep from '../FlowStep';
import expressions from 'components/common/rich-text/FormulaModal/expressions';
import RichText from 'components/common/rich-text/RichText';

import useStyles from './styles';

const AskForStatement = ({ number, statement, setStatement, type, setGaps }) => {
  const classes = useStyles();

  const onChange = (editor, _source) => {
    if (_source === 'user') {
      const contents = editor.getContents();
      setStatement(contents);

      if (!setGaps) {
        return;
      }

      const gapPositions = contents.ops.reduce((acc, op) => {
        if (op.insert.gap) {
          return [
            ...acc,
            { gap: op.insert.gap, position: op.attributes.position },
          ];
        }

        return acc;
      }, []);

      setGaps(gaps => gaps?.map(gap => {
        const gapInStatement = gapPositions.find(el => el.position === gap.position && gap.isCorrect);
        if (!gapInStatement) {
          return gap;
        }
        const newGapText = gapInStatement.gap;
        const oldGapText = JSON.parse(gap.text);

        let newText = null;
        if (oldGapText.formula) {
          newText = { ...oldGapText,
            formula: newGapText.formula,
          };
        } else {
          newText = { ...oldGapText, insert: newGapText };
        }

        return {
          ...gap,
          text: JSON.stringify(newText),
        };
      }));
    }
  };

  const getStatementHeader = () => {
    if (type === INFORMATION) {
      return lang.test.information;
    } else if (type === PAUSE) {
      return lang.test.exercises.addPauseItemStepHeader;
    } else {
      return lang.exerciseForm.statement;
    }
  };

  const getPlaceholder = () => {
    if (type === INFORMATION) {
      return lang.exerciseForm.statementPlaceholderInformation;
    } else if (type === PAUSE) {
      return lang.test.exercises.addPauseItemPlaceholder;
    } else {
      return lang.exerciseForm.statementPlaceholder;
    }
  };

  return (
    <FlowStep
      dataTour="ask-for-statement"
      stepNumber={number}
      header={getStatementHeader()}
      subHeader={type === 'caption' ?
        <>
          <FontAwesomeIcon icon={faTriangleExclamation} />
          {' '}
          {lang.exerciseForm.captionWarning}
        </>
        : null
      }
    >
      <RichText
        placeholder={getPlaceholder()}
        value={statement}
        onChange={(_content, _delta, _source, editor) => {
          onChange(editor, _source);
        }}
        className={classes.statementInput}
        enableMathSymbols
        mathSymbols={expressions}
      />
    </FlowStep>
  );
};

AskForStatement.propTypes = {
  number: PropTypes.number,
  statement: PropTypes.object,
  setStatement: PropTypes.func,
  setGaps: PropTypes.func,
  type: PropTypes.string,
};

export default AskForStatement;
