import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  section: {
    marginBottom: '20px',
  },
  sectionDescription: {
    '& > p': {
      margin: '0',
    },
  },
  reviewableExercise: {
    marginBottom: '20px',
  },
}));
