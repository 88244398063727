import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { Button, Icon } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { quillIsEmpty } from 'utils';

import EntityActionsContainer from 'components/common/entity/EntityActionsContainer';
import EntityBody from 'components/common/entity/EntityBody';
import EntityContent from 'components/common/entity/EntityContent';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import EntityHeader from 'components/common/entity/EntityHeader';
import EntitySubHeader from 'components/common/entity/EntitySubHeader';
import EntityTitle from 'components/common/entity/EntityTitle';
import AnnotationBlot from 'components/common/rich-text/RichText/AnnotationBlot';
import ExerciseAnswer from 'components/exercises/exercise/exercise-answer/ExerciseAnswer';
import ExerciseJustification from 'components/exercises/exercise/exercise-answer/ExerciseJustification';
import ExerciseGrade from 'components/exercises/exercise/exercise-header/ExerciseGrade';
import ExerciseCriteriaGrader from 'components/exercises/exercise/exercise-sub-header/ExerciseCriteriaGrader';
import ExerciseFeedback from 'components/exercises/exercise/ExerciseFeedback';
import ExerciseModelAnswer from 'components/exercises/exercise/ExerciseModelAnswer';
import ExerciseStatement from 'components/exercises/exercise/ExerciseStatement';
import ProcessNodeOptions from 'components/exercises/exercise-form/ProcessNodeOptions';
import CommentBox from 'components/test/tabs/grades-tab/CommentBox';
import { useAttemptContext } from 'components/test/tabs/grades-tab/CorrectionTab/context';

import useStyles from './styles';

const UPWARDS = 'upwards';
const DOWNWARDS = 'downwards';

const ReviewableExercise = ({ num, exercise, classificationType }) => {
  const classes = useStyles();
  const modelAnswerToggle = useFeature(toggles.modelAnswer);
  const { commenting, commentingExerciseId, setCommenting, setTextAnnotationHovered, setTextAnnotationFocused, focusedTextAnnotationId, hoveredTextAnnotationId } = useAttemptContext();

  const [annotationsDirection, setAnnotationsDirection] = useState(DOWNWARDS);
  const annotationsRef = useRef(null);
  const exerciseRef = useRef(null);

  const hasMultipleCorrectChoices = exercise.choices?.filter(choice => choice.isCorrect).length > 1;

  useEffect(() => {
    if (commenting && exerciseRef.current && annotationsRef.current) {
      const exerciseRect = exerciseRef.current.getBoundingClientRect();
      const annotationsHeight = annotationsRef.current.offsetHeight;

      const scrollY = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight;

      const exerciseBottomPosition = exerciseRect.bottom + scrollY;

      const spaceBelow = pageHeight - exerciseBottomPosition;

      if (spaceBelow < annotationsHeight) {
        setAnnotationsDirection(UPWARDS);
      } else {
        setAnnotationsDirection(DOWNWARDS);
      }
    }
  }, [commenting]);

  const handleClickViewComments = useCallback(() => {
    if (commenting && commentingExerciseId === exercise.id) {
      setCommenting(false);
      setTextAnnotationFocused(null);
      return;
    }

    setCommenting(true, exercise.id);
  }, [commenting, commentingExerciseId, exercise.id, setCommenting, setTextAnnotationFocused]);

  const onSelectionChange = useCallback((range, editor) => {
    AnnotationBlot.setAllDefault();
    setTextAnnotationFocused(null);

    if (range?.length === 0) {
      const contents = editor.getContents(range.index);
      const op = contents.ops[0];
      if (op && op.attributes?.annotation) {
        const idsString = op.attributes.annotation.id;
        const ids = idsString.split('|');

        const onlyOverlapedId = ids.find(id => {
          const blots = document.querySelectorAll(`[data-id*="${id}"]`);
          return blots.length === 1;
        });

        const id = onlyOverlapedId ?? ids[ids.length - 1];
        AnnotationBlot.setAllUnfocused();
        AnnotationBlot.setIsActive(id, true);
        setTextAnnotationFocused(id);
        setCommenting(true, exercise.id);
      }
    }
  }, [exercise.id, setCommenting, setTextAnnotationFocused]);

  const processNode = (node) => {
    let item;
    const drop = exercise.answer.find(el => el.position === node.attribs['data-position']);
    if (!drop) {
      return null;
    }
    const gapAnswer = exercise.answer.find(answer => answer.position === drop.position);

    if (gapAnswer) {
      if (exercise.type === 'filling') {
        item = exercise.gaps.find(gap => gap.id === gapAnswer.gapId);
      }
    } else {
      item = null;
    }

    return (
      <ProcessNodeOptions
        id={exercise.id}
        drop={drop}
        item={item}
        gaps={exercise.gaps}
        option={exercise.option}
        correction
      />
    );
  };

  const instructions = [
    {
      shouldProcessNode: (node) => {
        return node.attribs && node.attribs['data-drop'];
      },
      processNode: processNode,
    },
  ];

  const annotationsSorted = exercise.textAnnotations
    ?.reduce((annotations, annotation) => [
      ...(annotation.id === focusedTextAnnotationId || (!focusedTextAnnotationId && annotation.id === hoveredTextAnnotationId) ? [annotation] : []),
      ...annotations,
      ...((focusedTextAnnotationId && annotation.id !== focusedTextAnnotationId) || (annotation.id !== focusedTextAnnotationId && annotation.id !== hoveredTextAnnotationId) ? [annotation] : []),
    ], []);

  const commentBox = commenting && commentingExerciseId === exercise.id ? (
    <div
      ref={annotationsRef}
      className={cx(classes.commentBoxContainer, { isInsideSection: !!exercise.publicationSectionId, isUpwards: annotationsDirection === UPWARDS })}
    >
      {(annotationsDirection === DOWNWARDS ? annotationsSorted : annotationsSorted?.reverse())
        ?.map(annotation => (
          <CommentBox
            key={annotation.id}
            textAnnotationId={annotation.id}
            comment={annotation.comment}
          />
        ))}
    </div>
  ) : false;

  const footer = (
    <div className={classes.footerWrapper}>
      {(exercise.textAnnotations?.length > 0 || commenting) && (
        <Button
          onClick={handleClickViewComments}
          styleType={commenting && commentingExerciseId === exercise.id ? 'filled' : 'outlined'}
        >
          <Icon icon="comment" className={classes.annotateIcon} />
          {commenting && commentingExerciseId === exercise.id ? lang.test.grades.closeAnnotations : exercise.textAnnotations?.length}
        </Button>
      )}
    </div>
  );

  const onMouseOver = (event) => {
    if (event.target.className.includes?.('ql-annotation')) {
      const idsString = event.target.getAttribute('data-id');
      const ids = idsString.split('|');

      const onlyOverlapedId = ids.find(id => {
        const blots = document.querySelectorAll(`[data-id*="${id}"]`);
        return blots.length === 1;
      });

      const id = onlyOverlapedId ?? ids[ids.length - 1];

      if (!focusedTextAnnotationId) {
        AnnotationBlot.setAllUnfocused();
        AnnotationBlot.setIsActive(id, true);
      }
      setTextAnnotationHovered(id);
    }
  };

  const onMouseOut = (event) => {
    if (event.target.className.includes?.('ql-annotation')) {
      if (!focusedTextAnnotationId) {
        AnnotationBlot.setAllDefault();
      }
      setTextAnnotationHovered(null);
    }
  };

  return (
    <EntityBody
      className={commenting && commentingExerciseId !== exercise.id && classes.exerciseUnfocused}
      _ref={exerciseRef}
    >
      <EntityHeader>
        <EntityTitle
          num={num}
          type={exercise.type}
        />
        <EntityActionsContainer>
          {![INFORMATION, PAUSE].includes(exercise.type) && classificationType !== 'none' && (
            <ExerciseGrade
              grade={exercise.grade}
              maxGrade={exercise.maxGrade}
            />
          )}
        </EntityActionsContainer>
      </EntityHeader>
      {classificationType === 'rubric' && (
        <EntitySubHeader>
          <ExerciseCriteriaGrader
            criteriaGrades={exercise.criteriaGrades}
            readOnly
          />
        </EntitySubHeader>
      )}
      <EntityContent>
        <ExerciseStatement
          statement={exercise.statement}
          instructions={instructions}
        />
        <div onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
          <ExerciseAnswer
            exerciseId={exercise.id}
            type={exercise.type}
            choices={exercise.choices}
            answer={exercise.answer}
            correctTrueFalse={exercise.correctTrueFalse}
            image={exercise.image}
            gaps={exercise.gaps}
            dropAnswers={exercise.answer}
            orderItems={exercise.valueOrderItems}
            orderingCorrect={exercise.orderingCorrect}
            option={exercise.option}
            correction
            hasMultipleCorrectChoices={hasMultipleCorrectChoices}
            isCompact={exercise.isCompact}
            isShortAnswer={exercise.isShortAnswer}
            connectors={exercise.connectors}
            connections={exercise.connections}
            gradingOption={exercise.gradingOption}
            exerciseCells={exercise.exerciseCells}
            hasWordCount={exercise.hasWordCount}
            footer={footer}
            commentBox={commentBox}
            onSelectionChange={onSelectionChange}
          />
          {exercise.hasJustification && (
            <ExerciseJustification
              justification={exercise.justification}
              hasWordCount={exercise.hasWordCount}
              footer={footer}
              commentBox={commentBox}
              onSelectionChange={onSelectionChange}
            />
          )}
        </div>
        {!quillIsEmpty(exercise.extraText) && (
          <>
            <Spacer px={10} />
            <EntityExpandableText
              text={exercise.extraText}
              full={exercise.extraTextStartExpanded}
            />
          </>
        )}
        {modelAnswerToggle && !quillIsEmpty(exercise.modelAnswer) && (
          <>
            <Spacer px={10} />
            <ExerciseModelAnswer
              modelAnswer={exercise.modelAnswer}
            />
          </>
        )}
        <ExerciseFeedback
          feedback={exercise.feedback}
        />
      </EntityContent>
    </EntityBody>
  );
};

ReviewableExercise.propTypes = {
  num: PropTypes.number.isRequired,
  exercise: PropTypes.shape({
    id: PropTypes.string.isRequired,
    statement: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(PropTypes.object),
    image: PropTypes.string,
    gaps: PropTypes.array,
    answer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.array,
    ]),
    correctTrueFalse: PropTypes.bool,
    grade: PropTypes.string,
    maxGrade: PropTypes.string,
    feedback: PropTypes.object,
    hasJustification: PropTypes.bool,
    justification: PropTypes.string,
    criteriaGrades: PropTypes.array,
    valueOrderItems: PropTypes.arrayOf(PropTypes.object),
    orderingCorrect: PropTypes.bool,
    isCompact: PropTypes.bool,
    isShortAnswer: PropTypes.bool,
    option: PropTypes.string,
    extraText: PropTypes.object,
    extraTextStartExpanded: PropTypes.string,
    connectors: PropTypes.array,
    connections: PropTypes.array,
    modelAnswer: PropTypes.bool,
    gradingOption: PropTypes.string,
    exerciseCells: PropTypes.array,
    hasWordCount: PropTypes.bool,
    textAnnotations: PropTypes.array,
    publicationSectionId: PropTypes.string,
  }),
  classificationType: PropTypes.string,
};

export default ReviewableExercise;
