import { createUseStyles } from 'react-jss';

export default createUseStyles({
  title: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
  },
  warningIcon: {
    marginLeft: '0.5em',
  },
});
