import React, { useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { decrementTotalExercises, decrementTotalGroups, decrementTotalRubrics, decrementTotalTests } from 'actions/userActions';
import api from 'api';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const LeaveGroupModal = ({ open, close, groupId, refresh }) => {
  const toast = useToast();
  const classes = useStyles();
  const [leaveGroupRequest] = useApi(api.leaveGroup);
  const dispatch = useDispatch();
  const { spaceId } = useParams();

  const [loading, setLoading] = useState(false);

  const leaveGroup = () => {
    setLoading(true);
    leaveGroupRequest([groupId], null, ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          if (spaceId === PERSONAL_SPACE) {
            dispatch(decrementTotalGroups());
            dispatch(decrementTotalTests(data.totalTests));
            dispatch(decrementTotalExercises(data.totalExercises));
            dispatch(decrementTotalRubrics(data.totalRubrics));
          }
          setLoading(false);
          toast.success(lang.group.successLeaveGroup);
          refresh();
        }, 350);
      } else {
        setLoading(false);
        toast.error(lang.oops);
      }
    });
  };

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
    {
      name: lang.leave,
      color: 'red',
      onClick: leaveGroup,
      loading: loading,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.group.leaveGroup}
      actions={actions}
      center
      transition
      small
    >
      <div className={classes.leaveGroupInfo}>
        {lang.group.leaveGroupInfo}
      </div>
      <div className={classes.leaveGroupInfo}>
        {lang.group.leaveGroupInfo2}
      </div>
    </Modal>
  );
};

LeaveGroupModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  groupId: PropTypes.string,
  refresh: PropTypes.func,
};

export default LeaveGroupModal;
