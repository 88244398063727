import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import FlowStep from '../FlowStep';
import RichText from 'components/common/rich-text/RichText';

import useStyles from './styles';

const AskForModelAnswer = ({ number, modelAnswer, setModelAnswer }) => {
  const classes = useStyles();

  const onChange = (editor, _source) => {
    if (_source === 'user') {
      setModelAnswer(editor.getContents());
    }
  };

  const header = (
    <div className={classes.header}>
      {lang.exerciseForm.modelAnswer}
    </div>
  );

  return (
    <FlowStep
      dataTour="ask-for-model-answer"
      stepNumber={number}
      header={header}
    >
      <RichText
        placeholder={lang.exerciseForm.modelAnswerPlaceholder}
        value={modelAnswer}
        onChange={(_content, _delta, _source, editor) => {
          onChange(editor, _source);
        }}
        className={classes.modelAnswerInput}
      />
    </FlowStep>
  );
};

AskForModelAnswer.propTypes = {
  number: PropTypes.number,
  modelAnswer: PropTypes.object,
  setModelAnswer: PropTypes.func,
};

export default AskForModelAnswer;
