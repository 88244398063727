import React, { Fragment } from 'react';
import { faAngleUp, faAngleDown, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import lang from 'lang';

import EntityAction from '../EntityAction';

import useStyles from './styles';

const EntityMover = ({ onMove, loading }) => {
  const classes = useStyles();

  const onMoveClick = (moveUp) => {
    onMove(moveUp);
  };

  if (loading) {
    return (
      <div className={classes.actionsWrap}>
        <Spinner
          className={classes.actionsSpinner}
        />
      </div>
    );
  }

  return (
    <Fragment>
      <EntityAction
        icon={faGripVertical}
        tip={lang.drag}
        tipDirection="right"
        className={classes.moveAction}
      />
      <div className={classes.actionsWrap}>
        <EntityAction
          icon={faAngleUp}
          tip={lang.test.moveUp}
          tipDirection="right"
          onClick={() => onMoveClick(true)}
          className={classes.moveAction}
        />
        <EntityAction
          icon={faAngleDown}
          tip={lang.test.moveDown}
          tipDirection="right"
          onClick={() => onMoveClick(false)}
          className={classes.moveAction}
        />
      </div>
    </Fragment>
  );
};

EntityMover.propTypes = {
  onMove: PropTypes.func,
  loading: PropTypes.bool,
};

export default EntityMover;
