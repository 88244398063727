import React, { useEffect, useMemo, useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { decrementTotalRubrics, incrementTotalRubrics, selectUserFeatureToggleMaxUsages, selectUserTotalFreeRubrics } from 'actions/userActions';
import api from 'api';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useInput from 'hooks/common/useInput';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';
import PlansPill from 'components/common/plans/PlansPill';

import useStyles from './styles';

const SendRubricModal = ({ open, close, selectedRubrics, groupId, groups, refresh }) => {
  const classes = useStyles();
  const toast = useToast();
  const userId = useSelector(state => state.user.data.id);
  const schools = useSelector(state => state.user.data.schools);
  const hasPersonalSpace = useSelector(state => state.user.data.hasPersonalSpace);
  const [sendTemplateRubricsRequest] = useApi(api.sendTemplateRubrics);
  const totalFreeRubrics = useSelector(selectUserTotalFreeRubrics);
  const createRubricToggle = useFeature(toggles.createRubric, totalFreeRubrics, selectedRubrics.length);
  const createRubricToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createRubric));
  const iaveToggle = useFeature(toggles.iave);
  const dispatch = useDispatch();
  const { spaceId } = useParams();

  const schoolOptions = schools?.map(school => ({ value: school.id, label: school.name }));

  if (hasPersonalSpace) {
    schoolOptions.push({ value: PERSONAL_SPACE, label: lang.appKeywords.personalSpace });
  }

  const [loading, setLoading] = useState(false);
  const [group, setGroup, groupErrors, setGroupErrors] = useInput(null);
  const [school, setSchool, schoolErrors, setSchoolErrors] = useInput(schoolOptions.find(currentSchool => currentSchool.value === spaceId));
  const [copy, setCopy] = useState(true);

  const groupOptions = groups.map(group => ({ value: group.id, label: group.label, schoolId: group.schoolId }))
    .filter(group => group.schoolId === (school?.value !== PERSONAL_SPACE ? school?.value : null))
    .filter(group => group.value !== groupId);

  const otherAuthors = useMemo(() => {
    return selectedRubrics.filter(rubric => rubric.authorId !== userId);
  }, [selectedRubrics, userId]);

  const containsOtherAuthors = useMemo(() => {
    return otherAuthors.length > 0;
  }, [otherAuthors]);

  const sendDisabled = useMemo(() => {
    if (iaveToggle) {
      return false;
    }

    if (!createRubricToggle && copy && school.value === PERSONAL_SPACE && spaceId === PERSONAL_SPACE) {
      return true;
    }

    return false;
  }, [iaveToggle, createRubricToggle, copy, school.value, spaceId]);

  const copyDisabled = useMemo(() => {
    if (iaveToggle) {
      return false;
    }

    if (!createRubricToggle && school.value === PERSONAL_SPACE && spaceId === PERSONAL_SPACE) {
      return true;
    }

    if (containsOtherAuthors) {
      return true;
    }

    return false;
  }, [iaveToggle, createRubricToggle, school.value, spaceId, containsOtherAuthors]);

  useEffect(() => {
    if (containsOtherAuthors) {
      setCopy(true);
      return;
    }

    if (!createRubricToggle && school.value === PERSONAL_SPACE && spaceId === PERSONAL_SPACE) {
      setCopy(false);
      return;
    }

    setCopy(true);
  }, [containsOtherAuthors, createRubricToggle, school.value, spaceId]);

  const _setSchool = (school) => {
    setSchool(school);
    const schoolGroup = groups.find(group => group.schoolId === (school.value !== PERSONAL_SPACE ? school.value : null) && group.isPersonal);
    setGroup({
      value: schoolGroup.id,
      label: schoolGroup.label,
    });
  };

  const cancel = () => {
    close();
    setTimeout(() => {
      setGroup(null);
      setSchool(schoolOptions.find(currentSchool => currentSchool.value === spaceId));
      setGroupErrors(null);
      setCopy(true);
    }, 350);
  };

  const sendRubric = () => {
    if (!group) {
      setGroupErrors([selectedRubrics.length === 1 ? lang.rubrics.send.errorSendRubricGroup : lang.rubrics.send.errorSendRubricsGroup]);
      return;
    }

    if (!school) {
      setSchoolErrors([lang.rubrics.send.errorSendRubricsSchool]);
      return;
    }

    for (let i = 0; i < selectedRubrics.length; i++) {
      const rubric = selectedRubrics[i];
      if (rubric.authorId !== userId && spaceId !== PERSONAL_SPACE && school.value === PERSONAL_SPACE) {
        toast.warning(lang.rubrics.send.errorSendRubricsToMySpace);
        return;
      }
    }

    const templateRubricIds = selectedRubrics.map(rubric => rubric.id);

    setLoading(true);
    sendTemplateRubricsRequest([], { templateRubricIds, groupId: group.value, copy }, ({ data }) => {
      if (data.status === 0) {
        toast.success(selectedRubrics.length === 1 ? lang.rubrics.send.successSendRubric : lang.rubrics.send.successSendRubrics);
        setTimeout(() => {
          if (school.value === PERSONAL_SPACE && (spaceId !== PERSONAL_SPACE || (spaceId === PERSONAL_SPACE && copy))) {
            dispatch(incrementTotalRubrics(selectedRubrics.length));
          } else if (spaceId === PERSONAL_SPACE && school.value !== PERSONAL_SPACE && !copy) {
            dispatch(decrementTotalRubrics(selectedRubrics.length));
          }

          setLoading(false);
          refresh();
          close();
        }, 350);
        return;
      }

      setLoading(false);
      toast.error(lang.oops);
      close();
    });
  };

  const getActions = () => ([
    {
      name: lang.cancel,
      color: 'black',
      onClick: cancel,
    },
    {
      name: lang.send,
      onClick: sendRubric,
      loading: loading,
      disabled: sendDisabled,
      tip: sendDisabled ? lang.plans.exceededRubricsTip(createRubricToggleMaxUsages) : '',
    },
  ]);

  return (
    <Modal
      open={open}
      close={cancel}
      header={selectedRubrics?.length === 1 ? lang.rubrics.send.sendRubric : lang.rubrics.send.sendRubrics}
      actions={getActions()}
      center
      transition
      small
    >
      <Input
        type="select"
        value={school}
        label={lang.appKeywords.spaces}
        placeholder={lang.appKeywords.space}
        onChange={(option) => _setSchool(option)}
        options={schoolOptions}
        errors={schoolErrors}
        disabled={containsOtherAuthors && spaceId !== PERSONAL_SPACE}
      />
      <Spacer px={15} />
      <Input
        type="select"
        value={group}
        label={lang.appKeywords.group}
        placeholder={lang.appKeywords.group}
        onChange={(option) => setGroup(option)}
        options={groupOptions}
        errors={groupErrors}
      />
      <Spacer px={15} />
      <div className={classes.keepCopyContainer}>
        <Input
          type="checkbox"
          value={copy}
          label={lang.test.send.keepCopy}
          onChange={(event) => setCopy(event.target.checked)}
          disabled={copyDisabled}
        />
        <Render when={!createRubricToggle && school.value === PERSONAL_SPACE}>
          <PlansPill
            tip={lang.plans.exceededRubricsTip(createRubricToggleMaxUsages)}
          />
        </Render>
      </div>
    </Modal>
  );
};

SendRubricModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  selectedRubrics: PropTypes.array,
  groupId: PropTypes.string,
  groups: PropTypes.array,
  refresh: PropTypes.func,
};

export default SendRubricModal;
