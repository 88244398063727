import { v4 } from 'uuid';

export const tableReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CELLS':
      return action.payload;
    case 'SET_CELL_VALUE': {
      const index = state.findIndex(cell => cell.id === action.payload.key);
      if (index === -1) {
        throw new Error(`Cell with col ${action.payload.col} and row ${action.payload.row} not found`);
      }
      return [
        ...state.slice(0, index),
        { ...state[index], value: action.payload.value },
        ...state.slice(index + 1),
      ];
    }
    case 'SET_CHOICE_CELL': {
      const index = state.findIndex(cell => cell.col === action.payload.col && cell.row === action.payload.row);
      if (index === -1) {
        throw new Error(`Cell with col ${action.payload.col} and row ${action.payload.row} not found`);
      }
      return [
        ...state.slice(0, index),
        { ...state[index], isCorrect: !state[index].isCorrect },
        ...state.slice(index + 1),
      ];
    }
    case 'ADD_COLUMN': {
      const lastCol = state.filter(el => el.row === 0).length;
      const rowLength = state.filter(el => el.col === 0 && el.row !== 0).length;
      return [
        ...state,
        // header
        {
          id: v4(),
          row: 0,
          col: lastCol,
          value: null,
          isCorrect: null,
          identifier: String.fromCharCode(65 + lastCol) + '_0',
        },
        // content
        ...Array.from(
          { length: rowLength },
          (_, index) => {
            const row = index + 1;
            const identifier = String.fromCharCode(65 + lastCol) + '_' + row;
            return {
              id: v4(),
              row,
              col: lastCol,
              value: null,
              isCorrect: false,
              identifier,
              originalIdentifier: identifier,
            };
          },
        ),
      ];
    }
    case 'REMOVE_COLUMN': {
      const targetColumn = action.payload.col;
      return state.filter(el => el.col !== targetColumn).map(el => ({
        ...el,
        col: el.col > targetColumn ? el.col - 1 : el.col,
        originalIdentifier: el.col > targetColumn && el.row > 0 ? String.fromCharCode(65 + el.col - 1) + '_' + el.row : el.originalIdentifier,
      }));
    }
    case 'REMOVE_ROW': {
      const targetRow = action.payload.row;
      return state.filter(el => el.row !== targetRow).map(el => ({
        ...el,
        row: el.row > targetRow ? el.row - 1 : el.row,
      }));
    }
    case 'ADD_ROW': {
      const lastRow = state.filter(el => el.col === 0).length;
      const colLength = state.filter(el => el.row === 0 && el.col !== 0).length;
      return [
        ...state,
        // header
        {
          id: v4(),
          row: lastRow,
          col: 0,
          value: null,
          identifier: String.fromCharCode(65) + '_' + lastRow,
        },
        // content
        ...Array.from(
          { length: colLength },
          (_, index) => {
            const col = index + 1;
            const identifier = String.fromCharCode(65 + col) + '_' + lastRow;
            return {
              id: v4(),
              row: lastRow,
              col: col,
              value: null,
              isCorrect: false,
              identifier: identifier,
              originalIdentifier: identifier,
            };
          },
        ),
      ];
    }
    case 'SET_IDENTIFIERS': {
      const index = state.findIndex(el => el.row === action.payload.index.row && el.col === action.payload.index.col);
      return [
        ...state.slice(0, index),
        { ...state[index], identifier: action.payload.value },
        ...state.slice(index + 1),
      ];
    }
    default:
      return state;
  }
};
