import { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { appLoaded } from 'actions/pageActions';
import { bootUser, langSet } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useQuery from 'hooks/common/useQuery';
import lang from 'lang';
import { addCookie } from 'utils';

const AuthHandler = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const [meRequest] = useApi(api.me);
  const { inProgress } = useMsal();

  const spaceId = window.location.pathname.split('/')[2];

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      meRequest([], null, ({ data }) => {
        if (data.status === 0) {
          addCookie('session_lang', data.user.lang);
          lang.setLanguage(data.user.lang);
          dispatch(langSet(data.user.lang));
          dispatch(bootUser(data.user));
        }
        dispatch(appLoaded());
      });
    }
  }, [inProgress, meRequest, dispatch, query, history, spaceId]);

  return null;
};

export default AuthHandler;
