import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import AskForExtraGaps from '../ask-for-extra-gaps/AskForExtraGaps';

const AskForGapsDropdown = ({ number, gaps, setGaps, type, restricted }) => {

  const filteredGaps = useMemo(() => gaps.sort((a, b) => (type === 'filling' ? a.position - b.position : a.gapCoords.x - b.gapCoords.x)).reduce(
    (positionGaps, currentGap) => {
      const positionArray = positionGaps.find(el => (
        type === 'filling' ?
          el[0].position === currentGap.position
          :
          el[0].gapCoords.x === currentGap.gapCoords.x
          &&
          el[0].gapCoords.y === currentGap.gapCoords.y
      ));
      if (positionArray) {
        positionArray.push(currentGap);
        return positionGaps;
      } else {
        return [...positionGaps, [currentGap]];
      }
    }, [],
  ), [gaps, type]);

  return (
    <Fragment>
      {filteredGaps.map((positionArray, index) => (
        <AskForExtraGaps
          key={positionArray.find(gap => gap.isCorrect).id}
          number={number}
          subNumber={index + 1}
          gap={positionArray.find(gap => gap.isCorrect)}
          gaps={positionArray}
          setGaps={setGaps}
          type={type}
          dropdown
          restricted={restricted}
        />
      ))}
    </Fragment>
  );
};

AskForGapsDropdown.propTypes = {
  number: PropTypes.number,
  gaps: PropTypes.array,
  setGaps: PropTypes.func,
  type: PropTypes.string,
  restricted: PropTypes.bool,
};

export default AskForGapsDropdown;
