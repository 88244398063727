import { createUseStyles } from 'react-jss';

export default createUseStyles({
  sectionHeaderTitle: {
    fontWeight: 'normal',
    marginTop: '0px',
  },
  inputTable: {
    width: '100%',
    tableLayout: 'fixed',
    padding: '10px 20px',
  },
});
