import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  headerWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '55px',
    padding: '0 20px',
    backgroundColor: theme.backgroundColor2,
  },
  headerNavigator: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  textItem: {
    fontSize: '18px',
    fontWeight: 600,
    color: theme.textColor,
  },
  linkItem: {
    fontSize: '18px',
    fontWeight: 600,
    color: theme.textColor,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.inputBorder3}`,
    padding: '5px 10px',
    minWidth: '100px',
    justifyContent: 'space-between',
    borderRadius: '10px',
  },
  dropdownItemLabel: {
    height: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dropdownArrow: {
    fontSize: '10px',
    marginLeft: '10px',
  },
  dropdownMenu: {
    backgroundColor: `${theme.selectBackground} !important`,
    borderRadius: '10px',
    border: `1px solid ${theme.inputBorder3} !important`,
    top: 'calc(100% + 9px)',
    width: 'calc(100% - 2px)',
    zIndex: '1',
    overflow: 'hidden',
  },
  dropdownMenuItem: {
    borderBottom: 'none !important',
    margin: '0 !important',
    '&:hover': {
      backgroundColor: theme.selectBackgroundHover,
    },
    '&:nth-last-of-type(1)': {
      borderBottom: 'none !important',
    },
    '&.selected': {
      backgroundColor: theme.selectBackgroundHover,
    },
  },
  dropdownMenuItemLabel: {
    height: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  separator: {
    fontWeight: 'normal',
    marginRight: '10px',
    '&:last-child': {
      display: 'none',
    },
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    marginLeft: '10px',
    fontSize: '12px',
  },
  linkIcon: {
    display: 'none',
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: '1em',
    opacity: 0.6,
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
  iconLabel: {
    marginTop: '0.25em',
    fontSize: '12px',
  },
  actionIcon: {
    fontSize: '20px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    headerWrap: {
      padding: '0 10px',
      display: 'block',
      height: 'unset',
    },
    headerNavigator: {
      paddingTop: '0.5em',
      paddingBottom: '0.5em',
      '&.isInvisible': {
        display: 'none',
      },
    },
    actionButton: {
      marginLeft: '0px',
    },
    headerActions: {
      paddingBottom: '0.5em',
      justifyContent: 'space-evenly',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    textItem: {
      display: 'none',
    },
    headerWrap: {
      justifyContent: 'space-around',
    },
  },
}));
