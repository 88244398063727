import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useHistory, useParams } from 'react-router';

import api from 'api';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import useQuery from 'hooks/common/useQuery';
import lang from 'lang';
import { cleanAndSortGroups } from 'utils';

import CreateExerciseModal from 'components/exercises/CreateExerciseModal';
import ExerciseBank from 'components/exercises/exercise-bank/ExerciseBank';
import Header from 'components/exercises/Header';

const Exercises = () => {
  const { loaded } = usePageLogic(lang.exercises.title, true);
  const query = useQuery();
  const { spaceId } = useParams();
  const history = useHistory();
  const [getSchoolGroupsRequest] = useApi(api.getSchoolGroups);
  const [getGroupsRequest] = useApi(api.getGroups);

  const [schoolGroups, setSchoolGroups] = useState([]);
  const [group, setGroup] = useState(null);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [createExerciseModal, setCreateExerciseModal] = useState(false);
  const [groups, setGroups] = useState([]);

  const fetchGroups = useCallback(() => {
    const schoolId = spaceId === 'p' ? null : spaceId;
    getGroupsRequest([schoolId], null, ({ data }) => {
      if (data.status === 0) {
        const newGroups = cleanAndSortGroups(data.groups);
        setGroups(newGroups);
      }
    });
  }, [getGroupsRequest, spaceId]);

  const fetchSchoolGroups = useCallback(() => {
    getSchoolGroupsRequest([spaceId], null, ({ data }) => {
      if (data.status === 0) {
        loaded();

        const newGroups = cleanAndSortGroups(data.groups);

        setSchoolGroups(newGroups);

        const currentGroupId = query().get('group');
        const group = newGroups.find(group => group.id === currentGroupId);

        if (group) {
          setGroup(group);
        } else {
          setGroup(newGroups[0]);
          if (!newGroups[0]) {
            return;
          }
          history.replace({
            search: `?group=${newGroups[0].id}`,
          });
        }
      }
    });
  }, [getSchoolGroupsRequest, loaded, query, history, spaceId]);

  useEffect(() => {
    fetchSchoolGroups();
    fetchGroups();
  }, [fetchGroups, fetchSchoolGroups]);

  if (!group) {
    return null;
  }

  return (
    <Fragment>
      <CreateExerciseModal
        open={createExerciseModal}
        setOpen={setCreateExerciseModal}
        group={group}
      />
      <Header
        groups={schoolGroups}
        group={group}
        setGroup={setGroup}
        setCreateExerciseModal={setCreateExerciseModal}
        setSelectedExercises={setSelectedExercises}
      />
      <ExerciseBank
        groupId={group.id}
        groups={groups}
        setCreateExerciseModal={setCreateExerciseModal}
        selected={selectedExercises}
        setSelected={setSelectedExercises}
      />
    </Fragment>
  );
};

export default Exercises;
