import React, { useState, Fragment, useCallback, useEffect } from 'react';
import { faArrowLeft, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { selectTestGroupId, selectTestGroupName } from 'actions/testActions';
import { selectHasPersonalSpace, selectUserFullName, selectUserId, selectUserSchools, selectUserSpaceIdPreference } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import { cleanAndSortGroups } from 'utils';

import Button from 'components/common/Button';
import IconBtn from 'components/common/IconBtn';
import Input from 'components/common/Input';
import Tooltip from 'components/common/Tooltip';
import RubricBank from 'components/rubrics/RubricBank';

import useStyles from './styles';

const SelectRubric = ({ cancel, afterSubmit }) => {
  const classes = useStyles();
  const toast = useToast();
  const { spaceId, testId } = useParams();
  const userId = useSelector(selectUserId);
  const userName = useSelector(selectUserFullName);
  const schools = useSelector(selectUserSchools);
  const hasPersonalSpace = useSelector(selectHasPersonalSpace);
  const currentSchoolId = useSelector(selectUserSpaceIdPreference);
  const groupId = useSelector(selectTestGroupId);
  const groupName = useSelector(selectTestGroupName);
  const [getGroupsRequest] = useApi(api.getGroups);
  const [setTestRubricRequest] = useApi(api.setTestRubric);

  const schoolOptions = schools?.map(school => ({ value: school.id, label: school.name }));

  if (hasPersonalSpace) {
    schoolOptions.push({ value: 'p', label: lang.appKeywords.personalSpace });
  }

  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState({ value: groupId, label: groupName });
  const [school, setSchool] = useState(schoolOptions.find(currentSchool => currentSchool.value === currentSchoolId));
  const [rubricSelected, setRubricSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const userFilters = school.value !== 'p' && school.value !== currentSchoolId ? [{ id: userId, name: userName, disabled: true }] : null;

  const groupOptions = groups.map(group => ({ value: group?.id, label: group.label, schoolId: group.schoolId }))
    .filter(group => group.schoolId === (school?.value !== 'p' ? school?.value : null));

  const fetchGroups = useCallback(() => {
    getGroupsRequest([spaceId], null, ({ data }) => {
      if (data.status === 0) {
        const currentGroups = cleanAndSortGroups(data.groups);
        const currentGroup = currentGroups.find(currentGroup => currentGroup.id === groupId);

        setGroups(currentGroups);
        setGroup(currentGroup);
      }
    });
  }, [getGroupsRequest, spaceId, groupId]);

  const _setSchool = (school) => {
    setSchool(school);

    const firstSchoolGroup = groups.map(group => ({ value: group.id, label: group.label, schoolId: group.schoolId }))
      .filter(group => group.schoolId === (school?.value !== 'p' ? school?.value : null))[0];

    setGroup(firstSchoolGroup);
  };

  const setRubric = () => {
    if (!rubricSelected) {
      return;
    }

    setLoading(true);
    setTestRubricRequest([testId], { templateRubricId: rubricSelected.id }, ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          setLoading(false);
          toast.success(lang.test.rubric.successSetRubric);
          afterSubmit();
        }, 300);
        return;
      }

      toast.error(lang.oops);
    });
  };

  const goBack = () => {
    if (loading) {
      return;
    }

    cancel();
    setRubricSelected(null);
  };

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return (
    <Fragment>
      <div className={classes.header}>
        <div className={classes.titleWrap}>
          <Render when={cancel}>
            <IconBtn
              icon={faArrowLeft}
              tip={lang.goBack}
              onClick={goBack}
            />
          </Render>
          <div className={classes.title}>
            {lang.test.rubric.selectRubricFromBank}
          </div>
        </div>
        <div className={classes.selectorWrapper}>
          <div className={classes.groupWrapper}>
            <div className={classes.label}>
              {lang.appKeywords.spaces}
            </div>
            <div className={classes.schoolWrapper}>
              <Render when={school.value !== 'p' && school.value !== currentSchoolId}>
                <Tooltip
                  tip={lang.test.selectRubricsFromOtherSpace}
                  left
                >
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                </Tooltip>
              </Render>
              <div className={classes.schoolSelector}>
                <Input
                  type="select"
                  value={school}
                  placeholder={lang.appKeywords.space}
                  onChange={(option) => _setSchool(option)}
                  options={schoolOptions}
                />
              </div>
            </div>
          </div>
          <div className={classes.groupWrapper}>
            <div className={classes.label}>
              {lang.group.title}
            </div>
            <div>
              <Input
                type="select"
                value={group}
                placeholder={lang.appKeywords.group}
                onChange={(option) => setGroup(option)}
                options={groupOptions}
              />
            </div>
          </div>
        </div>
      </div>
      <RubricBank
        groupId={group.value}
        groups={groups}
        selectable
        selected={rubricSelected ? [rubricSelected] : []}
        setSelected={setRubricSelected}
        userFilters={userFilters}
        wrapped
      />
      <div className={classes.btnContainer}>
        <Button
          onClick={setRubric}
          className={classes.btn}
          disabled={!rubricSelected}
          loading={loading}
        >
          {lang.test.rubric.addRubric}
        </Button>
        <Button
          onClick={goBack}
          className={classes.btn}
          black
        >
          {lang.cancel}
        </Button>
      </div>
    </Fragment>
  );
};

SelectRubric.propTypes = {
  cancel: PropTypes.func,
  afterSubmit: PropTypes.func,
};

export default SelectRubric;
