import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import PropTypes from 'prop-types';

import InnerGap from '../InnerGap';

const DraggableGap = ({ item, draggable }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: item.gapId ? item.gapId : item.id,
    data: {
      supports: 'gap',
    },
  });

  const style = {
    transform: isDragging ? null : draggable ? (transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined) : undefined,
    cursor: draggable ? (transform ? 'grabbing' : undefined) : undefined,
    opacity: isDragging ? '0%' : '100%',
    touchAction: 'none',
  };

  return (
    <InnerGap
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      item={item}
      draggable={draggable}
      isDragging={isDragging}
    />
  );
};

DraggableGap.propTypes = {
  item: PropTypes.object,
  disabled: PropTypes.bool,
  draggable: PropTypes.bool,
  type: PropTypes.string,
};

export default DraggableGap;
