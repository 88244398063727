import React, { useEffect, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import PreviewableRubric from '../PreviewableRubric';
import Modal from 'components/common/Modal';

const PreviewRubricModal = ({ open, close, templateRubricId }) => {
  const [rubric, setRubric] = useState(null);
  const toast = useToast();
  const [getTemplateRubricRequest] = useApi(api.getTemplateRubric);

  useEffect(() => {
    if (open) {
      getTemplateRubricRequest([templateRubricId], null, ({ data }) => {
        if (data.status === 0) {
          setRubric(data.rubric);
          return;
        }

        toast.error(lang.oops);
      });
    }
  }, [getTemplateRubricRequest, templateRubricId, toast, open]);

  const actions = [
    {
      name: lang.close,
      onClick: close,
      color: 'black',
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.rubrics.previewRubric}
      actions={actions}
      center
      transition
      large
    >
      <PreviewableRubric
        rubric={rubric}
      />
    </Modal>
  );
};

PreviewRubricModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  templateRubricId: PropTypes.string,
};

export default PreviewRubricModal;
