import { SET_FILTERS, SET_GROUPS, SET_ORDER, SET_SEARCH, SET_ITEMS, USAGES_ORDER, INCREASE_USAGES, DEFAULT_SEARCH_TYPE } from 'actions/exploreActions';
import { increaseUsages } from 'utils/explore';

const initialState = {
  items: [],
  search: '',
  filters: [],
  order: USAGES_ORDER,
  groups: [],
  searchType: DEFAULT_SEARCH_TYPE,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: action.search,
        searchType: action.searchType,
        order: action.order,
        items: [],
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
        items: [],
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.order,
        items: [],
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: action.groups,
      };
    case SET_ITEMS:
      return {
        ...state,
        items: [...state.items, ...action.items].filter((item, index, self) => self.findIndex((i) => i.id === item.id) === index),
      };
    case INCREASE_USAGES:
      return increaseUsages(state, action.itemId);
    default:
      return state;
  }
};

export default reducer;
