import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import CustomFields from '../CustomFields';
import PredefinedInputs from '../PredefinedFields';
import Card from 'components/common/Card';

import useStyles from './styles';

const HeaderSettings = ({ getValue, setValue, getErrors, setErrors }) => {
  const classes = useStyles();

  return (
    <Card
      header={lang.test.physicalVersion.headerSettings}
      className={classes.sectionContainer}
    >
      <div className={classes.tableWrapper}>
        <PredefinedInputs
          getValue={getValue}
          setValue={setValue}
          getErrors={getErrors}
        />
        <CustomFields
          getValue={getValue}
          setValue={setValue}
          getErrors={getErrors}
          setErrors={setErrors}
        />
      </div>
    </Card>
  );
};

HeaderSettings.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getErrors: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default HeaderSettings;
