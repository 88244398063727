import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import lang from 'lang.js';
import PropTypes from 'prop-types';

import FilterPoolItem from 'components/common/filter-selector/FilterPoolItem';
import Input from 'components/common/Input';
import colors from 'components/common/label-selector/color-picker/colors';
import RandomizerSwatch from 'components/common/label-selector/color-picker/RandomizerSwatch';
import Swatch from 'components/common/label-selector/color-picker/Swatch';

import useStyles from './styles.js';

const ColorPicker = ({ color, setColor, name, setName }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.picker}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={classes.swatches}>
        {colors.map((swatchColor) => (
          <Swatch
            key={swatchColor}
            swatchColor={swatchColor}
            color={color}
            setColor={setColor}
          />
        ))}
      </div>
      <Render when={setName}>
        <div className={classes.lower}>
          <RandomizerSwatch
            color={color}
            setColor={setColor}
          />
          <div className={classes.input}>
            <Input
              type={'text'}
              value={name}
              placeholder={lang.exercises.labels.labelName}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
        </div>
        <div className={classes.previewWrapper}>
          <FilterPoolItem
            className={classes.preview}
            poolItem={{ color: color, name: name ? name : lang.exercises.labels.labelName }}
            poolItems={[]}
          />
        </div>
      </Render>
    </div>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired,
  name: PropTypes.string,
  setName: PropTypes.func,
};

export default ColorPicker;
