/**
 * Action constants
 */
const SET_ATTEMPT = 'SET_ATTEMPT';
const UPDATE_GRADE = 'UPDATE_GRADE';
const UPDATE_CRITERIA_GRADE = 'UPDATE_CRITERIA_GRADE';
const UPDATE_FEEDBACK = 'UPDATE_FEEDBACK';

/**
 * Action creators
 */
const setAttempt = (attempt) => ({
  type: SET_ATTEMPT,
  attempt: attempt,
});

const updateGrade = (answerId, grade, sectionId) => ({
  type: UPDATE_GRADE,
  answerId: answerId,
  grade: grade,
  sectionId: sectionId,
});

const updateCriteriaGrade = (answerId, criteriaGradeId, grade, sectionId) => ({
  type: UPDATE_CRITERIA_GRADE,
  answerId: answerId,
  criteriaGradeId: criteriaGradeId,
  grade: grade,
  sectionId: sectionId,
});

const updateFeedback = (answerId, feedback, sectionId) => ({
  type: UPDATE_FEEDBACK,
  answerId: answerId,
  feedback: feedback,
  sectionId: sectionId,
});

/**
 * Selectors
 */
const selectAttempt = (state) => {
  return state.attempt;
};

const selectAttemptEndedAt = (state) => {
  return state.attempt?.endedAt;
};

const selectAttemptSection = (id) => {
  return (state) => {
    const section = state.attempt.items.find((item) => item.id === id && item.itemType === 'section');
    section.sectionTotal = section.exercises ? section.exercises.reduce((partialSum, exercise) => partialSum + parseFloat(exercise.grade), 0) : 0;
    section.sectionMaxTotal = section.exercises ? section.exercises.reduce((partialSum, exercise) => partialSum + exercise.maxGrade, 0) : 0;
    return section;
  };
};

const selectAttemptExercise = (id, sectionId) => {
  return (state) => {
    if (sectionId) {
      const section = state.attempt.items
        .find((item) => item.id === sectionId && item.itemType === 'section');

      if (!section) {
        return;
      }

      return section.exercises
        .find((exercise) => exercise.id === id);
    }

    return state.attempt.items
      .find((item) => item.id === id && item.itemType === 'exercise');
  };
};

export {
  SET_ATTEMPT,
  UPDATE_GRADE,
  UPDATE_CRITERIA_GRADE,
  UPDATE_FEEDBACK,
  setAttempt,
  updateGrade,
  updateCriteriaGrade,
  updateFeedback,
  selectAttempt,
  selectAttemptEndedAt,
  selectAttemptSection,
  selectAttemptExercise,
};
