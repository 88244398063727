import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 13px',
    gap: '0.5em',
  },
  label: {
    '&.disabled': {
      opacity: '0.4',
    },
  },
  toggleWrapper: {
    display: 'flex',
    padding: '0 0 15px 13px',
    gap: '0.5em',
    alignItems: 'center',
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: '1em',
    border: `1px solid ${theme.cardBorder}`,
    borderRadius: '3px',
    padding: '5px',
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
  actionIcon: {
    fontSize: '20px',
    opacity: 0.6,
  },
}));
