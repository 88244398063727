/**
 * Action constants
 */
export const SET_TESTS = 'SET_TESTS';
export const SET_PAGES = 'SET_PAGES';
export const SET_PAGE = 'SET_PAGE';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const RESET = 'RESET';

/**
 * Action creators
 */
export const setTests = (tests) => ({
  type: SET_TESTS,
  tests,
});

export const setPages = (pages) => ({
  type: SET_PAGES,
  pages,
});

export const setPage = (page) => ({
  type: SET_PAGE,
  page,
});

export const startLoading = () => ({
  type: START_LOADING,
});

export const stopLoading = () => ({
  type: STOP_LOADING,
});

export const reset = () => ({
  type: RESET,
});

/**
 * Selectors
 */
export const selectTests = (state) => state.testsPage.tests;

export const selectPages = (state) => state.testsPage.pages;

export const selectPage = (state) => state.testsPage.page;

export const selectLoading = (state) => state.testsPage.loading;
