import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  generateGradesContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  studentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '&.admin': {
      gap: '23px',
    },
    paddingBottom: '10px',
    position: 'sticky',
    top: '15px',
    height: '100%',
  },
  studentSearchInput: {
    display: 'block',
    '&.admin > input': {
      height: '52px',
    },
  },
  studentListCard: {
    width: '100% !important',
    '& .otl-cardContentContainer': {
      padding: 0,
    },
    borderRadius: '18px',
    overflow: 'hidden',
    boxShadow: '0 4px 4px #00000040',
  },
  studentList: {
    maxHeight: '65vh !important',
    overflow: 'hidden',
    overflowY: 'auto',
  },
  studentSelect: {
    display: 'none',
  },
  studentListItem: {
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  studentBtn: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.listItemHover,
    },
    '&.selected': {
      backgroundColor: theme.listItemSelected,
    },
  },
  studentName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  studentGradeAndActions: {
    display: 'flex',
    gap: '1em',
  },
  validationIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  grade: {
    color: theme.successColor,
    '&.failed': {
      color: theme.errorColor,
    },
  },
  deleteIconWrapper: {
    color: theme.textColor,
    transition: 'color 300ms',
    '&:hover': {
      color: theme.buttonBackgroundHoverRed,
    },
  },
  deleteIconWrapperDisabled: {
    color: theme.textColor9,
    cursor: 'not-allowed',
  },
  noStudents: {
    textAlign: 'center',
    color: theme.textColor2,
  },
  attemptActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  publishGradesButton: {
    marginBottom: '10px',
  },
  exportResolutionsButton: {
    marginRight: '0.5em',
  },
  publishGradesIcon: {
    marginRight: '10px',
  },
  testStatisticsCard: {
    width: '100% !important',
    '& .otl-cardContentContainer': {
      padding: 0,
    },
    height: '100%',
    borderRadius: '18px',
    overflow: 'hidden',
    boxShadow: '0 4px 4px #00000040',
    marginBottom: '15px',
  },
  testStatistics: {
    padding: '25px 5px 5px 5px',
    height: '100%',
    width: '100% !important',
    paddingBottom: '15px',
    display: 'flex',
    flexFlow: 'row-wrap',
    justifyContent: 'space-evenly',
  },
  moreStatsButton: {
    cursor: 'pointer',
    marginRight: '10px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  statNumbers: {
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingRight: '15px',
  },
  statButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  statNumberTitle: {
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  statNumber: {
    marginTop: '7px',
    marginBottom: '5px',
    fontSize: '19px',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 600,
    color: theme.successColor,
    '&.failed': {
      color: theme.errorColor,
    },
  },
  statsInfoIcon: {
    marginLeft: '5px',
  },
  parentRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexFlow: 'column-wrap',
  },
  donutChart: {
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  pagination: {
    '& .otl-paginationPageItem': {
      width: '37px',
      height: '37px',
    },
    '& .otl-paginationPageItem.selected': {
      width: '35px !important',
      height: '35px !important',
      backgroundColor: theme.paginationBackground,
      borderRadius: '0',
      border: `1px solid ${theme.paginationBorder}`,
    },
    '& .otl-paginationPageItem:hover': {
      width: '37px',
      height: '37px',
      backgroundColor: theme.paginationBackground,
      borderRadius: '0',
    },
    '& .otl-paginationPageItem.disabled:hover': {
      backgroundColor: 'inherit',
      cursor: 'inherit',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    studentsContainer: {
      justifyContent: 'flex-end',
    },
    studentListCard: {
      width: 'unset !important',
      display: 'none',
    },
    studentSearchInput: {
      display: 'none',
    },
    studentSelect: {
      display: 'block',
      width: '30%',
    },
  },

  [`@media (max-width: ${theme.xs}px)`]: {
    studentSelect: {
      width: '100%',
    },
  },
}));
