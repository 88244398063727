import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  randomSwatchWrapper: {
    width: '3em',
    height: '3em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  randomSwatch: {
    color: theme.textColorContrast,
    width: '2.5em',
    height: '2.5em',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'width 150ms, height 150ms',
    '&:hover': {
      cursor: 'pointer',
      width: '3em',
      height: '3em',
    },
    '&:hover $icon': {
      animation: '$spin 1s linear infinite',
      animationTimingFunction: 'ease-in-out',
    },
  },
  icon: {
    fontSize: '20px',
  },
}));
