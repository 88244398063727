import React from 'react';
import { faFileAlt, faPencilRuler, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { selectUserIsTeacher } from 'actions/userActions';
import lang from 'lang';
import routes from 'routes';

import Card from 'components/common/Card';

import useStyles from './styles';

const Shortcuts = ({ totalTests, totalExercises, totalRubrics }) => {
  const { spaceId, groupId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const isTeacher = useSelector(selectUserIsTeacher);

  const shortcuts = [
    {
      label: lang.appKeywords.assessments,
      link: routes.tests.ref(spaceId, groupId),
      icon: faFileAlt,
      total: totalTests,
    },
    {
      label: lang.appKeywords.exercises,
      link: routes.exercises.ref(spaceId, groupId),
      icon: faPencilRuler,
      total: totalExercises,
    },
    {
      label: lang.appKeywords.rubrics,
      link: routes.rubrics.ref(spaceId, groupId),
      icon: faTable,
      total: totalRubrics,
    },
  ];

  const goToShortcut = (link) => {
    if (!isTeacher) {
      return;
    }

    history.push(link);
  };

  return (
    <div data-tour="group-shortcuts">
      <Row className={classes.shortcutsContainer}>
        {shortcuts.map(shortcut => (
          <Col
            xl={4}
            key={shortcut.label}
          >
            <Card
              width="100%"
              onClick={() => goToShortcut(shortcut.link)}
              className={cx(classes.card, { clickable: isTeacher })}
            >
              <FontAwesomeIcon
                icon={shortcut.icon}
                className={classes.icon}
              />
              <div className={classes.linkText}>
                {shortcut.label}
              </div>
              <h1>
                {shortcut.total}
              </h1>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

Shortcuts.propTypes = {
  totalTests: PropTypes.number,
  totalExercises: PropTypes.number,
  totalRubrics: PropTypes.number,
};

export default Shortcuts;
