import lang from 'lang';

const validateRubric = (rubric, setErrors) => {
  let valid = true;
  const nameErrors = [];
  const criteriaErrors = {
    general: [],
    criteria: [],
  };
  criteriaErrors.criteria = rubric.criteria.map(() => ({ name: [] }));

  if (!rubric.name.trim()) {
    nameErrors.push(lang.rubrics.form.nameRequiredError);
    valid = false;
  }

  if (rubric.criteria.length === 0) {
    criteriaErrors.general.push(lang.rubrics.form.criteriaRequiredError);
    valid = false;
  }

  rubric.criteria.forEach((criterion, index) => {
    if (!criterion.name.trim()) {
      criteriaErrors.criteria[index].name.push(lang.rubrics.form.criteriaNameRequiredError);
      valid = false;
    }
  });

  setErrors('name', nameErrors);
  setErrors('criteria', criteriaErrors);

  return valid;
};

export {
  validateRubric,
};
